import React from 'react';
import { call_get_api } from "../utils/event_handling";
import Image from '../components/image';
import { iam_ip, APP_BASE_URL } from "../utils/ip_details";


import { connect } from "react-redux";
//import { Link } from 'react-router';

import {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setEndDate,
    setStartDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setTenantId,
    setInitialValues,
    setAttribute,
    setDataSet,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    setMonitorInitialValues,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLModelMapping,
    addMLDataSetInfo,
    addMLAttributeInfo,
    setMLModel,
    addMLModel,
    addModelPerformance, addModelDetails,
    addMonitorDQListViewData, addMetrics, addDatasetVersions,
    addUniquenessDataSetInfo, addUniquenessAttributeInfo, addRecencyDetails,
    addRecentHistory, addDataSize, addRecencyPreview, prefetchMonitorState, noIntegration

} from "../redux/actions";
import { getProtocol, getCompleteEndpoint } from "../utils/common_utils";
import { LOGIN_SOURCE } from "../utils/constant"
import {perform_login_initial_setup} from "../login/login_initial_steps";

class MSILogin extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            errors:null
        };
        this.login = this.login.bind(this);
        this.perform_login_initial_setup = perform_login_initial_setup.bind(this);
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
    }

    login() {
        call_get_api(iam_ip, "msi/user_details").then((response) => {
            // Following function performs required prefetches and initial setups
            this.perform_login_initial_setup(LOGIN_SOURCE.MSI_LOGIN_SRC,
                response, {});
        });

    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.login()
    }
    getUrl() {                
        let protocol = getProtocol(APP_BASE_URL);
        let reqEndPoint = getCompleteEndpoint(protocol, APP_BASE_URL, 
                                              "login?error_message=" + this.state.errors);
        window.location.href = reqEndPoint       
    }

    render() {
        return (
            <>
            {!this.state.errors ?
                <div className="fullpage-loader">
                    <div><Image src="logo" /></div>
                    <div className="qd-spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            :this.getUrl()
            }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setStartDate,
    setEndDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setTenantId,
    setInitialValues,
    setDataSet,
    setAttribute,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    setMonitorInitialValues,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLModelMapping,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    setMLModel,
    addModelPerformance,
    addModelDetails,
    addMonitorDQListViewData,
    addMetrics,
    addDatasetVersions,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyDetails,
    addRecencyPreview,
    addRecentHistory,
    addDataSize,
    prefetchMonitorState,
    noIntegration
}
)(MSILogin);