import React from "react";
import {IS_NONE_CHECK} from "../../utils/constant";
import BasicPortlet from "../../components/basicPortlet";
import Load from "../../components/loadAction";
import NoErrorComponent from "./monitorNoResult";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import MpCVHorizontalSections from "./mpCVHorizontalSections";

class MpCompareViewChartGroup extends React.Component {

    renderModalChart() {
        if (IS_NONE_CHECK.includes(this.props.groupData)) {
            return "";
        }

        if (this.props.loadValue === true) {
            return (
                <div className="col-md-12">
                    <BasicPortlet
                        className="mb-0 pb-0" title="" bodyClassName="pb-0"
                        content={<Load/>}
                    />
                </div>
            );
        }

        let charts = this.props.groupData["data"];
        if (charts.length === 0) {
            return (
                <NoErrorComponent
                    message={"Model performance not computed for the selected model."}/>
            );
        }

        return charts.map((horizontalChartData, index) => {
            const combinedChart = horizontalChartData.combinedChart;
            if (IS_NONE_CHECK.includes(combinedChart)) {
                return "";
            }

            const key = combinedChart.key;

            return (
                <LazyLoadComponent key={`cv_lazy_comp_${key}_${index}`}
                                   placeholder={<Load isBootStrapColumn={true}/>}>

                    <MpCVHorizontalSections horizontalChartData={horizontalChartData}>

                    </MpCVHorizontalSections>

                </LazyLoadComponent>
            );
        });

    }

    render() {
        return (
            <div className="qd-chart-group m-0 mb-4">
                <div className="qd-chart-group-heading">
                    <h4 className="qd-chart-group-title">
                        <span className="circle-number">
                            {this.props.index}
                        </span>
                        {this.props.groupData.header}
                    </h4>
                    <p className="m-0">
                        {this.props.groupData.sub_header}
                    </p>
                </div>
                <div className="qd-chart-group-body" key={this.props.isAdvancedFilterApplied}>
                    <div className="row row-sm">
                        {this.renderModalChart()}
                    </div>
                </div>
            </div>
        );

    }
}

export default MpCompareViewChartGroup;
