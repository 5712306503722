import React from 'react';
import CopyRights from '../../components/copyrights';
import { iam_google_signup } from "../../utils/ip_details";
import { call_post_api } from "../../utils/event_handling";
import { getCompleteEndpoint, getProtocol } from "../../utils/common_utils";
import {LOGIN_SOURCE} from "../../utils/constant";

import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from "react-redux";
import { Link } from 'react-router';
import {perform_login_initial_setup} from '../login_initial_steps';

import {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setEndDate,
    setStartDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setTenantId,
    setInitialValues,
    setAttribute,
    setDataSet,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    setMonitorInitialValues,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLModelMapping,
    addMLDataSetInfo,
    addMLAttributeInfo,
    setMLModel,
    addMLModel,
    addModelPerformance, addModelDetails,
    addMonitorDQListViewData, addMetrics, addDatasetVersions,
    addUniquenessDataSetInfo, addUniquenessAttributeInfo, addRecencyDetails,
    addRecentHistory, addDataSize, addRecencyPreview, 
    prefetchMonitorState,
    storeMetadataDetails, noIntegration
} from "../../redux/actions";
import {IS_AZURE_DRX} from "../../utils/constant"

class Login extends React.Component {

    constructor(props, context) {
        super(props, context);
        let errorMsg = localStorage.getItem("error_message");
        let errors = null;
        if (errorMsg !== undefined && errorMsg !== null && errorMsg !== "undefined" && errorMsg !== 'null') {
            errors = errorMsg;
            localStorage.removeItem("error_message");
        }

        let rememailId, rempassword, remcheckbox;
        if (localStorage.getItem('rememberMe') === "" || localStorage.getItem('rememberMe') === null || localStorage.getItem('rememberMe') === false || localStorage.getItem('rememberMe') === undefined) {
            rememailId = "";
            rempassword = "";
            remcheckbox = "";
        } else {
            rememailId = localStorage.getItem("rememailId");
            rempassword = localStorage.getItem("rempassword");
            remcheckbox = localStorage.getItem("rememberMe");
        }
        this.state = {
            passwordIcon: faEye,
            passwordType: "password",
            errors: errors,
            setPasswordLink: false,
            rememailId: rememailId,
            rempassword: rempassword,
            remcheckbox: remcheckbox,
            azLoaderClass:"btn"

        };

        this.hideAlert = this.hideAlert.bind(this);
        this.msiLogin = this.msiLogin.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.login = this.login.bind(this);
        this.perform_login_initial_setup = perform_login_initial_setup.bind(this);
        this.getForgotPasswordContent = this.getForgotPasswordContent.bind(this);
    }

    hideAlert() {
        this.setState({
            errors: null,
            hideSubmitButton: false
        });
    }

    msiLogin() {
        this.setState({azLoaderClass:"btn loading-btn loading-btn--pending"})
        let login_env = process.env.REACT_APP_CUSTOM_NODE_ENV_LOGIN;
        if (login_env === "msi_login") {
            let protocol = getProtocol(iam_ip);
            let reqEndPoint = getCompleteEndpoint(protocol, iam_ip, "market_place_login");
            window.location.href = reqEndPoint
        }
    }

    getForgotPasswordContent() {
        
        return (
            <a href="/forget-password"
                onClick={this.props.setPage}
                id="forget-password"
                className="forget-password">Forgot Password?</a>
        )
    }

    disableButton() {
        this.setState({ hideSubmitButton: true });
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
            this.setState({ passwordIcon: faEyeSlash });
            this.setState({ passwordType: "text" })
        } else {
            this.setState({ passwordIcon: faEye });
            this.setState({ passwordType: "password" })
        }
    }

    login(event) {

        this.disableButton();
        let { email, password } = event;
        const { rememberMe } = this.state;
        const data = { email: email, password: password };
        call_post_api(iam_ip, "login", data).then((response) => {
            let req_details = {
                "email": email, "password": password,
                "rememberMe": rememberMe
            }

            // Following function performs required prefetches and initial setups
            this.perform_login_initial_setup(LOGIN_SOURCE.NORMAL_LOGIN_SRC,
                response, req_details);
        });
    }

    handleChange = (event) => {

        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        if (input.type === 'checkbox') {
            this.setState({ [input.name]: value });
            this.setState({ "remcheckbox": value });
        }
    };

    render() {
        const loginSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
            password: yup.string().required("Password is a required field"),
        });

        let DEPLOYED_ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV
        let LOGIN_ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV_LOGIN;
        let LOGIN_CLOUD = process.env.REACT_APP_CLOUD;
        if (DEPLOYED_ENVIRONMENT === "managed_service" && LOGIN_ENVIRONMENT !== "msi_login") {
            return (
                <div className="prelogin-content">
                    {this.props.isFirstTimeLogin ?
                        <h4 className="login-welcome-text">Thank you for email confirmation,
                            <br />Please login to your account.</h4> : <h4 className="login-welcome-text">Welcome, Please login
                            <br />to your account.</h4>
                    }
                    <div className="prelogin-form-container">
                        {this.state.errors ?
                            <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                {this.state.errors}
                                {this.state.setPasswordLink ?
                                    <a href='/initiate-set-password'
                                        show={this.state.setPasswordLink}>
                                        Click the link to set password.
                                    </a>
                                    :
                                    ''
                                }
                                <button type="button" className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert" aria-label="Close" />
                            </div> : ""
                        }

                    <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.login}
                        initialValues={{ email: this.state.rememailId, password: this.state.rempassword, rememberMe: this.state.remcheckbox }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                        <Form noValidate name="login" className="login-form needs-validation" onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-group"
                                        value={values.email}
                                        autoFocus
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        isInvalid={errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.email && errors.email }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label> Password <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.passwordType}
                                        value={values.password}
                                        placeholder="Password"
                                        className="form-group"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <div className="form-extra">
                                <Form.Group controlId="rememberMe">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id={"login-checkbox"}
                                        onChange={this.handleChange}
                                        className="custom-control custom-checkbox"
                                        label="Remember Me"
                                        name="rememberMe"
                                        checked={this.state.remcheckbox}
                                    />
                                </Form.Group>
                                {this.getForgotPasswordContent()}
                            </div>
                            <div className="form-action-btn">
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Login</button>
                                {this.state.hideSubmitButton ? <button type="submit" className="btn loading-btn loading-btn--pending"></button> : ""}
                            </div>
                        </Form>
                        )}
                    </Formik>
                    <CopyRights classDetails="text-copyright" showCopyRight='true'/>
                </div>
            </div>
        );
        } else if (LOGIN_ENVIRONMENT === "msi_login" && LOGIN_CLOUD !== "AWS") {
            return (
                <div className="prelogin-content">
                    <h4 class="login-welcome-text">Welcome Back, Please login <br />to your account.</h4>
                    <div className="azure-signin-container">
                        <div className="azure-signin-content">
                            <p>Sign in using Azure Active Directory Single Sign On. <br /><a href="https://docs.microsoft.com/azure/active-directory/">Learn more</a></p>
                            {this.state.errors ?
                            <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                {this.state.errors}
                                <button type="button" className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert" aria-label="Close" />
                            </div> : ""
                            }
                            <div className="azure-signin d-inline-block">
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn"
                                    onClick={() => this.msiLogin()}>
                                    <i className="azure-signin-icon"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="145" height="19" viewBox="0 0 145 19">
                                        <text id="Sign_in_with_Azure_AD" data-name="Sign in with Azure AD" transform="translate(0 15)" fill="#fff" font-size="14" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Sign in with Azure AD</tspan></text>
                                    </svg>
                                </button>
                            </div>
                            {this.state.hideSubmitButton ? <button type="submit" className={this.state.azLoaderClass}></button> : ""}
                            <p>Contact your site administrator to <br />request access.</p>
                        </div>
                    </div>
                    <CopyRights classDetails="text-copyright" showCopyRight='true' />
                </div>
            );
        }
        else if(LOGIN_CLOUD === "AWS"){
             return (
                <div className="prelogin-content">
                                      {this.props.isFirstTimeLogin ?
                        <h4 className="login-welcome-text">Thank you for email confirmation,
                            <br />Please login to your account.</h4> : <h4 className="login-welcome-text">Welcome, Please login
                            <br />to your account.</h4>
                    }   
                    <div className="azure-signin-content">
                            <p>Sign in using Azure Active Directory Single Sign On. <br /><a href="https://docs.microsoft.com/azure/active-directory/">Learn more</a></p>
                            <div className="azure-signin d-inline-block">
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn"
                                    onClick={() => this.msiLogin()}>
                                    <i className="azure-signin-icon"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="145" height="19" viewBox="0 0 145 19">
                                        <text id="Sign_in_with_Azure_AD" 
                                        data-name="Sign in with Azure AD" 
                                        transform="translate(0 15)" 
                                        fill="#fff" 
                                        fontSize="14" 
                                        fontFamily="SegoeUI-Bold, Segoe UI" 
                                        fontWeight="700">
                                            <tspan x="0" y="0">
                                                Sign in with Azure AD
                                            </tspan></text>
                                    </svg>
                                </button>
                            </div>
                            {this.state.hideSubmitButton ? <button type="submit" className="btn loading-btn loading-btn--pending"></button> : ""}
                            {/* <p>Contact your site administrator to <br />request access.</p> */}
                    </div>                   
                    <div className="prelogin-form-container">
                        <div className="text-divider">
                            <span>or Login with email </span>
                        </div>
                        {this.state.errors ?
                            <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                {this.state.errors}
                                {this.state.setPasswordLink ?
                                    <a href='/initiate-set-password'
                                        show={this.state.setPasswordLink}>
                                        Click the link to set password.
                                    </a>
                                    :
                                    ''
                                }
                                <button type="button" className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert" aria-label="Close" />
                            </div> : ""
                        }

                    <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.login}
                        initialValues={{ email: this.state.rememailId, password: this.state.rempassword, rememberMe: this.state.remcheckbox }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                        <Form noValidate name="login" className="login-form needs-validation" onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-group"
                                        value={values.email}
                                        autoFocus
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        isInvalid={errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.email && errors.email }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label> Password <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.passwordType}
                                        value={values.password}
                                        placeholder="Password"
                                        className="form-group"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <div className="form-extra">
                                <Form.Group controlId="rememberMe">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id={"login-checkbox"}
                                        onChange={this.handleChange}
                                        className="custom-control custom-checkbox"
                                        label="Remember Me"
                                        name="rememberMe"
                                        checked={this.state.remcheckbox}
                                    />
                                </Form.Group>
                                {
                                        IS_AZURE_DRX
                                    ?
                                        ''
                                    :
                                        this.getForgotPasswordContent()
                                }
                            
                            </div>
                            <div className="form-action-btn">                                
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Login</button>
                                {this.state.hideSubmitButton ? <button type="submit" className="btn loading-btn loading-btn--pending"></button> : ""}
                            </div>
                        </Form>
                        )}
                    </Formik>
                    <CopyRights classDetails="text-copyright" showCopyRight='true'/>
                </div>
            </div>
        );
            

        }
        else {
            return (
                <div className="prelogin-content">
                    {this.props.isFirstTimeLogin ?
                        <h4 className="login-welcome-text">Thank you for email confirmation,
                            <br />Please login to your account.</h4> : <h4 className="login-welcome-text">Welcome, Please login
                            <br />to your account.</h4>
                    }
                    <div className="google-signin">
                        <a href={window.location.protocol + "//" + iam_google_signup + "/google_signup"}>
                            <div className="abcRioButton abcRioButtonBlue">
                                <div className="abcRioButtonContentWrapper">
                                    <div className="abcRioButtonIcon">
                                        <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                                            <svg version="1.1" xmlns="https://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48"
                                                className="abcRioButtonSvg">
                                                <g>
                                                    <path fill="#EA4335"
                                                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                                                    </path>
                                                    <path fill="#4285F4"
                                                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                                                    </path>
                                                    <path fill="#FBBC05"
                                                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                                                    </path>
                                                    <path fill="#34A853"
                                                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                                                    </path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: "16px", lineHeight: "48px" }} className="abcRioButtonContents">
                                        <span id="not_signed_inkisvxreeku2m">Log in with Google</span>
                                        <span id="connectedkisvxreeku2m" style={{ display: "none" }}>Signed in with Google</span>
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="prelogin-form-container">
                        <div className="text-divider">
                            <span>or Login with email </span>
                        </div>
                        {this.state.errors ?
                            <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                {this.state.errors}
                                {this.state.setPasswordLink ?
                                    <a href='/initiate-set-password'
                                        show={this.state.setPasswordLink}>
                                        Click the link to set password.
                                    </a>
                                    :
                                    ''
                                }
                                <button type="button" className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert" aria-label="Close" />
                            </div> : ""
                        }

                    <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.login}
                        initialValues={{ email: this.state.rememailId, password: this.state.rempassword, rememberMe: this.state.remcheckbox }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                        <Form noValidate name="login" className="login-form needs-validation" onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-group"
                                        value={values.email}
                                        autoFocus
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        isInvalid={errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.email && errors.email }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label> Password <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.passwordType}
                                        value={values.password}
                                        placeholder="Password"
                                        className="form-group"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <div className="form-extra">
                                <Form.Group controlId="rememberMe">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id={"login-checkbox"}
                                        onChange={this.handleChange}
                                        className="custom-control custom-checkbox"
                                        label="Remember Me"
                                        name="rememberMe"
                                        checked={this.state.remcheckbox}
                                    />
                                </Form.Group>
                                {
                                        IS_AZURE_DRX
                                    ?
                                        ''
                                    :
                                        this.getForgotPasswordContent()
                                }
                            
                            </div>
                            <div className="form-action-btn">
                                <Link to="/signup"
                                      className="btn btn-circle btn-outline-secondary"
                                      onlyActiveOnIndex>Sign Up
                                </Link>
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Login</button>
                                {this.state.hideSubmitButton ? <button type="submit" className="btn loading-btn loading-btn--pending"></button> : ""}
                            </div>
                        </Form>
                        )}
                    </Formik>
                    <CopyRights classDetails="text-copyright" showCopyRight='true'/>
                </div>
            </div>
        );
        }
    }

}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setStartDate,
    setEndDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setTenantId,
    setInitialValues,
    setDataSet,
    setAttribute,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    setMonitorInitialValues,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLModelMapping,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    setMLModel,
    addModelPerformance,
    addModelDetails,
    addMonitorDQListViewData,
    addMetrics,
    addDatasetVersions,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyDetails,
    addRecencyPreview,
    addRecentHistory,
    addDataSize,
    prefetchMonitorState,
    storeMetadataDetails,
    noIntegration
}
)(Login);