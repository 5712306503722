import {
    ADD_DATA_SET_ATTRIBUTE_MAPPING,
    ADD_DATA_SOURCE,
    ADD_DATA_SOURCE_DATA_SET_MAPPING,
    ADD_DATASET_VERSIONS,
    ADD_DISCOVERY_STATUS,
    ADD_ENVIRONMENT,
    ADD_ENVIRONMENT_DATA_SOURCE_MAPPING,
    ADD_INFO,
    ADD_LAST_PROFILING_TIME,
    ADD_METADATA,
    ADD_ML_RELATIONSHIP,
    ADD_PROCESSING_STATUS,
    ADD_RECENCY_DETAILS,
    ADD_RELATIONSHIP,
    ADD_RECENT_HISTORY,
    ADD_DATA_SIZE,
    MARK_NOTIFICATION_AS_READ,
    REMOVE_NOTIFICATION_FROM_LIST,
    SET_ATTRIBUTE,
    SET_CHIP_DATA,
    SET_DATA_SET,
    SET_DATA_SOURCE,
    SET_END_DATE,
    SET_ENVIRONMENT,
    SET_FILTERED_DATA,
    SET_INITIAL_VALUES,
    SET_ML_CHIP_DATA,
    SET_ML_DATA_SOURCE,
    SET_ML_MODEL,
    SET_MODEL_AB_LIST,
    SET_START_DATE,
    SET_TENANT_ID,
    SET_TIME_FILTER,
    USER_NOTIFICATIONS,
    STORE_IP_DATA,
    ADD_METADATA_DETAILS,
    NO_INTEGRATION,
    FORM_DATASET_INFO,
    ADD_DATASET_LAST_REFRESH,
    ADD_DATASOURCE_DISCOVER_PAGE
} from "../actionTypes";
import moment from 'moment';
import {IS_NONE_CHECK} from "../../utils/constant";


const initialState = {
    relationship: {},
    dataset_relationship: {},    
    metaData: {},
    info: {},
    selectedDataSource: -1,
    selectedMlDataSource: -1,
    selectedEnvironment: -1,
    integrations: {},
    lastProfilingTime: {},
    environments:{},
    environmentDataSourceMapping: {},
    dataSourceDataSetMapping: {},
    dataSetAttributeMapping: {},
    dataSets:{},
    columns:{},
    chipData: [],
    mlChipData: [],
    processingStatus: {},
    discoveryStatus: {},
    filteredValues: [],
    tenantId: -1,
    selectedDataSetId: -1,
    selectedAttributeName: "",
    selectedAttributeID: "",
    selectedMLModel: -1,
    mlRelationship: {},
    modelABData: [],
    modelInfoMap: {},
    modelABDataLoaded: false,
    startDate: moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00'),
    endDate: moment().format('YYYY-MM-DD 23:59:59'),
    timeFilterApplied: false,
    userNotificationData: {
        page: 0,
        loaded: false,
        unreadNotifications: [],
        allNotifications: [],
        completeLoadInitiated: false
    },
    datasetVersion: {},
    recencyDetails: {},
    recentHistory: [],
    dataSize: [],
    metadataDetails: {},
    preFetchMetaData: false,
    prefetchDatasetDetails: false,
    prefetchRecencyDetails: false,
    prefetchRecentHistory: false,
    prefetchDataSize: false,
    prefetchModelABData: false,
    preFetchUserNotifications: false,
    preFetchMetadataDetails: false,
    ipData:null,
    noIntegrationStatus:null,
    mappedDatasetInfo:{},
    discoverPageDataSource: {},
    datasetLastProcessInfoOver: false,
    datasetLastProcessInfo: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_VALUES: {
            return initialState;
        }
        case ADD_METADATA: {
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    ...action.payload.content
                },
                preFetchMetaData:true

            };
        }
        case ADD_RELATIONSHIP: {
            let attribute_data = [];
            let dataset_data = [];
            let payload = action.payload.data;            
            if( Array.isArray(payload)){                
                attribute_data = payload;
            }
            else if(!IS_NONE_CHECK.includes(payload) && typeof payload === "object"){
                attribute_data = payload.attribute;
                dataset_data = payload.dataset;
                if (IS_NONE_CHECK.includes(attribute_data)){
                    attribute_data = [];
                }
                if (IS_NONE_CHECK.includes(dataset_data)){
                    dataset_data = [];
                }                
            }                        
            return {
                ...state,
                relationship: {
                    ...state.relationship,
                    [action.payload.dataSource]: attribute_data
                },
                dataset_relationship: {
                    ...state.dataset_relationship,
                    [action.payload.dataSource]: dataset_data
                }
            };
        }
        case SET_DATA_SOURCE: {
            return {
                ...state,
                selectedDataSource: action.payload.dataSource
            }
        }
        case SET_ML_DATA_SOURCE: {
            return {
                ...state,
                selectedMlDataSource: action.payload.mlDataSource
            }
        }
        case ADD_ML_RELATIONSHIP: {
            return {
                ...state,
                mlRelationship: {
                    ...state.mlRelationship,
                    [action.payload.mlModel]: action.payload.data
                }
            };
        }
        case SET_ML_MODEL: {
            return {
                ...state,
                selectedMLModel: action.payload.mlModel
            }
        }
        case SET_TENANT_ID: {
            return {
                ...state,
                tenantId: action.payload.tenantId
            }
        }
        case SET_DATA_SET: {
            return {
                ...state,
                selectedDataSetId: action.payload.dataSet
            }
        }
        case SET_CHIP_DATA: {
            return {
                ...state,
                chipData: action.payload.chipData
            }
        }
        case SET_ML_CHIP_DATA: {
            return {
                ...state,
                mlChipData: action.payload.mlChipData
            }
        }
        case SET_FILTERED_DATA: {
            return {
                ...state,
                filteredValues: action.payload.filteredValues
            }
        }
        case SET_START_DATE: {
            return {
                ...state,
                startDate: action.payload.startDate
            }
        }
        case SET_END_DATE: {
            return {
                ...state,
                endDate: action.payload.endDate
            }
        }
        case SET_TIME_FILTER: {
            return {
                ...state,
                timeFilterApplied: action.payload.timeFilterApplied
            }
        }
        case SET_ATTRIBUTE: {
            return {
                ...state,
                selectedAttributeName: action.payload.attributeName,
                selectedAttributeID: action.payload.attributeId
            }
        }
        case SET_ENVIRONMENT: {
            return {
                ...state,
                selectedEnvironment: action.payload.environment
            }
        }
        case ADD_DATA_SOURCE: {
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    [action.payload.dataSourceId]: action.payload.dataSourceName
                }
            }
        }
        case ADD_INFO: {
            return {
                ...state,
                info: {
                    ...state.info,
                    ...action.payload.data
                }
            }
        }
        case ADD_DISCOVERY_STATUS: {
            return {
                ...state,
                discoveryStatus: {
                    ...state.discoveryStatus,
                    [action.payload.dataSourceId]: action.payload.discoveryStatus
                }
            }
        }
        case ADD_PROCESSING_STATUS: {
            return {
                ...state,
                processingStatus: {
                    ...state.processingStatus,
                    [action.payload.dataSourceId]: action.payload.processingStatus
                }
            }
        }
        case ADD_LAST_PROFILING_TIME: {
            return {
                ...state,
                lastProfilingTime: {
                    ...state.lastProfilingTime,
                    [action.payload.dataSourceId]: action.payload.lastProfilingTime
                }
            }
        }
        case ADD_ENVIRONMENT: {
            return {
                ...state,
                environments: {
                    ...state.environments,
                    ...action.payload.data
                }
            }
        }
        case ADD_ENVIRONMENT_DATA_SOURCE_MAPPING: {
            return {
                ...state,
                environmentDataSourceMapping: {
                    ...state.environmentDataSourceMapping,
                    ...action.payload.mapping
                }
            }
        }
        case ADD_DATA_SOURCE_DATA_SET_MAPPING: {
            return {
                ...state,
                dataSourceDataSetMapping: {
                    ...state.dataSourceDataSetMapping,
                    ...action.payload.mapping
                }
            }
        }
        case ADD_DATA_SET_ATTRIBUTE_MAPPING: {
            return {
                ...state,
                dataSetAttributeMapping: {
                    ...state.dataSetAttributeMapping,
                    ...action.payload.mapping
                }
            }
        }

        case USER_NOTIFICATIONS: {
            let userNotificationResult = action.payload.userNotificationData;
            const isReset = userNotificationResult.resetData;
            let notificationInState = state.userNotificationData;

            notificationInState.loaded = true;
            notificationInState.page = userNotificationResult.page;

            // We will reset the data during first time clicking if View All notifications
            // is clicked for the first time
            if (isReset === true) {
                notificationInState.allNotifications = userNotificationResult.user_notification;
                notificationInState.unreadNotifications = userNotificationResult.unread_user_notification;
                notificationInState.completeLoadInitiated = true;
                return {
                    ...state,
                    userNotificationData: notificationInState,
                    preFetchUserNotifications: true
                };
            }

            // Append the results to existing data
            const newUnreadNotifications = userNotificationResult.unread_user_notification;
            if (newUnreadNotifications !== undefined && newUnreadNotifications !== null &&
                newUnreadNotifications.length > 0) {
                // Merge incoming unread items to existing list
                notificationInState.unreadNotifications.push(...newUnreadNotifications);
            }

            const newAllNotifications = userNotificationResult.user_notification;
            if (newAllNotifications !== undefined && newAllNotifications !== null &&
                newAllNotifications.length > 0) {
                // Merge incoming notification items to existing list
                notificationInState.allNotifications.push(...newAllNotifications);
            }

            return {
                ...state,
                userNotificationData: notificationInState,
                preFetchUserNotifications: true
            };
        }

        case MARK_NOTIFICATION_AS_READ: {
            let userNotificationID = action.payload.notificationID;

            let notificationInState = state.userNotificationData;
            const currUnreadNotifications = notificationInState.unreadNotifications;

            // Remove the given notification from unread list. User has clicked it,
            // so this will be removed from unread list
            const newUnreadNotifications = currUnreadNotifications.filter(x=>x.notification_id !== userNotificationID);
            notificationInState.unreadNotifications = newUnreadNotifications;

            // Mark it as read notification
            const currAllNotifications = notificationInState.allNotifications;
            const reqNotification = currAllNotifications.find(x=>x.notification_id === userNotificationID);
            if (!IS_NONE_CHECK.includes(reqNotification)) {
                reqNotification["read_status"] = true;
            }

            return {
                ...state,
                userNotificationData: notificationInState
            };
        }

        case REMOVE_NOTIFICATION_FROM_LIST: {
            let userNotificationID = action.payload.notificationID;

            let notificationInState = state.userNotificationData;
            const currUnreadNotifications = notificationInState.unreadNotifications;
            const currAllNotifications = notificationInState.allNotifications;

            // Remove the selected notification from all the lists
            const newUnreadNotifications = currUnreadNotifications.filter(x=>x.notification_id !== userNotificationID);
            const newAllNotifications = currAllNotifications.filter(x=>x.notification_id !== userNotificationID);

            notificationInState.unreadNotifications = newUnreadNotifications;
            notificationInState.allNotifications = newAllNotifications;

            return {
                ...state,
                userNotificationData: notificationInState
            };
        }

        case ADD_DATASET_VERSIONS: {
            return {
                ...state,
                datasetVersion: {
                    ...state.datasetVersion,
                    ...action.payload.datasetVersion
                },
                prefetchDatasetDetails: true
            }
        }

        case ADD_RECENCY_DETAILS: {
            return {
                ...state,
                recencyDetails: {
                    ...state.recencyDetails,
                    ...action.payload.recencyDetails
                },
                prefetchRecencyDetails: true
            }
        }
        case ADD_RECENT_HISTORY:
            {
            return {
                ...state,
                recentHistory: action.payload.recentHistory,
                prefetchRecentHistory: true
            }
        }
        case ADD_DATA_SIZE:
            {
            return {
                ...state,
                dataSize: action.payload.dataSize,
                prefetchDataSize: true
            }
        }
        case ADD_METADATA_DETAILS:
            {
            return {
                ...state,
                metadataDetails: action.payload.metadataDetails,
                preFetchMetadataDetails: true
            }
        }

        case SET_MODEL_AB_LIST: {
            const updated_state = {
                ...state,
                modelABDataLoaded: true,
                modelABData: action.payload.modelABList,
                prefetchModelABData: true
            };

            let modelInfoMap = action.payload.model_info_map;
            if (modelInfoMap === undefined || modelInfoMap === null) {
                modelInfoMap = {};
            }

            // Combine existing map info and new map info
            const stateModelInfoMap = updated_state["modelInfoMap"];
            updated_state["modelInfoMap"] = Object.assign({}, stateModelInfoMap, modelInfoMap);
            return updated_state;
        }

        case STORE_IP_DATA: {

            return {
                ...state,
                ipData: {
                    ...state.ipData,
                    ...action.payload.ipData
                }
            }
        }

        case NO_INTEGRATION: {
            return{
                ...state,
                noIntegrationStatus:action.payload.data
            }
        }
        case FORM_DATASET_INFO:
            return {
                ...state,
                mappedDatasetInfo: action.payload.data,
        }

        case ADD_DATASET_LAST_REFRESH:
            return {
                ...state,
                datasetLastProcessInfo: action.payload.lastRefreshOfDatasets,
                datasetLastProcessInfoOver: true
        }

        case ADD_DATASOURCE_DISCOVER_PAGE:
            let requiredRes = {};
            let incomingData = action.payload.discoverPageData;

            for (let currIntData of incomingData){
                let currSource = currIntData.integration_id;
                let currPageData = currIntData.page_data;
                if (IS_NONE_CHECK.includes(currPageData)) {
                    currPageData = {};
                }
                
                let chartContent = currPageData.chart_content;
                if (IS_NONE_CHECK.includes(chartContent)) {
                    chartContent = {}
                }
                requiredRes[currSource] = chartContent;
            }

            return {
                ...state,
                discoverPageDataSource: requiredRes
        }
        
        default:
            return state;
        }
}
