import React from "react";
import Image from "../image";
import Table from "react-bootstrap/Table";
import ListGroup from 'react-bootstrap/ListGroup'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {QualdoPlanCycle, QualdoPlanVersions, QUALDO_PAYMENT_STATUS, PAID_PLANS} from "../../utils/constant";
import {submitUsersPayment} from "../../utils/event_handling";
import {isUserOnBasicPlan, setBasicPlanInLocalStorage, getCurrentPlanDetailOfUser} from "../../utils/common_utils";


class QualdoPlanComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.subscribeToFreePlan = this.subscribeToFreePlan.bind(this);
        this.submitUsersPayment = submitUsersPayment.bind(this);

        this.state = {
            free: this.props.details.free,
            starter: this.props.details.starter,
            professional: this.props.details.professional,
        }
    }


    subscribeToFreePlan(planType) {
        let email = localStorage.getItem("emailId");
        let userName = localStorage.getItem('user_name');

        let finalData = {
            "billing_cycle": QualdoPlanCycle.MONTHLY,
            "plan_type": planType,
            "email": email,
            "user_name": userName
        }

        this.submitUsersPayment(finalData, this.props.setPaymentStatus, this.props.showToast);
        setBasicPlanInLocalStorage();
    }

    render() {
        let planDetails = getCurrentPlanDetailOfUser();
        let paymentDue = false;
        let freePlan = true;
        let starterPlan = true;
        let professionalPlan = true;
        let hidePlanCount = 0;        
        let freeClass = 'default';
        let starterClass = '';
        let professionalClass = '';
        let planClass = ''
        if (planDetails !== undefined && planDetails !== null) {
           if (planDetails["payment_status"] === QUALDO_PAYMENT_STATUS.PAYMENT_PENDING){
                paymentDue = true;
           }
        }

        if (this.state.free === undefined) {
            freePlan = false;            
            starterClass = 'default'
            hidePlanCount = hidePlanCount + 1;
        }
        if (this.state.starter === undefined) {
            starterPlan = false;
            professionalClass = 'default'
            hidePlanCount = hidePlanCount + 1;

        }
        if (this.state.professional === undefined) {
            professionalPlan = false;            
            hidePlanCount = hidePlanCount + 1;
        }

        if (hidePlanCount === 1){
            planClass = 'compare-col-price';            
        }
        if (hidePlanCount === 2){
            planClass = 'single-col-price';
        }
        

        let paidPlan = false;
        if (PAID_PLANS.includes(planDetails["plan_name"])) {
            paidPlan = true;
        }

        let basicPlanUser = isUserOnBasicPlan();

        let freePlanTitle = "Monitoring ML-Model Performance";
        if (basicPlanUser) {
            freePlanTitle = "Already in Basic Plan";
        }

        return (
            <div className={"pricing-table " + planClass}>
                {/* <ul>
                    <li className="active">
                        <div className="price_item">
                            <Image className="price_icon"
                                   src="pricingIcon_free"
                                   alt="Machine Learning Performance"/>
                            <h5 className="price_title">{`${this.state.free.title}`}</h5>
                        </div>
                    </li>
                    <li>
                        <div className="price_item">
                            <Image className="price_icon"
                                   src="pricingIcon_starter"
                                   alt="Machine Learning Monitoring Tools"/>
                            <h5 className="price_title">{`${this.state.starter.title}`}</h5>
                        </div>
                    </li>
                    <li>
                        <div className="price_item">
                            <Image className="price_icon" src="pricingIcon_professional" alt="ML Monitoring tools"/>
                            <h5 className="price_title">{`${this.state.professional.title}`}</h5>
                        </div>
                    </li>
                </ul> */}


                <ListGroup as="ul" horizontal>
                    {freePlan && hidePlanCount !==2?
                    <ListGroup.Item as="li" active>
                        <div className="price_item">
                            <Image className="price_icon" src="pricingIcon_free" alt="Machine Learning Performance"/>
                            <h5 className="price_title">{`${this.state.free.title}`}</h5>
                        </div>
                    </ListGroup.Item>
                    :''}
                    {starterPlan && hidePlanCount !==2?
                    <ListGroup.Item as="li">
                        <div className="price_item">
                            <Image className="price_icon" src="pricingIcon_starter" alt="Machine Learning Monitoring Tools"/>
                            <h5 className="price_title">{`${this.state.starter.title}`}</h5>
                        </div>
                    </ListGroup.Item>
                    :''}
                    {professionalPlan && hidePlanCount !==2?
                    <ListGroup.Item as="li">
                        <div className="price_item">
                            <Image className="price_icon" src="pricingIcon_professional" alt="ML Monitoring tools"/>
                            <h5 className="price_title">{`${this.state.professional.title}`}</h5>
                        </div>
                    </ListGroup.Item>
                    :''}
                </ListGroup>

                <Table>
                    <tbody>
                    <tr className="price_table_header">
                        <td className="hide"/>
                        {freePlan?
                        <td className={freeClass+" no-border"}>
                            <div className="price_item">
                                <Image className="price_icon"
                                       src="pricingIcon_free"
                                       alt="Machine Learning Performance"/>
                                <h5 className="price_title">{`${this.state.free.title}`}</h5>
                            </div>
                        </td>
                        : ''}
                        {starterPlan?
                        <td className={starterClass+" no-border"}>
                            <div className="price_item">
                                <Image className="price_icon"
                                       src="pricingIcon_starter"
                                       alt="Machine Learning Monitoring Tools"/>
                                <h5 className="price_title">{`${this.state.starter.title}`}</h5>
                            </div>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass+" no-border"}>
                            <div className="price_item">
                                <Image className="price_icon"
                                       src="pricingIcon_professional"
                                       alt="ML Monitoring tools"/>
                                <h5 className="price_title">{`${this.state.professional.title}`}</h5>
                            </div>
                        </td>
                       :''} 
                    </tr>
                    <tr>
                        <td/>
                        {freePlan?
                        <td className={freeClass}>
                            <div className="price">
                                {`${this.state.free.details.currency}${this.state.free.price}`}<sub>/ mo</sub>
                            </div>
                        </td>
                        : ''}
                        {starterPlan?
                        <td className={starterClass}>
                            <div className="price">
                                {`${this.state.starter.details.currency}${this.state.starter.price}`}<sub>/ mo</sub>
                            </div>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <div className="price">
                                {`${this.state.professional.details.currency}${this.state.professional.price}`}<sub>/ mo</sub>
                            </div>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td>Data Size</td>
                        {freePlan?
                        <td className={freeClass}>
                            Upto {`${this.state.free.details.data_size}`} GB
                        </td>
                        : ''}
                        {starterPlan?
                        <td className={starterClass}>
                            Upto {`${this.state.starter.details.data_size}`} GB
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            Upto {`${this.state.professional.details.data_size}`} GB
                        </td>
                        :''}
                    </tr>
                    {this.props.hideModelInfo ? "" : <tr>
                        <td>
                            ML Models
                        </td>
                        {freePlan?
                        <td className={freeClass}>
                            Includes {`${this.state.free.details.ml_models_size}`}
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            Includes {`${this.state.starter.details.ml_models_size}`}
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            Includes {`${this.state.professional.details.ml_models_size}`}
                        </td>
                        :''}
                    </tr>}
                    {this.props.hideModelInfo ?
                    <tr>
                        <td>Additional data</td>
                        {freePlan?                        
                        <td className={freeClass}/>
                        :''}
                        {starterPlan?
                        <td className={starterClass}> $52 per 100GB</td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}> $52 per 100GB</td>
                        :''}
                    </tr>
                    :
                    <tr>
                        <td>Additional Models &<br/>Additional data</td>
                        {freePlan?                        
                        <td className={freeClass}/>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>$9 per model, <br/> $52 per 100GB</td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>$9 per model, <br/>$52 per 100GB</td>
                        :''}
                    </tr>}
                    {this.props.hideModelInfo ? "" : <tr>
                        <td>ML Monitoring Metrics</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>}
                    <tr>
                        <td>DQ Metrics</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td>Alerts</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td>Notifications</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    {/*<tr>
                        <td>APIs</td>
                        <td className="default"/>
                        <td>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        <td>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                    </tr>*/}
                    <tr>
                        <td>Visualizations</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td>Custom Metrics</td>
                        {freePlan?
                        <td className={freeClass}/>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td><strong>Service &amp; Support</strong></td>
                        {freePlan?
                        <td className={freeClass+" no-border"}/>
                        :''}
                        {starterPlan?
                        <td className={starterClass+" no-border"}/>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass+" no-border"}/>
                        :''}
                    </tr>
                    <tr>
                        <td>Email</td>
                        {freePlan?
                        <td className={freeClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {starterPlan?
                        <td className={starterClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                        <td>Chat</td>
                        {freePlan?
                        <td className={freeClass+" no-border"}/>
                        :''}
                        {starterPlan?
                        <td className={starterClass+" no-border"}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass+" no-border"}>
                            <i><FontAwesomeIcon icon={faCheck}/></i>
                        </td>
                        :''}
                    </tr>
                    <tr>
                    <td/>
                        {
                        paymentDue === false && paidPlan === false && freePlan === true?
                        <td className={freeClass+" no-border"}>
                            <button onClick={this.subscribeToFreePlan.bind(this, QualdoPlanVersions.FREE)}
                                    className="price_btn price_btn_fill"
                                    disabled={basicPlanUser}
                                    title={freePlanTitle}>
                                Subscribe
                            </button>
                        </td>
                        :freePlan?
                        <td className={freeClass+" no-border"}>
                            <button onClick={()=>this.props.navigateToPaymentPage(QualdoPlanVersions.FREE)}
                                    className="price_btn price_btn_fill"
                                    disabled={basicPlanUser}
                                    title={freePlanTitle}>
                                Subscribe
                            </button>
                        </td>
                        :''
                        }
                        {starterPlan?
                        <td className={starterClass+" no-border"}>
                            <button onClick={() =>this.props.navigateToPaymentPage(QualdoPlanVersions.STARTER)}
                                    className="price_btn price_btn_fill"
                                    title="Monitoring ML-Model Performance">
                                Subscribe & Pay
                            </button>
                        </td>
                        :''}
                        {professionalPlan?
                        <td className={professionalClass+" no-border"}>
                            <button onClick={() => this.props.navigateToPaymentPage(QualdoPlanVersions.PROFESSIONAL)}
                                    className="price_btn price_btn_fill"
                                    title="Monitoring ML-Model Performance">
                                Subscribe & Pay
                            </button>
                        </td>
                        :''}
                    </tr>
                    </tbody>
                </Table>
            </div>);
    }
}

export default QualdoPlanComponent;
