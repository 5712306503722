import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { NO_ERROR_COLOR_CLASS_MAP } from "../../utils/constant";
import { getShortTitleForAttribute } from "../../utils/common_utils";
import { FQ_TOOLTIP_PLACEHOLDER } from "../../utils/attribute_name_utils";
import RcTooltip from 'rc-tooltip';


const renderTitleToolTip = (props) => (
    <span {...props}>
        {props}
    </span>
);


class StatsAttributeName extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.renderAttributeName = this.renderAttributeName.bind(this);
    }

    renderAttributeName() {
        let requiredAttributeName = this.props.attributeName;
        let propsColor = this.props.color;
        let reqClassName;
        if (propsColor === null || propsColor === undefined) {
            reqClassName = "f-16 lh-20 text-danger";
        } else {
            reqClassName = NO_ERROR_COLOR_CLASS_MAP[propsColor];
        }

        let shortenedData = getShortTitleForAttribute(requiredAttributeName);
        let requiredName = shortenedData["requiredName"];
        let fqTooltipContent = shortenedData["fqTooltipContent"];

        if (fqTooltipContent === null) {
            // Render without tooltip
            return (
                <span className={reqClassName}> {requiredAttributeName} </span>
            );
        }

        // Render with tooltip
        return (
            <>
                <RcTooltip 
                    placement="top"
                    overlay={renderTitleToolTip(fqTooltipContent)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <span className={reqClassName}> {FQ_TOOLTIP_PLACEHOLDER} </span>
                </RcTooltip>
                
                <span className={reqClassName}> {requiredName} </span>
            </>



        );

    }

    render() {
        return (
            <p>
                <i><FontAwesomeIcon icon={faTag} /></i>
                <span>
                    <label>Attribute Name: </label>
                    {this.renderAttributeName()}
                </span>
            </p>
        );
    }
}

export default StatsAttributeName;