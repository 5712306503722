// The following constant should be in sync with following 3 places.
// qualdo\integrations\connector_interfaces\utils\flatten_dataframe_utils.py (QUALDO_NAME_SEPARATOR)
// qualdo\qualdo_utils\constants.py (QUALDO_ATTRIBUTE_SEPARATOR)
// qualdo/compute_engine/compute_measure/estimators/constants.py (QUALDO_ATTRIBUTE_SEPARATOR)
export const QUALDO_ATTRIBUTE_SEPARATOR = "_qld_";

// The following constant should be in sync with following place.
// qualdo\qualdo_utils\constants.py (STRUCT_SEPARATOR)
export const STRUCT_SEPARATOR = " > ";

// This below two constants should be in sync with following place.
// qualdo\qualdo_utils\constants.py (QUALDO_CHILD_DATASET_SEPARATOR, DATASET_SEPARATOR)
export const QUALDO_CHILD_DATASET_SEPARATOR = "_qldds_"

export const DATASET_SEPARATOR = "$"

export const FQ_TOOLTIP_PLACEHOLDER = "[..] ";

export function normalizeAttributeName(attribute_name) {
    if (attribute_name === null || attribute_name === undefined) {
        return attribute_name;
    }

    return attribute_name.replaceAll(QUALDO_ATTRIBUTE_SEPARATOR, STRUCT_SEPARATOR);
}

export function normalizeChildDatasetName(dataset_name) {
    if (dataset_name === null || dataset_name === undefined) {
        return dataset_name;
    }

    return dataset_name.replaceAll(QUALDO_CHILD_DATASET_SEPARATOR, DATASET_SEPARATOR);
}

// Following is used as separator for forming metadata mapped ids.
// This should be changed in future because when attribute name/dataset name have
// dot in them, we will face issues.
// The following constant should be in sync with 
// qualdo/qualdo_utils/constants.py (ATTRIBUTE_MAP_VALUE_SEPARATOR)
 export const METADATA_MAP_VALUE_SEPARATOR = "#@qld@#";
//export const METADATA_MAP_VALUE_SEPARATOR = ".";

// Following is used as separator for forming metadata mapped ids.
// This can be kept as dot since we are just combining ids
export const METADATA_MAP_KEY_SEPARATOR = ".";

export function attributeNameFromMappedValue(mappedValue){
    /**
     * Parse the string and returns the attribute name
     * Currently we are using dot as separator.
     * Like forming(env_name.data_source_name.data_set_name.attribute_name)
     * Refer API: metadata/mapping/latest response.
     * The usage of dot should be changed to some other combination in future.
     */
    if (mappedValue === undefined) {
        return "";
    }

    let splitValues = mappedValue.split(METADATA_MAP_VALUE_SEPARATOR);
    
    // Attribute name is the last part of the string. So we are fetching the last index
    return splitValues[splitValues.length - 1];
}

export function datasetNameFromMappedValue(mappedValue){
    /**
     * Parse the string and returns the dataset name
     * Currently we are using dot as separator.
     * Like forming(env_name.data_source_name.data_set_name.attribute_name)
     * Refer API: metadata/mapping/latest response.
     * The usage of dot should be changed to some other combination in future.
     */
    if (mappedValue === undefined) {
        return "";
    }
    
    let splitValues = mappedValue.split(METADATA_MAP_VALUE_SEPARATOR);
    
    // Dataset name is the 3rd part of the string. So we are fetching the 2nd index
    return splitValues[2];
}

export function attributeIDFromMappedValue(mappedKey){
    /**
     * Parse the string and returns the attribute id
     * Currently we are using dot as separator.
     * Like forming(env_id.data_source_id.data_set_id.attribute_id)
     * Refer API: metadata/mapping/latest response.
     */
    let splitValues = mappedKey.split(METADATA_MAP_KEY_SEPARATOR);
    
    // Attribute id is the last part of the string. So we are fetching the last index
    return splitValues[splitValues.length - 1];
}


export function datasetIDFromMappedValue(mappedKey){
    /**
     * Parse the string and returns the data set id
     * Currently we are using dot as separator.
     * Like forming(env_id.data_source_id.data_set_id.attribute_id)
     * Refer API: metadata/mapping/latest response.
     */
    let splitValues = mappedKey.split(METADATA_MAP_KEY_SEPARATOR);
    
    // Dataset id is the third part of the string. So we are fetching the 2nd index
    return splitValues[2];
}



export function isChildDataset(datasetName){
    /*
     * Based on the given dataset name, this identifies whether dataset is a 
     * child dataset or normal dataset
     **/
    if (datasetName === undefined || datasetName === null) {
        return false;
    }

    return datasetName.includes(QUALDO_CHILD_DATASET_SEPARATOR);
}
