import React from 'react';
import {findQuantileForProgress }  from  "../../utils/User_handling";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck, faLevelUpAlt, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import Load from '../../components/loadAction';
import UpgradeComponent from "../../components/planComponents/upgradeComponent";
import {DATA_LIMIT_EXCEED, BUTTON_NAMES} from "../../utils/constant";
import PlanHeaderInfo from "./planHeaderInfo";
import PlanChangeRequest from "./planChangeRequest"
import {browserHistory} from 'react-router';
import {parseDateAsUTC, formatFutureTime, getCurrentUTCDateTime, getQualdoPlanCycle} from "../../utils/common_utils";
import {deletePlanChangeRequest, getApi, getPaymentDetailsTableData} from  "../../utils/event_handling";
import Modal from "react-bootstrap/Modal";
import PlanChangeNotAllowed from "../../components/paymentComponents/planChangeNotAllowed";


const goToChoosePlan = () => {
    window.location.href = "/choose-plan";
}

class PlanAndUsage extends React.Component {
    constructor(props){
        super(props);
        this.getApi = getApi.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setFormState = this.setFormState.bind(this);
        this.showDataLimitExceedModal = this.showDataLimitExceedModal.bind(this);
        this.hideDataLimitExceedModal = this.hideDataLimitExceedModal.bind(this);
        this.increaseDataLimitPlan = this.increaseDataLimitPlan.bind(this);
        this.showCancelPlanChangeRequest = this.showCancelPlanChangeRequest.bind(this);
        this.closeCancelPlanChangeRequest = this.closeCancelPlanChangeRequest.bind(this);
        this.deletePlanChangeRequest = deletePlanChangeRequest.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.getQualdoPlanCycle = getQualdoPlanCycle.bind(this);
        this.changePaymentPageDetails = this.changePaymentPageDetails.bind(this);
        this.state = {
            error_message: null,
            srcIsChoosePlan: false,
            showDataLimitExceedModal: false,
            planDetails: this.props.planDetails,
            cancelPlanChangeRequest: false,
            change_request_id: null,
        };
    }

    changePaymentPageDetails(data){
        let planType = data["plan_details"]["plan_name"];
        data["hideModelInfo"] = this.props.hideModelInfo
        localStorage.setItem("qualdoPlan", planType);
        browserHistory.push({pathname:'/payment', state: data})
    }

    componentDidMount() {
        let usageProgress = document.getElementById('usageProgress');
        let mlModelProgress = document.getElementById('mlModelProgress');
        if( usageProgress !== null && mlModelProgress !== null) {
             let planDetails = this.state.planDetails;
             let progressUsage=findQuantileForProgress(planDetails.progressbar,planDetails.maxDataLimit);
             let progressMlModel=findQuantileForProgress(planDetails.mlModelCount,planDetails.maxMlModelLimit);
             usageProgress.setAttribute('class','progress-bar w-'+progressUsage);
             mlModelProgress.setAttribute('class','progress-bar w-'+progressMlModel);
        }
    }


    showErrorMessage(message){
        this.setState({"error_message": message});
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    setFormState(value) {
        this.setState ({form: value});
    }


    getResetTimer(planEndDate) {
        let parsedEndDate = parseDateAsUTC(planEndDate);
        let date = parsedEndDate.getDate();

        // We are adding a date because, 'reset on' date will be the next day of current cycle's end date
        parsedEndDate.setDate(date + 1);
        parsedEndDate.setHours(0);
        parsedEndDate.setMinutes(0);
        parsedEndDate.setSeconds(0);
        parsedEndDate.setMilliseconds(0);
        return formatFutureTime(parsedEndDate);
    }

    showDataLimitExceedModal() {
        this.setState({showDataLimitExceedModal: true})
    }

    redirectToPaymentPage() {
        localStorage.removeItem("src_choose_plan");
        this.getApi("get_payment_page_details");
        //browserHistory.push("/payment");
    }


    hideDataLimitExceedModal() {
        this.setState({showDataLimitExceedModal: false})
    }

    increaseDataLimitPlan() {
        this.props.increaseDataLimit();
        this.hideDataLimitExceedModal();
    }

    formattedPlanResetDate(parsedPlanEndDate) {
        /*
         * This function converts the given plan expiry date into user readable format,
         * so that it can be displayed in Plan and Usage section.
         *
         */
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let date = parsedPlanEndDate.getDate();
        let monthName = monthNames[parsedPlanEndDate.getMonth()]
        let year = parsedPlanEndDate.getFullYear()
        return date + " " + monthName + " " + year
    }

    showCancelPlanChangeRequest(change_request_id){
        this.setState({cancelPlanChangeRequest: true, change_request_id: change_request_id});
    }

    closeCancelPlanChangeRequest(){
        this.setState({cancelPlanChangeRequest: false, change_request_id: null});
    }

    render() {
        let planDetails = this.state.planDetails;
        let usageProgress = document.getElementById('usageProgress');
        let mlModelProgress = document.getElementById('mlModelProgress');
        if( usageProgress !== null && mlModelProgress !== null) {
             let progressUsage=findQuantileForProgress(planDetails.progressbar,planDetails.maxDataLimit);
             let progressMlModel=findQuantileForProgress(planDetails.mlModelCount,planDetails.maxMlModelLimit);
             usageProgress.setAttribute('class','progress-bar w-'+progressUsage);
             mlModelProgress.setAttribute('class','progress-bar w-'+progressMlModel);
        }
        let buttonAction;
        if ( planDetails.comments !== undefined ) {
            let buttonName = planDetails.comments[2];
            if (buttonName === BUTTON_NAMES.PAY_NOW) {
                buttonAction = this.redirectToPaymentPage.bind(this);
            } else if(buttonName === BUTTON_NAMES.CHOOSE_PLAN ) {
                buttonAction = goToChoosePlan
            } else if(buttonName === BUTTON_NAMES.INCREASE_LIMIT) {
                buttonAction = this.showDataLimitExceedModal.bind(this);
            }
        }

        let parsedPlanEndDate = parseDateAsUTC(planDetails.cycleEnd);
        let date = parsedPlanEndDate.getDate();

        // We are adding a date because, 'reset on' date will be the next day of current cycle's end date
        parsedPlanEndDate.setDate(date + 1);
        parsedPlanEndDate.setHours(0);

        const resetDateFormatted = `${this.formattedPlanResetDate(parsedPlanEndDate)}`;
        const currentTime = getCurrentUTCDateTime();
        const timeDifference = (parsedPlanEndDate - currentTime);
        let labelPrefix = "Usage will be reset on";
        if (timeDifference < 0) {
            labelPrefix = "Usage reset paused on";
        }
        const usage_reset_date_label = `${labelPrefix} ${resetDateFormatted}`;
        let showAdditionalData;
        let showAdditionalMlmodel;
        let existData = planDetails.monthlyLimit[0];
        let existModel = planDetails.monthlyLimit[1];
        let additionalData;
        let additionalMlmodel;
        let additionalDataCost;
        let additionalMlmodelCost;
        if (existData !== undefined && existModel !== undefined)
        {
            existData=Number(existData.match(/\d+/g));//extract allowed data size according to user choosen plan.
            existModel=Number(existModel.match(/\d+/g));//extract allowed mlmodel  according to user choosen plan.
            additionalData = planDetails.maxDataLimit - existData;
            additionalMlmodel = planDetails.mlModelCount - existModel;
            additionalDataCost = (additionalData*planDetails.addDataCost) / 100;
            additionalMlmodelCost = additionalMlmodel*planDetails.addModelCost;
            showAdditionalData = additionalData > 0 ;
            showAdditionalMlmodel = additionalMlmodel > 0;
        }
        let description = planDetails.comments[1];
        if (description === undefined) {
            description = "";
        }
        if(planDetails['paymentStatus'] === 'payment_pending'){
            let replaceStr = 'past-due on ' + planDetails['cycleEndDate']
            description = description.replace(/past-due/g,replaceStr )
        }

        return (
            <>
                <UpgradeComponent id={"data_limit_exceed"}
                                  showModal={this.state.showDataLimitExceedModal}
                                  title={DATA_LIMIT_EXCEED.TITLE}
                                  description={DATA_LIMIT_EXCEED.DESCRIPTION}
                                  closeModal={this.hideDataLimitExceedModal}
                                  setConfirm={this.increaseDataLimitPlan}
                                  cancelText={"Cancel"}
                                  confirmText={"Increase data limit"}

                />
               {
               this.state.cancelPlanChangeRequest === true ?
                    <>
                    <Modal show={true} centered onHide={this.closeCancelPlanChangeRequest}>
                        <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon"><i className="text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/></i></div>
                            <div className="modal_alert-text">
                                    <h4>Cancel the Request for Plan change?</h4>
                                    <p>This will remove your request for the plan change.</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.closeCancelPlanChangeRequest} className="btn btn-outline btn-grey btn-circle mr-2">No</button>
                                <button type="button" onClick={() => this.deletePlanChangeRequest(this.state.change_request_id)}
                                    className="btn btn-primary btn-circle">Yes</button>
                            </div>
                        </div>
                        </Modal.Body>
                    </Modal>
                    </>
               : ''
               }
               {
                    this.state.error_message !== null
                    &&
                    <PlanChangeNotAllowed error_message= {this.state.error_message}/>
                }
                <div className="qd-section_content  shadow-sm settings-preferences">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title">
                            <i>
                                <FontAwesomeIcon icon={faPaperPlane}/>
                            </i> Plan & Usage
                        </h4>
                    </div>
                   <div className="qd-section-body">
                       {
                           (planDetails.comments !== undefined && planDetails.showInformationBanner) ?
                               <PlanHeaderInfo
                                   title={planDetails.comments[0]}
                                   // title={"Data usage reached the maximum limit: 100 GB."}
                                   description={description}
                                   // description={"Cost will be $52 per 100 GB. Click here to increase data limit."}
                                   buttonLabel={planDetails.comments[2]}
                                   buttonAction={buttonAction}/>
                               :
                               ''
                       }
                       {/*
                           planDetails.requiresDataLimitUpgrade
                               ?
                               <PlanHeaderInfo
                                   title={`Data usage reached the maximum limit: ${planDetails.maxDataLimit} GB. `}
                                   description={"Cost will be $52 per 100 GB. " +
                                   "Click here to increase data limit."}
                                   buttonLabel={"Increase data limit"}
                                   buttonAction={this.showDataLimitExceedModal}
                               />
                               :
                               ''

                       */
                       }


                        <div className="row row-sm">
                            <div className="col-xl-7">
                                <div className="portlet mb-3">
                                    <div className="portlet-title">
                                        <div className="caption"> <span className="text-uppercase font-dark">Your plan Details</span> </div>
                                    </div>
                                    <div className="portlet-body">
                                        {
                                            planDetails.cost !== null && planDetails.monthlyLimit !== [] && planDetails.comments !== [] ?                                         <div className="fancy-card">
                                                <div className="row row-sm">
                                                    <div className="col-md-4">
                                                        <h6 className="card-subtitle mb-2 text-muted">Plan</h6>
                                                        <p className="card-text">{planDetails.planName}</p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <h6 className="card-subtitle mb-2 text-muted">Monthly Limit</h6>
                                                        <ul className="pl-0">
                                                            <li>{planDetails.monthlyLimit[0]}</li>
                                                            { this.props.hideModelInfo ? "" : <li>{planDetails.monthlyLimit[1]}</li>}
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <h6 className="card-subtitle mb-2 text-muted">Cost</h6>
                                                        <p>{planDetails.cost}</p>
                                                    </div>
                                                </div>
                                                {showAdditionalData || showAdditionalMlmodel ?
                                                <div className="row row-sm">
                                                    <div className="col-md-4">
                                                        <h6 className="card-subtitle mb-2 text-muted"> </h6>
                                                        <p className="card-text"></p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <h6 className="card-subtitle mb-2 text-muted">Additional Usage</h6>
                                                        <ul className="pl-0">
                                                           {showAdditionalData ? <li><b>{additionalData}GB</b> Data Size</li>:''}
                                                           {showAdditionalMlmodel && !(this.props.hideModelInfo) ? <li><b>{additionalMlmodel}</b> ML Models</li>:''}
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <h6 className="card-subtitle mb-2 text-muted">Cost</h6>
                                                        <ul className="pl-1"><p>
                                                            {showAdditionalData ? <li>${additionalDataCost}/Month</li> : ''}
                                                            {showAdditionalMlmodel && !(this.props.hideModelInfo) ? <li>${additionalMlmodelCost}/Month</li> : ''}
                                                            </p>
                                                        </ul>
                                                    </div>
                                                </div>:''}
                                                <h6 className="card-subtitle mb-2 text-muted">Plan Benefits</h6>
                                                <ul className="pl-0" >
                                                    { this.props.hideModelInfo ? "" : <><li><FontAwesomeIcon color="#228B22" icon={faCheck}/>  {planDetails.planBenefits[0]}</li>
                                                    <li><FontAwesomeIcon color="#228B22" icon={faCheck}/>  {planDetails.planBenefits[1]}</li></>}
                                                    <li><FontAwesomeIcon color="#228B22" icon={faCheck}/>  {planDetails.planBenefits[2]}</li>
                                                    <li><FontAwesomeIcon color="#228B22" icon={faCheck}/>  {planDetails.planBenefits[3]}</li>
                                                </ul>

                                                    <button type="button"
                                                            onClick={goToChoosePlan}
                                                            className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faLevelUpAlt}/>
                                                        Choose Plan & Pay
                                                    </button>
                                                </div>:
                                                <Load/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="portlet mb-3">
                                    <div className="portlet-title">
                                        <div className="caption">
                                            <span className="text-uppercase font-dark">
                                                Plan Usage
                                            </span>
                                        </div>
                                    </div>
                                    <div className="portlet-body">
                                         {
                                             planDetails.size !== null && planDetails.mlModelCount !== null ?
                                                <div className="fancy-card">
                                                    <div className="d-flex">
                                                        {planDetails.cycleEnd !== undefined ?
                                                            <>
                                                                <h6 className="card-subtitle mb-2 text-muted flex-grow-1">
                                                                    {usage_reset_date_label}
                                                                </h6>
                                                                <span
                                                                    className="badge">
                                                                    {this.getResetTimer(planDetails.cycleEnd)}
                                                                </span>
                                                            </>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    <div className="usage-progress mb-4">
                                                        <div className="d-flex">
                                                            <label className="flex-grow-1">Data Size</label>
                                                            <label>{planDetails.size} / {planDetails.maxDataLimit} GB </label>
                                                        </div>
                                                        <div className="progress mb-4">
                                                            <div className="progress-bar" id="usageProgress"
                                                                 role="progressbar"
                                                                 aria-valuenow={planDetails.progressbar}
                                                                 aria-valuemin="0" aria-valuemax="100"/>
                                                        </div>
                                                        {this.props.hideModelInfo ? "" : <><div className="d-flex">
                                                            <label className="flex-grow-1">No of Models</label>
                                                            <label>{planDetails.mlModelCount} / { planDetails.mlModelCount > 
                                                            planDetails.maxMlModelLimit ? planDetails.mlModelCount :  
                                                             planDetails.maxMlModelLimit } Models </label>
                                                        </div>
                                                        <div className="progress mb-5">
                                                            <div className="progress-bar" id="mlModelProgress"
                                                                 role="progressbar"
                                                                 aria-valuenow={planDetails.mlModelCount}
                                                                 aria-valuemin="0" aria-valuemax="100"/>
                                                        </div></>}

                                                    </div>
                                                </div> :
                                                <Load/>
                                         }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            Object.keys(this.props.change_request).length > 0 ?
                            <PlanChangeRequest showToast={this.props.showToast} change_request={this.props.change_request} showCancelPlanChangeRequest={this.showCancelPlanChangeRequest}/>
                            : ''
                        }

                   </div>
                </div>
            </>
        );
    }

}

export default PlanAndUsage;