import React from 'react';
export default class ProgressLoader extends React.Component {
    render() {
        return(
            <div className={"progress-loader "+ this.props.modifierClass}>
                <div className="progress-loader__track">
                    <div className="progress-loader__bar1"></div>
                    <div className="progress-loader__bar2"></div>
                </div>
            </div>
        )
    }
}
