import React from 'react';
import BorderedSummary from "../../components/tableCellComponents/borderedSummary";

class ModelABSummaryList extends React.Component {

    render() {
        const firstComponent = this.props.summaries[0];
        const secondComponent = this.props.summaries[1];
        const thirdComponent = this.props.summaries[2];

        return (
            <>

                <BorderedSummary titleClass={"text-primary"}
                                 showModelErrorFS={this.props.showModelErrorFS}
                                 borderedTitle={firstComponent.name}
                                 model_id={firstComponent.model_id}
                                 keyValueData={firstComponent.abKeyValueSummary}
                                 lastProcessedTime={firstComponent.lastProcessedTime}
                                 showErrorButton={firstComponent.modelHasError}
                >

                </BorderedSummary>


                <BorderedSummary titleClass={"text-info"}
                                 borderedTitle={secondComponent.name}
                                 showModelErrorFS={this.props.showModelErrorFS}
                                 model_id={secondComponent.model_id}
                                 keyValueData={secondComponent.abKeyValueSummary}
                                 showErrorButton={secondComponent.modelHasError}
                                 lastProcessedTime={secondComponent.lastProcessedTime}
                >

                </BorderedSummary>

                {thirdComponent === undefined ?
                    '' :
                    <BorderedSummary titleClass={"text-success"}
                                     borderedTitle={thirdComponent.name}
                                     showModelErrorFS={this.props.showModelErrorFS}
                                     model_id={thirdComponent.model_id}
                                     keyValueData={thirdComponent.abKeyValueSummary}
                                     showErrorButton={thirdComponent.modelHasError}
                                     lastProcessedTime={thirdComponent.lastProcessedTime}
                    >

                    </BorderedSummary>
                }


            </>
        )
    }
}

export default ModelABSummaryList;
