import React  from 'react';
import { ENVIRONMENT, DATA_SOURCE, DATA_SET, ATTRIBUTE } from '../../utils/constant';
import { getShortTitleForAttribute } from '../../utils/common_utils';
import { FQ_TOOLTIP_PLACEHOLDER } from '../../utils/attribute_name_utils';
import RcTooltip from 'rc-tooltip';

const renderTitleToolTip = (props) => (
    <span {...props}>
      {props}
    </span>
  );

class Filter extends React.Component {
     constructor(props) {
        super(props);
        this.renderFilterChips = this.renderFilterChips.bind(this);
        this.addChipsToFilter = this.addChipsToFilter.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
     }

    removeElement(event, key) {
        this.props.removeFilterElements(event, key);
    }

    addChipsToFilter(id, value, type) {
        if (id === undefined) {
            return '';
        }

        let requiredTooltip = null;
        let tooltipHolder = null;
        let shortName = null;

        let chipClass = "chip";
        let keySuffix = "";
        if (type === ENVIRONMENT) {
            chipClass = "chip chip-red";
            keySuffix = "env";
        } else if (type === DATA_SOURCE) {
            chipClass = "chip chip-green";
            keySuffix = "datasource";
        } else if (type === DATA_SET) {
            chipClass = "chip chip-yellow";
            keySuffix = "dataset";
        } else if (type === ATTRIBUTE) {
            chipClass = "chip chip-blue";
            keySuffix = "attribute";
            let shortenedData = getShortTitleForAttribute(value);
            shortName = shortenedData["requiredName"];
            requiredTooltip = shortenedData["fqTooltipContent"];
            if (requiredTooltip !== null) {
                tooltipHolder = FQ_TOOLTIP_PLACEHOLDER;
            }
        }

        let keyVal = String(id) + value + keySuffix;
        
        if (tooltipHolder === null) {
            return (
                <div key={keyVal} className={chipClass} id={id}>
                    {value}
                    {type === 1 ? "" : <i onClick={this.removeChipData.bind(this, id)} className="close" ></i>}
                </div>
            );
        }

        // We need to show tooltip and shortened name
        return (
            <div key={keyVal} className={chipClass} id={id}>
                <RcTooltip 
                    placement="top"
                    overlay={renderTitleToolTip(value)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <span className="mr-1">{tooltipHolder}</span>
                </RcTooltip>
                {shortName}
                {type === 1 ? "" : <i onClick={this.removeChipData.bind(this, id)} className="close" ></i>}
            </div>
        );
    }

    removeChipData(key) {
        this.props.removeChipData(key)
    }

    renderFilterChips() {
        let divItems = [];
        this.props.dataModule.chipData.filter((chipInfo)=> chipInfo.key!=="*" && divItems.push(this.addChipsToFilter(chipInfo.key, chipInfo.value, chipInfo.type)))
        return divItems;
    }

    render() {
        return (
            <>
                <div className="qd-filtered-section">
                    <div className="qd-filtered-body">
                        <div className="qd-filtered_chips">
                            {this.renderFilterChips()}
                            {/* <div className="alert moniker-alert" role="alert">
                                <p className="mb-0">
                                    <strong> {this.props.lastProfilingTime}</strong>
                                </p>
                            </div> */}
                        </div>
                        <div className="qd-filtered_button" onClick={this.props.showFilterModal}>
                            <button className="btn btn-secondary text-white clickon__filter">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24"
                                    viewBox="0 0 24 24" width="24">
                                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                    <path d="M0 0h24v24H0z" fill="none" />
                                </svg> <span>Filters</span>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Filter;