import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";


class SubscribedPlan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Card className="fancy-card">
                <Card.Body>
                    <Row className="row-sm">
                        <Col md={4}>
                            <h6 className="card-subtitle text-muted mb-2">Plan</h6>
                            <p className="card-text mb-3">{this.props.planName}</p>
                        </Col>
                        <Col md={4}>
                            <h6 className="card-subtitle text-muted mb-2">Monthly Limit</h6>
                            <ul className="pl-0">
                                <li key={"ds"}>
                                    upto <strong>{`${this.props.dataSize} `} GB</strong> Data Size
                                </li>
                                {this.props.hideModelInfo ? "" : <li key={"ml"}>
                                    upto <strong>{`${this.props.mlModels}`}</strong> Models
                                </li>}
                            </ul>
                        </Col>
                    </Row>
                    <h6 className="card-subtitle mb-2 text-muted mb-2">Plan Benefits</h6>
                    <ul className="pl-0 mb-0">
                        {
                            this.props.planBenefits.map((x, index) =>
                                (x.name.toLowerCase().includes("model") && this.props.hideModelInfo ? "" : <li key={index}>
                                    <FontAwesomeIcon color="#228B22" icon={faCheck}/>{" "}{x.name}
                                </li>)
                            )
                        }
                    </ul>
                </Card.Body>
            </Card>
        );
    }
}

export default SubscribedPlan;
