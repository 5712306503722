import React from 'react';
import $ from 'jquery';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import {event as currentEvent} from 'd3-selection';
import './lineage.css';
import Slider from 'react-rangeslider';
import Form from 'react-bootstrap/Form';
import Image from '../components/image';

const d3 = {
  ...d3Module,
  tip: d3Tip
};

const getAllChildren = (node) => {
  let nodes = [];
  if (node.children) {
    node.children.forEach(child => {
      nodes.push(child);
      nodes = nodes.concat(getAllChildren(child));
    });
  }
  return nodes;
};

    // Function to calculate the tree dimensions
    const calculateTreeDimensions = (root) => {
      let maxDepth = 0;
      let maxBreadth = 0;

      root.each((d) => {
        if (d.depth > maxDepth) maxDepth = d.depth;
        if (d.x > maxBreadth) maxBreadth = d.x;
      });

      return {
        width: (maxDepth + 1) * 350, // Example node width + padding
        height: (maxBreadth + 1) * 1500 // Example node height + padding
      };
    };

function getAllParents(node) {

      let parents = [];
      if(node === undefined){
        return parents;
      }
      while (node.parent) {
        parents.push(node.parent);
        node = node.parent;
      }
      return parents;
}
class PipelineLineage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          height:this.props.height,
          data:this.props.data,
          rangeValue: 10,
        }
        this.showGraph = this.showGraph.bind(this);
        this.resetLineage = this.resetLineage.bind(this);
        this.setRangeValue = this.setRangeValue.bind(this);
    }
  // zoomOut() {
  //     svg.transition().call(zoom.scaleBy, 0.8);
  // }
  //  resetZoom() {
  //     svg.transition().call(zoom.transform, d3.zoomIdentity);
  // }
  showGraph(){

    let chart_id =this.props.chart_id;

    let selectedNode;
        $(".lg-tooltip").hide(); // On very first line of scripts.js file
  var icon_job = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_job" transform="translate(-8449 726)">
    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
  </g>
</svg>
`

  var icon_task = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
    <path id="Path_8121" data-name="Path 8121" d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z" transform="translate(1 1)" fill="currentColor"/>
  </g>
</svg>
`

/*
  var icon_dataset = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_datasouce" transform="translate(-8523 726)">
    <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24" transform="translate(8523 -726)" fill="none"/>
    <path id="database-solid" d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z" transform="translate(8527 -723)" fill="currentColor"/>
  </g>
</svg>`
*/



  var icon_dataset_icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_dataset" transform="translate(-8487 726)">
    <rect id="Rectangle_1920" data-name="Rectangle 1920" width="24" height="24" transform="translate(8487 -726)" fill="none"/>
    <path id="layer-group-solid" d="M.434,5.2,8.623,8.911a.9.9,0,0,0,.748,0L17.561,5.2a.785.785,0,0,0,0-1.407L9.372.074a.9.9,0,0,0-.748,0L.434,3.789A.786.786,0,0,0,.434,5.2ZM17.561,8.3l-2.042-.926L9.836,9.95a2.026,2.026,0,0,1-1.678,0L2.476,7.374.434,8.3a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712a.785.785,0,0,0,0-1.406Zm0,4.493-2.034-.922L9.836,14.45a2.026,2.026,0,0,1-1.678,0l-5.69-2.579-2.035.922a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712A.785.785,0,0,0,17.561,12.793Z" transform="translate(8490.003 -722.993)" fill="currentColor"/>
  </g>
</svg>`

var icon_external_link = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_file_open" transform="translate(-254 -412)">
  <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="16" transform="translate(254 412)" fill="none"/>
  <path id="file_open_FILL0_wght400_GRAD0_opsz24" d="M161.178-868.221a1.135,1.135,0,0,1-.832-.346A1.134,1.134,0,0,1,160-869.4v-9.423a1.134,1.134,0,0,1,.346-.832,1.134,1.134,0,0,1,.832-.346h4.712l3.534,3.534v3.534h-1.178v-2.945H165.3v-2.945h-4.123v9.423h5.3v1.178Zm9.394.221-1.737-1.737v1.31h-1.178v-3.328h3.328v1.178h-1.325l1.737,1.737Zm-9.394-1.4v0Z" transform="translate(96.3 1294)" fill="currentColor"/>
</g>
</svg>`

var icon_feature_search = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_feature_search" transform="translate(-312 -412)">
  <rect id="Rectangle_3" data-name="Rectangle 3" width="16" height="16" transform="translate(312 412)" fill="none"/>
  <path id="feature_search_FILL0_wght400_GRAD0_opsz24" d="M89.366-873.415l1.171,1.171v2.78a1.127,1.127,0,0,1-.344.827,1.127,1.127,0,0,1-.827.344h-8.2a1.127,1.127,0,0,1-.827-.344,1.127,1.127,0,0,1-.344-.827v-8.2a1.127,1.127,0,0,1,.344-.827,1.127,1.127,0,0,1,.827-.344h3.22a2.616,2.616,0,0,0-.176.563q-.059.3-.088.607H81.171v8.2h8.2Zm.761-2.546L92-874.088l-.82.82-1.873-1.873a3.646,3.646,0,0,1-.659.293,2.343,2.343,0,0,1-.746.117,2.54,2.54,0,0,1-1.866-.768,2.54,2.54,0,0,1-.768-1.866,2.54,2.54,0,0,1,.768-1.866A2.54,2.54,0,0,1,87.9-880a2.54,2.54,0,0,1,1.866.768,2.54,2.54,0,0,1,.768,1.866,2.345,2.345,0,0,1-.117.746A3.647,3.647,0,0,1,90.127-875.961ZM87.9-875.9a1.413,1.413,0,0,0,1.039-.424,1.413,1.413,0,0,0,.424-1.039,1.413,1.413,0,0,0-.424-1.039,1.413,1.413,0,0,0-1.039-.424,1.413,1.413,0,0,0-1.039.424,1.413,1.413,0,0,0-.424,1.039,1.413,1.413,0,0,0,.424,1.039A1.413,1.413,0,0,0,87.9-875.9Zm-6.732,2.488v0Z" transform="translate(234 1294.133)" fill="currentColor"/>
</g>
</svg>`

var icon_troubleshoot = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_troubleshoot" transform="translate(-283 -412)">
  <rect id="Rectangle_2" data-name="Rectangle 2" width="16" height="16" transform="translate(283 412)" fill="none"/>
  <path id="troubleshoot_FILL1_wght400_GRAD0_opsz24" d="M91.16-868.6l-2.82-2.82a4.933,4.933,0,0,1-1.357.75,4.594,4.594,0,0,1-1.582.27,4.6,4.6,0,0,1-2.437-.66,4.893,4.893,0,0,1-1.717-1.74h1.47a3.821,3.821,0,0,0,1.192.877,3.448,3.448,0,0,0,1.493.323,3.472,3.472,0,0,0,2.55-1.05A3.472,3.472,0,0,0,89-875.2a3.471,3.471,0,0,0-1.05-2.55,3.471,3.471,0,0,0-2.55-1.05,3.465,3.465,0,0,0-2.437.953,3.507,3.507,0,0,0-1.147,2.348h-1.2a4.658,4.658,0,0,1,1.493-3.2A4.624,4.624,0,0,1,85.4-880a4.633,4.633,0,0,1,3.4,1.395A4.633,4.633,0,0,1,90.2-875.2a4.6,4.6,0,0,1-.27,1.583,4.931,4.931,0,0,1-.75,1.358L92-869.44Zm-6.4-4.2-.945-3.12-.78,2.22H80v-.9h2.4l.99-2.85h.9l.915,3.06.645-2.01h.9l.9,1.8h.45v.9h-1l-.7-1.41-.75,2.31Z" transform="translate(205 1294.333)" fill="currentColor"/>
</g>
</svg>`


var graph=this.props.data
if (!graph || !graph.tasks || !graph.links || !graph.datasets) {
  console.error("Invalid graph data:", graph);
  return;
}
let getalllinks = graph.links;

const graphTree = (graph) => {
  const nodesMap = new Map();

  // Create nodes from datasets
  for (const [key, value] of Object.entries(graph.tasks)) {
    let contentresult = generateNodeContent(key, value, graph.tasks[key],graph.datasets[key]);

    const newNode = {
      name: key,
      content: contentresult[0],
      contentheight:contentresult[1],
      contenttaskcnt:contentresult[2],
      contentdatasetcnt:contentresult[3],
      children: []
    };

    nodesMap.set(key, newNode);
  }


  // Identify the root node 
  const destinationSet = new Set(graph.links.map(link => link.destination));
  const rootLink = graph.links.find(link => !destinationSet.has(link.source));

  if (!rootLink) {
    return null; 
  }
  const rootNodeName = rootLink.source;
  // Set to keep track of added nodes
  const addedNodes = new Set();
  // Map each link in the graph to the corresponding nodes
  graph.links.forEach(link => {
    const sourceNode = nodesMap.get(link.source);
    const targetNode = nodesMap.get(link.destination);
    if (sourceNode && targetNode) {
      // Check if the target node is already added as a child
      if (!addedNodes.has(targetNode.name)) {
        sourceNode.children.push(targetNode);
        addedNodes.add(targetNode.name);
      }
    }
  });
  // Find the root node
  const rootNode = nodesMap.get(rootNodeName);
  return rootNode;
};

const generateNodeContent = (nodeName,value,tasks,datasets) => {
  let content = "";
  // Header section
  content += "<div class='lg-panel-header tltip tooltipHeading'>";
  content += `<a data-type='tooltip' data-tagname='${nodeName}' id='${nodeName}_jobs' href='#' class='
   jobstooltiptrigger'>`;
  content += `<span class='lg-panel-title'>${icon_job} ${nodeName}</span>`;
  content += "</a></div>";

  // Check if node has tasks
  if (tasks && tasks.length > 0) {
    // Body section
    content += "<div class='lg-panel-body'>";
    content += "<div class='lg-list'>";
    content += "<div class='lg-list__heading'>Tasks</div>";

    // Iterate over each task and generate HTML content
    tasks.forEach((task) => {
      content += `<div class="lg-list__item tltip highlights ${task.task_name}">`;
      content += `<a href="#" data-type="task" data-tagname='${nodeName}' id="${task.task_id}_task" data-classname="${task.task_name}" class="highlights jobstooltiptrigger ${task.task_name}">`;
      content += `<div class="lg-list__label tooltipTask">${icon_task} <span>${task.task_name}</span></div>`;
      content += `<div class="lg-list__actions"><div class="lg-list__actions-left "><i class="upstream" data-name="${nodeName}">${icon_troubleshoot}</i><i class="downstream" data-name="${nodeName}">${icon_feature_search}</i></div><div class="lg-list__actions-right"><i class="rightpaneldetail">${icon_external_link}</i></div></div>`;
      content += `</a></div>`;
    });

    content += "</div>";
  
  // Check if node has datasets
  if (datasets && datasets.length > 0) {
    content += "<div class='lg-list'>";
    content += "<div class='lg-list__heading'>Datasets</div>";

    // Iterate over each dataset and generate HTML content
    datasets.forEach((dataset) => {
      if (dataset) {
        content += `<a href="#" data-type="dataset" data-tagname='${nodeName}' id="${dataset.lineage_dataset_id}_dataset" data-classname="${dataset.pipeline_data_set_name}" class="highlights jobstooltiptrigger ${dataset.pipeline_data_set_name}">`;
        content += `<div class="lg-list__item tltip highlights ${dataset.lineage_dataset_name}">`;
        content += `<div class="lg-list__label tooltipDataset">${icon_dataset_icon}&nbsp;${dataset.lineage_dataset_name}</div>`;
        content += `</a></div>`;
      }
    });
    content += "</div>";
  }
  content += "</div>";
 }
  // Close the panel container
  // content += "</div>";
  // content  = "test";
 let taskcnt = tasks.length;
 let datasetcnt = datasets.length;
 let heightCalculation = datasetcnt*80+taskcnt*80;
  return [content,heightCalculation,taskcnt,datasetcnt];
};

const dataFromGraph = graphTree(graph);


// Count all jobs, tasks, and datasets
let jobCount = 0;
let taskCount = 0;
// let datasetCount = Object.keys(graph.datasets).length;

for (const tasks of Object.values(graph.tasks)) {
  taskCount = Math.max(taskCount, tasks.length);
  jobCount++;
}

const nodeWidth = 200 + (jobCount * 10); 
// const nodeHeight = 50 + (taskCount + datasetCount) * 13; 

// SVG dimensions based on node dimensions
// const svgWidth = (nodeWidth + 50) * jobCount; 
// const svgHeight = (nodeHeight + 150) * jobCount; 

// const width = 1200;
// const height = 600;
const margin = { top: 20, right: 90, bottom: 30, left: 90 };

// Create a hierarchy from the data
// const root = d3.hierarchy(dataFromGraph);

function validateAndFixData(node) {
  if (!node) return null;

  // Ensure each node has a children property
  if (!node.children) {
    node.children = [];
  }

  // Recursively validate and fix children
  node.children = node.children.map(validateAndFixData);

  return node;
}

const validDataFromGraph = validateAndFixData(dataFromGraph);

if (validDataFromGraph) {
  try {
    const root = d3.hierarchy(validDataFromGraph);
// Generate the tree structure
const treeLayout = d3.tree();
let treeData = treeLayout(root);
const dimensions = calculateTreeDimensions(treeData);
const svgWidth = dimensions.width; 
const svgHeight = dimensions.height; 
// Set up the tree layout with a vertical orientation
 treeLayout.size([svgWidth, svgHeight]);
// const treeLayout = d3.tree().size([width, height]);
treeData = treeLayout(root);
// const svg = d3.select("#"+chart_id+" svg")
//     .attr("width", svgWidth + margin.left + margin.right)
//     .attr("height", svgHeight + margin.top + margin.bottom)
//     .call(d3.zoom().on("zoom", function () {
//         svg.attr("transform", currentEvent.transform)
//     }))
//     .append("g")
//     .attr("transform", `translate(${margin.left},${margin.top})`);
//         // Prevent zooming on double-click
//         svg.on("dblclick.zoom", null);
//         // Prevent zooming on mouse scroll
//         svg.on("wheel.zoom", null);

// Assuming you're using D3 version 5 or earlier

// Create or select the SVG element
const svg = d3.select("#" + chart_id + " svg")
  .attr("width", svgWidth + margin.left + margin.right)
  .attr("height", svgHeight + margin.top + margin.bottom)
  .call(d3.zoom().on("zoom", function(event) {
    const transform = currentEvent.transform; // Access the transform object from event
    svg.select("g") // Apply transform to the <g> element
      .attr("transform", `translate(${transform.x},${transform.y}) scale(${transform.k})`);

    // Calculate the zoom level based on transform.k (the scale factor)
    const zoomLevel = parseInt(transform.k * 10); // Example conversion to match Slider's scale

    // Ensure the correct context for setState
    if (typeof this.setState === 'function') {
      this.setState({ rangeValue: zoomLevel }); // Update state to synchronize with slider
    }
  }.bind(this))) // Bind the correct context
  .append("g")
  .attr("transform", `translate(${margin.left},${margin.top})`);

// Prevent default zoom behaviors on double-click and mouse scroll
svg.on("dblclick.zoom", null);
svg.on("wheel.zoom", null);


// Create the lineage graph
const lineageGraph = svg.append("g").attr("transform", "translate(50, 50)");

lineageGraph.append("defs").append("marker")
                .attr("id", "arrow")
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5")
                        .style("fill","#CED4DA");

                        lineageGraph.append("defs").append("marker")
                        .attr("id", "upstreamarrow")
                        .attr("viewBox", "0 -5 10 10")
                        .attr("refX", 0)
                        .attr("refY", 0)
                        .attr("markerWidth", 5)
                        .attr("markerHeight", 5)
                        .attr("orient", "auto")
                        .append("svg:path")
                                .attr("d", "M0,-5L10,0L0,5")
                                .style("fill","#007BFF");

                        lineageGraph.append("defs").append("marker")
                                .attr("id", "downstreamarrow")
                                .attr("viewBox", "0 -5 10 10")
                                .attr("refX", 0)
                                .attr("refY", 0)
                                .attr("markerWidth", 5)
                                .attr("markerHeight", 5)
                                .attr("orient", "auto")
                                .append("svg:path")
                                        .attr("d", "M0,-5L10,0L0,5")
                                        .style("fill","#F64E60");


// Define a variable to indicate whether the current node should be positioned above or below
let isAbove = true; 

// Render nodes with HTML content
const nodes = lineageGraph.selectAll(".node")
.data(treeData.descendants())
.enter()
.append("g")
.attr("class", (d) => {return d.data.name})
.attr("transform", (d) => {
// Adjust the y-coordinate based on whether the node should be above or below
// const y = isAbove ? svgHeight * 0.075 : svgHeight * 0.025; 
// const y = isAbove ? height * 0.075 : height * 0.025; 
isAbove = !isAbove; 
return `translate(${d.y}, ${d.data.contentheight})`;
});

 nodes.append("foreignObject")
.attr("class", "nodegroup")
.attr("width", nodeWidth)
.attr("height",  (d) => { return d.data.contentheight + 70 } )
// .attr("tooltipx",  (d) => { return d.x; } )
// .attr("tooltipy",  (d) => { return d.y+d.data.contenttaskcnt*150+d.data.contentdatasetcnt*150; } )
.html(d => `<div class='lg-panel' id="${d.data.name}">${d.data.content}</div>`)
.on('click', handleClick);

function handleClick(event, d) {
  selectedNode = event;
}

nodes.append("foreignObject")
        .attr("id", "jobstooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(`<span  class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Pipeline:</label>Job2</p>
         <p><label>Run Status:</label>
           <i class="status-icon">
             <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
               <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
               <path id="Path_8128" data-name="Path 8128"
                 d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
                 fill="#ff4934" />
             </svg>
           </i>
           Failed
         </p>
         <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
       </div>
     </span>`);
   
   
     nodes.append("foreignObject")
        .attr("id", "tasktooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(`  <div id="tooltipTask" class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Task:</label>taskname1</p>
         <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
       </div>
       <div class="lg-tooltip__body">
         <div class="lg-tooltip__section">
           <dl>
             <dt>Read from</dt>
             <dd>
               <i>
                 <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                   <g id="icon_datasouce" transform="translate(-8523 726)">
                     <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                       transform="translate(8523 -726)" fill="none" />
                     <path id="database-solid"
                       d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                       transform="translate(8527 -723)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               SAP - Daily Global Sales Extract
             </dd>
           </dl>
           <dl>
             <dt>Write to</dt>
             <dd>
               <i>
                 <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                   <g id="icon_datasouce" transform="translate(-8523 726)">
                     <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                       transform="translate(8523 -726)" fill="none" />
                     <path id="database-solid"
                       d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                       transform="translate(8527 -723)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               S3 - Raw Daily Global Sales Extract
             </dd>
           </dl>
         </div>
       </div>
     </div>`);
   
   
     nodes.append("foreignObject")
        .attr("id", "datsettooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(` <span id="tooltipDataset" class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Dataset:</label>Dataset1</p>
       </div>
       <div class="lg-tooltip__body">
         <div class="lg-tooltip__section">
           <dl>
             <dt>Read tasks</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               load_prior_day_na_sales_to_databricks_bronze
             </dd>
           </dl>
           <dl>
             <dt>Written Tasks</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               extract_regional_sales_to_s3
             </dd>
           </dl>
         </div>
         <div class="lg-tooltip__section">
           <dl>
             <dt>1 Missing Operation</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               Data wasn't written by task <strong>extract_regional_sales_to_s3</strong>
             </dd>
           </dl>
         </div>
       </div>
     </span>`);

  $(document).on('click', '.rightpaneldetail', function () {
   $(".lineage-graph-sidebar").addClass("show")
  });

      // Adjust node positions for overlapping effect
      const overlapX = 1;  // Horizontal overlap
      const overlapY = 1;  // Vertical overlap
  
      nodes.attr('transform', (d, i) => {
          const x = d.y - i * overlapX;
          const y = d.x + i * overlapY;
          return `translate(${x},${y})`;
      });


          
  $(document).on('click', '.upstream', function () {
    if(selectedNode !== undefined){
      $('a').removeClass("related-active")
      $(this).closest('a').addClass("related-active")
    const allParents = getAllParents(selectedNode);
    let nodename = allParents.map((item) => {
      return item.data.name
    });
    $('.lg-panel').removeClass('highlights-downstream').removeClass('related-active ').addClass('disable-lineagenode');
    // $('.lg-panel').removeClass('highlights-downstream').addClass('disable-lineagenode');
    for(let loop=0;loop<allParents.length;loop++){
      $('#'+chart_id+' #'+allParents[loop].data.name).removeClass('disable-lineagenode').addClass("highlights-downstream");
    }
    $('#'+chart_id+' #'+selectedNode.data.name).removeClass('disable-lineagenode').addClass("lg-panel--active highlights-downstream");
    var $paths = $('path.lineage'); // Get all paths
    for (var i=0;i<$paths.length;i++){ // Iterate through each one
        var $path =$($paths[i]); // This gets a single path
        $path.attr("stroke","#CED4DA")
        $path.attr("marker-end","url(#arrow)");  
    }
    for ( i=0;i<$paths.length;i++){ // Iterate through each one
         $path =$($paths[i]); // This gets a single path
        if(nodename.indexOf($path.attr("destination")) >= 0 || selectedNode.data.name === $path.attr("destination")){
          $path.attr("stroke","#007BFF")
          $path.attr("marker-end","url(#upstreamarrow)");  
        }
      }
    }
   });

   $(document).on('click', '.downstream', function (d) {
    if(selectedNode!==undefined){
      $('a').removeClass("related-active")
      $(this).closest('a').addClass("related-active")
    const allChildren = getAllChildren(selectedNode);
    let nodename = allChildren.map((item) => {
      return item.data.name
    });
    $('.lg-panel').removeClass('highlights-downstream').removeClass('related-active ').addClass('disable-lineagenode');
    for(let loop=0;loop<allChildren.length;loop++){
      $('#'+chart_id+' #'+allChildren[loop].data.name).removeClass('disable-lineagenode').addClass("highlights-downstream");
    }
    $('#'+chart_id+' #'+selectedNode.data.name).removeClass('disable-lineagenode').addClass("lg-panel--active highlights-downstream");
    var $paths = $('path.lineage'); // Get all paths
    for (var i=0;i<$paths.length;i++){ // Iterate through each one
        var $path =$($paths[i]); // This gets a single path
        $path.attr("stroke","#CED4DA")
        $path.attr("marker-end","url(#arrow)");  
    }
    for ( i=0;i<$paths.length;i++){ // Iterate through each one
         $path =$($paths[i]); // This gets a single path
        if(nodename.indexOf($path.attr("source")) >= 0 || selectedNode.data.name === $path.attr("source")){
          $path.attr("stroke","#007BFF")
          $path.attr("marker-end","url(#upstreamarrow)");  
        }
      }
    }
   }); 

$(document).on('mouseout', 'a.jobstooltiptrigger', function () {
   $("#jobstooltip,#tasktooltip,#datsettooltip").hide();
});




  $(document).on('mouseover', 'a.jobstooltiptrigger_disable', function (event) {
    var elems = this;
    var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"
    var tagname = $("#" + elems.id).attr("data-tagname"); // will return the string "123"
    let rect1 = lineageGraph.selectAll("."+tagname)
    let rect1conordinates = rect1.attr("transform").replace("translate(","").replace(")","");
    rect1conordinates = rect1conordinates.split(",");
    let rect1x = rect1conordinates[0]
    let rect1y = rect1conordinates[1]
    let rect1height = $("."+tagname+" .nodegroup").attr("height");
    let rect1width = $("."+tagname+" .nodegroup").attr("width");

    var newposx = parseInt(rect1x)+parseInt(rect1width);
    var newposy = parseInt(rect1y)-(parseInt(rect1height)+parseInt(20));

    if(tooltip === "tooltip") {
           $("#tooltipHeader").show();
           $("#jobstooltip").show();
           $("#jobstooltip").attr("y", newposy);
           $("#jobstooltip").attr("x", newposx);
           $("#jobstooltip").attr("width", "200");
           $("#jobstooltip").attr("height", "200");
         }
         else if(tooltip === "task") {

          $("#tasktooltip").show();
           $("#tasktooltip").attr("y", newposy);
           $("#tasktooltip").attr("x", newposx);
           $("#tasktooltip").attr("width", "300");
           $("#tasktooltip").attr("height", "300");
          
         }
         else if(tooltip === "dataset") {
           $("#datsettooltip").show();
           $("#datsettooltip").attr("y", newposy);
           $("#datsettooltip").attr("x", newposx);
           $("#datsettooltip").attr("width", "300");
           $("#datsettooltip").attr("height", "300");
         }
         
       });
     
       for(let loop=0;loop<getalllinks.length;loop++){

        let rect1 = lineageGraph.selectAll("."+getalllinks[loop]['source'])
        let rect2 = lineageGraph.selectAll("."+getalllinks[loop]['destination'])


        let rect1x;
        let rect1y;
        let rect1height;
        let rect1width;

        let rect2x;
        let rect2y;
        let rect2height;
        // let rect2width;

        if(rect1.attr("transform")!==undefined){
          let rect1conordinates = rect1.attr("transform").replace("translate(","").replace(")","");
          rect1conordinates = rect1conordinates.split(",");
          rect1x = rect1conordinates[0]
          rect1y = rect1conordinates[1]
          rect1height = $("."+getalllinks[loop]['source']+" .nodegroup").attr("height");
          rect1width = $("."+getalllinks[loop]['source']+" .nodegroup").attr("width");
        }
        
        if(rect2.attr("transform")!==undefined){
          let rect2conordinates = rect2.attr("transform").replace("translate(","").replace(")","");
          rect2conordinates = rect2conordinates.split(",");
          rect2x = rect2conordinates[0]
          rect2y = rect2conordinates[1]
          rect2height = $("."+getalllinks[loop]['source']+" .nodegroup").attr("height");
        }  
      let x1 = parseInt(rect1x) + parseInt(rect1width);
      let y1 =parseInt(rect1y) + parseInt(rect1height) / 2;
      let x2 = parseInt(rect2x);
      let y2 = parseInt(rect2y) + parseInt(rect2height) / 2;
      let midx  = ((x2 - x1) / 2) + x1
      //The data for our line
      let lineData = [{ "x": x1,   "y": y1},  
                      { "x": midx,  "y": y1}, 
                      { "x": midx,  "y": y2}, 
                      { "x": x2-10,  "y": y2}];
        let lineFunction =  d3.line()
        .x(function(d) { return d.x; })
        .y(function(d) { return d.y; })
        .curve(d3.curveBasis);  
      //The line SVG Path we draw
      lineageGraph.append("path")
                                  .attr("d", lineFunction(lineData))
                                  .attr("source",getalllinks[loop]['source'])
                                  .attr("destination",getalllinks[loop]['destination'])
                                  .attr("stroke", "#CED4DA")
                                  .attr("class", "lineage")
                                  .attr("stroke-width", 2)
                                  .attr("fill", "none")
                                  .attr("marker-end","url(#arrow)");  
                                }


          //       // Links
    // const links = svg.selectAll('.link')
    // .data(root.links())
    // .enter()
    // .append('path')
    // .attr('class', 'link')
    // .attr('d', d3.linkHorizontal()
    //     .x(d => d.y)
    //     .y(d => d.x));

  
    //   links.attr('d', d3.linkHorizontal()
    //       .x(d => d.y - root.descendants().indexOf(d.source) * overlapX)
    //       .y(d => d.x + root.descendants().indexOf(d.source) * overlapY));


    if (this.props.selectedNode !== undefined) {
      const transformAttr = $("#" + chart_id + " svg g g." + this.props.selectedNode).attr("transform");
    
      if (transformAttr) {
        let getFocusPosition = transformAttr.replace("translate(", "").replace(")", "");
    
        let focusposition = getFocusPosition.split(",");
        let moveposistionx = parseInt(focusposition[0]) + (parseInt(svgWidth) / 2) * -1;
        let moveposistiony = parseInt(focusposition[1]) * -1;
    
        // +(parseInt(svgHeight)/2)
    
        d3.select("#" + chart_id + " svg g").attr("transform",
          `translate(${moveposistionx},${moveposistiony}) scale(1)`);
        $('#' + chart_id + ' #' + this.props.selectedNode).addClass("lg-panel--active highlights-downstream");
      } else {
        console.error("Transform attribute is undefined for the selected node");
      }
    } else {
      console.error("Selected node is undefined");
    }
  }
    catch (error) {
      console.error("Error creating hierarchy:", error);
    }
  } else {
    console.error("dataFromGraph is null or not an object");
  }
  }


      componentDidMount() {
        if(this.state.data.hasOwnProperty("tasks")){
          this.showGraph();
        }
      }
    resetLineage(){
      if(this.state.data.hasOwnProperty("tasks")){
      $('.lg-panel').removeClass('highlights-downstream').removeClass('disable-lineagenode');
      var $paths = $('path.lineage'); // Get all paths
      for (var i=0;i<$paths.length;i++){ // Iterate through each one
          var $path =$($paths[i]); // This gets a single path
          $path.attr("stroke","#CED4DA")
          $path.attr("marker-end","url(#arrow)");  
      }
    }
  }

  setRangeValue = (event) => {
    if(this.state.data.hasOwnProperty("tasks")){
      // alert(this.state.data)
      let eventscale = event > 10 ? event/10 : 10
      let scale = $("#"+this.props.chart_id+" svg g").first().attr("transform").split(" ")[0]+" scale("+eventscale+")";
  //     d3.select("#"+this.props.chart_id+" svg g")
  // .transition()
  // .call(d3.zoom.scaleTo, eventscale);
      $("#"+this.props.chart_id+" svg g").first().attr("transform",scale)
      this.setState({rangeValue : event});
    }
  }
    render() {
        return (
          <>
           {this.state.data.hasOwnProperty("tasks") ? 
           <>
          <div className="portlet-title">
          <div className="caption">
              <span className="font-dark"></span>
              {/* <p>Click on task or dataset in order to see their downstream</p> */}
          </div>
          <div className="actions">
              <div className="lg-toolbar">
                  <div className="lg-toolbar-item">
                      <p class="mb-0 d-none"><label class="text-muted mb-0 d-none d-xl-inline-block">Last Sync:</label> <strong>Today at 04:00:00 PM</strong></p>
                  </div>
                  <div className="lg-toolbar-item">
                      <Form className="mr-3">
                          <Form.Group controlId="formBasicRangeCustom" className="form-inline flex-nowrap mb-0">
                              <Form.Label className="d-none d-xl-inline-block">Zoom</Form.Label>
                              <Slider
                                 disabled={this.state.data === "" ? false : true } 
                                  min={1}
                                  max={100}
                                  tooltip={false}
                                  value={this.state.rangeValue}
                                  onChange={this.setRangeValue}
                              />
                              <span className="rangeslider__label">{this.state.rangeValue + "%"}</span>
                          </Form.Group>
                      </Form>
                  </div>
                  <div className="lg-toolbar-item">
                      <button class="btn btn-light-secondary" onClick={this.resetLineage}>Reset</button>
                  </div>
              </div>
          </div>
          </div>
          <div className="portlet-body p-0">
          <div id={this.props.chart_id} class="lineage-graph-container"><svg></svg></div>
          </div>
          </>
          : <>
          <div className="placeholder-msg">
                                                                            <div className="placeholder-msg__icon">
                                                                                <Image src="icon_placeholder_no_pipeline_data" />
                                                                            </div>
                                                                            <p>No Data Found</p>
                                                                        </div>
          </> }
          </> 
        )
    }
}
export default PipelineLineage;