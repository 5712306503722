import React from 'react';
import DqpListViewContent from "./dqpListView";
import DqpGridViewContent from "./dqpGridView";
import {faChartLine, faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

class MonitorDQPTabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);
        let metricsList = this.props.monitorModule.metrics;

        this.state = {
            activeTabKey: "dqp_list_view",
            metricsList: metricsList,
        };
    }

    changeTab(tab) {
        this.setState({activeTabKey: tab});
    }

    componentDidUpdate(prevProps){
        if(this.state.activeTabKey !== prevProps.activeTabKey){
            
        }
    }
    
    // shouldComponentUpdate(){
    //     if(this.state.showLoader === false){
    //         return true
    //     }
    //     return false
    // }

    render() {
        return (
            <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                <div className="tabbable-line mb-3">
                    <Nav variant="tabs" id="page-nav-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="dqp_grid_view">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </i>  Chart View</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="dqp_list_view">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faList}/>
                                        </i>  List View</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                            <div className="tab-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="dqp_grid_view">
                                        <div className="portlet">
                                            <div className="portlet-body">
                                              {this.state.activeTabKey === 'dqp_grid_view'?  
                                                    <DqpGridViewContent 
                                                        showLoader={this.props.showLoader}
                                                        // integrationsMap={this.props.integrationsMap}
                                                        monitorModule={this.props.monitorModule}
                                                        metaData = {this.props.metaData}
                                                        mappedDatasetInfo = {this.props.mappedDatasetInfo}                                                        
                                                        filterData={this.props.filterData}
                                                        metricsList={this.state.metricsList}
                                                        integrationMapping={this.props.integrationMapping}
                                                        default_filter_data={this.props.default_filter_data}
                                                        setCustomFilterValues={this.props.setCustomFilterValues}
                                                        inProgress={this.props.inProgress}
                                                        noIntegration = {this.props.noDataFound}
                                                        noDataFound = {this.props.noDataFound}                                                    
                                                    />
                                                :
                                                    ''
                                                }
                                                        </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dqp_list_view">
                                        {this.state.activeTabKey === 'dqp_list_view'?  
                                                <DqpListViewContent 
                                                    monitorModule={this.props.monitorModule}
                                                    timelinessData={this.props.timelinessData}
                                                    firstDatasetUp={this.props.firstDatasetUp}
                                                    metaData = {this.props.metaData}
                                                    mappedDatasetInfo = {this.props.mappedDatasetInfo}                                                        
                                                    metricsList={this.state.metricsList}
                                                    showLoader={this.props.showLoader}
                                                    renderChild = {this.renderChild}
                                                    filterData={this.props.filterData}
                                                    follow_attributes={this.props.follow_attributes}
                                                    integrationMapping={this.props.integrationMapping}
                                                    default_filter_data={this.props.default_filter_data}
                                                    setCustomFilterValues={this.props.setCustomFilterValues}
                                                    inProgress={this.props.inProgress}
                                                    noDataFound={this.props.noDataFound}
                                                    
                                                    /> 
                                            :
                                                ''
                                        }
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
            </Tab.Container>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MonitorDQPTabs);
