import BasicPortlet from "../../components/basicPortlet";
import React from "react";
import { browserHistory} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBezierCurve} from '@fortawesome/free-solid-svg-icons';
import Image from "../../components/image";
import Load from '../../components/loadAction';



class NoModelComponent extends React.Component {
    constructor(props) {
        super(props);
        this.go_to_configure_page = this.go_to_configure_page.bind(this);
        // setUpBotton this variable for the to show configure datasource if the user have no integration details
        this.state = {setUpBotton: false}
    }

    go_to_configure_page() {
        let redirectPage = "/model"
        if(this.props.navigatePage !== undefined){
            redirectPage = this.props.navigatePage
        }
        browserHistory.push(redirectPage);
    }

    componentDidMount(){
           if (this.props.noIntegration !== null && this.props.noIntegration === true){
                    this.setState({setUpBotton:true})
           }
    }

    render() {
        let buttonName = "Setup Model";
        let noContentSubtext = "No models configured yet.";
        if (this.props.customButtonName !== undefined) {
            buttonName = this.props.customButtonName;
        }

        if (this.props.noContentSubtext !== undefined) {
            noContentSubtext = this.props.noContentSubtext;
        }
        return (
            <>
            {
            // here we will check the undefined and the setUpBotton status if it is false means we will show the 
            // loader if it is true means we will show the configure datasource button
            this.state.setUpBotton?
                <BasicPortlet
                    className="mb-0 pb-0" bodyClassName="pb-0"
                    content={
                    <div className="col-md-8 offset-md-2 text-center p-5">
                        <div className="p-5">
                            <i className="d-inline-block mb-3">
                                <Image src="icon_configure"/>
                            </i>
                            {
                            (this.props.isInProgress !== undefined && this.props.isInProgress === true) ?
                            <h4 className="mb-3">{this.props.message}</h4>
                            :
                           <>
                                <p>
                                    {noContentSubtext}
                                </p>
                                <div className="d-flex justify-content-center">
                                    <button type="button"
                                            onClick={this.go_to_configure_page}
                                            className="btn btn-primary mb-2">
                                        <i>
                                            <FontAwesomeIcon icon={faBezierCurve}/>
                                        </i>  {buttonName}
                                    </button>
                                </div>
                           </>
                            }
                        </div>
                    </div>
                    }
                />
            :
            (this.props.model === true?
                <BasicPortlet
                        className="mb-0 pb-0" bodyClassName="pb-0"
                        content={
                        <div className="col-md-8 offset-md-2 text-center p-5">
                            <div className="p-5">
                                <i className="d-inline-block mb-3">
                                    <Image src="icon_configure"/>
                                </i>
                <p>{noContentSubtext}</p>
                <div className="d-flex justify-content-center">
                    <button type="button"
                            onClick={this.go_to_configure_page}
                            className="btn btn-primary mb-2">
                        <i>
                            <FontAwesomeIcon icon={faBezierCurve}/>
                        </i>  {buttonName}
                    </button>
                </div>
                </div>
                </div>
                }
                />
                :
                 <Load/>
            )
            }
        </>)
    }
}


export default NoModelComponent;
