import React from 'react';
import RcTooltip from 'rc-tooltip';

const TagsInput = props => {
	const [tags, setTags] = React.useState(props.tags);
	const removeTags = indexToRemove => {
		// Will remove the tag in input box
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);

		// will remove the ip state variable
		props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)])
	};
	const addTags = event => {
		if (event.target.value !== "") {
			let ip = event.target.value
			// regex to validate ip and cidr range
			if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip)) {
				props.setStateValue(false);
				props.selectedTags([...tags, event.target.value]);
				setTags([...tags, event.target.value])
				props.selectedTags([...tags, event.target.value]);
			}
			else {
				props.setStateValue(true);
			}
			event.target.value = "";
		}
	};

	let hideTextField = props.placeholder === null;

	return (
		<div className="qd_tags-input">
			<div className="tag-list">
				{tags.map((tag, index) => (
					<div key={index} className="tag-item">
						<div className="tag">
							<span className='tag-title'>{tag}</span>
							<RcTooltip
								placement="top"
								overlay={
									<span>
										disassociate IP from instance
									</span>}
								arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
								>
								<i className={props.closeTag} onClick={() => removeTags(index)}></i>
							</RcTooltip>
						</div>
					</div>
				))}


				{
				hideTextField ?
				'' :
				<input
					type="text"
					onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
					onBlur={event => addTags(event)}
					placeholder={props.placeholder}
					disabled={hideTextField}
				/>
				}

			</div>
		</div>
	);
};

export default TagsInput;