import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

export function ImageComponent(props) {

    return (
        <div className="media email-media">
             <div className="media-body">
                <h5>{props.userDetails.username}</h5>
                <span className="text-black-50 font-weight-normal">{props.userDetails.email}</span>
             </div>
        </div>
    );
}


