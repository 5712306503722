import React from 'react';
import visPromiseLookup from '../charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTag } from '@fortawesome/free-solid-svg-icons';
import VideoContent from './videoContent';
import Form from 'react-bootstrap/Form';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import { event as currentEvent } from 'd3-selection';


const d3 = {
    ...d3Module,
    tip: d3Tip
};

class ChartBody extends React.Component {

    constructor(props) {
        super(props);
        this.toggleHelpSection = this.toggleHelpSection.bind(this);
        this.hideTextLegendArea = this.hideTextLegendArea.bind(this);
        this.state = {
            renderVis: null,
            chart_data: this.props.chart_data,
            metaData: this.props.metaData,
            hidePartitions: this.props.hidePartitions,
            metricInfo: this.props.metricInfo,
            selector: this.props.id,
            visPromise: null,
            chartType: this.props.chartType,
            colorType: this.props.colorType,
            expand: this.props.expand,
            customHeight: this.props.customHeight,
            chartName: this.props.chartName,
            page: this.props.page,
            compareChart: this.props.compareChart,
            isNonTimeSeries: this.props.chart_data ? this.props.chart_data.isNonTimeSeries : false,
            filteredValues: this.props.filteredValues,
            selectedDataSource: this.props.selectedDataSource,
            timeFilterApplied: this.props.timeFilterApplied,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            showHelpSection: false,
            showTextLegendArea: true,
            datasetVersion: this.props.datasetVersion,
            chartzoom: 10,
            checkedAttributes: this.props.checkedAttributes,
            checkedPartition: this.props.checkedPartition,
            relationshipCheckFilter: this.props.relationshipCheckFilter,
            showSliderPopover: false,
            allowRelationsFilter: this.props.allowRelationsFilter,
        };
        this.chartZoom = this.chartZoom.bind(this);
        this.resetZoom = this.resetZoom.bind(this);
        this.chartHelpText = this.chartHelpText.bind(this);
        this.chartHelpTextHide = this.chartHelpTextHide.bind(this);
        this.relationshipApplyFilter = this.relationshipApplyFilter.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
    }

    relationshipApplyFilter(e) {
        this.setState({
            allowRelationsFilter: e.currentTarget.value,
        })
        let value = e.currentTarget.value
        this.props.relationshipCheck(value)
    }

    toggleHelpSection = () => {
        let showHelpSection = this.state.showHelpSection ? false : true;
        this.setState({ showHelpSection: showHelpSection });
    };

    hideTextLegendArea = () => {
        this.setState({ showTextLegendArea: false });
    }

    showTextLegendAreaContent = () => {
        this.setState({ showTextLegendArea: true });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.chart_data !== state.chart_data) {
            return { chart_data: props.chart_data };
        } else if (props.expand !== state.expand) {
            return { expand: props.expand }
        } else if (props.chart_data_v1 !== state.chart_data_v1) {
            return { chart_data_v1: props.chart_data_v1 };
        } else if (props.chartType !== state.chartType) {
            return { chartType: props.chartType }
        } else if (props.compareChart !== state.compareChart) {
            return { compareChart: props.compareChart }
        } else if (props.filteredValues !== state.filteredValues) {
            return { filteredValues: props.filteredValues }
        } else if (props.selectedDataSource !== state.selectedDataSource) {
            return { selectedDataSource: props.selectedDataSource }
        } else if (props.metricInfo !== state.metricInfo) {
            return { metricInfo: props.metricInfo }
        } else if (props.timeFilterApplied !== state.timeFilterApplied) {
            return { timeFilterApplied: props.timeFilterApplied }
        } else if (props.startDate !== state.startDate) {
            return { startDate: props.startDate }
        } else if (props.endDate !== state.endDate) {
            return { endDate: props.endDate }
        }
        else if (props.checkedAttributes !== state.checkedAttributes) {
            return { checkedAttributes: props.checkedAttributes }
        }
        else if (props.relationshipCheckFilter !== state.relationshipCheckFilter) {
            return { relationshipCheckFilter: props.relationshipCheckFilter }
        }
        else if (props.checkedPartition !== state.checkedPartition) {
            return { checkedPartition: props.checkedPartition }
        }
        else if (props.allowRelationsFilter !== state.allowRelationsFilter) {
            return { allowRelationsFilter: props.allowRelationsFilter }
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.chart_data !== this.state.chart_data) {
            this.setState({ chart_data: this.props.chart_data });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.expand !== this.state.expand) {
            this.setState({ expand: this.props.expand });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.chart_data_v1 !== this.state.chart_data_v1) {
            this.setState({ chart_data_v1: this.props.chart_data_v1 });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.chartType !== this.state.chartType) {
            this.setState({ chartType: this.props.chartType });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.compareChart !== this.state.compareChart) {
            this.setState({ compareChart: this.props.compareChart });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.filteredValues !== this.state.filteredValues) {
            this.setState({ filteredValues: this.props.filteredValues });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.selectedDataSource !== this.state.selectedDataSource) {
            this.setState({ selectedDataSource: this.props.selectedDataSource });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.metricInfo !== this.state.metricInfo) {
            this.setState({ metricInfo: this.props.metricInfo });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.timeFilterApplied !== this.state.timeFilterApplied) {
            this.setState({ timeFilterApplied: this.props.timeFilterApplied });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.startDate !== this.state.startDate) {
            this.setState({ startDate: this.props.startDate });
            this.loadAsyncVis(this.props.chartType);
        } else if (prevState.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
            this.loadAsyncVis(this.props.chartType);
        }
        else if (prevState.checkedAttributes !== this.state.checkedAttributes) {
            this.setState({ checkedAttributes: this.props.checkedAttributes });
            this.loadAsyncVis(this.props.chartType);
        }
        else if (prevState.checkedPartition !== this.state.checkedPartition) {
            this.setState({ checkedPartition: this.props.checkedPartition });
            this.loadAsyncVis(this.props.chartType);
        }
        else if (prevState.relationshipCheckFilter !== this.state.relationshipCheckFilter) {
            this.setState({ relationshipCheckFilter: this.props.relationshipCheckFilter });
            this.loadAsyncVis(this.props.chartType);
        }
        else if (prevState.relationshipCheckFilter !== this.state.relationshipCheckFilter) {
            this.setState({ relationshipCheckFilter: this.props.relationshipCheckFilter });
            this.loadAsyncVis(this.props.chartType);
        }
        else if (prevState.allowRelationsFilter !== this.state.allowRelationsFilter) {
            this.setState({ allowRelationsFilter: this.props.allowRelationsFilter });
            this.loadAsyncVis(this.props.chartType);
        }

    }


    chartHelpText() {
        this.setState({ showSliderPopover: true });
    }

    chartHelpTextHide() {
        this.setState({ showSliderPopover: false });
    }

    componentDidMount() {
        this.loadAsyncVis(this.props.chartType);
        // setTimeout(() => {
        //     this.setState({ showSliderPopover: true });
        // }, 2000);

    }

    loadAsyncVis(visType) {
        this.visPromise = visPromiseLookup[visType];
        this.visPromise()
            .then((renderVis) => {
                if (this.visPromise) {
                    this.setState({ renderVis }, this.renderVis);
                }
            })
            .catch((error) => {
                console.warn(error); // eslint-disable-line
            });
    }

    renderVis() {
        const hasVisPromise = !!this.state.renderVis;
        if (hasVisPromise) {
            this.state.renderVis(this);
        }
    }
    zoomIn() {
        if (document.getElementById("zoomlevel") !== null) {
            if (document.getElementById("zoomlevel").value < 10) {
                let zoomvalue = (parseInt(document.getElementById("zoomlevel").value) + 1) * parseInt(10)
                this.chartZoom(zoomvalue)
            }
        }
    }
    zoomOut() {
        if (document.getElementById("zoomlevel") !== null) {
            if (document.getElementById("zoomlevel").value > 1) {
                let zoomvalue = (parseInt(document.getElementById("zoomlevel").value) - 1) * parseInt(10)
                this.chartZoom(zoomvalue)
            }
        }
    }

    chartZoom(newchartzoom) {
        if (document.getElementById("zoomlevel") !== null) {
            let zoom = d3.zoom()
                .scaleExtent([1, 10])
                .on("zoom", zoom_actions)
            // .filter(() => {
            //     // const foundNode = this.N.findNodeByID(D3.event.srcElement.id.split('_')[1])
            //  })
            let graphzoomlevel = (newchartzoom / 10);
            document.getElementById("zoomlevel").value = graphzoomlevel;
            d3.select('svg')
                .transition()
                .call(zoom.scaleTo, graphzoomlevel);
            this.setState({ chartzoom: newchartzoom });
            function zoom_actions() {
                let g = d3.select('.everything');
                let width = g["_groups"][0][0]["attributes"][1]["value"]
                let height = g["_groups"][0][0]["attributes"][2]["value"]
                let trans = currentEvent.transform;
                let panX = parseInt(trans.x) > 10 ? 10 : parseInt(trans.x);
                let maxX = -(parseInt(trans.k) - 1) * parseInt(width - 10);
                let tx = parseInt(panX) < parseInt(maxX) ? parseInt(maxX) : parseInt(panX);

                let panY = parseInt(trans.y) > 10 ? 10 : parseInt(trans.y);
                let maxY = -(parseInt(trans.k) - 1) * parseInt(height - 10);
                let ty = parseInt(panY) < parseInt(maxY) ? parseInt(maxY) : parseInt(panY);

                // let tx = Math.min(0, Math.max(parseInt(trans.x), parseInt(width) - parseInt(width) * parseInt(trans.k)));
                // let ty = Math.min(0, Math.max(parseInt(trans.y), parseInt(height) - parseInt(height) * parseInt(trans.k)));
                if (trans !== "") {
                    let obj = currentEvent.transform;
                    obj.k = graphzoomlevel;
                    if (graphzoomlevel === 1) {
                        let obj = currentEvent.transform;
                        obj.x = 0;
                        obj.y = 0;
                    }
                    else {
                        let obj = currentEvent.transform;
                        obj.x = tx;
                        obj.y = ty;
                    }
                    g.transition().duration(10).attr("transform", obj)
                    // .on('dblclick.zoom', false)
                    // .on('wheel', function() {
                    //     currentEvent.preventDefault();
                    // });
                }
                // g.attr("transform", currentEvent.transform)
            }
        }
    }

    handleChartZoomChange = (newchartzoom) => {
        this.chartZoom(newchartzoom)
    }

    resetZoom() {
        this.chartZoom('10')
    }

    render() {
        // const { sliderValue } = this.state;
        if (this.props.page === "profile" || this.props.page === "profile_discover") {
            return (
                <>
                    <div className="chart-container">
                        {
                            this.state.showTextLegendArea ?
                                <div className="chart-info-outer">
                                    <div className="chart-info">
                                        <p>
                                            <i><FontAwesomeIcon icon={faInfoCircle} /></i>
                                            <span>Hover over an dataset/attribute to understand data quality metrics and errors in detail.
                                                To know more about this chart type in detail, <a className="mr-1" data-toggle="modal" href="#!" onClick={this.toggleHelpSection}>click here</a></span>
                                        </p>
                                    </div>
                                </div> : ""
                        }
                        {
                            this.state.showHelpSection ?
                                <VideoContent
                                    video_url={this.props.video_url}
                                    show={this.state.showHelpSection}
                                    onHide={this.toggleHelpSection}
                                    name={this.props.title}
                                /> : ""
                        }
                        {this.props.showChartFilter !== false ?
                            <div className="chart-filter">
                                <div className="checkbox-group">
                                    {this.props.showParititionFilter === true && (this.props.datasetLevelChart.length + this.props.attributeLevelChart.length) > 0 ?
                                        <Form.Group>
                                            <div className="custom-control custom-checkbox">
                                                <Form.Control
                                                    id={"include_partition_dataset"}
                                                    name={"include_partition_dataset"}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={this.state.checkedPartition}
                                                    onChange={(e) => {
                                                        this.setState({ allowPartition: !this.state.allowPartition })
                                                        this.props.allowPartition()
                                                    }}
                                                />
                                                <Form.Label className="custom-control-label" htmlFor={"include_partition_dataset"}>
                                                    <i className='mr-1'>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.71916 8.9392L2.57726 6.15301C2.14091 5.955 2.14091 5.2953 2.57726 5.09729L8.71953 2.3111C8.8076 2.27084 8.90337 2.25 9.00023 2.25C9.09709 2.25 9.19267 2.27084 9.28075 2.3111L15.4225 5.09755C15.8588 5.29556 15.8588 5.955 15.4225 6.15301L13.5447 7.00481C13.1954 6.83744 12.8103 6.75 12.404 6.75C11.6857 6.75 11.0334 7.02326 10.5282 7.52828C10.1988 7.85774 9.96794 8.24985 9.84727 8.68208L9.28056 8.9392C9.1013 9.02067 8.89842 9.02067 8.71916 8.9392ZM9.76158 9.65833L9.6292 9.71833C9.42979 9.80877 9.21812 9.85464 9.00005 9.85464C8.78197 9.85464 8.57048 9.80877 8.3709 9.71833L4.1093 7.78643L2.57726 8.48068C2.14091 8.67842 2.14091 9.33759 2.57726 9.53534L8.71916 12.3194C8.89842 12.4006 9.1013 12.4006 9.28056 12.3194L10.327 11.8451V11.0503C10.2596 10.9666 10.1968 10.8786 10.1388 10.7862C9.91521 10.4305 9.79692 10.0451 9.76158 9.65833ZM10.327 12.777L9.6292 13.0933C9.42979 13.1837 9.21812 13.2296 9.00005 13.2296C8.78197 13.2296 8.57048 13.1837 8.3709 13.0933L4.10344 11.1587L2.57726 11.8504C2.14091 12.0481 2.14091 12.7073 2.57726 12.905L8.71916 15.6891C8.89842 15.7703 9.1013 15.7703 9.28056 15.6891L9.87767 15.4185C9.79344 15.1583 9.75005 14.8825 9.75005 14.5961C9.75005 14.1248 9.86613 13.6523 10.1287 13.2226C10.1578 13.175 10.1882 13.1284 10.2199 13.0829C10.2541 13.0338 10.2898 12.9859 10.327 12.9393V12.777Z" fill="#212529" />
                                                            <path d="M12.4038 15.75C12.0865 15.75 11.8149 15.637 11.5889 15.4111C11.363 15.1851 11.25 14.9135 11.25 14.5962C11.25 14.375 11.3029 14.1779 11.4087 14.0048C11.5144 13.8317 11.6538 13.6923 11.8269 13.5865V10.399C11.6538 10.2933 11.5144 10.1562 11.4087 9.98798C11.3029 9.81971 11.25 9.625 11.25 9.40385C11.25 9.08654 11.363 8.8149 11.5889 8.58894C11.8149 8.36298 12.0865 8.25 12.4038 8.25C12.7212 8.25 12.9928 8.36298 13.2188 8.58894C13.4447 8.8149 13.5577 9.08654 13.5577 9.40385C13.5577 9.625 13.5048 9.81971 13.399 9.98798C13.2933 10.1562 13.1538 10.2933 12.9808 10.399V12.0577L15.2885 11.2933V10.399C15.1154 10.2933 14.976 10.1562 14.8702 9.98798C14.7644 9.81971 14.7115 9.625 14.7115 9.40385C14.7115 9.08654 14.8245 8.8149 15.0505 8.58894C15.2764 8.36298 15.5481 8.25 15.8654 8.25C16.1827 8.25 16.4543 8.36298 16.6803 8.58894C16.9062 8.8149 17.0192 9.08654 17.0192 9.40385C17.0192 9.625 16.9663 9.81971 16.8606 9.98798C16.7548 10.1562 16.6154 10.2933 16.4423 10.399V11.2788C16.4423 11.5288 16.3702 11.7548 16.226 11.9567C16.0817 12.1587 15.8894 12.2981 15.649 12.375L12.9808 13.2692V13.5865C13.1538 13.6923 13.2933 13.8317 13.399 14.0048C13.5048 14.1779 13.5577 14.375 13.5577 14.5962C13.5577 14.9135 13.4447 15.1851 13.2188 15.4111C12.9928 15.637 12.7212 15.75 12.4038 15.75Z" fill="#212529" />
                                                        </svg>
                                                    </i>Partition Dataset ({this.props.datasetLevelChart.length})
                                                </Form.Label>
                                            </div>
                                        </Form.Group> : ""
                                    }
                                    {this.props.showAttributeFilter === true && (this.props.datasetLevelChart.length + this.props.attributeLevelChart.length) > 0 ?
                                        <Form.Group>
                                            <div className="custom-control custom-checkbox">
                                                <Form.Control
                                                    id={"include_attributes"}
                                                    name={"include_attributes"}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={this.state.checkedAttributes}
                                                    // defaultChecked = {this.state.checkedAttributes}
                                                    // defaultValue = {this.state.checkedAttributes}
                                                    onChange={(e) => {
                                                        this.setState({ checkedAttributes: !this.state.checkedAttributes })
                                                        this.props.allowAttributes()
                                                    }}


                                                />
                                                <Form.Label className="custom-control-label" htmlFor={"include_attributes"}>
                                                    <i className='mr-1'>
                                                        <FontAwesomeIcon icon={faTag} />
                                                    </i>Attributes ({this.props.attributeLevelChart.length})
                                                </Form.Label>
                                            </div>
                                        </Form.Group> : ""
                                    }
                                </div>
                                {this.state.relationshipCheckFilter === true && this.state.checkedAttributes === true ?
                                    <div className="radio-group">
                                        <Form.Check
                                            custom
                                            name="relationshipcheck"
                                            type="radio"
                                            value={"All"}
                                            checked={this.state.allowRelationsFilter === "All"}
                                            id="all_atributes"
                                            label="All"
                                            onChange={this.relationshipApplyFilter}
                                        />
                                        <Form.Check
                                            custom
                                            name="relationshipcheck"
                                            type="radio"
                                            value={"Attributes having relationship only"}
                                            checked={this.state.allowRelationsFilter !== "All"}
                                            id="atributes_with_relationship"
                                            label="Attributes having relationship only"
                                            onChange={this.relationshipApplyFilter}
                                        />
                                    </div> : ""}
                            </div> : ""}
                        {/* Start: RC Slider */}
                        {(this.props.showZoom === true && this.props.datasetLevelChart.length + this.props.attributeLevelChart.length > 0) || (this.props.chartType === "hierarchicalEdgeBundlingDiscover") ?
                            <div className="chart-resize-box">
                                <div className="chart-resize-slider">
                                    <div className="cr-slider__reset" onClick={this.resetZoom}>
                                        <i className="icon-reset" title="Reset">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="frame_reload_FILL0_wght300_GRAD0_opsz24 1">
                                                    <path id="Vector" d="M12 16.8461C10.8602 16.8461 9.85704 16.4958 8.99038 15.7952C8.12371 15.0945 7.56089 14.2141 7.30193 13.1538H8.5442C8.7865 13.8936 9.21823 14.4952 9.8394 14.9587C10.4606 15.4221 11.1807 15.6538 12 15.6538C13.0115 15.6538 13.8734 15.2977 14.5856 14.5856C15.2977 13.8734 15.6538 13.0115 15.6538 12C15.6538 10.9884 15.2977 10.1266 14.5856 9.4144C13.8734 8.70222 13.0115 8.34613 12 8.34613C11.4525 8.34613 10.9352 8.46408 10.448 8.69998C9.96084 8.93588 9.53648 9.25383 9.17495 9.65383H10.8461V10.8461H7.15383V7.15383H8.34613V8.79038C8.80894 8.29936 9.35638 7.90384 9.98843 7.60383C10.6205 7.30383 11.291 7.15383 12 7.15383C13.3384 7.15383 14.4807 7.6269 15.4269 8.57305C16.3731 9.51922 16.8461 10.6615 16.8461 12C16.8461 13.3384 16.3731 14.4807 15.4269 15.4269C14.4807 16.3731 13.3384 16.8461 12 16.8461ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V15H4.99997V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19H8.99998V20.5H5.3077ZM15 20.5V19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V15H20.5V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H15ZM3.5 8.99998V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H8.99998V4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V8.99998H3.5ZM19 8.99998V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H15V3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V8.99998H19Z" fill="currentColor" />
                                                </g>
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="cr-slider__main">
                                        {/* <span className="slider-chartzoom">{this.state.chartzoom}%</span> */}
                                        <i className="icon-zoomin" onClick={this.zoomIn}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="icon_zoom_in" clip-path="url(#clip0_3129_26367)">
                                                    <path id="Vector" d="M15.4999 13.9996H14.7099L14.4299 13.7296C15.6299 12.3296 16.2499 10.4196 15.9099 8.38965C15.4399 5.60965 13.1199 3.38965 10.3199 3.04965C6.0899 2.52965 2.5399 6.08965 3.0499 10.3196C3.3899 13.1196 5.6099 15.4396 8.3899 15.9096C10.4199 16.2496 12.3299 15.6296 13.7299 14.4296L13.9999 14.7096V15.4996L18.2599 19.7496C18.6699 20.1596 19.3299 20.1596 19.7399 19.7496L19.7499 19.7396C20.1599 19.3296 20.1599 18.6696 19.7499 18.2596L15.4999 13.9996ZM9.4999 13.9996C7.0099 13.9996 4.9999 11.9896 4.9999 9.49965C4.9999 7.00965 7.0099 4.99965 9.4999 4.99965C11.9899 4.99965 13.9999 7.00965 13.9999 9.49965C13.9999 11.9896 11.9899 13.9996 9.4999 13.9996ZM9.4999 6.99965C9.2199 6.99965 8.9999 7.21965 8.9999 7.49965V8.99965H7.4999C7.2199 8.99965 6.9999 9.21965 6.9999 9.49965C6.9999 9.77965 7.2199 9.99965 7.4999 9.99965H8.9999V11.4996C8.9999 11.7796 9.2199 11.9996 9.4999 11.9996C9.7799 11.9996 9.9999 11.7796 9.9999 11.4996V9.99965H11.4999C11.7799 9.99965 11.9999 9.77965 11.9999 9.49965C11.9999 9.21965 11.7799 8.99965 11.4999 8.99965H9.9999V7.49965C9.9999 7.21965 9.7799 6.99965 9.4999 6.99965Z" fill="currentColor" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_3129_26367">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </i>
                                        <div className="rc-slider-wrap rc-vertical-slider">
                                            <div className="rc-slider-container">
                                                <Slider
                                                    min={10}
                                                    step={10}
                                                    max={100}
                                                    vertical
                                                    value={this.state.chartzoom}
                                                    onChange={this.handleChartZoomChange}
                                                />
                                            </div>
                                        </div>
                                        <i className="icon-zoomout" onClick={this.zoomOut}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="icon_zoom_out" clip-path="url(#clip0_4316_4074)">
                                                    <g id="Vector">
                                                        <path d="M15.4999 13.9996H14.7099L14.4299 13.7296C15.6299 12.3296 16.2499 10.4196 15.9099 8.38965C15.4399 5.60965 13.1199 3.38965 10.3199 3.04965C6.0899 2.52965 2.5399 6.08965 3.0499 10.3196C3.3899 13.1196 5.6099 15.4396 8.3899 15.9096C10.4199 16.2496 12.3299 15.6296 13.7299 14.4296L13.9999 14.7096V15.4996L18.2599 19.7496C18.6699 20.1596 19.3299 20.1596 19.7399 19.7496L19.7499 19.7396C20.1599 19.3296 20.1599 18.6696 19.7499 18.2596L15.4999 13.9996ZM9.4999 13.9996C7.0099 13.9996 4.9999 11.9896 4.9999 9.49965C4.9999 7.00965 7.0099 4.99965 9.4999 4.99965C11.9899 4.99965 13.9999 7.00965 13.9999 9.49965C13.9999 11.9896 11.9899 13.9996 9.4999 13.9996Z" fill="currentColor" />
                                                        <path d="M6.9999 9.49965C6.9999 9.21965 7.2199 8.99965 7.4999 8.99965H11.4999C11.7799 8.99965 11.9999 9.21965 11.9999 9.49965C11.9999 9.77965 11.7799 9.99965 11.4999 9.99965H7.4999C7.2199 9.99965 6.9999 9.77965 6.9999 9.49965Z" fill="currentColor" />
                                                    </g>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4316_4074">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </i>
                                    </div>
                                </div>
                            </div> : ""}
                        {/* End: RC Slider */}
                        <div id={this.props.id} className={this.props.classValue}></div>
                    </div>
                    {this.state.showSliderPopover === true ?
                        <div className="chart-resize-slider-tour">
                            <div className="tour-backdrop"></div>
                            <div className={this.state.showSliderPopover ? "cr-slider-popover show" : "cr-slider-popover"}>
                                <div className="popover-header">
                                    <h3 className="popover-title">Zoom and Reset Controls</h3>
                                </div>
                                <div className="popover-content">
                                    <p>To improve your chart visualization, use the draggable slide range to zoom in or out, and click the 'Reset' button icon to return to the default view</p>
                                </div>
                                <div className="popover-footer">
                                    <button onClick={this.chartHelpTextHide} className="btn btn-light btn-circle">Got It</button>
                                </div>
                            </div>
                        </div> : ""
                    }

                </>
            );
        } else {
            return (
                <>
                    <div id={this.props.id} className={this.props.classValue}></div>
                </>
            );
        }

    }
}

export default ChartBody;