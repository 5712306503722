import {call_get_api, call_post_api, postUIlogs} from "./event_handling";
import {  ui_service_ip } from "./ip_details";
import moment from 'moment';
import {MAX_UNREAD_PER_PAGE} from "./constant";
import {
    attributeIDFromMappedValue,
    attributeNameFromMappedValue,
    datasetIDFromMappedValue,
    METADATA_MAP_KEY_SEPARATOR, 
    METADATA_MAP_VALUE_SEPARATOR
} from "./attribute_name_utils";

let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00');
let endDate = moment().format('YYYY-MM-DD 23:59:59');

export function preFetchRelationshipData (
    tenantData, setDataSource, setMlDataSource, addRelationship,
    addDataSource, addInfo, addLastProfilingTime, setStartDate, setEndDate, addDiscoveryStatus, addProcessingStatus, noIntegration, store
) {
    let page = 1;
    tenantData["page"] = page;
    call_post_api(ui_service_ip, "profile/data/relationship/latest", tenantData).then(
            (result) => {
                if (result !== undefined && result !== null && result.integration !== null ) {
                    let profileRequestData = {
                        "integrations": [result.integration],
                        "tenant_id": tenantData.tenant_id,
                        "payload_start_time": startDate,
                        "payload_end_time": endDate
                    }
                    call_post_api(ui_service_ip, "profile/info/latest", profileRequestData).then(
                        (profileResult) => {
                            let infoData = {};
                            if (profileResult !== undefined && profileResult !== null) {
                                infoData[result.integration] = profileResult.response;
                                if (store !== undefined) {
                                    store.dispatch(addInfo(infoData));
                                    store.dispatch(setDataSource(result.integration));
                                    store.dispatch(setMlDataSource(result.integration));
                                } else {
                                    addInfo(infoData);
                                    setDataSource(result.integration);
                                    setMlDataSource(result.integration);
                                }
                                }
                        }
                    );
                    if (store !== undefined) {
                        store.dispatch(addRelationship(result.integration, result[result.integration]));
                        if (result.count.length === 0 || result.integration === undefined){
                             store.dispatch(noIntegration(true));
                        }
                        else{
                             store.dispatch(noIntegration(false));
                        }
                        store.dispatch(addDataSource(result.integration, result.integration_name));
                        store.dispatch(addDiscoveryStatus(result.integration, result.status_text));
                        store.dispatch(addProcessingStatus(result.integration, result.latest_processing_status));
                    } else {
                        addRelationship(result.integration, result[result.integration]);
                        if (result.count.length === 0 || result.integration === undefined){
                            noIntegration(true);
                        }
                        else{
                            noIntegration(false);
                        }
                        addDataSource(result.integration, result.integration_name);
                        addDiscoveryStatus(result.integration, result.status_text);
                        addProcessingStatus(result.integration, result.latest_processing_status);
                    }
                    let firstPage = "yes"
                    getLastProfilingTime(firstPage, addLastProfilingTime, tenantData.tenant_id, setStartDate, setEndDate, store);
                    let totalPage = result.count;
                    firstPage = "no"
                    while (page < totalPage ) {
                        page = page + 1;
                        tenantData["page"] = page;
                        call_post_api(ui_service_ip, "profile/data/relationship/latest", tenantData).then(
                            (pageResult) => {
                                if (pageResult !== null && pageResult !== undefined) {
                                    let profileRequestData = {
                                        "integrations": [pageResult.integration],
                                        "tenant_id": tenantData.tenant_id,
                                        "payload_start_time": startDate,
                                        "payload_end_time": endDate
                                    }
                                    if (store !== undefined) {
                                              if (pageResult.count.length === 0 || pageResult.integration === undefined){
                                                    store.dispatch(noIntegration(true));
                                              }
                                              else{
                                                    store.dispatch(noIntegration(false));
                                               }
                                              store.dispatch(addDataSource(pageResult.integration, pageResult.integration_name));
                                              store.dispatch(addDiscoveryStatus(pageResult.integration, pageResult.status_text));
                                              store.dispatch(addProcessingStatus(pageResult.integration, pageResult.latest_processing_status));
                                    } else {
                                              if (pageResult.count.length === 0 || pageResult.integration === undefined){
                                                    noIntegration(true);
                                              }
                                              else{
                                                    noIntegration(false);
                                              }
                                              addDataSource(pageResult.integration, pageResult.integration_name);
                                              addDiscoveryStatus(pageResult.integration, pageResult.status_text);
                                              addProcessingStatus(pageResult.integration, pageResult.latest_processing_status);
                                    }
                                    call_post_api(ui_service_ip, "profile/info/latest", profileRequestData).then(
                                        (profileResult) => {
                                            let infoData = {};
                                            if (profileResult !== undefined && profileResult !== null) {
                                                infoData[pageResult.integration] = profileResult.response;
                                                if (store !== undefined) {
                                                    store.dispatch(addInfo(infoData));
                                                } else {
                                                    addInfo(infoData);
                                                }                                                                                                

                                            }
                                        }
                                    );
                                    if (store !== undefined) {
                                        store.dispatch(addRelationship(pageResult.integration, pageResult[pageResult.integration]));
                                    } else {
                                        addRelationship(pageResult.integration, pageResult[pageResult.integration]);
                                    }
                                }
                            }
                        );
                    }
                }
            }
    )
}

export function preFetchMetaData(
    tenantData, addMetaData, addEnvironment,
    addEnvironmentDataSourceMapping, addDataSourceDataSetMapping,
    addDataSetAttributeMapping, setDataSet, setAttribute, setDataSource, setMlDataSource, addRelationship,
    addDataSource, addInfo, addLastProfilingTime, setStartDate, setEndDate, addDiscoveryStatus, addProcessingStatus, noIntegration, store,retries=3
) {

    call_get_api(ui_service_ip, "metadata/mapping/latest",{"tenant_id":tenantData["tenant_id"]}).then(
        (result) => {
            if (result !== undefined && result !== null) {
                for (const [key, value] of Object.entries(result.meta_data)) {
                    let dataSetId = datasetIDFromMappedValue(key);
                    let attributeId = attributeIDFromMappedValue(key);
                    let attributeName = attributeNameFromMappedValue(value);
                    if (store !== undefined) {
                        store.dispatch(addMetaData(result.meta_data));
                        store.dispatch(setDataSet(dataSetId));
                        store.dispatch(setAttribute(attributeId, attributeName));
                    } else {
                        addMetaData(result.meta_data);
                        setDataSet(dataSetId);
                        setAttribute(attributeId, attributeName);
                    }
                    break;
                }
                let firstPage = "yes"
                getLastProfilingTime(firstPage, addLastProfilingTime, tenantData.tenant_id, setStartDate, setEndDate, store);
                // preFetchRelationshipData(
                //     tenantData, setDataSource, setMlDataSource, addRelationship,
                //     addDataSource, addInfo, addLastProfilingTime,setStartDate, setEndDate, addDiscoveryStatus, addProcessingStatus, noIntegration, store
                // );
                formEnvData(
                    result.meta_data, addEnvironment, addEnvironmentDataSourceMapping,
                    addDataSourceDataSetMapping, addDataSetAttributeMapping, store
                );
            }
            else {
            if (retries > 0) {
                preFetchMetaData(
                tenantData, addMetaData, addEnvironment,
                addEnvironmentDataSourceMapping, addDataSourceDataSetMapping,
                addDataSetAttributeMapping, setDataSet, setAttribute, setDataSource, setMlDataSource, addRelationship,
                addDataSource, addInfo, addLastProfilingTime, setStartDate, setEndDate, addDiscoveryStatus, addProcessingStatus, noIntegration, store,retries - 1)
            }
            }
    }
    );
}

export function preFetchUserNotifications(tenantData, setUserNotificationToState, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }
    let data = {tenant_id:tenantID,count:MAX_UNREAD_PER_PAGE}
    call_get_api(ui_service_ip, "user_notification/unread",data).then(
        (result) => {
            if (result === undefined || result === null) {
                if (retries > 0) {
                preFetchUserNotifications(tenantData, setUserNotificationToState, store,retries-1)
                 }
                const error = `Unable to fetch user notification for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }

            result["page"] = 1;
            if (store !== undefined) {
                store.dispatch(setUserNotificationToState(result));
            } else {
                setUserNotificationToState(result);
            }
        }
    );
}


function formEnvData(
    metadata, addEnvironment, addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping, addDataSetAttributeMapping, store
) {
    let envData = {};
    let dataSourceInfo = {};
    let dataSetInfo = {};
    let attributeInfo = {};
    for (let item in metadata) {
        let idList = item.split(METADATA_MAP_KEY_SEPARATOR);
        if (idList.length === 3){
            continue;
        }

        let envId = idList[0];
        if (!(envId in envData)) {
            envData[envId] = metadata[item].split(METADATA_MAP_VALUE_SEPARATOR)[0];
            dataSourceInfo[envId] = [];
        }
        let dataSourceId = idList[1];
        if (dataSourceInfo[envId].indexOf(dataSourceId) === -1) {
            dataSourceInfo[envId].push(dataSourceId);
            dataSetInfo[dataSourceId] = [];
        }
        let dataSetId = idList[2];
        if (dataSetInfo[dataSourceId].indexOf(dataSetId) === -1) {
            dataSetInfo[dataSourceId].push(dataSetId);
            attributeInfo[dataSetId] = [];
        }
        let attributeId = idList[3];
        if (attributeId!== undefined && attributeInfo[dataSetId].indexOf(attributeId) === -1) {
            attributeInfo[dataSetId].push(attributeId)
        }
    }
    if (store !== undefined) {
        store.dispatch(addEnvironment(envData));
        store.dispatch(addEnvironmentDataSourceMapping(dataSourceInfo));
        store.dispatch(addDataSourceDataSetMapping(dataSetInfo));
        store.dispatch(addDataSetAttributeMapping(attributeInfo));
    } else {
        addEnvironment(envData);
        addEnvironmentDataSourceMapping(dataSourceInfo);
        addDataSourceDataSetMapping(dataSetInfo);
        addDataSetAttributeMapping(attributeInfo);
    }
}

function getLastProfilingTime(firstPage, addLastProfilingTime, tenantId, setStartDate, setEndDate, store){
    let endPoint = "metrics_store_payload_created_time";
    let data = {"tenant_id":tenantId}
    call_get_api(ui_service_ip, endPoint,data).then((response) => {
        if (response !== undefined && response.code === 200) {         
            let results = response["results"];
            if (results !== null && results.length > 0){
                for (let index = 0; index < results.length; index++) {   
                    let integrationId = results[index]["integration_id"]               
                    if (store !== undefined) {
                    store.dispatch(addLastProfilingTime(integrationId, results[index]["last_processed_time"]));
                } else {
                    addLastProfilingTime(integrationId, results[index]["last_processed_time"]);
                }
                if (firstPage === "yes" ) {
                    if(store !== undefined) {
                        store.dispatch(setStartDate(moment(results[index]["last_processed_time"])))
                        store.dispatch(setEndDate(moment(results[index]["last_processed_time"])))
                    } else {
                      setStartDate(moment(results[index]["last_processed_time"]))
                      setEndDate(moment(results[index]["last_processed_time"]))
                    }
                    firstPage = false
                }
            }

            }            

            }
        }
    );
}


export function prefetchDatasetDetails(tenantData, addDatasetVersions, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    let data = {"tenant_id" : tenantID};
    call_get_api(ui_service_ip, "/datasets",data).then(
        (result) => {
            if (result === undefined || result === null) {
                if (retries > 0) {
                prefetchDatasetDetails(tenantData, addDatasetVersions, store,retries-1)
                 }
                const error = `Unable to fetch dataset version details for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            if (store !== undefined) {
                store.dispatch(addDatasetVersions(result.datasets));
            } else {
                addDatasetVersions(result.datasets);
            }
        }
    );
}


export function prefetchRecencyDetails(tenantData, addRecencyDetails, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    const endPoint = "recency/all";
    let data = {"tenant_id":tenantID, "type":"profile"};
    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result === undefined || result === null) {
                if (retries > 0) {
                prefetchRecencyDetails(tenantData, addRecencyDetails, store,retries-1)
                 }
                const error = `Unable to fetch recency details for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            if (store !== undefined) {
                store.dispatch(addRecencyDetails(result.profile_res));
            } else {
                addRecencyDetails(result.profile_res);
            }
        }
    );
}


export function prefetchModelABData(tenantData, addModelABDataToList, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    const endPoint = `/model_ab_data`;
    call_get_api(ui_service_ip, endPoint).then(
        (result) => {
            if (result === undefined || result === null || result.code !== 200) {
                if (retries > 0) {
                prefetchModelABData(tenantData, addModelABDataToList, store,retries-1)
                 }
                const error = `Unable to fetch model_ab_data for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            if (store !== undefined) {
                store.dispatch(addModelABDataToList(result.model_ab_data, result.model_info_map));
            } else {
                addModelABDataToList(result.model_ab_data, result.model_info_map);
            }
        }
    );
}


export function prefetchRecentHistory(tenantData, addRecentHistory, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    const endPoint = "recent_search";
    call_get_api(ui_service_ip, endPoint,{"tenant_id":tenantID}).then(
        (result) => {
            if (result === undefined || result === null) {
                if (retries > 0) {
                prefetchRecentHistory(tenantData, addRecentHistory,store,retries-1)
                 }
                const error = `Unable to fetch recent search history for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            let searchResults = Object.values(result.search_results)
            if (store !== undefined) {
                    store.dispatch(addRecentHistory(searchResults));
            } else {
                    addRecentHistory(searchResults);
            }
        }
    );
}


export function prefetchDataSize(tenantData, addDataSize, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    const endPoint = "data_size";
    call_get_api(ui_service_ip, endPoint,{"tenant_id":tenantID}).then(
        (result) => {
            if (result === undefined || result === null) {
                if (retries > 0) {
                prefetchDataSize(tenantData, addDataSize, store,retries-1)
                 }
                const error = `Unable to fetch data size for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            let dataSize = result.data_size
            if (store !== undefined) {
                    store.dispatch(addDataSize(dataSize));
            } else {
                    addDataSize(dataSize);
            }
        }
    );
}


export function preFetchMetadataDetails(tenantData, storeMetadataDetails, store,retries=3) {
    const tenantID = tenantData["tenant_id"];
    if (tenantID === undefined || tenantID === null) {
        return;
    }

    const endPoint = "alert_applicable_metrics";
    call_get_api(ui_service_ip, endPoint,{"tenant_id":tenantID}).then(
        (result) => {
            if (result === undefined || result === null) {
                const error = `Unable to fetch metadata details for tenant: ${tenantID}`;
                postUIlogs(error);
                return;
            }
            let metadataDetails = result.metadata_details
            if (store !== undefined) {
                    store.dispatch(storeMetadataDetails(metadataDetails));
            } else {
                storeMetadataDetails(metadataDetails);
            }
        }
    );
}

export function formData(mappedDatasetInfo, data_mappedDatasetInfo, store) {
    if (data_mappedDatasetInfo !== undefined){
      if(store !== undefined){
        store.dispatch(mappedDatasetInfo(data_mappedDatasetInfo));
      } 
      else {
        mappedDatasetInfo(data_mappedDatasetInfo);
      }
    }
  }