import React from "react";
import Image from "../../components/image";
import {customDateFormat} from "../../utils/common_utils";


class PlanChangeRequest extends React.Component {
   constructor(props) {
        super(props);
        this.state = {
         }
    }

    render() {
        let change_request_id = this.props.change_request["change_request_id"];
        let change_on = customDateFormat(this.props.change_request["change_on"]);
        let plan_details = this.props.change_request["plan_details"];

        return (
           <div className="row row-sm">
            <div className="col-xl-7">
                <div className="portlet">
                <div className="portlet-title">
                <div className="caption">
                <span className="text-uppercase font-dark">Plan Change Request</span>
                </div>
                </div>
                <div className="portlet-body">
                <div className="fancy-card">
                    <div className="row no-gutters">
                    <div className="col-sm-8">
                        <h6 className="card-subtitle text-muted">Request to downgrade the current plan</h6>
                    </div>
                    <div className="col-sm-4">
                        <div className="d-sm-flex justify-content-sm-end">
                        <span className="badge">Starts from</span>
                        <span className="payment__due-date">{change_on}</span>
                    </div>
                    </div>
                    </div>
                    <div className="card__payment-reminder">
                    <div className="card-body">
                        <div className="row no-gutters">
                        <div className="col-sm-7">
                            <div className="payment-plan payment-plan__starter">
                            <div className="icon-box">
                                <Image src="plan_starter" alt="" />
                            </div>
                            <div className="payment-plan__info">
                                <h5><strong>${plan_details["price"]}</strong> / {plan_details["plan_cycle"]}</h5>
                                <p>Qualdo™ - {plan_details["plan_name"]}</p>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="payment-plan__action">
                              <button type="button" onClick={(e) => this.props.showCancelPlanChangeRequest(change_request_id)} className="btn btn-outline btn-grey btn-circle">Cancel my Schedule</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }
}

export default PlanChangeRequest;
