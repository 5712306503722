import React, {useState} from "react";
import ModelABEditActions from "./modelABEditActions";

export function ModelABEdit(props) {
    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);

    const [edit, setEdit] = useState(false);
    const closeEdit = () => setEdit(false);
    const showEdit = () => setEdit(true);

    const [deleteWarningStatus, setDeleteWarning] = useState(false);
    const closeDeleteWarning = () => setDeleteWarning(false);
    const enableDeleteWarning = () => setDeleteWarning(true);

    return (
        <ModelABEditActions
            modelABTableProps={props.modelABTableProps}
            view_details={props.data}
            showToast={props.showToast}
            component="ModelAB"
            view={view}
            viewClose={viewClose}
            deleteWarningStatus={deleteWarningStatus}
            closeDeleteWarning={closeDeleteWarning}
            enableDeleteWarning={enableDeleteWarning}
            viewShow={viewShow}
            edit={edit}
            closeEdit={closeEdit}
            showEdit={showEdit}
            delete_component="delete_model_ab"
            handleABDeleteInParent={props.handleModelABDelete}
        />
    );

}
