import React from 'react';
import { faLock, faDatabase, faHandshake, faRocket, faCog, faUserCog, faCube, faHourglassHalf, faChartArea, faPlusCircle, faUserCircle, faBell, faUserFriends,faTachometerAlt,faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faSearchengin} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const faIconMapping = {
    'cog': faCog,
    'cube': faCube,
    'hourGlass': faHourglassHalf,
    'chartArea': faChartArea,
    'plusCircle': faPlusCircle,
    'userCog': faUserCog,
    'userCircle': faUserCircle,
    'quickStart': faRocket,
    'notifications': faBell,
    'privacy': faLock,
    'cookie': faDatabase,
    'freeTrial': faHandshake,
    'userFriends':  faUserFriends,
    'faSearchEngine': faSearchengin,
    'tachometer':faTachometerAlt,
    'faSitemap':faSitemap
};

class BreadCrumb extends React.Component {
    render() {
        let icon = faIconMapping[this.props.icon];
        return (
            <div className="qd-subheader">
                <div className="container-fluid">
                    <div className="qd-subheader__main">
                        {this.props.sidenav ? <span id="sidenav-toggler" className="navbar-toggler-icon d-lg-none d-md-block" onClick={this.props.showSidenav}></span> : ''}
                        <div className="qd-subheader__breadcrumbs">
                            <div  className="qd-subheader__breadcrumbs-icon"><i><FontAwesomeIcon icon={icon}/></i></div>
                            <div  className="qd-subheader__breadcrumbs-link">{this.props.title} </div>
                            {
                                this.props.separator ?
                                    <>
                                        <span className="qd-subheader__breadcrumbs-separator"></span>
                                        <div className="qd-subheader__breadcrumbs-link">{this.props.separatorData} </div>
                                    </> : ''
                             }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BreadCrumb;