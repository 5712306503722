import React from 'react';
import { IS_NONE_SAAS_EDITION } from "../utils/constant"
let content;
let edition;
if (IS_NONE_SAAS_EDITION) {
    content = "Detect data drifts and outliers in cloud data warehouses.";
    edition="-DRX"
} else {
    content = "Detect data drifts and decays for your Models.";
    edition=""
}
class Banner extends React.Component {

    render() {
        return (
            <div className="col prelogin-info-col">
                <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle">
                        <div className="login_intro prelogin-pattern">
                           <h1>Eliminate high-stake
                                <br/>data issues in minutes
                            </h1>
                            <p>
                                Use Qualdo™{edition} to identify, monitor, troubleshoot data issues
                                and errors in your favorite modern databases. {content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;