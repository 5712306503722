import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import NavigationHeader from '../components/navigationHeader';
import {CopyRightsFooter} from '../components/copyrights';
import Image from './image';


let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO
class FreeTrialAgreement extends React.Component {

    render() {
              let sessionToken = localStorage.getItem('sessionToken');
              return (
                <>
                {sessionToken === null ?
                    <header className="qd-header">
                    <div className="qd-header-container navbar navbar-expand-md">
                        <div className="qd-header__brand">
                            <a href="#!">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src={BRAND_LOGO}
                                />
                            </a>
                        </div>
                    </div>
                    </header>
                : <NavigationHeader/>
                }
                <main>
                    <BreadCrumb icon='freeTrial' title='Free-Trial Subscription Agreement' />
                        <div className="qd-container">
                                <div className="qd-body no-tabs p-4">
                                        <div className="policy-doc">
                                            <div className="policy-doc">
                                                <p>
                                                  This Free-Trial Subscription Agreement (this <span className="highlight-text">"Agreement"</span>) contains
                                                  terms and conditions that
                                                  govern your acquisition of subscriptions to, and use of, the Free-Trial Services (as defined
                                                  below), and is a contract between Qualdo™, Inc., a Delaware (USA) corporation (<span className="highlight-text">"Qualdo™"</span>),
                                                  and you or the entity or organization that you represent.
                                                </p>
                                                <p>
                                                  If you are an individual using the Free-Trial Services for your own purposes: (1) all
                                                  references to <span className="highlight-text">"Customer"</span> are to you and (2) you
                                                  represent and warrant that you are at least
                                                  18 years of age, or have otherwise reached the age of "majority" where you reside, and that
                                                  you have the right, power and authority to enter into this Agreement.
                                                </p>
                                                <p>
                                                  If you are using the Free-Trial Services on behalf of an entity or organization that you
                                                  represent: (1) all references to <span className="highlight-text">"Customer"</span> are
                                                  to that entity or organization and (2) you
                                                  represent and warrant that you are at least 18 years of age, or have otherwise reached the
                                                  age of "majority" where you reside, and that you have the right, power and authority to
                                                  enter into this Agreement on behalf of Customer.
                                                </p>
                                                <h4>This Agreement becomes binding and effective on Customer upon
                                                  the earliest of:
                                                </h4>
                                                <ul className="trial-list">
                                                  <li> when you access or use the Free-Trial Services.</li>
                                                  <li> when you click an "I Accept," "Sign up" or similar button or check box referencing this
                                                    Agreement, or
                                                  </li>
                                                  <li> when you enter into a Free-Trial Order (as defined below) with Qualdo™.
                                                  </li>
                                                </ul>
                                                <p>
                                                  Capitalized terms not otherwise defined in this Agreement will have the respective meanings
                                                  assigned to them in Section 24. Qualdo™ may modify this Agreement from time to time, subject
                                                  to the terms in Section 26 below.
                                                </p>
                                                <ul className="trial-list">
                                                  <li>Free-Trial Orders</li>
                                                  <li>Access and Use</li>
                                                  <li>Support</li>
                                                  <li>Free-Trial Subscription</li>
                                                  <li>APIs and Tools</li>
                                                  <li>Hosting and Other Provider</li>
                                                  <li>Security and Privacy</li>
                                                  <li>Customer Responsibilities and Restrictions</li>
                                                  <li>Compliance with Applicable Laws</li>
                                                  <li>Ownership</li>
                                                  <li>Confidentiality</li>
                                                  <li>Disclaimers</li>
                                                  <li>Term and Termination</li>
                                                  <li>Indemnification</li>
                                                  <li>Limitations of Liability</li>
                                                  <li>Publicity</li>
                                                  <li>Notices</li>
                                                  <li>Assignment</li>
                                                  <li>U.S. Government Customers</li>
                                                  <li>Independent Parties; No Third-Party Beneficiaries</li>
                                                  <li>Force Majeure</li>
                                                  <li>Governing Law; Venue</li>
                                                  <li>Miscellaneous</li>
                                                  <li>Definitions</li>
                                                  <li>Counterparts</li>
                                                  <li>Changes to this Agreement</li>
                                                </ul>
                                                <h4>1. Free-Trial Orders.
                                                </h4>
                                                <p>
                                                  This Agreement sets forth the terms pursuant to which Customer may access and use the
                                                  Free-Trial Services in connection with one or more Free-Trial Orders. Subject to the terms
                                                  of a Free-Trial Order, the Free-Trial Services will support Customer's collection,
                                                  monitoring, management and analysis of data generated by systems, platforms, services,
                                                  software, devices, sites and/or networks that Customer uses in its own internal business
                                                  operations (collectively, but exclusive of all Free-Trial Services and Paid Services,
                                                  <span className="highlight-text">"Customer's Environment"</span>).
                                                </p>
                                                <h4>2. Access and Use.</h4>
                                                <p>
                                                  2.1. Subject to the applicable Free-Trial Order and this Agreement, Qualdo hereby grants to
                                                  Customer the right to access and use the Free-Trial Services in accordance with the
                                                  Documentation during the Free-Trial Term for Customer's Environment.
                                                </p>
                                                <p>
                                                  2.2. As between the Parties, Customer controls Customer's Environment and its individual
                                                  components (each, a <span className="highlight-text">"Customer Component"</span>),
                                                  whether owned, leased or licensed by Customer,
                                                  located on Customer's premises or cloud-based, used by Customer on a software-as-a-service
                                                  basis or otherwise. Customer will be able to use the Free-Trial Services by establishing
                                                  integrations or other connections to one or more Customer Components (each, a <span className="highlight-text">"Connection"</span>).
                                                  By implementing a Connection to a Customer Component, Customer hereby grants to Qualdo the
                                                  right, and is expressly instructing Qualdo, to access and interoperate with that Customer
                                                  Component during the Free-Trial Term in order to provide and support the Free-Trial
                                                  Services. Customer is responsible for complying with all applicable third-party terms,
                                                  policies and licenses governing its access and use of Customer Components and associated
                                                  data (collectively, <span className="highlight-text">"Third-Party Terms"</span>)
                                                </p>
                                                <p>
                                                  2.3. Through Customer's configuration and use of Connections and Free-Trial Services,
                                                  Customer has control over the types and amounts of data from Customer's Environment that are
                                                  submitted for Processing by the Services (collectively, <span className="highlight-text">"Customer
                                                    Data"</span>). By submitting
                                                  Customer Data to the Free-Trial Services, Customer hereby grants to Qualdo the right, and is
                                                  expressly instructing Qualdo, to Process Customer Data during the Free-Trial Term in order
                                                  to provide and support the Free-Trial Services and as otherwise provided in this Agreement.
                                                </p>
                                                <p>
                                                  2.4. All rights granted by each Party to the other under this Section 2 are limited,
                                                  nonexclusive and, except as otherwise provided in this Agreement, non-transferable.
                                                </p>
                                                <h4>3. Support</h4>
                                                <p>
                                                  Subject to this Agreement, Qualdo may, at its option, make Support to Authorized Users
                                                  through the Services and by email.
                                                </p>
                                                <h4>4. Free-Trial Subscription.</h4>
                                                <p>
                                                  Qualdo will make the Free-Trial Services available to Customer until the earliest of: (a)
                                                  the end of the period specified in the applicable Free-Trial Order; (b) the start date of
                                                  any Paid Order for the applicable Services; (c) termination by Qualdo, at any time, in its
                                                  sole discretion; or (d) termination by Customer pursuant to Section 26. Additional terms and
                                                  conditions, including Supplemental Terms, may apply to Free-Trial Services and Customer
                                                  agrees any such additional terms and conditions are incorporated into this Agreement by
                                                  reference and are legally binding.
                                                </p>
                                                <h4>5. APIs and Tools.</h4>
                                                <p>
                                                  One or more APIs will be available to Customer to assist with Customer's implementation of
                                                  Connections, and Qualdo makes client libraries available to facilitate Customer's coding
                                                  against the API(s). In addition, Authorized Users may install a Qualdo-produced software
                                                  agent on certain Customer Components to support Customer's collection of Customer Data. The
                                                  code for these libraries and agents (collectively, <span className="highlight-text">"Ancillary Tools"</span>)
                                                  are available in
                                                  public repositories at https://github.com/Qualdo and are subject to the applicable open
                                                  source licenses referenced in those repositories. Customer determines and controls what APIs
                                                  and Ancillary Tools (if any) to use in connection with the Free-Trial Services. By using an
                                                  API or Ancillary Tool in connection with the Free-Trial Services, Customer hereby agrees to
                                                  do so in accordance with the Documentation and, in the case of the Ancillary Tool, with the
                                                  applicable open source licenses (provided that if an applicable open source license for an
                                                  Ancillary Tool contradicts rights or restrictions in the Documentation, the license will
                                                  take precedence). The Ancillary Tools are not <span className="highlight-text">"Free-Trial Services"</span> or
                                                  <span className="highlight-text">"Support"</span> for
                                                  purposes of this Agreement.
                                                </p>
                                                <h4>6. Hosting and Other Providers.</h4>
                                                <p>
                                                  Qualdo uses third-party hosting providers, other service providers and Qualdo Affiliates to
                                                  support the provision of the Free-Trial Services and Support in the ordinary course of its
                                                  business, i.e., not specifically for Customer (collectively, <span className="highlight-text">"Ordinary Course
                                                    Providers"</span>).
                                                  Qualdo reserves the right to engage and substitute Ordinary Course Providers as it deems
                                                  appropriate, but shall: (a) remain responsible to Customer for the provision of the
                                                  Free-Trial Services and Support and (b) be liable for the actions and omissions of its
                                                  Ordinary Course Providers undertaken in connection with Qualdo's performance of this
                                                  Agreement to the same extent Qualdo would be liable if performing the Free-Trial Services or
                                                  Support directly. In no event shall providers of Customer Components be deemed Ordinary
                                                  Course Providers for any purpose under this Agreement.
                                                </p>
                                                <h4>7. Security and Privacy.
                                                </h4>
                                                <p>
                                                  7.1. As discussed in the Documentation, including at https://www.qualdo.ai/, each Party has
                                                  obligations with respect to the security of the Free-Trial Services and Customer Data.
                                                  Taking into account the nature and types of Customer Data, Qualdo will employ
                                                  administrative, physical and technical measures in accordance with applicable industry
                                                  practice to protect the Free-Trial Services and prevent the accidental loss or unauthorized
                                                  access, use, alteration or disclosure of Customer Data under its control during each
                                                  Free-Trial Term.
                                                </p>
                                                <p>
                                                  7.2. Customer is responsible for properly configuring the Free-Trial Services in accordance
                                                  with the Documentation, enabling single sign-on for Customer's accounts, and securing access
                                                  passwords, keys, tokens or other credentials used by Customer in connection with the
                                                  Free-Trial Services (collectively, <span className="highlight-text">"Customer
                                                    Credentials"</span>). Customer agrees to use
                                                  reasonable efforts to prevent unauthorized access or use of the Free-Trial Services and to
                                                  promptly notify Qualdo if Customer believes (a) any Customer Credentials have been lost,
                                                  stolen or made available to an unauthorized third party or (b) an unauthorized third party
                                                  has accessed the Free-Trial Services or Customer Data.
                                                </p>
                                                <p>
                                                  7.3. Except for limited Personal Information in Account Data, Qualdo does not require
                                                  Personal Information for Customer's access and use of the Free-Trial Services. Customer
                                                  shall limit Personal Information in Account Data to only that necessary for the creation and
                                                  administration of its Qualdo accounts. With regard to Customer Data, Customer shall not use
                                                  the Free-Trial Services to Process any Sensitive Information and shall use reasonable
                                                  efforts to restrict the inclusion of other Personal Information in Customer Data. The
                                                  Documentation provides further information on both filtering Personal Information from, and
                                                  masking Personal Information in, data before they are submitted to the Free-Trial Services.
                                                </p>
                                                <p>
                                                  7.4. Qualdo may Process information about Customer's configuration and use of the Free-Trial
                                                  Services ("Usage Data"), Customer Data and Account Data: (a) to manage Customer's account;
                                                  (b) to provide and improve the Free-Trial Services and Support, including to address
                                                  requests for Support and troubleshoot other issues; and (c) to provide Customer and
                                                  Authorized Users insights, service and feature announcements and other reporting. Qualdo may
                                                  also Process Usage Data that has been aggregated and/or anonymized (including, for clarity,
                                                  that does not allow a third party to identify Customer as the source of the information):
                                                  (i) to develop new services and features and (ii) to promote Qualdo's services, including,
                                                  for example, through analyses of patterns and trends. Qualdo's Processing of Usage Data,
                                                  Customer Data and Account Data shall at all times be subject to Qualdo's obligations under
                                                  this Agreement, including those of security under Section 7.1 and confidentiality under
                                                  Section 11; any applicable Supplemental Terms; and, with respect to Account Data, the
                                                  Privacy Policy.
                                                </p>
                                                <h4>8. Customer Responsibilities and Restrictions.</h4>
                                                <p>
                                                  8.1. Customer will be solely responsible for: (a) Customer's Environment, including as
                                                  necessary to enable Authorized Users' access and use of the Free-Trial Services; (b) Account
                                                  Data, Customer Data and Customer Credentials (including activities conducted with Customer
                                                  Credentials), subject to Qualdo's Processing obligations under this Agreement; (c) providing
                                                  any required notices to, and receiving any required consents and authorizations from,
                                                  Customer Component providers, Authorized Users and persons whose Personal Information may be
                                                  included in Account Data, Customer Data or Customer Credentials; and (d) ensuring use of the
                                                  Free-Trial Services is only for Customer's Environment and in accordance with the AUP,
                                                  Documentation and applicable Third-Party Terms.
                                                </p>
                                                <p>
                                                  8.2. No provision of this Agreement includes the right to, and Customer shall not, directly
                                                  or indirectly: (a) enable any person or entity other than Authorized Users to access and use
                                                  the Free-Trial Services; (b) attempt to gain unauthorized access to any Free-Trial Service
                                                  or its related systems or networks; (c) use any Free-Trial Service to access Qualdo
                                                  Intellectual Property Rights except as permitted under this Agreement; (d) modify, copy or
                                                  create any derivative work based upon a Free-Trial Service or any portion, feature or
                                                  function of a Free-Trial Service; (e) resell, distribute or otherwise make available any
                                                  Free-Trial Service to any third party, including as part of a managed services offering; (f)
                                                  except to the extent limited by Applicable Law, reverse engineer, disassemble or decompile
                                                  all or any portion of, or attempt to discover or recreate the source code for, the
                                                  Free-Trial Services or access or use the Free-Trial Services or Documentation in order to
                                                  (1) copy ideas, features, functions or graphics, (2) develop competing products or services,
                                                  or (3) perform competitive analyses; (g) remove, obscure or alter any proprietary notice
                                                  related to the Free-Trial Services; (h) send or store Malicious Code; (i) use or permit
                                                  others to use the Free-Trial Services in violation of Applicable Law; or (j) use or permit
                                                  others to use the Free-Trial Services other than as described in the applicable Free-Trial
                                                  Order, Documentation and this Agreement.
                                                </p>
                                                <p>
                                                  8.3. Qualdo reserves the right to investigate potential violations of the above provisions
                                                  of this Section 8. In the event Qualdo reasonably believes a violation has occurred, in
                                                  addition to any other remedies available at law or in equity, Qualdo will have the right to
                                                  suspend Authorized Users suspected of the violation from accessing the Free-Trial Services
                                                  for so long as is reasonably necessary to address the potential violation. For clarity,
                                                  Qualdo reserves the right, but does not assume any obligation to Customer, to take any of
                                                  the actions described in this Section 8.3.
                                                </p>
                                                <h4>9. Compliance with Applicable Laws.</h4>
                                                <p>
                                                  Customer agrees to comply with all Applicable Laws with respect to its performance of its
                                                  obligations and exercise of its rights under this Agreement. Without limiting the foregoing:
                                                </p>
                                                <p>
                                                  9.1. Customer shall comply with Applicable Laws concerning the privacy and protection of
                                                  Personal Information. Without limiting Section 8.1, Customer will be solely responsible for
                                                  providing any notices required by Applicable Law to, and receiving any consents and
                                                  authorizations required by Applicable Law from, persons whose Personal Information may be
                                                  included in Account Data, Customer Data or Customer Credentials.
                                                </p>
                                                <p>
                                                  9.2. Customer shall comply with Applicable Laws concerning anti-bribery and anti-corruption,
                                                  which may include the U.S. Foreign Corrupt Practices Act of 1977 and the UK Bribery Act
                                                  2010. As of the date of this Agreement and the date of each Free-Trial Order, Customer
                                                  represents that it has neither received nor been offered any illegal or improper bribe,
                                                  kickback, payment, gift or thing of value from any employee, agent or representative of
                                                  Qualdo or its Affiliates in connection with this Agreement. Customer agrees to promptly
                                                  notify Qualdo if it learns of any violation of the foregoing. This representation is not
                                                  intended to include customary and reasonable gifts and entertainment provided in the
                                                  ordinary course of business, to the extent such gifts and entertainment are permitted by
                                                  Applicable Law.
                                                </p>
                                                <p>
                                                  9.3. Customer shall (a) comply with Applicable Laws administered by the U.S. Commerce Bureau
                                                  of Industry and Security, U.S. Treasury Office of Foreign Assets Control or other
                                                  governmental entity imposing export controls and trade sanctions <span className="highlight-text">("Export
                                                    Laws")</span>, including
                                                  designating countries, entities and persons <span className="highlight-text">("Sanctions Targets")</span> and
                                                  (b) not directly or
                                                  indirectly export, re-export or otherwise deliver Free-Trial Services to a Sanctions Target,
                                                  or broker, finance or otherwise facilitate any transaction in violation of any Export Laws.
                                                  Customer represents that it is not a Sanctions Target or prohibited from receiving
                                                  Free-Trial Services pursuant to this Agreement under Applicable Laws, including Export Laws.
                                                </p>
                                                <h4>10. Ownership.</h4>
                                                <p>
                                                  As between the Parties: (a) Customer owns all right, title and interest in and to Customer's
                                                  Environment and Customer Data, including in each case all associated Intellectual Property
                                                  Rights, and (b) Qualdo owns all right, title and interest in and to the Paid Services,
                                                  Free-Trial Services, Documentation and Feedback, including in each case all associated
                                                  Intellectual Property Rights. Except for the rights expressly granted by one Party to the
                                                  other in this Agreement, all rights are reserved by the granting Party.
                                                </p>
                                                <h4>11. Confidentiality.</h4>
                                                <p>
                                                  11.1. As used in this Agreement, <span className="highlight-text">"Confidential
                                                    Information"</span> means any information disclosed
                                                  by one Party, its Affiliates, business partners or their respective employees, agents or
                                                  contractors (collectively, the <span className="highlight-text">"Discloser"</span>)
                                                  that is designated as confidential, either
                                                  orally or in writing, or that, given the nature of the information or circumstances
                                                  surrounding its disclosure, reasonably should be understood to be confidential. Confidential
                                                  Information includes without limitation: (a) Customer Data; (b) information relating to the
                                                  Discloser's or its Affiliates' technology, customers, business plans, promotional and
                                                  marketing activities, finances and other business affairs; (c) third-party information that
                                                  the Discloser is obligated to keep confidential; and (d) the terms of this Agreement and all
                                                  Free-Trial and Paid Orders. However, Confidential Information does not include any
                                                  information that: (i) was known to the Party that receives any Confidential Information (the
                                                  <span className="highlight-text">"Recipient"</span>) prior to receiving the same from
                                                  the Discloser in connection with this
                                                  Agreement; (ii) is independently developed by the Recipient without reference to or use of
                                                  the Discloser's Confidential Information; (iii) is acquired by the Recipient from another
                                                  source without restriction as to use or disclosure; or (iv) is or becomes publicly available
                                                  through no fault or action of the Recipient.
                                                </p>
                                                <p>
                                                  11.2. The Recipient shall not (a) use the Discloser's Confidential Information for any
                                                  purpose outside the scope of this Agreement without the Discloser's prior written consent or
                                                  (b) disclose the Discloser's Confidential Information to any person or entity, except to the
                                                  Recipient's employees, agents, contractors and service providers who (i) are bound by
                                                  non-use and non-disclosure obligations at least as protective as those contained in this
                                                  Agreement and (ii) have a need to know the Confidential Information for the Recipient to
                                                  exercise its rights or perform its obligations under this Agreement. Notwithstanding the
                                                  foregoing, the Recipient may disclose the Discloser's Confidential Information to the
                                                  limited extent any use or disclosure is required by Applicable Law or a valid and binding
                                                  order of a governmental body (such as a subpoena or court order), provided that, to the
                                                  extent permitted under Applicable Law, the Recipient uses reasonable efforts to give the
                                                  Discloser reasonable advance notice thereof to afford the Discloser an opportunity to
                                                  intervene and seek an order or other appropriate relief for the protection of its
                                                  Confidential Information. In the event of any breach or threatened breach by the Recipient
                                                  of its obligations under this Section, the Discloser will be entitled to seek injunctive and
                                                  other equitable relief to enforce such obligations.
                                                </p>
                                                <h4>12. Disclaimers.</h4>
                                                <p>12.1. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, NEITHER
                                                  PARTY MAKES ANY WARRANTY OR GUARANTEE OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                                                  OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER IMPLIED, EXPRESS,
                                                  OR STATUTORY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A
                                                  PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING,
                                                  USAGE OR TRADE PRACTICE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>
                                                <p>12.2. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, ALL
                                                  FREE-TRIAL SERVICES, SUPPORT (IF ANY) AND ANY OTHER MATERIAL ARE PROVIDED BY Qualdo ON AN
                                                  "AS IS" AND "AS AVAILABLE" BASIS. Qualdo MAKES NO REPRESENTATION OR WARRANTY, AND HAS NO
                                                  SUPPORT OBLIGATIONS OR LIABILITY, WITH RESPECT TO ANY CUSTOMER COMPONENT. WITHOUT LIMITING
                                                  THE OTHER PROVISIONS OF THIS SECTION 12, Qualdo MAKES NO WARRANTY OF ANY KIND THAT THE
                                                  FREE-TRIAL SERVICES, DOCUMENTATION, ANCILLARY TOOLS OR ANY OTHER MATERIAL, OR RESULTS OF THE
                                                  USE THEREOF, WILL: (a) MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS; (b) OPERATE
                                                  WITHOUT INTERRUPTION; (c) ACHIEVE ANY INTENDED RESULT; (d) BE ERROR FREE OR (e) BE
                                                  COMPATIBLE, WORK WITH OR CONTINUE TO WORK WITH CUSTOMER COMPONENTS. ANY CHANGES TO CUSTOMER
                                                  COMPONENTS (INCLUDING THEIR UNAVAILABILITY) OR THIRD-PARTY TERMS DURING AN ORDER TERM DO NOT
                                                  AFFECT CUSTOMER'S OBLIGATIONS UNDER THE APPLICABLE ORDER OR THIS AGREEMENT.</p>
                                                <h4>13. Term and Termination.</h4>
                                                <p>13.1. The term of this Agreement will continue through the last
                                                  Free-Trial Term to be in effect.</p>
                                                <p>
                                                  13.2. Upon expiration or earlier termination of a Free-Trial Order: (a) subject to Section
                                                  13.3, all rights granted to Customer with respect to Free-Trial Services under such
                                                  Free-Trial Order will terminate effective as of the effective date of termination and (b)
                                                  subject to Section 13.3, Qualdo will have no obligation to provide Free-Trial Services to
                                                  Customer or Authorized Users after the effective date of the termination.
                                                </p>
                                                <p>
                                                  13.3. Subject to any applicable shorter Service Plan retention periods, for up to 30 days
                                                  from the effective date of termination of this Agreement, an Authorized User designated by
                                                  Customer will be permitted to continue to access and download Customer Data that was
                                                  accessible to Authorized Users through the Free-Trial Services immediately prior to
                                                  termination. The designated Authorized User's access and use will continue to be subject to
                                                  the terms of this Agreement, provided the Authorized User shall not access or use the
                                                  Free-Trial Services other than to download Customer Data.
                                                </p>
                                                <p>
                                                  13.4. The provisions set forth in the following Sections, and any other right or obligation
                                                  of the Parties in this Agreement that, by its nature, should survive termination or
                                                  expiration of this Agreement, will survive any expiration or termination of this Agreement:
                                                  7.4, 8.2, 9 through 15, and 17 through 26.
                                                </p>
                                                <h4>14. Indemnification.</h4>
                                                <p>
                                                  Customer agrees to defend, indemnify and hold harmless Qualdo, its Affiliates and their
                                                  employees, contractors, agents, officers and directors, from and against any and all claims,
                                                  damages, obligations, losses, liabilities, costs or debt, and expenses (including without
                                                  limitation attorneys' fees) arising out of or related to any legal claim, suit, action or
                                                  proceeding by a third party arising out of or relating to any of the following
                                                  (collectively, <span className="highlight-text">"Customer-Controlled Matters"</span>):
                                                  (i) Customer's Environment, including
                                                  Connections to Customer Components, whether enabled through APIs, Ancillary Tools or
                                                  otherwise; (ii) Account Data, Customer Data or Customer Credentials (including activities
                                                  conducted with Customer Credentials), subject to Qualdo's Processing obligations under this
                                                  Agreement; or (iii) use of the Free-Trial Services by Customer or an Authorized User in a
                                                  manner that breaches a Free-Trial Order, Service Plan or this Agreement.
                                                </p>
                                                <h4>15. Limitations of Liability.</h4>
                                                <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS
                                                  OTHERWISE PROVIDED IN THIS SECTION 15: (a) IN NO EVENT SHALL EITHER PARTY, ITS AFFILIATES OR
                                                  THEIR EMPLOYEES, AGENTS, CONTRACTORS, OFFICERS OR DIRECTORS BE LIABLE FOR ANY INDIRECT,
                                                  PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
                                                  LIMITATION DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
                                                  INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THIS AGREEMENT; AND (b) IN NO EVENT SHALL
                                                  Qualdo'S CUMULATIVE AND AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED TWO HUNDRED U.S.
                                                  DOLLARS. THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION (COLLECTIVELY, THE <span className="highlight-text">"EXCLUSIONS"</span>)
                                                  APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
                                                  OR ANY OTHER BASIS, EVEN IF THE NON-BREACHING PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
                                                  SUCH DAMAGE. THE EXCLUSIONS SHALL NOT APPLY TO CUSTOMER'S INDEMNIFICATION OBLIGATIONS UNDER
                                                  SECTION 14 OR BREACH OF SECTION 8.2. THE PROVISIONS OF THIS SECTION 15 ALLOCATE THE RISKS
                                                  UNDER THIS AGREEMENT BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THE EXCLUSIONS IN
                                                  DETERMINING TO ENTER INTO THIS AGREEMENT.</p>
                                                <h4>16. Publicity.</h4>
                                                <p>
                                                  Neither Party shall, except as otherwise required by Applicable Law or stock exchange
                                                  requirements, issue or release any announcement, statement, press release or other publicity
                                                  or marketing materials relating to this Agreement or otherwise use the other Party's marks
                                                  or logos without the prior written consent of the other Party; provided, however, that
                                                  Qualdo may (subject its obligations of non-attribution under Section 7.4) include Customer's
                                                  name and logo in its lists of Qualdo customers, its public website and other promotional
                                                  material. Qualdo agrees to promptly cease such uses of Customer's name and logo following
                                                  Customer's request sent to logos@qualdo.com.
                                                </p>
                                            </div>
                                        </div>
                                </div>
                        </div>
                </main>
                <CopyRightsFooter/>
                </>
              );
    }
}

export default FreeTrialAgreement;