import {call_get_api} from "./event_handling";
import {  ui_service_ip } from "./ip_details";

export function preFetchModelDetails(tenantData, addMLModelMapping, addMLDataSet, addMLAttribute, addMLModel, setMLModel,addModelDetails, store,retries=3) {
  let data ={"tenant_id": tenantData}
  
  call_get_api(ui_service_ip, "model/map", data).then(
        (result) => {

            if (result !== undefined && result !== null) {
                if (store !== undefined){
                    store.dispatch(addMLModelMapping(result.model_map));
                    store.dispatch(setMLModel(Object.keys(result.model_map)[0]));
                } else {
                    addMLModelMapping(result.model_map);
                    setMLModel(Object.keys(result.model_map)[0]);
                }


                let data ={tenant_id: tenantData,isDataset:1}
                call_get_api(ui_service_ip, "ml-models",data).then(
                    (result) => {
                        if (result !== undefined && result !== null) {
                            if (store !== undefined) {
                                store.dispatch(addModelDetails(result.ml_model));
                            } else {
                                addModelDetails(result.ml_model)
                            }
                            this.setState({showLoader:false})
                        }
                        else {
                        if (retries > 0) {
                            preFetchModelDetails(tenantData, addMLModelMapping, addMLDataSet, addMLAttribute, addMLModel, setMLModel, store,retries-1)
                             }
                        }
            
                    }
                );

            }
        else {
            if (retries > 0) {
                preFetchModelDetails(tenantData, addMLModelMapping, addMLDataSet, addMLAttribute, addMLModel, setMLModel, store,retries-1)
                 }
            }
            }
    );
}
