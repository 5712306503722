import React from 'react';
import PipelineIntegration from "../components/pipelineintegration";

class AddPipeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
            }
        }
    }
    render() {
        return(
            <PipelineIntegration                 
                src_page={this.props.src_page} 
                showCancel={this.props.showCancel} 
                setPage={this.props.setPage} 
                showToast={this.props.showToast} 
                existingIntegrations={this.props.existingIntegrations}
                setEnvData={this.props.setEnvData}   
                env_id={this.props.env_id}  
            />
        );
    }
}

export default AddPipeline;