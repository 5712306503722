import React from 'react';
import Select from 'react-select';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postAirflow, validateAirflowUrl }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils';
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';


class AirflowForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateAirflowUrl = validateAirflowUrl.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.postAirflow = postAirflow.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.state = {
            check: false,
            options: [],
            dag: {},
            hideSubmitButton: false,
            airflow:{
                airflowName: '',
                username: '',
                password: '',
                dagId: '',
                payload: '',
                url: '',
                type: ''
            },
            errors: {
            },
            passwordIcon: faEye,
            passwordType: "password"
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleCancel() {
        this.setState({check:false});
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }
    }

    
    componentDidMount() {
        const exAirflowDetails = this.props.ex_details;
        if (exAirflowDetails === undefined) {
            return;
        }
        let airflowDetails = this.state.airflow;
        airflowDetails.airflowName = exAirflowDetails.pipeline_trigger_name;
        const exConfigurationDetails = exAirflowDetails.configuration;
        airflowDetails.dagId = exConfigurationDetails.dag_id;
        airflowDetails.url = exConfigurationDetails.url;
        airflowDetails.username = exConfigurationDetails.username;
        airflowDetails.password = exConfigurationDetails.password;
        airflowDetails.payload = exConfigurationDetails.payload;
        this.setState({airflow:airflowDetails});
        this.setState({dag:{"label": exConfigurationDetails.dag_id, "value": exConfigurationDetails.dag_id}})
    }


    requires_pipeline_update(airflowData){
        const exAirflowDetails = this.props.ex_details;
        if (airflowData.pipeline_trigger_name !== exAirflowDetails.pipeline_trigger_name) {
            return true;
        }
        const exConfiguration = exAirflowDetails.configuration;
        const currentConfiguration = exAirflowDetails.configuration;
        if (currentConfiguration.url !== exConfiguration.url) {
            return true;
        }
        if (currentConfiguration.dag_id !== exConfiguration.dag_id) {
            return true;
        }
        if (currentConfiguration.payload !== exConfiguration.payload) {
            return true;
        }
        if (currentConfiguration.username !== exConfiguration.username) {
            return true;
        }
        if (currentConfiguration.password !== exConfiguration.password) {
            return true;
        }
        return false;
    }

    render() {
        const airflowSchema = yup.object({
            dagId: yup.string().required("Dag Name is a required field")
        });
        const airflowValidate = yup.object({
            airflowName:yup.string().min(1,"Name must be at least 1 characters").required("Airflow Name is a required field").test(
                'name test',
                'invalid Airflow name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            username: yup.string().required("Username is a required field"),
            password: yup.string().required("Password is a required field"),
            url: yup.string().url("Enter a valid URL").required("URL is a required field"),
        });


        if (this.state.check === true) {
            return (
                    <>
                            <Formik
                                validationSchema={airflowSchema}
                                onSubmit={this.postAirflow}
                                initialValues={this.state.airflow}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                      <Form.Group controlId="dagId" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Select DAG<span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                <Select
                                                    name="dagId"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    id="DatabricksJob"
                                                    options={this.state.options}
                                                    defaultValue={this.state.dag}
                                                    isInvalid={errors.dagId && touched.dagId}
                                                    onChange={selectedOption => {
                                                        handleChange("dagId")(selectedOption.value.toString());
                                                    }}
                                                    classNamePrefix='select-control'
                                                    placeholder="Select DAG"
                                                />
                                                <ErrorMessage component="div" className="error-text" name="dagId" />
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="payload"  className="row">
                                            <Form.Label className="col-md-4 col-form-label">Payload :</Form.Label>
                                            <div className="col-md-5">
                                                <Form.Control
                                                      type="text"
                                                      name="payload"
                                                      value={values.payload}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className="form-control"
                                                      placeholder="Trigger payload"/>
                                            </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                            <div className="col-md-5 offset-sm-4">
                                              <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                                </button>
                                            </div>
                                      </Form.Group>
                                </Form>
                                )}
                            </Formik>
                    </>
            );
        } else {
                return (
                             <>
                                    <Formik
                                        validationSchema={airflowValidate}
                                        onSubmit={this.validateAirflowUrl}
                                        initialValues={this.state.airflow}
                                    >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                          <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                            <Form.Group controlId="airflowName" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Name <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                        type="text"
                                                        name="airflowName"
                                                        value={values.airflowName}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.airflowName && touched.airflowName}
                                                        placeholder="Airflow Name"/>
                                                    <Form.Control.Feedback type="invalid">
                                                            { touched.airflowName && errors.airflowName }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="username"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">Airflow Username <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          name="username"
                                                          onChange={handleChange}
                                                          value={values.username}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.username && touched.username}
                                                          className="form-control"
                                                          placeholder="Airflow Username"/>
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.username && errors.username }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="password"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">Airflow Password <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <InputGroup bsPrefix="icon-input-group">
                                                        <i>
                                                            <FontAwesomeIcon icon={faLock}/>
                                                        </i>
                                                        <Form.Control
                                                              name="password"
                                                              type={this.state.passwordType}
                                                              onChange={handleChange}
                                                              value={values.password}
                                                              onBlur={handleBlur}
                                                              autoComplete={this.props.autocomplete}
                                                              isInvalid={errors.password && touched.password}
                                                              className="form-control"
                                                              placeholder="Airflow Password"/>
                                                        <Form.Control.Feedback type="invalid">
                                                               { touched.password && errors.password }
                                                        </Form.Control.Feedback>
                                                        <i className="security-eye">
                                                            <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                                        </i>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="url" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Airflow URL <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          onChange={handleChange}
                                                          name="url"
                                                          value={values.url}
                                                          className="form-control"
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.url && touched.url}
                                                          placeholder="Airflow URL" />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.url && errors.url }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <div className="col-md-5 offset-sm-4">
                                                    <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                    <button type="submit" disabled={this.state.hideSubmitButton} onClick={this.handleClick} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                                    </button>
                                                </div>
                                            </Form.Group>
                                         </Form>
                                    )}
                                    </Formik>
                             </>
                );
        }
    }

}


export default AirflowForm;

