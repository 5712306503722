import React from 'react';
import Select from 'react-select';
import {postDatasource}  from "../../../../utils/event_handling";
import {userValidation} from '../../../../utils/common_utils';
import {Form, InputGroup} from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {TEST_CONNECTION_MESSAGES, VERSION_FORMATS, FILE_TYPE_FORMATS} from "../../../../utils/constant";


class GcsForm extends React.Component {
    constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postGcsData = this.postGcsData.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.state = {
            integration_data: this.props.integration_data,
            isChecked: true,
            hideSubmitButton: false,
            json: '',
            json_file_name: '',
            refreshType: {"label":"File Replace", "value":"file_replace"},
            versionFormat: null,
            versionOccurence: {"label":"prefix", "value":"prefix"},
            fileType: null,
        };

    }
    toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
    }

    componentDidMount() {
        this.fill_details();
    }

    fill_details() {
        if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined && this.props.ex_integration_details.integration_config  !== undefined) {
            let ex_integration_details = this.props.ex_integration_details;
            let integration_data = this.state.integration_data;
            integration_data.integration_name = this.props.ex_integration_details["integration_name"];
            integration_data.integration_id = this.props.ex_integration_details["integration_id"];
            integration_data.integration_type = "gcs";
            const exIntegrationConfig = ex_integration_details.integration_config;
            const authConfig = ex_integration_details.auth_config;
            integration_data.json_file = "exists";
            integration_data.json = authConfig;
            integration_data.json_file_name = exIntegrationConfig.json_file_name;
            integration_data.bucket_name = this.props.ex_integration_details.integration_config.bucket;
            integration_data.sub_path = this.props.ex_integration_details.integration_config.sub_path;
            integration_data.error_storage = this.props.ex_integration_details.integration_config.error_storage;
            let fileType = this.props.ex_integration_details.integration_config.filetype;
            integration_data.fileType = fileType
            let refreshType = this.props.ex_integration_details.integration_config.refresh_type;
            integration_data.refreshType = refreshType;
            let versionType = this.props.ex_integration_details.integration_config.version_type;
            let versionFormat = this.props.ex_integration_details.integration_config.version_format;
            let versionOccurence = this.props.ex_integration_details.integration_config.version_occurence;
            integration_data.versionOccurence = versionOccurence;
            if (versionType !== "custom") {
              integration_data.versionFormat = versionType;
              this.setState({versionFormat: {"label": versionType, "value": versionType}});
            } else {
              integration_data.versionFormat = versionType;
              integration_data.versionFormatRegex = versionFormat;
              this.setState({versionFormat: {"label": versionType, "value": versionType}});
            }
            let defaultErrorStorage = this.props.ex_integration_details.integration_config.default_error_storage;
            if (defaultErrorStorage === undefined) {
                defaultErrorStorage = true;
            }
            this.setState({isChecked: defaultErrorStorage,
                        selectOptionKey: "updatedFileType",
                        fileType:{"label": fileType, "value": fileType},
                        refreshType: {"label": FILE_TYPE_FORMATS[refreshType], "value": refreshType},
                        versionOccurence: {"label": versionOccurence, "value": versionOccurence}}
                        );


            this.setState({isChecked: defaultErrorStorage});
            this.setState({integration_data:integration_data});
        } else {
            let integration_data = this.state.integration_data;
            this.setState({integration_data:integration_data});
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    postGcsData(event){
        this.disableButton();
        const {integration_name, error_storage, bucket_name, sub_path,
            fileType, versionFormat, versionFormatRegex} = event;

        let json = this.state.json;
        let json_file_name = this.state.json_file_name;
        if (json === '') {
            json_file_name = this.state.integration_data.json_file_name;
            json = this.state.integration_data.json;
        }
        let version_format;
        let version_type;
            if (versionFormat === "custom") {
                version_format = versionFormatRegex;
                version_type = "custom";
            } else {
                version_format = VERSION_FORMATS[versionFormat];
                version_type = versionFormat;
            }
        let gcsData = {"integration_name":integration_name,
                             "integration_id":this.state.integration_data.integration_id,
                             "integration_type":"gcs",
                             "data_store_type":"filesystem",
                             "error_storage": error_storage,
                             "bucket_name": bucket_name,
                             "sub_path": sub_path,
                             "filetype": fileType,
                             "refresh_type": this.state.refreshType.value,
                             "version_format": version_format,
                             "version_type": version_type,
                             "version_occurence": this.state.versionOccurence.value,
                             "json": json,
                             "json_file": json_file_name,
                             "isFirstIntegration": this.props.isFirstIntegration,
                             "env_id":this.props.env_id
                             }
        let default_message = TEST_CONNECTION_MESSAGES["gcs"];
        this.props.closeTestConnection();
        this.props.setSubmitButton(true);
        this.props.setData(gcsData, default_message);
        this.postDatasource(gcsData);

    }

  handleFileChange(e) {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    let name = e.target.files[0]["name"];
    fileReader.onload = e => {
      this.setState({"json": JSON.parse(e.target.result), "json_file_name": name});
    };
 };


        getSchema() {
           const subPath = RegExp(/^(?!\/)/);

           let gcsSchema = '';
           if(this.props.formType === "gcs" && this.state.isChecked === true &&
           this.state.refreshType.value ==="file_replace") {
                  gcsSchema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                           let val = userValidation(value,"input");       
                           return val;
                    }
                    ),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        json_file: yup.string().required('JSON file is required'),
                        fileType: yup.string().required('File type is a required field')
                  });
           }else if(this.props.formType === "gcs" && this.state.isChecked === false &&
           this.state.refreshType.value ==="file_replace") {
                  gcsSchema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                           let val = userValidation(value,"input");       
                           return val;
                    }
                    ),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        json_file: yup.string().required('JSON file is required'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field')
                  });
           }else if (this.props.formType === "gcs" && this.state.isChecked === true &&
           this.state.refreshType.value !=="file_replace") {
                  gcsSchema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                           let val = userValidation(value,"input");       
                           return val;
                    }
                    ),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        json_file: yup.string().required('JSON file is required'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                  });
           }else if(this.props.formType === "gcs" && this.state.isChecked === false &&
           this.state.refreshType.value !=="file_replace") {
                  gcsSchema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                           let val = userValidation(value,"input");       
                           return val;
                    }
                    ),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        json_file: yup.string().required('JSON file is required'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                  });
           }

           return gcsSchema;


        }

    render() {
        let gcsSchema = this.getSchema();
        let file_option = [
                    {"label":"csv", "value":"csv"},
                    {"label":"json", "value":"json"},
                    {"label":"parquet", "value":"parquet"},
                    {"label":"avro", "value":"avro"},
                    {"label":"excel", "value":"excel"}
               ];
            let refresh_option = [
                    {"label":"File Replace", "value":"file_replace"},
                    {"label":"File Version", "value":"file_version"},
                    {"label": "Folder Version", "value":"folder_version"}
               ];
            let version_option = [
                    {"label":"dd-mm-yyyy", "value":"dd-mm-yyyy"},
                    {"label":"yyyy-mm-dd", "value":"yyyy-mm-dd"},
                    {"label": "dd-mm-yy", "value":"dd-mm-yy"},
                    {"label": "custom", "value": "custom" }
               ];
            let occurence_options = [
                {"label":"prefix", "value":"prefix"},
                {"label":"suffix", "value":"suffix"}
            ]
        let initial_values = {
                integration_name: '', error_storage: '', bucket_name: '', sub_path: '', fileType: '',
                refreshType: this.state.refreshType, versionFormat: '',
                versionOccurence: this.state.versionOccurence,
                json_file: ''
            };
        let edit_integration = null;
        if(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null){
            edit_integration = true;
        }
        else{
            edit_integration = false;
        }

        return (
                <>
                    <Formik
                          validationSchema={gcsSchema}
                          onSubmit={this.postGcsData}
                          initialValues={(this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined) ? this.state.integration_data : initial_values}
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          setFieldValue,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <Form.Group controlId="integration_name" className="col-md-6">
                            <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="integration_name"
                                  value={values.integration_name === undefined ? "" : values.integration_name}
                                  onChange={handleChange}
                                  placeholder="Datasource Name"
                                  onBlur={handleBlur}
                                  isInvalid={errors.integration_name && touched.integration_name}/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.integration_name && errors.integration_name }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <>
                        <Form.Group controlId="bucket_name" className="col-md-6">
                                         <Form.Label>Bucket Name <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="bucket_name"
                                               value={values.bucket_name === undefined ? "" : values.bucket_name}
                                               onChange={handleChange}
                                               placeholder="Bucket Name"
                                               onBlur={handleBlur}
                                               isInvalid={errors.bucket_name && touched.bucket_name}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.bucket_name && errors.bucket_name }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="sub_path" className="col-md-6">
                                         <Form.Label>Sub Path</Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="sub_path"
                                               value={values.sub_path === undefined ? "" : values.sub_path}
                                               onChange={handleChange}
                                               placeholder="Sub Path"
                                               onBlur={handleBlur}
                                               isInvalid={errors.sub_path}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           {errors.sub_path }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="fileType" className="col-md-6">
                                          <Form.Label>File Type <span className="text-danger">*</span></Form.Label>
                                                <Select name="fileType"
                                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                        onChange={selectedOption => {
                                                                   handleChange("fileType")(selectedOption.value);
                                                        }}
                                                        isInvalid={errors.fileType && touched.fileType}
                                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobFileTypeEdit" : "blobFileTypeAdd"}
                                                        key={this.state.selectOptionKey}
                                                        defaultValue={this.state.fileType}
                                                        options={file_option}
                                                        placeholder="Choose file type"
                                                        classNamePrefix='select-control'
                                                />
                                          <ErrorMessage component="div" className="error-text" name="fileType" />
                                    </Form.Group>

                        {values.json_file !== "exists" ?
                            <Form.Group controlId="json_file" className="col-md-6">
                                <Form.Label>Service Account JSON <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="file"
                                      accept=".json"
                                      className="form-control"
                                      name="json_file"
                                      title = {values.json_file}
                                      placeholder="JSON file"
                                      onChange={(e) => {
                                          handleChange(e);
                                          this.handleFileChange(e);
                                        }}
                                      isInvalid={errors.json_file && touched.json_file}
                                      />
                                <Form.Control.Feedback type="invalid">
                                           { touched.json_file && errors.json_file }
                                </Form.Control.Feedback>
                            </Form.Group>
                            :
                            <Form.Group controlId="json_file" className="col-md-6">
                                <Form.Label>Service Account JSON <span className="text-danger">*</span></Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    value={values.json_file_name === undefined ? "" : values.json_file_name}
                                  />
                                  <InputGroup.Append>
                                   <button
                                     className="btn btn-secondary text-white"
                                     onClick={()=> {
                                         setFieldValue ('json_file',  '')
                                         }} >
                                     Edit
                                   </button>
                                  </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        }
                       <Form.Group controlId="refreshType" className="col-md-6">
                                          <Form.Label>Refresh Type <span className="text-danger">*</span></Form.Label>
                                                <Select name="refreshType"
                                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                        onChange={selectedOption => {
                                                                    handleChange("refreshType")(selectedOption.value);
                                                                    this.setState ({
                                                                    refreshType: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                                    }}
                                                        isInvalid={errors.refreshType && touched.refreshType}
                                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobRefreshTypeEdit" : "blobRefreshTypeAdd"}
                                                        key={this.state.selectOptionKey}
                                                        defaultValue={this.state.refreshType}
                                                        options={refresh_option}
                                                        placeholder="Choose refresh type"
                                                        classNamePrefix='select-control'
                                                        isDisabled={edit_integration}
                                                />
                                          <ErrorMessage component="div" className="error-text" name="refreshType" />
                        </Form.Group>
                        { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                            <Form.Group controlId="versionFormat"
                            className="col-md-6">
                                  <Form.Label>Refresh Format <span className="text-danger">*</span></Form.Label>
                                        <Select name="versionFormat"
                                                filterOption={({label}, query) => label.includes(query)}
                                                onChange={selectedOption => {
                                                        this.setState ({
                                                        versionFormat: {"label": selectedOption["value"]    ,"value": selectedOption["value"]}});
                                                        handleChange("versionFormat")(selectedOption.value);}}
                                                isInvalid={errors.versionFormat && touched.versionFormat}
                                                id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobVersionTypeEdit" : "blobVersionTypeAdd"}
                                                key={this.state.selectOptionKey}
                                                defaultValue={this.state.versionFormat}
                                                options={version_option}
                                                placeholder="Choose Refresh Format"
                                                classNamePrefix='select-control'
                                                isDisabled={edit_integration}
                                        />
                                  <ErrorMessage component="div" className="error-text" name="versionFormat" />
                            </Form.Group> : ""
                        }
                        { this.state.versionFormat !== null && (this.state.versionFormat.value === "custom" && this.state.refreshType.value !== "file_replace") ?
                            <Form.Group controlId="versionFormatRegex" className="col-md-6">
                                 <Form.Label>Custom Refresh Format</Form.Label>
                                 <Form.Control
                                       type="text"
                                       className="form-control"
                                       name="versionFormatRegex"
                                       value={values.versionFormatRegex === undefined ? "" : values.versionFormatRegex}
                                       onChange={handleChange}
                                       placeholder="Enter the custom regex that matches your version pattern here"
                                       onBlur={handleBlur}
                                       isInvalid={errors.versionFormatRegex && touched.versionFormatRegex}
                                       isDisabled={edit_integration}
                                 />
                                 <Form.Control.Feedback type="invalid">
                                   { touched.versionFormatRegex && errors.versionFormatRegex }
                                 </Form.Control.Feedback>
                            </Form.Group> : ""
                        }
                        { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                            <Form.Group controlId="versionOccurence" className="col-md-6">
                                  <Form.Label>Refresh Format Occurrence <span className="text-danger">*</span></Form.Label>
                                        <Select name="versionOccurence"
                                                filterOption={({label}, query) => label.includes(query)}
                                                onChange={selectedOption => {
                                                            handleChange("versionOccurence")(selectedOption.value);
                                                            this.setState ({
                                                            versionOccurence: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                            }}
                                                isInvalid={errors.versionOccurence && touched.versionOccurence}
                                                id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobOccurenceTypeEdit" : "blobOccurenceTypeAdd"}
                                                key={this.state.selectOptionKey}
                                                defaultValue={this.state.versionOccurence}
                                                options={occurence_options}
                                                placeholder="Choose Refresh Format Occurrence"
                                                classNamePrefix='select-control'
                                                isDisabled={edit_integration}
                                        />
                                  <ErrorMessage component="div" className="error-text" name="versionOccurence" />
                            </Form.Group> : ""
                        }
                        </>
                            <div className="col-md-12 py-2">
                                 <div className="custom-control custom-checkbox">
                                    <Form.Control
                                        type="checkbox"
                                        name="errorColumn"
                                        className="custom-control-input"
                                        onChange={this.toggleChange}
                                        checked={this.state.isChecked}
                                    />
                                    <Form.Label className="custom-control-label">Store Data Issues and Errors in Qualdo™</Form.Label>
                                 </div>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error Storage Sub Path <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="error_storage"
                                      placeholder="Error Storage Sub Path"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.error_storage && touched.error_storage}
                                      value={values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                           { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                             </Form.Group> : ''}
                        </div>
                    <hr className="clearfix"/>
                    <div className="text-right">
                         {this.props.showCancel ?
                             <button type="button"
                                     onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)}
                                     className="btn btn-outline btn-grey btn-circle mr-2">
                                 Cancel
                             </button> : ''}
                         <button type="submit"
                                 disabled={this.state.hideSubmitButton}
                                 className="btn btn-primary btn-circle mr-2">
                             Test Connection
                         </button>
                    </div>
                    </Form>)}
                    </Formik>
                </>

        );
    }
}
export default GcsForm;