import React from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import { call_post_api }  from "../../utils/event_handling";
import Image from '../../components/image';
import Banner from '../../components/banner';



class InitiateSetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.setPassword = this.setPassword.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            formValid: false,
            hideSubmitButton: false,
            errorCount: null,
            errors: '',
            emailSent: false
        };

    }


    setPassword(event) {
            this.disableButton();
            const { email} = event;
            const passwordData = {email: email};
            call_post_api(iam_ip, 'send/set-password-link', passwordData).then((response) => {
                if (response.code === 200) {
                    this.setState({emailSent: true});
                } else {
                    this.setState({errors:response.message,hideSubmitButton: false});
                }
            });

    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    hideAlert() {
        this.setState({
          errors: '',
        });
    }

    render() {
        let setPassword = localStorage.getItem("setPassword");
        if (setPassword === "undefined" || setPassword === "null" || setPassword === null || setPassword === undefined  || setPassword === false) {
            window.location = '/';
            return;
        }

        if (this.state.emailSent === true){
            localStorage.removeItem("setPassword");
        }

        const setSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
        });

        return (
            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                        <div className="prelogin-content">
                        { this.state.emailSent === false ?
                            <>
                            <h4 className="login-welcome-text">Set new password</h4>
                            <p>To set a password for your account, enter your email and click send email button. Email will be sent to your account with link. Using that link you can set password for this account. If you have a problem with setting your password,
                                please send your queries to <a href = "mailto:helpdesk@qualdo.ai" >helpdesk@qualdo.ai</a></p>
                            <div className="prelogin-form-container mt-5">
                                {this.state.errors !== '' ?
                                       <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                          {this.state.errors}
                                          <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"></button>
                                       </div> : ''
                                }
                                <Formik
                                    validationSchema={setSchema}
                                    onSubmit={this.setPassword}
                                    initialValues={{email: ''}}
                                >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid,
                                }) => (
                                <Form onSubmit={handleSubmit} className="login-form">
                                    <Form.Group controlId="email">
                                        <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                        <InputGroup bsPrefix="icon-input-group">
                                            <i>
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                            </i>
                                            <Form.Control
                                                type="text"
                                                placeholder="Email Address"
                                                className="form-group"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={errors.email && touched.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                               { touched.email && errors.email }
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Send Email</button>
                                </Form>
                                 )}
                                </Formik>
                            </div>
                            </>
                        :
                        <div className="prelogin-content">
                            <div className="prelogin-thankyou">
                                <i className="d-inline-flex p-2 mr-3">
                                   <Image src="email"/>
                                </i>
                                <h2>Thank you!</h2>
                                <h3>Please check your email!</h3>
                                <p className="text-muted">Look out for an email from us with instructions on how to set your password!</p>
                                <p class="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                            </div>
                        </div>
                        }
                    </div>
                     </div>
                    </div>
                    <Banner/>
                </div>
            </div>
        );
    }
}

export default InitiateSetPassword;