import React from "react";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "58986417-35ca-4773-899c-348cdd0b099d";

const setting = {
  color: {
    theme: "#F64E60",
    launcherText: "#ffffff",
    widgetTitle: "#ffffff"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "name", prefill: { "*": localStorage.getItem('user_name') } },
      { id: "email", prefill: { "*": localStorage.getItem('emailId') } }
    ]
  }
};

class HelpZendesk extends React.Component {

   render() {
        return (<Zendesk zendeskKey={ZENDESK_KEY} {...setting} />);
   }

}

export default HelpZendesk;