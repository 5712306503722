import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import BasicPortlet from '../../components/basicPortlet';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReceipt, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import AddCardComponent from "./addCardComponent";
import PaymentCardsList from "./paymentCardsList";
import QualdoDataTable from "../../components/bootstrapTable";
import {
    getApi,
    getBillingHistoryTableData,getBrainTreeAuthToken,
    getPaymentDetailsTableData,getBillingAddressDetails,
    submitPrimaryCardRequest, updateAutoDebitStatus
} from "../../utils/event_handling";
import Load from "../../components/loadAction";
import EditCardComponent from "./editCardComponent";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import {IS_NONE_CHECK} from "../../utils/constant";

class Billing extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.showAddCardSection = this.showAddCardSection.bind(this);
        this.hideAddCardSection = this.hideAddCardSection.bind(this);
        this.updateAlertSection = this.updateAlertSection.bind(this);
        this.showEditSection = this.showEditSection.bind(this);
        this.showAlertSection = this.showAlertSection.bind(this);
        this.makeCardAsPrimary= this.makeCardAsPrimary.bind(this);
        this.getEmptyCardSection= this.getEmptyCardSection.bind(this);
        this.addNewCardDetailToList= this.addNewCardDetailToList.bind(this);
        this.updatePrimaryStatusOfCards= this.updatePrimaryStatusOfCards.bind(this);
        this.updateCardDetailToList= this.updateCardDetailToList.bind(this);
        this.removeCardDetailFromList= this.removeCardDetailFromList.bind(this);
        this.setAutoDebit = this.setAutoDebit.bind(this);
        
        this.pdfdownload = this.pdfdownload.bind(this);
        this.getApi = getApi.bind(this);
        this.updateAutoDebitStatus = updateAutoDebitStatus.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.getBillingHistoryTableData = getBillingHistoryTableData.bind(this);
        this.getBillingAddressDetails = getBillingAddressDetails.bind(this);
        this.submitPrimaryCardRequest = submitPrimaryCardRequest.bind(this);
        this.getBrainTreeAuthToken = getBrainTreeAuthToken.bind(this);


        this.state = {
            showAddCard: false,
            isEditMode: false,
            showAlert: false,
            exCardDetails: null,
            cardGroupLoading: true,
            alertText: "",
            alertStatus: "Success! ",
            alertClass: "success",
            availableCards: null,
            billingHistory: null,
            braintreeAuthorizationToken: '',
            renderCards: false,
            disableAutoDebitToggle: true,
            autoDebit: null,
            billinginitalloader:true,
        }
    }
    
    componentDidMount() {

        this.getData();
        const bt_token = this.getBrainTreeAuthToken();
        this.setState({
            cardGroupLoading: false,
            braintreeAuthorizationToken: bt_token
        });

       
    }
    getData(){
        this.getApi("get_user_payments_and_billing_history")
    }
    showAddCardSection(event) {
        this.setState({showAddCard: true})
    }

    pdfdownload(row) {
        let reqAddressID = row["Status"]["billingDetails"]["billing_address_id"];
        let pdfinfo = row.Receipt;
         let createddate   =   pdfinfo.created_time;
         let aordercreated = createddate.split(" "); 
         let next_payment_date   =   pdfinfo.next_payment_date;
         let anext_payment_date = next_payment_date.split(" ");          
        //  let companyName = "Smruti Sagarika";
        //  let addressLine1 = "56, MSH Layout";
        //  let addressLine2 =   "2nd Stage, 5th Main, Anand Nagar, Hebbal";
        //  let city =   "Bengaluru";
        //  let state =   "Karnataka";
        //  let zipCode =   "560024";
        //  let country =  "India";

         let companyName = "";
         let addressLine1 = "";
         let addressLine2 =   "";
         let city =   "";
         let state =   "";
         let zipCode =   "";
         let country =  "";
         let billing_address = this.state.billing_address
        let matchedBillingAddress = billing_address[reqAddressID];
         if (IS_NONE_CHECK.includes(matchedBillingAddress)) {
            matchedBillingAddress = {};
         }

        companyName = matchedBillingAddress.company_name;
         addressLine1 = matchedBillingAddress.address_line_1;
         addressLine2 = matchedBillingAddress.address_line_2 === undefined ? "" : matchedBillingAddress.address_line_2;
         city =   matchedBillingAddress.city;
         state =  matchedBillingAddress.state;
         zipCode = matchedBillingAddress.zip_code;
         country = matchedBillingAddress.country;
         if(country === undefined){
             country = "";
         }
//        if(pdfinfo.hasOwnProperty('billing_address')){
//
//             companyName = billing_address[index].companyName;
//             addressLine1 = billing_address[index].addressLine1;
//             addressLine2 = billing_address[index].addressLine2;
//             city =   billing_address[index].city;
//             state =  billing_address[index].state;
//             zipCode = billing_address[index].zipCode;
//             country = billing_address[index].country;
//
//        }
        let pdfsize = 850;
        let percesize = 325;
        var html = htmlToPdfmake(`
        <div style="max-width: `+pdfsize+`px;margin: 15px auto;">
           <table style="margin:0;">
            <tr style="border:none;margin:0;">
                <td style="text-align:left;width:`+(percesize)+`px;">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="34" viewBox="0 0 170 34">
                            <g id="Group_5" data-name="Group 5" transform="translate(-3 -3.3)">
                            <g id="Group_2" data-name="Group 2" transform="translate(3 3.4)">
                                <path id="Path_1" data-name="Path 1" d="M29.1,34.143a17.3,17.3,0,0,1-9.4,2.75C10.1,36.893,3,29.662,3,20.6v-.1A16.4,16.4,0,0,1,19.8,4.1,16.249,16.249,0,0,1,36.5,20.5v.1a16.354,16.354,0,0,1-2.7,9.064l3.2,2.75L32.7,37.3Zm-6.2-5.6-4.3-3.768,4.3-4.99,4.2,3.87a7.846,7.846,0,0,0,.5-3.055v-.1c0-4.583-3.2-8.656-8-8.656-4.7,0-7.9,3.972-7.9,8.555v.1c0,4.583,3.2,8.555,7.9,8.555a6.66,6.66,0,0,0,3.3-.512Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                                <path id="Path_2" data-name="Path 2" d="M40.3,27.4V11.7h8.4v13c0,2.6,1.4,4,3.4,4s3.5-1.4,3.5-4v-13H64v24H55.6V32.4a8.93,8.93,0,0,1-7.2,3.8C43.3,36.3,40.3,32.9,40.3,27.4Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                                <path id="Path_3" data-name="Path 3" d="M67.3,28.9h0c0-5.1,3.8-7.6,9.4-7.6a18.582,18.582,0,0,1,5.8.9v-.4c0-2.5-1.6-3.9-4.8-3.9a17.643,17.643,0,0,0-6.6,1.3l-1.7-5.9a24.217,24.217,0,0,1,9.7-1.9c4.3,0,7.2,1,9.1,2.9,1.8,1.8,2.6,4.3,2.6,7.6V35.7H82.5V33.2a9.066,9.066,0,0,1-7.1,3C70.9,36.3,67.3,33.6,67.3,28.9ZM82.6,27V25.9a7.788,7.788,0,0,0-3.3-.6c-2.4,0-3.9,1.2-3.9,3v.1c0,1.6,1.2,2.6,3,2.6a3.887,3.887,0,0,0,4.2-4Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                                <path id="Path_4" data-name="Path 4" d="M95.3,3.4h8.4v33H95.3Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                                <path id="Path_5" data-name="Path 5" d="M107.5,24.172h0c0-8.044,4.9-12.83,10.6-12.83a9.043,9.043,0,0,1,7.4,3.564V3.4h8.4V36.391h-8.4V33.133a8.717,8.717,0,0,1-7.3,3.767C112.4,36.9,107.5,32.012,107.5,24.172Zm18.1,0h0c0-3.258-2.2-5.6-4.9-5.6s-4.9,2.24-4.9,5.5v.1c0,3.258,2.2,5.6,4.9,5.6a5.314,5.314,0,0,0,4.9-5.6Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                                <path id="Path_6" data-name="Path 6" d="M137.4,23.8h0c0-6.9,5.6-12.6,13.3-12.6s13.2,5.6,13.2,12.5v.1c0,6.8-5.6,12.6-13.3,12.6C143,36.3,137.4,30.7,137.4,23.8Zm18.3,0h0a5.217,5.217,0,0,0-5.1-5.5c-3.1,0-5,2.4-5,5.3v.1a5.19,5.19,0,0,0,5.1,5.4C153.8,29.2,155.7,26.7,155.7,23.8Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                            </g>
                            <g id="Group_3" data-name="Group 3" transform="translate(162.4 3.3)">
                                <path id="Path_7" data-name="Path 7" d="M165,8.6h-1V4.3h-1.6v-1h4.2v.9H165Zm5.4-1.2h-.1l-1.6-2.5V8.6h-1V3.3h1.1l1.6,2.5L172,3.3h1V8.5h-1V4.9Z" transform="translate(-162.4 -3.3)" fill="#fa4616"/>
                            </g>
                            </g>
                      </svg>
                    </div>
                </td>
                <td style="text-align:right;width:`+(percesize)+`px;">
                    <h4 style="font-size: 28px;font-weight: 500;color:black;margin-bottom:5px;">Invoice</h4>
                    <h5 style="font-size: 14px;font-weight: 500;color:DimGray;">Invoice Number - `+pdfinfo.order_id+`</h5>
                </td>
            </tr>
           </table>
           <hr data-pdfmake="{&quot;color&quot;:&quot;gainsboro&quot;}">
           <table data-pdfmake="{noBorders&quot;,noPaddings&quot;}">
            <tr style="font-size: 14px;border: none;">
                <td style="text-align:left;width:`+(percesize)+`px;">
                    <h5 style="font-size: 16px;font-weight: 600;color:black;">Invoiced To:</h5>
                    <p style="line-height: 1.2;color:DimGray;font-size: 14px;">
                        <strong>`+companyName+`</strong>
                        <br />`+addressLine1+ ' ' +addressLine2+`,
                        <br />`+city+', '+state+`, 
                        <br />`+zipCode+`
                        <br />`+country+`
                    </p>
                </td>
                <td style="text-align:right;width:`+(percesize)+`px;">
                    <h5 style="font-size: 16px;font-weight: 600;color:black;">Paid To:</h5>
                    <p style="line-height: 1.2;color:DimGray;font-size: 14px;">
                        <strong>Saturam Inc</strong>
                        <br />3260 Hillview Avenue,
                        <br />Palo Alto, CA 94304.
                        <br />contact@qualdo.ai
                    </p>
                </td>
            </tr>
           </table>
           <table style="border: 1px solid Gainsboro;">
                <thead>
                <tr style="border: none;margin:10px 4px;color:DimGray;font-size:14px;font-weight:600;">
                    <th style="width:320px;background-color:WhiteSmoke;">Description</th>
                    <th style="width:120px;background-color:WhiteSmoke;text-align:right;">Price</th>
                    <th style="width:70px;background-color:WhiteSmoke;text-align:right;">QTY</th>
                    <th style="width:120px;background-color:WhiteSmoke;text-align:right;">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: none;margin:15px 4px 0px;font-size:14px;font-weight:400;color:DimGray;">
                    <td style="border:none;border-bottom: 1px solid Gainsboro;">
                        <!--<strong style="margin:0;font-weight:500;">May14 - June14, 2021</strong>-->
                        <strong style="margin:0;font-weight:500;">`+aordercreated[2]+` `+aordercreated[1]+` - `+anext_payment_date[2]+` `+anext_payment_date[1]+`, `+anext_payment_date[3]+`</strong>
                        <p style="margin-left:4px;">`+pdfinfo.details.plan_title+` `+pdfinfo.details.billing_cycle+`</p>
                    </td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:30px;text-align:right;">`+pdfinfo.currency_symbol+pdfinfo.amount+`</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:30px;text-align:right;">1</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:30px;text-align:right;">`+pdfinfo.currency_symbol+pdfinfo.amount+`</td>
                </tr>
                <!--<tr style="border: none;margin:5px 4px 0px;font-size:14px;font-weight:400;color:DimGray;">
                    <td style="border:none;border-bottom: 1px solid Gainsboro;">
                        <strong style="margin:0;font-weight:500;">Data Limit</strong>
                        <p style="margin-left:4px;">100GB Data Upgrade</p>
                    </td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">1</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                </tr>
                <tr style="border: none;margin:5px 4px 0px;font-size:14px;font-weight:400;color:DimGray;">
                    <td style="border:none;border-bottom: 1px solid Gainsboro;">
                        <strong style="margin:0;font-weight:500;">Data Limit 
                        <span style="background-color:Gray;color:white;font-size:12px;font-weight:500;">
                            <span style="line-height:1.4;margin:10px"> Last Month Pending </span>
                        </span></strong>
                        <p style="margin-left:4px;">100GB Data Upgrade</p>
                    </td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">1</td>
                    <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                </tr>-->
                <tr style="border: none;border-bottom: 1px solid Gainsboro;margin:10px 4px 10px;font-size:14px;color:DimGray;">
                    <td colspan="3" style="background-color:WhiteSmoke;">
                        <strong style="text-align:right;font-weight:600;">Sub Total:</strong>
                    </td>
                    <td style="background-color:WhiteSmoke;text-align:right;font-weight:500;">
                      <span>`+pdfinfo.currency_symbol+pdfinfo.amount+`</span>
                    </td>
                </tr>
                <!--<tr style="border: none;border-bottom: 1px solid Gainsboro;margin:10px 4px 10px;font-size:14px;color:DimGray;">
                    <td colspan="3" style="background-color:WhiteSmoke;">
                        <strong style="text-align:right;font-weight:600;">GST:</strong>
                    </td>
                    <td style="background-color:WhiteSmoke;text-align:right;font-weight:500;">
                        <span>$68.58</span>
                    </td>
                </tr>-->
                <tr style="border: none;margin:10px 4px 10px;font-size:14px;color:DimGray;">
                    <td colspan="3" style="background-color:WhiteSmoke;">
                        <strong style="text-align:right;font-weight:600;">Total:</strong>
                    </td>
                    <td style="background-color:WhiteSmoke;text-align:right;font-weight:500;">
                        <span>`+pdfinfo.currency_symbol+pdfinfo.amount+`</span>
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <h5 style="font-size: 16px;font-weight: 600;color:black;margin: 30px 0 0;">Payment:</h5>
                <!--<p style="color:DimGray;font-size: 14px;margin-bottom:20px;">June 14, 2021</p>-->
                <p style="color:DimGray;font-size: 14px;margin-bottom:20px;">`+aordercreated[2]+` `+aordercreated[1]+`, `+aordercreated[3]+`</p>
            </div>
            <div>
                <h5 style="font-size: 16px;font-weight: 600;color:black;margin: 0;">Note:</h5>
                <p style="color:DimGray;font-size: 14px;margin-bottom:50px;">All amounts in United States Dollars (USD)</p>
            </div>
            <hr data-pdfmake="{&quot;color&quot;:&quot;Gainsboro&quot;}">
            <div style="text-align:center">
                <p style="line-height:1.5;color:DimGray;font-size: 14px;margin-top:30px;">
                    <strong style="font-weight:600;color:black;">Saturam Inc</strong>
                    <br />355 Bryant Street, Unit 403, San Francisco, CA 94107   |   +1 650-308-4857 
                </p>
            </div>
        </div>
`, {window:window, tableAutoSize:true});
        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
       
        pdfMake.createPdf(documentDefinition).open();
    }

    setAutoDebit() {

        // Disable the toggle button, till the API update is complete to prevent continuous click
        this.setState({disableAutoDebitToggle: true});

        // Call the API to update auto debit status in server
        const newAutoDebitStatus = !this.state.autoDebit;
        this.updateAutoDebitStatus(newAutoDebitStatus);

        // Enable the toggle button, since API call is over
        this.setState({disableAutoDebitToggle: false});
    }

    hideAddCardSection(event) {
        this.setState({
            showAddCard: false,
            isEditMode: false,
            exCardDetails: null
        })
    }

    updateAlertSection(status) {
        this.setState({showAlert: status})
    }

    showAlertSection(status, alertText, showAddCard, alertClass) {
        this.setState({
            alertStatus: status,
            alertText: alertText,
            showAlert: true,
            showAddCard: showAddCard,
            alertClass: alertClass
        }, ()=>{
              window.setTimeout(()=>{
                this.setState({showAlert:false})
              },8000) });
    }


    addNewCardDetailToList(cardDetail) {
        let availableCards = this.state.availableCards;

        if (availableCards === null) {
            availableCards = [];
        }

        const currentCardIsDefault = cardDetail.isPrimary;
        let currentCardDetail = {
            "brand": cardDetail.card_type,
            "customerName": cardDetail.cardholder_name,
            "expires": `${cardDetail.expiration_month}/${cardDetail.expiration_year}`,
            "lastFourDigits": cardDetail.last_4,
            "isPrimary": currentCardIsDefault,
            "id": cardDetail.id
        };

        // Make other cards as non primary
        if (currentCardIsDefault) {
            for (let card of availableCards) {
                card["isPrimary"] = false;
            }
        }

        availableCards.push(currentCardDetail);
        this.setState({availableCards: availableCards})
    }

    removeCardDetailFromList(cardDetailID) {
        let availableCards = this.state.availableCards;

        if (availableCards === null) {
            availableCards = [];
        }

        let updatedAvailableCards = [];
        for (let card of availableCards) {
            if (card.id === cardDetailID) {
                continue;
            }

            updatedAvailableCards.push(card);
        }

        this.setState({availableCards: updatedAvailableCards});
        this.setState({renderCards: !this.state.renderCards});
        this.hideAddCardSection();
    }

    updateCardDetailToList(cardDetail) {
        let availableCards = this.state.availableCards;

        if (availableCards === null) {
            availableCards = [];
        }

        for (let card of availableCards) {
            if (card.id !== cardDetail.id) {
                continue;
            }

            card.customerName = cardDetail.customer_name;
            card.expires = `${cardDetail.expiry_month}/${cardDetail.expiry_year}`;
        }

        this.setState({availableCards: availableCards});
        this.setState({renderCards: !this.state.renderCards});
        this.hideAddCardSection();
    }

    showEditSection(exCardDetails) {
        this.setState({
            exCardDetails: exCardDetails,
            showAddCard: true,
            isEditMode: true
        });
    }

    makeCardAsPrimary(cardDetail) {
        this.setState({cardGroupLoading: true});
        this.submitPrimaryCardRequest(cardDetail);
        this.setState({cardGroupLoading: false});
    }

    getEmptyCardSection() {
        if (this.state.availableCards === null) {
            return (<Load/>);
        }

        if (this.state.availableCards.length === 0 &&
            !this.state.isEditMode &&
            !this.state.showAddCard) {

            return (<BasicPortlet className="pb-0" title="Payment Method"
                                  bodyClassName=""
                                  content={
                                      <div>
                                          <p>Hm, looks like you don’t have a credit card on
                                              file.</p>
                                          <button type="button"
                                                  onClick={this.showAddCardSection}
                                                  className="btn btn-primary btn-circle">
                                              <i>
                                                  <FontAwesomeIcon icon={faCreditCard}/>
                                              </i> Add payment method
                                          </button>
                                      </div>
                                  }
            />)
        }
    }

    updatePrimaryStatusOfCards(cardId) {
        if (this.state.availableCards === null) {
            return;
        }

        for (let card of this.state.availableCards) {
            card["isPrimary"] = String(card.id) === String(cardId);
        }
        this.setState({renderCards: !this.state.renderCards});
    }

   
    render() {

        let availableCards = this.state.availableCards; 
        if (availableCards === null) {
            availableCards = [];
        }

      

        let showRemoveCard = availableCards.length > 1;

        return (
            <>
                <div className="qd-section_content shadow-sm settings-preferences">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title">
                            <i><FontAwesomeIcon icon={faReceipt}/></i>
                            Billing & Payment
                        </h4>
                    </div>
                    <div className="qd-section-body">
                        <div style={{
       display: this.state.billinginitalloader ? "block" : "none",
    }}>
        {<Load/>}
                        </div>

                        <div  style={{
       visibility: this.state.billinginitalloader ? "hidden" : "visible",
    }}>
                        <Row>
                            <Col md={12}>

                                {
                                    this.state.showAlert
                                        ?
                                        <Alert variant={this.state.alertClass} onClose={() => this.updateAlertSection(false)}
                                                isopen={String(this.state.showAlert)}
                                               dismissible>
                                            <p className="mb-0">
                                                <strong className="mr-2 font-weight-bold">
                                                    {this.state.alertStatus}
                                                </strong>
                                                {this.state.alertText}
                                            </p>
                                        </Alert>
                                        :
                                        ''
                                }

                                {
                                    this.state.showAddCard
                                        ?
                                        <div className="portlet">
                                            <div className="portlet-title">
                                                <div className="caption float-none pb-1">
                                                    <span className="text-info">
                                                        {
                                                            this.state.isEditMode
                                                                ?
                                                                'Edit credit or debit card' :
                                                                "Add New Credit Card Details"
                                                        }

                                                    </span>
                                                    <p className="text-muted mb-0 bold">
                                                        {
                                                            this.state.isEditMode
                                                                ?
                                                                'You can edit your card details' :
                                                                "You can add your credit card for the future purchase and " +
                                                                "automatically renew"
                                                        }
                                                        .
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="portlet-body">
                                                <Row>
                                                    {
                                                        this.state.isEditMode ?
                                                            <Col md={12}>
                                                                <EditCardComponent
                                                                    key={
                                                                        (this.state.exCardDetails === undefined ||
                                                                            this.state.exCardDetails === null)
                                                                            ?
                                                                            0 :
                                                                            this.state.exCardDetails.id
                                                                    }
                                                                    showAlertSection={this.showAlertSection}
                                                                    showRemoveCard={showRemoveCard}
                                                                    exCardDetails={this.state.exCardDetails}
                                                                    updateCardDetailToList={this.updateCardDetailToList}
                                                                    removeCardDetailFromList={this.removeCardDetailFromList}
                                                                    hideAddCardSection={this.hideAddCardSection}/>
                                                            </Col> :
                                                            <Col md={12}>
                                                                <AddCardComponent
                                                                    addNewCardDetailToList={this.addNewCardDetailToList}
                                                                    authorizationToken={this.state.braintreeAuthorizationToken}
                                                                    showAlertSection={this.showAlertSection}
                                                                    hideAddCardSection={this.hideAddCardSection}/>
                                                            </Col>
                                                    }
                                                </Row>

                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    this.getEmptyCardSection()
                                }

                                {this.state.cardGroupLoading
                                    ?
                                    <Load/>
                                    :
                                    (this.state.isEditMode || this.state.showAddCard) ?
                                        ''
                                        :
                                        <PaymentCardsList cards={this.state.availableCards}
                                                          key={this.state.renderCards}
                                                          showEditSection={this.showEditSection}
                                                          makeCardAsPrimary={this.makeCardAsPrimary}
                                                          showAddCardSection={this.showAddCardSection}>
                                        </PaymentCardsList>
                                }
                                <div className="portlet">
                                    <div className="portlet-title">
                                        <div className="caption">
                                            <span className="text-uppercase font-dark">
                                                Billing Preferences
                                            </span>
                                        </div>
                                    </div>
                                    <div className="portlet-body py-4">
                                        <div className="renew-subscription-info mb-3">
                                            <h6>
                                                Automatically renew my subscription every month
                                            </h6>

                                            <div
                                                className="custom-control custom-switch custom-switch-sm orange-switch ml-2">

                                                <input id="auto_debit_btn"
                                                       key={this.state.disableAutoDebitToggle}
                                                       defaultChecked={this.state.autoDebit}
                                                       className="custom-control-input"
                                                       type="checkbox"
                                                       onClick={this.setAutoDebit}
                                                       disabled={this.state.disableAutoDebitToggle}
                                                />
                                                <label className="custom-control-label"
                                                       htmlFor="auto_debit_btn"/>

                                            </div>
                                        </div>
                                        <p className="renew-subscription-text">
                                            Turning off auto renew may result in a discontinuation of service at your
                                            renewal date
                                        </p>
                                    </div>
                                </div>


      
                                {this.state.billingHistory === null ?
                                    <BasicPortlet className="pb-0"
                                                  title="Billing History"
                                                  bodyClassName=""
                                                  content={<Load/>}
                                    />
                                    :
                                    <BasicPortlet className="pb-0"
                                                  title="Billing History"
                                                  bodyClassName=""
                                                  content={
                                                      <QualdoDataTable
                                                          hideSizePerPage={this.state.billingHistory.data.length < 5}
                                                          data={this.state.billingHistory}
                                                          pdfdownload = {this.pdfdownload}
                                                          showToast={this.showToast}
                                                          component_name="billingHistory"
                                                      />
                                                  }
                                        />
                                }

                            </Col>
                        </Row>
                        </div>        
                    </div>
                </div>

            </>
        );
    }
}

export default Billing;
