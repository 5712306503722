import React from "react";
import Image from "../components/image";
import MonitorChartGroup from "./components/monitorChartGroup";
import {connect} from "react-redux";
import AdvancedFilterModel from "./components/advanced_filter_model";
import {ML_MODEL_WITH_MODEL_EVALUATION_METRICS, MODEL_EVALUATION} from "../utils/constant";

class MPListViewFullScreen extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.formGroupedChart = this.formGroupedChart.bind(this);

        this.state = {
            updatedKey: "k1",
            fullScreenData: {},
            variant: this.props.variant,
            selectedMlModel: this.props.selectedMlModel
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedMlModel !== state.selectedMlModel) {
            return {
                selectedMlModel: props.selectedMlModel
            }
        }

        return null;
    }

    formGroupedChart() {
        let selectedMlModel = this.state.selectedMlModel;

        let ml_model_id = 0;
        if (selectedMlModel !== undefined && selectedMlModel !== null) {
            ml_model_id = selectedMlModel[0].value;
        }
        let mlModelOptions = this.props.mlModelOptions;
        let selectedOption = mlModelOptions.find(option => option.value === ml_model_id);

        const groupedData = this.props.fullScreenData;
        let rowCount = 0
        let ModelEvaluationMetrics = selectedOption.mlType !== undefined && ML_MODEL_WITH_MODEL_EVALUATION_METRICS.includes(selectedOption.mlType) ? [selectedOption] : [];
        let hasEvaluationMetrics = false;
        if (ModelEvaluationMetrics.length > 0){
            hasEvaluationMetrics=true;
        }
        return Object.keys(groupedData).map((key, index) => {
            let loadValue = !this.props.monitorModule.modelPerformanceFetchCompleted;
            let sectionType = groupedData[key]["type"]
            if(key === MODEL_EVALUATION && hasEvaluationMetrics === false){
                return ""
            }
            rowCount = rowCount + 1
            if(this.props.selectedMetrics.length > 0){
                let sectionFiltered = this.props.selectedMetrics.filter(x=>x.type === sectionType)
                if(sectionFiltered.length === 0){
                   rowCount = rowCount - 1
                   return ""
                }
            }
            return (
                (<MonitorChartGroup key={`${this.state.updatedKey}_${ml_model_id}_${key}`}
                                    groupData={groupedData[key]}
                                    mapModel={this.props.monitorModule.mlModelMapping}
                                    index={rowCount}
                                    tab="listView"
                                    considerEndDate={false}
                                    timeFilterApplied={true}
                                    loadValue={loadValue}
                                    chartType={this.state.chartType}
                                    selectedMlModel={selectedMlModel}
                                    variant={this.state.variant}
                                    startDateInside={this.props.startDateInside}
                                    endDateInside={this.props.endDateInside}
                                    startDate={this.props.startDate}
                                    endDate={this.props.endDate}
                                    chartTimeFilter={this.chartTimeFilter}
                >

                </MonitorChartGroup>));
        });
    }

    render() {
        let modelMonitoringData = this.props.fullScreenData["Model Monitoring"]["data"]
        return (
            <div className={"qd-fullscreen fade " + this.props.fullScreenClassVal}>
                <div className="qd-fs_dialog">
                    <div className="qd-fs_content">
                        <div className="qd-fs_header">
                            <div className="header_content">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src="logo"
                                />
                                <h4 className="header_title">{this.props.title}</h4>
                            </div>
                            <div className="actions">
                                <AdvancedFilterModel
                                     mlModelOptions={this.props.mlModelOptions}
                                     selectedMlModel={this.props.selectedMlModel}
                                     startDate={this.props.startDate}
                                     modelMonitoringData={modelMonitoringData}
                                     type="modelPerformance"
                                     endDate={this.props.endDate}
                                     changeModelInFullScreen={this.props.changeModelInFullScreen}/>
                            </div>
                            <button type="button" onClick={this.props.closeFullScreen}
                                    className="close" aria-label="Close">
                                <span aria-hidden="true"/>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="qd-fs_body overflow-auto pt-0">
                            <div className="qd-grid qd-grid-monitor">
                                <div className="qd-grid-item qd-section"
                                     key={this.props.isAdvancedFilterApplied}>
                                     {this.props.lastProfilingTimeModelInside !== undefined && this.props.lastProfilingTimeModelInside !== null ? <div className="alert moniker-alert d-inline-block mt-3"
                                     style={{minWidth: '700px'}}
                                     role="alert">
                                    <p className="mb-0">
                                        <strong> {`Your data last profiled at ${this.props.lastProfilingTimeModelInside}`}
                                        </strong>
                                    </p>
                                </div> : "" }
                                    {this.formGroupedChart()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MPListViewFullScreen);