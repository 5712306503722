import React from 'react';
import ChartBody from "../../components/chartBody";
import moment from "moment";
import _ from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";
import {formatNumber} from "../../utils/common_utils";


class ThumbnailChartList extends React.Component {

    constructor(props, context) {
        super(props, context);
        let startDate = moment().startOf("day");
        this.state = {
            startData: startDate
        }

        this.getHorizontalSection = this.getHorizontalSection.bind(this);
        this.renderSingleThumbnailChart = this.renderSingleThumbnailChart.bind(this);
    }

    getClassInfoForChartStatistics(valueList, metricName) {
        let classDataForUp = {
            "classForNumber": "perform up",
            "classForStrongElement": "text-green"
        }
        let classDataForDown = {
            "classForNumber": "perform down",
            "classForStrongElement": "text-danger"
        }
        let classDataForNoChange = {
            "classForNumber": "",
            "classForStrongElement": ""
        }

        const valLength = valueList.length;
        if (valLength === 0) {
            return classDataForNoChange;
        }

        if (valLength === 1) {
            return classDataForNoChange
        }
        let valueIncreaseMetrics = ["Fill Rate", "Row Count", "Uniqueness"]
        let isPositive = valueIncreaseMetrics.includes(metricName) || this.props.isModel

        let latestValue = valueList[valLength - 1];
        let secondLatestValue = valueList[valLength - 2];
        let isValueDecreases = latestValue < secondLatestValue;
        let isValueIncreases = latestValue > secondLatestValue
        if (isValueDecreases && isPositive) {
            classDataForDown.classForNumber = "perform down"
            return classDataForDown;
        }else if(isValueDecreases && !isPositive){
           classDataForUp.classForNumber = "perform pve-down"
           return classDataForUp;
        }else if(isValueIncreases && isPositive){
           classDataForUp.classForNumber = "perform up"
           return classDataForUp;
        }else if(isValueIncreases && !isPositive) {
          classDataForDown.classForNumber = "perform nve-up"
          return classDataForDown;
        }else {
           return classDataForNoChange;
        }

    }

    renderSingleThumbnailChart(singleChartData) {
        if(singleChartData.hasDrift === false) {
           return
        }else if((singleChartData.chart_level === "dataset" || singleChartData.chart_level === "combined") && singleChartData.chartTitle === "mean_shap_value"){
            return
        }
        let metricName = singleChartData.name;
        let nSingleChartData = _.cloneDeep(singleChartData);
        const chartVals = nSingleChartData.chartData.values;
        const Y2_Values = nSingleChartData.chartData.Y2_Values;
        let firstNumberStrongClass = "";

        let firstNumClass;
        let firstNumber;
        /**Suffix can be added if required */
        // let sufix = METRIC_SCALE_MAPPING[nSingleChartData.name]
        
        let sufix = ""

        if (Array.isArray(chartVals[chartVals.length - 1])) {
            firstNumber = '';
            firstNumClass = "";
        } else {
            let classData = this.getClassInfoForChartStatistics(chartVals, metricName);
            firstNumber = chartVals[chartVals.length - 1]
            if (firstNumber % 1 !== 0) {
                firstNumber = firstNumber.toFixed(1)
                firstNumber = formatNumber(firstNumber)
                firstNumber = String(firstNumber)+sufix
            }
            else {
                firstNumber = formatNumber(firstNumber)
                firstNumber = String(firstNumber)+sufix
            }
            firstNumClass = classData.classForNumber;
            firstNumberStrongClass = classData.classForStrongElement;
        }

        let secondNumberStrongClass;
        let secondNumber;
        let secondNumberClass;
        let showSecondNumber = Y2_Values !== undefined && Y2_Values !== null;
        if (showSecondNumber) {
            secondNumber = Y2_Values[Y2_Values.length - 1]
            if (secondNumber % 1 !== 0) {
                secondNumber = String(secondNumber.toFixed(1))+sufix
            }
            else {
                secondNumber = String(secondNumber)+sufix
            }
            let classData = this.getClassInfoForChartStatistics(Y2_Values, metricName);
            secondNumberClass = classData.classForNumber;
            secondNumberStrongClass = classData.classForStrongElement;
        }

        if(singleChartData["errorList"] !== undefined) {
           singleChartData["chartData"]["errorList"] = nSingleChartData["errorList"]
        }
        if(singleChartData.drift_patterns !== undefined) {
           singleChartData["chartData"]["drift_patterns"] = nSingleChartData["drift_patterns"]
        }
        
        if(singleChartData.name === "Unique Values" && singleChartData.attribute_name === undefined ){
            // Updating Uniqueness metric label for dataset level. 
            singleChartData["name"] = "Unique Rows"
        }

        return (
            <div className="tbl_chart" key={singleChartData.idValue + "_div"}>
                <div className="chart-card" 
                style={{"cursor":"pointer"}}
                onClick={()=>this.props.fullattribute(this.props.listDataAttribute,singleChartData.name,firstNumber,firstNumClass,secondNumber)}
                >
                    <ChartBody
                        classValue="chart"
                        id={singleChartData.idValue.replace('%','_')}
                        key={singleChartData.idValue}
                        chartType={singleChartData.chartType}
                        style={{height: "250px"}}
                        scale={singleChartData.scale}
                        chart_data={singleChartData.chartData}
                        colorType={singleChartData.textColor}
                        issuedAttributes = {this.props.issuedAttributes}
                        isErrorChart={this.props.isErrorChart}
                    />
                </div>

                <div className="tbl_chart-caption">
                    <label>{singleChartData.name}</label>
                    <div>
                        <div>
                            <strong className={firstNumberStrongClass}>
                                {firstNumber}
                            </strong>
                            <span className={firstNumClass}/>
                        </div>
                        {
                            showSecondNumber ?
                                <div>
                                    <strong className={secondNumberStrongClass}>
                                        {secondNumber}
                                    </strong>
                                    <span className={secondNumberClass}/>
                                </div>
                                :
                                ""
                        }

                    </div>
                </div>
            </div>
        );
    }

    getHorizontalSection(sectionData) {
        let sectionName = sectionData.name;
        if(sectionName === "Model Explainability"){
            return ""
        }
        let sectionKey = sectionData.key;
        // This change is for the Model Performance List view tab
        if ( sectionData.data.length === 0 && sectionName === "Model Accuracy Metrics"){
                return (<div className="tbl-placeholder">
                                        <h2>
                                            <FontAwesomeIcon icon={faChartArea}/>
                                        </h2>
                                        {this.props.noModelConfigured === true ? "Model not configured on the selected time period" : (this.props.noDataAvailable === true || this.props.component_name === "modelPerformanceListView" ? "No Charts Found": "No Errors Found")}
                                    </div>);
        }
        if (sectionData.data.length === 0 && sectionName === "Model Evaluation Metrics"){
            return ""
        }
        // This change is for the Model Error List View tab
        if ( sectionData.data.length === 0 ){
            return (<div className="tbl-placeholder">
                                    <h2>
                                        <FontAwesomeIcon icon={faChartArea}/>
                                    </h2>
                                    {this.props.noModelConfigured === true ? "Model not configured" : (this.props.noDataAvailable === true ? "No Charts Found": "No Errors Found")}
                                </div>);
        }
        return (
            <dl className="tbl_horiz-charts" key={sectionKey}>
                <dt>{sectionName}</dt>
                <dd>
                    <div
                        className="tbl_horiz-chart-content">
                        {
                                sectionData.data.map(_d => this.renderSingleThumbnailChart(_d))
                        }

                    </div>
                </dd>
            </dl>
        );
    }

    render() {
        let allHorizontalSections = this.props.listData;
        return (
            allHorizontalSections.length > 0 ? allHorizontalSections.map(x => this.getHorizontalSection(x)) : <div className="tbl-placeholder">
                                    <h2>
                                        <FontAwesomeIcon icon={faChartArea}/>
                                    </h2>
                                    <h4>
                                    {this.props.noModelConfigured === true ? "Model not configured" : (this.props.noDataAvailable === true ? "Dataset not available": "No chart found")}
                                    </h4>
                                </div>
        )
    }
}

export default ThumbnailChartList;