import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import { CopyRightsFooter } from '../components/copyrights';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCog, faTachometerAlt, faSitemap, faCheck, faCheckCircle, faLongArrowAltRight, faDatabase, faWindowRestore,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Form from 'react-bootstrap/Form';
import {CallAPI} from "./pbihandling";
import {browserHistory} from 'react-router';
import $ from "jquery";
import Load from '../components/loadAction';
import {getFeatureAccess} from "../utils/common_utils";
import {IS_NONE_CHECK} from "../utils/constant";
import Image from "../components/image";
import Modal from "react-bootstrap/Modal";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import QuickStart from './quick_start'


class PowerBISetup extends React.Component {

    constructor(props) {
        super(props);
        this.CallAPI = CallAPI.bind(this);
        this.step1 = this.step1.bind(this);
        this.step2 = this.step2.bind(this);
        this.step3 = this.step2.bind(this);
        this.step0 = this.step0.bind(this);
        this.step0back = this.step0back.bind(this);
        this.prepareReport = this.prepareReport.bind(this);
        this.showAllDashboard = this.showAllDashboard.bind(this);
        this.gotoconfiguration = this.gotoconfiguration.bind(this)
        this.checkalreadyselecteditems = this.checkalreadyselecteditems.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.connectorsadd = this.connectorsadd.bind(this)
        this.connectorsremovecancel = this.connectorsremovecancel.bind(this)
        this.connectorsremove = this.connectorsremove.bind(this)


        this.state = {
            setqualdourlLink: false,
            powerbidashboard: null,
            powerbireport: null,
            checkall: false,
            step1: false,
            step2: false,
            step0: true,
            showmoreflag: false,
            alldashboard: '',
            fourdashboard: '',
            selecteddashboard: 0,
            pbiuserselectedlist: null,
            configureconfirmchecked:'yes',
            setupdata:null,
            connectionnext:'',
            alreadyselecteditems:[],
            deletesetup:false,
            conntorremove:''
        };
    }



    changestatus() {

        // this.setState({checkall:false});

    }  

    gotoconfiguration() {
        browserHistory.push("/configure");
    }

    handleCheckChieldElement = event => {

        let powerbidashboard = this.state.powerbidashboard;
        powerbidashboard.forEach(fruite => {

          if (fruite.id === event.target.value){
                fruite.checkedstatus = event.target.checked;
            }

        });
        let powerbireport = this.state.powerbireport;
        powerbireport.forEach(fruite => {
          if (fruite.id === event.target.value)
            fruite.checkedstatus = event.target.checked;
        });
        this.setState({ powerbidashboard: powerbidashboard,powerbireport:powerbireport});
    };

    
    step0() {
        if($(".is-checked").length > 0) {
            $("#step0").hide();
            $("#step1").show();
            this.setState({step1:true,step0:false});
            this.getpowerbidashboard();
        }
    }

    step0back() {
        this.setState({step0:true,step1:false,step2:false});
        $("#step0").show();
        $("#step1").hide();
    }

    handleDelete() {


    }

    step2() {

        let powerbidashboard = this.state.powerbidashboard;
        let powerbireport = this.state.powerbireport;
        let firstfourdashboard = '';
        let alldashboard = '';
        let firstfourcounter = 0;
        let selectedstatus = false;

        
        let newArray = powerbireport.filter(function (el) {
            return el.checkedstatus  === true 
          });

          if(newArray.length  > 0){
              


        powerbidashboard.forEach(fruite => {
            if(fruite.checkedstatus){
                selectedstatus = true;
                if(firstfourcounter < 4){
                    firstfourdashboard += `<div class="card card-dashboard mr-2"><div class="icon-box-square --type-dashboard"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" class="svg-inline--fa fa-tachometer-alt fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg></div><h5 class="card-title">`+fruite.name+`</h5></div>`;
                }
                alldashboard += `<div class="card card-dashboard mr-2"><div class="icon-box-square --type-dashboard"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" class="svg-inline--fa fa-tachometer-alt fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg></div><h5 class="card-title">`+fruite.name+`</h5></div>`;
                firstfourcounter++;
            }
            
        })

        powerbireport.forEach(fruite => {
            if(fruite.checkedstatus){
                selectedstatus = true;
                if(firstfourcounter < 4){
                    firstfourdashboard += `<div class="card card-dashboard mr-2"><div class="icon-box-square --type-report"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-bar" class="svg-inline--fa fa-chart-bar fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg></div><h5 class="card-title">`+fruite.name+`</h5></div>`;
                }
                alldashboard += `<div class="card card-dashboard mr-2"><div class="icon-box-square --type-report"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-bar" class="svg-inline--fa fa-chart-bar fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg></div><h5 class="card-title">`+fruite.name+`</h5></div>`;
                firstfourcounter++;
            }
            
        })

        $("#step0").hide();
        $("#step1").show();
        this.setState({step2:true,step0:false,step1:false});
     
        if(selectedstatus){
            this.setState({step1:false,step2:true,selecteddashboard:firstfourcounter,alldashboard:alldashboard,fourdashboard:firstfourdashboard});
            if(firstfourcounter < 5){
                $("#showmore").hide();
            }else {
                let moredashboardcnt = firstfourcounter-4;
                $("#showmore").html(`+ `+moredashboardcnt+` more dashboards`);
                $("#showmore").show();
            }
            $(".dashboard-content").html(firstfourdashboard);
        }




        // if(selectedstatus){
        //     this.setState({selecteddashboard:firstfourcounter,alldashboard:alldashboard,fourdashboard:firstfourdashboard});
        //     this.prepareReport();
        // } 

        // let powerbidashboard = this.state.powerbidashboard;
        // let powerbireport = this.state.powerbireport;
       
        // let firstfourdashboard = '';
        // let alldashboard = '';
        // let firstfourcounter = 0;
        // let selectedstatus = false;

        // powerbidashboard.forEach(fruite => {
        //     if(fruite.checkedstatus){
        //         selectedstatus = true;
        //         if(firstfourcounter < 4){
        //             firstfourdashboard += `<div className="card card-dashboard mr-2"><div className="icon-box-square --type-dashboard"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" className="svg-inline--fa fa-tachometer-alt fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg></div><h5 className="card-title">`+fruite.name+`</h5></div>`;
        //         }
        //         alldashboard += `<div className="card card-dashboard mr-2"><div className="icon-box-square --type-dashboard"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" className="svg-inline--fa fa-tachometer-alt fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg></div><h5 className="card-title">`+fruite.name+`</h5></div>`;
        //         firstfourcounter++;
        //     }
            
        // })

        // powerbireport.forEach(fruite => {
        //     if(fruite.checkedstatus){
        //         selectedstatus = true;
        //         if(firstfourcounter < 4){
        //             firstfourdashboard += `<div className="card card-dashboard mr-2"><div className="icon-box-square --type-report"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-bar" className="svg-inline--fa fa-chart-bar fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg></div><h5 className="card-title">`+fruite.name+`</h5></div>`;
        //         }
        //         alldashboard += `<div className="card card-dashboard mr-2"><div className="icon-box-square --type-report"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-bar" className="svg-inline--fa fa-chart-bar fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg></div><h5 className="card-title">`+fruite.name+`</h5></div>`;
        //         firstfourcounter++;
        //     }
            
        // })

        // if(selectedstatus){

        //     this.setState({step1:false,step2:true,selecteddashboard:firstfourcounter,alldashboard:alldashboard,fourdashboard:firstfourdashboard});
        //     $("#showmore").html(`+ `+firstfourcounter+` more dashboards`);
        //     if(firstfourcounter < 5){
        //         $("#showmore").hide();
        //     }else {
        //         $("#showmore").show();
        //     }
        //     $(".dashboard-content").html(firstfourdashboard);
        // }
    }
    }

    prepareReport() {
       // this.setState({checkall:value,powerbidashboard: powerbidashboard,powerbireport:powerbireport});

    
        var selected_dashboard_list = [];
        let powerbidashboard = this.state.powerbidashboard;
        powerbidashboard.forEach(fruite => {
            if(fruite.checkedstatus){
                var pbiselectedobj = {};
                pbiselectedobj.powerbi_dashboard_id =fruite.id;
                pbiselectedobj.name = fruite.name;
                pbiselectedobj.type = fruite.type;
                pbiselectedobj.embed_url = fruite.embed_url;
                selected_dashboard_list.push(pbiselectedobj);
            }
        });

        let powerbireport = this.state.powerbireport;
        powerbireport.forEach(fruite => {
            if(fruite.checkedstatus){
                let pbiselectedobj = {};
                pbiselectedobj.powerbi_dashboard_id =fruite.id;
                pbiselectedobj.name = fruite.name;
                pbiselectedobj.type = fruite.type;
                pbiselectedobj.embed_url = fruite.embed_url;
                pbiselectedobj.workspace = fruite.workspace;
                pbiselectedobj.integration_id = fruite.integration_id;
                pbiselectedobj.integration_name = fruite.integration_name;
                selected_dashboard_list.push(pbiselectedobj);
            }
        });

                this.setState({
                    pbiuserselectedlist:selected_dashboard_list 
                },() => {
                    this.CallAPI("savedashboard");
                });

        // let powerbireport = this.state.powerbireport;
        // powerbireport.forEach(fruite => {
        //     fruite.checkedstatus = value;
        // });
        // browserHistory.push("/report");    
        // {
        //     "user_qualdo_auth_token":"",
        //       "dashboard":[{
        //          "powerbi_dashboard_id": "a5e6bfb1-ed2c-446b-99dc-0e22a990e95f",
        //          "name": "Sales & Returns Sample v201912.pbix",
        //          "type": "report",
        //          "embed_url": "https://app.powerbi.com/dashboardEmbed?dashboardId=a5e6bfb1-ed2c-446b-99dc-0e22a990e95f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6ZmFsc2V9fQ%3d%3d"
        //  }]
        //  }
    }
    step1() {
        this.setState({step1:true,step2:false,showmoreflag:false});
    }

    goback() {
        browserHistory.push("/");    
    } 

    deselectall = (event) => {
    
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;


        if(input.type === 'checkbox'){
            let powerbidashboard = this.state.powerbidashboard;
            powerbidashboard.forEach(fruite => {
              //  this.checkalreadyselecteditems(fruite.id)
              if(this.state.alreadyselecteditems.indexOf(fruite.id) === -1) {
                fruite.checkedstatus = value;
              }else{
                fruite.checkedstatus = true;
              }

            });
            
            let powerbireport = this.state.powerbireport;
            powerbireport.forEach(fruite => {
               // this.checkalreadyselecteditems(fruite.id)
               if(this.state.alreadyselecteditems.indexOf(fruite.id) === -1) {
                fruite.checkedstatus = value;
              }else{
                fruite.checkedstatus = true;
              }
            });
            this.setState({checkall:value,powerbidashboard: powerbidashboard,powerbireport:powerbireport});
        }
    }
   getpowerbidashboard(){
        this.CallAPI('powerbisetup');
    }
    componentDidMount() {
       this.CallAPI('getpowerbiconnectors');
       this.CallAPI('powerbierrordashboardsetup');
    //   let json =  {"code":200,"datasources":[],"datasources_others":[],"message":"Successfully fetched","tenant_id":"1"}
    //    let json =  {"code":200,"datasources":[{"added":1,"environment_name":"Test","integration_id":1,"integration_name":"Sample Admin","workspace_name":"ALL "},{"added":0,"environment_name":"Test","integration_id":1,"integration_name":"Sample Admin","workspace_name":"pbiconnector"}],"datasources_others":[{
	// 	"added": 0,
	// 	"environment_name": "Test",
	// 	"integration_id": 1,
	// 	"integration_name": "Sample Admin",
	// 	"workspace_name": "pbiconnector"
	// }],"message":"Successfully fetched","tenant_id":"1"}

    //    this.setState({setupdata:json});
    //    let newArray = json.datasources.filter(function (el) {
    //     return el.added > 0 
    //   });
    //    $("#step1").hide();
    //    if(newArray.length===0){
    //    $('#step0next').attr('disabled', 'disabled'); 
    //    }
    }

    showAllDashboard() {
        let flag = this.state.showmoreflag === true ? false : true;
        this.setState({showmoreflag:flag});
         if(flag){
            $(".dashboard-content").html(this.state.alldashboard);
            $("#showmore").hide();
        }else {
            $(".dashboard-content").html(this.state.fourdashboard);
        }
    }

    // confireCheck(event) {
    //     console.log(event.target.checked.value);
    // }

    confireCheck = e => {
        // const { name, value } = e.target;
        if(e.target.checked) {
            this.setState({ configureconfirmchecked: e.target.value });
        }
      };

    checkalreadyselecteditems(id){
      if(this.state.alreadyselecteditems.length > 0 && this.state.alreadyselecteditems!== null){
        if(this.state.alreadyselecteditems.indexOf(id) === -1) {
            return "0";  // Readonly State
        }else {
            return "2";   // Unselected State
        }
      }else{
          return "1";  // All Checked 
      }
    }  
    
    

    connectorsremove(){



        let addflag = 0;
        if($("#"+this.state.conntorremove).hasClass("is-checked")) {
            addflag = 0;
            $("#"+this.state.conntorremove).removeClass("is-checked").addClass("btn-grey").html(`
          <i class="mr-1"><svg id="work_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Path_2038" data-name="Path 2038" d="M0,0H24V24H0Z" fill="none"/>
          <path id="plus-solid" d="M34,57a1.385,1.385,0,0,1-1.385,1.385H26.385v6.231a1.385,1.385,0,0,1-2.769,0V58.385H17.385a1.385,1.385,0,1,1,0-2.769h6.231V49.385a1.385,1.385,0,1,1,2.769,0v6.231h6.231A1.382,1.382,0,0,1,34,57Z" transform="translate(-13 -45)" />
        </svg></i>Add`)
         }

        let obj = {
            "email":localStorage.getItem("emailId"),
            "added": addflag,
            "integration_id": $("#"+this.state.conntorremove).attr("data-integrationid"),
            "integration_name": $("#"+this.state.conntorremove).attr("data-integration_name")
            }
           this.CallAPI("powerbi_status_update",obj);
           this.setState({deletesetup:false,conntorremove:''});

            setTimeout(function() {
            if($(".is-checked").length > 0) {
                $("#step0next").removeAttr("disabled");
            }else {
                $('#step0next').attr('disabled', 'disabled'); 
            }
            }, 100);
    }



    connectorsremovecancel(){
        this.setState({deletesetup:false});
    }

    connectorsadd(pm){
        let addflag = 0;
        if($("#"+pm).hasClass("is-checked")) {
        let integration_name = $("#"+pm).attr("data-integration_name");
        this.setState({deletesetup:true,conntorremove:pm},() => {
         $("#delete_message_setup").html("Are you sure you want delete "+integration_name+"?")
         });
        return;
        
        //     addflag = 0;
        //     $("#"+pm).removeClass("is-checked").addClass("btn-grey").html(`
        //   <i class="mr-1"><svg id="work_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //   <path id="Path_2038" data-name="Path 2038" d="M0,0H24V24H0Z" fill="none"/>
        //   <path id="plus-solid" d="M34,57a1.385,1.385,0,0,1-1.385,1.385H26.385v6.231a1.385,1.385,0,0,1-2.769,0V58.385H17.385a1.385,1.385,0,1,1,0-2.769h6.231V49.385a1.385,1.385,0,1,1,2.769,0v6.231h6.231A1.382,1.382,0,0,1,34,57Z" transform="translate(-13 -45)" />
        // </svg></i>Add`)

         }else {
            addflag =1;
             $("#"+pm).removeClass("btn-grey").addClass("is-checked").html(`<i class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
             <g id="Group_4901" data-name="Group 4901" transform="translate(3752 -3324)">
               <rect id="Rectangle_2660" data-name="Rectangle 2660" width="24" height="24" transform="translate(-3752 3324)" fill="none"/>
               <path id="check-solid" d="M17.623,96.387a1.283,1.283,0,0,1,0,1.816L7.337,108.49a1.283,1.283,0,0,1-1.816,0L.377,103.347A1.285,1.285,0,1,1,2.2,101.53l4.2,4.231,9.414-9.374a1.281,1.281,0,0,1,1.816,0Z" transform="translate(-3749 3233.562)" fill="#fff"/>
             </g>
           </svg>
           </i> Added`)
         }
        let obj = {
         "email":localStorage.getItem("emailId"),
         "added": addflag,
         "integration_id": $("#"+pm).attr("data-integrationid"),
         "integration_name": $("#"+pm).attr("data-integration_name")
         }
        this.CallAPI("powerbi_status_update",obj);
         setTimeout(function() {
         if($(".is-checked").length > 0) {
             $("#step0next").removeAttr("disabled");
         }else {
             $('#step0next').attr('disabled', 'disabled'); 
         }
         }, 100);
    }

    render() {

        const featureAccess = getFeatureAccess();
        let enablePowerBiTab = false;
        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const powerbiTab = featureAccess["powerbi"];
            enablePowerBiTab = powerbiTab === true;
        }
        // enablePowerBiTab =  true;
       let powerbidashboard =  this.state.powerbidashboard
       let powerbireport =  this.state.powerbireport
    //    let step0next = 0;

       return (

        enablePowerBiTab ? 
            <>
            <NavigationHeader page="powerbi" tab="pbi-setup"/>
                <div className="qd-container pt-3 pt-md-4">
                    <div className="qd-body no-tabs qd-grid shadow-none bg-transparent">
                        <div className="qd-aside-overlay"></div>
                        <div id="qd-section__section" className="qd-section qd-grid-item">
                            <div className="row m-0 m-sm-0">
                                <div className={ this.state.setupdata !== null ? 
                                           (this.state.setupdata.datasources.length === 0 && this.state.setupdata.datasources_others.length === 0) ?  "" : "col qd-section__center-focus" : "col qd-section__center-focus"}>
                                    <div className="portlet">
                                        <div className="portlet-body p-3 p-sm-4">
                                           { 
                                           this.state.setupdata !== null ? 
                                           (this.state.setupdata.datasources.length === 0 && this.state.setupdata.datasources_others.length === 0) ?  
                                            <div className="row">
                                                <div className="col-md-8 offset-md-2">
                                                    <div className="text-center my-5">
                                                        <i className="d-inline-block mb-3">
                                                            <Image src="icon_qd_pbi_configure" />
                                                        </i>
                                                        <h4 className="mb-3">No Power BI connection {this.state.setupdata.datasources.hasOwnProperty('integration_id')}</h4>
                                                        <p className="text-muted mb-4">The PowerBI connection does not exist. Using the configure button, set up your Power BI connection to Qualdo™.</p>
                                                        <div className="pt-2"><button onClick={this.gotoconfiguration} className="btn btn-primary btn-circle">Configure Now <i><FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></i></button></div>
                                                    </div>
                                                </div>
                                                <QuickStart />
                                            </div> 
                                            : ""
                                            : <Load/>
                                            }
                                            <div className=
                                            {
                                                this.state.setupdata !== null && (this.state.setupdata.datasources.length > 0 || this.state.setupdata.datasources_others.length > 0) ? '' : 'd-none'
                                            }
                                            >
                                                <div className="d-none">
                                                    <h2 className="mr-3 mb-0 align-self-center d-none d-sm-block text-blue"><FontAwesomeIcon icon={faCog} /></h2>
                                                    <div className="d-flex align-items-start flex-column">
                                                        <h3>Configure dashboards/reports to Qualdo™</h3>
                                                        <p className="mb-2 text-muted">Choose your Power BI dashboards/repots with Qualdo™ to monitor the Datasource and Datasets.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <h2 className="mr-3 mb-0 align-self-center d-none d-sm-block text-blue"><FontAwesomeIcon icon={faCog} /></h2>
                                                    <div className="d-flex align-items-start flex-column">
                                                        <h3>Choose your configured environment with Power BI</h3>
                                                        <p className="mb-2 text-muted">Choose your Power BI dashboard with Qualdo™ to monitor the Datasource and Datasets.</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div id="step0">
                                           { 
                                           this.state.setupdata !== null ? 
                                           (this.state.setupdata.datasources.length > 0) ? 
                                                <div className="pbi-connector-block mt-3 mt-sm-4">
                                                    <div className="pbi-connector-header">
                                                        <h4 className="pbi-connector-title order-last order-sm-first">Your Power BI connectors</h4>
                                                        <div className="action order-first order-sm-last">
                                                            <button onClick={this.gotoconfiguration} className="btn btn-light-blue btn-block">Configure New PowerBI Connector<i className="ml-2"><FontAwesomeIcon icon={faLongArrowAltRight} /></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="pbi-connector-body">
                                                        <div className="row row-sm">
                                                      
                                                        { this.state.setupdata.datasources.map((d, i) => (
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="pbi-connector-card h-100">
                                                                    <div className="row no-gutters h-100">
                                                                        <div className="col card-icon-wrap">
                                                                            <Image className="card-icon" src="icon_powerbi_dark" />
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="card-body">
                                                                           
                                                                            <div className="card-content">
                                                                            <h5 className="card-title">{d.environment_name}</h5>
                                                                             <p className="card-text">
                                                                                <i><FontAwesomeIcon icon={faDatabase} /></i>
                                                                                <span>{d.integration_name}</span>
                                                                            </p> 
                                                                             <p className="card-text">
                                                                                <i><FontAwesomeIcon icon={faWindowRestore} /></i>
                                                                                <span>{d.workspace_name}</span>
                                                                            </p> 
                                                                        </div>
                                                                        <div className="card-action">
                                                                            {/* <button data-integrationid={d.integration_id} data-integration_name={d.integration_name}  data-workspace_name={d.workspace_name} className={addflag===1 ? "btn btn-circle btn-outline btn-grey is-checked datasourceselecction" : "btn btn-circle btn-outline btn-grey datasourceselecction"}><i className={addflag===1 ? "icon-check mr-1" : "icon-plus mr-1"}></i>{addflag===1 ? "Added" : "Add"}</button> */}
                                                                           {d.added === 1 ? 
                                                                            <button id={'btn_'+i} data-integrationid={d.integration_id} data-integration_name={d.integration_name}  data-workspace_name={d.workspace_name} className={"btn btn-circle btn-outline is-checked  datasourceselecction"} onClick={() => this.connectorsadd('btn_'+i)}><i class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                            <g id="Group_4901" data-name="Group 4901" transform="translate(3752 -3324)">
                                                                              <rect id="Rectangle_2660" data-name="Rectangle 2660" width="24" height="24" transform="translate(-3752 3324)" fill="none"/>
                                                                              <path id="check-solid" d="M17.623,96.387a1.283,1.283,0,0,1,0,1.816L7.337,108.49a1.283,1.283,0,0,1-1.816,0L.377,103.347A1.285,1.285,0,1,1,2.2,101.53l4.2,4.231,9.414-9.374a1.281,1.281,0,0,1,1.816,0Z" transform="translate(-3749 3233.562)" fill="#fff"/>
                                                                            </g>
                                                                          </svg>
                                                                          </i> Added
                                                                          </button>
             :
             <button id={'btn_'+i} data-integrationid={d.integration_id} data-integration_name={d.integration_name}  data-workspace_name={d.workspace_name} className={"btn btn-circle btn-outline btn-grey  datasourceselecction"} onClick={() => this.connectorsadd('btn_'+i)}><i className={"mr-1"}><svg id="work_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
             <path id="Path_2038" data-name="Path 2038" d="M0,0H24V24H0Z" fill="none"/>
             <path id="plus-solid" d="M34,57a1.385,1.385,0,0,1-1.385,1.385H26.385v6.231a1.385,1.385,0,0,1-2.769,0V58.385H17.385a1.385,1.385,0,1,1,0-2.769h6.231V49.385a1.385,1.385,0,1,1,2.769,0v6.231h6.231A1.382,1.382,0,0,1,34,57Z" transform="translate(-13 -45)" />
           </svg></i>{"Add"}</button>
    }
             
                                                                        </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           ))}
                                                        </div>
                                                    </div>
                                                </div> : ""
                                                :""
                                                }

                                                { 
                                               (this.state.setupdata !== null && (this.state.setupdata.datasources_others.length > 0)) ?  
                                                <div className="pbi-connector-block">
                                                    <div className="pbi-connector-header">
                                                        <h4 className="pbi-connector-title">Others</h4>
                                                    </div>
                                                    <div className="pbi-connector-body">
                                                        <div className="row row-sm">
                                                       { this.state.setupdata.datasources_others.map(d => (
                                                                          <div className="col-lg-6 mb-3">
                                                                          <div className="pbi-connector-card h-100">
                                                                              <div className="row no-gutters h-100">
                                                                                  <div className="col card-icon-wrap">
                                                                                      <Image className="card-icon" src="icon_powerbi_dark" />
                                                                                  </div>
                                                                                  <div className="col">
                                                                                      <div className="card-body">
                                                                                          <div className="card-content">
                                                                                              <h5 className="card-title">{d.environment_name}</h5>
                                                                                              <p className="card-text">
                                                                                                  <i><FontAwesomeIcon icon={faDatabase} /></i>
                                                                                                  <span>{d.integration_name}</span>
                                                                                              </p>
                                                                                              <p className="card-text">
                                                                                                  <i><FontAwesomeIcon icon={faWindowRestore} /></i>
                                                                                                  <span>{d.workspace_name}</span>
                                                                                              </p>
                                                                                          </div>
                                                                                          <div className="card-action">
                                                                                              <OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                  <Popover className="popover-danger">
                                                                                                      <Popover.Content>
                                                                                                          <span className="text-danger"><i><FontAwesomeIcon icon={faTimesCircle} /></i> {localStorage.getItem("emailId")}</span> email id is out of the scope to connect with Power BI
                                                                                                      </Popover.Content>
                                                                                                  </Popover>
                                                                                              }>
                                                                                                  <button className="btn btn-circle btn-outline btn-grey" disabled><i className="icon-plus mr-1"></i>Add</button>
                                                                                              </OverlayTrigger>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  
                                                        ))}

                                                        
                                                        </div>
                                                    </div>
                                                </div> : ""
                                                } 
                                            </div>
                                                <div id="step1">
                                                {
                                                (this.state.powerbidashboard === null) ? 
                                                <Load /> : 
                                                <div className={this.state.step1 ? "my-3" : "my-3 d-none"}>
                                                    <Table borderless responsive className="react-custom-table">
                                                        <thead>
                                                            <tr>
                                                                <th><Form.Check type="checkbox" checked={this.state.checkall} onChange={this.deselectall} /></th>
                                                                <th><FontAwesomeIcon icon={faFileAlt} /></th>
                                                                <th>Name</th>
                                                                <th>Workspace</th>
                                                                <th>Datasource Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                        {powerbidashboard.map((item, i) => ( 
                                                            item.workspace !== "QUALDO_POWERBI_WORKSPACE" ? 
                                                            <tr>
                                                            <td>
                                                                {
                                                                    this.checkalreadyselecteditems(item.id) === "1" ? 
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id} checked={item.checkedstatus}  onClick={this.handleCheckChieldElement} />
                                                                    : this.checkalreadyselecteditems(item.id) === "2" ?
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id} checked disabled onClick={this.handleCheckChieldElement} />
                                                                    :
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id}  checked={item.checkedstatus}  onClick={this.handleCheckChieldElement} />
                                                                  
                                                                }   
                                                            </td>
                                                            <td>
                                                                <div className="icon-box-square --type-dashboard"><FontAwesomeIcon icon={faTachometerAlt} /></div>
                                                            </td>
                                                            <th>{item.name}{item.checkedstatus}</th>
                                                            <td>{item.workspace}</td>
                                                             </tr> : "" 
                                                         ))}

                                                            {powerbireport.map((item, i) => ( 
                                                             item.workspace !== "QUALDO_POWERBI_WORKSPACE" ? 
                                                            <tr>
 <td>

 {
                                                                    this.checkalreadyselecteditems(item.id) === "1" ? 
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id} checked={item.checkedstatus}  onClick={this.handleCheckChieldElement} />
                                                                    : this.checkalreadyselecteditems(item.id) === "2" ?
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id} checked disabled onClick={this.handleCheckChieldElement} />
                                                                    :
                                                                    <Form.Check type="checkbox" id={item.id} value={item.id}  checked={item.checkedstatus}  onClick={this.handleCheckChieldElement} />
                                                                  
                                                                }

                                                                {/* {
                                                     this.checkalreadyselecteditems(item.id) === "1" ? 
                                                     <Form.Check type="checkbox" id={item.id} value={item.id} checked  onClick={this.handleCheckChieldElement} />
                                                     : this.checkalreadyselecteditems(item.id) === "2" ?
                                                     <Form.Check type="checkbox" id={item.id} value={item.id} checked disabled onClick={this.handleCheckChieldElement} />
                                                     : (item.checkedstatus === true) ?
                                                     <Form.Check type="checkbox" id={item.id} value={item.id}  checked={item.checkedstatus}  onClick={this.handleCheckChieldElement} />
                                                     :
                                                     <Form.Check type="checkbox" id={item.id} value={item.id}  onClick={this.handleCheckChieldElement} />
                                                                } */}
                                                            </td>                                                            <td>
                                                                <div className="icon-box-square --type-report"><FontAwesomeIcon icon={faTachometerAlt} /></div>
                                                            </td>
                                                            <th>{item.name}{item.checkedstatus}</th>
                                                            <td>{item.workspace}</td>
                                                            <td>{item.integration_name}</td>
                                                             </tr> : "" 
                                                         ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                }

{/* <div className={this.state.step2 ? "py-2 py-sm-3" : "text-center py-3 py-md-5 d-none"}> */}
                                                    {/* <div className={this.state.configureconfirmchecked === "yes" ?  "card_radio-selector --is-selected" : "card_radio-selector"} id="configure_dashboard_check">
                                                        <div className="custom-control custom-radio flex-column">
                                                            <input type="radio"
                                                                id="customRadio1"
                                                                name="customRadio"
                                                                value="yes"
                                                                className="custom-control-input"
                                                                checked={this.state.configureconfirmchecked === "yes"}
                                                                onChange={this.confireCheck}
                                                            />
                                                            <label className="custom-control-label" htmlFor="customRadio1">
                                                                <div className="card-content">
                                                                    <h4 className="card-title">Configure to start monitoring your Dashboards and Reports</h4>
                                                                    <p className="card-text">Choose your Power BI dashboard with Qualdo™ to monitor the Datasource and Datasets.</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className={this.state.configureconfirmchecked === "skip" ?  "card_radio-selector --is-selected" : "card_radio-selector"} id="configure_dashboard_skip_check">
                                                        <div className="custom-control custom-radio flex-column">
                                                            <input type="radio"
                                                                id="customRadio2"
                                                                name="customRadio"
                                                                value="skip"
                                                                checked={this.state.configureconfirmchecked === "skip"}
                                                                className="custom-control-input" 
                                                                onChange={this.confireCheck}

                                                            />
                                                            <label className="custom-control-label" htmlFor="customRadio2">
                                                                <div className="card-content">
                                                                    <h4 className="card-title">Skip to configure later</h4>
                                                                    <p className="card-text">Choose your Power BI dashboard with Qualdo™ to monitor the Datasource and Datasets.</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                {/* </div> */}
                                                </div> 

                                                 <div className={this.state.step2 ? "text-center py-3 py-md-5" : "text-center py-3 py-md-5 d-none"}>
                                                    <i className="d-inline-block mb-4">
                                                        <Image src="icon_green_check"
                                                        />
                                                    </i>
                                                    <h3 className="mb-3">Well Done!</h3>
                                                    <p className="mb-4">Following dashboards and reports will be monitored for data issues</p>
                                                    <div className="dashboard-container mb-3 mb-sm-5">
                                                        <div className="dashboard-content">
                                                            {/* <div className="card card-dashboard mr-2">
                                                                <div className="icon-box-square --type-dashboard">
                                                                    <FontAwesomeIcon icon={faTachometerAlt} />
                                                                </div>
                                                                <h5 className="card-title">Star Dashboard</h5>
                                                            </div>
                                                            <div className="card card-dashboard mr-2">
                                                                <div className="icon-box-square --type-report">
                                                                    <FontAwesomeIcon icon={faChartBar} />
                                                                </div>
                                                                <h5 className="card-title">BB</h5>
                                                            </div>
                                                            <div className="card card-dashboard mr-2">
                                                                <div className="icon-box-square --type-report">
                                                                    <FontAwesomeIcon icon={faChartBar} />
                                                                </div>
                                                                <h5 className="card-title">ZeeNetwork</h5>
                                                            </div>
                                                            <div className="card card-dashboard mr-2">
                                                                <div className="icon-box-square --type-dashboard">
                                                                    <FontAwesomeIcon icon={faTachometerAlt} />
                                                                </div>
                                                                <h5 className="card-title">Piperr Dashboard</h5>
                                                            </div> */}
                                                        </div>
                                                        <button id="showmore" type="button" onClick={this.showAllDashboard} className="btn btn-link">+20 more dashboards</button>
                                                    </div>
                                                    <p className='text-muted'>Qualdo generated report issue will be ready in a few moments.</p>
                                                    <div className="alert alert-light d-inline-block"><small>
                                                    Qualdo will create a default workspace named "QUALDO_PBI_WORKSPACE"
                                                    </small></div>
                                                </div> 
                                                <hr />

                                                <div className={this.state.step0 ? "text-center mt-4" : "text-center mt-4 d-none"}>
                                                    {/* <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.goback}>Go Back</button> */}
                                                    <button id="step0next"  className="btn btn-primary btn-circle" type="submit" onClick={this.step0}>Next <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></button>
                                                </div>
                                                <div className={this.state.step1 ? "text-center mt-4" : "text-center mt-4 d-none"}>
                                                    <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.step0back}>Go Back</button>
                                                    <button id="step1next" className="btn btn-primary btn-circle" type="submit" onClick={this.step2}>Next <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></button>
                                                </div>
                                                <div className= {this.state.step2 ? "text-center mt-4" : "text-center mt-4 d-none"} >
                                                    <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.step1}>Go Back</button>
                                                    <button className="btn btn-primary btn-circle" type="submit" onClick={this.prepareReport}><FontAwesomeIcon icon={faCheck} className="ml-1 mr-0" /> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRightsFooter/>

                <div className="qd-container d-none">
                    <div className="qd-body no-tabs p-4">
                        <div className="portlet">
                            <div className="portlet-title">
                                <div className="caption">
                                    <div className="text-uppercase font-dark">
                                        Monitoring Dashboard
                                    </div>
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className="react-bootstrap-table table-responsive">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>S.No
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Dashboard Name
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Type
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Owner
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Refreshed
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Quality Status
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Processing Status
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Last Processing Time
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Action
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Star Dashboard</td>
                                                <td>Dashboard</td>
                                                <td>Jai Priya</td>
                                                <td>Wed, 17 Jun 2020 11:48:32 GMT</td>
                                                <td>--</td>
                                                <td><span className="badge badge-light">Not yet started</span></td>
                                                <td>Wed, 17 Jun 2020 11:48:32 GMT</td>
                                                <td>
                                                <button type="button" className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>BB</td>
                                                <td>Report</td>
                                                <td>Shagul</td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td><span className="badge badge-light-success border-0"><FontAwesomeIcon icon={faCheckCircle} /> No Errors</span></td>
                                                <td><span className="badge badge-light-success">Completed</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <button type="button" className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>ZeeNetwork</td>
                                                <td>Report</td>
                                                <td>Sasikumar</td>
                                                <td>Mon, 12 Jan 2022 11:11:10 GMT</td>
                                                <td>--</td>
                                                <td><span className="badge badge-light-warning">In Progress</span></td>
                                                <td>Mon, 12 Jan 2022 11:11:10 GMT</td>
                                                <td>
                                                <button type="button" className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button> 
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Piperr Dashboard</td>
                                                <td>Dashboard</td>
                                                <td>Rakesh</td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td><span className="badge badge-light-danger border-0"><FontAwesomeIcon icon={faTimesCircle} /> Error</span></td>
                                                <td><span className="badge badge-light-success">Completed</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <button type="button" className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button>  
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>BB</td>
                                                <td>Report</td>
                                                <td>Shagul</td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>--</td>
                                                <td><span className="badge badge-light-danger">Failed Authentication</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <button type="button" className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button>   
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={false} size="md" centered>
                    <Modal.Body>
                        <button type="button" className="icon-cross"><span className="sr-only">Close</span></button>
                        <div className="modal__alert">
                            <div className="modal_alert-icon mb-3">
                                <Image src="icon_pbi_configure_success" />
                            </div>
                            <div className="modal_alert-text">
                                <h4>Power BI configuration has been Done!</h4>
                                <p>Your Power BI configuration <strong className="text-nowrap">(Power BI_Envi)</strong> <br className="d-none d-sm-block" />has been created.</p>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-primary btn-circle">Power BI - Setup <i><FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></i></button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


        <Modal show={this.state.deletesetup} centered onHide="">
            <Modal.Body>
                <div className="modal__alert">
                    <div className="modal_alert-icon">
                        <i className="text-danger">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </i>
                    </div>
                    <div className="modal_alert-text">
                        <h4 id="delete_message_setup">Are sure want to remove powerbi connector?</h4>
                        <p>This will delete your qualdo power bi reports</p>
                    </div>
                    <div className="modal_alert-btn-group">
                        <button type="button" 
                                className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.connectorsremovecancel}>Cancel
                        </button>
                        <button type="button"
                                className="btn btn-primary btn-circle mr-2" onClick={this.connectorsremove} >Delete
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>



            </> : <></>
        );
    }
}

export default PowerBISetup;