import React from "react";
import NavigationHeader from "../../components/navigationHeader";
import BreadCrumb from "../../components/breadCrumb";
import Load from "../../components/loadAction";
import {CopyRightsFooter} from "../../components/copyrights";

class NotificationLoader extends React.Component {
    render() {
        return (
            <>
                <NavigationHeader/>
                <main>
                    <BreadCrumb icon='notifications' title='Qualdo Notifications'/>
                    <div className="qd-container">
                        <div className="qd-grid">
                            <div className="qd-body no-tabs qd-grid">
                                <div id="qd-aside" className="qd-aside qd-aside-notification qd-grid-item">
                                    <Load/>
                                </div>
                                <div
                                    className="text-center text-muted d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                    <Load/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <CopyRightsFooter/>
            </>
        );
    }
}

export default NotificationLoader;
