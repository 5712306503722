import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';


class Security extends React.Component {


    render() {
        return (
            <>
                <div className="qd-section_content  shadow-sm settings-preferences">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title">
                            <i>
                                <FontAwesomeIcon icon={faUserShield}/>
                            </i> Security
                        </h4>
                    </div>
                <div className="qd-section-body">
                    <div className="row row-sm">
                        <div className="col-md-12">
                            <div className="portlet">
                                <div className="portlet-body py-4">
                                     COMING SOON
                                </div>
                            </div>
                        </div>

                  </div>  </div>
            </div>
        </>
        );
    }

}
export default Security;



