import React from 'react';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {postMetricsData} from "../../utils/event_handling";
import {getId, getSQLQueryErrorComponents, validateSQLQueryMetric, userValidation,sortDict} from "../../utils/common_utils";
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    SQL_QUERY_DUMMY_PLACE_HOLDER, SQL_QUERY_NOTE_1,
    SQL_QUERY_NOTE_2
} from "../../utils/constant";
import { normalizeAttributeName, normalizeChildDatasetName } from '../../utils/attribute_name_utils';



class DataQualityMetricsForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.postMetricsData = postMetricsData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.metricAssociations = [];
        this.data_set_options = [];
        this.state = {
            QuerySelection: "custom query",
            dataSource: null,
            data_set: null,
            hideSubmitButton: false,
            aggregation_2: '',
            attributes: {attribute_name_1: '',attribute_name_2: ''},
            metric_data: {
                metric_name: '',
                mas_id: null,
                selected_datasource: '',
                selected_dataset: null,
                attribute_name_2: '',
                attribute_name_1: '',
                aggregation_1: null,
                operation: null,
                aggregation_2: null,
                drift_threshold: 10,
                metrics_threshold: "",
                metric_condition: null,
            },
            exValues: {
                metric_name: '',
                mas_id: '',
                selected_dataset: '',
                selected_datasource: '',
                attribute_name_2: '',
                attribute_name_1: '',
                aggregation_1: '',
                operation: '',
                aggregation_2: '',
                drift_threshold: 10,
                metrics_threshold: "",
                metric_condition: ""
            }
        };
    }

    componentDidMount() {
        const ex_metric_details = this.props.ex_metric_details;
        let data = this.fill_details(ex_metric_details);
        if (data === null) {
            return;
        }

        let isSqlQuery = (data.metric_data.formula_type === "sql_as_formula");
        let selectedQuery = isSqlQuery ? "sql query" : "custom query";
        if(data.data_set !== undefined) {
          this.setState({data_set: data.data_set});
        }
        this.setState({
            QuerySelection: selectedQuery,
            metric_data: data.metric_data,
            attributes: data.attributes,
            exValues:data.exValues,
            dataSource: data.dataSource
        });
    }

    getDatasets(dataSource) {
        let optionArr = [];

        if (dataSource !== null && dataSource !== undefined) {
            let id = dataSource.value;
            if (this.props.details.data_set !== undefined && this.props.details.data_set !== null && this.props.datasets !== null) {
                this.props.details.data_set.map(function (row) {
                if (row["integration_id"] === id) {
                    optionArr.push({"label": normalizeChildDatasetName(row["data_set_name"]), "value": row["data_set_id"]});
                }
                return 1;
            });
        }

    }
        return optionArr;
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    fill_details(ex_metric_details) {
        this.metricAssociations = this.getAssociations();
        if (ex_metric_details === undefined || ex_metric_details === null) {
            return null;
        }

        let metric_data = this.state.metric_data;
        let exValues = this.state.exValues;
        metric_data.metric_name = ex_metric_details.metric_name;
        exValues.metric_name = ex_metric_details.metric_name;
        let metrics_definition = ex_metric_details.metrics_definition;
        let dataSource = metrics_definition.integration;
        if(dataSource !== undefined) {
          exValues.selected_datasource = dataSource.value;
        }
        this.data_set_options = this.getDatasets(dataSource);
        if (metrics_definition.formula.query) {
            let queryData = metrics_definition.formula.query;
            metric_data.sqlQuery = queryData;
            exValues.sqlQuery = queryData;
        }
        // Get the matching metrics association option to set it as default option
        let mas_id = ex_metric_details.mas_id;
        let match = this.metricAssociations.filter(x => x.value === mas_id);
        metric_data.mas_id = match[0];
        exValues.mas_id = match[0].value;

        // Get the matching dataset option to set it as default option
        let data_set_id = ex_metric_details.data_set_id;
        let data_set_match = this.data_set_options.filter(x => x.value === data_set_id);
        if (data_set_match.length > 0) {
            metric_data.selected_dataset = data_set_match[0];
            exValues.selected_dataset = data_set_match[0].value;
        }
        const formula = metrics_definition.formula;

        let ex_attributes = this.state.attributes;
        if (typeof formula === "object") {
            if (formula.type === "custom_formula") {
                let component_1 = formula.components[0];
                const aggregation_1 = component_1.aggregation;
                metric_data.aggregation_1 = {"label": aggregation_1, "value": aggregation_1};
                exValues.aggregation_1 = aggregation_1;
                let att_name_1 = component_1.attribute_name;
                let reqAttributeName_1 = normalizeAttributeName(att_name_1);
                ex_attributes.attribute_name_1 = {"label": reqAttributeName_1, "value": att_name_1};
                exValues.attribute_name_1 = att_name_1;
                if(formula.components[1] !== undefined) {
                        const operator = formula.components[1].operation;
                        metric_data.operation = {"label": operator, "value": operator};
                        exValues.operation = operator;
                        let component_2 = formula.components[2];
                        const aggregation_2 = component_2.aggregation;
                        metric_data.aggregation_2 = {"label": aggregation_2, "value": aggregation_2};
                        exValues.aggregation_2 = aggregation_2;
                        let att_name_2 = component_2.attribute_name;
                        let reqAttributeName_2 = normalizeAttributeName(att_name_2)
                        ex_attributes.attribute_name_2 = {"label": reqAttributeName_2, "value": att_name_2};
                        exValues.attribute_name_2 = att_name_2;
                }
            }
            else if (formula.type === "sql_as_formula") {
                metric_data.formula_type = "sql_as_formula";
            }
        }

        let driftThresholdValue = metrics_definition.drift_threshold;
        exValues.drift_threshold = driftThresholdValue;

        let metricsThresholdValue = metrics_definition.metrics_threshold;
        exValues.metrics_threshold = metricsThresholdValue;
        let metricsThresholdCondition;
        let val;
        if (metrics_definition.metric_condition !== undefined && metrics_definition.metric_condition !== null && metrics_definition.metric_condition !== ""){
            val = metrics_definition.metric_condition;
            metricsThresholdCondition = {"label": val, "value": val};
        } else {
            val = ">";
            metricsThresholdCondition = {"label": ">", "value": ">"};
        }
        metric_data.metric_condition=metricsThresholdCondition;
        exValues.metric_condition = val;
        return {
            "metric_data": metric_data,
            "attributes": ex_attributes,
            "exValues":exValues,
            "data_set": data_set_match[0],
            "dataSource": dataSource
        };
    }

    getAssociations() {
        let option_arr = [];
        let data = [];

        if (this.props.mas_data === undefined || this.props.mas_data === null) {
            return option_arr;
        }

        data = this.props.mas_data;
        if (data !== "") {
            option_arr = data.map(function (row) {
                return {"label": row["mas_name"], "value": row["mas_id"]};
            });
        }

        return option_arr;
    }

    getAttributes() {
        let data_set = this.state.data_set;
        let id;
        if(data_set !== null) {
          id = data_set.value;
        }
        if (data_set === null && this.state.metric_data.selected_dataset !== null) {
            // This will be invoked while populating the existing metric definition in form
            // for the first time
            id = this.state.metric_data.selected_dataset.value;
        }

        let option_arr = [];
        if (this.props.details.attributes != null) {
            this.props.details.attributes.map(function (row) {
                if (row["data_set_id"] === id) {
                    let reqAttributeName = row["attribute_name"];
                    let normAttributeName = normalizeAttributeName(reqAttributeName);
                    option_arr.push({"label": normAttributeName, "value": reqAttributeName});
                }
                return 1;
            });
        }
        return option_arr;
    }

    requires_metric_definition_update(data) {
        if (this.props.ex_metric_details === undefined || this.props.ex_metric_details === null) {
            return true;
        }

        if (data.data_set_id !== this.props.ex_metric_details.data_set_id) {
            return true;
        }

        let current_formula = JSON.stringify(data.metrics_definition.formula);
        let ex_formula = JSON.stringify(this.props.ex_metric_details.metrics_definition.formula);

        if (current_formula !== ex_formula) {
            return true;
        }

        let current_drift_threshold = JSON.stringify(data.metrics_definition.drift_threshold);
        let ex_drift_threshold = JSON.stringify(this.props.ex_metric_details.metrics_definition.drift_threshold);

        let current_metrics_threshold = JSON.stringify(data.metrics_definition.metrics_threshold);
        let ex_metrics_threshold = JSON.stringify(this.props.ex_metric_details.metrics_definition.metrics_threshold);

        if (current_drift_threshold !== ex_drift_threshold) {
            return true;
        }

        if (current_metrics_threshold !== ex_metrics_threshold) {
            return true;
        }

        if (data.mas_id !== this.props.ex_metric_details.mas_id) {
            return true;
        }

    }

    constructMetricData(event) {
        let {metric_name,mas_id,selected_dataset,sqlQuery,attribute_name_1,attribute_name_2,aggregation_1,aggregation_2,operation, drift_threshold, metrics_threshold, metric_condition} = event;
        let mas_type = null;
        if (this.props.type === "ml") {
            mas_type = "mlm";
        }
        else if (this.props.type === "dq") {
            mas_type = "dqm";
        }

        if (typeof metrics_threshold === 'string')
        {
            metrics_threshold = parseFloat(metrics_threshold);
            if (isNaN(metrics_threshold)) {
                metrics_threshold = 0;
            }
        }

        const team_id = Number(localStorage.getItem('team_id'));
        const current_timestamp = new Date().getTime();
        const data_set_id = Number(selected_dataset);
        let formulaData = null
        let dimensionValue = null
        if (this.state.QuerySelection === "custom query") {
            formulaData = {
                "components": [{
                    "aggregation": aggregation_1,
                    "attribute_name": attribute_name_1
                }, ],
                "type": "custom_formula"
            }
            if(attribute_name_2 !== '' && aggregation_2 !== ''){
               formulaData["components"].push({"operation": operation});
               formulaData["components"].push({"aggregation": aggregation_2,"attribute_name": attribute_name_2});
            }
            dimensionValue = "attribute"
        }
        else {
            formulaData = {"query": sqlQuery, "type": "sql_as_formula"}
            dimensionValue = "dataset"
        }
        return {
            "tenant_id": team_id,
            "metric_name": metric_name,
            "metric_type": "UDF",
            "mas_id": Number(mas_id),
            "data_set_id": data_set_id,
            "schedule_interval": "@once",
            "metrics_definition": {
                "name": metric_name,
                "version": "1",
                "compute_drift": false,
                "drift_threshold": drift_threshold,
                "metrics_threshold": metrics_threshold,
                "metric_condition":metric_condition,
                "mas_type": mas_type,
                "dimension": dimensionValue,
                "checks": [],
                "sources": [],
                "timestamp": current_timestamp,
                "formula": formulaData,
                "measures": [],
                "integration": this.state.dataSource,

            }
        }
    }

    handleChange = e => {
        let value = e.target.value
        if(this.props.src_page !== "edit_metrics" && value === "sql query"){
            let attributes = this.state.attributes;
            attributes.attribute_name_1 = '';
            attributes.attribute_name_2 = '';
            this.setState({ attributes:attributes});
        }
        this.setState({QuerySelection: value});
    }

    componentWillMount() {
        this.customQueryId = getId(this.props.type);
        this.sqlQueryId = getId(this.props.type);

    }


    render() {
        let exDetails = this.props.ex_metric_details;
        let attribute_options = this.getAttributes();

        let aggregationKey1 = "keyEmptyAggregation1";
        if (this.state.metric_data.aggregation_1 !== null) {
                    aggregationKey1 = "keyUpdatedAggregation1";
        }

        let aggregationKey2 = "keyEmptyAggregation2";
        if (this.state.metric_data.aggregation_2 !== null) {
                    aggregationKey2 = "keyUpdatedAggregation2";
        }

        let operationKey = "emptyOperationKey";
        if (this.state.metric_data.operation !== null) {
                    operationKey = "updatedOperationKey";
        }

        let conditionKey = "conditionKey";
        if (this.state.metric_data.metric_condition !== null) {
            conditionKey = "updateConditionKey";
        }
        let agg_options = [
            {"label": "count", "value": "count"},
            {"label": "standard_dev", "value": "standard_dev"},
            {"label": "minimum", "value": "minimum"},
            {"label": "maximum", "value": "maximum"},
            {"label": "mean", "value": "mean"}];

        let operations = [
            {"label": "+", "value": "+"},
            {"label": "*", "value": "*"},
            {"label": "/", "value": "/"},
            {"label": "%", "value": "%"}];

        let condition_options = [{"label":">", "value":">"},
                                 {"label":"<", "value":"<"},
                                 {"label":">=", "value":">="},
                                 {"label":"<=", "value":"<="}];

        let metricSchema = '';
        let schemaObj = {
                metric_name: yup.string().min(1,"Name must be at least 1 characters").required("Metrics name is a required field").test(
                    'metric name test',
                    'invalid Metric name',        
                    function test(value) {        
                       let val = userValidation(value,"input");       
                       return val;
                }
                ),
                mas_id: yup.string().required("Associate with is a required field"),
                selected_datasource: yup.string().required("Datasource is a required field"),
                selected_dataset: yup.string().required("Dataset is a required field"),
                aggregation_1: yup.string().required("Aggregation is a required field"),
                attribute_name_1: yup.string().required("Attribute is a required field"),
                drift_threshold: yup.string().required("Drift Threshold is a required field"),
                metrics_threshold: yup.string().required("Metrics Threshold is a required field"),
                metric_condition: yup.string().required("Condition is a required field")
            }
        if(this.state.QuerySelection === "custom query") {
             if(this.state.aggregation_2 !== ''  && this.state.attributes.attribute_name_2 !== '') {
                 schemaObj.operation = yup.string().required("Operation is a required field")
                 metricSchema = yup.object(schemaObj);
             }else {
                 metricSchema = yup.object(schemaObj);
             }
        }else {
             metricSchema = yup.object({
                metric_name: yup.string().min(1,"Name must be at least 1 characters").required("Metrics name is a required field").test(
                    'metric name test',
                    'invalid Metric name',        
                    function test(value) {        
                       let val = userValidation(value,"input");       
                       return val;
                }
                ),
                mas_id: yup.string().required("Associate with is a required field"),
                selected_datasource: yup.string().required("Datasource is a required field"),
                selected_dataset: yup.string().required("Dataset is a required field"),
                sqlQuery: yup.string().required("SQL query is a required field"),
                drift_threshold: yup.string().required("Drift Threshold is a required field"),
                metrics_threshold: yup.string().required("Metrics Threshold is a required field"),
                metric_condition: yup.string().required("Condition is a required field")
             });
        }

        let associateKey = "emptyAssociateKey";
        if (exDetails !== undefined && this.state.metric_data.mas_id !== null) {
            associateKey = "updatedAssociateKey"
        }

        let datasourceSelectionKey = "emptyDatasourceSelection";
        if (exDetails !== undefined && this.state.dataSource !== null) {
            datasourceSelectionKey = "updatedDatasourceSelection";
        }
        let datasetSelectionKey = "emptyDatasetSelection";
        if (exDetails !== undefined && this.state.dataSource !== null) {
            datasetSelectionKey = "updatedDatasetSelection";
        }

        let metricAssociations = this.metricAssociations;
        if (metricAssociations.length === 0) {
            metricAssociations = this.getAssociations();
        }

        let datasetOptions = this.data_set_options;
        if (datasetOptions.length === 0) {
            datasetOptions = this.getDatasets(this.state.dataSource);
        }

    /** Sorting Datasource and dataset options for the From dropDown */
        if(datasetOptions.length>1){
            datasetOptions = sortDict(datasetOptions)
        }

        let datasourceOptions = [];
        if(this.props.integrationOption !== null && this.props.integrationOption.length > 1){
            datasourceOptions= sortDict(this.props.integrationOption)
        }
        else{
            datasourceOptions= this.props.integrationOption
        }
    /** Sorting Datasource and dataset options for the Form dropDown */


        let initial_values = {metric_name:'',mas_id:'',selected_datasource: '',selected_dataset: '', sqlQuery:'',attribute_name_1: '',attribute_name_2: '',aggregation_1: '',aggregation_2: '',operation: '',dimension: '', drift_threshold: '', metrics_threshold: '', metric_condition:''}
        return (
            <div className="row">
                <div className="col-md-12 pt-4">
                    <Formik
                        validationSchema={metricSchema}
                        validate={values => validateSQLQueryMetric(values, this.state.QuerySelection)}
                        onSubmit={this.postMetricsData}
                        initialValues={exDetails !== undefined ? this.state.exValues : initial_values}
                    >
                    {({
                          values,
                          setFieldValue,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                    <Form name='metricsForm' onSubmit={handleSubmit}
                          method="post" style={{maxWidth: '900px', margin: '0 auto'}}>
                        <Form.Group controlId="metric_name"  className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Metric Name <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Form.Control
                                      type="text"
                                      name="metric_name"
                                      className="form-control"
                                      isInvalid={errors.metric_name && touched.metric_name}
                                      value={values.metric_name}
                                      onChange={handleChange}
                                      placeholder="Metric Name"/>
                                <Form.Control.Feedback type="invalid">
                                                        { touched.metric_name && errors.metric_name }
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="mas_id" className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Associate with <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Select
                                        name="mas_id"
                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                        id="metricsAssociation"
                                        key={associateKey}
                                        defaultValue={this.state.metric_data.mas_id}
                                        options={metricAssociations}
                                        placeholder="Associate with"
                                        isInvalid={errors.mas_id && touched.mas_id}
                                        onChange={selectedOption => {
                                                  handleChange("mas_id")(selectedOption.value.toString());
                                        }}
                                        classNamePrefix='select-control'
                                />
                                <ErrorMessage component="div" className="error-text" name="mas_id" />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="selected_datasource" className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Datasource <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Select
                                        onChange={selectedOption => {
                                                  handleChange("selected_datasource")(selectedOption.value.toString());
                                                  setFieldValue('selected_dataset', '')
                                                  setFieldValue('attribute_name_1', '')
                                                  setFieldValue('attribute_name_2', '')
                                                  let attributes = this.state.attributes;
                                                  attributes.attribute_name_1 = '';
                                                  attributes.attribute_name_2 = '';
                                                  this.setState({dataSource: selectedOption, attributes:attributes, data_set: null});
                                        }}
                                        name="selected_datasource"
                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                        id="metricsDatasource"
                                        isInvalid={errors.selected_datasource && touched.selected_datasource}
                                        key={datasourceSelectionKey}
                                        defaultValue={this.state.dataSource}
                                        options={datasourceOptions}
                                        isDisabled = {exDetails !== undefined ? true:false}
                                        placeholder="Select datasource"
                                        classNamePrefix='select-control'
                                />
                                {this.state.dataSource === null && <ErrorMessage component="div" className="error-text" name="selected_datasource" />}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="selected_dataset" className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Dataset <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Select
                                        onChange={selectedOption => {
                                                  handleChange("selected_dataset")(selectedOption.value.toString());
                                                  setFieldValue('attribute_name_1', '')
                                                  setFieldValue('attribute_name_2', '')
                                                  let attributes = this.state.attributes;
                                                  attributes.attribute_name_1 = '';
                                                  attributes.attribute_name_2 = '';
                                                  this.setState({data_set: selectedOption, attributes:attributes});
                                        }}
                                        name="selected_dataset"
                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                        id="metricsDataset"
                                        isInvalid={errors.selected_dataset && touched.selected_dataset}
                                        key={datasetSelectionKey}
                                        value={this.state.data_set}
                                        options={datasetOptions}
                                        isDisabled = {exDetails !== undefined ? true:false}
                                        placeholder="Select dataset"
                                        classNamePrefix='select-control'
                                />
                                {this.state.data_set === null && <ErrorMessage component="div" className="error-text" name="selected_dataset" />}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="drift_threshold"  className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Drift Threshold <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Form.Control
                                      type="number"
                                      name="drift_threshold"
                                      className="form-control"
                                      isInvalid={errors.drift_threshold && touched.drift_threshold}
                                      value={values.drift_threshold}
                                      onChange={handleChange}
                                      placeholder="Drift Threshold"/>
                                <Form.Control.Feedback type="invalid">
                                                        { touched.drift_threshold && errors.drift_threshold }
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        {/*
                        <Form.Group controlId="metrics_threshold"  className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Metrics Threshold <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Form.Control
                                      type="number"
                                      name="metrics_threshold"
                                      className="form-control"
                                      isInvalid={errors.metrics_threshold && touched.metrics_threshold}
                                      value={values.metrics_threshold}
                                      onChange={handleChange}
                                      placeholder="Metric Threshold"/>
                                <Form.Control.Feedback type="invalid">
                                                        { touched.metrics_threshold && errors.metrics_threshold }
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        */}
                        <Form.Group className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Metrics Threshold <span className="text-danger">*</span> :</Form.Label>
                             <div className="col-md-6">
                                <Form.Group className="form-row">
                                 <div className="col-md-6 col-6">
                                    <Select
                                        options={condition_options}
                                        id="metric_condition"
                                        name="metric_condition"
                                        key={conditionKey}
                                        filterOption={({label}, query) => label.includes(query)}
                                        isInvalid={errors.metric_condition && touched.metric_condition}
                                        onChange={selectedOption => {
                                          handleChange("metric_condition")(selectedOption.value.toString());
                                        }}
                                        defaultValue={this.state.metric_data.metric_condition}
                                        placeholder="Condition"
                                        classNamePrefix='select-control'
                                    />
                                    <ErrorMessage component="div" className="error-text" name="metric_condition" />
                                </div>
                                <div className="col-md-6">
                                   <Form.Control
                                      type="number"
                                      name="metrics_threshold"
                                      className="form-control"
                                      isInvalid={errors.metrics_threshold && touched.metrics_threshold}
                                      value={values.metrics_threshold}
                                      onChange={handleChange}
                                      placeholder="Metric Threshold"/>
                                   <Form.Control.Feedback type="invalid">
                                                        { touched.metrics_threshold && errors.metrics_threshold }
                                    </Form.Control.Feedback>
                                </div>
                             </Form.Group>
                            </div>
                        </Form.Group>
                        <Form.Group className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Formula :</Form.Label>
                            <div className="col-md-6" >
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                          type="radio"
                                          name="queryName"
                                          id={this.customQueryId}
                                          value="custom query"
                                          checked={this.state.QuerySelection === "custom query"}
                                          onChange={this.handleChange}
                                          disabled = {exDetails !== undefined && this.state.QuerySelection === "sql query"? true:false}
                                          className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.customQueryId}>
                                        Custom Query
                                    </Form.Label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                          type="radio"
                                          name="queryName"
                                          id={this.sqlQueryId}
                                          value="sql query"
                                          checked={this.state.QuerySelection === "sql query"}
                                          onChange={this.handleChange}
                                          disabled = {exDetails !== undefined && this.state.QuerySelection === "custom query"? true:false}
                                          className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.sqlQueryId}>
                                        Sql Query
                                    </Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        {this.state.QuerySelection === "sql query" ?
                            <Form.Group controlId="sql_type" className="row">
                                <Form.Label className="col-md-4 col-form-label text-md-right">Sql Query
                                    <span className="text-danger">*</span> :
                                </Form.Label>
                                <div className="col-md-6">
                                    <Form.Control
                                        as="textarea"
                                        ows="2"
                                        type="text"
                                        name="sqlQuery"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sqlQuery}
                                        disabled = {exDetails !== undefined ? true:false}
                                        isInvalid={errors.sqlQuery && touched.sqlQuery}
                                        className="form-control"
                                        placeholder={SQL_QUERY_DUMMY_PLACE_HOLDER}/>

                                        <div className="form-text text-muted small mt-1">
                                            <strong>Note:</strong>
                                            {SQL_QUERY_NOTE_1}
                                            <br/>
                                            {SQL_QUERY_NOTE_2}

                                        </div>

                                    <Form.Control.Feedback type="invalid">
                                        {getSQLQueryErrorComponents(touched.sqlQuery && errors.sqlQuery)}
                                    </Form.Control.Feedback>
                                </div>


                            </Form.Group> :
                            <>
                        <Form.Group  className="row">
                            <Form.Label className="col-md-4 col-form-label text-md-right">Query <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-6">
                                <Form.Group className="form-row">
                                    <div className="col-md-6 col-6">
                                        <Select
                                                options={agg_options}
                                                id="metricsAggregation1"
                                                name="aggregation_1"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                key={aggregationKey1}
                                                isInvalid={errors.aggregation_1 && touched.aggregation_1}
                                                onChange={selectedOption => {
                                                  handleChange("aggregation_1")(selectedOption.value.toString());
                                                }}
                                                defaultValue={this.state.metric_data.aggregation_1}
                                                isDisabled = {exDetails !== undefined ? true:false}
                                                placeholder="Aggregation"
                                                classNamePrefix='select-control'
                                        />
                                        <ErrorMessage component="div" className="error-text" name="aggregation_1" />
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <Select
                                                name="attribute_name_1"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                id="metricsAttribute1"
                                                onChange={selectedOption => {
                                                  handleChange("attribute_name_1")(selectedOption.value.toString());
                                                  let attributes = this.state.attributes;
                                                  attributes.attribute_name_1 = {"label":selectedOption.label,"value":selectedOption.value.toString()}
                                                  this.setState({attributes:attributes});
                                                }}
                                                isInvalid={errors.attribute_name_1 && touched.attribute_name_1}
                                                options={attribute_options}
                                                value={this.state.attributes.attribute_name_1}
                                                isDisabled = {exDetails !== undefined ? true:false}
                                                placeholder="Select Attribute"
                                                classNamePrefix='select-control'
                                        />
                                        <ErrorMessage component="div" className="error-text" name="attribute_name_1" />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form-row">
                                    <div className="col-md-6 col-6">
                                        <Select
                                                options={operations}
                                                id="metricsOperation"
                                                name="operation"
                                                filterOption={({label}, query) => label.includes(query)}
                                                key={operationKey}
                                                isInvalid={errors.operation && touched.operation}
                                                onChange={selectedOption => {
                                                  handleChange("operation")(selectedOption.value.toString());
                                                }}
                                                defaultValue={this.state.metric_data.operation}
                                                isDisabled = {exDetails !== undefined ? true:false}
                                                placeholder="Operation"
                                                classNamePrefix='select-control'
                                        />
                                        <ErrorMessage component="div" className="error-text" name="operation" />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form-row">
                                    <div className="col-md-6 col-6">
                                        <Select
                                                options={agg_options}
                                                name="aggregation_2"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                id="metricsAggregation2"
                                                key={aggregationKey2}
                                                isInvalid={errors.aggregation_2 && touched.aggregation_2}
                                                onChange={selectedOption => {
                                                  handleChange("aggregation_2")(selectedOption.value.toString());
                                                  this.setState({aggregation_2:selectedOption.value.toString()})
                                                }}
                                                defaultValue={this.state.metric_data.aggregation_2}
                                                isDisabled = {exDetails !== undefined ? true:false}
                                                placeholder="Aggregation"
                                                classNamePrefix='select-control'
                                        />
                                        <ErrorMessage component="div" className="error-text" name="aggregation_2" />
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <Select
                                                name="attribute_name_2"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                id="metricsAttribute2"
                                                isInvalid={errors.attribute_name_2 && touched.attribute_name_2}
                                                onChange={selectedOption => {
                                                handleChange("attribute_name_2")(selectedOption.value.toString());
                                                let attributes = this.state.attributes;
                                                attributes.attribute_name_2 = {"label":selectedOption.label,"value":selectedOption.value.toString()}
                                                this.setState({attributes:attributes});
                                                }}
                                                options={attribute_options}
                                                value={this.state.attributes.attribute_name_2}
                                                isDisabled = {exDetails !== undefined ? true:false}
                                                placeholder="Select Attribute"
                                                classNamePrefix='select-control'
                                        />
                                        <ErrorMessage component="div"
                                                      className="error-text"
                                                      name="attribute_name_2" />
                                    </div>
                                </Form.Group>
                            </div>
                        </Form.Group></>}
                        <Form.Group className="row">
                            <div className="col-md-5 offset-sm-4">
                                <button type="button"
                                        onClick={() => this.props.ex_metric_details ? this.props.closeModal() : this.props.handleClick()}
                                        className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                <button type="submit"
                                        className="btn btn-primary btn-circle"
                                        disabled={this.state.hideSubmitButton}>
                                    <FontAwesomeIcon icon={faCheck}/>Submit
                                </button>
                            </div>
                        </Form.Group>
                    </Form>)}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default DataQualityMetricsForm;