import React from 'react';
import {INDEX_IN_AB} from "../../utils/constant";

export function ModelABSingleStat(props) {
    const propsData = props.data;
    const reqLabel = propsData.model_name;
    const reqText = INDEX_IN_AB[propsData.index_in_ab];

    return (
        <p>
            <span className={props.classNameVal}>{reqText}</span>
            <span>
                <label>{"Model:"}</label>

                {reqLabel}
            </span>
        </p>
    );
}

export function ModelABCVStats(props) {
    const propsData = props.data;
    const associatedModels = propsData.associatedModels;

    return (
        <>
            {associatedModels[0] === null ? '' :
                <ModelABSingleStat data={associatedModels[0]}
                                   classNameVal={"abtest_parent"}/>
            }

            {associatedModels[1] === null ? '' :
                <ModelABSingleStat data={associatedModels[1]}
                                   classNameVal={"abtest_child"}/>
            }

            {associatedModels[2] === null ? '' :
                <ModelABSingleStat data={associatedModels[2]}
                                   classNameVal={"abtest_nextchild"}/>
            }
        </>
    );
}