import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ButtonActions from "./buttonActions";

export function TeamTableButtons(props) {
    const [approveUser, setApproveUser] = useState(false);
    const approveClose = () => setApproveUser(false);
    const approveShow = () => setApproveUser(true);

    const [rejectUser, setRejectUser] = useState(false);
    const rejectClose = () => setRejectUser(false);
    const rejectShow = () => setRejectUser(true);

    return (
        <ButtonActions
            userDetails={props.userDetails}
            showToast={props.showToast}
            approveUser={approveUser}
            setApproveUser={setApproveUser}
            approveShow={approveShow}
            approveClose={approveClose}
            rejectUser={rejectUser}
            setRejectUser={setRejectUser}
            rejectShow={rejectShow}
            rejectClose={rejectClose}
            component="requests"
        />
    );
}

