import React from 'react';
import QualdoDataTable from '../components/bootstrapTable';
// import {METADATA_MAP_VALUE_SEPARATOR, normalizeAttributeName, normalizeChildDatasetName} from '../utils/attribute_name_utils';
import AttributeNameComp from "../components/tableCellComponents/attributeNameComp";
import {SRC_ATTRIBUTE_COLUMN, DEST_ATTRIBUTE_COLUMN} from '../utils/constant';
import ProgressBar from 'react-bootstrap/ProgressBar';

const ATTRIBUTE_NAME_COLUMNS = [SRC_ATTRIBUTE_COLUMN, DEST_ATTRIBUTE_COLUMN];

class RelationshipListView extends React.Component{
     constructor(props, context) {
        super(props, context);
        this.formTableData = this.formTableData.bind(this);
        this.renderDiscoverTableComponents = this.renderDiscoverTableComponents.bind(this);
        this.state = {

        }
     }

   renderDiscoverTableComponents(actionType, headerValue, cell) {

      if (ATTRIBUTE_NAME_COLUMNS.includes(headerValue)) {
         return (
            <AttributeNameComp tooltipPlacement={"right"}
               attributeName={cell}>
            </AttributeNameComp>
         )
      } else if(headerValue === "Relationship Score") {
         let percentage = cell.value * 100;
         return (<div className="data-progress-percentage">
                     <div className="progress">
                          <ProgressBar now={percentage} />
                     </div>
                     <h5 className="data-progress-value">{`${percentage}%`}</h5>
                </div>);
    }

      return cell;
   }

   formTableData() {
      let tabledata = this.props.chartDatas;
      let filterdata = tabledata.filter(laur => {
         return (laur.imports.length > 0) ? true : false;
       });
       let finalData = []
       for(let loop=0;loop<filterdata.length;loop++){ 
         if(parseInt(this.props.filter_data_set_id) === parseInt(filterdata[loop]['dataSetId']) || this.props.filter_data_set_id === null){
         let parentattrnam = filterdata[loop]['name'];
         let parentdatasetname = filterdata[loop]['datasetName'];
         let confidence_score = filterdata[loop]['confidence_score']
         for(let innerloop=0;innerloop<filterdata[loop].imports.length;innerloop++){ 
         let tableList = []
         tableList.push({"value": parentdatasetname, "type": "td"});
         tableList.push({"value": parentattrnam, "type": "td"});
         tableList.push({"value": filterdata[loop].imports[innerloop]['data_set_name'], "type": "td"});
         tableList.push({"value": filterdata[loop].imports[innerloop]['attribute_name'], "type": "td"});
         tableList.push({"value": confidence_score, "type": "progressBar"});
         finalData.push(tableList);
         }
         }
      }
      return finalData;
     }

     render() {
      let tableList = this.formTableData()
        let tableData = {"headers": ["Source Dataset", SRC_ATTRIBUTE_COLUMN, "Destination Dataset", 
                                     DEST_ATTRIBUTE_COLUMN, "Relationship Score"], 
                          "data": tableList
                        }
     // let tableData = this.props.dataModule    
      return (
           <QualdoDataTable
              customGetActionComponent={this.renderDiscoverTableComponents}
              data={tableData}
              component_name='discover'
           />
        );
     }

}

export default RelationshipListView;