import React from 'react';
import DropdownTreeSelect from "react-dropdown-tree-select";
import $ from 'jquery';
import "./nested-data.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomTreeSelectAttribute extends React.Component {

    constructor(props) {
        super(props);
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadFilter = this.loadFilter.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.state = {
            id: this.props.id,
            placeholder: this.props.placeholder,
            selected_id: [],
            selected_id_index: [],
            data: this.props.data,
            cur_data: "",
            renderDataset: true
        }
    }
    loadFilter() {
        let filterDatasetId = []
        let filterAttributeId = []
        let cur_data = this.props.data;
        let parms
        let datasetindex;
        if (this.props.name === "attributes") {
            for (let i = 0; i < cur_data.length; i++) {
                let datasetid;
                if (cur_data[i].value !== "*") {
                    if (cur_data[i].dataset_id !== undefined) {
                        datasetindex = -1;
                        datasetid = cur_data[i]["dataset_id"];
                        datasetindex = filterDatasetId.findIndex(function (item) {
                            return item === datasetid;
                        });
                        if (datasetindex === -1) {
                            filterDatasetId.push(datasetid)
                        }
                    }
                    if (cur_data[i].value > 0 && cur_data[i].checked === true) {
                        filterAttributeId.push(cur_data[i].value)
                    }

                    if (cur_data[i]["children"] !== undefined) {
                        for (let j = 0; j < cur_data[i]["children"].length; j++) {
                            let datasetid;
                            if (cur_data[i]["children"][j].value !== "*") {
                                datasetindex = -1;
                                datasetid = cur_data[i]["children"][j]["dataset_id"];
                                datasetindex = filterDatasetId.findIndex(function (item) {
                                    return item === datasetid;
                                });
                                if (datasetindex === -1) {
                                    filterDatasetId.push(datasetid)
                                }
                                if (cur_data[i]["children"][j].value > 0 && cur_data[i]["children"][j]["checked"] === true) {
                                    filterAttributeId.push(cur_data[i]["children"][j].value);
                                }
                            }
                        }
                    }
                }
            }
            parms = {
                filterdDatsource: [this.props.seletedDatasource],
                fiteredDatseset: filterDatasetId,
                fitlerdDate: [this.props.startDate.format("Y/MM/DD"), this.props.endDate.format("Y/MM/DD")],
                fitlerdAttr: filterAttributeId
            }
            this.props.loadFilter(parms)
            return;
        }
    }

    componentDidMount() {
        $('body').click(function () {
            $(".textfield-outlined").removeClass("customselect-active");
        });

        $(document).off('click', '#treeselectapply');
        $(document).on('click', '#treeselectapply', function (e) {
            this.loadFilter();
            $(".textfield-outlined").removeClass("customselect-active");
            document.body.click()
        }.bind(this));

        $(document).off('click', '#treeselectcancel');
        $(document).on('click', '#treeselectcancel', function (e) {
            document.body.click()
            $(".textfield-outlined").removeClass("customselect-active");
            // eslint-disable-next-line
        }.bind(this));
        this.setState({ "cur_data": this.state.data });

        let cur_id = this.state.id;
        $("#" + cur_id + " .search").keyup(function () {
            if ($("span").hasClass("no-matches")) {
                $(".custom-dropdown-action").remove();
                setTimeout(() => {
                    $(".custom-dropdown-action").remove();
                }, 100);
            } else {
                setTimeout(() => {
                    if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && this.props !== undefined && this.props.data.length > 1) {
                        $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                        <button type="button" id="treeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                        <button type="button" id="treeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                        $("#" + cur_id).parent().parent().addClass('customselect-active');
                    }
                }, "100");
            }
        });
    }

    componentDidUpdate() {
        // let cur_id = this.state.id;
        setTimeout(() => {
            if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && this.props.data !== undefined && this.props.data.length > 1) {
                $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                <button type="button" id="treeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                <button type="button" id="treeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
            }
        }, "100");
    }

    onFocus() {
        // While changing datasource need to render the dropdown with latest data
        if (this.props.name === "data_set") {
            this.setState({ renderDataset: true, type: "data_set" })
        }
        if (this.props.name === "attributes") {
            this.setState({ renderDataset: true, type: "attributes" })
        }
        let cur_id = this.state.id;
        if (this.state.data.length > 1) {
            setTimeout(() => {
                if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && this.props.data !== undefined) {
                    if (this.props.data.length > 1) {
                        $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                    <button type="button" id="treeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" id="treeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                        $("#" + cur_id).parent().parent().addClass('customselect-active');
                    }
                }
            }, "100");
        }

    }

    onChange = (currentNode, selectedNodes) => {
        if (currentNode.value === "*") {
            this.setState({ renderDataset: false })
            this.toggleAll(currentNode);
        } else {
            let cur_data = this.props.data;
            if (currentNode["_depth"] === 0) {
                let index = cur_data.findIndex(function (item) {
                    return item.value === currentNode["value"] && item.dataset_id === currentNode["dataset_id"];
                });
                if (index > -1) {
                    cur_data[index]["checked"] = currentNode["checked"]
                    if (cur_data[index]["children"] !== undefined) {
                        for (let j = 0; j < cur_data[index]["children"].length; j++) {
                            cur_data[index]["children"][j].checked = currentNode.checked;
                        }
                    }
                }
            }
            
            else if (currentNode["_depth"] === 1) {
                let index = cur_data.findIndex(function (item) {
                    return item.value === currentNode["dataset_id"];
                });
                cur_data = cur_data.map((data)=> {
                    if(data.value === currentNode.data_set_id && currentNode["checked"] === false){
                        let checkAll = false
                        data.checked = checkAll
                        return data
                    }

                    else if(data.value === currentNode.data_set_id && currentNode["checked"] === true){
                    
                        let checkedAll = false
                        if(data.children !== undefined){
                            let childAtt = data.children

                            let CheckedList = childAtt.filter((childData)=> childData.checked !== false)
                            
                            
                            if(data.children.length === CheckedList.length+1 ){
                                /** Length -1 is included because the current data have the selecting attribute value as false
                                 *  As of now handled with -1 login further we can to check the logic
                                 */
                                checkedAll = true
                            }
                            else {
                            }
                        }
                        data.checked = checkedAll
                        return data
                    }
                    else{
                        return data
                    }
                })


                if (cur_data[index]["children"] !== undefined) {
                    let childindex = -1;
                    if (index > -1) {
                        childindex = cur_data[index]["children"].findIndex(function (item) {
                            return item.value === currentNode["value"];
                        });
                    }
                    if (childindex > -1) {
                        cur_data[index]['children'][childindex]["checked"] = currentNode["checked"]
                    }
                }
            }

            this.props.handleAttributesSelection(cur_data)
            return;


            /** Commented the below due to above return to avoid ui warnings */
            // let index = cur_data.findIndex(function(item){
            //     return item.value === currentNode["value"] && item.dataset_id === currentNode["dataset_id"];
            // });
            // if(index === -1){

            // }
            // if(index > -1){
            //     cur_data[index]["checked"] = currentNode["checked"]
            // }
            // if(index > -1){
            // if(cur_data[index]["children"]!==undefined){
            //     let childindex = -1;
            //     if(index > -1){
            //         childindex = cur_data[index]["children"].findIndex(function(item){
            //         return item.value === currentNode["value"];
            //     });
            //     }
            //     if(childindex > -1){
            //         cur_data[index]['children'][childindex]["checked"] = currentNode["checked"]
            //     }
            // }

            // }
            // this.props.handleAttributesSelection(cur_data)
        }

    }
    onAction = (node, action) => {


    }

    toggleAll = (node) => {
        let data = this.props.data;
        if (node.value === "*" && node.dataset_id === undefined) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]["children"] === undefined) {
                    data[i].checked = node.checked;
                } else {
                    data[i].checked = node.checked;
                    for (let j = 0; j < data[i]["children"].length; j++) {
                        data[i]["children"][j].checked = node.checked;
                    }
                }
            }
        }
        else if (node.value === "*" && node.dataset_id > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]["children"] === undefined && parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                    if (parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                        data[i].checked = node.checked;
                    }
                } else {
                    if (data[i]["children"] !== undefined && parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                        data[i].checked = node.checked;
                        for (let j = 0; j < data[i]["children"].length; j++) {
                            data[i]["children"][j].checked = node.checked;
                        }
                    }
                    else {
                        if (parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                            data[i].checked = node.checked;
                        }
                    }
                }
            }
        }
        this.props.handleAttributesSelection(data)
    };

    onNodeToggle = currentNode => {
        let index = -1;
        if (this.props.name === "data_set") {
            let cur_data = this.props.data;
            index = cur_data.findIndex(function (item) {
                return item.value === currentNode["value"];
            });
            if (index > -1) {
                cur_data[index]["expanded"] = currentNode["expanded"]
            }
            this.setState({ cur_data: cur_data });
            this.props.handleExpand(cur_data);
        } else {
            let cur_data = this.props.data;
            index = cur_data.findIndex(function (item) {
                return item.value === currentNode["value"];
            });
            if (index > -1) {
                cur_data[index]["expanded"] = currentNode["expanded"]
            }
            this.setState({ cur_data: cur_data });
            this.props.handleAttributesSelection(cur_data);
        }
    }

    render() {
        let cur_data = [];
        let checkall = 0;
        let selectplaceholdercnt = 0;

        if (this.props.data !== undefined) {

            cur_data = this.props.data;

            for (let i = 0; i < cur_data.length; i++) {
                if (cur_data[i].checked === true && cur_data[i].value !== "*" && cur_data[i].value !== undefined && cur_data[i].children === undefined) {
                    selectplaceholdercnt++;
                }

                /** Handle un-checking of all option under dataset */
                if(cur_data[i].value === "*" && cur_data[i].dataset_id !== undefined){
                    let data = cur_data.filter((data)=> data.dataset_id === cur_data[i].dataset_id && data.checked !== true)
                    if(data.length > 0){
                        cur_data[i].checked = false
                    } 
                    if(data.length === 1 && data[0].value === "*"){
                        cur_data[i].checked = true
                    } 
                }

                if (cur_data[i]["children"] !== undefined) {
                    for (let j = 0; j < cur_data[i]["children"].length; j++) {
                        if (cur_data[i]["children"][j].value !=="*" && cur_data[i]["children"][j].checked === true && cur_data[i].value !== "*" && cur_data[i].value !== undefined) {
                            if(cur_data[i]["children"][j].value !=="*"){
                                selectplaceholdercnt++;
                            }
                        }
                    }
                }
            }
        }
        checkall = cur_data.filter((data) =>data.checked !== true && data.value !== undefined);
        if(cur_data.length !== 1 && checkall.length===1 && checkall[0].value === "*"){
            cur_data[0].checked = true
        }
        else{
            cur_data[0].checked = false
        }
        return (
            <div className="react-dropdown-tree">
                <div className="react-dropdown-tree-prepend">
                    <FontAwesomeIcon icon={this.props.prependIcon} />
                </div>
                <DropdownTreeSelect
                    id={this.props.id}
                    className="bootstrap-demo"
                    data={cur_data}
                    onChange={this.onChange}
                    onAction={this.onAction}
                    onNodeToggle={this.onNodeToggle}
                    onFocus={this.onFocus}
                    texts={{ placeholder: selectplaceholdercnt === 0 ? this.props.placeholder : selectplaceholdercnt + " selected", noMatches: "No Options" }}
                />
            </div>
        );
    }
}
export default CustomTreeSelectAttribute;