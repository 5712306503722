import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import NavigationHeader from '../components/navigationHeader';
import {CopyRightsFooter} from '../components/copyrights';
import Image from './image';


let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO

class CookiePolicy extends React.Component {

    render() {
              let sessionToken = localStorage.getItem('sessionToken');
              return (
                <>
                {sessionToken === null ?
                    <header className="qd-header">
                    <div className="qd-header-container navbar navbar-expand-md">
                        <div className="qd-header__brand">
                            <a href="#!">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src={BRAND_LOGO}

                                />
                            </a>
                        </div>
                    </div>
                    </header>
                : <NavigationHeader/>
                }
                <main>
                    <BreadCrumb icon='cookie' title='Cookie Policy' />
                        <div className="qd-container">
                                <div className="qd-body no-tabs p-4">
                                   <div className="policy-doc">
                                        <p>
                                          This Cookie Policy (this <span className="highlight-text">"Policy"</span>) describes how and when Qualdo™,
                                          Inc. (<span className="highlight-text">"Qualdo™"</span>, "we," "us," or "our") uses cookies on our website
                                          located at https://www.Qualdo™hq.com/ (the <span className="highlight-text">"Site"</span>), as well how you
                                          can manage the cookie settings in your browser. By continuing to visit the Site or use our services, you
                                          are agreeing to use of the cookies and similar technologies for the purposes we describe in this Policy.
                                        </p>
                                        <h4>What is a Cookie?</h4>
                                        <p> A cookie is a small text file downloaded to your browser when you visit a website that enables certain
                                          features and functionality. You can think of cookies as providing a 'memory' for a website, so that it can
                                          recognize users and user activity over time. For instance, a cookie may be used to record where you have
                                          paused a video so that you can resume playing it from the same spot later on. Most web pages contain
                                          elements from multiple web domains, so when you visit the Site your browser may receive cookies from
                                          several sources. This may include third parties that Qualdo™ has hired to provide services such as site
                                          analytics or ad targeting. Qualdo™ also uses web beacons, which are electronic images that may be used in
                                          our services or emails and help deliver cookies, count visits and understand usage and campaign
                                          effectiveness.
                                        </p>
                                        <h4>How Qualdo™ Uses Cookies and Other Tracking Technologies</h4>
                                        <p> Qualdo™ uses cookies and similar technologies, such as web beacons, to help us understand the profile of
                                          our visitors, recognize when you are signed in to your account, and provide you with a better user
                                          experience. We may also use cookies to analyze and track data, determine the popularity of certain
                                          content, deliver advertising and content targeted to your interests on our services and other websites and
                                          better understand your online activity.
                                        </p>
                                        <h4>Third-Party Use of Cookies</h4>
                                        <p> Some content or applications on the Site are served by third-parties, including advertisers, ad networks
                                          and servers, content providers, and application providers. These third parties may use cookies alone or in
                                          conjunction with web beacons or other tracking technologies to collect information about you when you use
                                          our website. The information they collect may be associated with your personal information or they may
                                          collect information, including personal information, about your online activities over time and across
                                          different websites and other online services. They may use this information to provide you with
                                          interest-based (behavioral) advertising or other targeted content.
                                        </p>
                                        <p> We do not control these third parties’ tracking technologies or how they may be used. If you have any
                                          questions about an advertisement or other targeted content, you should contact the responsible provider
                                          directly.
                                        </p>
                                        <h4>What are your Choices Regarding Cookies?</h4>
                                        <p> Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set
                                          your browser to remove or reject browser cookies. Please note that if you choose to remove or reject
                                          cookies, this could affect the availability and functionality of our services. To learn more about how to
                                          control cookie settings through your browser:
                                        </p>

                                        <h4>Where can you find more information about cookies?</h4>
                                        <p> If you want to learn more about cookies, or how to control, disable or delete them, please visit
                                          http://www.aboutcookies.org for detailed guidance. In addition, certain third party advertising networks,
                                          including Google, permit users to opt out of or customize preferences associated with your internet
                                          browsing. To learn more about this feature from Google, click here.
                                        </p>
                                        <p> You can learn about opting out of third-party targeting cookies on the Digital Advertising Alliance’s
                                          consumer choice page here or the Network Advertising Initiative’s consumer choice page here or the
                                          European Interactive Digital Advertising Alliance consumer choice page here.
                                        </p>
                                        <h4>Changes to this Policy</h4>
                                        <p> We may change this Policy from time to time. If we make changes, we will notify you by revising the date
                                          at the top of the policy. By accessing the Site or using our services, you agree to the latest version of
                                          this Policy. We encourage you to review this Policy whenever you access the Site or otherwise interact
                                          with us to stay informed about our practices.
                                        </p>
                                   </div>
                                </div>
                        </div>
                </main>
                <CopyRightsFooter/>
                </>
              );
    }
}

export default CookiePolicy;