import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import NavigationHeader from '../components/navigationHeader';
import { CopyRightsFooter } from '../components/copyrights';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faFont, faLink } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {faDatabase,faBezierCurve} from "@fortawesome/free-solid-svg-icons";
import {CallAPI} from "./pbihandling";
import BasicPortlet from '../components/basicPortlet';
import Load from '../components/loadAction';
import QualdoDataTable from '../components/bootstrapTable';
import $ from "jquery";
import Select from 'react-select'
import {getFeatureAccess} from "../utils/common_utils";
import {IS_NONE_CHECK} from "../utils/constant";

class AttributeReport extends React.Component {
    constructor (props) {
        super(props);
        this.state = {isToggleOn: false,authfailmodal:false,mapping_dataset:null,drx_dataset:null,
        mapping_env_options:[],
        mapping_datasource_options:[],
        mapping_dataset_options:[],
        selected_env:null,
        selected_datasource:null,
        selected_dataset:null,
        selected_cols:[],
        model_fotter_button_step:1,
        err_env:0,
        err_datset:0,
        err_datasource:0,
        save_mapping_data:null,
        editdatsetlevelmappingid:'',
        editdatasetid:'',
      };

        this.openModal = this.openModal.bind(this)
        this.handleclose = this.handleclose.bind(this)
        this.CallAPI = CallAPI.bind(this);
        this.selectDatasource = this.selectDatasource.bind(this)
        this.selectEnv = this.selectEnv.bind(this)
        this.selectDataset = this.selectDataset.bind(this)
        this.saveMapping = this.saveMapping.bind(this)
        this.handleTab = this.handleTab.bind(this)
        this.mappingNext = this.mappingNext.bind(this)
    }

    openModal(id,status) {

      if(document.querySelectorAll("#page-nav-tabs > div:nth-child(1) > a:nth-child(1)")[0] !== undefined){
        document.querySelectorAll("#page-nav-tabs > div:nth-child(1) > a:nth-child(1)")[0].click();
      }

      if(status === null) {

      this.setState({
        err_env:0,
          err_datset:0,
          editdatsetlevelmappingid:'',
          err_datasource:0,
          authfailmodal:true,
          selected_env:null,
          selected_datasource:null,
          selected_dataset:null,
          model_fotter_button_step:1
        });
      }
        else {
          this.CallAPI("getpowerbidatasetmapping",id);
        }
    }


    handleTab(pm) {
      if(this.state.selected_dataset === null){
        setTimeout(function() { 
          if(document.querySelectorAll("#page-nav-tabs > div:nth-child(1) > a:nth-child(1)")[0] !== undefined){
            document.querySelectorAll("#page-nav-tabs > div:nth-child(1) > a:nth-child(1)")[0].click();
          }
        }, 50); 

          let envflag = 0,err_datset=0,err_datasource;
          if(this.state.selected_env===null){
            envflag = 1;
          }
          if(this.state.selected_datasource===null){
            err_datasource = 1;
          }
          if(this.state.selected_dataset===null){
            err_datset = 1;
          }
    
          if(err_datset === 0){
           
          } 
    
          this.setState({
            err_env:envflag,
              err_datset:err_datset,
              err_datasource:err_datasource,
            });
      } 
      if(this.state.selected_dataset!==null)
      {
        let selected = pm === "ml" ?  2 : 1;
        this.setState({model_fotter_button_step:selected})
      }
    }

    mappingNext() {

      let envflag = 0,err_datset=0,err_datasource;
      if(this.state.selected_env===null){
        envflag = 1;
      }
      if(this.state.selected_datasource===null){
        err_datasource = 1;
      }
      if(this.state.selected_dataset===null){
        err_datset = 1;
      }

      if(err_datset === 0){
        document.querySelectorAll("#page-nav-tabs > div:nth-child(2) > a:nth-child(1)")[0].click();
      } 

      this.setState({
        err_env:envflag,
          err_datset:err_datset,
          err_datasource:err_datasource,
        });

    }

    saveMapping() {

      let powerbi_columns = [];
      let qualdo_columns = [];
      let selecteddatasetid = this.state.selected_dataset.value;
      var envcheck = [];
      let env = this.state.drx_dataset;
      let envoptions = []; 
      env.filter(function (el) {
        if(envcheck.indexOf(el.dataset_id) === -1  && el.dataset_id===selecteddatasetid) {
              envcheck.push(el.dataset_id);
              envoptions.push(el);
              return el;
            }
            return el;
      });
      if(envoptions.length === 0){
        return;
      }
      
      console.log("=============");
      console.log(envoptions);
      console.log("=============");

      $('.qualdo_columns').each(function(){
        qualdo_columns.push($(this).val());
      });

      $(".powerbi_columns").removeAttr("style");

      let errorflag = 0;
      $('.powerbi_columns').each(function(){
        if($(this).val()){
          powerbi_columns.push($(this).val());
        }else {
          $(this).css('border', '1px solid red');
          $(this).focus(); 
          errorflag = 1;
        }
        return;
      });


      if(errorflag===1)
      {
        return;
      }

      let selected_arribute = [];

      

      for(let loop=0;loop < qualdo_columns.length;loop++){
        let selected_arributeobj = {}
        selected_arributeobj["powerbi_column_name"] =  powerbi_columns[loop]; 
        selected_arributeobj["qualdo_column_name"] = qualdo_columns[loop];
        selected_arribute.push(selected_arributeobj);
      }

  //    console.log(selected_arribute);

      let obj = {}

       let dataset_level_mapping_obj = {}

       dataset_level_mapping_obj.id = this.state.editdatsetlevelmappingid;
       dataset_level_mapping_obj.env_name = envoptions[0]["env_name"];
       dataset_level_mapping_obj.env_id = envoptions[0]["env_id"];
       dataset_level_mapping_obj.datasource_name = envoptions[0]["datasource_name"];
       dataset_level_mapping_obj.datasource_id = envoptions[0]["datasource_id"];
       dataset_level_mapping_obj.dataset_name = envoptions[0]["dataset_name"];
       dataset_level_mapping_obj.dataset_id = envoptions[0]["dataset_id"];
       dataset_level_mapping_obj.file_type = envoptions[0]["file_type"];
       dataset_level_mapping_obj.file_path = envoptions[0]["file_path"];
       dataset_level_mapping_obj.bucket_name = envoptions[0]["bucket_name"];
       dataset_level_mapping_obj.container_name = envoptions[0]["storage_name"];
       obj.id = this.state.editdatasetid;
       obj.dataset_level_mapping = dataset_level_mapping_obj;
       obj.attribute_level_mapping =  selected_arribute;
       console.log(obj);
       this.setState({save_mapping_data:obj})
       this.CallAPI("save_mapping",obj);
      /*

{      "id":""
        "dataset_level_mapping": {
                "id":""
                "env_name": "",
                "env_id": "",
                "datasource_name": "",
                "datasource_id": "",
                "dataset_name": "",
                "dataset_id": "",
                "file_type": "",
                "file_path": "",
                "bucket_name": "",
                "container_name": ""
        },
        "attribute_level_mapping": [{
                        "powerbi_column_name": "",
                        "qualdo_column_name": ""
                },
                {
                        "powerbi_column_name": "",
                        "qualdo_column_name": ""
                }
        ]
}

*/
    }

    handleclose() {
        let flag = false;
        this.setState({authfailmodal:flag});
    }

    getData() {
      this.CallAPI("getMappingDataset");
      this.CallAPI("DRXMappingDataset");
    }

    componentDidMount() {
      this.getData();
  }

componentDidUpdate() {

}


handleNavSelect (pm) {

}


selectEnv(e) {

            var envcheck = [];
            let env = this.state.drx_dataset;
            let envoptions = []; 
            env.filter(function (el) {
              if(envcheck.indexOf(el.datasource_id) === -1  && el.env_id===e.value) {

                    envcheck.push(el.datasource_id);
                    var envselectobj = {};
                    envselectobj.value = el.datasource_id;
                    envselectobj.label = el.datasource_name;
                    envoptions.push(envselectobj);
                    return el;

                  }
                  return el;
            });
  this.setState({mapping_datasource_options:envoptions,selected_env:e,selected_datasource:null});
}

selectDataset(e){
  let env = this.state.drx_dataset;
  let envoptions,coloptions=[]; 
  env.filter(function (el) {
    if(el.dataset_id===e.value) {
      envoptions = el.col_details;

      for (let i=0; i<envoptions.length; i++){
        let mappingobj = {};
        mappingobj.qualdo_column_name = envoptions[i].col_name;
        mappingobj.powerbi_column_name = envoptions[i].col_name;
        coloptions.push(mappingobj);
      }
          return el;
        }
        return el;
  });
  this.setState({selected_dataset:e,selected_cols:coloptions,err_datset:0});

}

selectDatasource(e){

  var envcheck = [];
  let env = this.state.drx_dataset;
  let envoptions = []; 
  env.filter(function (el) {
    if(envcheck.indexOf(el.dataset_id) === -1  && el.datasource_id===e.value) {
          envcheck.push(el.dataset_id);
          var envselectobj = {};
          envselectobj.value = el.dataset_id;
          envselectobj.label = el.dataset_name;
          envoptions.push(envselectobj);
          return el;
        }
        return el;
  });
this.setState({mapping_dataset_options:envoptions,selected_datasource:e,selected_dataset:null});
}


formatInput = (event) => {
  const attribute = event.target.getAttribute('name')
  this.setState({ [attribute]: event.target.value.trim() })
}


AvoidSpace(event) {
  var k = event ? event.which : window.event.keyCode;
  if (k === 32) return false;
}


    render() {
       

      const featureAccess = getFeatureAccess();
      let enablePowerBiTab = false;
      if (!IS_NONE_CHECK.includes(featureAccess)) {
          const powerbiTab = featureAccess["powerbi"];
          enablePowerBiTab = powerbiTab === true;
      }
        // let envoptions = this.state.mapping_env_options;
        let cols = this.state.selected_cols;

        return (
          enablePowerBiTab ? 
            <>
          <NavigationHeader page="powerbi" tab="pbi-mapping"/>
                <BreadCrumb icon="faSitemap" title="Dataset Mapping"/>
                <div className="qd-container">
                    <div className="qd-body no-tabs p-4">

                    <BasicPortlet
                    className="pb-0"
                    id="pbidashboard"
                    title="Dataset Mapping"
                    content={
                        this.state.mapping_dataset === null ? 
                         <Load /> : 
                        <QualdoDataTable
                        data={this.state.mapping_dataset}
                        component_name="pbimapping"
                        setupMapping= {this.openModal}
                        />
                    }
                />
                    </div>
                </div>


<Modal show={this.state.authfailmodal} className="modal-mapping"  size="lg"
centered onHide={this.handleclose} >
    <Modal.Header closeButton onClick={this.handleclose}>
        <h5 className="modal-title" id="exampleModalLongTitle">
        Mapping
        </h5>
    </Modal.Header>
    <Modal.Body>



    <div className="triggers-alert-tab-content" >
                  <div className="triggers-alert-tab-dq-content">
                     <Tab.Container defaultActiveKey="dq"activeKey={this.state.activeKey} onSelect={this.handleTab}>
                     <div className="tabbable-line mb-3">
                         <Nav variant="tabs" id="page-nav-tabs" onSelect={this.handleNavSelect}>
                              <Nav.Item>
                                   <Nav.Link eventKey="dq">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faDatabase}/>
                                        </i>  Dataset Level</span>
                                   </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                   <Nav.Link eventKey="ml" >
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faBezierCurve}/>
                                        </i>  Attribute Level</span>
                                   </Nav.Link>
                              </Nav.Item>
                         </Nav>
                     </div>
                     <div className="tab-content" id={this.state.activeKey + "Tab"}>
                        <Tab.Content>
                             <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="dq">

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-md-right form-label">Environment <span className="text-danger">*</span>:</label>
                    <div className="col-md-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                          <FontAwesomeIcon icon={faLayerGroup} />
                          </div>
                        </div>
                        
                        <Select
                                        name="env"
                                        defaultValue={this.state.selected_env}
                                        filterOption={({label}, query) => label.includes(query)}
                                        className="select-control"
                                        classNamePrefix='select-control'
                                        options={this.state.mapping_env_options}
                                        placeholder="Choose Metrics"
                                        onChange={this.selectEnv}
                                    />
         

      { this.state.err_env === 1  ?
      <span className='error-text d-block w-100 mt-1'> {"Please Select an Environment"} </span> : ""}
                                  
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-md-right form-label">Datasource <span className="text-danger">*</span>:</label>
                    <div className="col-md-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                          <FontAwesomeIcon icon={faDatabase} />
                          </div>
                        </div>
                                    

<Select
                name="data_sources"
                defaultValue={this.state.selected_datasource}
                value={this.state.selected_datasource}
                filterOption={({label}, query) => label.includes(query)}
                className="select-control"
                classNamePrefix='select-control'
                options={this.state.mapping_datasource_options}
                placeholder="Choose Datasource"
                onChange={this.selectDatasource}
            />

{ this.state.err_datasource === 1  ?
      <span className='error-text d-block w-100 mt-1'> {"Please Select a Datasource"} </span> : ""}
            

            
                      </div>
                    </div>
                  </div>


                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-md-right form-label">Dataset <span className="text-danger">*</span>:</label>
                    <div className="col-md-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                          <FontAwesomeIcon icon={faFont} />
                          </div>
                        </div>
                                    

<Select
                name="data_set"
                defaultValue={this.state.selected_dataset}
                value={this.state.selected_dataset}
                filterOption={({label}, query) => label.includes(query)}
                className="select-control"
                classNamePrefix='select-control'
                options={this.state.mapping_dataset_options}
                placeholder="Choose Dataset"
                onChange={this.selectDataset}
            />

{ this.state.err_datset === 1  ?
      <span className='error-text d-block w-100 mt-1'> {"Please Select a Dataset"} </span> : ""}
            
                      </div>
                    </div>
                  </div>
                
                  <div className="form-group row d-none">
                    <label className="col-md-4 col-form-label text-md-right form-label">File type</label>
                    <div className="col-md-8">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <FontAwesomeIcon icon={faFileAlt} />
                          </div>
                        </div>
                        <select className="custom-select" id="inputGroupSelect04">
                          <option selected></option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row d-none">
                    <label className="col-md-4 col-form-label text-md-right form-label">File path</label>
                    <div className="col-md-8">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                          <FontAwesomeIcon icon={faLink} />
                          </div>
                        </div>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


                             </Tab.Pane>
                             <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="ml">

              <div className="mt-4 mb-3 d-none">
                <p className="text-muted mb-0">Source: <strong className="text-dark">customer_data</strong></p>
                <p className="text-muted mb-0">Destination: <strong className="text-dark">qualdo-customer_data</strong></p>
              </div>
              
              <div className="custom-table">
                <div className="custom-table__header">
                  <div className="row">
                    <div className="col-6">
                      <h6 className="custom-table__title">DRX Dataset Attributes</h6>
                    </div>
                    <div className="col-6">
                      <h6 className="custom-table__title">Power BI Dataset Attributes</h6>
                    </div>
                  </div>
                </div>

                <div className="custom-table__body">
                {
                  Object.keys(cols).map((col, i) => (
                  <div className="row row-sm mb-2">
                    <div className="col-6">
                    <input className="form-control qualdo_columns" type="text" defaultValue={cols[col].qualdo_column_name} readOnly={true} />
                    </div>
                    <div className="col-6">
                    <input className="form-control powerbi_columns" type="text"  name={cols[col].powerbi_column_name} defaultValue={cols[col].powerbi_column_name}   />
                    </div>
                    </div>
                    ))}
                    
                    </div>


                <div className="custom-table__body d-none">
                  <div className="row row-sm mb-2">
                    <div className="col-sm-5 col-6">
                    
                    <select className="custom-select" id="inputGroupSelect12">
                        <option selected>customer_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect12">
                        <option selected>customer_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <div className="btn-actions">
                        <button className="btn btn-light-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Success"><i className="fa fa-check-circle"></i></button>
                        <button className="btn btn-light-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove"><i className="fa fa-trash-alt"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mb-2">
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect13">
                        <option selected>store_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect14">
                        <option selected>store_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <div className="btn-actions">
                        <button className="btn btn-light-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Success"><i className="fa fa-check-circle"></i></button>
                        <button className="btn btn-light-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove"><i className="fa fa-trash-alt"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mb-2">
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect15">
                        <option selected>Name</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect16">
                        <option selected>Name</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <div className="btn-actions">
                        <button className="btn btn-light-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Success"><i className="fa fa-check-circle"></i></button>
                        <button className="btn btn-light-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove"><i className="fa fa-trash-alt"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mb-2">
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect17">
                        <option selected>email_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect18">
                        <option selected>email_id</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <div className="btn-actions">
                        <button className="btn btn-light-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Success"><i className="fa fa-check-circle"></i></button>
                        <button className="btn btn-light-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove"><i className="fa fa-trash-alt"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm mb-2">
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect19">
                        <option selected>Select Attribute</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-5 col-6">
                      <select className="custom-select" id="inputGroupSelect20">
                        <option selected>Select Mapping Attribute</option>
                        <option value="1">csv</option>
                        <option value="2">json</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <div className="btn-actions">
                        <button className="btn btn-outline-primary"><i className="icon__add-attribute"></i> <span>Add</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
                             </Tab.Pane>
                         </Tab.Content>
                     </div>
                     </Tab.Container>
                  </div>
            </div>
    </Modal.Body>
       <Modal.Footer>
         {this.state.model_fotter_button_step===1 ? 
         
         <div> 
        <button type="submit" className="btn btn-primary btn-circle show-success-toast" onClick={this.mappingNext} data-dismiss="modal"><i
              className="fas fa-check"></i> Next</button>
              </div>

         :
         <div> 
        <button type="button" className="btn btn-outline btn-grey btn-circle" onClick={this.handleclose}>Cancel</button>
        <button type="submit" className="btn btn-primary btn-circle show-success-toast" onClick={this.saveMapping} data-dismiss="modal"><i
              className="fas fa-check"></i> Submit</button>
              </div>
       }
      </Modal.Footer> 
</Modal>
<CopyRightsFooter/>

            </> : <></>
        );
    }
}

export default AttributeReport;