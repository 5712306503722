import React from 'react';
import Image from '../../components/image';
import Banner from './banner';

class EmailNotification extends React.Component {

    render() {
        let isPassWordThankyou = this.props.isPasswordThankYou;
        if(isPassWordThankyou){
            return (
                <div className="prelogin-content">
                    <div className="prelogin-thankyou">
                        <i className="d-inline-flex p-2 mr-3">
                           <Image src="email"/>
                        </i>
                        <h2>Thank you!</h2>
                        <h3>Please check your email!</h3>
                        <p className="text-muted">Look out for an email from us with instructions on how to reset your password!</p>
                        <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                    </div>
                </div>
            );

        } else{
            if(this.props.signupType === "joinTeam") {
               return (
                <div className="prelogin-content">
                    <div className="prelogin-thankyou">
                        <i className="d-inline-flex p-2 mr-3">
                           <Image src="email"/>
                        </i>
                        <h2>Thank you!</h2>
                        <p className="text-muted">We have sent your request to the Admin({this.props.email}).</p>
                        <p className="text-muted"> You will receive an email if admin approves your request.</p>
                        <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                    </div>
                </div>
            );
            }else{
                let email = this.props.email;
                if (this.props.route !== undefined && this.props.route.isNormalSignup === true) {
                    const {location} = this.props
                    // Case happens during normal signup
                    email = location.state.email;
                }

               return (
               <div className="user-prelogin">
                 <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                        <div className="prelogin-content">
                            <div className="prelogin-thankyou">
                                <i className="d-inline-flex p-2 mr-3">
                                   <Image src="email"/>
                                </i>
                                <h2>Thank you!</h2>
                                <h3>Check your Email!</h3>
                                <p className="text-muted">We have sent a verification email to <strong>{email}</strong>. Follow instructions in the email to get started!</p>
                                <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <Banner/>
                 </div>
               </div>

            );
            }
        }
        
    }

}

export default EmailNotification;
