import React from 'react';
// import Image from '../components/image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
// import { faClock as regularClock } from "@fortawesome/fontawesome-free-regular";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import Form from 'react-bootstrap/Form';
// import Dropdown from 'react-bootstrap/Dropdown';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Select, { components } from 'react-select';
import $ from 'jquery';
import { metricClassMapping } from '../metric_utils/constants';
// import DropdownTreeSelect from "react-dropdown-tree-select";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import { postToggleAction, postUIlogs } from '../../utils/metricEventHandling';
// import ConfirmationModal from './components/ConfirmationModal';
// import { toastObj } from '../utils/common_utils';
// import Toast from 'react-bootstrap/Toast';
import RcTooltip from 'rc-tooltip';
// import { sortData } from './metric_utils/common_utils'
// import { Formik, ErrorMessage } from 'formik';
// import * as yup from 'yup';

export default class IntegrationToggle extends React.Component {
    constructor(props) {
        super(props);

        this.postUIlogs = postUIlogs.bind(this);
        this.postToggleAction = postToggleAction.bind(this);
        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.closeAlertmodel = this.closeAlertmodel.bind(this);
        this.goToMetricPage = this.goToMetricPage.bind(this);
        this.getMetricsMetadata = this.props.getMetricsMetadata.bind(this);
        this.showToast = this.props.showToast.bind(this);

        this.state = {
            data: this.props.data,
            metaDataMapping: this.props.metaDataMapping,
            enabledCount: this.props.enabledCount,
            metricData: this.props.metricData,
            metaDataCount: this.props.metaDataCount,
            showConfirmation: false,
            confirmationData: {},
            showAlertModel: false,
            disableConfirmation: false
        }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                data: this.props.data,
                dataset: this.props.dataset,
                datasetMetric: this.props.datasetMetric,
                attribute: this.props.attribute,
                enabledCount: this.props.enabledCount,
                metaDataMapping: this.props.metaDataMapping,
                metaDataCount: this.props.metaDataCount,
                metricData: this.props.metricData,

            })
        }
    }

    closeConfirmation(e) {
        if (e.currentTarget.id === 'save-btn') {
            let thresholdData = this.state.confirmationData
            let data = {
                "tenant_id": thresholdData['tenant_id'],
                "env_id": thresholdData['env_id'],
                "integration_id": thresholdData['integration_id'],
                "data_set_id": thresholdData["data_set_id"],
                "attribute_id": thresholdData["attribute_id"],
                "metrics_id": thresholdData['metrics_id'],
                // "metrics_name" : thresholdData['metrics_name'],
                "checked": thresholdData["checked"],
                "is_enable": thresholdData["checked"] === true ? 1 : 0,
                "update_type": thresholdData['update_type'],
            }
            // let id = this.state.toggleId
            // $(id).prop("checked", thresholdData["checked"]);
            this.setState({ disableConfirmation: false })

            this.postToggleAction(data)
        }
        else {
            // let id = "#"+this.state.toggleId
            // $(id).prop("checked", true);
            this.setState({ showConfirmation: false, disableConfirmation: false })
        }
    }

    closeAlertmodel() {
        this.setState({ showAlertModel: !this.state.showAlertModel })
    }

    goToMetricPage() {
        this.props.closeFullScreen();
    }

    render() {
        let data = this.state.data
        let enabledCount = this.state.enabledCount
        let metricData = this.state.metricData
        let metaDataCount = this.state.metaDataCount
        let integrationThresholdData = {
            tenant_id: Number(localStorage.getItem('team_id')),
            env_id: data.env_id,
            integration_id: data.integration_id,
            data_set_id: null,
            attribute_id: null,
            is_enable: metricData.is_enable,
            metrics_id: metricData.metrics_id,
            update_type: "integration_status",
            metrics_name: metricData.metrics_name
        }
        let checked = false
        if (integrationThresholdData['is_enable'] === 1 || integrationThresholdData['is_enable'] === true) {
            checked = true
        }
        integrationThresholdData['checked'] = checked
        // let enabledCount = this.state.enabledCount

        return (
            <>
                <th className={metricClassMapping[metricData['metrics_name']]['tableClass']}>
                    <div className="metric-toggle-switch">
                        <div className="metric-toggle-switch__left">
                            <RcTooltip
                                overlay={
                                    <div>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Environment : </strong></label>{this.state.metaDataMapping[data.env_id]}</p>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Datasource : </strong></label>{this.state.metaDataMapping[data.integration_id]}</p>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Enabled Dataset : </strong></label><span className="font-weight-semibold">{(enabledCount['datasets'] !== undefined && enabledCount['datasets'][metricData['metrics_name']] === undefined ? 0 : enabledCount['datasets'][metricData['metrics_name']]['enabled'])}/{metaDataCount['datasets']}</span></p>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Enabled Attributes :</strong></label><span className="font-weight-semibold">{(enabledCount['attributes'] !== undefined && enabledCount['attributes'][metricData['metrics_name']] === undefined ? 0 : enabledCount['attributes'][metricData['metrics_name']]['enabled'])}/{metaDataCount['attributes']}</span></p>
                                    </div>
                                }
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <i className="icon fs-10"><FontAwesomeIcon icon={faDatabase} /></i>
                            </RcTooltip>
                        </div>
                        {/* <Form.Check
                            type="switch"
                            className="custom-switch-sm"
                            id={"integrationToggle" + data.integration_id + "_" + (metricData.metrics_id === undefined ? data.integration_id + metricData.dummyId : metricData.metrics_id + "_")}
                            label=""
                            checked={integrationThresholdData['checked']}
                            disabled={integrationThresholdData['metrics_name'] === "Row Count"}
                            onChange={(e) => {


                                let checked = e.target.checked
                                let id = "#" + e.target.id

                                if (this.props.activeMetrics[integrationThresholdData['metrics_name']] === -1) {
                                    $(id).prop("checked", !checked);
                                    this.setState({ showAlertModel: !this.state.showAlertModel, confirmationData: integrationThresholdData, toggleId: id })
                                    return null
                                }


                                this.setState({ toggleId: id })
                                // $(id).prop("checked", checked);
                                // if (checked === false) {
                                $(id).prop("checked", !checked);
                                integrationThresholdData["update_type"] = "integration_status"
                                integrationThresholdData['checked'] = checked
                                this.setState({ showConfirmation: true, confirmationData: integrationThresholdData, toggleId: id })
                                // }
                                // else {
                                //     let data = {
                                //         "tenant_id": integrationThresholdData['tenant_id'],
                                //         "env_id": integrationThresholdData['env_id'],
                                //         "integration_id": integrationThresholdData['integration_id'],
                                //         "data_set_id": integrationThresholdData["data_set_id"],
                                //         "attribute_id": integrationThresholdData["attribute_id"],
                                //         "metrics_id": integrationThresholdData['metrics_id'],
                                //         // "metrics_name" : integrationThresholdData['metrics_name'],
                                //         "checked": checked,
                                //         "is_enable": checked === true ? 1 : 0,
                                //         "update_type": 'integration_status',
                                //     }
                                //     $(id).prop("checked", checked);
                                //     this.postToggleAction(data)
                                // }
                            }
                            }
                        /> */}
                    <div class="custom-control custom-switch custom-switch-sm">
                    <input type="checkbox"
                    id={ "integrationToggle" + data.integration_id + "_" + (metricData.metrics_id === undefined ? data.integration_id + metricData.dummyId : metricData.metrics_id+"_")}
                    label=""
                    defaultChecked={integrationThresholdData['checked']}
                    disabled={integrationThresholdData['metrics_name'] === "Row Count"}
                    onChange={(e) => {
                        let checked = e.target.checked
                        let id = "#" + e.target.id
                        this.setState({ toggleId: id })
                        $(id).prop("checked", !checked);

                        if (this.props.activeMetrics[integrationThresholdData['metrics_name']] === -1) {
                            this.setState({ showAlertModel: !this.state.showAlertModel, confirmationData: integrationThresholdData, toggleId: id })
                            return null
                        }


                        integrationThresholdData["update_type"] = "integration_status"
                        integrationThresholdData['checked'] = checked
                        this.setState({ showConfirmation: true, confirmationData: integrationThresholdData, toggleId: id })
                        // }
                        // else {
                        //     let data = {
                        //         "tenant_id": integrationThresholdData['tenant_id'],
                        //         "env_id": integrationThresholdData['env_id'],
                        //         "integration_id": integrationThresholdData['integration_id'],
                        //         "data_set_id": integrationThresholdData["data_set_id"],
                        //         "attribute_id": integrationThresholdData["attribute_id"],
                        //         "metrics_id": integrationThresholdData['metrics_id'],
                        //         // "metrics_name" : integrationThresholdData['metrics_name'],
                        //         "checked": checked,
                        //         "is_enable": checked === true ? 1 : 0,
                        //         "update_type": 'integration_status',
                        //     }
                        //     $(id).prop("checked", checked);
                        //     this.postToggleAction(data)
                        // }
                    }}
                    className="custom-control-input "
                    />
                    <label className="custom-control-label" for={ "integrationToggle" + data.integration_id + "_" + (metricData.metrics_id === undefined ? data.integration_id + metricData.dummyId : metricData.metrics_id+"_")}>&nbsp;</label>
                    </div>

                        <div className="metric-toggle-switch__right">
                            <RcTooltip
                                overlay={<span>Here, you can <strong>enable / disable metrics</strong> at datasource level</span>}
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <i className="icon icon-info-circle"></i>
                            </RcTooltip>
                        </div>
                    </div>
                </th>

                <Modal show={this.state.showConfirmation} centered>
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger"><FontAwesomeIcon icon={this.state.confirmationData['checked'] === true ? faToggleOn : faToggleOff} /></i>
                            </div>
                            <div className="modal_alert-text">


                            <h4 className="mb-3">Are you sure you want to {this.state.confirmationData['checked'] === true ? "enable" : "disable"} {this.state.confirmationData['metrics_name']=== "Unique Values" ? "Uniqueness" :this.state.confirmationData['metrics_name']}?</h4>
                            <p><strong className="font-weight-semibold text-blue">{this.state.confirmationData['metrics_name']=== "Unique Values" ? "Uniqueness" :this.state.confirmationData['metrics_name']}</strong> metric will be {this.state.confirmationData['checked'] === true ? "enabled" : "disabled"} for all <strong className='font-weight-semibold'>{metaDataCount['datasets']}</strong>  datasets {this.state.confirmationData['metrics_name'] !== "Recency" ?  <>and <strong className='font-weight-semibold'>{metaDataCount['attributes']}</strong> attributes.</> : ""}</p>

                                {/* <h4 className="mb-3">Are you sure you want to disable {this.state.confirmationData['metrics_name']} for <strong> "{this.state.metaDataMapping[this.state.confirmationData.integration_id]}" </strong> ?</h4> */}

                                {/* <p><strong className="font-weight-semibold text-blue">{this.state.confirmationData['metrics_name']}</strong> metric has been disabled Successfully.</p> */}

                                {/* <p> */}
                                {/* <strong className="font-weight-semibold text-blue"></strong> */}
                                {/* Disabling the <strong className="font-weight-semibold text-blue">{this.state.confirmationData['metrics_name']}</strong> metric for the datasource <strong>" {this.state.metaDataMapping[this.state.confirmationData.integration_id]}"</strong>" will automatically turn it off for all its <strong>{this.state.metaDataCount['datasets']}</strong> datasets with <strong>{this.state.metaDataCount['attributes']}</strong> attributes.</p> */}
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-0 pb-4">
                                <button
                                    type="button"
                                    id="close-btn"
                                    className="btn btn-outline btn-grey btn-circle mr-2"
                                    onClick={this.closeConfirmation}
                                >Cancel</button>

                                <button
                                    type="button"
                                    id="save-btn"
                                    className="btn btn-primary btn-circle mr-2"
                                    disabled = {this.state.disableConfirmation}
                                    onClick={(e)=>{
                                        this.setState({disableConfirmation: true})
                                        this.closeConfirmation(e);
                                    }}
                                >Confirm</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* datasource/dataset/attribute level */}
                <Modal show={this.state.showAlertModel} centered>
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger"><FontAwesomeIcon icon={faToggleOn} /></i>
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Global {this.state.confirmationData['metrics_name']} metric is disabled</h4>
                                <p>You need to enable the Global  {this.state.confirmationData['metrics_name']} metric before activating it at the datasource level.</p>
                                {/* <p>Please enable the Global  {this.state.confirmationData['metrics_name']} metric in the default metrics page to proceed.</p> */}
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-0 pb-4">
                                <button
                                    type="button"
                                    id="close-btn"
                                    className="btn btn-outline btn-grey btn-circle mr-2"
                                    onClick={this.closeAlertmodel}
                                >Cancel</button>

                                <button
                                    type="button"
                                    id="save-btn"
                                    className="btn btn-primary btn-circle mr-2"
                                    onClick={this.goToMetricPage}
                                >Go Back</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}
