import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RcTooltip from 'rc-tooltip';

class TableCellButton extends React.Component {

    render() {
        const toolTipContentVal = this.props.toolTipContent;

        if (toolTipContentVal === undefined || toolTipContentVal === null) {
            return (
                <button onClick={this.props.onClickFunction}
                        type="button"
                        disabled={this.props.isDisabled}
                        className={this.props.classNameValue}>
                    <i>
                        <FontAwesomeIcon icon={this.props.buttonIconName}/>
                    </i>
                    <span>{this.props.componentName}</span>
                </button>
            );
        }

        // We need to show tooltip since tooltip content is not null
        return (
            <>
                <RcTooltip placement="top"
                                overlay={
                                    <span id={'tooltip-top'}>
                                        {toolTipContentVal}
                                    </span>
                                }
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                    <button onClick={this.props.onClickFunction}
                            type="button"
                            disabled={this.props.isDisabled}
                            className={this.props.classNameValue}>
                        <i>
                            <FontAwesomeIcon icon={this.props.buttonIconName}/>
                        </i>
                        <span>{this.props.componentName}</span>
                    </button>
                </RcTooltip>
            </>
        );
    }
}

export default TableCellButton;