export const CHART_TYPES = {
    hierarchicalEdgeBundling: 'hierarchicalEdgeBundling',
    radialBarChart: 'radialBarChart',
    chordDependency: 'chordDependency',
    zoomAbleSunburst: 'zoomAbleSunburst',
    radialDenDroGram: 'radialDenDroGram',
    areaChart: 'areaChart',
    heatMap: 'heatMap',
    bumpChart: 'bumpChart',
    barChart: 'barChart',
    horizontalBarChart: 'horizontalBarChart',
    groupedHorizontalBarChart: 'groupedHorizontalBarChart',
    groupedVerticalBarChart: 'groupedVerticalBarChart',
    horizontalCompareChart: 'horizontalCompareChart',
    multiLineFeatureChart: 'multiLineFeatureChart',
    baseLine: 'baseLine',
    cardArea: 'cardArea',
    thumbnailArea: 'thumbnailArea',
    thumbnailMultiLine: 'thumbnailMultiLine',
    multiLineChart: 'multiLineChart',
    thumbnailBar: 'thumbnailBar',
    asterPlot: 'asterPlot',
    boxPlot: 'boxPlot',
    scatterPlot: 'scatterPlot',
    stackedBarChart: 'stackedBarChart',
    packedChart: 'packedChart',
    hierarchicalEdgeBundlingV1: 'hierarchicalEdgeBundlingV1',
    radialBarChartV1: 'radialBarChartV1',
    hierarchicalEdgeBundlingV2: 'hierarchicalEdgeBundlingV2',
    radialBarChartV2: 'radialBarChartV2',
    hierarchicalEdgeBundlingV3: 'hierarchicalEdgeBundlingV3',
    hierarchicalEdgeBundlingDiscover: 'hierarchicalEdgeBundlingDiscover',
    hierarchicalEdgeBundlingV4: 'hierarchicalEdgeBundlingV4',
    hierarchicalEdgeBundlingV5: 'hierarchicalEdgeBundlingV5',
    hierarchicalEdgeBundlingV6: 'hierarchicalEdgeBundlingV6',
    confusionMatrix: 'confusionMatrix',
    thumbnailConfusionMatrix: 'thumbnailConfusionMatrix',
    modelDriftChart: 'modelDriftChart',
    barWithError: 'barWithError',
    areaWithError: 'areaWithError',
    radialBarChartV3: 'radialBarChartV3',
    boxPlotZoomable: 'boxPlotZoomable',
    radialBarChartV4: 'radialBarChartV4',
};

const loadChart = promise =>
     promise.then((module) => {
        const defaultExport = module.default || module;
        return defaultExport.default || defaultExport;
    });

const chartMap = {
    [CHART_TYPES.hierarchicalEdgeBundling]: () => loadChart(import('./hierarchicalEdgeBundling')),
    [CHART_TYPES.radialBarChart]: () => loadChart(import('./radialBarChart')),
    [CHART_TYPES.chordDependency]: () => loadChart(import('./chordDependency')),
    [CHART_TYPES.zoomAbleSunburst]: () => loadChart(import('./zoomAbleSunburst')),
    [CHART_TYPES.radialDenDroGram]: () => loadChart(import('./radialDenDroGram')),
    [CHART_TYPES.areaChart]: () => loadChart(import('./areaChart')),
    [CHART_TYPES.heatMap]: () => loadChart(import('./heatMap')),
    [CHART_TYPES.bumpChart]: () => loadChart(import('./bumpChart')),
    [CHART_TYPES.barChart]: () => loadChart(import('./barChart')),
    [CHART_TYPES.horizontalBarChart]: () => loadChart(import('./horizontalBarChart')),
    [CHART_TYPES.groupedHorizontalBarChart]: () => loadChart(import('./groupedHorizontalBarChart')),
    [CHART_TYPES.groupedVerticalBarChart]: () => loadChart(import('./groupedVerticalBarChart')),
    [CHART_TYPES.horizontalCompareChart]: () => loadChart(import('./horizontalCompareChart')),
    [CHART_TYPES.multiLineFeatureChart]: () => loadChart(import('./multiLineFeatureChart')),
    [CHART_TYPES.baseLine]: () => loadChart(import('./baseLine')),
    [CHART_TYPES.cardArea]: () => loadChart(import('./cardArea')),
    [CHART_TYPES.thumbnailArea]: () => loadChart(import('./thumbnailCharts/thumbnailArea')),
    [CHART_TYPES.thumbnailMultiLine]: () => loadChart(import('./thumbnailCharts/thumbnailMultiLine')),
    [CHART_TYPES.thumbnailBar]: () => loadChart(import('./thumbnailCharts/thumbnailBar')),
    [CHART_TYPES.multiLineChart]: () => loadChart(import('./multiLineChart')),
    [CHART_TYPES.asterPlot]: () => loadChart(import('./asterPlot')),
    [CHART_TYPES.boxPlot]: () => loadChart(import('./boxPlot')),
    [CHART_TYPES.scatterPlot]: () => loadChart(import('./scatterPlot')),
    [CHART_TYPES.stackedBarChart]: () => loadChart(import('./stackedBarChart')),
    [CHART_TYPES.packedChart]: () => loadChart(import('./packedChart')),
    [CHART_TYPES.hierarchicalEdgeBundlingV1]: () => loadChart(import('./hierarchicalEdgeBundlingV1')),
    [CHART_TYPES.radialBarChartV1]: () => loadChart(import('./radialBarChartV1')),
    [CHART_TYPES.hierarchicalEdgeBundlingV2]: () => loadChart(import('./hierarchicalEdgeBundlingV2')),
    [CHART_TYPES.radialBarChartV2]: () => loadChart(import('./radialBarChartV2')),
    [CHART_TYPES.hierarchicalEdgeBundlingV3]: () => loadChart(import('./hierarchicalEdgeBundlingV3')),
    [CHART_TYPES.hierarchicalEdgeBundlingV4]: () => loadChart(import('./hierarchicalEdgeBundlingV4')),
    [CHART_TYPES.hierarchicalEdgeBundlingV5]: () => loadChart(import('./hierarchicalEdgeBundlingV5')),
    [CHART_TYPES.hierarchicalEdgeBundlingV6]: () => loadChart(import('./hierarchicalEdgeBundlingV6')),
    [CHART_TYPES.hierarchicalEdgeBundlingDiscover]: () => loadChart(import('./hierarchicalEdgeBundlingDiscover')),
    [CHART_TYPES.confusionMatrix]: () => loadChart(import('./confusionMatrix')),
    [CHART_TYPES.thumbnailConfusionMatrix]: () => loadChart(import('./thumbnailCharts/thumbnailConfusionMatrix')),
    [CHART_TYPES.modelDriftChart]: () => loadChart(import('./modelDriftChart')),
    [CHART_TYPES.barWithError]: () => loadChart(import('./barWithError')),
    [CHART_TYPES.areaWithError]: () => loadChart(import('./areaWithError')),
    [CHART_TYPES.radialBarChartV3]: () => loadChart(import('./radialBarChartV3')),
    [CHART_TYPES.boxPlotZoomable]: () => loadChart(import('./boxPlotZoomable')),
    [CHART_TYPES.radialBarChartV4]: () => loadChart(import('./radialBarChartV4'))

}

export default chartMap;