import React from 'react';
import {faArrowRight, faChartArea} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ChartGrid from './chartGrid';
import Image from '../../components/image';
// import AdvancedFilterDq from './advanced_filter_dq';
import _ from 'lodash';
//import {setDataset, setMlModel} from "../../utils/event_handling";
import {
    convertDecimalDigits,
    dqRenderGroup,
    dqRenderModalChart,
    getChartType,
    replaceNullValues
} from "../../utils/common_utils";
import NoErrorInPreview from './noErrorPreview';
import CustomTreeFilter from '../components/customTreeFilter';
import {getMetricsOption} from "./filter_common_utils";
import NoErrorComponent from "./monitorNoResult";

class DqErrorGrid extends React.Component {

    constructor(props) {
        super(props);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.closeFullScreen = this.closeFullScreen.bind(this);
        this.dqRenderGroup = dqRenderGroup.bind(this);
        this.dqRenderAllCharts = this.dqRenderAllCharts.bind(this);
        this.dqRenderModalChart = dqRenderModalChart.bind(this);
        this.updateCharts = this.updateCharts.bind(this);
        this.applyDataset  = this.applyDataset.bind(this);
        this.getMetricsOption  = getMetricsOption.bind(this);
        this.applyDateFilter  = this.applyDateFilter.bind(this);
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.changeShowErrors = this.changeShowErrors.bind(this)
        let gridTitle = this.props.title;

        let showOnlyErrorData = false;

        this.state={
            data: this.props.data,
            title: gridTitle,
            showAttributes: true,
            showErrorsOnly: true,
            chartType: "areaChart",
            selectedMetrics: [],
            selectedDatasets: [],
            selectedAttributes: [],
            startDateInside: this.props.startDate,
            endDateInside: this.props.endDate,
            fullScreen: "fade",
            showOnlyErrorData: showOnlyErrorData,
            showFullScreen: false,
            variant: this.props.variant,
            modalData: this.props.modalData,
            aggregateOptions: this.getAggregateOptions(this.props.modalData["attribute"]),
            compareAttributeOptions: this.getAttributeOptions(this.props.modalData["attribute"]),
            compareDatasetOptions: this.getDataSrcOptions(this.props.modalData["dataSet"]),
            srcOptionList: null,
            selectedDataSrc:null,
            fullScreenData: null,
            applied_dataset:[],
            applied_attribute:[],
            applied_metrics:[],
            applied_start_date:'',
            applied_end_date:'',
        }
    }

    changeShowErrors() {
        this.setState({  showErrorsOnly: !this.state.showErrorsOnly }, function() {
        this.updateCharts(this.state.applied_start_date,
        this.state.applied_end_date, 
        this.state.applied_dataset,
        this.state.applied_attribute, 
        this.state.applied_metrics,
        true,
        true
        );
        });
    }

    componentDidMount(){

        let datasetsOptions = this.props.filterData.parentDatasets;
        let filterIntegrationData = datasetsOptions.filter((data)=> 
                parseInt(data.integration_id) === parseInt(this.props.seletedDatasourceId) && data.value!== "*"
        )
        let datsetid = [];
        let attribute = [];
        for(let loop=0;loop<filterIntegrationData.length;loop++){
         let dataset_id = filterIntegrationData[loop]["value"]; 
         let  datasetindex = datasetsOptions.findIndex(function(item){
          return parseInt(item.value) === parseInt(dataset_id);
         });
         if(datasetindex > -1){
             this.props.filterData["parentDatasets"][datasetindex]["checked"] = true;
         }
               datsetid.push(
                {label:filterIntegrationData[loop]["label"],
                value:dataset_id
                }
            )

            if(this.props.filterData["attributesOptions"][dataset_id] === undefined){
                // Condition to skip iteration for dataset without attributes
                continue
            }

            for(let loopattr=0;loopattr<this.props.filterData["attributesOptions"][dataset_id].length;loopattr++){
                this.props.filterData["attributesOptions"][dataset_id][loopattr]["checked"] = true;
                attribute.push(
                    {label:this.props.filterData["attributesOptions"][dataset_id][loopattr]["label"],
                    value:this.props.filterData["attributesOptions"][dataset_id][loopattr]["value"]
                    }
                )
            }
        }    
        let defaultmetrics = this.getMetricsOption(filterIntegrationData,1,1);

        
        this.setState({
                applied_dataset:datsetid,
                applied_attribute:attribute,
                applied_metrics:defaultmetrics,
                applied_start_date:this.props.startDate,
                applied_end_date:this.props.endDate,
                metrics_data:defaultmetrics})
    }
    applyDataset(pm){
        this.setState({"applied_dataset":pm.dataset,
        "applied_attribute":pm.attribute,
        "applied_metrics":pm.metrics,
        "applied_start_date":pm.startdate,
        "applied_end_date": pm.enddate
        })
        this.updateCharts(pm.startdate,
        pm.enddate, 
        pm.dataset,
        pm.attribute, 
        pm.metrics,
        true,
        true
        );
    }

    applyDateFilter(startdate,enddate){
        this.setState({ applied_start_date:startdate,
            applied_end_date:enddate}, function() {

            // let obj = {dataset:this.state.applied_dataset,
            //     attribute:this.state.applied_attribute,
            //     applied_metrics:this.state.metrics_data,
            //     startdate:this.state.applied_start_date,
            //     enddate:this.state.applied_end_date,
            //     }


        // let metrics = this.state.selectedMetrics;
        // let metricsData = metrics.filter((data)=> 
        //      data.checked===true
        //   )


                this.updateCharts(
                    startdate,
                    enddate, 
                    this.state.applied_dataset,
                    this.state.applied_attribute,
                    this.state.applied_metrics,
                    true,
                    true);
        });
    }

    static getDerivedStateFromProps(props, state){
        if (props.data !== state.data) {
            return {data : props.data};
        } else{
            return null;
        }
    }
    componentDidUpdate(prevProps, prevState) {
       if (prevState.data !== this.state.data) {
            let _modalData= _.cloneDeep(this.props.data);
            this.setState({modalData:_modalData});
       }
}

closeFullScreen(){
     document.body.classList.remove("overflow-hidden");
        this.setState({
            fullScreen: '',
            showFullScreen: false,
            selectedDatasets: [],
            startDateInside: null,
            endDateInside: null,
            showAttributes: true,
            showErrorsOnly: true,
            selectedAttributes: [],
            selectedMetrics: [],
            isAdvancedFilterApplied: false,
            isFilterChanged: false
        });
    }

    dqRenderAllCharts() {

        let undefinedAppliedCheck =false
        undefinedAppliedCheck = this.state.applied_dataset === undefined || this.state.applied_attribute === undefined || this.state.applied_metrics === undefined
        if(undefinedAppliedCheck === false && this.state.applied_attribute.length === 0 && (this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')) && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action ={"clear_selection"}/>);
        }
        if(this.state.applied_metrics !== undefined && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action={"clear_metric"}/>);
        }
        if(this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')){
            return (<NoErrorComponent action={"clear_dataset"}/>);
        }

        let data = Object.values(this.props.showMoreData)
        let finalData = data.map((fullScreenData) => {
                return this.dqRenderGroup(fullScreenData)
            });
            return finalData
        }


    setFullScreen() {
      document.body.classList.add("overflow-hidden");

      let datasetsOptions = this.props.filterData.datasetsOptions;
      let filterIntegrationData = datasetsOptions.filter((data)=> 
              parseInt(data.integration_id) === parseInt(this.props.seletedDatasourceId) && data.value!== "*"
      )
      let datsetid = [];
      let attribute = [];
      for(let loop=0;loop<filterIntegrationData.length;loop++){
       let dataset_id = filterIntegrationData[loop]["value"]; 
       let  datasetindex = datasetsOptions.findIndex(function(item){
        return parseInt(item.value) === parseInt(dataset_id);
       });
       if(datasetindex > -1){
           this.props.filterData["datasetsOptions"][datasetindex]["checked"] = true;
       }
             datsetid.push(
              {label:filterIntegrationData[loop]["label"],
              value:dataset_id
              }
          )
          if(this.props.filterData["attributesOptions"][dataset_id] === undefined){
                // Condition to skip iteration for dataset without attributes
                continue
            }

          for(let loopattr=0;loopattr<this.props.filterData["attributesOptions"][dataset_id].length;loopattr++){
              this.props.filterData["attributesOptions"][dataset_id][loopattr]["checked"] = true;
              attribute.push(
                  {label:this.props.filterData["attributesOptions"][dataset_id][loopattr]["label"],
                  value:this.props.filterData["attributesOptions"][dataset_id][loopattr]["value"]
                  }
              )
          }
      }    
      let defaultmetrics = this.getMetricsOption(filterIntegrationData,1,1);
      let metricsData = defaultmetrics;
      metricsData = metricsData.filter((data)=> 
       data.checked===true
        )

      this.setState({
              applied_dataset:datsetid,
              applied_attribute:attribute,
              applied_metrics:metricsData,
              applied_start_date:this.props.startDate,
              applied_end_date:this.props.endDate,
              metrics_data:defaultmetrics,
              showFullScreen : true,
              fullScreen: "show"
            })

    }

    updateCharts(startDate, endDate, selectedDatasets, selectedAttributes, selectedMetrics, showAttributes, showErrorsOnly){
          
        this.setState({isAdvancedFilterApplied: true, isFilterChanged: !this.state.isFilterChanged, startDateInside: startDate, endDateInside: endDate,
                      selectedDatasets: selectedDatasets, selectedAttributes: selectedAttributes,
                      selectedMetrics: selectedMetrics, showAttributes: showAttributes, showErrorsOnly: this.state.showErrorsOnly});
    }


    renderGridItem() {

        let chartValues = this.props.data;
        if (chartValues === undefined || chartValues === null) {
            return '';
        }

        if (chartValues.length === 0) {
            // Results are computed. But there are no error charts to show in the preview page.
            // So just showing that there are no errors message
            return (
                <NoErrorInPreview
                    message={`No Errors found in the configured Data Source(s)`}
                    footer={''}
                />
            );
        }
        
        if (this.props.disableShowMore) {
            // Results are not computed yet.
            return (
                <NoErrorInPreview
                    message={`Data processing is in-progress`}
                    footer={''}
                />
            );
        }

        // Display the error charts in the preview page
        return chartValues.map((chartData, index) => {
            const attribute_name = chartData.attribute_name;
            const dataSetId = chartData.data_set_id;
            let key = `${chartData.name}_${dataSetId}`;
            let idValue = "dqErrorPreview_" + chartData["datasetName"] + "_" + chartData["name"].replace(/ /g, "_");
            if (attribute_name !== undefined && attribute_name !== null) {
                key = key + "_" + attribute_name;
                idValue = "dqErrorPreview_" + chartData["datasetName"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g, "_");
            }

            let chartTitle = chartData.title;
            chartData["chartTitle"] = chartTitle;
            replaceNullValues(chartData);
            let chartType = getChartType(chartData, index);
            if (chartData["name"] === "Data Drift" && chartData["chartType"] === "boxPlotZoomable") {
                chartType = "boxPlotZoomable";
            }
            if(chartData["name"] === "Unique Values"){
                chartData["name"] = "Unique Rows"
            }
            chartData["drift"] = convertDecimalDigits(chartData["drift"])
            chartData["dq_chart_points"] = convertDecimalDigits(chartData["dq_chart_points"])
            return (
                <ChartGrid
                    yValue={chartData["name"]}
                    title={chartTitle}
                    key={key}
                    idValue={idValue}
                    scale={chartData["scale"]}
                    data={chartData}
                    chartType={chartType}
                    variant={this.state.variant}
                    index={index}
                    //selectedIntegration={selectedSource}
                />
            );
        });
    }

    getDataSrcOptions(dataSetData) {
        let compareDatasetOptions =[];
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(dataSetData)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                if (attribute === "data") {
                    for(let j = 0; j < chartData.length; j++) {
                        let dataSetChart = chartData[j];
                        let title =  dataSetChart.title.split(">");
                        let dataSetName;
                        if (title.length > 2) {
                            dataSetName = (dataSetChart.title.split(">")[2]).split("-")[0]
                        } else {
                           dataSetName = (dataSetChart.title.split(">")[1]).split("-")[0]
                        }
                        if (dataSetName !== undefined && dataSetName !== null) {
                            compareDatasetOptions.push({"label": dataSetName.trim(), "value": dataSetChart.data_set_id, "type": dataSetChart.name, "data": dataSetChart});
                        }
                    }
                }
            }
        }
        return compareDatasetOptions;
    }

    getAttributeOptions(attributeData) {
        let compareAttributeOptions =[];
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(attributeData)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                if (attribute === "data") {
                    for(let j = 0; j < chartData.length; j++) {
                        let attr = chartData[j];
                        let attribute_name = attr.attribute_name;
                        if (attribute_name !== undefined && attribute_name !== null) {
                            compareAttributeOptions.push({"label": attribute_name, "value":attribute_name+"$"+attr.name,"data_set_id":attr.data_set_id,
                            "data": attr});
                        }
                    }
                }
            }
        }
     return compareAttributeOptions;
    }

    getAggregateOptions(attributeData) {
        let aggList = [];
          //eslint-disable-next-line
        for (const [key, profileDataObj] of Object.entries(this.props.modalData['profilingData'])) {
                let profileDataList = profileDataObj.data;
                for(let j = 0; j < profileDataList.length; j++) {
                    let attr = profileDataList[j];
                    let drift_pattern = attr.type;
                    if (drift_pattern !== undefined && drift_pattern !== null) {
                        if (drift_pattern === "NO_DRIFT" || drift_pattern === "not_computed") {
                            aggList.push({"label":<a className="qd-cw_dp-item" href="/#">{attr.name}</a> , "value":attr.name,"attribute_name":attr.attribute_name,
                               "data": attr, "data_set_id": attr.data_set_id, "is_error": false});
                        } else {
                            aggList.push({"label":<a className="qd-cw_dp-item" href="/#">{attr.name} <span className="label label-danger">Error</span></a> , "value":attr.name,"attribute_name":attr.attribute_name,
                               "data": attr, "data_set_id": attr.data_set_id, "is_error": true});
                        }
                    }
                }
        }

//        //eslint-disable-next-line
//        for (const [key, value] of Object.entries(attributeData)) {
//            for (const [attribute, chartData] of Object.entries(value)) {
//                if (attribute === "data") {
//                    for(let j = 0; j < chartData.length; j++) {
//                        let attr = chartData[j];
//                        let attribute_name = attr.attribute_name;
//                        if (profileKeys.includes(attr.name)) {
//                            if (attribute_name !== undefined && attribute_name !== null) {
//                                aggregateOptions.push({"label":attr.name , "value":attr.name,"attribute_name":attribute_name,
//                                "data": attr, "data_set_id": attr.data_set_id});
//                            }
//                        }
//                    }
//                }
//            }
//        }

        return aggList;
    }

    loadDataSourceRender(){

    }
    clearAllFilterData() {
        if(this.props.filterData!==""){
            for (let i = 0; i < this.props.filterData["datasetsOptions"].length; i++) {
                this.props.filterData["datasetsOptions"][i]["checked"]=""
            }
        }
        let metrics_data;
        if(this.state.metrics_data!==""){
            metrics_data = this.state.metrics_data;
            for (let i = 0; i < metrics_data.length; i++) {
                metrics_data[i]["checked"]=""
            }
        }
        this.setState({
            metrics_data:metrics_data,
            clearAllFilter:true,
            applied_dataset:[],
            applied_attribute:[],
            applied_metrics:[],
            })
    }
    render() {


        let page = this.props.showModelFilter ? "model": "datasource";
        return (
            (this.props.modalData.length === 0) ?
                 <>
                    <div className="text-center text-muted py-5 w-100">
                        <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                        <h4>{`Drift values are currently not available for the selected ${page}.`}</h4>
                        <p>Please check after sometime.</p>
                    </div>
                 </>
                :
                <>
                <div className="qd-grid qd-grid-monitor">
                    <div className="qd-grid-item qd-section">
                        <div className="row row-sm">
                            {this.renderGridItem()}
                        </div>
                    </div>
                    <div className="qd-grid-item qd-aside">
                        <div className="d-flex align-items-center w-100 h-100 justify-content-center"
                             style={{height:"200px"}}>
                            {
                                this.props.disableShowMore === true ? '' :
                                <div className="show-more monitor-row_show-more"
                                     onClick={this.setFullScreen}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </span>
                                    <p>Show More</p>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                { this.state.fullScreen === "show" ? <div className={"qd-fullscreen fade " + this.state.fullScreen}>
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                    <h4 className="header_title">
                                        {this.state.title}
                                    </h4>
                                </div>
                                {/* style={{display:'none'}} */}
                                {/* <div className="actions" >
                                    <AdvancedFilterDq dataSourceOption={this.props.dataSourceOption}
                                               mappedDatasetInfo={this.props.mappedDatasetInfo}
                                               metricsList={this.props.metricsList}
                                               defaultDatasource={this.props.selectedDataSource}
                                               type="chartView"
                                               metricType={this.props.metricType}
                                               updateCharts={this.updateCharts}
                                               allData={this.props.allData}
                                               startDate={this.props.startDate}
                                               endDate={this.props.endDate}
                                               isDatasetLevel={this.props.isDatasetLevel}

                                   />
                                </div> */}
                                <button type="button" onClick={this.closeFullScreen} className="close"
                                        aria-label="Close">
                                    <span aria-hidden="true"/>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="qd-fs_body overflow-auto pt-2">
                                <div className="qd-grid qd-grid-monitor">
                                    <div className="qd-grid-item qd-section" key={this.state.isFilterChanged}>
                                <CustomTreeFilter
                                showErrorCheckbox={true}
                                startDate={this.state.applied_start_date}
                                endDate={this.state.applied_end_date}
                                seletedDatasourceId={this.props.seletedDatasourceId}
                                seletedDatasourceName={this.props.seletedDatasourceName}
                                data={this.props.filterData}
                                clearAllFilter={this.props.clearAllFilter}
                                loadDataSourceRender={this.loadDataSourceRender}
                                applyDataset={this.applyDataset}
                                applyDateFilter={this.applyDateFilter}
                                clearAllFilterData={this.clearAllFilterData}
                                showAttributes={true}
                                showDatasets={true}
                                showMetrics={true}
                                metrics_data={this.state.metrics_data}
                                viewOptions={false}
                                showParitionCheckbox={false}
                                changeGroup={this.changeGroup}
                                selectedGroup={this.state.selectedGroup}
                                fullscreenviewselect={true}
                                datasourcechange={false}
                                // changePartitions={this.changePartitions}
                                changeShowErrors={this.changeShowErrors}
                                // showpartitions={this.state.showpartitions}
                                showerrors={this.state.showErrorsOnly}
                                /> 
                                        { this.state.isAdvancedFilterApplied ? this.dqRenderAllCharts() : this.dqRenderGroup(this.props.modalData) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ""}
            </>
        );
    }
}
export default DqErrorGrid;

