import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock, faEye, faEyeSlash, faChalkboardTeacher, faEdit, faCheck, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { getApi, updateWhitelistedIp } from "../../utils/User_handling";
import Load from "../../components/loadAction";
import { toastObj, decryptForLocalUsage, checkPermissions } from "../../utils/common_utils";
import Form from 'react-bootstrap/Form';
import RcTooltip from 'rc-tooltip';
import TagsInput from "./TagsInput"
import Button from "react-bootstrap/Button";
import Image from '../../components/image';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { setShow } from "../../utils/event_handling";
import { storeIpData } from "../../redux/actions";
import { connect } from "react-redux";
import { GCLOUD, AWS_CLOUD, IS_NONE_SAAS_EDITION, 
        IS_AZURE_DRX, PERMISSIONS_LOCAL_STORE_KEY} from '../../utils/constant';

class Preferences extends React.Component {
  constructor(props) {
    super(props);
    this.getApi = getApi.bind(this);
    this.UpdateData = this.UpdateData.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clientIdIcon = this.clientIdIcon.bind(this);
    this.clientSecretIcon = this.clientSecretIcon.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.selectedTags = this.selectedTags.bind(this)
    this.setStateValue = this.setStateValue.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleupdate = this.handleupdate.bind(this)
    this.handleApprove = this.handleApprove.bind(this)
    this.updateWhitelistedIp = updateWhitelistedIp.bind(this);
    this.checkAndUpdateTopButton = this.checkAndUpdateTopButton.bind(this);
    this.setShow = setShow.bind(this);
    this.handleClose = () => this.setShow("show", false);

    this.state = {
      toastStatus: false,
      emailsubscription: [],
      chechkall: false,
      btnchkalltext: "Unsubscribe All",
      emailsubscriptioninitalloader: true,
      whitelisted_ip: [],
      old_whitelisted_ip: [],
      client_id: "",
      client_secret: "",
      clientidNameClass: "",
      clientsecretNameClass: "",
      clientIdsIcon: faEye,
      clientIdType: "password",
      clientSecretsIcon: faEye,
      clientSecretType: "password",
      editButton: true,
      disableSave: true,
      disableCancel: false,
      loader: true,
      placeHolder: "Enter IP Address",
      renderKey: null,
      show: false,
      required: '',
      updated: null,
      invalidIp: false,
      updateloader: null,
      user_message: "Please enter valid IP address",
      close_tag: 'tag-close-icon d-none',
      cloud: null,
      sleepInterval : 1,
      prevSleepInterval : 1,
      sleepIntervalClass : "d-none",
      isSleepIntervalErrMsg: "",
      modelHeader1: "Are you sure ? It will take few minutes",
      modelContent1: "New ip addresses will take some time to associate with your instance",
      modelHeader2: "Associate new ip address with instance?",
      modelContent2: "Confirm that you want to associate new ip addresses to your instance",
      ipData: props.dataModule.ipData
    };

  }

  componentDidMount() {
    this.getData();
    this.UpdateData();
  }

  UpdateData() {
    if (this.state.ipData !== null && this.state.ipData['updated_status']['status'] === true) {
      /**
       * This API call will update updated_status column with null value
       * To avoid the success status after updation of ip this is done
       */
      let data = { 'internal_update': true }
      this.updateWhitelistedIp(data)
    }
  }

  getData() {
    this.getApi("get_email_subscription");
    if (IS_NONE_SAAS_EDITION) {
      this.getApi("get_whitelisted_ip");
    }

    /**Api call happens until the loader is true
     * once the update is started loader set to true 
     * Will set loader to false once the updation is done
     */
    setInterval(() => {
      let ipDataIsNull = this.state.ipData === undefined || this.state.ipData === null;
      if (ipDataIsNull) {
        return;
      };

      let updated_status = this.state.ipData['updated_status']['status'];

      if (updated_status !== 'initiated' || updated_status === null) {
        this.getApi("get_whitelisted_ip");
      }
    }, 60000);
  }

  selectAll() {
    let checkallarr;
    if (this.state.chechkall) {

      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {
        checkallarr[i].email_user_status = 1;
        // emailsubscriptiondata.email_user_status
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Unsubscribe All",
        chechkall: false,
        emailsubscription: checkallarr,
      });

    } else {
      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {

        checkallarr[i].email_user_status = 0;
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Subscribe All",
        chechkall: true,
        emailsubscription: checkallarr,
      });
    }
    this.getApi("save_email_subscription");
  }

  closeToast() {
    this.setState({ toastStatus: false });
  }
  showToast(status, msg, link) {
    this.setState({
      toastStatus: true,
      toastMsg: msg,
      toastClass: toastObj[status]["className"],
      toastIcon: toastObj[status]["icon"],
      toastheading: toastObj[status]["heading"]
    });
    if (link.trim().length > 0) {
      setTimeout(function () {
        window.location.href = link;
      }, 3000);
    }
  }


  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  clientIdIcon() {
    let state = this.state.clientIdsIcon;
    if (state.iconName === "eye") {
      this.setState({ clientIdsIcon: faEyeSlash });
      this.setState({ clientIdType: "text" })
    } else {
      this.setState({ clientIdsIcon: faEye });
      this.setState({ clientIdType: "password" })
    }

  }

  clientSecretIcon() {
    let state = this.state.clientSecretsIcon;
    if (state.iconName === "eye") {
      this.setState({ clientSecretsIcon: faEyeSlash });
      this.setState({ clientSecretType: "text" })
    } else {
      this.setState({ clientSecretsIcon: faEye });
      this.setState({ clientSecretType: "password" })
    }

  }

  checkAndUpdateTopButton(checkedlist) {
    /**
     * This function will check all subscription values and it will change the top button text
     * */
    let allStatus = checkedlist.map(i => i.email_user_status);
    let uniqueValues = [...new Set(allStatus)];

    let allSubscriptionsInOneState = uniqueValues.length === 1;

    if (!allSubscriptionsInOneState) {
      return;
    }

    let firstVal = uniqueValues[0];
    let btnchkalltext;
    let chechkall;

    if (firstVal === 1) {
      btnchkalltext = "Unsubscribe All";
      chechkall = false;
    } else {
      btnchkalltext = "Subscribe All";
      chechkall = true;
    }

    this.setState({
      btnchkalltext: btnchkalltext,
      chechkall: chechkall
    });

  }

  toggleEmailGroup = (i) => (event) => {
    let checkedlist = this.state.emailsubscription;
    checkedlist[i].email_user_status = (event.target.checked === true ? 1 : 0);

    this.checkAndUpdateTopButton(checkedlist);

    this.setState({ emailsubscription: checkedlist })
    this.getApi("save_email_subscription");
  }

  selectedTags = whitelisted_ip => {
    this.setState({ whitelisted_ip })
    this.setState({ invalid: false })
    this.setState({ close_tag: 'tag-close-icon' })

    if (whitelisted_ip.length < 1) {
      this.setState({ disableSave: true, required: true })
    }
    else {
      this.setState({ disableSave: false, required: false })
    }
  };


  setStateValue(invalid) {
    this.setState({ invalidIp: invalid })
  }

  handleEdit(edit) {
    this.setState({ renderKey: 1 + this.state.renderKey })

    if (edit === true) {
      /**
       * Once the edit is clicked will add the cross icon to remove/edit ip's
       */
      this.setState({ editButton: false, close_tag: 'tag-close-icon', updated: null })
    }


    else if (edit === false && this.state.updated !== false) {
      /**
      * update the ip_list with old_list while user click on cancel button
      * During normal cancel action
      */
      this.setState({ editButton: true, whitelisted_ip: this.state.old_whitelisted_ip, updated: null })
      this.setState({ close_tag: 'tag-close-icon d-none' })
    }
    else if (edit === false && this.state.updated === false) {
      /** update the ip_list with old_list while user click on cancel button
      *  During update failure scenario : DB update happens here
      */
      this.setState({ editButton: true, close_tag: 'tag-close-icon d-none' })
      let data = {}
      data['whitelisted_ip'] = this.state.old_whitelisted_ip
      data['internal_update'] = true
      this.updateWhitelistedIp(data)
    }
    // Avoid error text when user click cancel
    if (this.state.whitelisted_ip.length > 0 || this.state.old_whitelisted_ip.length > 0) {
      this.setState({ invalidIp: false, required: false })
    }
  }

  handleupdate() {

    /** Handle submitting same values while updating */
    let new_ip = this.state.whitelisted_ip
    let old_ip = this.state.old_whitelisted_ip
    let result = new_ip.filter(function (o1) {
      return old_ip.some(function (o2) {
        return o1 !== o2; // return the ones with equal id
      });
    });
    if (result.length < 1) {
      return
    }
    // Handle Update whitelisted_ip
    let data = {}
    data["whitelisted_ip"] = this.state.whitelisted_ip
    data["client_id"] = this.state.client_id
    data["client_secret"] = this.state.client_secret
    data['updating_status'] = 'initiated'

    this.setState({ close_tag: 'tag-close-icon d-none' })

    /**
     * Modal will be applicable for all clouds with different content
     */
    this.setState({ show: true })

  }

  handleApprove() {
    this.setState({ approved: true, show: false })
    let data = {}
    data["whitelisted_ip"] = this.state.whitelisted_ip
    data["client_id"] = this.state.client_id
    data["client_secret"] = this.state.client_secret
    data['updating_status'] = 'initiated'
    this.updateWhitelistedIp(data)
  }

  render() {
    let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
    let permissionsList = [];
    if (permissions !== undefined && permissions !== null) {
      permissionsList = permissions;
    }
    let disableEmailSubscriptionsAction = checkPermissions('Alerts')
    return (
      <>
        <div className="qd-section_content  shadow-sm settings-preferences">
          <div className="qd-section-header">
            <h4 className="qd-section-header-title"><FontAwesomeIcon icon={faChalkboardTeacher} /> Preferences</h4>
          </div>
          <div className="qd-section-body">
            <div style={{
              display: this.state.emailsubscriptioninitalloader ? "block" : "none",
            }}>
              {<Load />}
            </div>
            <div>
              <div style={{
                visibility: this.state.emailsubscriptioninitalloader ? "hidden" : "visible",
              }}>
                <div className="row row-sm">
                  <div className="col-md-12">

                    {(IS_NONE_SAAS_EDITION && permissionsList.includes('BillingAccess'))
                      ?
                      <>
                        {this.state.loader
                          ?
                          <Load />
                          :
                          ((IS_NONE_SAAS_EDITION && permissionsList.includes('BillingAccess'))
                            ?
                            <>
                              <div className="portlet">
                                <div className="portlet-title">
                                  <div className="caption">
                                    <span className="text-uppercase font-dark">AUTHORISED NETWORK</span>
                                  </div>
                                </div>
                                <div className="portlet-body py-4">
                                  <div className="mb-4">
                                    <p>
                                      You can specify CIDR ranges to allow IP addresses in those range to access your instance.
                                    </p>
                                  </div>
                                  <Form.Group controlId="whitelisted_ip" className="tags-input-group">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <Form.Label className="position-relative">
                                        IP Address
                                        <RcTooltip
                                          placement="top"
                                          overlay={
                                            <span>
                                              {this.state.cloud === AWS_CLOUD
                                                ?
                                                '191.168.1.1/24 or 191.168.1.1/32'
                                                :
                                                '192.168.1.1 or 191.168.1.1/24'
                                              }
                                            </span>}
                                          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                        >
                                          <i className="icon-info-circle"></i>
                                        </RcTooltip>
                                      </Form.Label>

                                      {this.state.editButton ?
                                        <Button
                                          type="submit"
                                          variant="light-info mb-2"
                                          onClick={() =>
                                            this.handleEdit(true)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          Edit IP Address
                                        </Button>
                                        :
                                        ''
                                      }
                                    </div>
                                    {this.state.invalidIp ?
                                      <div
                                        id="ip-address"
                                        className="input- group invalid-feedback"
                                        class={this.state.invalidIp}>
                                        "Please enter valid IP address"
                                                </div>
                                      :
                                      ''
                                    }
                                    <TagsInput
                                      key={this.state.renderKey}
                                      selectedTags={this.selectedTags}
                                      setStateValue={this.setStateValue}
                                      tags={this.state.whitelisted_ip}
                                      closeTag={this.state.close_tag}
                                      placeholder={!this.state.editButton ? this.state.placeHolder : null}
                                    />



                                    {/* Tag Input Success Message */}
                                    {this.state.updated === true ?
                                      <span className="form-text text-success d-flex align-items-center">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                                        <small>New IP Addresses are updated.</small>
                                      </span>
                                      : (this.state.updated === false) ?
                                        <>
                                          {/* Tag Input Error Message */}
                                          <span className="form-text text-danger d-flex align-items-center">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                                            <small>Error Message</small>
                                          </span>
                                        </>
                                        :
                                        <>
                                          {/* Tag Input Loader */}
                                          <small className="form-text text-muted">
                                            {/* Loader will starts loading once the update started*/}
                                            {this.state.updateloader
                                              ?
                                              <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                                className="mr-2"
                                              >
                                              </Spinner>
                                              :
                                              ''
                                            }
                                              New IP addresses will take some time to propagate, please bear the delay.
                                            </small>
                                        </>

                                    }

                                    {this.state.required ?
                                      <div
                                        name="whitelisted_ip"
                                        className="whitelisted-ip error-text">
                                        IP Address is a required field
                                          </div>
                                      :
                                      ''
                                    }
                                  </Form.Group>
                                  {!this.state.editButton && IS_AZURE_DRX ?
                                    <div className=" form-label-right-md">
                                      <div className="form-group row">
                                        <label className="col-md-2 col-form-label">Client Id :</label>
                                        <div className="col-md-6">
                                          <div className="input-group has-append-icon">
                                            <div className="input-group-prepend">
                                              <div className="input-group-text">
                                                <i>
                                                  <FontAwesomeIcon icon={faUserLock} />
                                                </i>
                                              </div>
                                            </div>
                                            <input
                                              id='client-id'
                                              type={this.state.clientIdType}
                                              name="client_id"
                                              value={this.state.client_id}
                                              className={"form-control " + this.state.clientidNameClass}
                                              onChange={this.handleChange}
                                              required
                                              placeholder="User Client Id" />
                                            <div className="input-group-append-icon">
                                              <i className="security-eye">
                                                <FontAwesomeIcon
                                                  onClick={this.clientIdIcon}
                                                  icon={this.state.clientIdsIcon}
                                                  className="security-eye" />
                                              </i>
                                            </div>
                                          </div>
                                          <div className="invalid-feedback">Invalid client ID</div>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <label className="col-md-2 col-form-label">Client Secret :</label>
                                        <div className="col-md-6">
                                          <div className="input-group has-append-icon">
                                            <div className="input-group-prepend">
                                              <div className="input-group-text">
                                                <i>
                                                  <FontAwesomeIcon icon={faUserLock} />
                                                </i>
                                              </div>
                                            </div>
                                            <input
                                              id='client-secret'
                                              type={this.state.clientSecretType}
                                              name="client_secret"
                                              value={this.state.client_secret}
                                              className={"form-control " + this.state.clientsecretNameClass}
                                              onChange={this.handleChange}
                                              required
                                              placeholder="User Client Secret" />
                                            <div className="input-group-append-icon">
                                              <i className="security-eye">
                                                <FontAwesomeIcon
                                                  onClick={this.clientSecretIcon}
                                                  icon={this.state.clientSecretsIcon}
                                                  className="security-eye" />
                                              </i>
                                            </div>
                                          </div>
                                          <div className="invalid-feedback">Invalid client secret</div>
                                        </div>
                                      </div>
                                    </div> : ''}
                                  {!this.state.editButton ?
                                    <div className="text-md-right">
                                      <Button
                                        type="submit"
                                        className="btn-primary btn-circle mr-2"
                                        onClick={this.handleupdate}
                                        disabled={this.state.disableSave}
                                      >
                                        <i>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </i>
                                        {this.state.updated === false ?
                                          "Try again"
                                          :
                                          "Save Changes"
                                        }
                                      </Button>
                                      <Button
                                        type="submit"
                                        className="btn-outline btn-grey btn-circle"
                                        onClick={() =>
                                          this.handleEdit(false)
                                        }
                                        disabled={this.state.disableCancel}
                                      >Cancel
                                  </Button>
                                    </div>
                                    :
                                    ''}
                                </div>
                              </div>
                            </>
                            :
                            ''
                          )
                        }
                      </>
                      :
                      ''
                    }

                    <div className="portlet">
                      <div className="portlet-title">
                        <div className="caption">
                          <span className="text-uppercase font-dark">Email Subscriptions</span>
                        </div>
                      </div>
                      <div className="portlet-body py-4">
                        <div className="mb-4">
                          <p>Qualdo sends different notifications and digests by default. You can enable or disable your email subscriptions here.</p>
                          <button type="button" onClick={this.selectAll.bind(this)}
                            disabled={disableEmailSubscriptionsAction}
                            className="btn btn-outline-primary btn-circle">
                            {this.state.btnchkalltext}
                          </button>
                        </div>
                        <div className="mb-2">
                          <h4 className="mb-3">Unsubscribe the following Notifications</h4>
                          {/* <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck1" />
                            <label className="custom-control-label" for="preferenceCheck1" >Daily Quality Alerts</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck2" />
                            <label className="custom-control-label" for="preferenceCheck2" >Model Monitoring Alerts</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck3" />
                            <label className="custom-control-label" for="preferenceCheck3" >Custom Alerts</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck4" />
                            <label className="custom-control-label" for="preferenceCheck4" >Payment and Billing Reminder</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck5" />
                            <label className="custom-control-label" for="preferenceCheck5" >Usage Alerts</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck6" />
                            <label className="custom-control-label" for="preferenceCheck6" >Timeliness Alerts</label>
                          </div>
                          <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" id="preferenceCheck7" />
                            <label className="custom-control-label" for="preferenceCheck7" >Subscription Expiry/Renewal Alerts</label>
                          </div> */}
                          {/* onChange={this.handleChange} */}

                          {this.state.emailsubscription.map(function (emailsubscriptiondata, i) {
                            let isChecked = emailsubscriptiondata.email_user_status === 1 ? "checked" : "";
                            let grp_id = emailsubscriptiondata.email_group_id;

                            return (
                              <div key={`${grp_id}-${i}`}>
                                <div className="custom-control custom-switch custom-switch-sm blue-switch  mb-2">
                                  <input type="checkbox" checked={isChecked}
                                    disabled={disableEmailSubscriptionsAction}
                                    className="custom-control-input"
                                    id={'emailsubcheck' + i}
                                    onChange={this.toggleEmailGroup(i)} />
                                  <label className="custom-control-label"
                                    htmlFor={'emailsubcheck' + i}>
                                    &nbsp;{emailsubscriptiondata.email_group_name}
                                  </label>
                                </div>
                                <br />
                              </div>
                            )
                          }, this)}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="toastMsg">
          <Toast className={this.state.toastClass}
            onClose={() => this.closeToast}
            show={this.state.toastStatus}
            delay={5000} autohide>
            <div className="d-flex align-items-center">

              {this.state.toastStatus ?
                <i className="toast-icon">
                  <FontAwesomeIcon icon={this.state.toastIcon} />
                </i>
                :
                ''
              }

              <Toast.Body>
                <strong className="toast-title">{this.state.toastheading}</strong>
                <p>{this.state.toastMsg}</p>
              </Toast.Body>
              <button type="button"
                onClick={this.closeToast}
                className="close"
                data-dismiss="toast"
                aria-label="Close" />
            </div>
          </Toast>
        </div>
        {/* Modal Timer Notification */}
        <Modal
          show={this.state.show}
          size='md'
          centered
        >
          <Modal.Body>
            <div className="modal__alert p-sm-3">
              <button
                className="close"
                onClick={this.handleClose}>
                <span
                  className="sr-only">
                  Close
                </span>
              </button>
              {this.state.cloud === GCLOUD
                ?
                <div className="modal_alert-icon">
                  <Image src="icon_timer_blue" alt="" />
                </div>
                :
                ''
              }

              <div className="modal_alert-text">
                <h4 className="mb-4">{this.state.cloud === GCLOUD ? this.state.modelHeader1 : this.state.modelHeader2}</h4>
                <p>{this.state.cloud === GCLOUD ? this.state.modelContent1 : this.state.modelContent2}</p>
              </div>
              <div className="mb-4">
                <Button
                  variant='primary'
                  className="btn-circle"
                  onClick={this.handleApprove}>
                  <FontAwesomeIcon
                    icon={faCheck} />
                  Approve
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps, storeIpData)(Preferences);