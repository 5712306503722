import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const CustomSelect = props => {
  if (props.allowSelectAll) {
    let allOption = {"label": "All", "value": "*"}
    let options = []
    if(props.name === "attribute") {
      options = props.originalAttributeOptions
    }else {
      options = props.options
    }

    if (options === undefined || options === null) {
        options = [];
    }
    return (
      <Select
        {...props}
        options={options.length > 0 ? [props.allOption, ...props.options] : []}
        className="qd_nested-multiselect"
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([props.allOption, ...options]);
            }
            let result = [];
            if (selected.length === options.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(
                  option => option.value !== props.allOption.value
                );

              } else if (event.action === "select-option") {
                result = [props.allOption, ...options];
              }


              return props.onChange(result);
            }
          }



          return props.onChange(selected);
        }}
      />
    );
  }

  return <Select {...props} />;
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

CustomSelect.defaultProps = {
  allOption: {
    label: "All",
    value: "*"
  }
};

export default CustomSelect;
