import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import NavigationHeader from '../components/navigationHeader';
import { CopyRightsFooter } from '../components/copyrights';
import Image from './image';


let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO

class PrivacyPolicy extends React.Component {

    render() {
        let sessionToken = localStorage.getItem('sessionToken');
        return (
            <>
                {sessionToken === null ?
                    <header className="qd-header">
                        <div className="qd-header-container navbar navbar-expand-md">
                            <div className="qd-header__brand">
                                <a href="#!">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src={BRAND_LOGO}
                                    />
                                </a>
                            </div>
                        </div>
                    </header>
                    : <NavigationHeader />
                }
                <main>
                    <BreadCrumb icon='privacy' title='Privacy Policy' />
                    <div className="qd-container">
                        {/* <div className="qd-body no-tabs p-4">
                            <div className="policy-doc">
                                <h4><strong>Privacy Policy</strong></h4>
                                <p>
                                    This Privacy Policy explains how information about you is collected, used and disclosed by Qualdo, Inc.
                                    (collectively, "Qualdo," "we" or "us") when you use our website [https://www.qualdo.ai/] ("Website"),
                                    online products and monitoring services ("Platform") (collectively, "Services"), or when you otherwise
                                    interact with us. This Privacy Policy also describes your choices regarding use, access and correction of
                                    personal information collected about you through our Services. Please read this Privacy Policy carefully
                                    and ensure that you understand it before you start to use our Services.
                                </p>
                                <p>
                                    This Website is owned and operated by, or on behalf of, Qualdo. ("we", "our" or "us"). We are the data
                                    controller in respect of personal information of our users based in the European Union.
                                </p>
                                <p>
                                    By accessing and using the Services, you acknowledge that you have read and understood the content of this
                                    Privacy Policy. We reserve the right to update this Privacy Policy from time to time. If we make changes,
                                    we will notify you by revising the date at the top of the Privacy Policy and, in some cases, we may
                                    provide you with additional notice (such as adding a statement to our homepage or sending you a
                                    notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise
                                    interact with us to stay informed about our information practices and the ways you can help protect your
                                    privacy.
                                </p>
                            </div>
                        </div> */}
                        <div className="qd-body no-tabs p-4">
                            <div className="policy-doc">
                                <div className="policy-doc_content">
                                    <h1>Privacy Policy</h1>
                                    <h2>The Qualdo™ Privacy Policy was updated on July 23, 2020.</h2>
                                    <p className="text-primary">
                                        Qualdo™ is a trademark of <a href="https://www.saturam.com/" rel="noopener noreferrer" target="_blank">Saturam
                                            Inc</a>.
                                    </p>
                                    <Image src="icon_privacyPolicy" />
                                    <h3>Our View of the Importance of Privacy</h3>
                                    <p>
                                        Saturam, Inc. ("Saturam", "we", "our", or "us") is corporately committed to ensuring the
                                        same high level of respect and protection for the Personal Information we collect that the
                                        individuals within Saturam desire for their own data. We value individual privacy and
                                        personal control of Personal Information. We handle your Personal Information in the way we
                                        would want our Personal Information to be handled by others.
                                    </p>
                                </div>
                                <div className="policy-doc_info">
                                    <h3>How This Privacy Policy Applies to You</h3>
                                    <p>
                                        A person may interact with us by accessing our websites saturam.com, qualdo.ai, or any
                                        subdomain ("Website", "Websites") as a visitor ("Visitor", "Visitors"), or by using any
                                        version of our software ("Service", "Services") in the context of registration,
                                        subscription, or agreement as a user ("User", "Users").
                                    </p>
                                    <p>
                                        This privacy policy sets out how we collect, store, process, transfer, share and use data
                                        that identifies or is associated with you ("Personal Information") and information regarding
                                        our use of cookies and similar technologies, including information that does not personally
                                        identify you ("Impersonal Information"). We will not sell any of your Personal Information.
                                    </p>
                                    <p>
                                        By accessing and using the Product or Services, you acknowledge that you have read and
                                        understood the content of this Privacy Policy.
                                    </p>
                                    <h3>Changes to this Privacy Policy</h3>
                                    <p>
                                        We reserve the right to modify or amend the terms of our Privacy Policy from time to time.
                                        If we make changes, we will notify you by revising the date at the top of the Privacy
                                        Policy. We encourage you to review the Privacy Policy whenever you access the Services or
                                        otherwise interact with us to stay informed about our information practices and the ways you
                                        can help protect your privacy.
                                    </p>
                                    <h3>What We Mean by Personal Information</h3>
                                    <p>
                                        Personal Information means information identifiable to any person, including, but not
                                        limited to, information that relates to a person's name, health, finances, education,
                                        addresses, telephone numbers, social security numbers, driver license numbers, other
                                        identifying numbers, and any financial identifiers.
                                    </p>
                                    <h3>What We Mean by Impersonal Information</h3>
                                    <p>
                                        Impersonal Information includes information that does not personally identify you, but may
                                        include tracking and usage information about your location, demographics, use of the Website
                                        and the Internet.
                                    </p>
                                    <h3>Children and Minors</h3>
                                    <p>
                                        We only collect Personal Information of individuals 18 years of age and older, or have
                                        otherwise reached the age of "majority" where you reside.
                                    </p>
                                    <h3>Where Personal Information is Stored</h3>
                                    <p>
                                        Saturam is headquartered in the United States and has service providers in other countries.
                                        Your Personal Information, Impersonal Information, and other information collected through
                                        the Website and Services may be stored and processed in the United States or any other
                                        country in which Saturam, its Clients, Affiliates or Service Providers maintain facilities.
                                        Saturam, its Clients, Affiliates, or Service Providers may transfer information that we
                                        collect about you, including Personal Information across borders and from your country or
                                        jurisdiction to other countries or jurisdictions around the world.
                                    </p>
                                    <p>
                                        If you are located in the United States, or other regions with laws governing data
                                        collection and use that may differ from US law, please note that we may transfer
                                        information, including Personal Information, to a country and jurisdiction that does not
                                        have the same data protection laws as your jurisdiction.
                                    </p>
                                    <p>
                                        By registering for and using the Website you consent to the use and disclosure of
                                        information about you as described in this Privacy Policy, and to the transfer of
                                        information to the US or to any other country in which Saturam, its Clients, Affiliates or
                                        Service Providers maintain facilities.
                                    </p>
                                    <p>
                                        In connection with Saturam's processing of Personal Information it receives in the U.S. from
                                        the European Union ("EU Data"), Saturam participates in, and complies with, the EU-U.S.
                                        Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework (collectively, the
                                        "Frameworks") as set forth by the U.S. Department of Commerce regarding the collection, use,
                                        and retention of Personal Information transferred from the European Union and Switzerland to
                                        the United States.
                                    </p>
                                    <p>
                                        If you have specific geo-location or other requirements for storage or data movement, please
                                        contact Saturam at <a href="http://privacypolicy@saturam.com/"
                                            target="_blank" rel="noopener noreferrer">privacypolicy@saturam.com</a> to discuss your requirements and potential
                                        solutions we may have to meet those requirements.
                                    </p>

                                    <h3>How Personal Information is Used</h3>
                                    <p>
                                        We may use your Personal and Impersonal Information in the following ways:
                                    </p>
                                    <ul className="decimal-list">
                                        <li>
                                            We may process your electronic contact details that you have provided to us including,
                                            but not limited to, your email address and phone number, in order to contact you to
                                            inform you about our products and services.
                                        </li>
                                        <li>
                                            We use your profile to manage your access to the Websites and/or Services.
                                        </li>
                                    </ul>
                                    <h3>How Personal Information is Retained</h3>
                                    <p>
                                        We may retain your Personal Information indefinitely in order to maintain the history of our
                                        relationship, the product or service history, and to keep your profile intact. However, you
                                        can require that we retain certain Personal Information for no longer than necessary to
                                        fulfill the purpose of the processing in question, i.e. typically for the duration of our
                                        contractual relationship. Whenever we process your Personal Information, such Personal
                                        Information will only be processed for the duration of your consent, which you may revoke or
                                        restrict at any time. If you do so, we will stop processing your Personal Information
                                        according to the context of the stated revocation or restriction, with the following
                                        exceptions.
                                    </p>
                                    <ul className="alpha-list">
                                        <li>
                                            We retain a history of transactions and accounts according to standard accounting
                                            practices, which does persist.

                                        </li>
                                    </ul>
                                    <h3>How Personal Information is Shared</h3>
                                    <p>
                                        Saturam may disclose your Information to others in the following ways:
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Affiliates:</span> We may disclose Information to our
                                        affiliates subject to this Privacy Policy in order for them to help market, sell, and
                                        service our Offerings. Saturam maintains agreements compliant with this Privacy Policy
                                        covering the use of Personal Information within the Saturam family of companies.
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Service Providers:</span> We may disclose Information
                                        to our service providers (e.g., infrastructure as a service, order fulfillment,
                                        professional/customer/support services), pursuant to written agreements with
                                        confidentiality, privacy, and security obligations. Saturam maintains a list of its
                                        sub-processors who process Personal Information as part of the Offerings, which Saturam
                                        updates as needed.
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">App Developers:</span> We may disclose Impersonal
                                        Information about App use and performance with App Developers so that they can improve and
                                        enhance the performance of their Apps. App Developers will be identified to you when you
                                        download and use their Apps pursuant to their license terms, including their privacy
                                        policies.
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Partners and Event Sponsors:</span> We may disclose
                                        contact and account Information to our partners and event sponsors (identified at time of
                                        registration or event participation) pursuant to written agreements with confidentiality,
                                        privacy and security obligations. They may use the Information to assess your interest in
                                        Saturam Offerings, conduct user research and surveys, or send you marketing communications,
                                        subject to the terms of their privacy policies. We may also share Support Usage Data with
                                        partners when they manage your Offering for you.
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Compliance and Safety:</span> We may disclose
                                        Information as necessary or appropriate under applicable laws (including laws outside your
                                        country of residence) to comply with legal process or requirements; to respond to requests
                                        from public or government authorities (including those outside your country of residence);
                                        to enforce our terms and conditions; and to protect our operations, rights, and safety, and
                                        that of you and others, as needed.
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Merger, Sale, etc.:</span> We may disclose Personal
                                        Information in the event of a proposed or actual corporate or financing transaction, such as
                                        a reorganization, merger, sale, joint venture, assignment, transfer, or disposition of all
                                        or any portion of Saturam business, assets, or stock (including Information regarding any
                                        bankruptcy or similar proceedings).
                                    </p>
                                    <p>
                                        <span className="policy-doc_subhead">Other Users:</span> We may disclose Impersonal
                                        Information to other users of the service in aggregate format. This may include "best
                                        practices" tips, KPIs, benchmark data or other such aggregated information useful to the
                                        user community.
                                    </p>
                                    <h3>Requests to Manage or Delete Personal Information</h3>
                                    <p>
                                        Saturam Inc. respects your control over your Personal Information and, upon request, we will
                                        confirm whether we hold or are processing information that we have collected from you. You
                                        also have the right to amend or update inaccurate or incomplete Personal Information,
                                        request deletion of your Personal Information, or request that we no longer use it. Under
                                        certain circumstances we will not be able to fulfill your request, such as if it interferes
                                        with our regulatory obligations, affects legal matters, we cannot verify your identity, or
                                        it involves disproportionate cost or effort, but in any event we will respond to your
                                        request within a reasonable timeframe and provide you an explanation. In order to make such
                                        a request, please email us at <a href="http://privacypolicy@saturam.com/"
                                            target="_blank" rel="noopener noreferrer">privacypolicy@saturam.com</a> or contact us at 3260 Hillview Avenue,
                                        Palo Alto, California 94304.
                                    </p>
                                    <h3>Personal Information We Collect</h3>
                                    <h3>Cookies</h3>
                                    <p>Please refer to our Cookie Policy found here.</p>
                                    <h3>Explanation and Examples</h3>
                                    <p>
                                        As a general matter, you can browse the Website without submitting your Personal Information
                                        to us. However, there are a number of circumstances in which you may supply us or our agents
                                        with your Personal Information. The following lists the most common ways in which we may
                                        collect your Personal Information.
                                    </p>
                                    <ul className="decimal-list">
                                        <li> When you submit the contact form</li>
                                        <li> When you download collaterals from our websites.</li>
                                        <li> When you sign up for a demo.</li>
                                        <li> When you submit a job application to work at Saturam.</li>
                                        <li> When you request a call, customer service, support requests or other assistance.</li>
                                        <li> For website related communications, e.g. account verification; technical notification.
                                        </li>
                                        <li> When you register or attend Saturam hosted or sponsored events such as promotional
                                            events, webcasts, contests, or hackathons.</li>
                                        <li> Any other place on the Website where you knowingly volunteer Personal Information.</li>
                                    </ul>
                                    <h3>Other Sources</h3>
                                    <p>
                                        As part of our marketplace activity, we collect Information about you from other sources
                                        such as public databases, joint marketing partners, social media platforms, conference/event
                                        hosts, and other partners.
                                    </p>
                                    <h3>Impersonal Information We Collect</h3>
                                    <p>
                                        In addition, when you interact with the Website, we may collect certain information that
                                        does not identify you individually and our servers may automatically keep an activity log of
                                        your use of our Website ("Impersonal Information"). Generally, we collect and store the
                                        following categories of Impersonal Information:
                                    </p>
                                    <ul className="decimal-list">
                                        <li> Non-identifiable demographic data such as age, gender, and zip code as part of
                                            collecting Personal Information.</li>
                                        <li> Device information about your computer, browser, mobile device, or other device that
                                            you use to access the Website. This information may include IP address, geolocation
                                            information, unique device identifiers, browser type, browser language, and other
                                            transactional information.</li>
                                        <li> Analytics and usage information about your use of the Website.</li>
                                        <li> Additional "traffic data" and log files such as time of access, date of access,
                                            software crash reports, session identification number, access times, and referring
                                            website addresses.</li>
                                        <li> Other information regarding your use of the Website.</li>
                                        <li> We may also gather and use information about you from publicly available third-party
                                            sources.</li>
                                    </ul>
                                    <h3>Social Media Widgets</h3>
                                    <p>
                                        Our Service may include social media features, such as Facebook, Twitter, Instagram,
                                        LinkedIn, YouTube and chatbot widgets. These features may collect your Personal Information
                                        and track your use of the Service. These social media features are either hosted by a third
                                        party or hosted directly in the Service. Your interactions with these features are governed
                                        by the privacy policy of the company providing such functionality.
                                    </p>
                                    <h3>LOG-IN FEATURES</h3>
                                    <p>
                                        We may support signup and log in using a Google email account. If you sign up using your
                                        Google email account, Google will ask your permission to share certain information from your
                                        Google account with us. This may include your first name, last name, gender, general
                                        location, your time zone and birthday. This information is collected by Google and is
                                        provided to us under the terms of Google's privacy policy which you can find here:
                                    </p>
                                    <a rel="noopener noreferrer" href="https://policies.google.com/privacy"
                                        target="_blank">https://policies.google.com/privacy/.</a>
                                    <p>
                                        You can control the information that we receive from Google using the privacy settings in
                                        your Google account.
                                    </p>
                                    <h3>Unsubscribe</h3>
                                    <p>
                                        You may unsubscribe from commercial communications at any time by contacting us at <a
                                            href="http://privacypolicy@saturam.com/" rel="noopener noreferrer" target="_blank">privacypolicy@saturam.com</a>
                                        or by using the unsubscribe link in our email communication.
                                    </p>
                                    <h3>Payment Information</h3>
                                    <p>
                                        Any payment card information you use to make a purchase on the Services is collected and
                                        processed directly by us or our payment processor, Braintree. and we never receive or store
                                        your full payment card information. Braintree may use your Payment Information in accordance
                                        with its own Privacy Policy here: <a
                                            href="https://www.braintreepayments.com/legal/braintree-privacy-policy" rel="noopener noreferrer" target="_blank">
                                            https://www.braintreepayments.com/legal/braintree-privacy-policy.</a>
                                    </p>
                                    <h3>Links to Third Party Sites</h3>
                                    <p>
                                        Saturam has no control over third party sites and their use of information that you may
                                        supply to such third-party sites.
                                    </p>
                                    <h3>Contact Us</h3>
                                    <p>
                                        If you have any questions about this Privacy Policy or don't see your concerns addressed
                                        here, please contact us by email at <a href="http://privacypolicy@saturam.com/"
                                            target="_blank" rel="noopener noreferrer" >privacypolicy@saturam.com</a> or via mail at 3260 Hillview Avenue, Palo
                                        Alto, California 94304. Attn: Privacy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <CopyRightsFooter />
            </>
        );
    }
}

export default PrivacyPolicy;