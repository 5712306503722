/**
 * Represents the Monitor page -> Model Errors tab contents
 * */

import React from 'react';
import { connect } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faList } from "@fortawesome/free-solid-svg-icons";
import ModelError from "./mlError";
import ModelErrorListView from "./modelErrorListView";

class MonitorERTabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);
        this.state = {
            activeTabKey: "me_list_view",
        }
    }


    changeTab(tab) {
        this.setState({ activeTabKey: tab });
    }

    render() {
        return (
            <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                <div className="tabbable-line mb-3">
                    <Nav variant="tabs" id="page-nav-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="me_chart_view">
                                <span className="qd-menu__link-text">
                                    <i>
                                        <FontAwesomeIcon icon={faChartLine} />
                                    </i>  Chart View</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="me_list_view">
                                <span className="qd-menu__link-text"><i>
                                    <FontAwesomeIcon icon={faList} />
                                </i>  List View</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="tab-content">
                    <Tab.Content>
                        {this.state.activeTabKey === "me_chart_view" ?
                            <Tab.Pane eventKey="me_chart_view">
                                <div className="portlet">
                                        <ModelError modelPerformanceData={this.props.modelPerformanceData}
                                            integrationsMap={this.props.integrationsMap}
                                            showMoreData={this.props.showMoreData}
                                            mlModelOptions={this.props.mlModelOptions}
                                            attributeOptions={this.props.attributeOptions}
                                        />
                                </div>
                            </Tab.Pane>
                            :
                            (this.state.activeTabKey === "me_list_view") ?
                                <Tab.Pane eventKey="me_list_view">
                                    <ModelErrorListView
                                        modelPerformanceData={this.props.modelPerformanceData}
                                        tableData={this.props.tableData}
                                        showLoader={this.props.showLoader}
                                        firstDatasetUp={this.props.firstDatasetUp}
                                        tableUpdated={this.props.tableUpdated}
                                        timelinessShowMore={this.props.timelinessShowMore}
                                        showMoreData={this.props.showMoreData}
                                        mlModelOptions={this.props.mlModelOptions}
                                        attributeOptions={this.props.attributeOptions}
                                    />
                                </Tab.Pane>
                                : ""}
                    </Tab.Content>
                </div>
            </Tab.Container>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MonitorERTabs);