import React from 'react';
import ChartBody from '../../components/chartBody';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import ProgressStatus from '../../components/progressStatus';
import NoDataComponent from "../components/noDataComponent";
import {
    RELATIONSHIP_METRICS,
    RELATIONSHIP_STATIC_CARD,
    RELATIONSHIP_METRIC_COLOR,

} from '../../utils/constant';
import moment from 'moment';
import * as d3 from 'd3';

import {
    normalizeChildDatasetName
} from '../../utils/attribute_name_utils';

import { renderTableComponents, renderskloader } from "./dq_render_utils";
import ProfileCardData from "./profileCardData"
import "../css/skloader.css";
import { getDatasForTab } from "../../utils/dataProfileEventHandling"
import { profileTabPrefetch, formTableData } from "../../utils/dataProfileEventHandling"
import TreeFilter from "../data/treeFilter"

class Relationship extends React.Component {

    constructor(props) {
        super(props);
        this.formCardData = this.formCardData.bind(this);
        this.formTableData = formTableData.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.filterTimeData = this.filterTimeData.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.renderTableComponents = renderTableComponents.bind(this);
        this.skloader = this.skloader.bind(this);
        this.profileTabPrefetch = profileTabPrefetch.bind(this);
        this.loadDataSourceRender = this.loadDataSourceRender.bind(this);
        this.getDatasForTab = getDatasForTab.bind(this);
        this.handleFilteredData = this.handleFilteredData.bind(this);
        this.allowPartition = this.allowPartition.bind(this)
        this.allowAttributes = this.allowAttributes.bind(this)
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.relationshipCheck = this.relationshipCheck.bind(this)
        this.setDataSetAndAttribute = this.setDataSetAndAttribute.bind(this);
        
        let startDate = moment().subtract(30, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        startDate = moment(startDate).format("Y/MM/D");
        endDate = moment(endDate).format("Y/MM/D");

        this.state = {
            hideLastProfiledTime: false,
            last_processed_time: "",
            isFilterMode: true,
            showModal: false,
            cardName: "",
            cardData: [],
            tableData: {
                "headers": ["DataSource", "DataSet", "Attribute Name", "Metric", "Metric Value"],
                "data": []
            },
            selectedDate: [],
            dataset_id: '',
            initial_load: 1,
            hideComponent: 'd-none',
            chartTitle: "Relationships & Errors within a Datasource",
            gridTitle: "Data quality metric values for selected datasets / attributes",
            startDate: startDate,
            endDate: endDate,
            environmentOptions: [],
            datasourceOptions: [],
            datasetsOptions: [],
            attributesOptions: [],
            showLoader: this.props.showLoader,
            newCardData: null,
            datasetLevelChart: [],
            attributeLevelChart: [],
            filterData: this.props.filterData,
            existingList: this.props.existingList,
            attributeOptions: [],
            seletedDatasourceId: "",
            seletedDatasourceName: "",
            filteredValues: this.props.filteredValues,
            checkedPartition: false,
            checkedAttributes: false,
            chartDatas: [],
            chartErrors: {},
            clearAllFilter: false,
            profileData: {},
            filteredDatas: {},
            showRelationalData: false,
            allowRelationData: false,
            allowRelationsFilter: "All",
            inProgress: null
        }
    }

    componentDidMount() {
        if (this.props.startDate !== null) {
            let loadparams = this.props.loadparams
            loadparams['tab_type'] = 1
            this.setState({ showLoader: true })
            this.getDatasForTab(loadparams, this.state.filteredValues, true)
        }
        else {
            if (this.props.noDataFound !== true || this.props.inProgress !== true) {
                this.setState({ showLoader: true })
                this.profileTabPrefetch(this.state.filteredValues, 1);
            }
        }
        this.formCardData();
    }

    componentWillUnmount() {
        d3.selectAll(".d3-tip").remove();
    }


    relationshipCheck(value) {
        if (value === "All") {
            this.setState({ allowRelationData: false, allowRelationsFilter: "All" })
        }
        else {
            this.setState({ allowRelationData: true, allowRelationsFilter: value })

        }
    }


    allowPartition() {
        this.setState({ checkedPartition: !this.state.checkedPartition })
    }

    allowAttributes() {
        this.setState({ checkedAttributes: !this.state.checkedAttributes })
    }


    loadDataSourceRender(params, filteredDatas = null, dateFilter = null) {
        /**Function used to Persist filtered values while applying date filter */

        /**TODO : Need to optimize this condition*/
        if (dateFilter === true) {
            this.setState({ showLoader: true })
            if (Object.keys(this.state.filteredValues).length > 0) {
                this.setState({ filteredDatas: this.state.filteredValues })
                filteredDatas = this.state.filteredValues
            }
            else {
                filteredDatas = this.state.filteredDatas
            }
            this.setState({
                clearAllFilter: false,
                showLoader: true,
                seletedDatasourceId: params.integration_id,
                seletedDatasourceName: params.integration_name,
                checkedAttributes: false,
                checkedPartition: false,
                allowRelationData: false,
                allowRelationsFilter: "All"
            })
            this.getDatasForTab(params, filteredDatas, dateFilter)

        }
        else {
            // 
            this.setState({
                clearAllFilter: false,
                showLoader: true,
                seletedDatasourceId: params.integration_id,
                seletedDatasourceName: params.integration_name,
                checkedAttributes: false,
                checkedPartition: false,
                allowRelationData: false,
                allowRelationsFilter: "All"
            })
            this.getDatasForTab(params)
        }
    }

    clearAllFilterData() {
        this.setState({ filterData: this.props.filterData, clearAllFilter: true })
    }

    handleFilteredData(data) {
        this.props.handleFilteredData(data)
        this.setState({ filteredValues: data, checkedAttributes: false, checkedPartition: false, allowRelationData: false, allowRelationsFilter: "All" })
        let filterData = formTableData(this.state.profileData['totalDatas'], 1, data)
        if (filterData !== undefined) {
            this.setState({ newCardData: filterData['cardData'], tableData: filterData['tableData'] })
        }
    }

    filterTimeData(key) {
        let tempStartDate = this.state.startDate;
        let timeList = [];
        for (let i = 0; i < 8; i++) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }

        return { "time": timeList, "values": RELATIONSHIP_STATIC_CARD[key] }
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }

    resetFilter() {
        this.setState({ formResetFlag: 1 });
    }

    toggleMainFilterApplied() {
        let isMainFilterApplied = this.state.isMainFilterApplied ? false : true;
        this.setState({
            isMainFilterApplied: isMainFilterApplied
        })
    }

    formTableData(filteredValues, changedDataSource = -1, startDate = this.props.dataModule.startDate, endDate = this.props.dataModule.endDate) {
        let tableData = this.state.tableData
        this.setState({ tableData: tableData });
    }

    setDataSetAndAttribute(dataSet, attribute) {
        this.props.setAttributeTab(dataSet, attribute)
    }

    formCardData() {
        let cardInfo = [];
        let commonId = 0;
        for (let key in RELATIONSHIP_METRICS) {
            let tempInfo = {};
            tempInfo["metric_name"] = RELATIONSHIP_METRICS[key];
            tempInfo["hide_edit"] = true;
            tempInfo["cardType"] = "chart";
            tempInfo["background"] = RELATIONSHIP_METRIC_COLOR[RELATIONSHIP_METRICS[key]].cardBackground;
            tempInfo["textColor"] = RELATIONSHIP_METRIC_COLOR[RELATIONSHIP_METRICS[key]].textColor;
            tempInfo["chartData"] = this.filterTimeData(commonId);
            tempInfo["idValue"] = `relationship${commonId}`;
            commonId = commonId + 1;
            cardInfo.push(tempInfo);
        }
        this.setState({
            cardData: cardInfo
        });
    }

    handleClick(data) {
        this.setState({ showModal: true, cardName: data.metric_name });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    skloader(key) {
        let count = this.state.initial_load + key
        this.setState({ initial_load: count })
    }

    loadFilter(pm) {

    }

    render() {
        const lastProfiledTime = this.state.last_processed_time
        let lastProfilingTime = lastProfiledTime ?
            `Your data last profiled at ${lastProfiledTime}` : "--";

        /**  TODO */
        // Below entire chart logic needs to be moved into a function : handleChartData()

        let chartData = []

        let initialFilteredValues = this.state.filteredValues
        let initialFilteredDatasets = []
        let initialFilteredAttributes = []
        let allowRelationsFilter = false

        if (Object.keys(initialFilteredValues).length > 0 && initialFilteredValues['fiteredDatseset'].length > 0) {
            initialFilteredDatasets = initialFilteredValues['fiteredDatseset']
            initialFilteredAttributes = initialFilteredValues['fitlerdAttr']
        }



        let datasetLevelError = []
        let attributeLevelError = []

        if (Object.keys(this.state.chartErrors).length > 0) {
            datasetLevelError = this.state.chartErrors['dataset_level']
            attributeLevelError = this.state.chartErrors['attr_level']
        }


        /**Separate Filter Existing Dataset Level Chart Data */
        let datasetLevelChart = []


        this.state.datasetLevelChart.map((data) => {
            data['name'] = normalizeChildDatasetName(data['name'])
            if (data.imports.length > 0 && data["confidence_score"] === 0) {
                data["confidence_score"] = 1
            }
            if (this.state.existingList.includes(Number(data.dataSetId))) {
                let error_metrics = []
                if (initialFilteredDatasets.length > 0) {
                    if (datasetLevelError[data.dataSetId] !== undefined && initialFilteredDatasets.includes(Number(data.dataSetId))) {
                        datasetLevelError[data.dataSetId].map((data) => {
                            if (data.error === true) {
                                error_metrics = error_metrics.concat(data)
                            }
                            return null
                        })
                        data['metrics'] = error_metrics
                        datasetLevelChart = datasetLevelChart.concat(data)
                    }
                    else if (initialFilteredDatasets.includes(Number(data.dataSetId))) {
                        datasetLevelChart = datasetLevelChart.concat(data)
                    }
                    // }
                }
                else {
                    if (datasetLevelError[data.dataSetId] !== undefined) {
                        datasetLevelError[data.dataSetId].map((data) => {
                            if (data.error === true) {
                                error_metrics = error_metrics.concat(data)
                            }
                            return null
                        })
                        data['metrics'] = error_metrics

                    }
                    datasetLevelChart = datasetLevelChart.concat(data)
                }

            }

            return null;
        })


        /**Separate Existing Attribute Level Chart Data */
        let attributeLevelChart = []
        this.state.attributeLevelChart.map((data) => {
            data['datasetName'] = normalizeChildDatasetName(data['datasetName'])
            if (data.imports.length > 0 && data["confidence_score"] === 0) {
                data["confidence_score"] = 1
            }
            if (this.state.existingList.includes(Number(data.attribute_id))) {
                let error_metrics = []
                if (initialFilteredDatasets.length > 0) {
                    if (attributeLevelError[data.attribute_id] !== undefined) {
                        attributeLevelError[data.attribute_id].map((data) => {
                            if (data.error === true) {
                                error_metrics = error_metrics.concat(data)
                            }
                            return null
                        })
                    }

                    data['metrics'] = error_metrics
                    if (initialFilteredDatasets.includes(Number(data.dataSetId)) && initialFilteredAttributes.includes(Number(data.attribute_id))) {
                        attributeLevelChart = attributeLevelChart.concat(data)
                    }
                    // else if(initialFilteredDatasets.includes(Number(data.dataSetId))){
                    //     attributeLevelChart = attributeLevelChart.concat(data)

                    // }
                    else if (initialFilteredAttributes.length === 0 && initialFilteredDatasets.includes(Number(data.dataSetId))) {
                        attributeLevelChart = attributeLevelChart.concat(data)
                    }
                }
                else {
                    if (attributeLevelError[data.attribute_id] !== undefined) {
                        attributeLevelError[data.attribute_id].map((data) => {
                            if (data.error === true) {
                                error_metrics = error_metrics.concat(data)
                            }
                            return null
                        })
                        data['metrics'] = error_metrics
                    }
                    attributeLevelChart = attributeLevelChart.concat(data)
                }
            }

            return null;
        })

        /**Separate Partition and child datasets */
        let parentDatasetIds = []
        let childDatasetIds = []

        datasetLevelChart.map((data) => {
            if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
                childDatasetIds = childDatasetIds.concat(Number(data.dataSetId))
            }
            else {
                parentDatasetIds = parentDatasetIds.concat(Number(data.dataSetId))
            }
            return null
        })


        /**Separate Partition Dataset Attributes and Child Dataset Attributes  */
        let parentAttributeIds = []
        let childAttributeIds = []

        attributeLevelChart.map((data) => {
            if (parentDatasetIds.includes(Number(data.dataSetId))) {
                parentAttributeIds = parentAttributeIds.concat(data.dataSetId)
            }
            else {
                childAttributeIds = childAttributeIds.concat(data.attribute_id)
            }
            return null
        })


        /**Handle Dataset level chart Data based on the chart check box filter */
        let attributeList = []

        datasetLevelChart.map((data) => {
            if (this.state.checkedPartition) {
                chartData = chartData.concat(data)
                attributeList = childAttributeIds.concat(parentAttributeIds)
            }
            else if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
                /*** Skip Pushing Child Dataset initially */
            }
            else {
                attributeList = parentAttributeIds
                chartData = chartData.concat(data)
            }
            return null
        })

        /**Handle Attribute level chart Data based on the chart check box filter */
        if (this.state.checkedAttributes) {
            chartData = []

            attributeLevelChart.map((data) => {
                if (data.confidence_score !== undefined && data.confidence_score !== null && data.confidence_score > 0) {
                    allowRelationsFilter = true
                }
                if (this.state.allowRelationData === true && allowRelationsFilter) {
                    if (this.state.checkedPartition === false) {
                        if (parentDatasetIds.includes(Number(data.dataSetId))) {
                            if (data.confidence_score !== undefined && data.confidence_score !== null && data.confidence_score > 0) {
                                chartData = chartData.concat(data)
                            }
                        }
                    }
                    else if (childDatasetIds.includes(Number(data.dataSetId))) {
                        if (data.confidence_score !== undefined && data.confidence_score !== null && data.confidence_score > 0) {
                            chartData = chartData.concat(data)
                        }
                    }
                    else {
                        if (data.confidence_score !== undefined && data.confidence_score !== null && data.confidence_score > 0) {
                            chartData = chartData.concat(data)
                        }
                    }
                }
                else if (this.state.allowRelationData !== true) {
                    if (this.state.checkedPartition === false) {
                        if (parentDatasetIds.includes(Number(data.dataSetId))) {
                            chartData = chartData.concat(data)
                        }
                    }
                    else if (childDatasetIds.includes(Number(data.dataSetId))) {
                        chartData = chartData.concat(data)
                    }
                    else {
                        chartData = chartData.concat(data)
                    }
                }
                return chartData
            })
        }
        /**  TODO */
        // Above entire chart logic needs to be moved into a function : handleChartData()

        return (
            this.state.showLoader === false && this.props.inProgress === false
                ?
                (
                    <div className={"data-profile-tabs"}>
                        <div className="qd-tab__content-action">
                            {
                                this.state.hideLastProfiledTime === false ?
                                    <div className="caption">
                                        <div className="alert moniker-alert" role="alert">
                                            <p className="mb-0">
                                                <strong> {lastProfilingTime}</strong>
                                            </p>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="actions flex-shrink-0">
                                <button onClick={this.toggleFilter} className="btn btn-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                        viewBox="0 0 24 24" width="24">
                                        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                        <path d="M0 0h24v24H0z" fill="none" />
                                    </svg>
                                    <span className="dot-symbol"></span>
                                    <span>Filters</span>
                                </button>
                            </div>
                        </div>

                        {this.state.isFilterMode === true &&
                            <TreeFilter
                                tab={"relationship"}
                                startDate={this.props.startDate !== null ? this.props.startDate : this.state.startDate}
                                endDate={this.props.endDate !== null ? this.props.endDate : this.state.endDate}
                                seletedDatasourceId={this.state.seletedDatasourceId}
                                seletedDatasourceName={this.state.seletedDatasourceName}
                                data={this.state.filterData}
                                clearAllFilterData={this.clearAllFilterData}
                                clearAllFilter={this.state.clearAllFilter}
                                loadDataSourceRender={this.loadDataSourceRender}
                                handleFilteredData={this.handleFilteredData}
                                handleDateFiltered={this.props.handleDateFiltered}

                            />
                        }
                        <ProfileCardData
                            cardData={this.state.cardData}
                            newCardData={this.state.newCardData}
                            cardName={this.state.cardName}
                            showModal={this.state.showModal}
                            hideModal={this.hideModal}
                            skloader={this.skloader}
                        />
                        <BasicPortlet
                            video_url="profile_data"
                            className="pb-0"
                            title={this.state.chartTitle}
                            bodyClassName="pb-0"
                            showFilter={false}
                            showtimeFilter={false}
                            id="relationship"
                            disableTimeFilter={true}
                            content={
                                <ChartBody
                                    id="chartRel"
                                    chartType="hierarchicalEdgeBundlingV1"
                                    page="profile"
                                    video_url="profile_data"
                                    title={this.state.chartTitle}
                                    changeTab={this.props.changeTab}
                                    setDataSetAndAttribute={this.setDataSetAndAttribute}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    showParititionFilter={true}
                                    showAttributeFilter={true}
                                    showZoom={true}

                                    // ToDo Rename variable
                                    chart_data={chartData}
                                    datasetLevelChart={childDatasetIds}
                                    attributeLevelChart={attributeList}
                                    allowPartition={this.allowPartition}
                                    allowAttributes={this.allowAttributes}
                                    checkedPartition={this.state.checkedPartition}
                                    checkedAttributes={this.state.checkedAttributes}
                                    relationshipCheck={this.relationshipCheck}
                                    relationshipCheckFilter={this.state.allowRelationData === true ? this.state.allowRelationData : allowRelationsFilter}
                                    allowRelationsFilter={this.state.allowRelationsFilter}
                                />
                            }
                        />
                        <BasicPortlet
                            video_url="profile_data"
                            className="mb-0 pb-0" id="relationshipTableData"
                            title={this.state.gridTitle}
                            bodyClassName="pb-0"
                            content={
                                <QualdoDataTable
                                    customGetActionComponent={this.renderTableComponents}
                                    data={this.state.tableData}
                                    component_name="profile_data"
                                />
                            }
                        />
                    </div>
                )
                :
                (
                    this.props.inProgress === true && this.state.showLoader === false
                        ?
                        <BasicPortlet
                            video_url="profile_data"
                            className="pb-0"
                            title={this.state.chartTitle}
                            bodyClassName="pb-0"
                            id="relationship"
                            content={
                                <>
                                    <ProgressStatus
                                        progressStatus={this.props.progressStatus}
                                    />
                                </>
                            }
                        />
                        :
                        (this.props.noDataFound === true ?
                            <NoDataComponent
                                noContentSubtext={"Please Configure datasource to view Relationship"}
                                customButtonName={"Configure Datasource"}
                                navigatePage={"/configure"}
                                noIntegration={this.props.noDataFound} />
                            :
                            <>
                                {this.state.showLoader === true ? renderskloader(this.state.chartTitle, this.state.gridTitle) : ''}
                            </>
                        )
                )
        );
    }
}

export default Relationship;