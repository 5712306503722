import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from 'lodash';

const CustomSelectNestedCheckbox = props => {
  if (props.allowSelectAll) {
    let allOption = {"label": "All", "type": "*", "value": "*"}
    return (
      <Select
        {...props}
        options={props.options.length > 0 ? [props.allOption, ...props.options] : []}
        className="qd_nested-multiselect"
        onChange={(selected, event) => {
          let result = []
          if (selected !== null && selected.length > 0) {
            // Case 1: All option is selected
            if (selected[selected.length - 1].value === allOption.value) {
               return props.onChange([props.allOption, ...props.options]);
            }

            let grouped = _.mapValues(_.groupBy(selected, 'type'));
            let groupedOptions = _.mapValues(_.groupBy([props.allOption, ...props.options], 'type'));
            let propsSelected = _.mapValues(_.groupBy(props.value, 'type'));

            let groupedKeys = Object.keys(grouped)
            let propsKey = Object.keys(propsSelected)

            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                 result = selected.filter(
                   option => option.value !== props.allOption.value
                 );
                 for (let [key,value] of Object.entries(grouped)) {
                      let filtered = value.filter(x=>x.value === key)
                      let selectedFiltered = value.filter(x=>x.value !== key)
                      let originalFiltered = groupedOptions[key].filter(x=>x.type === key)
                      if(selectedFiltered.length < originalFiltered) {
                        result = result.filter(
                           option => option.value !== key
                         );
                         continue
                      }
                      if(filtered.length === 0){
                         result = result.filter(x=>x.type !== key)
                      }
                  }
                 return props.onChange(result);

              }else if(!groupedKeys.includes("*") && propsKey.includes("*")){
                  result = []
                  return props.onChange(result);
              }

            }

            for (let [key,value] of Object.entries(grouped)) {
                      let filtered = value.filter(x=>x.value === key)
                      let optionFiltered = value.filter(x=>x.value !== key)
                      let v = groupedOptions[key]
                      if(value[value.length - 1].value === key && key !== "*") {
                        let filtered1 = props.options.filter(x=>x.type === key)
                        result = result.concat(filtered1)
                        continue
                      }
                      let original = v.filter(x=>x.value !== key)
                      if(filtered.length === 0 && original.length === optionFiltered.length) {
                        let isAllUnSelected = false
                        if(propsSelected[key] !== undefined) {
                          isAllUnSelected = propsSelected[key].filter(x=>x.value === key).length > 0
                        }
                        if(isAllUnSelected) {
                          result = result.concat(result)
                          continue
                        }else{
                          result = result.concat(props.options.filter(x=>x.type === key))
                          continue
                        }

                      }

                      if(optionFiltered.length > 0 && key !== "*" && original.length > optionFiltered.length) {
                         result = result.concat(optionFiltered)
                         continue
                      }

                      if(filtered.length > 0 && key !== "*"){
                         let filtered1 = props.options.filter(x=>x.type === key)
                         result = result.concat(filtered1)
                         continue
                      }

                      result = result.concat(optionFiltered)





                  }
          }
           //Case 5: All options selected
          if(result.length === props.options.length){
              return props.onChange([props.allOption, ...props.options]);
          }




          return props.onChange(result);
        }}
      />
    );
  }

  return <Select {...props} />;
};

CustomSelectNestedCheckbox.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

CustomSelectNestedCheckbox.defaultProps = {
  allOption: {
    label: "All",
    value: "*",
    type: "*"
  }
};

export default CustomSelectNestedCheckbox;