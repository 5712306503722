import React from "react";
import Image from "../image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import Modal from "react-bootstrap/Modal";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {submitPlanChangeRequest }  from  "../../utils/event_handling";
import {customDateFormat, capitalizeFirstLetter} from "../../utils/common_utils";
import {PLAN_ICON_MAPS} from "../../utils/constant";

class PlanDowngradeModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleTab = this.handleTab.bind(this);
        this.goToPreviousScreen = this.goToPreviousScreen.bind(this);
        this.goToNextScreen = this.goToNextScreen.bind(this);
        this.changeActiveClass = this.changeActiveClass.bind(this);
        this.submitData = this.submitData.bind(this);
        this.submitPlanChangeRequest = submitPlanChangeRequest.bind(this);
        this.goToChoosePlan = this.goToChoosePlan.bind(this);
        let mlModels = this.setMlModels();
         this.state = {
            current_tab: "tab_1",
            selectedModels: mlModels,
            has_done_1: null,
            has_done_2: null,
            has_done_3: null
         }
    }

    setMlModels(){
        let data = {}
        for (var i = 0; i < this.props.data["model_list"].length; i++) {
            let ml_model_id = this.props.data["model_list"][i]["ml_model_id"];
            data[ml_model_id] = false;
        }
        return data
    }

    submitData(){
        let ml_models_to_be_saved = Object.fromEntries(Object.entries(this.state.selectedModels).filter(([k,v]) => v === true));
        let save_models = [];
        if(Object.keys(ml_models_to_be_saved).length > 0) {
            save_models = Object.keys(ml_models_to_be_saved);
        }
        let current_plan_id = this.props.data["current_plan"]["qualdo_plan_id"];
        let requested_plan_id = this.props.data["requested_plan"]["qualdo_plan_id"];
        let data = {"tenant_id":localStorage.getItem('team_id'),
                    "ml_models_to_be_saved": save_models,
                    "current_plan_id": current_plan_id , "requested_plan_id": requested_plan_id}
        this.submitPlanChangeRequest(data, this.props.showToast);
    }

    handleTab(key) {
        if (key === "tab_3"){
            let allowed_models = this.props.data["no_of_models_to_select"];
            let selectedModels = Object.fromEntries(Object.entries(this.state.selectedModels).filter(([k,v]) => v === true));
            if (Object.keys(selectedModels).length > allowed_models){
                this.setState({error_message: true})
                return
            }
       }
       this.setState ({current_tab: key, error_message: false});
    }

    goToChoosePlan(){
      this.props.closeDowngradePopup();
        // window.location.href = "/choose-plan";
    }

    goToPreviousScreen(){
       let current_tab = Number(this.state.current_tab.replace("tab_", ''));
       let has_done_var = "has_done_"+current_tab;
       let previous_tab = (current_tab > 1 ? (current_tab - 1) :(current_tab));
       this.setState({current_tab: "tab_"+previous_tab, [has_done_var]: null});
    }

    goToNextScreen() {
       let current_tab = Number(this.state.current_tab.replace("tab_", ''));
       let has_done_var = "has_done_"+current_tab;
       let next_tab = (current_tab < 3 ? (current_tab + 1) :(current_tab));
       if (next_tab === 3){
            let allowed_models = this.props.data["no_of_models_to_select"];
            let selectedModels = Object.fromEntries(Object.entries(this.state.selectedModels).filter(([k,v]) => v === true));
            if (Object.keys(selectedModels).length  > allowed_models){
                this.setState({error_message: true});
                return
            }
       }
       this.setState({current_tab: "tab_"+next_tab, error_message: false, [has_done_var]: "has-done"});
    }


    changeActiveClass(ml_model_id) {
        this.setState(state => ({
            selectedModels: { ...state.selectedModels, [ml_model_id]: !state.selectedModels[ml_model_id] }
        }))
    }

    renderModels() {
        let models = [];
        for (var i = 0; i < this.props.data["model_list"].length; i++) {
            let ml_model_id = this.props.data["model_list"][i]["ml_model_id"];
            let is_selected = this.state.selectedModels[ml_model_id];
            let class_name = "custom-tag";
            if (is_selected === true){
                class_name = class_name +" active";
            }
            const handleClick = () => this.changeActiveClass(ml_model_id);
            models.push(<div onClick={handleClick}  className={class_name} key={ml_model_id}><span className="custom-tag_title">{this.props.data["model_list"][i]["model_name"]}</span><span className="custom-tag_divider"></span><i className="custom-tag_icon"></i></div>);
        }
        return models;
    }


    render() {
    let current_plan_details = this.props.data["current_plan"];
    let requested_plan = this.props.data["requested_plan"];

    let no_of_models = 0;

    let selectedModels = Object.fromEntries(Object.entries(this.state.selectedModels).filter(([k,v]) => v === true));

    if( this.props.data["model_list"] !== undefined &&  this.props.data["model_list"].length > 0){
        no_of_models =  this.props.data["model_list"].length;
    }
    let button_name = "Next"
    if (this.state.current_tab === "tab_3"){
        button_name = "Submit";
    }
    let model_not_selected =  Object.keys(selectedModels).length === 0;

    let error_message = false;
    if (Object.keys(selectedModels).length > this.props.data["no_of_models_to_select"]){
        error_message = true;
    }
    let current_plan_icon = PLAN_ICON_MAPS[current_plan_details["plan_name"]];
    let requested_plan_icon = PLAN_ICON_MAPS[requested_plan["plan_name"]];

    let customMetricAlert = false;
    if (requested_plan["plan_name"] === "free"){
        customMetricAlert = true;
    }

    let current_plan_end_date = customDateFormat(this.props.data["current_plan_cycle_end"])
    let next_billing_date = customDateFormat(this.props.data["next_billing_date"])

        return (
  <Modal show={true} centered size="md" scrollable className="modal__sm-scrollable-fullscreen" dialogClassName="modal-light">
    <Tab.Container activeKey={this.state.current_tab} onSelect={this.handleTab}>
          <Modal.Header className="modal-header">
            <h3 className="modal-header__title">Downgrade Subscription</h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.goToChoosePlan}></button>
            <div className="tabbable-line has-arrow-right">
            <Nav variant="tabs" role="tablist">
                <Nav.Item>
                <Nav.Link eventKey="tab_1" className={this.state.has_done_1} >
                    <span className="qd-menu__link-text has-done">1<span className="d-none d-sm-inline">.</span> Subscription</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab_2" className={this.state.has_done_2}>
                    <span className="qd-menu__link-text has-done">2<span className="d-none d-sm-inline">.</span> Choose <span className="d-none d-sm-inline">ML Model</span></span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab_3" className={this.state.has_done_3}>
                        <span className="qd-menu__link-text has-done">3<span className="d-none d-sm-inline">.</span> Activate</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="qd-plan-downgrade">
              <Tab.Content>
                <Tab.Pane eventKey="tab_1">
                  <div className="qd-plan-downgrade__card">
                    <div className="card__qualdo-edition your-plan__professional card">
                      <div className="card-body">
                        <div className="user__plan">
                        <Image src={current_plan_icon} alt=""/>
                          <div className="user__plan-info">
                            <p>Current plan</p>
                            <h4>{capitalizeFirstLetter(current_plan_details["plan_name"])}</h4>
                          </div>
                        </div>
                        <p className="qd-plan__benefits">Upto {current_plan_details["allowed_datasize"]} GB Data Size <br />Upto {current_plan_details["allowed_models"]} Models</p>
                        <h6 className="qd-plan__time-period">Until {current_plan_end_date}</h6>
                      </div>
                    </div>
                    <div className="qd-plan__navigation-arrow">
                      <Image src="plan_navigation_arrow" alt="" />
                    </div>
                    <div className="card__qualdo-edition your-plan__starter card active">
                      <div className="card-body">
                        <div className="user__plan"><Image src={requested_plan_icon} alt=""/>
                          <div className="user__plan-info">
                            <p>New plan</p>
                            <h4>{capitalizeFirstLetter(requested_plan["plan_name"])}</h4>
                          </div>
                        </div>
                        <p className="qd-plan__benefits">Upto {requested_plan["allowed_datasize"]} GB Data Size <br />Upto {requested_plan["allowed_models"]} Models</p>
                        <h6 className="qd-plan__time-period">Starting from {next_billing_date}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="qd-plan-downgrade__info">
                    <h4>Starting from {next_billing_date}</h4>
                    <p>{capitalizeFirstLetter(requested_plan["plan_name"])} Plan ({requested_plan["plan_cycle"]})</p>
                  </div>
                  <div className="alert-info mb-2">
                    <h5>Your plan downgrade request will be effective from the next billing cycle.</h5>
                    <p>Your account will be switched to the new plan automatically from the next billing cycle.</p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab_2">
                    {
                    no_of_models > 0
                    ?
                    <>
                    <h4 className="qd-plan-downgrade__title">Configured ML Models</h4>
                    <p>You have configured <strong>{this.props.data["model_list"].length}</strong> models in your Qualdo™ account.
                    Up to {requested_plan["allowed_models"]} models can be monitored in your new plan. Please select the models you wish to continue monitoring in the new plan.</p>
                    <small className="d-block text__user-guide">
                      Please select the models by clicking the <span>[+] plus symbol</span> 
                      <br/>
                      you wish to continue monitoring in the new plan</small>

                    <div className="custom-tag-group">
                    {this.renderModels(this.props.data["model_list"])}
                    </div>
                    </>
                  :
                  <div className="centered-msg">
                      <div>
                        <Image src="icon_model_cubes" alt=""/>
                      </div>
                      <h4 className="centered-msg__title mb-2">No ML Models configured</h4>
                      <p className="centered-msg__description">You have not configured any ML Model in your Qualdo™ account.</p>
                  </div>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="tab_3">
                  <div className="centered-msg">
                      <div className="centered-msg__icon">
                        <Image src="icon_green_check" alt=""/>
                      </div>
                      <h4 className="centered-msg__title">You are requesting to downgrade your Qualdo™ plan</h4>
                      { (no_of_models > 0 || customMetricAlert ===true) &&
                        <div className="alert-warning">
                            <i className="alert-icon_warning mr-3 d-none d-sm-block"></i>
                            <div className="alert-warning_content">
                              {
                              no_of_models > 0 &&
                              <p>You have selected <strong>{Object.keys(selectedModels).length}</strong> model(s) for monitoring in the new plan. Other models with their associated charts and details will be deleted. Model(s) once deleted cannot be recovered. </p>
                              }
                              {
                              customMetricAlert === true &&
                              <p>Custom metrics will be deleted on downgrading the plan.</p>
                              }
                            </div>
                        </div>
                      }
                      <p className="centered-msg__description">The change which you are requesting will be applicable from <strong>{next_billing_date}</strong></p>
                  </div>
              </Tab.Pane>
              </Tab.Content>
            </div>
          </Modal.Body>
          {
          no_of_models > 0 && model_not_selected === true &&  this.state.current_tab === "tab_2"
          ?
          <Modal.Footer className="modal-footer modal-footer-warning">
            <p className="msg__text">You have not selected any models for the new plan. <span>Continue Anyway</span> without selecting any existing model.</p>
            <button type="submit" onClick={this.goToNextScreen} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal">Continue Anyway <i className="ml-2"><FontAwesomeIcon icon={ faArrowRight }/></i> </button>
          </Modal.Footer>
          :
            error_message === true && this.state.current_tab === "tab_2"?
              <Modal.Footer className="modal-footer modal-footer-warning">
                <p className="msg__text"><span>You have to select only {this.props.data["no_of_models_to_select"]} ML Models based on the new plan.</span></p>
                <button type="button" onClick={this.goToPreviousScreen} className="btn btn-outline btn-grey btn-circle mr-2">Go Back</button>
                <button type="submit" disabled onClick={this.goToNextScreen} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal">{button_name} <i className="ml-2"><FontAwesomeIcon icon={ faArrowRight }/></i> </button>
              </Modal.Footer>
            :
              <Modal.Footer className="modal-footer d-block text-right">
                <button type="button" onClick={this.goToPreviousScreen} className="btn btn-outline btn-grey btn-circle mr-2">Go Back</button>
                {
                button_name === "Next" ?
                <button type="submit" onClick={this.goToNextScreen} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal">{button_name} <i className="ml-2"><FontAwesomeIcon icon={ faArrowRight }/></i> </button>
                :
                <button type="submit" onClick={this.submitData} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal"><i className="ml-2"><FontAwesomeIcon icon={ faCheck }/></i> {button_name}</button>
                }
              </Modal.Footer>
          }
      </Tab.Container>
  </Modal>
        );
    }
}


export default PlanDowngradeModal;

