import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';


class CustomListInCell extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.getProfileListComponents = this.getProfileListComponents.bind(this);
    }

    getProfileListComponents(listData) {
        return listData.map(x =>
            <dl key={x.text}>
                <dt>{x.text}</dt>
                {x.timeValue !== undefined ?
                   <dd className={x.highlight === true ? "text-danger": ""}>
                       <strong>{moment(x.timeValue).utcOffset(0).format('Do MMMM YYYY')}<br/>
                               {moment(x.timeValue).utcOffset(0).format('HH:mm:ss')}<br/>
                       </strong>
                   </dd>
                   :
                   <dd><strong>{x.value}</strong></dd>
                }
            </dl>
        );
    }

    render() {
        const listData = this.props.listData;
        if (listData === undefined || listData === null) {
            return ""
        }

        if (listData.length === 0 && this.props.isModel === true) {
            return (
                <div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea}/>
                    </h2>
                    <h4>{(this.props.inProgress !== undefined && this.props.inProgress === true) ? "Model Profiling is in-progress" : this.props.noModelConfigured !== undefined && this.props.noModelConfigured === false ? "No data found" : "Model not configured"}</h4>
                </div>
            );
        }

        return (
            <>
                {this.getProfileListComponents(listData)}
            </>
        );

    }
}


export default CustomListInCell;