import React from 'react';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "../../components/image";
import {
    PAYMENT_CARD_TYPES,
    PAYMENT_COMPONENT_STYLES,
    PAYMENT_FIELD_ID_MAP
} from "../../utils/constant";
import Load from "../../components/loadAction";
import {postPaymentNonce} from "../../utils/event_handling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";

let braintree = require('braintree-web');

class AddCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.clientDidCreate = this.clientDidCreate.bind(this);
        this.hostedFieldsDidCreate = this.hostedFieldsDidCreate.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.showPaymentPage = this.showPaymentPage.bind(this);
        this.cardTypeProcessor = this.cardTypeProcessor.bind(this);
        this.postPaymentNonce = postPaymentNonce.bind(this);
        this.getCardTypeImage = this.getCardTypeImage.bind(this);
        this.tokenizeFunction = this.tokenizeFunction.bind(this);
        this.handleCustomerName = this.handleCustomerName.bind(this);
        this.cardComponentsValidator = this.cardComponentsValidator.bind(this);
        this.getComponentClass = this.getComponentClass.bind(this);
        this.makeCurrentAsDefaultPayment = this.makeCurrentAsDefaultPayment.bind(this);

        let make_default = false;

        this.state = {
            hostedFields: null,
            errorOccurred: false,
            load: true,
            isBraintreeError : false,
            cardType: null,
            customerNameClass: '',
            customerName: '',
            creditCardErrorMsg:"Credit card number is required",
            make_default: make_default,
            validation: {
                cvv: {
                    is_valid: false,
                    is_invalid: false
                },
                expiration_date: {
                    is_valid: false,
                    is_invalid: false
                },
                card_number: {
                    is_valid: false,
                    is_invalid: false
                },
            }
        };
    }

    getComponentClass(component) {
        let reqClass = "form-control";
        let validation = this.state.validation;
        let componentInfo = validation[component];

        if (componentInfo === undefined || componentInfo === null) {
            return reqClass;
        }

        if (componentInfo.is_valid) {
            reqClass = `${reqClass} is-valid`;
        } else if (componentInfo.is_invalid) {
            reqClass = `${reqClass} is-invalid`;
        }

        return reqClass;
    }

    componentDidCatch(error, info) {
        this.setState({errorOccurred: true});
    }


    componentDidMount() {
        this.showPaymentPage();
    }

    showPaymentPage() {
        let braintreeAuthorizationToken = this.props.authorizationToken;
        braintree.client.create({
            authorization: braintreeAuthorizationToken
        }, this.clientDidCreate);
    }


    clientDidCreate(err, client) {
        braintree.hostedFields.create({
            client: client,
            styles: PAYMENT_COMPONENT_STYLES,
            fields: {
                number: {
                    selector: '#card_number',
                    placeholder: 'Enter your card number'
                },
                cvv: {
                    selector: '#cvv',
                    type: "password"
                },
                expirationDate: {
                    selector: '#expiration_date',
                    placeholder: 'MM/YY',
                }
            }
        }, this.hostedFieldsDidCreate);
    }

    cardTypeProcessor(event) {
        if (event.cards.length === 1) {
            const cardType = event.cards[0].type;
            this.setState({cardType: cardType});
        } else {
            this.setState({cardType: null});
        }
    }

    cardComponentsValidator(event) {
        let field = event.fields[event.emittedBy];
        const currentFiledId = field.container.id;

        // Remove any previously applied error or warning classes
        let validation = this.state.validation;
        let isValidPrev = validation[currentFiledId].is_valid;
        validation[currentFiledId].is_valid = false;
        validation[currentFiledId].is_invalid = false;

        if (field.isValid) {
            validation[currentFiledId].is_valid = true;
        } else if (field.isPotentiallyValid) {
            // skip adding classes if the field is
            // not valid, but is potentially valid
            if (isValidPrev) {
                validation[currentFiledId].is_invalid = true;
            }
        } else {
            validation[currentFiledId].is_invalid = true;
        }
        if(field.isEmpty ===true  && currentFiledId === "card_number" )
        {
            validation[currentFiledId].is_valid = false;
            validation[currentFiledId].is_invalid = true;
            this.setState({creditCardErrorMsg:"Credit card number is required"})
        }
        else 
        {
            this.setState({creditCardErrorMsg:"Card number is invalid"})
        }
        this.setState({validation: validation});
    }

    hostedFieldsDidCreate(err, hostedFields) {
        let submitBtn = document.getElementById('btn-add-card');
        this.setState({hostedFields: hostedFields});
        if (hostedFields === undefined)
        {
            this.setState({isBraintreeError:true});
            this.setState({load: false});
        }
        else
        {
            hostedFields.on('cardTypeChange', this.cardTypeProcessor);
            hostedFields.on('validityChange', this.cardComponentsValidator);
            this.setState({load: false});
        }
        
        submitBtn.addEventListener('click', this.submitHandler);
        submitBtn.removeAttribute('disabled');
    }

    tokenizeFunction(err, payload) {
        let submitBtn = document.getElementById('btn-add-card');
        if (err) {
            submitBtn.removeAttribute('disabled');
            console.error(err);
        } else {
            let details = {
                "nonce": payload.nonce,
                "customer_name": this.state.customerName,
                "make_default": this.state.make_default
            };

            this.postPaymentNonce(details, this.props.showAlertSection);
        }
    }

    submitHandler(event) {
        let submitBtn = document.getElementById('btn-add-card');
        event.preventDefault();

        let formIsInvalid = false;
        let retrievedState = this.state.hostedFields.getState();
        // Loop through the Hosted Fields and check
        // for validity, apply the is-invalid class
        // to the field container if invalid

        let validation = this.state.validation;

        for (let field of Object.keys(retrievedState.fields)) {
            if (!retrievedState.fields[field].isValid) {
                // Mark the error component
                let mappedID = PAYMENT_FIELD_ID_MAP[field];
                if (validation === undefined) {
                    continue;
                }

                let currentCompValidation = validation[mappedID];
                if (currentCompValidation === undefined || currentCompValidation == null) {
                    continue;
                }

                currentCompValidation.is_invalid = true;
                formIsInvalid = true;
            }
        }

        this.setState({validation: validation});

        if (this.state.customerName.trim().length === 0) {
            this.setState({customerNameClass: 'is-invalid'});
            formIsInvalid = true;
        }

        if (formIsInvalid) {
            // skip tokenization request if any fields are invalid
            return;
        }

        submitBtn.setAttribute('disabled', 'disabled');

        this.state.hostedFields.tokenize(this.tokenizeFunction);
    }

    getCardTypeImage() {
        return (<Image src={PAYMENT_CARD_TYPES[this.state.cardType]}/>);
    }

    handleCustomerName(event) {
        const customerName = event.target.value;
        this.setState({customerName: customerName});
        if (!customerName.trim()) {
            this.setState({customerNameClass: 'is-invalid'});
        } else {
            this.setState({customerNameClass: 'is-valid'});
        }
    }

    makeCurrentAsDefaultPayment(event) {
        this.setState({make_default: !this.state.make_default});
    }

    render() {
        return (
            <Form className="payment__card">
                {this.state.load ? <Load/> : ''}

                <div className="card payment__preference"
                     style={{display: (this.state.load || this.state.isBraintreeError ) ? "none" : "block"}}>
                    <Row className="no-gutters">
                        <Col className="card-icon d-none d-sm-flex">
                            <i><FontAwesomeIcon icon={faInfoCircle}/></i>
                        </Col>
                        <Col>
                            <div className="card-body">
                                <h5 className="card-title">Qualdo™ accepts major
                                    credit
                                    and
                                    debit cards.</h5>
                                <ul className="payment-cards">
                                    <li><Image src="creditCard_visa"/></li>
                                    <li><Image src="creditCard_master"/></li>
                                    <li><Image src="creditCard_amex"/></li>
                                    <li><Image src="creditCard_discover"/></li>
                                    <li><Image src="creditCard_jcb"/></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Form.Row>
                    <Col xs={7} className="form-group"
                         style={{display: (this.state.load || this.state.isBraintreeError) ? "none" : "block"}}
                    >
                        <Form.Group controlId="formCardName">
                            <Form.Label>Name on card <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Name"
                                          className={`${this.state.customerNameClass}`}
                                          onChange={this.handleCustomerName}
                                          defaultValue={this.state.customerName}
                            />
                            <div className="invalid-feedback">
                                Invalid cardholder name
                            </div>
                        </Form.Group>
                    </Col>

                </Form.Row>

                <Form.Row>

                    <Col xs={7}
                         style={{display: (this.state.load || this.state.isBraintreeError) ? "none" : "block"}}
                         className="form-group">
                        <Form.Label className="hosted-fields--label">Credit card number <span className="text-danger">*</span></Form.Label>
                        <div className={this.getComponentClass("card_number")}
                             id="card_number"/>
                        <div className="invalid-feedback">
                            {this.state.creditCardErrorMsg}
                        </div>
                        {this.state.cardType === null
                            ?
                            '' :
                            <div className="credit-card-icon">{this.getCardTypeImage()}</div>
                        }
                    </Col>

                    <Col sm={3} xs={5} className="form-group"
                         style={{display: (this.state.load || this.state.isBraintreeError) ? "none" : "block"}}
                    >
                        <label htmlFor="expiration_date">Expiration Date <span className="text-danger">*</span></label>
                        <div className={this.getComponentClass("expiration_date")}
                             id="expiration_date"/>
                        <div className="invalid-feedback">
                            Please provide valid expiration date
                        </div>
                    </Col>

                    <Col xs={2}
                         style={{display: (this.state.load|| this.state.isBraintreeError) ? "none" : "block"}}
                         className="form-group"
                    >
                        <Form.Label className="hosted-fields--cvv">CVV <span className="text-danger">*</span></Form.Label>
                        <div
                            className={this.getComponentClass("cvv")}
                            id="cvv"
                        />
                        <div className="invalid-feedback">
                            Security code required
                        </div>
                    </Col>
                </Form.Row>

                {
                    (this.state.load || this.state.isBraintreeError) ?
                        ''
                        :
                        <Form.Group controlId="formBasicCheckbox"
                                    className="mt-2">
                            <Form.Label>Would you like to make this card your primary payment method?</Form.Label>
                            <Form.Check
                                custom
                                type="checkbox"
                                name={"isPrimary"}
                                className="text-muted"
                                onChange={this.makeCurrentAsDefaultPayment}
                                defaultValue={this.state.make_default}
                                id={"custom-checkbox3"}
                                label={"Yes, please make the this my primary payment method"}
                            />
                        </Form.Group>
                }
                { 
                    (!this.state.load && this.state.isBraintreeError)?
                    <>
                    <div className="renew-subscription-info mb-3">
                        <h6>
                        Sorry we were unable to load the secure credit card form. Please try reloading the page and contact us through help desk if you are still having problems.
                        </h6>
                    </div>
                    <div>
                        <p className="renew-subscription-text">
                        Still not working? If you are connecting through a VPN please try temporarily pause the service or switch to different network.
                         </p>
                    </div>
                    </>

                    :
                
                <>
                <Button id="btn-add-card"
                        className="btn-primary btn-circle mr-2"
                        type="submit">
                    <i><FontAwesomeIcon icon={faCheck}/></i>
                    Add Credit Card
                </Button>

                <Button className="btn-outline btn-grey btn-circle"
                        onClick={this.props.hideAddCardSection}
                        type="submit">
                    Cancel
                </Button>
                </>
                }
            </Form>
        );
    }
}

export default AddCardComponent;