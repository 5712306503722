import React from 'react';
import Image from '../../components/image';

class Banner extends React.Component {

    render() {
        return (
            <div className="col prelogin-info-col">
                <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle">
                        <h3 className="prelogin-info__title">Transform your business with AI-powered data curation</h3>
                        <div className="prelogin-cards prelogin-pattern">
                            <div className="row justify-content-md-center m-0">
                                <div className="col-sm-6">
                                    <div className="card">
                                        <Image
                                            className="card-img-top"
                                            src="dataEngLogo"
                                        />
                                        <div className="card-body">
                                            <h4>For Data Engineers</h4>
                                            <p className="card-text">Continuously identify data issues during ingestion or in upstream pipelines
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <Image
                                            className="card-img-top"
                                            src="dataStewLogo"
                                        />
                                        <div className="card-body">
                                            <h4>For Data Stewards</h4>
                                            <p className="card-text">Easily comply with regulations
                                            by reporting continuously
                                            updated/maintained data
                                            quality measures</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="card card-translateY">
                                        <Image
                                            className="card-img-top"
                                            src="dataSciLogo"
                                        />
                                        <div className="card-body">
                                            <h4>For Data Scientists</h4>
                                            <p className="card-text">Keep an eagle eye on data drifts in your input datasets and output model
                                            predictions</p>
                                        </div>
                                    </div>
                                    <div className="card card-translateY">
                                        <Image
                                            className="card-img-top"
                                            src="dataDevLogo"
                                         />
                                        <div className="card-body">
                                            <h4>For DevOps and DataOps</h4>
                                            <p className="card-text">Effortlessly compare data consistency across different environments and
                                            databases</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;