import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import NavigationHeader from '../components/navigationHeader';
import { CopyRightsFooter } from '../components/copyrights';
import Image from '../components/image';
import { Table, Col } from 'react-bootstrap';
import QualdoDataTable from '../components/bootstrapTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimesCircle,faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Modal from "react-bootstrap/Modal";
import PBIDashbordViewFullScreen from "./pbireport";
import BasicPortlet from '../components/basicPortlet';
import {CallAPI} from "./pbihandling";
import Load from '../components/loadAction';
import { Link } from 'react-router';
import {toastObj} from "../utils/common_utils";
import Toast from "react-bootstrap/Toast";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {getFeatureAccess} from "../utils/common_utils";
import {IS_NONE_CHECK} from "../utils/constant";
import {browserHistory} from 'react-router';
// import $ from "jquery";

class Report extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isToggleOn: false,
            isModelErrFullscreenMode:false,
            powerbidashboard_data:null,
            powerbidataset_data:null,
            pbi_selected_name:null,
            prowerbireportid:null,
            prowerbireporturl:null,
            powerbistausupdatetoast:'',
            powerbiactionid:null,
            powerbiactionflag:null,
            toastStatus : false
        };

        this.openModal = this.openModal.bind(this)
        this.handleclose = this.handleclose.bind(this)
        this.pireportfullscreen = this.pireportfullscreen.bind(this)
        this.closepireportfullscreen = this.closepireportfullscreen.bind(this)
        this.pireportdatasetfullscreen = this.pireportdatasetfullscreen.bind(this)
        this.pbistatus = this.pbistatus.bind(this)
        this.CallAPI = CallAPI.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
    }

    showToast(status, msg, link) {
        
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }
    }

    closeToast() {
        this.setState({toastStatus: false}, this.getData);
    }

    componentDidUpdate(){
        // if(this.state.powerbistausupdatetoast!==""){
        //      this.showToast("alert-success", "Email Subscriptions Sucessfully Updated", "");
        // }
    }

    getData() {

        // let json =  {"code":200,"datasources":[{"added":1,"environment_name":"Test","integration_id":1,"integration_name":"Sample Admin","workspace_name":"ALL "},{"added":0,"environment_name":"Test","integration_id":1,"integration_name":"Sample Admin","workspace_name":"pbiconnector"}],"datasources_others":[{
        //     "added": 0,
        //     "environment_name": "Test",
        //     "integration_id": 1,
        //     "integration_name": "Sample Admin",
        //     "workspace_name": "pbiconnector"
        // }],"message":"Successfully fetched","tenant_id":"1"}
        // //  let json =  {"code":200,"datasources":[],"datasources_others":[],"message":"Successfully fetched","tenant_id":"1"}
        // this.setState({setupdata:json});
       
        // let newArrayall = json.datasources.filter(function (el) {
        //     return el.added > 0 || el.added === 0 
        // });
        
        // let newArray = json.datasources.filter(function (el) {
        //     return el.added > 0 
        // });
        // if(newArray.length === 0 && newArrayall.length === 0){
        //     $("#powerbi_setup_flow").removeClass("d-none");
        //     $("#powerbi_error_flow").addClass("d-none");
        //     $("#btn_powerbi_configure").show();
        //     $("#btn_powerbi_setup").hide();
        //     $("#powerbi_err_heading").html("No Power BI connection");    
        //     $("#powerbi_err_desc").html("The PowerBI connection does not exist. Using the configure button, set up your Power BI connection to Qualdo™.");
        // }
        // else if(newArray.length === 0 && newArrayall.length > 0){
        //     $("#powerbi_setup_flow").removeClass("d-none");
        //     $("#powerbi_error_flow").addClass("d-none");
        //     $("#btn_powerbi_configure").hide();
        //     $("#btn_powerbi_setup").show();
        //     $("#powerbi_err_heading").html("Setup Power BI connection");    
        //     $("#powerbi_err_desc").html("Set up your Power BI connection to Monitoring Status.");
        // }
        // else {
        //     $("#powerbi_error_flow").removeClass("d-none");
        //     $("#powerbi_setup_flow").addClass("d-none");
        // // this.CallAPI("powerbierrordashboard");
        // // this.CallAPI("powerbierrordataset");
        // // this.CallAPI("token");
        // }
        this.CallAPI("powerbierrconnector");
        this.CallAPI("powerbierrordashboard");
        this.CallAPI("powerbierrordataset");
        this.CallAPI("token");
   }

   componentDidMount() {
    if(localStorage.getItem('setupmessage') === "1"){
        localStorage.setItem('setupmessage',"");
        this.showToast("alert-success", 'Qualdo will create the default workspace name called "QUALDO_PBI_WORKSPACE"', "");
    }
    this.getData();
    // $("#btn_powerbi_configure").hide();
    // $("#btn_powerbi_setup").hide();
   }

    openModal() {
        this.setState({isToggleOn:true});
    }

    handleclose() {
        this.setState({isToggleOn:false});
    }

    pireportfullscreen(row){
        document.body.classList.add("overflow-hidden");
        this.setState({
            isModelErrFullscreenMode:true,
            modelErrFullScreenClassKey:"show",
            pbi_selected_name:row["Name"],
            prowerbireportid:row["Actions"]["pbierrordashboard"]["powerbi_dashboard_id"],
            prowerbireporturl:row["Actions"]["pbierrordashboard"]["embed_url"]
        });
    }

    pireportdatasetfullscreen(row){
        document.body.classList.add("overflow-hidden");
        this.setState({
            isModelErrFullscreenMode:true,
            modelErrFullScreenClassKey:"show",
            pbi_selected_name:row["Dataset Name"],
            prowerbireportid:row["Reports & Dashboards"]["pbierrordataset"]["powerbi_id"],
            prowerbireporturl:row["Reports & Dashboards"]["pbierrordataset"]["embedUrl"]
        });
    }

    closepireportfullscreen(){
        document.body.classList.remove("overflow-hidden");
        this.setState({isModelErrFullscreenMode:false,modelErrFullScreenClassKey:""});
    }

    pbistatus(e){
        let tabledata = this.state.powerbidashboard_data;
        tabledata.data[e.target.id][6]["pbierrordashboard"]["actions"]  = e.currentTarget.checked === true ? "1" : "0";
        this.setState({ powerbidashboard_data:tabledata,
            powerbiactionid:tabledata.data[e.target.id][6]["pbierrordashboard"]["id"],
            powerbiactionflag:tabledata.data[e.target.id][6]["pbierrordashboard"]["actions"] }, () => {                              
            this.CallAPI("savedashboardstatus");
          })
    }
    gotoconfiguration() {
        browserHistory.push("/configure");
    }

    gotosetup() {
        browserHistory.push("/pbi-setup");
    }

    render() {
        const featureAccess = getFeatureAccess();
        let enablePowerBiTab = false;
        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const powerbiTab = featureAccess["powerbi"];
            enablePowerBiTab = powerbiTab === true;
        }
        // enablePowerBiTab = true;
        return (
            enablePowerBiTab?
            <>

            <NavigationHeader page="powerbi" tab="pbi-report" />
                <BreadCrumb icon="tachometer" title="Error Monitoring Status"/>
                <div className="qd-container pt-3 pt-md-4 d-none" id="powerbi_setup_flow">
                    <div className="qd-body no-tabs qd-grid shadow-none bg-transparent">
                        <div id="qd-section__section" className="qd-section qd-grid-item">
                            <div className="row m-0 m-sm-0">
                                <div className="col qd-section__center-focus">
                                    <div className="portlet">
                                        <div className="portlet-body p-3 p-sm-4">
                                              <div className="row">
                                                <div className="col-md-8 offset-md-2">
                                                    <div className="text-center my-5">
                                                        <i className="d-inline-block mb-3">
                                                            <Image src="icon_qd_pbi_configure" />
                                                        </i>
                                                        <h4 className="mb-3" id="powerbi_err_heading">&nbsp;</h4>
                                                        <p className="text-muted mb-4" id="powerbi_err_desc">&nbsp;</p>
                                                        <div className="pt-2"><button id="btn_powerbi_configure" onClick={this.gotoconfiguration} className="d-none btn btn-primary btn-circle">Configure Now <i><FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></i></button></div>
                                                        <div className="pt-2"><button id="btn_powerbi_setup" onClick={this.gotosetup} className="d-none btn btn-primary btn-circle">Setup Power BI Connector<i><FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></i></button></div>
                                                    </div>
                                                </div>
                                             </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                </div>
                <div className="" id="powerbi_error_flow">
                <Tab.Container  defaultActiveKey="relationship" activeKey={this.state.activeTabKey} onSelect={this.changeTab}>
<div className="qd-tab__header">
    <div className="container-fluid">

  
                                

        <div className="qd-tab__header-wrapper">

  
            <div className="qd-tab__header-menu">

          


                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="relationship">
                            <span className="qd-menu__link-text">PowerBI Reports {this.state.showSpinner ? <Image  height="34" width="34" src="spinner"/> : ''}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="completeness">
                            <span className="qd-menu__link-text">PowerBI Dataset {this.state.showSpinner ? <Image  height="34" width="34" src="spinner"/> : ''}</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </div>
    </div>
</div>

<div className="qd-container">
    <div className="qd-body">
        <div className="qd-tab__content" id={this.state.activeTabKey + "Tab"}>
            <Tab.Content>
                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                          eventKey="relationship">
{/* 
<Col md={{ span: 8, offset: 2 }} className="text-center p-5" >
                            <i className="d-inline-block mb-3">
                                <Image src="addLogo" />
                            </i>
                            <h4 className="mb-3">Configure your first dataset</h4>
                            <p>Configure your first dataset with Qualdo to identify Dashboard/Reporter’s issue.</p>
                            <div className="d-flex justify-content-center">
                                 <Button variant="primary" className="mb-2" >Configure Now</Button> 
                                <Link to="/configure"
                                      className="mb-2 btn btn-primary"
                                      onlyActiveOnIndex>Configure Now
                                </Link>
                            </div>
                        </Col> */}

                  
                  <BasicPortlet
                    className="pb-0"
                    id="pbidashboard"
                    title="PowerBi Reports with errors"
                    content={
                        this.state.powerbidashboard_data === null ? 
                         <Load /> : 
                        <QualdoDataTable
                        data={this.state.powerbidashboard_data}
                        pireportfullscreen = {this.pireportfullscreen}
                        pbistatus= {this.pbistatus}
                        showToast={this.showToast}
                        component_name="pbierrordashboard"
                        />
                    }
                />
                
                </Tab.Pane>
                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                          eventKey="completeness">

                    {/* <Col md={{ span: 8, offset: 2 }} className="text-center p-5" >
                            <i className="d-inline-block mb-3">
                                <Image src="addLogo" />
                            </i>
                            <h4 className="mb-3">Configure your first dataset</h4>
                            <p>Configure your first dataset with Qualdo to identify Dashboard/Reporter’s issue.</p>
                            <div className="d-flex justify-content-center">
                                <Button variant="primary" className="mb-2" >Configure Now</Button>
                                <Link to="/configure"
                                      className="mb-2 btn btn-primary"
                                      onlyActiveOnIndex>Configure Now
                                </Link>
                            </div>
                        </Col> */}
                 
                 <BasicPortlet
                    className="pb-0"
                    id="pbidashboard"
                    title="Dataset in PowerBi with errors"
                    content={
                        this.state.powerbidataset_data === null || this.state.powerbidataset_data.data.length === 0  ? 
                       <> <Load /> <div className="text-center font-dark">  Data Profiling is In progress ... </div> </>: 
                        <QualdoDataTable
                        pireportdatasetfullscreen = {this.pireportdatasetfullscreen}
                        data={this.state.powerbidataset_data}
                        showToast={this.showToast}
                        component_name="pbierrordashboard"
                        />
                    }
                />


                </Tab.Pane>
            </Tab.Content>
        </div>
    </div>
</div>
</Tab.Container>
</div>


                <div className="qd-container d-none">
                    <div className="qd-body no-tabs p-4">
                         <Col md={{ span: 8, offset: 2 }} className="text-center p-5" >
                            <i className="d-inline-block mb-3">
                                <Image src="addLogo" />
                            </i>
                            <h4 className="mb-3">Configure your first dataset</h4>
                            <p>Configure your first dataset with Qualdo to identify Dashboard/Reporter’s issue.</p>
                            <div className="d-flex justify-content-center">
                                {/* <Button variant="primary" className="mb-2" >Configure Now</Button> */}
                                <Link to="/configure"
                                      className="mb-2 btn btn-primary"
                                      onlyActiveOnIndex>Configure Now
                                </Link>
                            </div>
                        </Col>
                    
                <>
                <BasicPortlet
                    className="pb-0"
                    id="pbidashboard"
                    title="PowerBi Reports with errors"
                    content={
                        this.state.powerbidashboard_data === null ? 
                         <Load /> : 
                        <QualdoDataTable
                        data={this.state.powerbidashboard_data}
                        pireportfullscreen = {this.pireportfullscreen}
                        pbistatus= {this.pbistatus}
                        showToast={this.showToast}
                        component_name="pbierrordashboard"
                        />
                    }
                />

            <BasicPortlet
                    className="pb-0"
                    id="pbidashboard"
                    title="Dataset in PowerBi with errors"
                    content={
                        this.state.powerbidataset_data === null ? 
                         <Load /> : 
                        <QualdoDataTable
                        pireportdatasetfullscreen = {this.pireportdatasetfullscreen}
                        data={this.state.powerbidataset_data}
                        showToast={this.showToast}
                        component_name="pbierrordashboard"
                        />
                    }
                />
                
                </>
                        <div className="portlet d-none" >
                            <div className="portlet-title">
                                <div className="caption">
                                    <div className="text-uppercase font-dark">
                                        Monitoring Dashboard
                                    </div>
                                </div>
                            </div>

                            <div className="portlet-body">
                                                       
                              <div className="react-bootstrap-table__header">
                      <div className="row">
                        <div className="col-6">
                          <div className="btn-group react-table__length  d-none" role="group">
                            <span>Show</span>
                            <select className="select-sm">
                              <option>5</option>
                              <option>10</option>
                              <option>all</option>
                            </select>
                            <span>items</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="btn-group react-table__filter" role="group">
                            <span>Search:</span>
                            <div className="table-search">
                              <input placeholder="Search" id="searchBox" className="form-control"
                                 type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                                <div className="react-bootstrap-table table-responsive">

                                    
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>S.No
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Dashboard Name
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Quality Status
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Type
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Owner
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Refreshed
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                              
                                                <th>Processing Status
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Last Processing Time
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>Action
                                                        <span className="order">
                                                        <span className="dropdown">
                                                            <span className="caret"></span>
                                                        </span>
                                                        <span className="dropup">
                                                            <span className="caret"></span>
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Star Dashboard-Qualdo</td>
                                                <td>--</td>
                                                <td>Name</td>
                                                <td>qualdo </td>
                                                <td>Wed, 17 Jun 2020 11:48:32 GMT</td>
                                                <td><span className="badge badge-light">Not yet started</span></td>
                                                <td>Wed, 17 Jun 2020 11:48:32 GMT</td>
                                                <td>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>BB-Qualdo</td>
                                                <td>--</td>
                                                <td>Report</td>
                                                <td>qualdo </td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                {/* <td><span className="badge badge-light-success border-0"><FontAwesomeIcon icon={faCheckCircle} /> No Errors</span></td> */}
                                                <td><span className="badge badge-light-success">Completed</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>star_training_data-Qualdo</td>
                                                <td>--</td>
                                                <td>Report</td>
                                                <td>qualdo </td>
                                                <td>Mon, 12 Jan 2022 11:11:10 GMT</td>
                                                <td><span className="badge badge-light-warning">In Progress</span></td>
                                                <td>Mon, 12 Jan 2022 11:11:10 GMT</td>
                                                <td>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>order-report-icon</td>
                                                <td><span className="badge badge-light-danger border-0"><FontAwesomeIcon icon={faTimesCircle} /> <a href="/monitor/error/datasource/datasetid/attribute">Error</a></span></td>
                                                <td>Dashboard</td>
                                                <td>qualdo </td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td><span className="badge badge-light-success">Completed</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Customer Profitability Sample-Qualdo</td>
                                                <td>--</td>
                                                <td>Dashboard</td>
                                                <td>qualdo </td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td><span className="badge badge-light-danger">Failed Authentication</span></td>
                                                <td>Tue, 15 Feb 2022 12:04:32 GMT</td>
                                                <td>
                                                <div className="custom-control custom-switch d-inline-block">
                                                    <input id="dashboard-switch1" className="custom-control-input" type="checkbox" />
                                                    <label className="custom-control-label" for="dashboard-switch1"></label>
                                                </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRightsFooter/>


                {this.state.isModelErrFullscreenMode ?
                    <PBIDashbordViewFullScreen fullScreenClassVal={this.state.modelErrFullScreenClassKey}
                                            powerbititle={this.state.pbi_selected_name}
                                            key={this.state.selectedModelInFullScreen}
                                            prowerbireportid={this.state.prowerbireportid}
                                            prowerbireporturl={this.state.prowerbireporturl}
                                            closepireportfullscreen = {this.closepireportfullscreen}
                    >
                    </PBIDashbordViewFullScreen> : ''
                }



<div id="toastMsg">
                    <Toast className={this.state.toastClass}
                           onClose={() =>  this.closeToast()}
                           show={this.state.toastStatus}
                           delay={3000} autohide>
                        <div className="d-flex align-items-center">

                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon}/>
                                </i>
                                :
                                ''
                            }

                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastheading}</strong>
                                <p>{ this.state.toastMsg }</p>
                            </Toast.Body>
                            <button type="button"
                                    onClick = {this.closeToast}
                                    className="close"
                                    data-dismiss="toast"
                                    aria-label="Close"/>
                        </div>
                    </Toast>
                </div>



<Modal show={this.state.isToggleOn}  style={{display: "block", paddingRight: "15px"}}
centered >
    <Modal.Header closeButton onClick={this.handleclose}>
        <h5 className="modal-title" id="exampleModalLongTitle">
           Mapping
        </h5>
    </Modal.Header>
    <Modal.Body>

    </Modal.Body>
    <Modal.Footer>
    </Modal.Footer>
</Modal>

            </> : 
            <> </>
        );
    }
}

export default Report;