import React from 'react';
import Select from 'react-select';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postAWSSagemaker, validateAWSSagemakerCredentials }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils'


class AWSSagemakerForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateAWSSagemakerCredentials = validateAWSSagemakerCredentials.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.postAWSSagemaker = postAWSSagemaker.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeSecretKeyIcon = this.ChangeSecretKeyIcon.bind(this);
        this.state = {
            check: false,
            options: [],
            job: {},
            hideSubmitButton: false,
            SecretKeyIcon:faEye,
            SecretKeyType:'password',
            awsSagemaker:{
                awsSagemakerName: '',
                endpointName: '',
                awsAccessId: '',
                awsSecretKeyAccess: '',
                awsRegion : '',
                payload : ''
            },
            errors: {
            }
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleCancel() {
        this.setState({check:false});
    }

    componentDidMount() {
        const exAwsSagemakerDetails = this.props.ex_details;
        if (exAwsSagemakerDetails === undefined) {
            return;
        }
        let awsSagemaker = this.state.awsSagemaker;
        awsSagemaker.awsSagemakerName = exAwsSagemakerDetails.pipeline_trigger_name;
        const exConfigurationDetails = exAwsSagemakerDetails.configuration;
        awsSagemaker.endpointName = exConfigurationDetails.url;
        awsSagemaker.awsAccessId = exConfigurationDetails.aws_access_key_id;
        awsSagemaker.awsSecretKeyAccess = exConfigurationDetails.aws_secret_access_key;
        awsSagemaker.awsRegion = exConfigurationDetails.aws_region_name;
        awsSagemaker.payload = exConfigurationDetails.payload;
        this.setState({awsSagemaker:awsSagemaker});
        this.setState({job: {"label": exConfigurationDetails.url, "value": exConfigurationDetails.url}})
    }


    requires_pipeline_update(sagemakerData){
        const exSagemakerDetails = this.props.ex_details;
        if (sagemakerData.pipeline_trigger_name !== exSagemakerDetails.awsSagemakerName) {
            return true;
        }
        const exConfiguration = exSagemakerDetails.configuration;
        const currentConfiguration = sagemakerData.configuration;
        if (currentConfiguration.url !== exConfiguration.url) {
            return true;
        }
        if (currentConfiguration.aws_access_key_id !== exConfiguration.aws_access_key_id) {
            return true;
        }
        if (currentConfiguration.aws_secret_access_key !== exConfiguration.aws_secret_access_key) {
            return true;
        }
        if (currentConfiguration.aws_region_name !== exConfiguration.aws_region_name) {
            return true;
        }
        if (currentConfiguration.payload !== exConfiguration.payload) {
            return true;
        }
        return false;
    }

    ChangeSecretKeyIcon() {
        let state = this.state.SecretKeyIcon;
        if (state.iconName === "eye") {
            this.setState({SecretKeyIcon: faEyeSlash});
            this.setState({SecretKeyType: "text"})
          } else {
            this.setState({SecretKeyIcon: faEye});
            this.setState({SecretKeyType: "password"})
          }
    }

    render() {

        const awsSagemakerSubmitSchema = yup.object({
            awsSagemakerName:yup.string().min(1,"Name must be at least 1 characters").required("Name is a required field").test(
                'Aws name test',
                'invalid Aws Sagemaker name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            endpointName: yup.string().required("Sagemaker Endpoint Name is a required field"),
            payload: yup.string().required("payload is a required field")
        });
        const awsSagemkerValidate = yup.object({
            awsSagemakerName: yup.string().min(1,"Name must be at least 1 characters").required("Name is a required field").test(
                'Aws name test',
                'invalid Aws Sagemaker name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            awsAccessId: yup.string().required("AWS Access id is a required field"),
            awsSecretKeyAccess: yup.string().required("AWS Secret Key Token is a required field"),
            awsRegion: yup.string().required("AWS Region is a required field"),
        });

        if (this.state.check === true) {
            return (
                    <>
                            <Formik
                                validationSchema={awsSagemakerSubmitSchema}
                                onSubmit={this.postAWSSagemaker}
                                initialValues={this.state.awsSagemaker}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid
                            }) => (
                                <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                      <Form.Group controlId="endpointName" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Select Endpoint<span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                 <Select
                                                       name="endpointName"
                                                       filterOption={({label}, query) => label.includes(query)}
                                                       id="SagemakerEndpoint"
                                                       options={this.state.options}
                                                       defaultValue={this.state.job}
                                                       onChange={selectedOption => {
                                                        handleChange("endpointName")(selectedOption.value.toString());
                                                        }}
                                                       isInvalid={errors.endpointName && touched.endpointName}
                                                       placeholder="Select Endpoint"/>
                                                       <ErrorMessage component="div" className="error-text" name="endpointName" />
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="payload" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Payload <span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                 <Form.Control
                                                       type="text"
                                                       name="payload"
                                                       value={values.payload}
                                                       className="form-control"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       isInvalid={errors.payload && touched.payload}
                                                       placeholder="AWS Lambda Payload"/>
                                                 <Form.Control.Feedback type="invalid">
                                                           { touched.payload && errors.payload }
                                                 </Form.Control.Feedback>
                                            </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                            <div className="col-md-5 offset-sm-4">
                                              <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                                </button>
                                            </div>
                                      </Form.Group>
                                </Form>
                                )}
                            </Formik>
                    </>
            );
        } else {
                return (
                             <>
                                    <Formik
                                        validationSchema={awsSagemkerValidate}
                                        onSubmit={this.validateAWSSagemakerCredentials}
                                        initialValues={this.state.awsSagemaker}
                                    >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        isValid
                                    }) => (
                                          <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                            <Form.Group controlId="awsSagemakerName" className="row">
                                                    <Form.Label className="col-md-4 col-form-label">Name <span className="text-danger">*</span> :</Form.Label>
                                                    <div className="col-md-5">
                                                        <Form.Control
                                                            type="text"
                                                            name="awsSagemakerName"
                                                            value={values.awsSagemakerName}
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.awsSagemakerName && touched.awsSagemakerName}
                                                            placeholder="AWS Sagemaker Name"/>
                                                        <Form.Control.Feedback type="invalid">
                                                                { touched.awsSagemakerName && errors.awsSagemakerName }
                                                        </Form.Control.Feedback>
                                                    </div>
                                            </Form.Group>

                                            <Form.Group controlId="url" className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Access Id Key <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          onChange={handleChange}
                                                          name="awsAccessId"
                                                          value={values.awsAccessId}
                                                          className="form-control"
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsAccessId && touched.awsAccessId}
                                                          placeholder="AWS Access Key" />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsAccessId && errors.awsAccessId }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="awsSecretKeyAccess"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Secret Key Access <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faLock}/>
                                                    </i>
                                                    <Form.Control
                                                          type={this.state.SecretKeyType}
                                                          name="awsSecretKeyAccess"
                                                          onChange={handleChange}
                                                          value={values.awsSecretKeyAccess}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsSecretKeyAccess && touched.awsSecretKeyAccess}
                                                          className="form-control"
                                                          placeholder="AWS Secret Key Access"
                                                          autoComplete={this.props.autocomplete}
                                                          />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsSecretKeyAccess && errors.awsSecretKeyAccess }
                                                    </Form.Control.Feedback>
                                                    <i className="security-eye">
                                                        <FontAwesomeIcon onClick={this.ChangeSecretKeyIcon} icon={this.state.SecretKeyIcon} className="security-eye"/>
                                                    </i>
                                                </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="awsRegion"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Region <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          name="awsRegion"
                                                          onChange={handleChange}
                                                          value={values.awsRegion}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsRegion && touched.awsRegion}
                                                          className="form-control"
                                                          placeholder="AWS Region"/>
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsRegion && errors.awsRegion }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <div className="col-md-5 offset-sm-4">
                                                    <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                    <button type="submit" disabled={this.state.hideSubmitButton} onClick={this.handleClick} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                                    </button>
                                                </div>
                                            </Form.Group>

                                         </Form>
                                    )}
                                    </Formik>
                             </>
                );
        }
    }

}


export default AWSSagemakerForm;

