import React from 'react';
import MonitorPortlet from '../components/monitorPortlet';
import _ from 'lodash';

import { getApi, setModelPerformanceData } from "../utils/event_handling";
import {
    // updateChartData,
    getPerformanceChartData,
    filterChartDataForTime,
    getPreviewPerformanceChartData,
    hasValidNumberOfDatasets,
} from "../utils/common_utils";
import Load from '../components/loadAction';
import ModelPerformanceGrid from "./components/modelPerformanceGrid";
import { getMonitorTimeStampMessage, applyDefaultTimeFilter } from "../utils/common_utils";
import { connect } from "react-redux";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import NoModelComponent from "./components/noModelComponent";
import { DISPLAY_DATE_FORMAT, NO_DATASET_NO_DATA_PROFILE } from "../utils/constant";
import { getDateObject } from "../charts/browser_utils";
class ModelPerformance extends React.Component {

    constructor(props) {
        super(props);
        /*this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.setGridData = this.setGridData.bind(this);
        this.state = {
            hideLastProfiledTime: false,
            data: this.setGridData(this.props.monitorModule.performancePreview)
        }*/
        this.getApi = getApi.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.handleModelFilter = this.handleModelFilter.bind(this);
        // this.setAvailableModelsAndChartData = this.setAvailableModelsAndChartData.bind(this);
        // this.setSelectedModelData = this.setSelectedModelData.bind(this);
        this.setModelPerformanceData = setModelPerformanceData.bind(this);
        this.getPerformanceChartData = getPerformanceChartData.bind(this);
        this.getPreviewPerformanceChartData = getPreviewPerformanceChartData.bind(this);
        this.getMonitorTimeStampMessage = getMonitorTimeStampMessage.bind(this);
        this.filterChartDataForTime = filterChartDataForTime.bind(this);

        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        if (props.monitorModule.performancePreview !== null && props.monitorModule.performancePreview !== undefined && props.monitorModule.performancePreview["filterStartDate"] !== null &&
            props.monitorModule.performancePreview["filterStartDate"] !== undefined) {

            const filterStartDateStr = props.monitorModule.performancePreview["filterStartDate"];
            const filterEndDateStr = props.monitorModule.performancePreview["filterEndDate"];
            const startDateObj = getDateObject(filterStartDateStr);
            const endDateObj = getDateObject(filterEndDateStr);
            startDate = moment(startDateObj).utc().startOf('day');
            endDate = moment(endDateObj).utc().endOf('day');


        }
        let groupedChartData = this.getPreviewPerformanceChartData(this.props.monitorModule.performancePreview,
            this.props.monitorModule.mlModelMapping);
        let completeGridData = _.cloneDeep(groupedChartData);
        let newGridData = applyDefaultTimeFilter(groupedChartData, startDate, endDate);
        this.state = {
            startDate: startDate,
            endDate: endDate,
            loadValue: false,
            mlModel: newGridData,
            completeData: completeGridData,
            selectedModel: null,
            closeAction: false,
            lastMonitorTime: null,
            updatedKey: false,
            integrationsMap: this.props.integrationsMap,
            isSuccessMessage: true
        }
    }


    static getDerivedStateFromProps(props, state) {
        // Update integrations map from the props
        if (props.integrationsMap !== state.integrationsMap) {
            return {
                integrationsMap: props.integrationsMap
            }
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.monitorModule.mlModelMapping !== this.props.monitorModule.mlModelMapping || prevProps.monitorModule.performancePreview !== this.props.monitorModule.performancePreview) {
            let groupedChartData = this.getPreviewPerformanceChartData(this.props.monitorModule.performancePreview,
                this.props.monitorModule.mlModelMapping);
            let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
            let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
            if (this.props.monitorModule.performancePreview !== null && this.props.monitorModule.performancePreview !== undefined
                && this.props.monitorModule.performancePreview["filterStartDate"] !== null && this.props.monitorModule.performancePreview["filterStartDate"] !== undefined) {

                const filterStartDateStr = this.props.monitorModule.performancePreview["filterStartDate"];
                const filterEndDateStr = this.props.monitorModule.performancePreview["filterEndDate"];
                const startDateObj = getDateObject(filterStartDateStr);
                const endDateObj = getDateObject(filterEndDateStr)

                startDate = moment(startDateObj).utc().startOf('day');
                endDate = moment(endDateObj).utc().endOf('day');
            }
            let completeGridData = _.cloneDeep(groupedChartData);
            let newGridData = applyDefaultTimeFilter(groupedChartData, startDate, endDate);
            this.setState({
                mlModel: newGridData,
                completeData: completeGridData,
            });
        }
    }

    handleModelFilter(selectedModel) {
        let selectedMLID = selectedModel["value"];
        let mlModelData = _.cloneDeep(this.state.completeData);
        let updatedGroup = mlModelData.filter(x => String(x.mlModelID) === String(selectedMLID));
        let allGroups = [];
        for (let g of updatedGroup) {
            let group = _.cloneDeep(g)
            let charts = group.data;
            if (charts === undefined) {
                continue;
            }
            allGroups.push(group);
            let timeFilteredData = [];
            for (let chart of charts) {
                if (chart.time === undefined || chart.isNonTimeSeries === true) {
                    //timeFilteredData.push(chart);
                    continue;
                }
                let copiedData = _.cloneDeep(chart);
                let result = this.filterChartDataForTime(chart, this.state.startDate, this.state.endDate);
                let boxPlotDataAvailable = false;
                if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                    boxPlotDataAvailable = true;
                    copiedData["boxPlotData"] = result.boxPlotData;
                }
                let updatedTime = result.time;
                if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                    (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
                    continue;
                }
                copiedData["drift_patterns"] = result.drift_patterns;
                if (result.drift_patterns.length > 0) {
                    let latest_drift_index = result.drift_patterns.length - 1;
                    copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
                }
                copiedData["time"] = updatedTime;
                copiedData["drift"] = result.drift;
                timeFilteredData.push(copiedData);
            }
            group["data"] = timeFilteredData;
        }

        if (allGroups.length === 0) {
            allGroups = [{
                "metric": selectedModel["label"],
                "data": []
            }]
        }
        this.setState({ mlModel: allGroups });
        this.setState({ selectedModel: selectedModel });
    }

    changeModel(model, stateKey, title, startDate, endDate) {
        this.setState({ loadValue: true });
        this.handleModelFilter(model);
        this.setState({ loadValue: false });
    }

    changeTime(model, stateKey, title, startDate, endDate) {
        this.setState({ loadValue: true });
    }

    hideContent() {
        this.setState({
            closeAction: true,
        });
    }

    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    handleTimeFilter(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;
        this.setState({ startDate: startDate, endDate: endDate });
        let mlModel = _.cloneDeep(this.state.completeData);

        let selectedModelId = null;
        if (this.state.selectedModel !== undefined && this.state.selectedModel !== null) {
            selectedModelId = String(this.state.selectedModel.value);
        }

        let allGroups = [];
        for (let group of mlModel) {
            let charts = group.data;
            if (charts === undefined) {
                continue;
            }

            if (selectedModelId !== null && String(group.mlModelID) !== selectedModelId) {
                continue;
            }

            allGroups.push(group);
            let timeFilteredData = [];

            for (let chart of charts) {
                if (chart.time === undefined || chart.isNonTimeSeries === true) {
                    timeFilteredData.push(chart);
                    continue;
                }

                let copiedData = _.cloneDeep(chart);
                let result = this.filterChartDataForTime(chart, startDate, endDate);
                let boxPlotDataAvailable = false;
                if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                    boxPlotDataAvailable = true;
                    copiedData["boxPlotData"] = result.boxPlotData;
                }
                let updatedTime = result.time;
                if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                    (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
                    continue;
                }

                copiedData["drift_patterns"] = result.drift_patterns;
                if (result.drift_patterns.length > 0) {
                    let latest_drift_index = result.drift_patterns.length - 1;
                    copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
                }
                copiedData["time"] = updatedTime;
                copiedData["drift"] = result.drift;
                timeFilteredData.push(copiedData);
            }

            group["data"] = timeFilteredData;
        }


        this.setState({ mlModel: allGroups });
        this.setState({ updatedKey: this.state.updatedKey });
    }


    render() {
        let ranges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        const selectedDataSource = this.props.dataModule.selectedDataSource;

        const hasValid = hasValidNumberOfDatasets(this.state.integrationsMap, selectedDataSource);

        const lastProfiledTime = this.props.dataModule.lastProfilingTime[selectedDataSource];
        let lastProfilingTime = lastProfiledTime ?
            `Your data last profiled at ${lastProfiledTime}` : "Model Profiling is In progress ...";

        if (!hasValid) {
            lastProfilingTime = NO_DATASET_NO_DATA_PROFILE;
        }

        let modelProcessStatus = {};
        let noModelConfigured = {}
        let lastProfilingTimeDict = this.props.dataModule.lastProfilingTime
        if (Object.keys(lastProfilingTimeDict).length > 0 && this.props.monitorModule.mlModelDetails !== undefined && this.props.monitorModule.mlModelDetails.length > 0) {
            for (let mlModel of this.props.monitorModule.mlModelDetails) {
                let modelCreatedTime = mlModel["created_time"];
                let convertedTime = getDateObject(modelCreatedTime).getTime();
                let integration_profiled_time = this.props.dataModule.lastProfilingTime[mlModel["integration_id"]];

                const modelCreatedDateObj = getDateObject(modelCreatedTime);
                const modelCreatedAt = moment(modelCreatedDateObj).utc().startOf('day');

                const lptDateObj = getDateObject(integration_profiled_time);
                const lpt = lptDateObj.getTime();

                let noModelConfiguredStatus = moment(this.state.startDate).isBefore(modelCreatedAt) && moment(this.state.endDate).isBefore(modelCreatedAt)
                noModelConfigured[mlModel["ml_model_id"]] = noModelConfiguredStatus;
                let status = false;
                if (convertedTime > lpt) {
                    status = true;
                }
                modelProcessStatus[mlModel["ml_model_id"]] = status;
            }
        }

        if (this.props.monitorModule.mlModelMapping !== undefined && Object.keys(this.props.monitorModule.mlModelMapping).length > 0) {
            for (let mlModel of Object.keys(this.props.monitorModule.mlModelMapping)) {
                if (!(mlModel in modelProcessStatus)) {
                    modelProcessStatus[mlModel] = true;
                }
            }
        }

        return (
            <>
                { Object.keys(lastProfilingTimeDict).length === 0 ? "" :
                    <div className="qd-tab__content-action">
                        <div className="caption">
                            <div className="alert moniker-alert" role="alert">
                                <p className="mb-0">
                                    <strong> {lastProfilingTime}</strong>
                                </p>
                            </div>
                        </div>
                        <div className="actions" >
                            <div className="action-left">
                                <Select
                                    name="models"
                                    filterOption={({ label }, query) => label.includes(query)}
                                    id="chooseModelForModelPerformance"
                                    options={this.props.mlModelOptions}
                                    onChange={this.changeModel}
                                    classNamePrefix='form-control'
                                    placeholder="Choose Model"
                                />
                            </div>
                            <div className="action-right">
                                <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                    startDate={this.state.startDate}
                                    onApply={this.handleTimeFilter}
                                    endDate={this.state.endDate}
                                    ranges={ranges}>
                                    <i>
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </i>
                                    <span className="d-inline-block">
                                        {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                    </span>
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                }

                {
                    (this.state.mlModel.length === 0) ?
                        <NoModelComponent model={true} />
                        :
                        ''
                }
                {
                    this.state.mlModel.length !== 0 && (this.state.loadValue === true || Object.keys(lastProfilingTimeDict).length === 0) ?
                        <MonitorPortlet mlModelOptions={[]}
                            video_url="monitor_model_performance"
                            className="pb-0" title="Model Performance Dashboard"
                            changeModel={this.changeModel}
                            showHelpIcon={true}
                            name={"Model Performance"}
                            bodyClassName="pb-0"
                            showFilter={false}
                            showModelFilter={false}
                            id="mlModelPerformance"
                            content={<Load />}
                        />
                        :
                        this.state.mlModel.map((groupData, index) => {
                            return (<MonitorPortlet key={`mlModelPerformance_${groupData["mlModelID"]}`}
                                srcOption={[]}
                                video_url="monitor_model_performance"
                                className="pb-0"
                                showHelpIcon={true}
                                name={"Model Performance Dashboard"}
                                title={`Performance metrics for '${groupData["metric"]}'`}
                                changeModel={this.changeModel}
                                index={index + 1}
                                showtimeFilter={false}
                                showFilter={false}
                                changeTime={this.changeTime}
                                bodyClassName="pb-0"
                                showModelFilter={false}
                                id={`mlModelPerformance_${groupData["metric"]}`}
                                content={<ModelPerformanceGrid data={groupData}
                                    isInProgress={modelProcessStatus[groupData["mlModelID"]]}
                                    key={`grid_mlModelPerformance_${groupData["mlModelID"]}`}
                                    currentModelID={groupData["mlModelID"]}
                                    currentModel={groupData["metric"]}
                                    dataUpdated={this.state.updatedKey}
                                    completeFullscreenData={this.props.completeFullscreenData}
                                    id="mlModelPerformance"
                                    setMlModel={this.setModelPerformanceData}
                                    showModelFilter={true}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    mlModelOptions={this.props.mlModelOptions}
                                    variant="mlModelPerformanceChartView"
                                    noModelConfigured={noModelConfigured[groupData.mlModelID]}
                                    title="Model Performance Metrics - Dashboard" />}
                            />);
                        })

                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(ModelPerformance);