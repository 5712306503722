import {powerbi_service_ip,console_ip} from "../utils/ip_details";
import {getProtocol,toastObj} from "../utils/common_utils";
import {browserHistory} from 'react-router';
//removeLocalStorageKeys
import $ from "jquery";
function pbi_mapping_dataset_table(input_data){
  let headers=["Dataset Name", "Created Date", "Refreshed Date","Actions"];
  let final_op=[];
  for(let i=0; i < input_data.length; i++) {
      let row=[];
      const pipeline_data = input_data[i];
      row.push({"value": pipeline_data["name"], "type": "td"})
      row.push({ "value": pipeline_data['created_date'].substring(4), "type": "td" });
      row.push({ "value": pipeline_data['refreshed_date'].substring(4), "type": "td" });
      // row.push({ "value": pipeline_data['actions'], "type": "td" });
      pipeline_data["index"] = i;
      row.push({"pbimapping": pipeline_data, "type": "component"})
      final_op.push(row)
  }
  let table_data ={"headers": headers, "data": final_op}
  return table_data;
}  

function pbi_error_dashboard_table(input_data){

    let headers=["Name","Errors", "Quality Check Status","Refreshed in DRX", "Last QC Time",  "Actions"];
     let final_op=[];
     for(let i=0; i < input_data.length; i++) {
         let row=[];
         const pipeline_data = input_data[i];
         row.push({"value": pipeline_data["name"], "type": "td"})
         row.push({ "value": pipeline_data['quality_status'], "type": "td" });
        //  row.push({"value": pipeline_data["type"], "type": "td"})
         row.push({ "value": pipeline_data['processing_status'], "type": "td" });
        //  row.push({ "value": pipeline_data['owner'], "type": "td" });
         row.push({ "value": pipeline_data['refresh_time'].substring(4), "type": "td" });
         row.push({ "value": pipeline_data['processedtime'].substring(4), "type": "td" });
        //  row.push({ "value": pipeline_data['actions'], "type": "td" });
         pipeline_data["index"] = i;
         row.push({"pbierrordashboard": pipeline_data, "type": "component"})
         final_op.push(row)
     }
     let table_data ={"headers": headers, "data": final_op}

     return table_data;
}


function pbi_error_dataset_table(input_data){

    let headers=["Dataset Name", "Dataset Summary","Quality Status","Reports & Dashboards"];
     let final_op=[];
     for(let i=0; i < input_data.length; i++) {
         let row=[];
         const pipeline_data = input_data[i];
         row.push({"value": pipeline_data["name"], "type": "td"})
        //  row.push({"value": pipeline_data["type"], "type": "td"})
         row.push({ "value": pipeline_data['quality_status'], "type": "td" });
         row.push({ "value": pipeline_data['processedtime'], "type": "td" });
         row.push({"pbierrordataset": pipeline_data, "type": "component"})
         final_op.push(row)
     }
     let table_data ={"headers": headers, "data": final_op}
     return table_data;
}

// function call_Update_api(ip, end_point,data, token="") {
//     let user_id = localStorage.getItem('user_id');
//     let team_id = localStorage.getItem('team_id');
//     if (token !== "" && token !== null) {
//         const item = JSON.parse(token);
//         const now = new Date();
//         if (now.getTime() > item.expiry) {
//             removeLocalStorageKeys();
//             window.location = '/';
//         }
//         token = item.value;
//     }
//     let protocol = getProtocol(ip);
//     return fetch(protocol+'//'+ip+'/'+end_point, {
//             method: 'PUT',
//             body: JSON.stringify(data),
//             headers:{ 'Content-Type': 'application/json', 'authToken':token, 'userId':user_id, 'teamId':team_id}
//         }).then((response) => response.json()).then((responseData) => {
//               return responseData;
//         }).catch( error => console.log(end_point, "- failed"));
// }

function call_post_api(ip, end_point,data) {
    let session =  localStorage.getItem('sessionToken');
    //let session;
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (session !== "" && session !== null) {
       const item = JSON.parse(session);
       session = item.value;
    }
    // session = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTI4ODQ4MjIsImV4cCI6MTY1Mjg5MzgyMiwiaXNzIjoic2F0dXJhbTppYW0iLCJlbWFpbCI6ImJlMGY3MTgzMjcxYTVlYjA2MzUyOGE3ZjU3MWRhZWI5NDYwYWM3YmU3MGQ0ZWRhOCIsInVzZXJfaWQiOjkyNiwidGVhbV9pZCI6MTIxOH0.kAV7qIgwWka6R-LL0MAWM0SsS51BXbRcA_PUTRjxqWs';

    // session = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjA3Mjc3MjMsImV4cCI6MTY2MDczNjcyMywiaXNzIjoic2F0dXJhbTppYW0iLCJlbWFpbCI6InByb2R1Y3RfZGV2QHNhdHVyYW0uY29tIiwidXNlcl9pZCI6MSwidGVhbV9pZCI6MX0.y2obyGRkmt-GQNjwS1Mg9JRY10iRMJ0XlfpzpoqFgvs';

    //   data = { 
    //   "tenantId": 1,
    //   "userId":1
    //   };

    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'authToken':session, "api-type": "qualdo_db_auth", 'userId':user_id, 'teamId':team_id }
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.log(end_point, "- failed"));
}


function call_get_api(ip, end_point,data) {
  let session =  localStorage.getItem('sessionToken');
  let user_id = localStorage.getItem('user_id');
  let team_id = localStorage.getItem('team_id');
  if (session !== "" && session !== null) {
      const item = JSON.parse(session);
      // const now = new Date();
      // if (now.getTime() > item.expiry) {
      //     localStorage.removeItem("sessionToken");
      //     window.location = '/';
      // }
      session = item.value;
  }
  let protocol = getProtocol(ip);
  return fetch(protocol+'//'+ip+'/'+end_point, {
          method: 'GET',
          mode: 'cors',
          headers:{ 'data':JSON.stringify(data), 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':session, "userId": user_id, "teamId": team_id}
      }).then((response) => response.json()).then((responseData) => {
          return responseData;
      }).catch( error => console.log(end_point, "- failed"));
}

export function showToast(status, msg, link) {
  this.setState({
      toastStatus: true,
      toastMsg: msg,
      toastClass: toastObj[status]["className"],
      toastLink: link,
      toastIcon: toastObj[status]["icon"],
      toastHeading: toastObj[status]["heading"]
  });
}

export function closeToast() {
  this.setState({toastStatus: false}, "");
}


// function call_iam_get_api(ip, end_point,data, token="") {
//         let session =  localStorage.getItem('sessionToken');
//         let user_id = localStorage.getItem('user_id');
//         let team_id = localStorage.getItem('team_id');
//         if (session !== "" && session !== null) {
//             const item = JSON.parse(session);
//             const now = new Date();
//             // if (now.getTime() > item.expiry) {
//             //     localStorage.removeItem("sessionToken");
//             //     window.location = '/';
//             // }
//             session = item.value;
//         }
//         let protocol = getProtocol(ip);
//         return fetch(protocol+'//'+ip+'/'+end_point, {
//                 method: 'GET',
//                 headers:{ 'data':JSON.stringify(data), 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':session, "userId": user_id, "teamId": team_id}
//             }).then((response) => response.json()).then((responseData) => {
//                 return responseData;
//             }).catch( error => console.log(end_point, "- failed"));
// }

function call_update_api(ip, end_point,data) {
  let session =  localStorage.getItem('sessionToken');
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    let token;
    if (session !== "" && session !== null) {
        const item = JSON.parse(session);
        // const now = new Date();
        // if (now.getTime() > item.expiry) {
        //     removeLocalStorageKeys();
        //     window.location = '/';
        // }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token,
            "userId": user_id, "teamId": team_id}
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.log(end_point, "- failed"));
}

export function CallAPI(api_name,data={}) {
   let session = localStorage.getItem("sessionToken");
   if (session !== "" && session !== null) {
    const item = JSON.parse(session);
    session = item.value;
  }
  switch(api_name) {
    case "powerbi_status_update":
      call_post_api(powerbi_service_ip, 'integration_mapping', data, session).then((response) => {
            if (response.code === 200) {
              return true;
            }   
      });
    break;
    case "token":
      let powerbi_error_dashboard_data = {};
      powerbi_error_dashboard_data.user_qualdo_auth_token = session;
      powerbi_error_dashboard_data.email = localStorage.getItem("emailId")
      call_get_api(powerbi_service_ip, "powerbi_token", powerbi_error_dashboard_data).then((response) =>{
        localStorage.setItem("pb_auth_token",response.token)       
      })
    break;
    case "save_mapping":
    call_post_api(powerbi_service_ip, 'powerbi_mapping',data, session).then((response) => {
          if (response.code === 200) {
            return true;
          } 
    });
    break;
    case "power_user_check":
    call_post_api(powerbi_service_ip, 'power_user_check', this.state.login_track_data, session).then((response) => {
          if (response.code === 200) {
            return true;
          } 
    });
    break;
    case "DRXMappingDataset":
      // let d = {"tenantId":1218, "userId":926, "storage_details":false}
      let d = {"tenantId":localStorage.getItem('team_id'), "userId":localStorage.getItem('user_id'), "storage_details":false}
      call_post_api(console_ip, "power_bi_dataset_info", d , session).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response.code === 200) {

          this.setState({"drx_dataset": response.qualdo_response});
          
          if(this.state.drx_dataset !== null) {
            var envcheck = [];
            let env = this.state.drx_dataset;
            let envoptions = []; 
            var newArray = env.filter(function (el) {
              if(envcheck.indexOf(el.env_id) === -1) {
                    envcheck.push(el.env_id);
                    let envselectobj = {};
                    envselectobj.value = el.env_id;
                    envselectobj.label = el.env_name;
                    envoptions.push(envselectobj);
                    return el;
                  }
                  return el;
            });
            console.log(newArray);
            
            this.setState({mapping_env_options:envoptions});
          }

          
        }
    })
    break;
    case "savedashboard":
    if(this.state.pbiuserselectedlist!==null){
     
      const saveSetupData = { 
      "user_qualdo_auth_token": session,
      "dashboard":this.state.pbiuserselectedlist
      }
      call_post_api(powerbi_service_ip, 'powerbi_setup', saveSetupData, session).then((response) => {
        if (response.code === 200) {
           localStorage.setItem('setupmessage',1);
           browserHistory.push("/pbi-report");  
            // this.props.showToast("alert-success", "Environment added successfully", '')
            // let tenant_id = localStorage.getItem('team_id')
            // let key = `environments_${tenant_id}`
            // sessionStorage.removeItem(key);
            // let env_data = response.env_data;
            // this.setState({showButton: false});
            // this.props.updateEnvironmentList(env_data);
        } else {
            //this.props.showToast("alert-danger", response.message, '')
        }
      });
    }  
    break;
    case "getMappingDataset":

    // var map_data = {"code":200,"datasets":[{"created_date":"Thu, 19 May 2022 07:47:18 GMT","id":3,"name":"test","refreshed_date":"Thu, 19 May 2022 07:47:18 GMT","status":1},{"created_date":"Thu, 19 May 2022 07:47:18 GMT","id":3,"name":"test","refreshed_date":"Thu, 19 May 2022 07:47:18 GMT","status":0}]}
  // let powerbidataset_data_table_data = pbi_mapping_dataset_table(map_data.datasets);
  // this.setState({mapping_dataset:powerbidataset_data_table_data}) 
  // return;;

      let powerbi_error_dashboard_get_data = {};
      powerbi_error_dashboard_get_data.user_qualdo_auth_token = session;
      call_get_api(powerbi_service_ip, "powerbi_mapping_dataset", powerbi_error_dashboard_get_data).then((response) =>{
              if (response === undefined) {
                  this.setState({errorOccurred: true});
              } else if (response.code === 200) {
                let powerbidataset_data_table_data = pbi_mapping_dataset_table(response.datasets);
                this.setState({mapping_dataset:powerbidataset_data_table_data})  
              } else if(response.code === 403) {
                  localStorage.removeItem("sessionToken");
                  window.location = '/';
              } else {
                  this.setState({errorOccurred: true});
              }
          })  
    break;
    case "savedashboardstatus":
    const inputData = { "id":this.state.powerbiactionid,
    "actions": this.state.powerbiactionflag,
    "user_qualdo_auth_token":session
    }
    call_update_api(powerbi_service_ip, "powerbi_error_dashboard", inputData, session).then((response) => {
          if (response === undefined) {
            this.setState({powerbistausupdatetoast: "failed"});
            this.showToast("alert-success", "Dashboard Action Status Not Updated", "");
          } else if (response.code === 200) {
            this.setState({powerbistausupdatetoast: "sucess"});
            this.showToast("alert-success", "Dashboard Action Status Updated", "");
          } else {
            this.setState({powerbistausupdatetoast: "failed"});
            this.showToast("alert-success", "Dashboard Action Status Not Updated", "");
          }
      });
   break;
   case "powerbierrordashboardsetup":
    let powerbierrordashboardsetupdata = {
      "user_qualdo_auth_token":session 
    }
  //   let json1  = {"code":200,"error_report":[{"actions":"1","embed_url":"https://app.powerbi.com/reportEmbed?reportId=046955d1-6b2e-44c7-81a3-f16a86d0c667&appId=7200c4ae-1949-4818-ac6d-d57d46ddd9d7&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d","error_count":null,"id":215,"name":"data_prediction","powerbi_dashboard_id":"a0cee5bc-05d7-4808-8f98-2f2c169c1c76","processedtime":"Tue, 06 Sep 2022 10:16:43 GMT","processing_status":"in-progress","quality_status":null,"redirection":null,"refresh_time":"Tue, 06 Sep 2022 10:19:25 GMT","type":"Report"}],"message":"Sucess"}
  //   let json  = {"code":200,"error_report":[],"message":"Sucess"}
  //   let adashboardissue = [];
  //   $.each(json1.error_report, function(i, item) {
  //     adashboardissue.push(item.powerbi_dashboard_id);
  //   });
  //  // this.setState({alreadyselecteditems:adashboardissue});
  //    this.setState({setupdata:json,alreadyselecteditems:["a0cee5bc-05d7-4808-8f98-2f2c169c1c76"]});
  //   return;

    call_get_api(powerbi_service_ip, "powerbi_error_dashboard", powerbierrordashboardsetupdata).then((response) =>{
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
              let adashboardissue = [];
              $.each(response.error_report, function(i, item) {
                adashboardissue.push(item.powerbi_dashboard_id);
              });
              this.setState({alreadyselecteditems:adashboardissue});
            } else if(response.code === 403) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            } else {
                this.setState({errorOccurred: true});
            }
        })
   break;
   case "powerbierrconnector":
    let getpowerbiconnectorsdataerr = { 
    "tenantId": localStorage.getItem('team_id'),
    "userId":localStorage.getItem('user_id'),
    "email":localStorage.getItem("emailId")
    };
    call_post_api(console_ip, "power_bi_connector_details", getpowerbiconnectorsdataerr).then((response) =>{
     // call_get_api("pbi-qualdo.eastus.cloudapp.azure.com/", "ui-api-service/powerbi_token", getpowerbiconnectorsdata).then((response) =>{
      if (response === undefined) {
          this.setState({errorOccurred: true});
          console.error("Unable to reach console server");
      } else if (response.code === 200) {

        let newArrayall = response.datasources.filter(function (el) {
            return el.added > 0 || el.added === 0 
        });

        let newArray = response.datasources.filter(function (el) {
            return el.added > 0 
        });
        if(newArray.length === 0 && newArrayall.length === 0){
            $("#powerbi_setup_flow").removeClass("d-none");
            $("#powerbi_error_flow").addClass("d-none");
            $("#btn_powerbi_configure").removeClass("d-none");
            $("#btn_powerbi_setup").addClass("d-none");
            $("#powerbi_err_heading").html("No Power BI connection");    
            $("#powerbi_err_desc").html("The PowerBI connection does not exist. Using the configure button, set up your Power BI connection to Qualdo™.");
        }
        else if(newArray.length === 0 && newArrayall.length > 0){
            $("#powerbi_setup_flow").removeClass("d-none");
            $("#powerbi_error_flow").addClass("d-none");
            $("#btn_powerbi_configure").addClass("d-none");
            $("#btn_powerbi_setup").removeClass("d-none");
            $("#powerbi_err_heading").html("Setup Power BI connection");    
            $("#powerbi_err_desc").html("Set up your Power BI connection to Monitoring Status.");
        }
        else {
            $("#powerbi_error_flow").removeClass("d-none");
            $("#powerbi_setup_flow").addClass("d-none");
        }
      } 
    });
   break;   
   case "powerbierrordashboard":
//         let powerbidataset_data = [
//             {
//                 "id": "1",
//                 "powerbi_id":"345483c0-2cf7-4f88-8f26-27dd95ea418f",
//                 "name": "star_prediction",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "Error",
//                 "processingstatus": "Completed",
//                 "refreshtime": "01/04/2022 09:00  GMT",
//                 "processedtime": "01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=345483c0-2cf7-4f88-8f26-27dd95ea418f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "error_count":3,
//                 "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{"value":"27613","label":"star_prediction"}],"attribute":[{"value":"58839","label":"prediction","datasetName": "star_prediction"}]},
//                 "actions":1
//             },
//             {
//                 "id": "2",
//                 "powerbi_id":"f570af22-e820-4a5d-aaaa-3eaf2ac72b44",
//                 "name": "star_response",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "Error",
//                 "processingstatus": "Completed",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f570af22-e820-4a5d-aaaa-3eaf2ac72b44&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "error_count":3,
//                 "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{"value":"27614","label":"star_response"}],"attribute":[{"value":"58844","label":"id","datasetName": "star_response"}]},
//                 "actions":1

//             },
//             {
//                 "id": "3",
//                 "powerbi_id":"b737d562-3edc-4cd8-91f6-27f4790c6caf",
//                 "name": "star_serving",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "Error",
//                 "processingstatus": "Completed",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=b737d562-3edc-4cd8-91f6-27f4790c6caf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "error_count":1,
//                 "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{"value":"27615","label":"star_serving_data"}],"attribute":[{"value":"58848","label":"url","datasetName": "star_serving_data"},{"value":"58849","label":"probability","datasetName": "star_serving_data"}]},
//                 "actions":1
//               },
//             {
//                 "id": "4",
//                 "powerbi_id":"85c95e51-5f48-4ac2-b31f-c784f778c7cf",
//                 "name": "star_training",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "No Error",
//                 "processingstatus": "Completed",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=85c95e51-5f48-4ac2-b31f-c784f778c7cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "error_count":1,
//                 "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{label: "star_training_data", value: "27616"}],"attribute":[]},
//                 "actions":1
//               },
//               {
//                 "id": "5",
//                 "powerbi_id":"0958dd9f-d193-49e2-a519-b5d0c6db1dc6",
//                 "name": "firstbbreport",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "No Error",
//                 "processingstatus": "Mapping Not Found",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=0958dd9f-d193-49e2-a519-b5d0c6db1dc6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "error_count":1,
//                 "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{label: "star_training_data", value: "27616"}],"attribute":[]},
//                 "actions":1
//               }
//             ,
//             {
//                 "id": "6",
//                 "powerbi_id":"2d398b69-4310-4c84-9db4-2be1aca1245a",
//                 "name": "order_report_icon",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "No Error",
//                 "processingstatus": "In Progress",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=2d398b69-4310-4c84-9db4-2be1aca1245a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",                "error_count":1,
//                 "redirection":{"datasource_id":"","datasource_name":"","dataset":[{"id":"test","label":"test"}],"attribute":[{"id":"test","label":"test"}]},
//                 "actions":1
//               }
//             ,
//             {
//                 "id": "6",
//                 "powerbi_id":"85c95e51-5f48-4ac2-b31f-c784f778c7cf",
//                 "name": "Sales & Returns Sample v201912.pbix",
//                 "type": "Report",
//                 "owner": "rajan",
//                 "qualitystatus": "No Error",
//                 "processingstatus": "Failed Authentication",
//                 "refreshtime": "01/04/2022 10:00  GMT",
//                 "processedtime":"01/04/2022 10:00  GMT",
//                 "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=85c95e51-5f48-4ac2-b31f-c784f778c7cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//                 "actions":1
//               }
//             ];    
// let json = {"code":200,"error_report":[{"id":291,"powerbi_dashboard_id":"3a1cca32-65fd-48df-971d-d541f2414454","name":"CustomerProfitabilitySample","type":"Report","quality_status":"Error","processing_status":"Completed","processedtime":"2022-09-1909:55:27","embed_url":"https://app.powerbi.com/reportEmbed?reportId=3a1cca32-65fd-48df-971d-d541f2414454&appId=9eaf0f85-7676-4cf9-97c8-203db0db2215&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19","refresh_time":"2022-09-1909:57:52","actions":1,"error_count":"","redirection":1,"integration_name":"Power_Bi_connectors","workspace_name":"tempname"},{"id":291,"powerbi_dashboard_id":"3a1cca32-65fd-48df-971d-d541f2414454","name":"CustomerProfitabilitySample","type":"Report","quality_status":"error","processing_status":"in-progress","processedtime":"2022-09-1909:55:27","embed_url":"https://app.powerbi.com/reportEmbed?reportId=3a1cca32-65fd-48df-971d-d541f2414454&appId=9eaf0f85-7676-4cf9-97c8-203db0db2215&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19","refresh_time":"2022-09-1909:57:52","actions":1,"error_count":"1","redirection":1,"integration_name":"Power_Bi_connectors","workspace":"tempname"}],"message":"Sucess"}
// // let json = {"code":200,"error_report":[],"message":"Sucess"}
// let powerbidashboard_data_table_data = pbi_error_dashboard_table(json.error_report);
// this.setState({powerbidashboard_data:powerbidashboard_data_table_data})

// if(json.error_report.length === 0){
//   $("#powerbi_setup_flow").removeClass("d-none");
//   $("#powerbi_error_flow").addClass("d-none");
//   $("#btn_powerbi_configure").hide();
//   $("#btn_powerbi_setup").show();
//   $("#powerbi_err_heading").html("Setup Power BI connection");    
//   $("#powerbi_err_desc").html("Set up your Power BI connection to Monitoring Status.");
// }

// return;

    let powerbi_error_dashboard_data2 = {
      "user_qualdo_auth_token":session 
    }
    call_get_api(powerbi_service_ip, "powerbi_error_dashboard", powerbi_error_dashboard_data2).then((response) =>{
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
             let powerbidashboard_data_table_data = pbi_error_dashboard_table(response.error_report);
             if(response.error_report.length === 0){
              $("#powerbi_setup_flow").removeClass("d-none");
              $("#powerbi_error_flow").addClass("d-none");
              $("#btn_powerbi_configure").hide();
              $("#btn_powerbi_setup").show();
              $("#powerbi_err_heading").html("Setup Power BI connection");    
              $("#powerbi_err_desc").html("Set up your Power BI connection to Monitoring Status.");
             }
             this.setState({powerbidashboard_data:powerbidashboard_data_table_data})
            } else if(response.code === 403) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            } else {
                this.setState({errorOccurred: true});
            }
        })
    break;        
    case "powerbierrordataset":
        // call_get_api(ui_service_ip, "pipeline_trigger", data).then((response) =>{
        //     if (response === undefined) {
        //         this.setState({errorOccurred: true});
        //     } else if (response.code === 200) {
        //         let res=pbi_error_dashboard_table(response.pipeline_trigger);
        //         this.setState({pipeline_data:res})
        //         this.setState({pipeline_title:"AVAILABLE PIPELINE TRIGGERS"})
        //     } else if(response.code === 403) {
        //         localStorage.removeItem("sessionToken");
        //         window.location = '/';
        //     } else {
        //         this.setState({errorOccurred: true});
        //     }
        // })

//     let powerbidashboard_data = [
//     {
// 		"id": "1",
//     "powerbi_id":"345483c0-2cf7-4f88-8f26-27dd95ea418f",
// 		"name": "star_prediction",
// 		"type": "csv",
// 		"owner": "rajan",
// 		"qualitystatus": "Error",
// 		"processingstatus": "Completed",
// 		"refreshed_date": "01/04/2022 09:00  GMT",
// 		"processedtime": "01/04/2022 10:00  GMT",
// 		"embedUrl": "https://app.powerbi.com/reportEmbed?reportId=345483c0-2cf7-4f88-8f26-27dd95ea418f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//     "error_count": 3,
//     "redirection": {
// 			"datasource": {
// 				"value": "1340",
// 				"label": "Error"
// 			},
// 			"datasource_name": "Error",
// 			"dataset": [{
// 				"value": "27613",
// 				"label": "star_prediction"
// 			}],
// 			"attribute": [{
// 				"value": "58839",
// 				"label": "prediction",
// 				"datasetName": "star_prediction"
// 			}]
// 		}  
//   },
// 	{
// 		"id": "2",
// 		"name": "star_response",
//     "powerbi_id":"f570af22-e820-4a5d-aaaa-3eaf2ac72b44",
// 		"type": "json",
// 		"owner": "rajan",
// 		"qualitystatus": "Error",
// 		"processingstatus": "Not yet started",
// 		"refreshed_date": "01/04/2022 10:00  GMT",
// 		"processedtime":"01/04/2022 10:00  GMT",
//     "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f570af22-e820-4a5d-aaaa-3eaf2ac72b44&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
// 		"error_count": 3,
// 		"redirection": {
// 			"datasource": {
// 				"value": "1340",
// 				"label": "Error"
// 			},
// 			"datasource_name": "Error",
// 			"dataset": [{
// 				"value": "27614",
// 				"label": "star_response"
// 			}],
// 			"attribute": [{
// 				"value": "58844",
// 				"label": "id",
// 				"datasetName": "star_response"
// 			}]
// 		}
// 	},
//     {
// 		"id": "3",
// 		"name": "star_serving",
//     "powerbi_id":"b737d562-3edc-4cd8-91f6-27f4790c6caf",
// 		"type": "csv",
// 		"owner": "rajan",
// 		"qualitystatus": "Error",
// 		"processingstatus": "In Progress",
// 		"refreshed_date": "01/04/2022 10:00  GMT",
// 		"processedtime":"01/04/2022 10:00  GMT",
//     "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=b737d562-3edc-4cd8-91f6-27f4790c6caf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
// 		"error_count": 1,
// 		"redirection": {
// 			"datasource": {
// 				"value": "1340",
// 				"label": "Error"
// 			},
// 			"datasource_name": "Error",
// 			"dataset": [{
// 				"value": "27615",
// 				"label": "star_serving_data"
// 			}],
// 			"attribute": [{
// 				"value": "58848",
// 				"label": "url",
// 				"datasetName": "star_serving_data"
// 			}, {
// 				"value": "58849",
// 				"label": "probability",
// 				"datasetName": "star_serving_data"
// 			}]
// 		}
//   },
//     {
// 		"id": "4",
//     "powerbi_id":"85c95e51-5f48-4ac2-b31f-c784f778c7cf",
// 		"name": "star_training",
// 		"type": "json",
// 		"owner": "rajan",
// 		"qualitystatus": "No Error",
// 		"processingstatus": "Failed Authentication",
// 		"refreshed_date": "01/04/2022 10:00  GMT",
// 		"processedtime":"01/04/2022 10:00  GMT",
//     "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=85c95e51-5f48-4ac2-b31f-c784f778c7cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
//     "error_count":1,
//     "redirection":{"datasource":{"value":"1340","label":"Error"},"datasource_name":"Error","dataset":[{label: "star_training_data", value: "27616"}],"attribute":[]}	}
//   ];
//   // let powerbidashboard_data = [];
//   let powerbidataset_data_table_data1 = pbi_error_dataset_table(powerbidashboard_data);
//   this.setState({powerbidataset_data:powerbidataset_data_table_data1})  
//   return;      

  
//   let response3 = {
//     "code": 200,
//     "datasets": [
//         {
//             "embed_url": "https://app.powerbi.com/reportEmbed?reportId=e4f6ea5f-4b6e-40e1-bcb4-43ec905dbb5a&groupId=49def082-8ae7-4477-ae3c-360a87716cbf&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19",
//             "id": 29,
//             "name": "serving_dataset_a",
//             "processedtime": "Thu, 29 Sep 2022 09:16:15 GMT",
//             "quality_status": "No Error",
//             "redirection": {'tests':'tsetstes'},
//             "refreshed_date": "Wed, 18 May 2022 06:16:29 GMT",
//             "powerbi_dashboard_id":"12589dde-d290-4c14-8181-ef888908ccd5"
//         }
//     ]
// };
//   let powerbidataset_data_table_data3 = pbi_error_dataset_table(response3.datasets);
//   this.setState({powerbidataset_data:powerbidataset_data_table_data3})  
//   return;




  let powerbi_error_dataser_data = {};
  powerbi_error_dataser_data.user_qualdo_auth_token = session;
    call_get_api(powerbi_service_ip, "powerbi_error_dataset", powerbi_error_dataser_data).then((response) =>{
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
               let powerbidataset_data_table_data = pbi_error_dataset_table(response.datasets);
               this.setState({powerbidataset_data:powerbidataset_data_table_data})  
            } else if(response.code === 403) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            } else {
                this.setState({errorOccurred: true});
            }
        })
    break;

    case "getpowerbiconnectors":
      let getpowerbiconnectorsdata = { 
      "tenantId": localStorage.getItem('team_id'),
      "userId":localStorage.getItem('user_id'),
      "email":localStorage.getItem("emailId")
      };
      call_post_api(console_ip, "power_bi_connector_details", getpowerbiconnectorsdata).then((response) =>{
       // call_get_api("pbi-qualdo.eastus.cloudapp.azure.com/", "ui-api-service/powerbi_token", getpowerbiconnectorsdata).then((response) =>{
        if (response === undefined) {
            this.setState({errorOccurred: true});
            console.error("Unable to reach console server");
        } else if (response.code === 200) {

          let newArray = response.datasources.filter(function (el) {
              return el.added > 0 
            });
            $("#step1").hide();
            if(newArray.length===0){
            $('#step0next').attr('disabled', 'disabled'); 
            }

          // let res = {
          //   "code": 200,
          //   "datasources": [{
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 1,
          //       "environment_name": "dq_reports"
          //     },
          //     {
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 0,
          //       "environment_name": "dq_reports"
          //     },
          //     {
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 0,
          //       "environment_name": "dq_reports"
          //     }
          //   ],
          //   "datasources_others": [{
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 1,
          //       "environment_name": "dq_reports"
          //     },
          //     {
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 1,
          //       "environment_name": "dq_reports"
          //     },
          //     {
          //       "integration_id": 1434,
          //       "integration_name": "testing_pbi1",
          //       "workspace_name": "All workspace",
          //       "added": 1,
          //       "environment_name": "dq_reports"
          //     }
          //   ],
          //   "message": "Successfully fetched",
          //   "tenant_id": "1020"
          // }

          this.setState({
            setupdata: response
            // setupdata: res
          });


        } 
      });
    break;   
    case "powerbisetup":
      // let inputDatapowerbidashboard = { "id":1,
      // "user_qualdo_auth_token":session,
      // "user_pb_auth_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyIsImtpZCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMmIxMjdjN2QtMWEwYi00ZTk4LThlOWUtZDMzM2EyYzc4YmE3LyIsImlhdCI6MTY0OTI0NzIxNSwibmJmIjoxNjQ5MjQ3MjE1LCJleHAiOjE2NDkyNTI4NjgsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJFMlpnWUhENW9QYWhVVGhVVVZqNG1zdWIwZ3ZhTDY5bEZmNU5McnB4MDFCRlB1VzhZeXdBIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6IjdmNTlhNzczLTJlYWYtNDI5Yy1hMDU5LTUwZmM1YmIyOGI0NCIsImFwcGlkYWNyIjoiMiIsImZhbWlseV9uYW1lIjoiUiIsImdpdmVuX25hbWUiOiJSYWphbiIsImlwYWRkciI6IjEyMi4xODYuMjQ3LjUwIiwibmFtZSI6IlJhamFuIiwib2lkIjoiOWQ2NjkwZTUtYmZiYy00NzBkLWFjYjYtNGQyMDJjMzU5ZGYyIiwicHVpZCI6IjEwMDMyMDAxMDU0QjJGQjgiLCJyaCI6IjAuQVNvQWZYd1NLd3NhbUU2T250TXpvc2VMcHdrQUFBQUFBQUFBd0FBQUFBQUFBQUFxQUhjLiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6IkVtYlZUQ3ppQkJIOHRVYzBycWpkUlFDZUxXdVhjcnpvY0hlSGd5MndtTnMiLCJ0aWQiOiIyYjEyN2M3ZC0xYTBiLTRlOTgtOGU5ZS1kMzMzYTJjNzhiYTciLCJ1bmlxdWVfbmFtZSI6InJhamFuLmN0QHNhdHVyYW0uY29tIiwidXBuIjoicmFqYW4uY3RAc2F0dXJhbS5jb20iLCJ1dGkiOiJjYU93ZmthNnBrLU1mV2J4b3ozTEFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.UJxwCUfk0ILlhsfZmYldPU6qQgraXEKFwx8vxkFjmOJLomLmsL9pZsBHrQ0qOstYnMKCJbMnX5H_AdDgxzzuqZYHJUjAfM6zpMjPa326xaMSJM3JiC9Eexrltjs25gNbFEqeD2Gu0Av6pas_428SvF21ye8IUnDHWzdAiySxEdKbKxcUaavk1Fts-o8LmXT31ag0A2yL8qGSN3a3JeADFbFGUxWT2NaxT753bvAXA2hcMT8Cd59vBXYX51Eawp2y2AfDx2-lrXDt4PWoF3kmlNglp4d3hMHMkQMo8Oj4OEYx6AKJWuoTqPnVzQHbhNNqcQH7m0Zg62bg6y-8-NGIJQ"
      // };
    let selectedIntegrtion = [];  
    $('.datasourceselecction').each(function() {
      let obj = {}
      obj.integration_id = $(this).attr("data-integrationid"); 
      obj.integration_name = $(this).attr("data-integration_name");
      obj.workspace_name = $(this).attr("data-workspace_name");
      selectedIntegrtion.push(obj);
    });


      let inputDatapowerbidashboard = { 
      "tenantId":localStorage.getItem('user_id'),
      "userId":localStorage.getItem('team_id'),
      "id":localStorage.getItem('user_id'),
      "user_qualdo_auth_token":session,
      "user_pb_auth_token":session,
      "integrations_list":selectedIntegrtion,
      "email":localStorage.getItem("emailId")
      };

// var response  = {
// 	"code": 200,
//   "dashboard": [],
// 	"report": [{
// 			"datasetId": "f1e9d6df-736f-48a7-8628-288b0a3c1cf4",
// 			"datasetWorkspaceId": "49def082-8ae7-4477-ae3c-360a87716cbf",
// 			"embedUrl": "https://app.powerbi.com/reportEmbed?reportId=4b00bf4d-815f-45ce-b29f-77f8a1788aeb&groupId=49def082-8ae7-4477-ae3c-360a87716cbf&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19",
// 			"id": "4b00bf4d-815f-45ce-b29f-77f8a1788aeb",
// 			"integration_id": "1",
// 			"integration_name": "test1",
// 			"isFromPbix": true,
// 			"isOwnedByMe": true,
// 			"name": "serving_dataset",
// 			"reportType": "PowerBIReport",
// 			"subscriptions": [],
// 			"users": [],
// 			"webUrl": "https://app.powerbi.com/groups/49def082-8ae7-4477-ae3c-360a87716cbf/reports/4b00bf4d-815f-45ce-b29f-77f8a1788aeb",
// 			"workspace": "pbiconnector"
// 		},
// 		{
// 			"datasetId": "9b1bc983-42b0-4dfb-8f69-3118fbea85eb",
// 			"datasetWorkspaceId": "49def082-8ae7-4477-ae3c-360a87716cbf",
// 			"embedUrl": "https://app.powerbi.com/reportEmbed?reportId=e4f6ea5f-4b6e-40e1-bcb4-43ec905dbb5a&groupId=49def082-8ae7-4477-ae3c-360a87716cbf&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBRdWVyeURhdGFTYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFQYWFTRW1iZWQiOnRydWUsInNraXBRdWVyeURhdGFFeHBvcnRUbyI6dHJ1ZX19",
// 			"id": "e4f6ea5f-4b6e-40e1-bcb4-43ec905dbb5a",
// 			"integration_id": "1",
// 			"integration_name": "test1",
// 			"isFromPbix": true,
// 			"isOwnedByMe": true,
// 			"name": "serving_dataset_a",
// 			"reportType": "PowerBIReport",
// 			"subscriptions": [],
// 			"users": [],
// 			"webUrl": "https://app.powerbi.com/groups/49def082-8ae7-4477-ae3c-360a87716cbf/reports/e4f6ea5f-4b6e-40e1-bcb4-43ec905dbb5a",
// 			"workspace": "pbiconnector"
// 		}
// 	]
// }
// var powerbidashboardata = response;
// var objectdashboard =  [];
// var objectdreport = [];
// let setuppagecheckall = '';
// if(!powerbidashboardata.dashboard.hasOwnProperty('error')){


// for(let i=0; i < powerbidashboardata.dashboard.length; i++){
//     let newobjDashboard = {};
//     newobjDashboard.id = powerbidashboardata.dashboard[i]["id"]; 
//     newobjDashboard.name = powerbidashboardata.dashboard[i]["displayName"];  
//     newobjDashboard.embed_url = powerbidashboardata.report[i]["embedUrl"];  
//     newobjDashboard.type = "Dashboard";   
//     newobjDashboard.workspace = powerbidashboardata.report[i]["workspace"];
//     newobj.integration_id = powerbidashboardata.report[i]["integration_id"];   
//     newobj.integration_name = powerbidashboardata.report[i]["integration_name"];   

   
//     if(this.state.alreadyselecteditems.length > 0 && this.state.alreadyselecteditems!==null) {
//       setuppagecheckall = 1;
//     if(this.state.alreadyselecteditems.indexOf(powerbidashboardata.dashboard[i]["id"]) === -1) {
//       newobjDashboard.checkedstatus = false;    
//     }
//     else {
//       newobjDashboard.checkedstatus = true;    
//     }
//   }
//     else {
//       newobjDashboard.checkedstatus = true;    
//     }
//     objectdashboard.push(newobjDashboard);
// }
// }

// if(!powerbidashboardata.report.hasOwnProperty('error')){
// for(var i=0; i < powerbidashboardata.report.length; i++){
//     var newobj = {};
//     newobj.id = powerbidashboardata.report[i]["id"]; 
//     newobj.name = powerbidashboardata.report[i]["name"]; 
//     newobj.embed_url = powerbidashboardata.report[i]["embedUrl"];   
//     newobj.workspace = powerbidashboardata.report[i]["workspace"];   
//     newobj.integration_id = powerbidashboardata.report[i]["integration_id"];   
//     newobj.integration_name = powerbidashboardata.report[i]["integration_name"];   


//     if(this.state.alreadyselecteditems.length > 0 && this.state.alreadyselecteditems!==null) {
//       if(this.state.alreadyselecteditems.indexOf(powerbidashboardata.report[i]["id"]) === -1) {
//         setuppagecheckall = 1;
//         newobj.checkedstatus = false;    
//       }
//       else {
//         newobj.checkedstatus = true;    
//       }
//     }
//       else {
//         newobj.checkedstatus = true;    
//       }    newobj.type = "Report";   
//     objectdreport.push(newobj);
// }
// }
// this.setState({
//     powerbidashboard: objectdashboard,
//     powerbireport:objectdreport,
//     checkall : setuppagecheckall === 1 ? false : true
//   });
//   return;
  

      call_get_api(powerbi_service_ip, "powerbi_setup", inputDatapowerbidashboard).then((response) =>{
            if (response === undefined) {
                this.setState({errorOccurred: true});
                console.error("Unable to reach console server");
            } else if (response.code === 200) {


              var powerbidashboardata = response;
              var objectdashboard =  [];
              var objectdreport = [];
              let setuppagecheckall = '';

             if(!powerbidashboardata.dashboard.hasOwnProperty('error')){


for(let i=0; i < powerbidashboardata.dashboard.length; i++){
    let newobjDashboard = {};
    newobjDashboard.id = powerbidashboardata.dashboard[i]["id"]; 
    newobjDashboard.name = powerbidashboardata.dashboard[i]["displayName"];  
    newobjDashboard.embed_url = powerbidashboardata.report[i]["embedUrl"];  
    newobjDashboard.type = "Dashboard";   
    newobjDashboard.workspace = powerbidashboardata.report[i]["workspace"];   
    if(this.state.alreadyselecteditems.length > 0 && this.state.alreadyselecteditems!==null) {
      setuppagecheckall = 1;
    if(this.state.alreadyselecteditems.indexOf(powerbidashboardata.dashboard[i]["id"]) === -1) {
      newobjDashboard.checkedstatus = false;    
    }
    else {
      newobjDashboard.checkedstatus = true;    
    }
  }
    else {
      newobjDashboard.checkedstatus = true;    
    }
    objectdashboard.push(newobjDashboard);
}

}

if(!powerbidashboardata.report.hasOwnProperty('error')){

for(var i=0; i < powerbidashboardata.report.length; i++){
    var newobj = {};
    newobj.id = powerbidashboardata.report[i]["id"]; 
    newobj.name = powerbidashboardata.report[i]["name"]; 
    newobj.embed_url = powerbidashboardata.report[i]["embedUrl"];   
    newobj.workspace = powerbidashboardata.report[i]["workspace"]; 
    newobj.integration_id = powerbidashboardata.report[i]["integration_id"];   
    newobj.integration_name = powerbidashboardata.report[i]["integration_name"];   
  
    if(this.state.alreadyselecteditems.length > 0 && this.state.alreadyselecteditems!==null) {
      if(this.state.alreadyselecteditems.indexOf(powerbidashboardata.report[i]["id"]) === -1) {
        setuppagecheckall = 1;
        newobj.checkedstatus = false;    
      }
      else {
        newobj.checkedstatus = true;    
      }
    }
      else {
        newobj.checkedstatus = true;    
      }
          newobj.type = "Report";   
    objectdreport.push(newobj);
}
}
this.setState({
    powerbidashboard: objectdashboard,
    powerbireport:objectdreport,
    checkall : setuppagecheckall === 1 ? false : true
  });

} else {
                console.error("Unable to update data limit")
            }
        });
        break;


    /*
    
    let powerbidashboardata  = {
        "dashboard":{
            "@odata.context": "http://wabi-india-central-a-primary-redirect.analysis.windows.net/v1.0/myorg/$metadata#dashboards",
            "value": [
              {
                "id": "a5e6bfb1-ed2c-446b-99dc-0e22a990e95f",
                "displayName": "Sales & Returns Sample v201912.pbix",
                "isReadOnly": false,
                "webUrl": "https://app.powerbi.com/groups/me/dashboards/a5e6bfb1-ed2c-446b-99dc-0e22a990e95f",
                "embedUrl": "https://app.powerbi.com/dashboardEmbed?dashboardId=a5e6bfb1-ed2c-446b-99dc-0e22a990e95f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6ZmFsc2V9fQ%3d%3d",
                "users": [],
                "subscriptions": []
              }
            ]
          },
          "report":
          {
              "@odata.context": "http://wabi-india-central-a-primary-redirect.analysis.windows.net/v1.0/myorg/$metadata#reports",
              "value": [
                {
                  "id": "345483c0-2cf7-4f88-8f26-27dd95ea418f",
                  "reportType": "PowerBIReport",
                  "name": "star_prediction",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/345483c0-2cf7-4f88-8f26-27dd95ea418f",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=345483c0-2cf7-4f88-8f26-27dd95ea418f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "61f55457-a3c3-4379-b373-5d12653584a4",
                  "users": [],
                  "subscriptions": []
                },
                {
                  "id": "f570af22-e820-4a5d-aaaa-3eaf2ac72b44",
                  "reportType": "PowerBIReport",
                  "name": "star_response",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/f570af22-e820-4a5d-aaaa-3eaf2ac72b44",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f570af22-e820-4a5d-aaaa-3eaf2ac72b44&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "61f55457-a3c3-4379-b373-5d12653584a4",
                  "users": [],
                  "subscriptions": []
                },
                {
                  "id": "b737d562-3edc-4cd8-91f6-27f4790c6caf",
                  "reportType": "PowerBIReport",
                  "name": "star_serving",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/b737d562-3edc-4cd8-91f6-27f4790c6caf",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=b737d562-3edc-4cd8-91f6-27f4790c6caf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "61f55457-a3c3-4379-b373-5d12653584a4",
                  "users": [],
                  "subscriptions": []
                },
                {
                  "id": "85c95e51-5f48-4ac2-b31f-c784f778c7cf",
                  "reportType": "PowerBIReport",
                  "name": "star_training",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/85c95e51-5f48-4ac2-b31f-c784f778c7cf",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=85c95e51-5f48-4ac2-b31f-c784f778c7cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "61f55457-a3c3-4379-b373-5d12653584a4",
                  "users": [],
                  "subscriptions": []
                },
                {
                  "id": "2d398b69-4310-4c84-9db4-2be1aca1245a",
                  "reportType": "PowerBIReport",
                  "name": "order-report-icon",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/2d398b69-4310-4c84-9db4-2be1aca1245a",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=2d398b69-4310-4c84-9db4-2be1aca1245a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "5c37ee0d-99d0-4b41-ae6c-003587efcce1",
                  "users": [],
                  "subscriptions": []
                },
                {
                  "id": "0958dd9f-d193-49e2-a519-b5d0c6db1dc6",
                  "reportType": "PowerBIReport",
                  "name": "firstbbreport",
                  "webUrl": "https://app.powerbi.com/groups/me/reports/0958dd9f-d193-49e2-a519-b5d0c6db1dc6",
                  "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=0958dd9f-d193-49e2-a519-b5d0c6db1dc6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
                  "isOwnedByMe": true,
                  "datasetId": "2d2d1f8e-79ce-4b6b-beb5-40e29b0bf231",
                  "users": [],
                  "subscriptions": []
                }
              ]
            }
          };

var objectdashboard =  new Array();
for(var i=0; i < powerbidashboardata.dashboard.value.length; i++){
    var newobj = new Object();
    newobj.id = powerbidashboardata.dashboard.value[i]["id"]; 
    newobj.name = powerbidashboardata.dashboard.value[i]["displayName"];   
    newobj.checkedstatus = true;    
     objectdashboard.push(newobj);
}

var objectdreport = new Array();
for(var i=0; i < powerbidashboardata.report.value.length; i++){
    var newobj = new Object();
    newobj.id = powerbidashboardata.report.value[i]["id"]; 
    newobj.name = powerbidashboardata.report.value[i]["name"];   
    newobj.workspace = powerbidashboardata.value[i]["workspace"];   
    newobj.integration_id = powerbidashboardata.value[i]["integration_id"];   
    newobj.integration_name = powerbidashboardata.value[i]["integration_name"];   

    newobj.checkedstatus = true;    
    objectdreport.push(newobj);
}
this.setState({
    powerbidashboard: objectdashboard,
    powerbireport:objectdreport
  });
  break;

  */
 case "getpowerbidatasetmapping":
  let powerbi_get_dataser_data = {};
  powerbi_get_dataser_data.user_qualdo_auth_token = session;
  powerbi_get_dataser_data.id = data;
  call_get_api(powerbi_service_ip, "getpowerbidatasetmapping", powerbi_get_dataser_data).then((response) =>{
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {


              let editenv = {};
              let editdatasource = {};
              let editdataset = {};
              

              editenv.value = response.datsetlevelmapping[0]["env_id"];
              editenv.label = response.datsetlevelmapping[0]["env_name"];

              editdatasource.value = response.datsetlevelmapping[0]["datasource_id"];
              editdatasource.label = response.datsetlevelmapping[0]["datasource_name"];

              editdataset.value = response.datsetlevelmapping[0]["dataset_id"];
              editdataset.label = response.datsetlevelmapping[0]["dataset_name"];


              var envcheck = [];
              let env = this.state.drx_dataset;
              let envoptions = []; 
              
              env.filter(function (el) {
                if(envcheck.indexOf(el.datasource_id) === -1  && el.env_id===response.datsetlevelmapping[0]["env_id"]) {
  
                      envcheck.push(el.datasource_id);
                      var envselectobj = {};
                      envselectobj.value = el.datasource_id;
                      envselectobj.label = el.datasource_name;
                      envoptions.push(envselectobj);
                      return el;
  
                    }
                    return el;
              });

              envcheck = [];
              let datasetoptions = []; 
              env.filter(function (el) {
                if(envcheck.indexOf(el.dataset_id) === -1  && el.datasource_id===response.datsetlevelmapping[0]["datasource_id"]) {
                      envcheck.push(el.dataset_id);
                      var envselectobj = {};
                      envselectobj.value = el.dataset_id;
                      envselectobj.label = el.dataset_name;
                      datasetoptions.push(envselectobj);
                      return el;
                    }
                    return el;
              });

              let coloptions = [];
              for (let i=0; i<response.attributelevelmapping.length; i++){
                let mappingobj = {};
                mappingobj.qualdo_column_name = response.attributelevelmapping[i]["qualdo_column_name"];
                mappingobj.powerbi_column_name = response.attributelevelmapping[i]["powerbi_column_name"];
                coloptions.push(mappingobj);
              }



              this.setState({
                err_env:0,
                selected_cols:coloptions,
                  err_datset:0,
                  err_datasource:0,
                  authfailmodal:true,
                  selected_env:editenv,
                  mapping_datasource_options:envoptions,
                  mapping_dataset_options:datasetoptions,
                  selected_datasource:editdatasource,
                  selected_dataset:editdataset,
                  model_fotter_button_step:1,
                  editdatsetlevelmappingid:response.datsetlevelmapping[0]["id"],
                  editdatasetid:data,
                });

            } else if(response.code === 403) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            } else {
                this.setState({errorOccurred: true});
            }
        })
    break; 
  default:
  break;
    }
}