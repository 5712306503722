import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPencilAlt, faTimes, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { handleIAMSubmit, getApi }  from  "../../utils/User_handling";
import { toastObj,userValidation } from "../../utils/common_utils";
import Image from "../../components/image";



class Account extends React.Component {
    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showToast =this.showToast.bind(this);
        this.closeToast= this.closeToast.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.state = {
            toastStatus: false,
            first_name:null,
            last_name:null,
            org_name:null,
            user_profile_image:null,
            email:null,
            phoneNo:null,
            disableButton: false,
            firstNameClass:'',
            lastNameClass:'',
            companyNameClass:'',
        };
        this.getData();
    }


        handleChange(event) {
        const { name, value } = event.target;
        if(name === "first_name")
        {
           let result = userValidation(value);
           let lastNameResult = userValidation(this.state.last_name)
           let firstNameClass = result?'':"is-invalid"

           // let companyNameResult = userValidation(this.state.org_name)
           // let companyNameClass = result?'':"is-invalid"

           this.setState({disableButton: !result || !lastNameResult,firstNameClass:firstNameClass})
        }
        else if(name === "last_name")
        {
           let result = userValidation(value);
           let firstNameResult = userValidation(this.state.first_name)
           let lastNameClass = result?'':"is-invalid"

           // let companyNameResult = userValidation(this.state.org_name)
           // let companyNameClass = result?'':"is-invalid"

           this.setState({disableButton: !result || !firstNameResult, lastNameClass:lastNameClass})
        }
        else if(name === "org_name")
        {
           let result = userValidation(value);
           let companyNameClass = result? '' : "is-invalid";

           this.setState({disableButton: !result || !result, companyNameClass:companyNameClass})
        //    this.setState({disableButton: !result})
        }
        this.setState({
            [name]: value
        });
    }

    handleImage = event => {
        let file = event.target.files[0];
        let reader = new FileReader();
        if(file.size > (3*1024*1024)) {
            this.showToast("alert-danger","File is too big! Max file size is 3MB", "");
        } else {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    user_profile_image: reader.result
                });
            };
        }
    }

    getData() {
        this.getApi("user_details");
    }


   closeToast() {
        this.form.reset();
        this.setState({toastStatus:false});
    }

   showToast(status, msg, link) {
       this.setState({disableButton: false});
       this.getData();
       this.setState({
          toastStatus: true,
          toastMsg: msg,
          toastClass: toastObj[status]["className"],
          toastIcon: toastObj[status]["icon"],
          toastHeading: toastObj[status]["heading"]

       });
   }

    render() {
        return (
          <>
             <div id="toastMsg">
                 <Toast className={this.state.toastClass}
                        onClose={() =>  this.closeToast()}
                        show={this.state.toastStatus} delay={3000}
                        autohide>
                      <div className="d-flex align-items-center">
                          {
                              this.state.toastStatus ?
                                  <i className="toast-icon">
                                      <FontAwesomeIcon icon={this.state.toastIcon}/>
                                  </i>
                                  :
                                  ''
                          }

                          <Toast.Body>
                              <strong className="toast-title">{this.state.toastHeading}</strong>
                              <p>{ this.state.toastMsg }</p>
                          </Toast.Body>
                          <button type="button"
                                  onClick = {this.closeToast}
                                  className="close"
                                  data-dismiss="toast"
                                  aria-label="Close"/>
                      </div>
                 </Toast>
             </div>
            <div className="qd-section_content  shadow-sm settings-preferences">
                <div className="qd-section-header">
                    <h4 className="qd-section-header-title">
                        <i>
                            <FontAwesomeIcon icon={faUser}/>
                        </i> User Profile
                    </h4>
                </div>
                <div className="qd-section-body">
                    <div className="row row-sm">
                        <div className="col-md-12">
                            <div className="portlet">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <span className="text-uppercase font-dark">Edit Profile</span>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                 <form className="needs-validation"  ref={form => this.form = form}  method="post"  name='update_user_details' onSubmit={handleIAMSubmit.bind(this)} >
                                    <div action="" className="form-label-right-md">
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label">Profile Picture :</label>
                                            <div className="col-md-6">
                                                <div className="qd-avatar qd-avatar--outline" id="kt_user_avatar">
                                                    <div className="qd-avatar__holder">
                                                    <Image width="110"  height="110"  src="userProfile"/>
                                                    </div>
                                                    <label className="qd-avatar__upload" data-toggle="qd-tooltip" title=""
                                                            data-original-title="Change avatar">
                                                        <i>
                                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                                        </i>

                                                        {/*<input type="file" name="user_profile_image" onChange={this.handleImage}  accept=".png, .jpg, .jpeg"/>*/}
                                                    </label>
                                                    <span className="qd-avatar__cancel" data-toggle="qd-tooltip" title=""
                                                            data-original-title="Cancel avatar">
                                                        <i>
                                                            <FontAwesomeIcon icon={faTimes}/>
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label">First Name :</label>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i>
                                                                <FontAwesomeIcon icon={faUser}/>
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <input type="text" name="first_name" value={this.state.first_name || ''} className = {"form-control " + this.state.firstNameClass}
                                                     onChange={this.handleChange} required/>
                                                    <div className="invalid-feedback">Invalid name</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label">Last Name :</label>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <i>
                                                                <FontAwesomeIcon icon={faUser}/>
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <input type="text" name="last_name" value={this.state.last_name || ''} className={"form-control " + this.state.lastNameClass}
                                                     onChange={this.handleChange} required/>
                                                     <div className="invalid-feedback">Invalid name</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-3 col-form-label">Company Name :</label>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faBuilding}/>
                                                        </div>
                                                    </div>
                                                    <input type="text" name="org_name" value={this.state.org_name || ''} className={"form-control " + this.state.companyNameClass}
                                                    onChange={this.handleChange} required/>
                                                    <div className="invalid-feedback">Invalid name</div>
                                                </div>
                                            </div>
                                        </div>
                                          <div className="text-md-right">
                                        <button type="submit" disabled={this.state.disableButton} className="btn btn-primary btn-circle mr-2">Update User Profile</button>
                                    </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
        );
    }
}

export default Account;