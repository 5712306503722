import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Image from './image';
import ChartBody from './chartBody';
import DefaultMetricsEdit from './defaultMetrics';
import CustomDefaultMetrics from './customDefaultMetrics';
import { setShow } from "../utils/event_handling";
import { checkPermissions, getMetricTypeFromDefinition } from "../utils/common_utils";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { IS_SAAS_EDITION } from '../utils/constant';

let rowCountEdit = false
class Card extends React.Component {
    constructor(props) {
        super(props);
        this.setShow = setShow.bind(this);
        this.handleClose = () => this.setShow("show", false);
        this.handleShow = () => this.setShow("show", true);
        this.handleCloseCustom = () => this.setShow("showFollowModal", false);
        this.updateEnableButton = this.updateEnableButton.bind(this);

        this.toggleConfirmation = this.toggleConfirmation.bind(this);
        this.closeConfirmation = this.closeConfirmation.bind(this);


        let handleFollow = null;
        if (this.props.handleCardFollow !== undefined) {
            handleFollow = this.props.handleCardFollow.bind(this);
        }
        this.handleFollow = handleFollow;

        let tabValue = "";
        if (this.props.ex_metric_details !== undefined && this.props.ex_metric_details !== null) {
            let metricType = getMetricTypeFromDefinition(this.props.ex_metric_details.metrics_definition);
            if (metricType !== null) {
                tabValue = metricType.toUpperCase();
            }
        }

        this.tabValue = tabValue;
        this.state = {
            show: false,
            showFollowModal: false,
            active: null,
            data: this.props.ex_metric_details,
            chartData: this.props.chartData,
            confirmationData: { "checked": false, "metrics_name": "Fill Rate" }

        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ex_metric_details.status_code !== state.data.status_code) {
            let activeStatus = props.ex_metric_details.status_code === 1;
            return {
                active: activeStatus,
                data: props.ex_metric_details
            }
        }

        return null;
    }

    toggleConfirmation(e, name) {
        let confirmationData = {
            checked: e.currentTarget.checked,
            metrics_name: name
        }

        this.setState({ showConfirmation: !this.state.showConfirmation, confirmationData: confirmationData })
        // handleMetricFollow
    }

    closeConfirmation() {
        this.setState({ showConfirmation: !this.state.showConfirmation })
    }

    // Commented the following function since it's usage is deprecated in React.
    // As alternative used 'getDerivedStateFromProps()' function
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.ex_metric_details.status_code !== this.state.data.status_code) {
    //         let activeStatus = nextProps.ex_metric_details.status_code === 1;
    //         this.setState({ active: activeStatus, data:  nextProps.ex_metric_details});
    //     }
    // }



    getEditComponent(componentName) {
        // When we get proper component name, if condition can be changed to switch-case
        let activeStatus = this.state.active;
        if (this.state.active === null && this.props.ex_metric_details !== undefined) {
            activeStatus = this.props.ex_metric_details.status_code === 1;
        }
        if (componentName === undefined) {
            const exMetricDetails = this.props.ex_metric_details;
            let editComponent = (<DefaultMetricsEdit closeModal={this.handleClose}
                metricName={this.props.name}
                updateEnableButton={this.updateEnableButton}
                metricId={this.props.id}
                handleFollow={this.handleFollow}
                activeStatus={activeStatus}
                exMetricDetails={exMetricDetails}
                details={this.props.additionalDetails.datasetInfo}
                showToast={this.props.showToast} />);
            return (
                <Modal id="EditModel" show={this.state.show} size="lg" centered onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Edit the Following {this.tabValue === "DQ" ? "Data Quality" : "Model"} Metric</h5>
                    </Modal.Header>
                    <Modal.Body> {editComponent}
                    </Modal.Body>

                </Modal>
            );
        }
    }

    getFollowButtonComponent(componentName) {
        if (componentName === undefined) {
            if (this.props.metricType === "default" || this.props.metricType === "combined") {
                return (
                    <Modal show={this.state.showFollowModal} size="lg"
                        centered onHide={this.handleCloseCustom}>
                        <Modal.Header closeButton>
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Apply {this.props.name} to </h5>
                        </Modal.Header>
                        <Modal.Body> {
                            <CustomDefaultMetrics closeModal={this.handleCloseCustom}
                                metricName={this.props.name}
                                metricId={this.props.id}
                                exMetricDetails={this.props.ex_metric_details}
                                details={this.props.additionalDetails.datasetInfo}
                                showToast={this.props.showToast} />

                        }
                        </Modal.Body>
                    </Modal>
                );
            }
        }

        return (<>
        </>);
    }

    updateEnableButton(status) {
        this.setState({ active: status });
    }

    render() {
        let activeStatus = this.state.active;

        if (this.state.active === null && this.props.ex_metric_details !== undefined) {
            activeStatus = this.props.ex_metric_details.status_code === 1;
        }

        let hide_edit = this.props.hide_edit
        let disableMetricsAction = checkPermissions('Metrics')

        if (this.props.name !== "Row Count" && activeStatus === true) {
            rowCountEdit = true && !disableMetricsAction
        }

        let cardBackground = this.props.background ? `dashboard-stat ${this.props.background}` : "dashboard-stat dashboard-stat-info";
        let textColor = this.props.textColor ? `title ${this.props.textColor}` : "title text-info";
        let chartLine = this.props.chartLine ? this.props.chartLine : "incremental";
        return (
            <>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className={cardBackground}>
                        <div className="details">
                            <div className="desc-header">
                                <div className={textColor}>
                                    {this.props.name}
                                    {/*
                                    this.props.configuredCount !== false ?
                                        <span className="badge badge-circle badge-dark">{this.props.configuredCount}</span>
                                        : ''
                                    */}
                                </div>
                                {
                                    (this.props.showFollow) ?

                                        <>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" onChange={(e) => this.toggleConfirmation(e, this.props.name)}
                                                    checked={activeStatus}
                                                    className="custom-control-input"
                                                    disabled={this.props.name === "Row Count" ? rowCountEdit : disableMetricsAction}
                                                    id={this.props.name} />
                                                <label className="custom-control-label"
                                                    htmlFor={this.props.name} />
                                            </div>
                                            {/* <span className="app-icon" onClick={this.handleFollow}>
                                                <i className="fas fa-play"><FontAwesomeIcon icon={ activeStatus ? faPlay : faPause }/></i>
                                            </span> */}
                                        </> :
                                        ''
                                }
                            </div>

                            <div className="desc">
                                {
                                    this.props.value !== null && this.props.value !== undefined ?
                                        <span className="desc-value">{this.props.value}</span> :
                                        ''
                                }
                                {
                                    // <span className="desc-percentage">%</span>
                                }

                                {IS_SAAS_EDITION === true &&
                                    <button
                                        disabled={disableMetricsAction ? disableMetricsAction : hide_edit}
                                        type="button"
                                        className="btn btn-sm btn-dark"
                                        name={this.props.name.concat("_edit").toLowerCase().replace(" ", "_")}
                                        onClick={this.handleShow}>
                                        <FontAwesomeIcon icon={faEdit} />
                                        Edit Metric
                                    </button>
                                }
                            </div>
                            {
                                this.props.cardType === undefined ?
                                    <div className="chart">
                                        <Image src={chartLine} alt="" />
                                    </div>
                                    :
                                    <ChartBody
                                        classValue="chart"
                                        id={this.props.idValue}
                                        chartType="cardArea"
                                        style={{ height: "250px" }}
                                        chart_data={this.state.chartData}
                                        colorType={this.props.textColor}
                                    />
                            }

                        </div>
                    </div>
                </div>
                {this.getEditComponent(this.props.cardType)}
                {this.getFollowButtonComponent(this.props.cardType)}

                <Modal show={this.state.showConfirmation} centered>
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger"><FontAwesomeIcon icon={this.state.confirmationData['checked'] === true ? faToggleOn : faToggleOff} /></i>
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">
                                    Are you sure you want to {this.state.confirmationData['checked'] === true ? "enable" : "disable"} {this.state.confirmationData['metrics_name']} ?
                                </h4>
                                {/* Are you sure you want to   ? </h4>  for <strong>" {this.state.metaDataMapping[this.state.confirmationData['data_set_id']]}"</strong> */}
                                <p>This change will affect all the configured datasources{this.state.confirmationData['metrics_name'] === "Row Count" || this.state.confirmationData['metrics_name'] === "Recency" ? " and datasets." : ", datasets and attributes."}</p>
                                {/* <p className="mb-3"><strong className="font-weight-semibold text-blue">{this.state.confirmationData['metrics_name']}</strong> will be {this.state.confirmationData['checked'] === true ? "enabled" : "disabled"} for dataset <strong></strong></p> */}
                                <hr className="w-75" />
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-2 pb-4">
                                <button
                                    type="button"
                                    id="close-btn"
                                    className="btn btn-outline btn-grey btn-circle mr-2"
                                    onClick={this.closeConfirmation}
                                >Cancel</button>

                                <button
                                    type="button"
                                    id="save-btn"
                                    className="btn btn-primary btn-circle mr-2"
                                    onClick={(e) => {
                                        this.handleFollow()
                                        this.closeConfirmation()
                                    }
                                    }
                                >Confirm</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}

export default Card;
