import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {updateFollowAttributeStatus} from "../../utils/event_handling";

class FollowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.updateFollowAttributeStatus = updateFollowAttributeStatus.bind(this);
        let follow_attribute_details = this.props.follow_attribute_details
        let followStatus = follow_attribute_details["follow_status"]
        let followClassName = followStatus ? "follow-btn mr-3 following" : "follow-btn mr-3"

        this.state = {
              followClassName: followClassName,
              followStatus: followStatus,
              isHovered: false,
              follow_attribute_details: follow_attribute_details,
        }
    }

    static getDerivedStateFromProps(props, state) {
      if(props.follow_attribute_details !== state.follow_attribute_details){
         return{
          follow_attribute_details:props.follow_attribute_details
         }
      }
      
      return null;
  }

    componentWillUnmount(){
      this.setState({isHovered:false})
    }

    handleHoverEntry(){
       this.setState({isHovered: true});
    }

    handleHoverExit(){
      this.setState({isHovered: false});
   }

    handleFollowButton(){
         let className = this.state.followClassName === "follow-btn mr-3" ? "follow-btn mr-3 following" : "follow-btn mr-3";
         let followStatus = !this.state.followStatus
         this.setState({followClassName: className, followStatus: followStatus});
         let follow_attribute_details = this.props.follow_attribute_details
         let follow_attributes = this.props.follow_attributes
            let dataSourceId = follow_attribute_details["dataSourceId"]
            let attributeId = follow_attribute_details["attributeId"]
            follow_attributes[dataSourceId][attributeId] = followStatus
            let data = {"integration_id": dataSourceId, "attribute_id": attributeId, "follow_status": followStatus}
            this.updateFollowAttributeStatus(data);
            // this.props.updateFollowAttributes(follow_attributes);
    }

    render(){
       return(<div className={this.state.followClassName} onMouseEnter={this.handleHoverEntry.bind(this)}  
                    onMouseLeave={this.handleHoverExit.bind(this)}>
                                      <span onClick={this.handleFollowButton.bind(this)} className="icon">
                                        <i><FontAwesomeIcon icon={this.state.followStatus ?  (this.state.isHovered ? faTimes : faCheck) : faPlus}/></i>
                                      </span>
                                      <p><span className="follow-btn__text">{this.state.followStatus ? (this.state.isHovered ? "Unfollow" : "Following") : "Follow"}</span> <br/>Attribute</p>
                                    </div>)


    }


}

export default FollowComponent;