import React from 'react';
import ChartBody from '../components/chartBody';
import NoErrorComponent from '../monitor/components/monitorNoResult';
import { DATASOURCE } from "./discoverConstants";

class RelationshipChartView extends React.Component {


     render() {
         // let chartData = this.props.dataModule.relationship[this.props.selectedDataSource];
          let chartData = this.props.chartDatas;
          let hidePartitions = this.props.sectionName === DATASOURCE;
          if (chartData === undefined) {
               return (
                    <NoErrorComponent message={`Relationship not available for the selected ${this.props.sectionName}.`} />
               );
          }

          return (
               <ChartBody
                    id="chartRel"
                    showMetricValues={false}
                    showChartFilter={false}
                    showParititionFilter={false}
                    chartType="hierarchicalEdgeBundlingDiscover"
                    page="profile"
                    video_url=""
                    classValue={"text-center table-responsive"}
                    title="Relationships & Errors within a Datasource"
                    changeTab={this.props.changeTab}
                    chart_data={chartData}
                    metaData={this.props.dataModule.metaData}
                    hidePartitions={hidePartitions}
                    datasetVersion={this.props.dataModule.datasetVersion}
                    metricInfo={this.props.dataModule.info[this.props.selectedDataSource]}
                    filteredValues={this.props.filteredValues}
                    datasetLevelChart={this.props.datasetLevelChart}
                    showZoom={true}
                    dataModule={this.props.dataModule} 
                    selectedDataSource={this.props.selectedDataSource}
                    attributeLevelChart={this.props.attributeLevelChart}
                    allowPartition={this.props.allowPartition}
                    allowAttributes={this.props.allowAttributes}
                    checkedPartition={this.props.checkedPartition}
                    checkedAttributes={this.props.checkedAttributes}
                    relationshipCheck={this.relationshipCheck}
                    relationshipCheckFilter={this.props.allowRelationData === true ? this.props.allowRelationData : this.props.allowRelationsFilter}
                    allowRelationsFilter={this.props.allowRelationsFilter}
                    showAttributeFilter={true}     
               />
          );
     }

}

export default RelationshipChartView;