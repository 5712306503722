import React from 'react';
import AttributeNameComp from "../../components/tableCellComponents/attributeNameComp";

export function renderTableComponents(actionType, headerValue, cell) {
    /**
     * Currently we are using this function for rendering all table components in
     * Profile -> Data page.
     * Example) for table in Relationship tab, Completeness tab and all tabs in that page
     */

    if (headerValue === "Attribute Name") {
        return (
            <AttributeNameComp tooltipPlacement={"right"}
                attributeName={cell}>
            </AttributeNameComp>
        )
    }

    return cell;
}

export function renderskloader(chart, grid) {
    return (
        <>
        <div className="qd-tab__content-action"><div className="caption"><div className="alert moniker-alert sk-loading" role="alert"><p className="mb-0"><strong>&nbsp;</strong></p></div></div><div className="actions flex-shrink-0"><button className="btn btn-light has-dot-symbol"><svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1" viewBox="0 0 24 24" width="24"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><span></span><span>Filters</span></button></div></div>
        <div className="qd-filter-section sk-loading"><div className="qd-filter__content"><div className="qd-filter__header"><h5 className="qd-filter__title">Data Relationship Filter</h5><button type="button" className="close"><span className="sr-only">Close</span></button></div><div className="qd-filter__body"><div className="qd-filter__options"><button className="btn btn-filter-reset shadow-none px-2">&nbsp;</button></div></div></div></div>
        <div className="form-row">
   <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
      <div className="card-flush">
         <div className="card-body">
            <div className="card-details">
               <div className="card-icon sk-loading-img">&nbsp;</div>
               <div className="card-content sk-loading">
                  <h5 className="card-name text-info sk-loading"><span>&nbsp;</span></h5>
                  <p className="card-value sk-loading">&nbsp;</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
      <div className="card-flush">
         <div className="card-body">
            <div className="card-details">
               <div className="card-icon sk-loading-img">&nbsp;</div>
               <div className="card-content sk-loading">
                  <h5 className="card-name text-info sk-loading"><span>&nbsp;</span></h5>
                  <p className="card-value sk-loading">&nbsp;</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
      <div className="card-flush">
         <div className="card-body">
            <div className="card-details">
               <div className="card-icon sk-loading-img">&nbsp;</div>
               <div className="card-content sk-loading">
                  <h5 className="card-name text-info sk-loading"><span>&nbsp;</span></h5>
                  <p className="card-value sk-loading">&nbsp;</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
      <div className="card-flush">
         <div className="card-body">
            <div className="card-details">
               <div className="card-icon sk-loading-img">&nbsp;</div>
               <div className="card-content sk-loading">
                  <h5 className="card-name text-info sk-loading"><span>&nbsp;</span></h5>
                  <p className="card-value sk-loading">&nbsp;</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div className="portlet mb-0 pb-0">
                <div className="portlet-title">
                    <div className="caption"><span className="text-uppercase font-dark">{chart}</span></div>
                    <div className="actions d-flex"></div>
                </div>
                <div className="portlet-body sk-loading" style={{ height: "400px", backgroundColor: "#EEF1F5" }}>
                </div>
            </div>
            <br />
            <div className="portlet mb-0 pb-0 sk-loading">
                <div className="portlet-title">
                    <div className="caption"><span className="text-uppercase font-dark">{grid}</span></div>
                    <div className="actions d-flex"></div>
                </div>
                <div className="portlet-body sk-loading" style={{ height: "400px", backgroundColor: "#EEF1F5" }}>
                </div>
            </div>
</>
    )
}

export function renderskloader_(chart, grid) {
    return (
        <>
            <div className="alert moniker-alert sk-loading" role="alert"><button type="button" className="close close-sm" data-dismiss="alert" aria-label="Close"></button><p className="mb-0"><strong>&nbsp;</strong></p></div>
            <div className="qd-filtered-section sk-loading"><div className="qd-filtered-body"><div className="qd-filtered_chips">
            </div>
                <div className="qd-filtered_button"><button className="btn btn-secondary text-white clickon__filter"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" viewBox="0 0 24 24" width="24"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg> <span>Filters</span></button></div></div></div>
            <div className="form-row">
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"><div className="dashboard-stat dashboard-stat-info sk-loading"></div></div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"><div className="dashboard-stat dashboard-stat-info sk-loading"></div></div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"><div className="dashboard-stat dashboard-stat-info sk-loading"></div></div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"><div className="dashboard-stat dashboard-stat-info sk-loading"></div></div>
            </div>
            <div className="portlet mb-0 pb-0">
                <div className="portlet-title">
                    <div className="caption"><span className="text-uppercase font-dark">{chart}</span></div>
                    <div className="actions d-flex"></div>
                </div>
                <div className="portlet-body sk-loading" style={{ height: "400px", backgroundColor: "#EEF1F5" }}>
                </div>
            </div>
            <br />
            <div className="portlet mb-0 pb-0 sk-loading">
                <div className="portlet-title">
                    <div className="caption"><span className="text-uppercase font-dark">{grid}</span></div>
                    <div className="actions d-flex"></div>
                </div>
                <div className="portlet-body sk-loading" style={{ height: "400px", backgroundColor: "#EEF1F5" }}>
                </div>
            </div>
        </>
    )
}