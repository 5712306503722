import React from "react";
import Image from "../../components/image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import {
    QUALDO_DOCUMENTATION
} from "../../utils/constant";
import { get_document_link } from "../../utils/common_utils";

class NoErrorInPreview extends React.Component {

    constructor(props, context) {
        super(props, context);
        let message = this.props.message;
        if (message === undefined || message === null) {
            message = "No Error found in the latest computation.";
        }

        let footer = this.props.footer;
        if (footer === undefined || footer === null) {
            footer = "Try with a different time period."
        }
        this.state = {
            message: message,
            footer: footer
        }
    }

    render() {
        return (
            this.props.message === null || this.props.NoModelComponent === true?
                <div className="text-center text-muted py-5">
                    <h1><i className="opacity-50"><FontAwesomeIcon icon={faTable} /></i></h1>
                    <h4>No data available</h4>
                    <p>For help, check out the <a href={get_document_link(this.props.component_name)} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                </div>
                :
                <div className="col-md-8 offset-md-2 text-center p-5">
                    <div className="p-5">
                        <i className="d-inline-block mb-3">
                            <Image src="icon_configure" />
                        </i>
                        <h4 className="mb-3">{this.state.message}</h4>
                        <p>{this.state.footer}</p>
                    </div>
                </div>
        );
    }
}

export default NoErrorInPreview;
