import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";


class PlanChangeNotNeeded extends React.Component {

    goToChoosePlan(){
        window.location.href = "/choose-plan";
    }

    render() {
        let message = "Change of Plan Not Allowed";
       return(
        <Modal show={true} centered onHide={this.goToChoosePlan}>
            <Modal.Body>
                <div className="modal__alert">
                <div className="modal_alert-icon"><i className="text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/></i></div>
                    <div className="modal_alert-text">
                        <h4>{message}</h4>
                        <p>{this.props.error_message}</p>
                    </div>
                    <div className="modal_alert-btn-group">
                        <button type="button"
                                onClick={()=>this.props.closePopUp()}
                                className="btn btn-primary btn-circle mr-2">Okay
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        )
    }
}


export default PlanChangeNotNeeded;