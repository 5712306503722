import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDatabase, faLayerGroup, faTag,
         faServer, faAsterisk, faChartPie, faList, faChartArea, faFileAlt, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import Tab from "react-bootstrap/Tab";
import $ from 'jquery';
import Nav from "react-bootstrap/Nav";
import RelationshipChartView from "./relationshipChartView";
import RelationshipListView from "./relationshipListView";
import MetricsCards from "./metricsCards";
import BasicPortlet from '../components/basicPortlet';
import NoErrorComponent from '../monitor/components/monitorNoResult';
import ChartFilterHeader from '../monitor/components/chartFilterHeader';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Load from '../components/loadAction';
import {
  convertDataForListView, getConvertedSize,
  getChartFilterData, getChartTypeForMonitorCharts,
  normalizeChartData, filterBoxPlotData,
  getShortTitleForAttribute
} from "../utils/common_utils";
import {
  getAttributeData,
  getDiscoverPageDataset
} from "../utils/event_handling";
import {
    // RELATIONSHIP_METRICS,
    DQ_CHART_POINTS,
    MIN_MAX_SCALE, REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART, BUTTON_WAIT_TIME, DUPLICATE_METRIC,
    RECENCY_INTERNAL_ID, ROW_COUNT_INTERNAL_ID
} from "../utils/constant";
import Image from '../components/image';
import Summary from "./summary";
import moment from "moment";
import ChartBody from '../components/chartBody';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import QualdoDataTable from '../components/bootstrapTable';
import RcTooltip from 'rc-tooltip';
import {
  METADATA_MAP_VALUE_SEPARATOR,
  FQ_TOOLTIP_PLACEHOLDER,
  datasetNameFromMappedValue,
  normalizeChildDatasetName,
  normalizeAttributeName
} from "../utils/attribute_name_utils";

import {
  ENV_NAME, DATASET, DATASOURCE, ATTRIBUTE
} from "./discoverConstants";

// import {DUMMY_DATA} from "./testData";
import { getDatasForTab,getMetaDatas } from "../utils/dataProfileEventHandling"
//import ProfileCardData from "../profile/data/profileCardData"




const envNodeMap = new Map();
const dSrcNodeMap = new Map();
const dSetMap = new Map();
const attributeNodeMap = new Map();

const renderAttributeTitleToolTip = (props) => (
  <span {...props}>
    {props}
  </span>
);

const allNodeMap =  new Map();
allNodeMap.set(ENV_NAME, envNodeMap);
allNodeMap.set(DATASOURCE, dSrcNodeMap);
allNodeMap.set(DATASET, dSetMap);
allNodeMap.set(ATTRIBUTE, attributeNodeMap);

let activeItem = {
    [ATTRIBUTE]: 'breadcrumb-item',
    [DATASET]: 'breadcrumb-item',
    [DATASOURCE]: 'breadcrumb-item',
    [ENV_NAME]: 'breadcrumb-item',

};

document.onkeydown = checkKey;
function checkKey(e) {
  e = e || window.event;
  let downClick = document.getElementById("down")
  if(downClick === null) {
    return
  }
  if (e.keyCode === 38) {
    // up arrow
    //document.getElementById("up").click();
    start("up");
  }
  else if (e.keyCode === 40) {
    // down arrow

    start("down");
    //document.getElementById("down").click();
  }

}

let funCallFlag;
function start(param) {
if(funCallFlag!=="undefined") {
 clearInterval(funCallFlag);
}
if(param === "up"){

   funCallFlag = setInterval(upButtonClick, BUTTON_WAIT_TIME);
}else{
    funCallFlag = setInterval(downButtonClick, BUTTON_WAIT_TIME);
}

}

function upButtonClick(e){
        document.getElementById("up").click();
        clearInterval(funCallFlag);
}

function downButtonClick(e){
        document.getElementById("down").click();
        clearInterval(funCallFlag);
}

class DiscoverResults extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.getChartFilterData = getChartFilterData.bind(this);
        this.getDiscoverPageDataset = getDiscoverPageDataset.bind(this);
        this.changeData = this.changeData.bind(this);
        this.convertDataForDiscoverCharts = this.convertDataForDiscoverCharts.bind(this);
        this.breadCrumbEvent = this.breadCrumbEvent.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleTreeViewButton = this.handleTreeViewButton.bind(this);
        this.processDatasourceChart = this.processDatasourceChart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeIcon = this.closeIcon.bind(this);
        this.getAttributeData = getAttributeData.bind(this);
        this.getEnvTableData = this.getEnvTableData.bind(this);
        this.applyTimeFilterToChart = this.applyTimeFilterToChart.bind(this);
        this.renderBreadCrumbAttributeContent = this.renderBreadCrumbAttributeContent.bind(this);
        this.reset = this.reset.bind(this);
        this.getDatasForTab = getDatasForTab.bind(this);
        this.getMetaDatas = getMetaDatas.bind(this);

        this.allowPartition = this.allowPartition.bind(this)
        this.allowAttributes = this.allowAttributes.bind(this)
        this.relationshipCheck = this.relationshipCheck.bind(this)

        let initialSelection = this.props.initialSelection
        let nestedData = this.props.nestedData
        let startDateDiscover = moment().subtract(30, 'days');
        let endDateDiscover = moment().endOf('day');
        this.state = {
            activeTabKey: "chartView",
            businessRule: null,
            sectionName: "",
            dataType: null,
            nativeType: null,
            outlier: null,
            envTableData: null,
            recencyData: 0,
            disableDown: false,
            disableUp: true,
            distributionData: null,
            profileDataDiscover: null,
            datasetIds: [],
            startDateDiscover: startDateDiscover,
            endDateDiscover: endDateDiscover,
            newData: {},
            isTreeSearch: false,
            // datasetRow: 0,
            // duplicateRows: 0,
            isRedirect: this.props.isRedirect,
            isFetched: false,
            filteredData: null,
            datasourceCount: 0,
            data: nestedData,
            filterSearch: "",
            subString: this.props.subString,
            lastRefresh: null,
            attributeCount: 0,
            datasetCount: 0,
            isSearchHappened: this.props.isSearchHappened,
            selectedDataSource: null,
            isChartChanged: false,
            driftOnMetricsChart: null,
            selectedData: [],
            isDataChanged: false,
            currentIndex: 0,
            highlightedType: "",
            highlightedId: null,
            showTreeView: false,
            metricsOptions: [],
            envName: initialSelection.envName,
            datasourceName: initialSelection.datasourceName,
            datasetName: initialSelection.datasetName,
            attributeName: initialSelection.attributeName,
            filteredValues: [],
            driftOnMetricsKey: null,
            metricsChartData: [],
            currentMetricOnChart: null,
            selectedMasChart: null,
            existingList:[],
            // profileData: {},
            chartErrors:[],
            datasetLevelChart: [],
            attributeLevelChart: [],
            checkedPartition: false,
            checkedAttributes: true,
            filter_data_set_id:null,
        }
    }
  allowPartition() {
        this.setState({ checkedPartition: !this.state.checkedPartition })
  }
  allowAttributes() {
        this.setState({ checkedAttributes: !this.state.checkedAttributes })
  }
relationshipCheck(value){
      if(value==="All"){
          this.setState({allowRelationData:false, allowRelationsFilter:"All"})
      }
      else{
          this.setState({allowRelationData:true, allowRelationsFilter:value})

      }
}
    changeTab(tab) {
        this.setState({activeTabKey: tab});
    }
    handleLoad(value) {
        this.setState({loadValue:value});
    }
    findAverage(inputArr) {
        if (inputArr !== null && inputArr !== undefined){
            return inputArr.reduce((a, b) => a + b) / inputArr.length;
        } else{
            return 0;
        }
    }

  renderBreadCrumbAttributeContent(activeItem) {
    let reqAttributeName = this.state.attributeName;

    let shortNameData = getShortTitleForAttribute(reqAttributeName);
    let reqAttrText = shortNameData["requiredName"];
    let fqTooltipContent = shortNameData["fqTooltipContent"];

    if (fqTooltipContent === null) {
      // Rendering without tooltip content
      return (
        <li id={ATTRIBUTE}
          className={activeItem[ATTRIBUTE]}
          onClick={this.breadCrumbEvent} aria-current="page">
          <i><FontAwesomeIcon icon={faTag} /></i>
          <span id={ATTRIBUTE}>{reqAttrText}</span>
        </li>
      )
    }

    // Rendering with tooltip content
    return (
      <li id={ATTRIBUTE}
        className={activeItem[ATTRIBUTE]}
        onClick={this.breadCrumbEvent} aria-current="page">

        <RcTooltip placement="top" key={fqTooltipContent}
          overlay={renderAttributeTitleToolTip(fqTooltipContent)}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
          <i><FontAwesomeIcon icon={faTag} /></i>
        </RcTooltip>

        <RcTooltip placement="top"
          overlay={renderAttributeTitleToolTip(fqTooltipContent)}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
          <span>{FQ_TOOLTIP_PLACEHOLDER}</span>
        </RcTooltip>

        <span id={ATTRIBUTE}>{reqAttrText}</span>

      </li>
    )
  }

    getEnvTableData(envData, datasetRes){
            let headers = ["Datasource Name", "Dataset Name", "Attribute Count", "Dataset Size", "Last Refreshed Time"];
            let finalOp = [];
            let datasourceData = Object.values(envData)
            for (let i = 0; i < datasourceData.length; i++) {
                let datasourceName = datasourceData[i]["title"]
                let datasetData = Object.values(datasourceData[i]["childNodes"])
                for(let j=0;j<datasetData.length;j++){
                    let row = [];
                    let d = datasetData[j];
                    let datasetName = d["title"]
                    let datasetId = d["id"]
                    let lastRefresh = this.props.datasetRefreshInfo[Number(datasetId)];

                    let sizeData = this.props.dataModule.dataSize.filter(x=>x.data_set_id === Number(datasetId))
                    let dataSize = 0
                    if(sizeData.length > 0) {
                      dataSize = getConvertedSize(sizeData[0]["size"])
                    }

                    let attributeCount = Object.values(d["childNodes"]).length

                    row.push({ "value": datasourceName, "type": "td" });
                    row.push({ "value": datasetName, "type": "td" });
                    row.push({ "value": attributeCount, "type": "td" });
                    row.push({ "value":dataSize, "type": "td" });
                    row.push({ "value":lastRefresh, "type": "td" });
                    finalOp.push(row);
                }
            }
            let tableData = { "headers": headers, "data": finalOp };

            return tableData;

    }

    applyTimeFilterToChart(currentData, startDate, endDate, chartType, isPatternNotNeeded){
      // this.state.startDate, this.state.endDate

      let metricName;
      if (currentData !== null && currentData !== undefined){
          metricName = currentData["name"]
          this.setState({currentMetricOnChart: currentData["name"]})
      }
      else{
        metricName = this.state.currentMetricOnChart
      }
      this.changeSource(metricName, "", "", startDate, endDate)

    }

    changeSource(metricName, stateKey, title, startDate=null, endDate=null) {
        let finalData = null
        let filteredData = null
        let isTimeFilter = false

        if(startDate === null) {
          startDate = this.state.startDateDiscover;
        }
        else{
          isTimeFilter = true
        }

        if (endDate === null) {
          endDate = this.state.endDateDiscover;
        }

        if (this.state.selectedData !== null && [DATASET, ATTRIBUTE].includes(this.state.sectionName)) {
            for(let row of this.state.selectedData) {
                if (row["name"] === metricName) {
                    finalData = row;
                    finalData['data_source_name'] = this.state.datasourceName;
                    finalData["datasetName"] = this.state.datasetName;
                    finalData['attribute_name'] = this.state.attributeName;
                    finalData['error_percentage'] = this.findAverage().toFixed(2);
                    if (finalData["name"] === "Data Drift") {
                        finalData["boxPlotData"] = filterBoxPlotData(finalData["boxPlotData"])
                    }

                    filteredData = getChartFilterData(finalData, startDate, endDate)

                    if(filteredData !== undefined && filteredData !== null && filteredData.time.length !== undefined) {
                        finalData = filteredData;
                    }

                    if (isTimeFilter === true){
                      finalData = filteredData
                    }

                    break;
                }
            }
        }else if(this.state.sectionName === DATASOURCE){
              let reqName = metricName;

              if (isTimeFilter) {
                reqName = this.state.selectedMasChart
              } else {
                this.setState({ selectedMasChart: metricName,filter_data_set_id:null});
              }

              let dataFromProps = this.props.datasourceData[this.state.selectedDataSource][reqName]
              let data = _.cloneDeep(dataFromProps);
          let result = this.processDatasourceChart(data, this.state.selectedDataSource,
            startDate, endDate);
              finalData = result["finalData"]
              filteredData = result["filteredData"]
              if (isTimeFilter === true){
                finalData = filteredData
              }

        }

        let isChartChanged = !this.state.isChartChanged
        const metricKey = "attribute_" + metricName + String(isChartChanged);

        this.setState({driftOnMetricsChart: finalData, filteredData: filteredData,
                    loadValue:false, driftOnMetricsKey: metricKey});

          this.setState({isChartChanged: !this.state.isChartChanged});
    }

    getDataSourceLastProfiletime(pmDataSrouceId){
      let porfileTimeData = this.props.porfileTimeData;
      if(porfileTimeData!=="" && porfileTimeData !== undefined && porfileTimeData!==""){
        let result = porfileTimeData.filter(data => parseInt(data.integration_id) === parseInt(pmDataSrouceId));
        if(result.length > 0){
          return result[0]["last_processed_time"];
        }
      }
    }
    componentDidMount(){
      this.getMetaDatas()


//alert(JSON.stringify(this.props.porfileTimeData))
    }
    componentDidUpdate(prevProps) {

      if(JSON.stringify(prevProps.initialSelection)!==JSON.stringify(this.props.initialSelection)){

        let initialDataFilter = this.props.initialSelection;
        if(initialDataFilter.type==="Datasource"){
        let initialDataFilter = this.props.initialSelection;
        if(initialDataFilter.type==="Datasource"){
          this.changeData(
            initialDataFilter.envName,
            initialDataFilter.datasourceName,
            "",
            "",
            initialDataFilter.envId,
            initialDataFilter.datasourceId,
            null, null, initialDataFilter.datasourceId, "DATASOURCE");
        }
        }
        else if(initialDataFilter.type==="Dataset"){
          this.changeData(
            initialDataFilter.envName,
            initialDataFilter.datasourceName,
            initialDataFilter.datasetName, "",
            initialDataFilter.envId,
            initialDataFilter.datasourceId,
            initialDataFilter.datasetId,
            null,
            initialDataFilter.datasetId,
            "DATASET");
        }
      }

      if(prevProps.nestedData !== this.props.nestedData) {
        let initialSelection = this.props.initialSelection
        let envId = initialSelection.envId
        let datasourceId = initialSelection.datasourceId
        let datasetId = initialSelection.datasetId
        let duplicateRows = this.props.duplicateRows;
        let attributeId = initialSelection.attributeId
        let filteredValues = [];
        let selectedData = []
        // eslint-disable-next-line
        let metricData = []
        let metricsOptions = []
        let chartData = this.props.metricData;
        let sectionName = ""
        let driftOnMetricsChart = null
        let filteredData = null
        let driftOnMetricsKey = null
        let highlightedType = ""
        let highlightedId = null
        let prefix;
        let datasetIds = []
        let attributeCount = 0
        let datasetCount = 0
        let datasourceCount = 0
        let nestedData = this.props.nestedData
        let lastRefresh;
        let datasetRow = this.props.datasetRow;
        let envTableData = null
        let recencyData = 0


        let keys = ["apiFetchOver"]
        let chart_data_keys = JSON.stringify(Object.keys(chartData))
        let keys_ = JSON.stringify(keys)

        sectionName = initialSelection.type;
        if (sectionName === ATTRIBUTE) {
          this.setState({isFetched: false,distributionData: null,profileDataDiscover:null});
          this.getAttributeData(datasetId, initialSelection.attributeId);
        }


        if (sectionName === DATASET) {
          // this.getDiscoverPageDataset(datasetId, datasetName, "");
          lastRefresh = this.props.datasetRefreshInfo[Number(datasetId)];
        }

        if(Object.keys(initialSelection).length !== 0 && chart_data_keys!==keys_) {
           envId = initialSelection.envId
           datasourceId = initialSelection.datasourceId
           highlightedType = initialSelection.type
           datasetId = initialSelection.datasetId
           attributeId = initialSelection.attributeId
           if(attributeId !== null && attributeId !== undefined) {
               prefix = `${envId}.${datasourceId}.${datasetId}.${attributeId}`
               if (this.state.metricsChartData !== null && this.state.metricsChartData !== undefined){
                   metricData = this.state.metricsChartData
               }
               sectionName = ATTRIBUTE

               highlightedId = attributeId
            }else if(datasetId !== null && datasetId !== undefined) {
                prefix = `${envId}.${datasourceId}.${datasetId}`;

                if (chartData.dataset_level_data !== undefined && chartData.dataset_level_data[datasetId] !== undefined){
                    metricData = Object.values(chartData.dataset_level_data[datasetId])
                }
                sectionName = DATASET;
                datasetIds = [datasetId]
                highlightedId = datasetId
                attributeCount = Object.values(nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"]).length
                let duplicateInfo = this.props.dataModule.info[datasourceId][prefix]
                if(duplicateInfo !== undefined ){
                  let duplicateRowsList = duplicateInfo[`${DUPLICATE_METRIC}_duplicate_row`]
                  if(duplicateRowsList) {
                     duplicateRows = duplicateRowsList[duplicateRowsList.length-1]
                  }

                  let datasetRowsList = duplicateInfo[`${ROW_COUNT_INTERNAL_ID}_time_value`]
                  if(datasetRowsList) {
                    datasetRow = datasetRowsList[datasetRowsList.length-1]
                  }

                }
            }else if(datasourceId !== null && datasourceId !== undefined) {
                prefix = `${envId}.${datasourceId}`;
                sectionName = DATASOURCE
                highlightedId = datasourceId
                datasetIds = Object.keys(nestedData[envId]["childNodes"][datasourceId]["childNodes"])
                datasetCount = datasetIds.length
                for(const key of Object.keys(this.props.datasourceData[datasourceId])){
                   if(key.includes("Data")){
                     metricsOptions.push({"label": key, "value": key})
                   }

                }
                if(metricsOptions.length > 0) {
                  let currMasName = metricsOptions[0].value;
                    this.setState({ selectedMasChart: currMasName });
                    let dataFromProps = this.props.datasourceData[datasourceId][currMasName]
                    let data = _.cloneDeep(dataFromProps);
                    let result = this.processDatasourceChart(data, datasourceId);
                    driftOnMetricsChart = result["finalData"]
                    filteredData = result["filteredData"]
                }

                let recencyInfo = this.props.dataModule.recencyDetails[datasourceId]
                for(let value of Object.values(recencyInfo)){
                      let recencyFiltered = value
                      let recencyList = recencyFiltered[`${RECENCY_INTERNAL_ID}_time_value`]
                      let latestRecency = 0
                      if(recencyList) {
                         latestRecency = recencyList[recencyList.length-1]
                      }
                      if(latestRecency > 30){
                        recencyData = recencyData + 1
                      }
               }
                let duplicateInfo = this.props.dataModule.info[datasourceId]
                for(let value of Object.values(duplicateInfo)){
                      let duplicateFiltered = value
                      let duplicateRowsList = duplicateFiltered[`${DUPLICATE_METRIC}_duplicate_row`]
                      if(duplicateRowsList) {
                         duplicateRows = duplicateRowsList[duplicateRowsList.length-1] + duplicateRows
                      }
               }

            }else {
                prefix = `${envId}`;
                sectionName = ENV_NAME;
                datasetCount = 0
                highlightedId = envId
                let total = nestedData[envId]["childNodes"]
                datasourceCount = Object.keys(total).length
                for(const value of Object.values(total)) {
                   datasetIds = datasetIds.concat(Object.keys(value["childNodes"]))
                   datasetCount = datasetCount + datasetIds.length

                }
                envTableData = this.getEnvTableData(nestedData[envId]["childNodes"], null)
        }
//         metricData = convertDataForListView(metricData, initialSelection.datasetName, initialSelection.datasourceName)
//         for(let i=0;i<metricData.length;i++) {
//            if(metricData[i]["name"] === "Row Count") {
//              datasetRow = metricData[i]["value"][metricData[i]["value"].length - 1]
//            }
// //           if(metricData[i]["hasDrift"] === false) {
// //             continue
// //           }
//            selectedData.push(metricData[i]);
//            metricsOptions.push({"label": metricData[i]["name"], "value": metricData[i]["name"]})
//         }
//         if(selectedData.length > 0) {
//           driftOnMetricsChart = selectedData[0]
//           filteredData = getChartFilterData(driftOnMetricsChart, this.state.startDateDiscover, this.state.endDateDiscover)
//           driftOnMetricsKey = sectionName + driftOnMetricsChart["name"]
//         }
        filteredValues.push(prefix)

        }

        // ---- Following code is new code. Above code is commented for reference
        if (sectionName === ENV_NAME && nestedData !== undefined && nestedData !== null) {
          prefix = `${envId}`;
          datasetCount = 0;
          highlightedId = envId;

          if (nestedData[envId] !== undefined) {
            let total = nestedData[envId]["childNodes"]
            datasourceCount = Object.keys(total).length
            for (const value of Object.values(total)) {
              datasetIds = datasetIds.concat(Object.keys(value["childNodes"]))
              datasetCount = datasetCount + datasetIds.length
            }

            envTableData = this.getEnvTableData(nestedData[envId]["childNodes"], null)
          }

        }

        if (sectionName === DATASOURCE && nestedData !== undefined && nestedData !== null) {
          prefix = `${envId}.${datasourceId}`;
          highlightedId = datasourceId;
          datasetIds = Object.keys(nestedData[envId]["childNodes"][datasourceId]["childNodes"])
          datasetCount = datasetIds.length
          for(const key of Object.keys(this.props.datasourceData[datasourceId])){
             if(key.includes("Data")){
               metricsOptions.push({"label": key, "value": key})
             }

          }
          if(metricsOptions.length > 0) {
              let currMasName = metricsOptions[0].value;
              this.setState({ selectedMasChart: currMasName });
              let dataFromProps = this.props.datasourceData[datasourceId][currMasName]
              let data = _.cloneDeep(dataFromProps);
              let result = this.processDatasourceChart(data, datasourceId);
              driftOnMetricsChart = result["finalData"]
              filteredData = result["filteredData"]
          }

          let recencyInfo = this.props.dataModule.recencyDetails[datasourceId]
          for(let value of Object.values(recencyInfo)){
                let recencyFiltered = value
                let recencyList = recencyFiltered[`${RECENCY_INTERNAL_ID}_time_value`]
                let latestRecency = 0
                if(recencyList) {
                   latestRecency = recencyList[recencyList.length-1]
                }
                if(latestRecency > 30){
                  recencyData = recencyData + 1
                }
         }
        //  let duplicateInfo = this.props.dataModule.info[datasourceId]
        //  for(let value of Object.values(duplicateInfo)){
        //         let duplicateFiltered = value
        //         let duplicateRowsList = duplicateFiltered[`${DUPLICATE_METRIC}_duplicate_row`]
        //         if(duplicateRowsList) {
        //            duplicateRows = duplicateRowsList[duplicateRowsList.length-1] + duplicateRows
        //         }
        //  }

        }

        if (sectionName === DATASET && nestedData !== undefined && nestedData !== null) {
          prefix = `${envId}.${datasourceId}.${datasetId}`;

          this.setState({isFetched: true});
          let fqDatasetName = this.props.metaData[prefix];
          let datasetName = datasetNameFromMappedValue(fqDatasetName)
          this.getDiscoverPageDataset(datasetId, datasetName, sectionName);

          datasetIds = [datasetId]
          highlightedId = datasetId
          attributeCount = Object.values(nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"]).length
          let duplicateInfo = ""
          if (JSON.stringify(this.props.dataModule.info) !== '{}'){
             duplicateInfo = this.props.dataModule.info[datasourceId][prefix]
          }
          if(JSON.stringify(duplicateInfo) !== '' ){
            let duplicateRowsList = duplicateInfo[`${DUPLICATE_METRIC}_duplicate_row`]
            if(duplicateRowsList) {
               duplicateRows = duplicateRowsList[duplicateRowsList.length-1]
            }

            let datasetRowsList = duplicateInfo[`${ROW_COUNT_INTERNAL_ID}_time_value`]
            if(datasetRowsList) {
              datasetRow = datasetRowsList[datasetRowsList.length-1]
            }

          }
        }


        if (sectionName === ATTRIBUTE && nestedData !== undefined && nestedData !== null) {
          prefix = `${envId}.${datasourceId}.${datasetId}.${attributeId}`
          if (this.state.metricsChartData !== null && this.state.metricsChartData !== undefined){
              metricData = this.state.metricsChartData
          }

          highlightedId = attributeId
        }
        filteredValues.push(prefix)

        this.setState({
            data: this.props.nestedData,
            envName: initialSelection.envName,
            datasourceName: initialSelection.datasourceName,
            selectedDataSource: datasourceId,
            lastRefresh: lastRefresh,
            datasetRow: datasetRow,
            duplicateRows: duplicateRows,
            recencyData: recencyData,
            subString: this.props.subString,
            selectedData: selectedData,
            driftOnMetricsChart: driftOnMetricsChart,
            filteredData: filteredData,
            sectionName: sectionName,
            driftOnMetricsKey: driftOnMetricsKey,
            metricsOptions: metricsOptions,
            attributeCount: attributeCount,
            datasourceCount: datasourceCount,
            isTreeSearch: false,
            envTableData: envTableData,
            newData: {},
            filterSearch: "",
            datasetCount: datasetCount,
            datasetIds: datasetIds,
            highlightedId: highlightedId,
            highlightedType: highlightedType,
            isSearchHappened: this.props.isSearchHappened,
            datasetName: initialSelection.datasetName,
            isRedirect: this.props.isRedirect,
            attributeName: initialSelection.attributeName,
            filteredValues: filteredValues,
            envId:envId,
            datasetId: datasetId,
            attributeId: attributeId,
            isDataChanged: !this.state.isDataChanged})
      }
    }
    getDefaultSelectedMetrics(){
        let selectedMetricOptions = [];
        if (this.state.metricsOptions.length > 0){
            selectedMetricOptions = this.state.metricsOptions[0];
            return selectedMetricOptions;
        } else{
            return selectedMetricOptions;
        }
    }

    breadCrumbEvent(e) {
       let type = e.target.id;
       if(this.state.highlightedType === type){
         return
       }
       let envName = this.state.envName
       let datasourceName = this.state.datasourceName
       let datasetName = this.state.datasetName
       let attributeName = this.state.attributeName
       let envId = this.state.envId
       let datasourceId = this.state.selectedDataSource
       let datasetId = this.state.datasetId
       let attributeId = this.state.attributeId
       if(type === ENV_NAME){
         this.changeData(envName, "", "", "", envId, null,
                      null, null, envId, type);
       }else if(type === DATASOURCE) {
         this.changeData(envName, datasourceName, "", "", envId, datasourceId,
                      null, null, datasourceId, type);
       }else if(type === DATASET) {
         this.changeData(envName, datasourceName, datasetName, "", envId, datasourceId,
                      datasetId, null, datasetId, type);
       }else if(type === ATTRIBUTE){
         this.changeData(envName, datasourceName, datasetName, attributeName, envId, datasourceId,
                      datasetId, attributeId, attributeId, type);
       }




    }

    convertDataForDiscoverCharts(metricData, datasetName, sectionName=null){

      let driftOnMetricsChart = []
      let filteredValues = [];
      let metricsOptions = []
      let selectedData = []
      let driftOnMetricsKey = null
      let chartData = this.props.metricData;
      // eslint-disable-next-line
      let filteredData;
      let prefix;
      // eslint-disable-next-line
      let currentIndex;
      if (sectionName === null) {
        sectionName = ATTRIBUTE;
      }
      metricData = convertDataForListView(metricData, datasetName, this.state.datasourceName)
      for(let i=0;i<metricData.length;i++) {
        selectedData.push(metricData[i]);
        metricsOptions.push({"label": metricData[i]["name"], "value": metricData[i]["name"]});
     }
     if(selectedData.length > 0) {
      driftOnMetricsChart = selectedData[0]
      if (driftOnMetricsChart["name"] === "Data Drift") {
               driftOnMetricsChart["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
      }
      filteredData = getChartFilterData(driftOnMetricsChart, this.state.startDateDiscover, this.state.endDateDiscover,"", false, false, 10)
      driftOnMetricsKey = sectionName + driftOnMetricsChart["name"]
    }

    if(this.state.currentIndex === null) {
      let currentData = this.props.filteredData.filter(x=>x.type === ATTRIBUTE && x.id === this.state.attributeId)
      currentIndex = this.props.filteredData.indexOf(currentData[0])
   }

   filteredValues.push(prefix)


   //if (sectionName === ATTRIBUTE)  {
  // alert(111)
  //   this.setState({
  //     metricsOptions: metricsOptions,
  //     currentIndex: currentIndex,
  //     sectionName: sectionName,
  //     driftOnMetricsChart: driftOnMetricsChart,
  //     selectedData: selectedData,
  //     driftOnMetricsKey: driftOnMetricsKey,
  //     filteredData: filteredData,
  //     filteredValues: filteredValues})
  //     return;
  //  }
      this.setState({
      metricsOptions: metricsOptions,
      driftOnMetricsChart: driftOnMetricsChart,
      driftOnMetricsKey: driftOnMetricsKey,
      selectedData: selectedData,
      sectionName: sectionName});
     // }
    }

    changeData(envName, datasourceName, datasetName, attributeName, envId, datasourceId,
                      datasetId, attributeId, id, type, currentIndex=null){
                        $("#zoomlevel").val(10);
        let filteredValues = [];
        let filteredData;
        let chartData = this.props.metricData;
        let driftOnMetricsChart = null
        let driftOnMetricsKey = null
        let selectedData = []
        // eslint-disable-next-line
        let metricData = []
        let metricsOptions = []
        let prefix;
        let attributeCount;
        let datasetCount;
        let datasetIds = [];
        let envTableData = null
        let lastRefresh;
        let duplicateRows = 0;
        let datasetRow = 0;
        let recencyData = 0
        let datasourceCount;
        let sectionName = ""
        let originalData = _.cloneDeep(this.props.originalData);

        if(attributeId !== null && attributeId !== undefined) {
           prefix = `${envId}.${datasourceId}.${datasetId}.${attributeId}`
           sectionName = ATTRIBUTE
           this.setState({isFetched: false, distributionData: null,profileDataDiscover:null});
           this.getAttributeData(datasetId, attributeId);
           originalData[envId]["isOpen"] = true
           originalData[envId]["childNodes"][datasourceId]["isOpen"] = true
           originalData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isOpen"] = true
           originalData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isOpen"] = true
        }else if(datasetId !== null && datasetId !== undefined) {
            originalData[envId]["isOpen"] = true

           originalData[envId]["childNodes"][datasourceId]["isOpen"] = true
           originalData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isOpen"] = true
           originalData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isShow"] = true

            prefix = `${envId}.${datasourceId}.${datasetId}`;
            lastRefresh = this.props.datasetRefreshInfo[Number(datasetId)];

//            alert(datasetId);
let datasourceprofiledate = this.getDataSourceLastProfiletime(datasourceId);
let datasourceprofilestartDate = moment(datasourceprofiledate).subtract(30, 'days');
let datasourceprofileendDate = moment(datasourceprofiledate).endOf('day');
let params=
{
  "integration_id":datasourceId,
  "integration_name":datasourceName,
  "tab_type":1,
  "tab":1,
  // "start_date":this.state.startDateDiscover,
  // "end_date":this.state.endDateDiscover
  "start_date":datasourceprofilestartDate,
  "end_date":datasourceprofileendDate
}
sectionName = DATASET;
let filteredDatas = {"fiteredDatseset":datasetId}
 let fqDatasetName = this.props.metaData[prefix];
 let datasetName = datasetNameFromMappedValue(fqDatasetName)
 this.getDiscoverPageDataset(datasetId, datasetName, sectionName);
 this.getDatasForTab(params, filteredDatas, true)
          //  return;
            this.setState({isFetched: true,filter_data_set_id:datasetId});
            datasetIds = [datasetId]
            // let duplicateInfo = this.props.dataModule.info[datasourceId][prefix]
            // if(duplicateInfo !== undefined ){
            //   let duplicateRowsList = duplicateInfo[`${DUPLICATE_METRIC}_duplicate_row`]
            //   if(duplicateRowsList) {
            //      duplicateRows = duplicateRowsList[duplicateRowsList.length-1]
            //   }
            //   let datasetRowsList = duplicateInfo[`${ROW_COUNT_INTERNAL_ID}_time_value`]
            //   if(datasetRowsList) {
            //     datasetRow = datasetRowsList[datasetRowsList.length-1]
            //   }
            // }
            attributeCount = Object.values(originalData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"]).length
        }
        else if(datasourceId !== null && datasourceId !== undefined) {
            // alert("DATASOURCE");
            let datasourceprofiledate = this.getDataSourceLastProfiletime(datasourceId);
            let datasourceprofilestartDate = moment(datasourceprofiledate).subtract(30, 'days');
            let datasourceprofileendDate = moment(datasourceprofiledate).endOf('day');
            let params=
{
"integration_id":datasourceId,
"integration_name":datasourceName,
"tab_type":1,
"tab":1,
"start_date":datasourceprofilestartDate,
"end_date":datasourceprofileendDate
}
this.getDatasForTab(params, null, null)

            prefix = `${envId}.${datasourceId}`;
            sectionName = DATASOURCE
            datasetIds = Object.keys(originalData[envId]["childNodes"][datasourceId]["childNodes"])
            datasetCount = datasetIds.length
            originalData[envId]["isOpen"] = true
            originalData[envId]["childNodes"][datasourceId]["isOpen"] = true
            originalData[envId]["childNodes"][datasourceId]["isShow"] = true

            this.setState({envName: envName,
              envTableData: envTableData,
              selectedDataSource: datasourceId,
              datasetId: datasetId,
              recencyData: recencyData,
              envId:envId,
              duplicateRows: duplicateRows,
              attributeId: attributeId,
              datasetRow: datasetRow,
              lastRefresh: lastRefresh,
              showTreeView: false,
              datasetCount: datasetCount,
              attributeCount: attributeCount,
              datasourceCount: datasourceCount,
              isChartChanged: !this.state.isChartChanged,
              datasourceName: datasourceName,
              datasetName: datasetName,
              highlightedType: type,
              datasetIds: datasetIds,
              highlightedId: id,
              attributeName: attributeName,
              filter_data_set_id:null
            })

            let reqMetricResult = this.props.datasourceData[datasourceId];
            // reqMetricResult = DUMMY_DATA
            for(const key of Object.keys(reqMetricResult)){
              let value = reqMetricResult[key];
              if (value === undefined || value.constructor !== Object) {
                continue;
              }

               if(key.includes("Data")){
                 metricsOptions.push({"label": key, "value": key})
               }
            }

            if(metricsOptions.length > 0) {
                let currMasName = metricsOptions[0].value;
                this.setState({ selectedMasChart: currMasName });
                let dataFromProps = reqMetricResult[currMasName]
                let data = _.cloneDeep(dataFromProps);
                let result = this.processDatasourceChart(data, datasourceId);
                driftOnMetricsChart = result["finalData"]
                filteredData = result["filteredData"]
            }

            // let duplicateInfo = this.props.dataModule.info[datasourceId]
            // for(let value of Object.values(duplicateInfo)){
            //           let duplicateFiltered = value
            //           let duplicateRowsList = duplicateFiltered[`${DUPLICATE_METRIC}_duplicate_row`]
            //           if(duplicateRowsList) {
            //              duplicateRows = duplicateRowsList[duplicateRowsList.length-1] + duplicateRows
            //           }
            // }
            let recencyInfo = this.props.dataModule.recencyDetails[datasourceId];

            for(let value of Object.values(recencyInfo)){
                      let recencyFiltered = value
                      let recencyList = recencyFiltered[`${RECENCY_INTERNAL_ID}_time_value`]
                      let latestRecency = 0
                      if(recencyList) {
                         latestRecency = recencyList[recencyList.length-1]
                      }
                      if(latestRecency > 30){
                        recencyData = recencyData + 1
                      }
               }

        }else {
            prefix = `${envId}`;
            sectionName = ENV_NAME
            datasetCount = 0
            let total = originalData[envId]["childNodes"]
            datasourceCount = Object.keys(total).length
            for(const value of Object.values(total)) {
              let currChildNodes = Object.keys(value["childNodes"]);
               datasetIds = datasetIds.concat(currChildNodes)
               datasetCount = datasetCount + currChildNodes.length
            }
            envTableData = this.getEnvTableData(originalData[envId]["childNodes"], chartData)
            originalData[envId]["isShow"] = true
            originalData[envId]["isOpen"] = true
        }
        // if([DATASET].includes(sectionName)) {
        //     metricData = convertDataForListView(metricData, datasetName, datasourceName)
        //     for(let i=0;i<metricData.length;i++) {
        //        if(metricData[i]["name"] === "Row Count") {
        //          datasetRow = metricData[i]["value"][metricData[i]["value"].length - 1]
        //        }
        //       if(metricData[i]["hasDrift"] === false) {
        //         continue
        //       }

        //        selectedData.push(metricData[i]);
        //        metricsOptions.push({"label": metricData[i]["name"], "value": metricData[i]["name"]});

        //       }
        // }

        if(selectedData.length > 0) {
          driftOnMetricsChart = selectedData[0]
          if (driftOnMetricsChart["name"] === "Data Drift") {
                   driftOnMetricsChart["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
          }
          filteredData = getChartFilterData(driftOnMetricsChart, this.state.startDateDiscover, this.state.endDateDiscover,"", false, false, 10)
          driftOnMetricsKey = sectionName + driftOnMetricsChart["name"]
        }
        if(currentIndex === null) {
           let currentData = this.props.filteredData.filter(x=>x.type === type && x.id === id)
           currentIndex = this.props.filteredData.indexOf(currentData[0])
        }

        filteredValues.push(prefix)
        if(this.state.isTreeSearch){
           this.setState({isTreeSearch: false, subString: "", filterSearch: "", newData: originalData, isDataChanged: !this.state.isDataChanged})
        }


        this.setState({envName: envName,
                     envTableData: envTableData,
                     selectedDataSource: datasourceId,
                     datasetId: datasetId,
                     recencyData: recencyData,
                     envId:envId,
                     duplicateRows: duplicateRows,
                     attributeId: attributeId,
                     datasetRow: datasetRow,
                     lastRefresh: lastRefresh,
                     showTreeView: false,
                     datasetCount: datasetCount,
                     attributeCount: attributeCount,
                     datasourceCount: datasourceCount,
                     isChartChanged: !this.state.isChartChanged,
                     datasourceName: datasourceName,
                     datasetName: datasetName,
                     highlightedType: type,
                     datasetIds: datasetIds,
                     highlightedId: id,
                     attributeName: attributeName})
    if (sectionName !== ATTRIBUTE){
      this.setState({
        driftOnMetricsChart: driftOnMetricsChart,
        currentIndex: currentIndex,
        sectionName: sectionName,
        selectedData: selectedData,
        filteredData: filteredData,
        metricsOptions: metricsOptions,
        driftOnMetricsKey: driftOnMetricsKey,
        filteredValues: filteredValues,
      })

    }
    }

  processDatasourceChart(data, datasourceId, startDate=null, endDate=null) {
    if(startDate === undefined || startDate === null) {
      startDate = this.state.startDateDiscover;
    }

    if (endDate === undefined || endDate === null) {
      endDate = this.state.endDateDiscover;
    }

    let finalData = null;
    let filteredData = null;
    let attributeName = null;
    let datasetName = null;
    if (data === undefined) {
      let result = { "finalData": finalData, "filteredData": filteredData }
      return result
    }

    for (const [key, value] of Object.entries(data)) {
      for (const [attribute, chartDataValues] of Object.entries(value)) {
        const attributeKeysSplit = attribute.split(".");
        if (datasourceId !== null) {
          if (attributeKeysSplit[1] !== datasourceId) {
            continue;
          }
        }

        let metaData = this.props.metaData[attribute];
        if (metaData === undefined) {
          metaData = this.props.metaData;
          // const filtered = Object.keys(metaData)
          //     .filter(key => key.startsWith(attribute))
          //     .reduce((obj, key) => {
          //         obj[key] = metaData[key];
          //         return obj;
          //     }, {});
          metaData = metaData[attribute];
          if (metaData !== undefined) {
            let reqSplitArr = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
            metaData = reqSplitArr.slice(0, reqSplitArr.length - 1).join(METADATA_MAP_VALUE_SEPARATOR);
          }
        }
        if (metaData !== undefined) {
          const metadataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);

          let toolTiptopHeader;
          let keysSplitLength = attributeKeysSplit.length;

          // Skip attribute level charts for consistency related metrics
          if (keysSplitLength === 4 && REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(key)) {
            continue;
          }

          if (keysSplitLength === 4) {
            datasetName = metadataSplit[2];
            attributeName = metadataSplit[metadataSplit.length - 1];
            toolTiptopHeader = attributeName;
          } else {
            datasetName = metadataSplit[2];
            toolTiptopHeader = datasetName;
          }
          if (chartDataValues["name"] === "Data Drift") {
            let boxPlotData = {}
            let statistics = {}
            let quantile = chartDataValues["boxplot_data"]
            if (quantile !== undefined && quantile !== null) {
              statistics["attribute_name"] = attributeName
              statistics["quantile"] = quantile
              statistics["drift_patterns"] = chartDataValues["drift_patterns"]
              statistics["time"] = chartDataValues["time"]
              if (attributeName !== null) {
                boxPlotData["chart_level"] = "Attribute"
              } else {
                boxPlotData["chart_level"] = "Dataset"
              }
              boxPlotData["statistics"] = [statistics]
              chartDataValues["boxPlotData"] = boxPlotData
              chartDataValues["chartType"] = "boxPlotZoomable"
            }
          }
          if (attributeName !== null) {
            chartDataValues["attribute_name"] = attributeName;
          }
          chartDataValues["datasetName"] = datasetName;
          chartDataValues["toolTiptopHeader"] = toolTiptopHeader;
          chartDataValues["data_set_id"] = attributeKeysSplit[2];
          chartDataValues["errorChart"] = true;
          chartDataValues["title"] = `${metadataSplit.join(" > ")} - ${chartDataValues.name}`;
          chartDataValues["data_source_name"] = metadataSplit[1];
          chartDataValues[DQ_CHART_POINTS] = chartDataValues["value"];

          let chartTypeData = getChartTypeForMonitorCharts(chartDataValues);
          chartDataValues["type"] = chartTypeData.chartPatternType;
          let hasError = chartTypeData.hasError;
          chartDataValues["hasDrift"] = hasError;

          // We are changing scale of all charts to min max scale
          // Part of fix for issue 1038
          chartDataValues["scale"] = MIN_MAX_SCALE;

          normalizeChartData(chartDataValues, DQ_CHART_POINTS);
          let chartValues = chartDataValues[DQ_CHART_POINTS];
          let drift_len = chartValues.length;

          if (drift_len === 0) {
            continue;
          }

          if (drift_len > 1) {
            chartDataValues["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
            chartDataValues["last_error"] = chartValues[(drift_len - 2)].toFixed(2);
          } else if (drift_len === 1) {
            chartDataValues["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
            chartDataValues["last_error"] = chartValues[(drift_len - 1)].toFixed(2);
          } else {
            chartDataValues["error_percentage"] = 0;
            chartDataValues["last_error"] = 0;
          }


        }
        finalData = chartDataValues

        if (finalData !== null) {
          if (finalData["name"] === "Data Drift") {
            finalData["boxPlotData"] = filterBoxPlotData(finalData["boxPlotData"])
          }
          filteredData = getChartFilterData(finalData, startDate, endDate)
          this.setState({ chartDatasetName: datasetName, chartAttributeName: attributeName })
          break
        }
      }


      if (finalData !== null) {
        break
      }
    }
    let result = { "finalData": finalData, "filteredData": filteredData }
    return result
  }

    closeIcon(){
       this.setState({ filterSearch: "", newData: {},isDataChanged: !this.state.isDataChanged, isTreeSearch: false, subString: null });
    }

    handleChange = event => {
        let filterSearch = event.target.value
        this.setState({ filterSearch: filterSearch });

      const lowerCasedFilter = filterSearch.toLowerCase();
      if (lowerCasedFilter.length === 0 || !lowerCasedFilter.trim()) {
        this.setState({
          newData: {},
          isDataChanged: !this.state.isDataChanged,
          isTreeSearch: false,
          subString: null
        });
        return;
      }

      if (lowerCasedFilter.length < 2) {
        // We have added this condition to allow search only when we entered one character
        this.setState({
          newData: {},
          isDataChanged: !this.state.isDataChanged,
          isTreeSearch: false,
          subString: null
        });
        return;
      }

        let autoCompleteData = this.props.autoCompleteData
        let nestedData = _.cloneDeep(this.props.originalData)
        let newData = {}
       let searchTextFound = false
       for(let i=0;i<autoCompleteData.length;i++) {
            let data = autoCompleteData[i]
            let type = data["type"]
            let result = data["name"].toLowerCase();
            if(!result.includes(lowerCasedFilter) ||type === "Recent"){
              continue
            }
            searchTextFound = true

            let envId = data["envId"]
            let datasourceId = data["datasourceId"]
            let datasetId = data["datasetId"]
            let attributeId = data["attributeId"]
            if(!Object.keys(newData).includes(envId)){
               newData[envId] = nestedData[envId]

               newData[envId]["isShow"] = true
               newData[envId]["isOpen"] = true
            }

            if(datasourceId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["isOpen"] = true
                 newData[envId]["childNodes"][datasourceId]["isShow"] = true
            }

            if(datasetId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isOpen"] = true
                  newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isShow"] = true
            }

            if(attributeId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isOpen"] = true
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isShow"] = true
            }




         }

       this.setState({newData: newData, isDataChanged: !this.state.isDataChanged, isTreeSearch: true, searchTextFound: searchTextFound, subString: filterSearch})
      };
    getId(highlightedType, filteredItem){
         if(highlightedType === ENV_NAME) {
           return filteredItem["envId"]
         }else if(highlightedType === DATASOURCE) {
           return filteredItem["datasourceId"]
         }else if(highlightedType === DATASET){
           return filteredItem["datasetId"]
         }else{
           return filteredItem["attributeId"]
         }
    }
    changeNavigation(position) {
       let currentIndex = this.state.currentIndex
       let filteredItem;
       let disableUp;
       let disableDown;
       let filteredData = this.props.filteredData
       if(currentIndex-1 === 0){
         disableUp = true
       }else{
         disableUp = false
       }
       if(currentIndex === (filteredData.length-2)){
         disableDown = true
       }else{
         disableDown = false
       }

       let highlightedType;
       let highlightedId;
       if(position === "down") {
          filteredItem = filteredData[currentIndex + 1]
          currentIndex = currentIndex + 1
          if(filteredItem !== undefined) {
            highlightedType = filteredItem["type"]
            highlightedId = this.getId(highlightedType, filteredItem)
          }
       }else {
          filteredItem = filteredData[currentIndex - 1]
          currentIndex = currentIndex - 1
          if(filteredItem !== undefined) {
            highlightedType = filteredItem["type"]
            highlightedId = this.getId(highlightedType, filteredItem)
          }
       }
       let envName = filteredItem["envName"];
       let datasourceName = filteredItem["datasourceName"]
       let datasetName = filteredItem["datasetName"]
       let attributeName = filteredItem["attributeName"]
       if(highlightedType === DATASOURCE) {
            datasourceName = filteredItem["name"]
         }else if(highlightedType === DATASET) {
            datasetName = filteredItem["name"]
         }else if(highlightedType === ATTRIBUTE) {
            attributeName = filteredItem["name"]
         }else if(highlightedType === ENV_NAME) {
            envName = filteredItem["name"]
         }
       this.changeData(envName, datasourceName, datasetName, attributeName,
                       filteredItem["envId"], filteredItem["datasourceId"],
                      filteredItem["datasetId"], filteredItem["attributeId"], highlightedId, highlightedType, currentIndex)
       this.setState({disableUp: disableUp, disableDown: disableDown})
    }

    reset() {
       this.setState({envName: ""});
       this.props.clearSearch();
    }

    getIcon(type) {
         if(type === ENV_NAME) {
           return faServer;
         }else if(type === DATASOURCE){
           return faDatabase;
         }else if(type === DATASET) {
           return faLayerGroup;
         }else {
            return faTag;
         }
   }


    getFiltered(data, text, childNodes, original) {
       if(text === original){
          data["isOpen"] = true
       }
       if(childNodes !== undefined) {
         let data1 = Object.values(childNodes)
         for(let i=0;i<data1.length;i++){
             this.getFiltered(data1[i], text, data1[i]["childNodes"], data1[i]["name"])
         }
         }
         else{
            return
         }

    }

    handleTreeViewButton(){
        this.setState({showTreeView: !this.state.showTreeView})
        setTimeout(function(){
            if($("#tree-view").hasClass("show")) {
             $('body').addClass("overflow-hidden");
        }else{
            $('body').removeClass("overflow-hidden");
        }
            }, 500);

    }


    render() {

      let chartData = []

      // alert(this.state.filter_data_set_id)

      let initialFilteredValues =  this.state.filteredValues
      let initialFilteredDatasets = []
      let initialFilteredAttributes = []
      let allowRelationsFilter = false

      if (Object.keys(initialFilteredValues).length > 0) {
        // if(initialFilteredValues['fiteredDatseset'].length > 0){
        //   // initialFilteredDatasets = initialFilteredValues['fiteredDatseset']
        //   // initialFilteredAttributes = initialFilteredValues['fitlerdAttr']
        // }
       }
      let datasetLevelError = []
      let attributeLevelError = []

      if (Object.keys(this.state.chartErrors).length > 0) {
          datasetLevelError = this.state.chartErrors['dataset_level']
          attributeLevelError = this.state.chartErrors['attr_level']
      }
      /**Separate Filter Existing Dataset Level Chart Data */
      let datasetLevelChart = []
      this.state.datasetLevelChart.map((data) => {
          data['name'] = normalizeChildDatasetName(data['name'])
          if(data.imports.length > 0 && data["confidence_score"] === 0){
              data["confidence_score"] = 1
          }
          if (this.state.existingList.includes(Number(data.dataSetId))) {
          let error_metrics = []
          if(initialFilteredDatasets.length > 0){
              if (datasetLevelError[data.dataSetId] !== undefined && initialFilteredDatasets.includes(Number(data.dataSetId))) {
                  datasetLevelError[data.dataSetId].map((data) => {
                      if (data.error === true) {
                          error_metrics = error_metrics.concat(data)
                      }
                      return null
                  })
                  data['metrics'] = error_metrics
                  datasetLevelChart = datasetLevelChart.concat(data)
              }
              else if(initialFilteredDatasets.includes(Number(data.dataSetId))){
                  datasetLevelChart = datasetLevelChart.concat(data)
              }
              // }
          }
          else{
              if (datasetLevelError[data.dataSetId] !== undefined) {
                  datasetLevelError[data.dataSetId].map((data) => {
                      if (data.error === true) {
                          error_metrics = error_metrics.concat(data)
                      }
                      return null
                  })
                  data['metrics'] = error_metrics

              }
              datasetLevelChart = datasetLevelChart.concat(data)
          }
          }
          return null;
      })
      /**Separate Existing Attribute Level Chart Data */
      let filterdataattr = [];
     let list_data_import = [];
     if(this.state.filter_data_set_id!==null){
       filterdataattr = this.state.attributeLevelChart.filter(laur => {
        return (parseInt(laur.dataSetId) === parseInt(this.state.filter_data_set_id)) ? true : false;
      });
      if(filterdataattr.length > 0){
        for(let loop=0;loop<filterdataattr.length;loop++){
          for(let innerloop = 0;innerloop<filterdataattr[loop]["imports"].length;innerloop++){
            list_data_import.push(filterdataattr[loop]["imports"][innerloop]['data_set_id'])
          }
        }
      }
     }
      let attributeLevelChart = []
      // alert(list_data_import)
      //alert(JSON.stringify(this.state.attributeLevelChart))
      this.state.attributeLevelChart.map((data) => {
//          if( (data.imports.length > 0 && list_data_import.indexOf(data['dataSetId']) >= 0 && this.state.filter_data_set_id !== null) || (this.state.filter_data_set_id === null)  ) {
            // console.log(data);
            data['datasetName'] = normalizeChildDatasetName(data['datasetName'])
          if(data.imports.length > 0 && data["confidence_score"] === 0 ){
              data["confidence_score"] = 1
          }
          if (this.state.existingList.includes(Number(data.attribute_id))) {
          let error_metrics = []
          if(initialFilteredDatasets.length > 0){
              if(attributeLevelError[data.attribute_id] !== undefined ){
                  attributeLevelError[data.attribute_id].map((data) => {
                      if (data.error === true) {
                          error_metrics = error_metrics.concat(data)
                      }
                      return null
                  })
              }

              data['metrics'] = error_metrics
              if (initialFilteredDatasets.includes(Number(data.dataSetId)) && initialFilteredAttributes.includes(Number(data.attribute_id))) {
                  attributeLevelChart = attributeLevelChart.concat(data)
              }
              else if(initialFilteredDatasets.includes(Number(data.dataSetId))){
                  attributeLevelChart = attributeLevelChart.concat(data)

              }
              else if(initialFilteredAttributes.length === 0 && initialFilteredDatasets.includes(Number(data.dataSetId))){
                  attributeLevelChart = attributeLevelChart.concat(data)
              }
              }
          else{
              if (attributeLevelError[data.attribute_id] !== undefined) {
                  attributeLevelError[data.attribute_id].map((data) => {
                      if (data.error === true) {
                          error_metrics = error_metrics.concat(data)
                      }
                      return null
                  })
                  data['metrics'] = error_metrics
              }
                  attributeLevelChart = attributeLevelChart.concat(data)
          }
          }
          return null;
  //        }
          })

      /**Separate Partition and child datasets */
      let parentDatasetIds = []
      let childDatasetIds = []

      datasetLevelChart.map((data) => {
          if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
              childDatasetIds = childDatasetIds.concat(Number(data.dataSetId))
          }
          else {
              parentDatasetIds = parentDatasetIds.concat(Number(data.dataSetId))
          }
          return null
      })


      /**Separate Partition Dataset Attributes and Child Dataset Attributes  */
      let parentAttributeIds = []
      let childAttributeIds = []

      attributeLevelChart.map((data) => {
          if (parentDatasetIds.includes(Number(data.dataSetId))) {
              parentAttributeIds = parentAttributeIds.concat(data.dataSetId)
          }
          else {
              childAttributeIds = childAttributeIds.concat(data.attribute_id)
          }
          return null
      })


      /**Handle Dataset level chart Data based on the chart check box filter */
      let attributeList = []

      datasetLevelChart.map((data) => {
          if (this.state.checkedPartition) {
              chartData = chartData.concat(data)
              attributeList = childAttributeIds.concat(parentAttributeIds)
          }
          else if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
              /*** Skip Pushing Child Dataset initially */
          }
          else {
              attributeList = parentAttributeIds
              chartData = chartData.concat(data)
          }
          return null
      })


      /**Handle Attribute level chart Data based on the chart check box filter */

      let chartDataAttr = []
        attributeLevelChart.map((data) => {
            if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0){
                allowRelationsFilter = true
            }
            if(allowRelationsFilter){
                if(childDatasetIds.includes(Number(data.dataSetId))){
                    if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0 ){
                      chartDataAttr = chartDataAttr.concat(data)
                    }
                }
                else{
                    if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0 ){
                      chartDataAttr = chartDataAttr.concat(data)
                    }
                }
            }
            else{
                if(childDatasetIds.includes(Number(data.dataSetId))){
                  chartDataAttr = chartDataAttr.concat(data)
                }
                else{
                  chartDataAttr = chartDataAttr.concat(data)
                }
            }
            return chartDataAttr
        })

      if (this.state.checkedAttributes) {
          chartData = []

          attributeLevelChart.map((data) => {
              if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0){
                  allowRelationsFilter = true
              }
              if(this.state.allowRelationData===true && allowRelationsFilter){
                  if(this.state.checkedPartition === false){
                      if(parentDatasetIds.includes(Number(data.dataSetId))){
                          if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0 ){
                              chartData = chartData.concat(data)
                          }
                      }
                  }
                  else if(childDatasetIds.includes(Number(data.dataSetId))){
                      if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0 ){
                          chartData = chartData.concat(data)
                      }
                  }
                  else{
                      if(data.confidence_score !== undefined && data.confidence_score!==null && data.confidence_score > 0 ){
                          chartData = chartData.concat(data)
                      }
                  }
              }
              else if(this.state.allowRelationData!==true){
                  if(this.state.checkedPartition === false){
                      if(parentDatasetIds.includes(Number(data.dataSetId))){
                          chartData = chartData.concat(data)
                      }
                  }
                  else if(childDatasetIds.includes(Number(data.dataSetId))){
                      chartData = chartData.concat(data)
                  }
                  else{
                      chartData = chartData.concat(data)
                  }
              }
              return chartData
          })
      }


      /**  TODO */
      // Above entire chart logic needs to be moved into a function : handleChartData()


        let data = []
        if(Object.values(this.state.newData).length > 0) {
          data = Object.values(this.state.newData)
        }else{
          data = this.state.data !== undefined && this.state.data !== null ? Object.values(this.state.data) : [];
        }
        let profileDataDiscover = this.state.profileDataDiscover;


//        alert(JSON.stringify(this.state.newCardData));

        if(profileDataDiscover!==null){
        let keys = ["avg","min","max","std"]
        if(profileDataDiscover !== undefined && profileDataDiscover !== null){
          if(this.state.sectionName==="Attribute"){
              for(let i=0;i<keys.length;i++){
                 if(typeof(profileDataDiscover[keys[i]]["drift"]) === "number"){
                    profileDataDiscover[keys[i]]["drift"] = parseFloat(profileDataDiscover[keys[i]]["drift"].toFixed(4))
                 }
                 if(typeof(profileDataDiscover[keys[i]]["value"]) === "number"){
                    profileDataDiscover[keys[i]]["value"] = parseFloat(profileDataDiscover[keys[i]]["value"].toFixed(4))
                 }
              }
              }
        }
        }
        for (let [key] of Object.entries(activeItem)) {
            activeItem[key] = "breadcrumb-item";
        }
        activeItem[this.state.highlightedType] = 'breadcrumb-item active'
        let sizeData = 0
        let filtered = this.props.dataModule.dataSize.filter(x=>(this.state.datasetIds.includes(x.data_set_id.toString())))
        for(let i=0;i<filtered.length;i++) {
           sizeData = sizeData + filtered[i]["size"]

        }
        let showTreeView = this.state.showTreeView
        sizeData = getConvertedSize(sizeData)
        setTimeout(function(){
            if(document.getElementById('dqInfoId')){
               if(document.getElementById('dqInfoId').clientHeight < 500){
                    if(showTreeView){
                       $("#tree-view .custom-scrollbar").height("calc(100% - 112px)");
                    }else{
                       $("#tree-view .custom-scrollbar").height(500);
                    }
               }else{
                    $("#tree-view .custom-scrollbar").height(document.getElementById('dqInfoId').clientHeight);
               }
            }
            }, 500);

        return(<><div className="row no-gutters">
                   <div className={this.state.showTreeView ? "qd_tree-wrap show": "qd_tree-wrap"} id="tree-view">
                    {this.props.metaData === null
                      ?
                            <Load></Load>
                      :
                        <div className="qd_tree-view-container">
                            <div className="qd_tree-header">
                              <h4 className="qd_tree-header-title"><FontAwesomeIcon icon={faProjectDiagram}/> Tree View</h4>
                              <button className="close" onClick={this.handleTreeViewButton}></button>
                            </div>
                             {this.state.isRedirect && !(this.state.isTreeSearch) ?
                                  <div className="search-controls-section">
                                    <div className="qd_tree-search-controls">
                                        <p><strong>  {  this.props.searchCount}</strong> results</p>
                                        <div className="search-actions">
                                              {this.props.filteredData ? <><button disabled={this.state.disableUp || this.props.filteredData.length <= 1} className="btn" id="up" onClick={this.changeNavigation.bind(this, "up")} title="Previous Match (&#8593; Up Arrow)">
                                                    <i className="icon_arrow-up"></i></button>
                                              <span className="icon-divider"></span>
                                              <button disabled={this.state.disableDown || this.props.filteredData.length <= 1} className="btn" id="down" onClick={this.changeNavigation.bind(this, "down")} title="Next Match (&#8595; Down Arrow)"><i className="icon_arrow-down"></i></button>
                                                </>
                                                :
                                                ""
                                                }
                                                <button className="btn" title="Close (Escape)" onClick={this.reset}><i className="icon_cross"></i></button>
                                        </div>
                                    </div>
                                  </div>
                                  :
                                  <div className={this.state.filterSearch !== "" ? "qd_tree-search has-btn-close": "qd_tree-search"}>
                                    <button className="btn btn-clear" onClick={this.closeIcon} title="Clear"><i className="icon-close"></i></button>
                                    <span className="qd_tree-search-icon">
                                        <i>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </i>
                                    </span>
                                    <input
                                      value={this.state.filterSearch}
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={this.handleChange}/>
                                </div>
                                }
                             <Scrollbars className="custom-scrollbar"
                                 autoHeightMin={0}
                                 autoHeightMax={200}
                                 thumbMinSize={30}
                                 universal={true}
                                 style={{ overflow: 'visible' }}
                             >
                             <ul className="qd_tree-view" key={this.state.isDataChanged}>
                              {data.length === 0 || (!this.state.searchTextFound && this.state.isTreeSearch) ?
                              <div className="alert_notree-search-match">
                                  <Image
                                    src="icon_exclamation_gray"
                                />
                                  <p>No matches found for your search</p>
                              </div>
                              :
                              data.map(({ id, title, childNodes, type, isOpen, isShow }) => (
                                <Node
                                            key={title}
                                            isRedirect={this.state.isRedirect || this.state.isTreeSearch}
                                            envName={title}
                                            isTreeSearch={this.state.isTreeSearch}
                                            subString={this.state.subString}
                                            title={title}
                                            type={type}
                                            isSearchHappened={!this.props.isSearchHappened && !this.state.isTreeSearch}
                                            id={id}
                                            childNodes={Object.values(childNodes)}
                                            isOpen={isOpen}
                                            isShow={isShow}
                                            highlightedId={this.state.highlightedId}
                                            highlightedType={this.state.highlightedType}
                                            changeData={this.changeData}/>
                              ))}

                            </ul>
                            </Scrollbars>


                        </div>
                      }
                   </div>
                   <div className="qd_tree-overlay"></div>
                   <div className="col qd_tree-content" key={this.state.isDataChanged}>{/* If content gets overflow need to add overflow-hidden css */}
                        <div className="qd_tree-content-inner" id="discoverIdA" style={{height: "100%", minHeight: "500px"}}>
                            <div className="qd_tree-info" id='dqInfoId'>
                                 {(!this.props.isNoResult && this.props.isSearchHappened) || (this.state.envName !== "" && this.state.envName !== undefined ) ? <>
                                  {this.state.sectionName === ENV_NAME ?  "" : <nav aria-label="breadcrumb">
                                      <ol className="breadcrumb bg-white pl-0">
                                      {this.state.envName !== "" && this.state.envName !== undefined ? <li id={ENV_NAME} className={activeItem[ENV_NAME]} onClick={this.breadCrumbEvent}><i><FontAwesomeIcon icon={faServer} /></i> <span id={ENV_NAME}>{this.state.envName}</span></li> : ""}
                                      {this.state.datasourceName !== "" &&  this.state.datasourceName !== undefined ? <li id={DATASOURCE} className={activeItem[DATASOURCE]} onClick={this.breadCrumbEvent}><i><FontAwesomeIcon icon={faDatabase} /></i> <span id={DATASOURCE}>{this.state.datasourceName}</span></li> : ""}
                                      {this.state.datasetName !== "" && this.state.datasetName !== undefined ? <li id={DATASET} className={activeItem[DATASET]} onClick={this.breadCrumbEvent}><i><FontAwesomeIcon icon={faLayerGroup} /></i> <span id={DATASET}>{this.state.datasetName}</span></li> : ""}
                                      {this.state.attributeName !== "" && this.state.attributeName !== undefined?
                                      this.renderBreadCrumbAttributeContent(activeItem) : ""
                                      }
                                      </ol>
                                  </nav>}
                                  {![ATTRIBUTE,ENV_NAME].includes(this.state.sectionName) ? <><hr className="clearfix"></hr>
                                     <h2 className="qd_tree-info-title">
                                        <i><FontAwesomeIcon icon={faAsterisk} /></i> <span>Relationship</span>
                                    </h2>
                                    <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                                        <div className="tabbable-line mb-3">
                                             <Nav variant="tabs" id="page-nav-tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="chartView">
                                                                <span className="qd-menu__link-text">
                                                                <i>
                                                                    <FontAwesomeIcon icon={faChartPie}/>
                                                                </i>  Chart View</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="listView">
                                                                <span className="qd-menu__link-text"><i>
                                                                    <FontAwesomeIcon icon={faList}/>
                                                                </i>  List View</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                             </Nav>
                                        </div>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="chartView">
                                                  {
                                                    chartData.length > 0 ?
                                                    <RelationshipChartView
                                                    sectionName={this.state.sectionName}
                                                    dataModule={this.props.dataModule}
                                                    chartDatas={chartData}
                                                    filteredValues={this.state.filteredValues}
                                                    selectedDataSource={this.state.selectedDataSource}
                                                    datasetLevelChart={childDatasetIds}
                                                    attributeLevelChart={attributeList}
                                                    allowPartition={this.allowPartition}
                                                    allowAttributes={this.allowAttributes}
                                                    checkedPartition={this.state.checkedPartition}
                                                    checkedAttributes={this.state.checkedAttributes}
                                                    relationshipCheck={this.relationshipCheck}
                                                    relationshipCheckFilter={this.state.allowRelationData === true ? this.state.allowRelationData : allowRelationsFilter}
                                                    allowRelationsFilter={this.state.allowRelationsFilter}
                                                    />
                                                    : ""
                                                  }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="listView">
                                                     {
                                                      this.state.tableData !== undefined ?
                                                      <RelationshipListView
                                                      dataModule={this.state.tableData}
                                                      chartDatas={chartDataAttr}
                                                      filteredValues={this.state.filteredValues}
                                                      selectedDataSource={this.state.selectedDataSource}
                                                      filter_data_set_id={this.state.filter_data_set_id}
                                                      /> : ""
                                                     }
                                                </Tab.Pane>
                                            </Tab.Content>
                                    </Tab.Container><hr className="clearfix"/></> : ""}
                                   {
                                     this.state.sectionName === ENV_NAME ?
                                                <><h2 className="qd_tree-info-title">
                                                  <i><FontAwesomeIcon icon={faFileAlt} /></i> <span>Description</span>
                                                </h2>
                                                <div className="row">
                                                     <div className="col-md-11">
                                                        <div className="mb-1 row">
                                                            <label className="col-md-3">Datasources count</label>
                                                             <div className="col-md-9 text-break text-muted">
                                                             <span>{this.state.datasourceCount}</span>
                                                             </div>
                                                        </div>
                                                        <div className="mb-1 row">
                                                            <label className="col-md-3">Datasets count</label>
                                                             <div className="col-md-9 text-break text-muted">
                                                             <span>{this.state.datasetCount}</span>
                                                             </div>
                                                        </div>
                                                        <div className="mb-1 row">
                                                            <label className="col-md-3">Total datasource size</label>
                                                             <div className="col-md-9 text-break text-muted">
                                                             <span>{sizeData}</span>
                                                             </div>
                                                        </div>
                                                     </div>
                                                </div></> : ""
                                    }
                                   {this.state.sectionName === ATTRIBUTE ? <><hr className="clearfix"/><div className="form-row">
                                     <div className="col-lg-6">
                                          {this.state.distributionData !== null && this.state.isFetched ?
                                                <BasicPortlet id="dataDistribution"
                                                    video_url="profile_data"
                                                    className="mb-3"
                                                    title={`Top 20 Frequency of ${this.state.attributeName}`}
                                                    shortTitle={`${getShortTitleForAttribute(this.state.attributeName)["requiredName"]}`}
                                                    titlePrefix={`Top 20 Frequency of `}
                                                    toolTipHandle={FQ_TOOLTIP_PLACEHOLDER}
                                                    titleTooltip={getShortTitleForAttribute(this.state.attributeName)["fqTooltipContent"]}
                                                    srcOption={[]}
                                                    content={<ChartBody
                                                        id="test5"
                                                        page="attribute"
                                                        chartType="barChart"
                                                        style={{ height: "350px" }}
                                                        chart_data={this.state.distributionData}
                                                        toolTipTitle="Frequency Distribution"
                                                        xValue="value"
                                                        yValue="count"
                                                        isError={false}
                                                        scale = {MIN_MAX_SCALE}
                                                    />}
                                                />
                                                :
                                                <BasicPortlet id="dataDistribution"
                                                    video_url="profile_data"
                                                    className="mb-3"
                                                    title="Data Distribution" srcOption={[]}
                                                    content={this.state.isFetched ? <div className="text-center text-muted py-5 w-100">
                                                        <h1><FontAwesomeIcon icon={faChartArea} /></h1>
                                                        <h4>Data distribution is not available for the selected attribute name</h4>
                                                        <p>Please check after sometime.</p>
                                                    </div> : <Load/>}
                                                />
                                            }
                                         </div>
                                     </div><hr className="clearfix"/></> : ""}

                                    {this.state.sectionName === ENV_NAME ?
                                          (this.state.envTableData !== null ? <><hr className="clearfix"/><BasicPortlet
                                                className="mb-0 pb-0"
                                                id="envTable"
                                                title="Configured Datasources & Datasets"
                                                content={<QualdoDataTable
                                                            id="envTable"
                                                            data={this.state.envTableData}
                                                            paginationSize={10}
                                                            component_name='environmentInfo'
                                                         />}
                                            /></> : <><hr className="clearfix"/><BasicPortlet
                                                className="mb-0 pb-0"
                                                id="envTableLoading"
                                                title="Configured Datasources & Datasets"
                                                content={
                                                            <Load />
                                                        }
                                            /></>)

                                       : <><div className="form-row">
                                         <div className="col-lg-4">
                                              <BasicPortlet
                                                  className="mb-3 mb-md-0"
                                                  id="summary"
                                                  bodyClassName="p-0"
                                                  title={`${this.state.sectionName} SUMMARY`}
                                                  content={<Summary datasetRow={this.state.datasetRow}
                                                                    dataType={this.state.dataType}
                                                                    nativeType={this.state.nativeType}
                                                                    profileData={profileDataDiscover}
                                                                    datasetName={this.state.datasetName}
                                                                    sectionName={this.state.sectionName}
                                                                    attributeName={this.state.attributeName}
                                                                    lastRefresh={this.state.lastRefresh}
                                                                    attributeCount={this.state.attributeCount}
                                                                    datasourceCount={this.state.datasourceCount}
                                                                    datasetCount={this.state.datasetCount}
                                                                    duplicateRows={this.state.duplicateRows}
                                                                    recencyData={this.state.recencyData}
                                                                    businessRule={this.state.businessRule}
                                                                    sizeData={sizeData} datasetIds={this.state.datasetIds}/>}
                                                  />
                                         </div>
                                         <div className="col-lg-8" key={this.state.isChartChanged}>
                                              <BasicPortlet
                                                  className="mb-3 mb-md-0"
                                                  id="issue"
                                                  bodyClassName="p-0"
                                                  title={`${this.state.sectionName} ISSUES`}
                                                  content={
                                                    (this.state.driftOnMetricsChart !== undefined) ?
                                                    <LazyLoadComponent placeholder={<Load isBootStrapColumn={true}/>}>
                                                            <ChartFilterHeader
                                                                data={this.state.driftOnMetricsChart}
                                                                errorNotAvailable={this.state.driftOnMetricsChart === null}
                                                                filteredData={this.state.filteredData}
                                                                timeFilterApplied={true}
                                                                startDate={this.state.startDateDiscover}
                                                                endDate={this.state.endDateDiscover}
                                                                customTimeFilterFunction={this.applyTimeFilterToChart}
                                                                key={this.state.driftOnMetricsKey}
                                                                dataSetId={this.state.datasetIds.length > 0 ? '' : this.state.datasetIds[0]}
                                                                selectedIntegration={this.props.selectedIntegration}
                                                                metricsOptions={this.state.metricsOptions}
                                                                title="Attribute Drifts"
                                                                id="driftOnMetrics"
                                                                chartType="areaChart"
                                                                page="attribute"
                                                                widgetClass="qd-chartWidget m-0 border-0"
                                                                variant={this.state.variant}
                                                                changeSource={this.changeSource}
                                                                handleLoad={this.handleLoad}
                                                                selectedMetrics={this.getDefaultSelectedMetrics()}
                                                                chartTimeFilter={this.chartTimeFilter}
                                                                dataSetName = {this.state.chartDatasetName !== undefined ? normalizeChildDatasetName(this.state.chartDatasetName) : normalizeChildDatasetName(this.state.datasetName)}
                                                                attributeName = {this.state.chartAttributeName !== undefined ? normalizeAttributeName(this.state.chartAttributeName) : normalizeAttributeName(this.state.attributeName)}
                                                                isAttribute = {true}
                                                                showHeatMap={true}
                                                                showSettingsMenu={false}
                                                                yValue="Value"
                                                                xValue="Time"
                                                                isError={false}
                                                                scale = {MIN_MAX_SCALE}
                                                                hideDistanceFilter = {true}
                                                                showAggregateError = {this.state.showAggregateError}
                                                                showMetricError = {this.state.showMetricError}

                                                            />
                                                    </LazyLoadComponent>
                                                    : <NoErrorComponent message={`Issue charts not available for the selected ${this.state.sectionName}.`}/>
                                                }
                                                  />
                                         </div>
                                    </div>

                                <hr className="clearfix"/>
                                {/* <BasicPortlet
                                      className="mb-0"
                                      id="discoverCards"
                                      bodyClassName="pb-0"
                                      title="PROFILE METRICS"
                                      content={<MetricsCards outlier={this.state.outlier} count={this.state.count} size={this.state.size} profileData={profileDataDiscover} sectionName={this.state.sectionName} selectedDataSource={this.state.selectedDataSource} dataModule={this.props.dataModule} filteredValues={this.state.filteredValues}/>}
                                /> */}
                                 {
                                  // eslint-disable-next-line
                                  this.state.newCardData != undefined ?
                                <BasicPortlet
                                      className="mb-0"
                                      id="discoverCards"
                                      bodyClassName="pb-0"
                                      title="PROFILE METRICS"
                                      content={<MetricsCards outlier={this.state.outlier} count={this.state.count} size={this.state.size} profileData={profileDataDiscover} sectionName={this.state.sectionName} selectedDataSource={this.state.selectedDataSource} dataModule={this.props.dataModule} filteredValues={this.state.filteredValues} cardData={this.state.newCardData}/>}
                                /> : ""
                              }
                                </>}</>
                                :
                                <div className="search-plh">
                                <Image className="search-plh__icon"
                                    src="icon_discover"
                                />
                                <p className="search-plh__desc">Search for your favourite thing or find similar results in the area.</p>
                                </div>
                                }
                            </div>
                        </div>
                   </div>
             </div>
                                                   <button className="btn btn_tree-toggler" onClick={this.handleTreeViewButton}><FontAwesomeIcon icon={faProjectDiagram}/> Tree View</button>
</>);

    }
}

export default DiscoverResults;


class Node extends React.Component {
    constructor(props) {
        super(props);
        let datasourceName = "";
        let datasourceId=null;
        let datasetName = "";
        let datasetId = null;
        let attributeName = "";
        let attributeId = null;
        let envId = null;
        let envName = "";

        if(props.type === DATASOURCE) {
          datasourceName = props.title;
          datasourceId = props.id;
        }else if(props.type === DATASET) {
          datasetName = props.title;
          datasetId = props.id;
        }else if(props.type === ATTRIBUTE){
          attributeName = props.title;
          attributeId = props.id;
        }else {
           envId = props.id
           envName = props.title
        }
        let highlightedClass = ""
        let listClassName = ""
        if(this.props.highlightedId === this.props.id && this.props.highlightedType === this.props.type) {
           highlightedClass = "search-highlight-selected"
           listClassName = "active"
         }

        this.state = {
        isOpen: this.props.isOpen && !this.props.isChanged,
        isShow: this.props.isShow,
        envName: envName === "" ? this.props.envName : envName,
        datasourceName: datasourceName === "" ? this.props.datasourceName : datasourceName,
        datasetName: datasetName === "" ? this.props.datasetName : datasetName,
        attributeName: attributeName,
        envId: envId === null ? this.props.envId : envId,
        highlightedId: this.props.highlightedId,
        highlightedType: this.props.highlightedType,
        datasourceId: datasourceId === null ? this.props.datasourceId : datasourceId,
        datasetId: datasetId === null ? this.props.datasetId : datasetId,
        attributeId: attributeId === null ? this.props.attributeId : attributeId,
        isChanged: false,
        listClassName: listClassName,
        highlightedClass: highlightedClass,
      };
      this.getIcon = this.getIcon.bind(this);
      this.renderNodeObject = this.renderNodeObject.bind(this);
      this.renderAttributeName = this.renderAttributeName.bind(this);
    }


    componentDidUpdate(prevProps){
       if(prevProps.highlightedId !== this.props.highlightedId) {
         this.setState({highlightedType: this.props.highlightedType, highlightedId: this.props.highlightedId});
         if(this.props.highlightedId === this.props.id && this.props.highlightedType === this.props.type) {
           this.setState({highlightedClass: "search-highlight-selected", listClassName: "active"});
         }else {
           this.setState({highlightedClass: "", listClassName: ""});
         }

       }
    }

    toggle = () => {
        let isOpen = this.props.isSearchHappened && !(this.state.isChanged) && [ENV_NAME, DATASOURCE].includes(this.state.type)
        if(isOpen) {
          this.setState({isOpen: false})
        }else{
          this.setState({
          isOpen: !this.state.isOpen,
        });
        }

        let isChanged = this.state.isChanged
        if(!isChanged) {
           this.setState({isChanged: true});
        }
        this.props.changeData(this.state.envName, this.state.datasourceName,
                                     this.state.datasetName, this.state.attributeName,
                                     this.state.envId, this.state.datasourceId,
                                     this.state.datasetId, this.state.attributeId, this.props.id, this.props.type)
    };


    getIcon(type) {
         if(type === ENV_NAME) {
           return faServer;
         }else if(type === DATASOURCE){
           return faDatabase;
         }else if(type === DATASET) {
           return faLayerGroup;
         }else {
            return faTag;
         }
   }
   getUlClassName(type) {
      let className = ""
      if(type === ENV_NAME) {
        className = this.state.isOpen || (this.props.isSearchHappened && !(this.state.isChanged))  ? "nested tree_top-level active" : "nested"
      }else if(type === DATASOURCE) {
        className = this.state.isOpen || (this.props.isSearchHappened && !(this.state.isChanged)) ? "nested active" : "nested"
      }else if(type === DATASET) {
        className = this.state.isOpen ? "nested has-no-caret active" : "nested has-no-caret"
      }
      return className;
   }
   getHighlightedText(text, highlight) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (parts.map((part, i) =>
          <span key={i}  className={part.toLowerCase() === highlight.toLowerCase() ? (this.props.isTreeSearch ? "tree-search-text-highlight":"search-text-highlight"): ""}>
            { part }
          </span>)
        );
    }



  renderAttributeName(title, type) {
    let reqAttrText;
    let fqTooltipContent = null;
    if (this.props.subString) {
      reqAttrText = this.getHighlightedText(title, this.props.subString);
    } else {
      let shortNameData = getShortTitleForAttribute(title);
      fqTooltipContent = shortNameData["fqTooltipContent"];
      if (fqTooltipContent === undefined || fqTooltipContent === null) {
        reqAttrText = title;
      } else {
        // Use short name instead of full attribute name
        reqAttrText = shortNameData["requiredName"]
      }
    }

    let reqClass = this.state.isShow ? (this.state.highlightedClass !== "" ? this.state.highlightedClass : (this.props.subString ? "" : "search-highlight1")) : ""

    if (this.props.subString || fqTooltipContent === null) {
      // No need to show tooltip for attribute.
      return (
        <span onClick={this.toggle}
          className={reqClass}><i>
            <FontAwesomeIcon icon={this.getIcon(type)} />
          </i>
          {reqAttrText}
        </span>
      );
    }

    return (
      <>

        <span onClick={this.toggle}
          className={reqClass}>
          <RcTooltip placement="top"
            overlay={renderAttributeTitleToolTip(fqTooltipContent)}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
            <i>
              <FontAwesomeIcon icon={this.getIcon(type)} />
            </i>
          </RcTooltip>

          <RcTooltip placement="top"
          overlay={renderAttributeTitleToolTip(fqTooltipContent)}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
          <span>{FQ_TOOLTIP_PLACEHOLDER}</span>
        </RcTooltip>

        <RcTooltip placement="top"
          overlay={renderAttributeTitleToolTip(fqTooltipContent)}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
          <span>{reqAttrText}</span>
        </RcTooltip>

        </span>
      </>
    );
  }

  renderNodeObject(id, title, childNodes, type, isOpen, isShow) {
    if (isShow || !this.props.isRedirect || ([ENV_NAME, DATASOURCE].includes(type) && this.props.isSearchHappened && !(this.state.isChanged))) {

      let reqNodeMap = allNodeMap.get(type);
      let currNode = reqNodeMap.get(id);
      if (currNode !== undefined && currNode !== null){
        // Currently we will not reach here since setting new node to map is commented
        // reqNodeMap.set in the last line of this function before return statement
        return currNode;
      }

      let createdNode =  (
        <Node key={title}
          title={title}
          id={id}
          changeData={this.props.changeData}
          isSearchHappened={this.props.isSearchHappened}
          isTreeSearch={this.props.isTreeSearch}
          envName={this.state.envName}
          envId={this.state.envId}
          isRedirect={this.props.isRedirect}
          highlightedType={this.state.highlightedType}
          highlightedId={this.state.highlightedId}
          datasourceName={this.state.datasourceName}
          datasetName={this.state.datasetName}
          attributeName={this.state.attributeName}
          datasourceId={this.state.datasourceId}
          datasetId={this.state.datasetId}
          attributeId={this.state.attributeId}
          childNodes={childNodes !== undefined ? Object.values(childNodes) : []}
          type={type} isOpen={isOpen}
          subString={this.props.subString}
          isShow={isShow}
          isChanged={this.state.isChanged} />)

        // reqNodeMap.set(id, createdNode);
        return createdNode;

    }
  }

   render() {
        const { title, childNodes, type } = this.props;

        let ulClassName = this.getUlClassName(type);
        let childrenLength = childNodes.filter(x=>x.isShow === true)
        let uniqueId = `${this.state.envId}_${this.state.datasourceId}_${this.state.datasetId}_${this.state.attributeId}`;
        uniqueId = uniqueId.replace(undefined, "_")
        return (
          <li className={this.state.listClassName} id={uniqueId}>
            {type !== ATTRIBUTE ?
            <span className={childrenLength.length === 0 && this.props.isRedirect ? "caret caret-invisible" : (this.state.isOpen ||(this.props.isSearchHappened && [ENV_NAME, DATASOURCE].includes(type) && !(this.state.isChanged)) ? "caret caret-down" : "caret")} onClick={this.toggle}>
              <i>
                <FontAwesomeIcon icon={this.getIcon(type)} />
              </i>
              {this.props.subString ?
              this.getHighlightedText(title, this.props.subString): title}
            </span> :

              this.renderAttributeName(title, type)

            }
            {((type !== ATTRIBUTE && (childrenLength.length > 0 || !this.props.isRedirect) && this.state.isOpen) || ([ENV_NAME, DATASOURCE].includes(type) && !(this.state.isChanged) && this.props.isSearchHappened)) && (
                <ul className={ulClassName}>
                {childNodes.map(({ id, title, childNodes, type, isOpen, isShow }) => (
                  this.renderNodeObject(id, title, childNodes, type, isOpen, isShow)

                ))}
              </ul>
            )}
          </li>
        );
   }
}
