// import {
//     // preFetchRelationshipData,
//     preFetchMetaData, preFetchUserNotifications, prefetchDatasetDetails, prefetchRecencyDetails, prefetchRecentHistory,
//     prefetchModelABData, prefetchDataSize, preFetchMetadataDetails
// } from "../utils/dataFetch";

import{
    prefetchRecencyDetails
} from "../utils/dataFetch";

// import {
    // preFetchMonitorPreview,
    // preFetchMonitorInfo,
    // preFetchMonitorModelInfo,
    // preFetchModelDetails,
    // preFetchMonitorListViewData,
    // preFetchMetrics,
    // preFetchFollowAttributes
// } from "../utils/monitorDataFetch";

// import {
//     addFollowAttributes, addUserNotificationToState,
//     addDatasetVersions, addRecencyDetails, setModelABDataToList,
//     addRecentHistory, addDataSize, storeMetadataDetails
// } from "../redux/actions";

import {
    addRecencyDetails    
} from "../redux/actions";

import { LOGIN_SOURCE, MANDATE_RESET_PASSWORD, LOCAL_STORE_KEY, PERMISSIONS_LOCAL_STORE_KEY } from '../utils/constant';
import { browserHistory } from 'react-router';
import {
    encryptForLocalUsage, decryptForLocalUsage, getRedirectionLinkToChangePassword,
    getRedirectionLinkToExpiredPlan, isDataLimitExceeded, getRedirectionLinkToDiscover
} from "../utils/common_utils";

import store from "../redux/store";
import mixpanel from 'mixpanel-browser';

export function perform_remember_me_checks(rememberMe, email, password) {
    if (rememberMe === true) {
        localStorage.setItem("rememailId", email);
        localStorage.setItem("rempassword", password);
        localStorage.setItem("rememberMe", rememberMe);
    } else {
        localStorage.setItem("rememailId", "");
        localStorage.setItem("rempassword", "");
        localStorage.setItem("rememberMe", "");
    }
}

export function perform_login_initial_setup(source, response, details) {
    /***
     * This function is used for calling the necessary prefetch APIs and 
     * performing initial setup and checks after login process.
     * This function is used by Normal Sign-in flow, Google sign-in flow.
     * 
     *  @param {str}  source Value can be 'normal_login' or 'google_login'
     */

    if (response === undefined) {
        // We did not get response from server. Due to server/error or 
        // connection issue on client side
        this.setState({ errors: "Could not login" });
        return;
    }

    if (response.code !== 200) {
        // We get response from server but it is invalid
        let errorMsg = response.message;
        if (errorMsg === "Bad request") {
            errorMsg = "An unexpected error occurred. Please try logging in again.";
        }

        let setPasswordLink = this.state.setPasswordLink;
        if (errorMsg === "Please set password or you can login using google!.") {
            // This case will be used when google sign in user is trying normal signup for first time.
            // We will not have password in db. So we are redirecting them to set password page 
            localStorage.setItem("setPassword", true);
            setPasswordLink = true;
        } else {
            setPasswordLink = false;
        }
        this.setState({
            errors: errorMsg, hideSubmitButton: false,
            setPasswordLink: setPasswordLink
        });
        return;
    }


    // We are getting successful response from server. So proceed prefetch 
    // and other initial setups that should be done after login.
    const now = new Date();
    const responseData = response['response'];
    let feature_access = responseData["feature_access"];
    const encryptedFA = encryptForLocalUsage(feature_access);
    localStorage.setItem('featureAccess', encryptedFA);

    const item = {
        value: responseData['auth_token'],
        user_id: responseData['user_id'],
        team_id: responseData['team_id'],
        expiry: now.getTime() + 150 * 60000
    };

    setTimeout(() => browserHistory.push('/'), 9000000);

    localStorage.setItem('sessionToken', JSON.stringify(item));

    if (details === undefined || details === null) {
        details = {};
    }

    // These keys used only for new user when redirected from web page
    localStorage.removeItem("isPlanDetailAvailable");
    localStorage.removeItem("qualdoPlan");
    localStorage.removeItem("qualdoPlanCycle");
    localStorage.removeItem("qualdoEdition");

    let email = details["email"];
    if (source === LOGIN_SOURCE.MSI_LOGIN_SRC) {
        // For MSI Login, we will get email from response data
        email = responseData.email
    }

    if (email !== undefined && email !== null){
        localStorage.removeItem("emailId");
        localStorage.setItem('emailId', email);
    }

    let connector_added = responseData["connector_added"];

    if (source === LOGIN_SOURCE.NORMAL_LOGIN_SRC) {
        // The following function call is need only for normal signup flow
        let rememberMe = details["rememberMe"];
        let password = details["password"];
        perform_remember_me_checks(rememberMe, email, password);
    }

    const accountFeatures = response["account_features"]["plan_details"];
    accountFeatures["payment_status"] = response["account_features"]["payment_status"];

    // Storing warning_message key into accountFeature and update buffer and expire key name
    accountFeatures['warning_message'] = response["account_features"]["warning_message"]
    accountFeatures['isPlanExpired'] = accountFeatures["is_plan_expired"]
    accountFeatures['isInBufferPeriod'] = accountFeatures["is_in_buffer_period"]

    // Encrypt User Details
    let encrypt_data = encryptForLocalUsage(accountFeatures)
    localStorage.setItem(LOCAL_STORE_KEY, encrypt_data)
    let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))

    // localStorage.setItem("warning_message", response["account_features"]["warning_message"]);
    // localStorage.setItem("isPlanExpired", accountFeatures["is_plan_expired"]);
    // localStorage.setItem("isInBufferPeriod", accountFeatures["is_in_buffer_period"]);

    localStorage.setItem("plan_details", JSON.stringify(accountFeatures));

    let passwordToken = null;
    let mandateToResetPassword = false;

    // Mandate password flow is applicable only for normal signup
    if (source === LOGIN_SOURCE.NORMAL_LOGIN_SRC) {
        mandateToResetPassword = String(responseData['reset_password']) === "1";
    }

    if (mandateToResetPassword) {
        passwordToken = MANDATE_RESET_PASSWORD.One;
        localStorage.setItem(MANDATE_RESET_PASSWORD.Reset_Password, passwordToken);
    }

    let teamID = responseData['team_id'];
    localStorage.setItem('team_id', teamID);
    localStorage.setItem('user_name', responseData['user_name']);
    localStorage.setItem('user_id', responseData['user_id']);
    localStorage.setItem("org_id", responseData['org_id']);
    localStorage.setItem('user_signup_type', responseData['user_signup_type']);
    let permissions_encrypt_data = encryptForLocalUsage(response["response"]["permissions"])
    localStorage.setItem(PERMISSIONS_LOCAL_STORE_KEY, permissions_encrypt_data);
    localStorage.setItem("v_ky", encryptForLocalUsage(responseData['video_key']));
    localStorage.setItem("v_tk", encryptForLocalUsage(responseData['video_token']));

    let tenantData = { "tenant_id": teamID };
    let currentStatus = response["response"]["current_status"];

    if (currentStatus === "disabled") {
        this.props.router.push("/user_status");
        return;
    }

    if (email !== undefined && email !== null) {
        mixpanel.init('155c019784ab639716eb7828d60964e2', { debug: true });
        mixpanel.identify(email);
        mixpanel.track('Users logged in', {});
        mixpanel.people.set({
            $email: email
        });
    }

    if (mandateToResetPassword) {
        // Currently mandate reset password will be used in AWS managed service 
        // when user logins for the first time with generated password.
        let redirectionLink = getRedirectionLinkToChangePassword();
        this.props.router.push(redirectionLink);
        return;
    }

    let setUpComplete = responseData["setup_status"] === "completed";
    let isPlanExpired = decrypt_data['isPlanExpired']
    let isInBufferPeriod = decrypt_data['isInBufferPeriod']
    let isDrxFreeTrail = decrypt_data['is_drx_free_trail']
    let DrxFreeTrailStatus = decrypt_data['drx_free_trail_status']
    if (isDrxFreeTrail !== undefined && isDrxFreeTrail !== null && isDrxFreeTrail === true){
        if(DrxFreeTrailStatus !== undefined && DrxFreeTrailStatus !== null && DrxFreeTrailStatus === "Expired"){
            // Fetch Recency Details
            prefetchRecencyDetails(tenantData, addRecencyDetails, store);
                        
            let redirectionLink = getRedirectionLinkToDiscover();
            this.props.router.push(redirectionLink);
            return;
        }
    }

    if (isPlanExpired === true && isInBufferPeriod === false) {
        let redirectionLink = getRedirectionLinkToExpiredPlan();
        this.props.router.push(redirectionLink);
        return;
    }

    if (!setUpComplete) {
        // Setup is not complete(User has not created any datasource so far). 
        // So we will redirect to corresponding setup page
        if (feature_access["skip_setup"] === true) {
            localStorage.setItem("DataSetupStatus", true);
            this.props.router.push("/quick-start");
        } else {
            localStorage.setItem("DataSetupStatus", false);
            this.props.router.push("/setup");
        }

        // no need to proceed since set up is incomplete.
        return;
    }

    // User has completed the setup.(Created at least one data source). 
    // So we can start prefetching other details
    this.props.setInitialValues();
    this.props.setTenantId(teamID);
    this.props.setMonitorInitialValues();

    /* ##
    preFetchMonitorPreview(tenantData, this.props.addMonitorPreview,
        this.props.addPerformancePreview,
        this.props.addModelPerformance); */
    /*preFetchRelationshipData(
        tenantData, this.props.setDataSource, this.props.addRelationship,
        this.props.addDataSource, this.props.addInfo, this.props.addLastProfilingTime
    );*/
    /* ##
    preFetchMetaData(
        tenantData, this.props.addMetaData, this.props.addEnvironment,
        this.props.addEnvironmentDataSourceMapping, this.props.addDataSourceDataSetMapping,
        this.props.addDataSetAttributeMapping, this.props.setDataSet, this.props.setAttribute,
        this.props.setDataSource, this.props.setMlDataSource, this.props.addRelationship, this.props.addDataSource,
        this.props.addInfo, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate, this.props.addDiscoveryStatus, this.props.addProcessingStatus,
        this.props.noIntegration
    );
    preFetchMonitorInfo(
        tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
        this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
        this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
        this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
        this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
        this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
        this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
        this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview, this.props.prefetchMonitorState
    );
    preFetchMonitorModelInfo(
        teamID, this.props.addMLModelMapping,
        this.props.addMLDataSetInfo, this.props.addMLAttributeInfo,
        this.props.addMLModel, this.props.setMLModel
    );*/

    //                        preFetchModelPerformanceInfo(
    //                            teamID, this.props.addModelPerformance
    //                        );

    /* ##
    preFetchModelDetails(teamID, this.props.addModelDetails);

    preFetchMetrics(teamID, this.props.addMetrics);

    preFetchFollowAttributes(tenantData, addFollowAttributes, store);

    preFetchMonitorListViewData(teamID, this.props.addMonitorDQListViewData);

    // Fetch first page result of user notifications
    preFetchUserNotifications(tenantData, addUserNotificationToState, store);

    // Fetch dataset version details
    prefetchDatasetDetails(tenantData, addDatasetVersions, store);

    // Fetch Recency Details
    prefetchRecencyDetails(tenantData, addRecencyDetails, store);

    // Fetch Model AB configuration list
    prefetchModelABData(tenantData, setModelABDataToList, store);

    // Fetch recent search history - discover page
    prefetchRecentHistory(tenantData, addRecentHistory, store);

    // Fetch data size - discover page
    prefetchDataSize(tenantData, addDataSize, store)

    // Fetch Metadata details used in custom alerts
    preFetchMetadataDetails(tenantData, storeMetadataDetails, store)
    */

    let isPbiEnabled = feature_access["powerbi"] === true;
    let dataLimitExceeded = isDataLimitExceeded();
    if (isPlanExpired === true && isInBufferPeriod === false) {
        let redirectionLink = getRedirectionLinkToExpiredPlan();
        this.props.router.push(redirectionLink);
    } else if (isPbiEnabled && connector_added === false) {
        this.props.router.push("/pbi-setup");
    } else if (dataLimitExceeded) {
        this.props.router.push("/plan");
    } else if (mandateToResetPassword) {
        let redirectionLink = getRedirectionLinkToChangePassword();
        this.props.router.push(redirectionLink);
    } else {
        let redirectUrl = "/configure";
        if (isPbiEnabled) {
            redirectUrl = "/pbi-report";
        }
        this.props.router.push(redirectUrl);
    }

}