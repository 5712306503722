import React from 'react';
import Select from 'react-select';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postAWSLambda, validateAWSCredentials }  from "../../utils/event_handling";
import {userValidation} from '../../utils/common_utils'


class AWSLambdaForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateAWSCredentials = validateAWSCredentials.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.postAWSLambda = postAWSLambda.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeSecretKeyIcon = this.ChangeSecretKeyIcon.bind(this);
        this.state = {
            check: false,
            options: [],
            lambda: {},
            hideSubmitButton: false,
            SecretKeyIcon:faEye,
            SecretKeyType:'password',
            awsLambda:{
                awsLambdaName: '',
                awsLambdaFunctionName: '',
                params: '',
                awsAccessId: '',
                awsSecretKeyAccess: '',
                awsRegion : '',
                payload : ''
            },
            errors: {
            }
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleCancel() {
        this.setState({check:false});
    }

    componentDidMount() {
        const exAwsLambdaDetails = this.props.ex_details;
        if (exAwsLambdaDetails === undefined) {
            return;
        }
        let awsLambda = this.state.awsLambda;
        awsLambda.awsLambdaName = exAwsLambdaDetails.pipeline_trigger_name;
        const exConfigurationDetails = exAwsLambdaDetails.configuration;
        awsLambda.awslambdaFunctionName = exConfigurationDetails.url;
        awsLambda.awsAccessId = exConfigurationDetails.aws_access_key_id;
        awsLambda.awsSecretKeyAccess = exConfigurationDetails.aws_secret_access_key;
        awsLambda.awsRegion = exConfigurationDetails.aws_region_name;
        awsLambda.payload = exConfigurationDetails.payload;
        this.setState({awsLambda:awsLambda});

    }


    requires_pipeline_update(lambdaData){
        const exLambdaDetails = this.props.ex_details;
        if (lambdaData.pipeline_trigger_name !== exLambdaDetails.awsLambdaName) {
            return true;
        }
        const exConfiguration = exLambdaDetails.configuration;
        const currentConfiguration = lambdaData.configuration;
        if (currentConfiguration.url !== exConfiguration.url) {
            return true;
        }
        if (currentConfiguration.aws_access_key_id !== exConfiguration.aws_access_key_id) {
            return true;
        }
        if (currentConfiguration.aws_secret_access_key !== exConfiguration.aws_secret_access_key) {
            return true;
        }
        if (currentConfiguration.aws_region_name !== exConfiguration.aws_region_name) {
            return true;
        }
        if (currentConfiguration.payload !== exConfiguration.payload) {
            return true;
        }
        return false;
    }

    ChangeSecretKeyIcon() {
        let state = this.state.SecretKeyIcon;
        if (state.iconName === "eye") {
            this.setState({SecretKeyIcon: faEyeSlash});
            this.setState({SecretKeyType: "text"})
          } else {
            this.setState({SecretKeyIcon: faEye});
            this.setState({SecretKeyType: "password"})
          }
    }

    render() {
        const awsLambdaSubmitSchema = yup.object({
            awsLambdaName:  yup.string().min(1,"Name must be at least 1 characters").required("AWs Name is a required field").test(
                'Aws name test',
                'invalid Aws name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            awsLambdaFunctionName: yup.string().required("Lambda Function is a required field"),
            payload: yup.string().required("payload is a required field") 
        });
        const awsLambdaValidate = yup.object({
            awsLambdaName:  yup.string().min(1,"Name must be at least 1 characters").required("AWs Name is a required field").test(
                'Aws name test',
                'invalid Aws name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            awsAccessId: yup.string().required("AWS Access id is a required field"),
            awsSecretKeyAccess: yup.string().required("AWS Secret Key Token is a required field"),
            awsRegion: yup.string().required("AWS Region is a required field"),
        });

        if (this.state.check === true) {
            return (
                    <>
                            <Formik
                                validationSchema={awsLambdaSubmitSchema}
                                onSubmit={this.postAWSLambda}
                                initialValues={this.state.awsLambda}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                      <Form.Group controlId="awsLambdaFunctionName" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Select Lambda Function<span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                <Select
                                                    name="awsLambdaFunctionName"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    id="awsLambdaFunction"
                                                    options={this.state.options}
                                                    defaultValue={this.state.lambda}
                                                    isInvalid={errors.awsLambdaFunctionName && touched.awsLambdaFunctionName}
                                                    onChange={selectedOption => {
                                                        handleChange("awsLambdaFunctionName")(selectedOption.value.toString());
                                                    }}
                                                    classNamePrefix='select-control'
                                                    placeholder="Select AWS Lambda Function"
                                                />
                                                <ErrorMessage component="div" className="error-text" name="awsLambdaFunctionName" />
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="payload" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Payload <span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                 <Form.Control
                                                       type="text"
                                                       name="payload"
                                                       value={values.payload}
                                                       className="form-control"
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       isInvalid={errors.payload && touched.payload}
                                                       placeholder="AWS Lambda Payload"/>
                                                 <Form.Control.Feedback type="invalid">
                                                           { touched.payload && errors.payload }
                                                 </Form.Control.Feedback>
                                            </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                            <div className="col-md-5 offset-sm-4">
                                              <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                                </button>
                                            </div>
                                      </Form.Group>
                                </Form>
                                )}
                            </Formik>
                    </>
            );
        } else {
                return (
                             <>
                                    <Formik
                                        validationSchema={awsLambdaValidate}
                                        onSubmit={this.validateAWSCredentials}
                                        initialValues={this.state.awsLambda}
                                    >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                          <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                            <Form.Group controlId="awsLambdaName" className="row">
                                                    <Form.Label className="col-md-4 col-form-label">Name <span className="text-danger">*</span> :</Form.Label>
                                                    <div className="col-md-5">
                                                        <Form.Control
                                                            type="text"
                                                            name="awsLambdaName"
                                                            value={values.awsLambdaName}
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.awsLambdaName && touched.awsLambdaName}
                                                            placeholder="AWS Lambda Name"/>
                                                        <Form.Control.Feedback type="invalid">
                                                                { touched.awsLambdaName && errors.awsLambdaName }
                                                        </Form.Control.Feedback>
                                                    </div>
                                            </Form.Group>
                                            
                                            <Form.Group controlId="url" className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Access Id Key <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          onChange={handleChange}
                                                          name="awsAccessId"
                                                          value={values.awsAccessId}
                                                          className="form-control"
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsAccessId && touched.awsAccessId}
                                                          placeholder="AWS Access Key" />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsAccessId && errors.awsAccessId }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="awsSecretKeyAccess"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Secret Key Access <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faLock}/>
                                                    </i>
                                                    <Form.Control
                                                          type={this.state.SecretKeyType}
                                                          name="awsSecretKeyAccess"
                                                          onChange={handleChange}
                                                          value={values.awsSecretKeyAccess}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsSecretKeyAccess && touched.awsSecretKeyAccess}
                                                          className="form-control"
                                                          placeholder="AWS Secret Key Access"
                                                          autoComplete={this.props.autocomplete}
                                                          />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsSecretKeyAccess && errors.awsSecretKeyAccess }
                                                    </Form.Control.Feedback>
                                                    <i className="security-eye">
                                                        <FontAwesomeIcon onClick={this.ChangeSecretKeyIcon} icon={this.state.SecretKeyIcon} className="security-eye"/>
                                                    </i>
                                                </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="awsRegion"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">AWS Region <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          name="awsRegion"
                                                          onChange={handleChange}
                                                          value={values.awsRegion}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.awsRegion && touched.awsRegion}
                                                          className="form-control"
                                                          placeholder="AWS Region"/>
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.awsRegion && errors.awsRegion }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <div className="col-md-5 offset-sm-4">
                                                    <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                    <button type="submit" disabled={this.state.hideSubmitButton} onClick={this.handleClick} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                                    </button>
                                                </div>
                                            </Form.Group>

                                         </Form>
                                    )}
                                    </Formik>
                             </>
                );
        }
    }

}


export default AWSLambdaForm;

