import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { SimpleHeader } from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import SimpleBasicPortlet from '../components/simpleBasicPortlet';
import Image from '../components/image';
import AddDataSources from '../configure/dataSources/addDataSources';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toastObj,userValidation} from "../utils/common_utils";
import ErrorHandler from '../components/error_500';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { call_post_api, postUIlogs }  from "../utils/event_handling";
import {ui_service_ip} from "../utils/ip_details";
import {CopyRightsFooter} from '../components/copyrights';



class Setup extends React.Component {
    constructor(props) {
        super(props);
        this.showToast =this.showToast.bind(this);
        this.closeToast= this.closeToast.bind(this);
        this.addEnvironment = this.addEnvironment.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.setAddDS = this.setAddDS.bind(this)
        this.setEnvData = this.setEnvData.bind(this)
        this.state = {
            errorOccurred: false,
            toastStatus: false,
            page: "add_env",
            errors: {
            },
            env_id:null,
            diasableAddEnvBtn:false,
            isAddDS: false,
        }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    addEnvironment(event) {
            this.setState({diasableAddEnvBtn:true})
            let { environment } = event;
            let session = localStorage.getItem("sessionToken");
            const set_env_data = {env_name: environment, tenant_id: localStorage.getItem('team_id')};
            call_post_api(ui_service_ip, 'environment', set_env_data, session).then((response) => {
                if(response.code === 200) {
                    this.setState({env_id:response['env_data']['env_id']})
                    // localStorage.setItem("env_id", response['env_data']['env_id']);
                    // localStorage.getItem('env_id');
                    this.showToast("alert-success","Environment added successfully",'')
                } else if(response.code === 403 ) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({diasableAddEnvBtn:false})
                   this.showToast("alert-danger",response.message,'')
                }
            });
    }

   closeToast() {
        this.setState({toastStatus:false});
        if (this.state.page === "add_integration" && this.state.toastClass.includes("toast-success") && this.state.nextPage !== null) {
            window.location = "/quick-start";
        } else if(this.state.page === "add_env" && this.state.toastClass.includes("toast-success")) {
            this.setState({page:"add_integration"});
        } else {
            console.error("failure");
        }
   }

    showToast(status, msg, link) {
        this.setState({
          toastStatus: true,
          toastMsg: msg,
          toastClass: toastObj[status]["className"],
          nextPage:link,
          toastIcon:toastObj[status]["icon"],
          toastHeading:toastObj[status]["heading"]
        });
    }

    setEnvData(data){
        this.setState({env_id:data})
    }

    setAddDS(value) {
        this.setState ({isAddDS: value});
   }

    renderTable() {
        if(this.state.page === "add_integration") {
            return <div className="portlet portlet-2col">
                {!this.state.isAddDS ?
                    <>
                        <SimpleBasicPortlet
                            className="pb-0" title="Datasources" bodyClassName="pb-0"
                            content={
                            <AddDataSources
                                        src_page="setup"
                                        setPage={this.setAddDS}
                                        showCancel={false}
                                        setEnvData={this.setEnvData}
                                        showToast={this.showToast}
                                        env_id={this.state.env_id}
                                    />}
                        />
                    </>
                    :
                    ''
                }
                        
                   </div>
        } else if(this.state.page === "add_env") {
               const envSchema = yup.object({
                    environment: yup.string().min(1,"Name must be at least 1 characters").required("Environment is a required field").test(
                        'environmentName test',
                        'invalid Environment name',
                        function test(value) {
                           let val = userValidation(value,"input");
                           return val;
                    }
                    ),
               });
               return <div className="row">
                        <Formik
                                 initialValues={{ environment: '' }}
                                 validationSchema={envSchema}
                                 onSubmit={this.addEnvironment}
                        >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              isValid,
                        }) => (
                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                              <Form className="form-row needs-validation mx-auto"
                                    noValidate method="post"
                                    name='setup_add_environment'
                                    onSubmit={handleSubmit} >
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Control
                                        className="placeholder-no-fix"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add Environment"
                                        name="environment"
                                        value={values.environment}
                                        onChange={handleChange}
                                        isInvalid={!!errors.environment}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                             { errors.environment }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="col-sm-6 text-center">
                                    <div className="text-md-left">
                                        <button type="submit" disabled={this.state.diasableAddEnvBtn} className="btn btn-primary btn-circle">
                                            <i><FontAwesomeIcon icon={faPlusCircle}/></i> Add Environment
                                        </button>
                                    </div>
                                </div>
                              </Form>
                            </div>
                        )}
                        </Formik>

               </div>
        }
    }

    render() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }

        return(
            // this.state.errorOccurred ?
            //     <ErrorHandler/>
            // :
            <>
            {this.state.errorOccurred ? <ErrorHandler/> : ""}
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                           onClose={() =>  this.closeToast()}
                           show={this.state.toastStatus}
                           delay={3000} autohide>
                        <div className="d-flex align-items-center">
                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon}/>
                                </i>
                                :
                                ''
                            }

                        <Toast.Body>
                            <strong className="toast-title">{this.state.toastHeading}</strong>
                            <p>{this.state.toastMsg}</p>
                        </Toast.Body>
                        <button type="button"
                                onClick={this.closeToast}
                                className="close"
                                data-dismiss="toast"
                                aria-label="Close"/>
                        </div>
                    </Toast>
                </div>
                <SimpleHeader/>
                <main>
                    <BreadCrumb icon='plusCircle' title='Datasource Setup' />
                    <div className="qd-container">
                        <div className="qd-body no-tabs p-3">
                            <div className="col-md-8 offset-md-2 text-center p-3">
                                <i className="d-inline-block mb-3">
                                    <Image
                                        src="addLogo"
                                    />
                                </i>
                                <h4 className="mb-3">Add your first Datasource</h4>
                                <p>
                                   Datasources are the files and datasets either containing the raw data or the model data that need to be monitored. Environment is a grouping of a set of datasources.
                                   Eg: Production Environment. Add an environment to get started with your first datasource.
                                </p>
                            </div>
                            {this.renderTable()}
                        </div>
                    </div>
                </main>
                <CopyRightsFooter/>
            </>
        );
    }
}

export default Setup;