export const ADD_METADATA = "ADD_METADATA";
export const ADD_RELATIONSHIP = "ADD_RELATIONSHIP";
export const ADD_ML_RELATIONSHIP = "ADD_ML_RELATIONSHIP";
export const ADD_INFO = "ADD_INFO";
export const SET_DATA_SOURCE = "SET_DATA_SOURCE";
export const SET_ML_DATA_SOURCE = "SET_ML_DATA_SOURCE";
export const SET_CHIP_DATA = "SET_CHIP_DATA";
export const SET_ML_CHIP_DATA = "SET_ML_CHIP_DATA";
export const ADD_PROCESSING_STATUS = "ADD_PROCESSING_STATUS";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_FILTERED_DATA = "SET_FILTERED_DATA";
export const SET_ML_MODEL = "SET_ML_MODEL";
export const SET_TIME_FILTER = "SET_TIME_FILTER";
export const ADD_DATA_SOURCE = "ADD_DATA_SOURCE";
export const ADD_DISCOVERY_STATUS = "ADD_DISCOVERY_STATUS";
export const ADD_LAST_PROFILING_TIME = "ADD_LAST_PROFILING_TIME";
export const ADD_ENVIRONMENT = "ADD_ENVIRONMENT";
export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const ADD_ENVIRONMENT_DATA_SOURCE_MAPPING = "ADD_ENVIRONMENT_DATA_SOURCE_MAPPING";
export const ADD_DATA_SOURCE_DATA_SET_MAPPING = "ADD_DATA_SOURCE_DATA_SET_MAPPING";
export const ADD_DATA_SET_ATTRIBUTE_MAPPING = "ADD_DATA_SET_ATTRIBUTE_MAPPING";
export const SET_TENANT_ID = "SET_TENANT_ID";
export const SET_INITIAL_VALUES = "SET_INITIAL_VALUES";
export const SET_DATA_SET = "SET_DATA_SET";
export const SET_ATTRIBUTE = "SET_ATTRIBUTE";
export const ADD_MONITOR_PREVIEW = "ADD_MONITOR_PREVIEW";
export const ADD_DQ_MONITOR_PREVIEW = "ADD_DQ_MONITOR_PREVIEW";
export const ADD_PERFORMANCE_PREVIEW = "ADD_PERFORMANCE_PREVIEW";
export const SET_MONITOR_INITIAL_VALUES = "SET_MONITOR_INITIAL_VALUES";
export const ADD_COMPLETENESS_INFO = "ADD_COMPLETENESS_INFO";
export const ADD_COMPLETENESS_ATTRIBUTE_INFO = "ADD_COMPLETENESS_ATTRIBUTE_INFO";
export const ADD_TIMELINESS_INFO = "ADD_TIMELINESS_INFO";
export const ADD_TIMELINESS_ATTRIBUTE_INFO = "ADD_TIMELINESS_ATTRIBUTE_INFO";
export const ADD_ACCURACY_INFO = "ADD_ACCURACY_INFO";
export const ADD_ACCURACY_ATTRIBUTE_INFO = "ADD_ACCURACY_ATTRIBUTE_INFO";
export const ADD_DRIFT_INFO = "ADD_DRIFT_INFO";
export const ADD_DRIFT_ATTRIBUTE_INFO = "ADD_DRIFT_ATTRIBUTE_INFO";
export const ADD_CONFORMITY_INFO = "ADD_CONFORMITY_INFO";
export const ADD_CONFORMITY_ATTRIBUTE_INFO = "ADD_CONFORMITY_ATTRIBUTE_INFO";
export const ADD_CONSISTENCY_INFO = "ADD_CONSISTENCY_INFO";
export const ADD_CONSISTENCY_ATTRIBUTE_INFO = "ADD_CONSISTENCY_ATTRIBUTE_INFO";
export const ADD_ML_MODEL_MAPPING = "ADD_ML_MODEL_MAPPING";
export const ADD_ML_MODEL_ATTRIBUTE_INFO = "ADD_ML_MODEL_ATTRIBUTE_INFO";
export const ADD_ML_MODEL_INFO = "ADD_ML_MODEL_INFO";
export const ADD_ML_MODEL_PERFORMANCE = "ADD_ML_MODEL_PERFORMANCE";
export const ADD_ML_MODEL_DETAILS = "ADD_ML_MODEL_DETAILS";
export const ADD_MONITOR_DQ_LIST_VIEW = "ADD_MONITOR_DQ_LIST_VIEW";
export const ADD_METRICS = "ADD_METRICS";
export const USER_NOTIFICATIONS = "USER_NOTIFICATIONS";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATIONS_AS_READ";
export const REMOVE_NOTIFICATION_FROM_LIST = "REMOVE_NOTIFICATION_FROM_LIST";
export const ADD_DATASET_VERSIONS = "ADD_DATASET_VERSIONS";
export const ADD_UNIQUENESS_INFO = "ADD_UNIQUENESS_INFO";
export const ADD_UNIQUENESS_ATTRIBUTE_INFO = "ADD_UNIQUENESS_ATTRIBUTE_INFO";
export const ADD_RECENCY_DETAILS = "ADD_RECENCY_DETAILS";
export const SET_MODEL_AB_LIST = "SET_MODEL_AB_LIST";
export const ADD_RECENCY_PREVIEW = "ADD_RECENCY_PREVIEW";
export const ADD_RECENT_HISTORY = "ADD_RECENT_HISTORY";
export const ADD_DATA_SIZE = "ADD_DATA_SIZE";
export const ADD_FOLLOW_ATTRIBUTES = "ADD_FOLLOW_ATTRIBUTES";
export const PREFETCH_MONITOR_STATE = "PREFETCH_MONITOR_STATE";
export const STORE_IP_DATA = "STORE_IP_DATA";
export const ADD_METADATA_DETAILS = "ADD_METADATA_DETAILS"
export const NO_INTEGRATION = "NO_INTEGRATION";
export const FORM_DATASET_INFO = "FORM_DATASET_INFO";
export const FORM_LISTVIEW_TABLE_DATA = "FORM_LISTVIEW_TABLE_DATA";
export const ADD_DATASET_LAST_REFRESH = "ADD_DATASET_LAST_REFRESH";
export const ADD_DATASOURCE_DISCOVER_PAGE = "ADD_DATASOURCE_DISCOVER_PAGE";
