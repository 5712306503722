import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import RcTooltip from 'rc-tooltip';


class BorderedSummary extends React.Component {

    getKeyValuePairs() {
        return this.props.keyValueData.map((x, index) =>
            <dl key={index} className="mb-0 ml-1 d-flex">
                <dt>{x.key}:</dt>
                <dd className="ml-2 mb-0">
                    <strong>{x.value}</strong>
                </dd>
            </dl>
        );
    }

    render() {

        return (
            <div className="card card_fieldset-group mt-1 mb-4">
                <label className="form-label">
                    <strong className={this.props.titleClass}>
                        {this.props.borderedTitle}
                    </strong>
                </label>
                <div className="d-flex">
                    <div className="w-100">
                        {this.getKeyValuePairs()}
                    </div>

                    {this.props.showErrorButton === true ?
                        <div className="flex-grow-1 align-self-center">

                            <RcTooltip placement="top"
                                            overlay={
                                                <span id={'tooltip-top'}>
                                                    {'View Model Errors'}
                                                </span>
                                            }
                                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                <button onClick={this.props.showModelErrorFS.bind(null, this.props.model_id,
                                    this.props.lastProcessedTime)}
                                        type="button"
                                        className="btn btn-link table-monitor-label-mw">
                                    <i className={"fs-5"}>
                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                    </i>
                                </button>
                            </RcTooltip>

                        </div>
                        : ''
                    }

                </div>
            </div>
        )
    }
}


export default BorderedSummary;
