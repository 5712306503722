import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import DatasetButton from "./datasetButton";
import PipelineSourceButton from "./pipelineSourceButton";
import DatasetError from "./datasetErrorComponent";
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import {get_document_link} from "../utils/common_utils";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {NOT_SORTABLE, QUALDO_DOCUMENTATION}  from "../utils/constant";


class RefreshesTable extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.paginationSize = this.props.paginationSize;
        this.getErrorComponent = this.getErrorComponent.bind(this);
        this.handleErrorModal = this.handleErrorModal.bind(this);
        this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
        this.handleSearchOperation = null;
        this.input = null;
        this.state = {
            hideSizePerPage: this.props.hideSizePerPage !== undefined ? this.props.hideSizePerPage : false,
            show: false,
            children: null
        }
    }

    // componentDidMount(){
    //     if(this.props.id==="datasourceErrorRefreshes"){
    //         this.props.renderedOnce(true)
    //     }
    // }

    shouldComponentUpdate(){
        if(this.props.id==="datasourceErrorRefreshes"){
            // Only for Configure page :  To avoid rerendering of Refresh table on Configure page
            // While the first call all the datas will received here
            // Only once this table will gets rendered  to avoid the rerendering

            if(this.props.updateStatus === true){
                // To handle rendering the Grid while Editing the datasource
                return true
            }
            return false
        }
        else{
            return true
        }
        // if(this.props.render===false){
        //     return false
        // }
        // else{
        //     return false
        // }
    }

    handleErrorModal(item){
        this.setState({show:true,children: item["value"]});
    }
    handleErrorModalClose(){
        this.setState({show:false});
    }

    getErrorComponent(item) {
        return(<DatasetError item={item} componentName={this.props.componentName}/>)

    }


    getButtonComponent(item) {

        if(this.props.componentName === "pipelinesource"){
           return (<PipelineSourceButton componentName={this.props.componentName} item={item}/>)
        }else{
            return (<DatasetButton componentName={this.props.componentName} item={item}/>)
        }
    }

    async navigateFirstPage(props) {
        return props.onPageChange(1);
    }

    renderCustomPageRow() {
        let result = []
        let headerKeys = {}
        this.props.data.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });

        for (let i = 0; i < this.props.data.data.length; i++) {
            let rowItems = this.props.data.data[i];
            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            const return_item_values = ["metric", "masName", "dataset","datasource"]
            const return_items = ["status", "refresh", "errors"]
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (return_item_values.includes(items.type)) {
                    obj[keys[k]] = items.value
                } else if (return_items.includes(items.type)) {
                    obj[keys[k]] = items;
                }
            }
            obj["ID"] = i + 1;
            result.push(obj);
        }
        return result

    }

    renderPipelineSourcesRow() {
        let result = []
        let headerKeys = {}
        this.props.data.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });
        for (let i = 0; i < this.props.data.data.length; i++) {
            let rowItems = this.props.data.data[i];

            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === 'td') {
                    obj[keys[k]] = items.value;
                } else if (items.type === "summary") {
                    obj[keys[k]] = items;
                } else if (items.type === "jobs") {
                    obj[keys[k]] = items;
                } else if (items.type === "errors") {
                    obj[keys[k]] = items;
                }else if (items.type === "datasetDiscovery") {
                    obj[keys[k]] = items;
                }
            }
            obj["ID"] = i + 1;
            result.push(obj);
        }
        // alert(JSON.stringify(result));
        return result
    }

    renderRow() {
        let result = []
        let headerKeys = {}
        this.props.data.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });

        for (let i = 0; i < this.props.data.data.length; i++) {
            let rowItems = this.props.data.data[i];

            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === 'td') {
                    obj[keys[k]] = items.value;
                } else if (items.type === "summary") {
                    obj[keys[k]] = items;
                } else if (items.type === "datasets") {
                    obj[keys[k]] = items;
                } else if (items.type === "errors") {
                    obj[keys[k]] = items;
                }else if (items.type === "datasetDiscovery") {
                    obj[keys[k]] = items;
                }
            }
            obj["ID"] = i + 1;
            result.push(obj);
        }
        return result
    }


    getDefaultPaginationOptions() {
        let pageLimit = [10,25,50,100]
        let pageList = [{text: 2, value: 2}]
        for(let i=0;i<=(pageLimit.length-1);i++) {
           if(this.props.data.data.length >= pageLimit[i]){
              pageList.push({text: pageLimit[i], value: pageLimit[i]});
           }else{
              break
           }

        }
        pageList.push({text: "All" , value: this.props.data.data.length});

        const options = {
            custom: true,
            // totalSize: this.props.data.data.length,
            page: 1,  // which page you want to show as default
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: 2,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }

    convertCustomMetricData(headerValue, cell, row) {
        const return_cell = ["Metric Name", "Metric Associations", "Dataset Name", "Datasource Name"]
        if (return_cell.includes(headerValue)) {
            return cell;
        }  else if (headerValue === "Errors") {
            return (this.getErrorComponent(cell));
        }else if (headerValue === "Refresh Status") {
            return (<> <dl>
                           <dt>Successful</dt>
                           <dd>{cell["value"][0]}</dd>
                         </dl>
                         <dl>
                         <dt>Failed</dt>
                         <dd>{cell["value"][1]}</dd>
                        </dl>
                     </>);
        }else if(headerValue === "Profile Status") {
              return (<> <dl>
                           <dt>Last Processing Time</dt>
                           <dd>{cell["value"][1]}</dd>
                         </dl>
                         <dl>
                         <dt>Current Processing Status</dt>
                         <dd>
                             <div className="data-progress-status">
                                  <label className={cell["value"][2]}>{cell["value"][0]}</label>
                                  <ProgressBar now={cell["value"][3]} />
                             </div>
                         </dd>
                        </dl>
                     </>);
        }


        return cell;
    }

    convertData(headerValue, cell, row) {
        if (headerValue === "Datasource Name") {
            return cell;
        } else if (headerValue === "Summary") {
            return (<><dl>
                         <dt>No. Of Datasets</dt>
                         <dd>{cell["value"]}</dd>
                       </dl>
                     </>);
        } else if (headerValue === "Datasets") {
            return (<div className="table-vertical-btns">{this.getButtonComponent(cell)}</div>);
        } else if (headerValue === "Errors") {
            return (this.getErrorComponent(cell));
        }else if(headerValue === "Profile Status") {
              return (<> <dl>
                           <dt>Last Processing Time</dt>
                           <dd>{cell["value"][1]}</dd>
                         </dl>
                         <dl>
                         <dt>Current Processing Status</dt>
                         <dd>
                             <div className="data-progress-status">
                                  <label className={cell["value"][2]}>{cell["value"][0]}</label>
                                  <ProgressBar now={cell["value"][3]} />
                             </div>
                         </dd>
                        </dl>
                     </>);
        }


        return cell;
    }



    convertPipelineSourceData(headerValue, cell, row) {
        if (headerValue === "Pipeline Source Name") {
            return cell;
        } else if (headerValue === "Summary") {
            return (<><dl>
                         <dt>No. Of Jobs</dt>
                         <dd>{cell["value"]}</dd>
                       </dl>
                     </>);
        } else if (headerValue === "Jobs") {
            return (<div className="table-vertical-btns">{this.getButtonComponent(cell)}</div>);
        } else if (headerValue === "Errors") {
            return (this.getErrorComponent(cell));
        }else if(headerValue === "Profile Status") {
              return (<> <dl>
                           <dt>Last Processing Time</dt>
                           <dd>{cell["value"][1]}</dd>
                         </dl>
                         <dl>
                         <dt>Current Processing Status</dt>
                         <dd>
                             <div className="data-progress-status">
                                  <label className={cell["value"][2]}>{cell["value"][0]}</label>
                                  <ProgressBar now={cell["value"][3]} />
                             </div>
                         </dd>
                        </dl>
                     </>);
        }


        return cell;
    }

    render() {
        const hasData = this.props.data.data.length > 0;
        const customTotal = (from, to, size) => {

            return(
                <span className="react-table__info">
                        Showing <b>{from}</b> to <b>{to}</b> of <b>{size}</b> results
                </span>
            );
            
        };

        const pageListRenderer = ({
                                      pages,
                                      onPageChange
                                  }) => {
            return (
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        {
                            pages.map(p => (
                                <button className="btn btn-success"
                                        onClick={() => onPageChange(p.page)}>
                                    {p.page}
                                </button>
                            ))
                        }
                    </div>
                </div>
            );
        };

        let options;
        let lengthLimit = 0;
        options = this.getDefaultPaginationOptions();
        lengthLimit = 2;


        options["paginationTotalRenderer"] = customTotal;
        options["pageListRenderer"] = pageListRenderer;
        options["hideSizePerPage"] = this.state.hideSizePerPage;

        let data = [];
        if (this.props.page === "custom_metrics") {
            data = this.renderCustomPageRow();
        }
        else if(this.props.componentName === "pipelinesource"){
            data =  this.renderPipelineSourcesRow();
        }
        else {
            data = this.renderRow();
        }
        const sizePerPageRenderer = ({
                                         options,
                                         currSizePerPage,
                                         onSizePerPageChange
                                     }) => {
            return(
                    <div className="react-bootstrap-table__header">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <div className="btn-group react-table__length" role="group">
                                <span>Show</span>
                                <Select
                                    classNamePrefix='select-control'
                                    defaultValue={{"label":lengthLimit,"value":lengthLimit}}
                                    options={options.map((option) => ({'label': option.text, 'value': option.page}))}
                                    onChange={(selectedOption) => {
                                        onSizePerPageChange(selectedOption.value);
                                    }}
                                />
                                <span>items</span>
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="btn-group react-table__filter" role="group">
                                <span>Search:</span>
                                <div className="table-search">
                                    <input
                                        placeholder={"Search"}
                                        id={"searchBox"}
                                        className="form-control"
                                        ref={n => this.input = n}
                                        onChange={this.handleSearchOperation}
                                        style={{backgroundColor: 'white', align: 'right'}}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        options["sizePerPageRenderer"] = sizePerPageRenderer.bind(this);

        let columns;
        if (this.props.page === "custom_metrics") {
            columns = this.props.data.headers.map(x => ({
                'dataField': x,
                'text': x,
                'sort': NOT_SORTABLE.includes(x.toLowerCase())  ? false : true,
                'searchable': true,
                'formatter': this.convertCustomMetricData.bind(this, x)
            }));
        }
        else if(this.props.componentName==="pipelinesource"){
            columns = this.props.data.headers.map(x => ({
                'dataField': x,
                'text': x,
                'sort': NOT_SORTABLE.includes(x.toLowerCase())  ? false : true,
                'searchable': true,
                'formatter': this.convertPipelineSourceData.bind(this, x)
            }));
        }
        else {
            columns = this.props.data.headers.map(x => ({
                'dataField': x,
                'text': x,
                'sort': NOT_SORTABLE.includes(x.toLowerCase())  ? false : true,
                'searchable': true,
                'formatter': this.convertData.bind(this, x)
            }));
        }

        columns = [{
            'dataField': "ID",
            'text': "ID",
            'sort': true
        }, ...columns];

        let CustomSearchBar = (props) => {
            const handleClick = () => {
                this.navigateFirstPage(props).then((res) => {
                    props.onSearch(this.input.value)
                });
            };
            this.handleSearchOperation = handleClick.bind(this);
            return ("");
        };
        let BindedCustomSearchBar = CustomSearchBar.bind(this);
        const contentTable = ({paginationProps, paginationTableProps}) => { 
        return(
            <div>
            {
                hasData && this.props.data.data.length > lengthLimit
                    ?
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    :
                    ''
            }

            {hasData ? <><ToolkitProvider
                keyField="ID"
                columns={columns}
                data={data}
                search
            >
                {
                    toolkitprops => (
                        <>
                            {
                                hasData
                                    ?
                                        <BindedCustomSearchBar
                                        {...toolkitprops.searchProps}
                                        {...paginationProps} />
                                    :
                                    ''
                            }

                            <BootstrapTable
                                bootstrap4
                                bordered={false}
                                responsive
                                // striped
                                // hover
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                wrapperClasses="table-responsive action-sticky-cell"
                                tableHeaderClass="qd-table mb-0"
                                tableBodyClass="qd-table mb-0"
                                className="action-sticky-cell"
                            />
                        </>
                    )
                }
            </ToolkitProvider></> : ""}
            <div className="react-bootstrap-table__footer">
                <div className="row">
                    {
                        hasData ? <div className="col-sm-12 col-md-6"> <PaginationTotalStandalone {...paginationProps} /> </div>
                            :
                            ''
                    }
                    <div className="col-sm-12 col-md-6">
                        {hasData && this.props.data.data.length > lengthLimit ?  <PaginationListStandalone {...paginationProps} /> : ''}
                    </div>

                </div>
            </div>

        </div>

        );
        }
        if (hasData) {
            return (
                <><div>
                    <PaginationProvider
                        pagination={
                            paginationFactory(options)
                        }
                    >
                        {contentTable}
                    </PaginationProvider>
                </div>
                </>
            );
        } else {
            return (<div className="text-center text-muted py-5">
                                <h1><i className='opacity-50'><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No data available</h4>
                                <p>For help, check out the <a href={get_document_link("index")} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div>
                    );
        }
    }
}

export default RefreshesTable;