import React from "react";
import ChartFilterHeader from "./chartFilterHeader";
import {CLASS_COLORS, IS_NONE_CHECK} from "../../utils/constant";
import {performTimeFilterForModelABCombinedCharts} from "../../utils/common_utils";

class MpCVHorizontalSections extends React.Component {

    getCombinedChart() {
        const horizontalChartData = this.props.horizontalChartData;
        const combinedChart = horizontalChartData.combinedChart;
        const combinedKey = `dc_${combinedChart.key}`;

        return (
            <div key={combinedKey} id={combinedKey} className="col-md-12">
                <ChartFilterHeader
                    tab={"mp"}
                    customTimeFilterFunction={performTimeFilterForModelABCombinedCharts}
                    hideChartOptions={true}
                    chartLevel={"model"}
                    y2Value={combinedChart["y2Value"]}
                    y1Value={combinedChart["y1Value"]}
                    yValue={combinedChart["yValue"]}
                    xValue={combinedChart["xValue"]}
                    timeFilterApplied={this.props.timeFilterApplied}
                    isError={combinedChart["isError"]}
                    colorCode={combinedChart["lineColors"]}
                    toolTipTitle={combinedChart["toolTipTitle"]}
                    label={combinedChart["label"]}
                    showHeatMap={false}
                    showSettingsMenu={false}
                    key={`model_perf_cv_${combinedKey}`}
                    customKey={`mpcv_${combinedKey}`}
                    scale={combinedChart["scale"]}
                    mapModel={this.props.mapModel}
                    selectedMLModel={this.props.selectedMlModel}
                    data={combinedChart}
                    filteredData={null}
                    dataSetId={null}
                    mlModelId={null}
                    title={combinedChart["chartTitle"]}
                    chartType={combinedChart.chartType}
                    // startDate={propsStartDate}
                    // endDate={propsEndDate}
                    usePropsTitle={true}
                    variant={"model_perf_comp_view"}
                    hideDistanceFilter={true}
                    chartTimeFilter={this.props.chartTimeFilter}
                />
            </div>)
    }

    getSingleChart(chartData, divPart, lineColors) {
        /* Previously used. Not used currently.*/

        if (IS_NONE_CHECK.includes(chartData)) {
            // Incoming chart data is undefined or null. So no further processing
            return '';
        }

        const isDoubleLineChart = chartData["name"].includes(" vs ");
        const reqKey = `mp_cv_dcs_${chartData.key}`;
        const areaColor = CLASS_COLORS[lineColors]["area"];
        const lineColor = CLASS_COLORS[lineColors]["line"];

        return (
            <div key={reqKey} id={reqKey} className={`col-md-${divPart}`}>
                <ChartFilterHeader
                    tab={"mp"}
                    chartLevel={"model"}
                    y2Value={chartData["y2Value"]}
                    y1Value={chartData["y1Value"]}
                    yValue={chartData["yValue"]}
                    xValue={chartData["xValue"]}
                    timeFilterApplied={this.props.timeFilterApplied}
                    isError={chartData["isError"]}
                    colorCode={lineColor}
                    lineColorCode={areaColor}
                    toolTipTitle={chartData["toolTipTitle"]}
                    label={chartData["label"]}
                    showHeatMap={false}
                    showSettingsMenu={false}
                    key={`model_perf_cv_s_${reqKey}`}
                    customKey={`mpcv_${reqKey}`}
                    scale={chartData["scale"]}
                    mapModel={this.props.mapModel}
                    selectedMLModel={this.props.selectedMlModel}
                    data={chartData}
                    filteredData={null}
                    dataSetId={null}
                    mlModelId={null}
                    multipleLines={isDoubleLineChart}
                    title={chartData["chartTitle"]}
                    chartType={chartData.chartType}
                    // startDate={propsStartDate}
                    // endDate={propsEndDate}
                    usePropsTitle={true}
                    variant={"model_perf_comp_view"}
                    hideDistanceFilter={true}
                    chartTimeFilter={this.props.chartTimeFilter}
                />
            </div>
        );
    }

    getIndividualChartsGroup() {
        /* Previously used. Not used currently.*/
        const horizontalChartData = this.props.horizontalChartData;
        const chartA = horizontalChartData.chart1;
        const chartB = horizontalChartData.chart2;
        const chartC = horizontalChartData.chart3;
        let count = 0;
        if (!IS_NONE_CHECK.includes(chartA)) {
            count = count + 1;
        }

        if (!IS_NONE_CHECK.includes(chartB)) {
            count = count + 1;
        }

        if (!IS_NONE_CHECK.includes(chartC)) {
            count = count + 1;
        }

        if (count === 0) {
            return "Empty";
        }

        const divPart = 12 / count;

        return (
            <>
                {this.getSingleChart(chartA, divPart, "compare_a")}
                {this.getSingleChart(chartB, divPart, "compare_b")}
                {this.getSingleChart(chartC, divPart, "compare_c")}
            </>
        )
    }

    render() {

        return (
            <>
                {this.getCombinedChart()}
                {/*{this.getIndividualChartsGroup()}*/}
            </>
        );

    }
}

export default MpCVHorizontalSections;
