import { ui_service_ip } from "./ip_details";
// import moment from 'moment';
// import {
//     METRIC_SCALE_MAPPING,
//     // metricCategory,
//     // metricMapping
// } from "../utils/constant"
import { call_post_api, call_get_api } from "./event_handling"
import { normalizeChildDatasetName } from "../utils/attribute_name_utils";

import { extractDatasetInfoForMonitorListView, getMonitorTabDQPListViewTableData } from "./common_utils"
// import store from "../redux/store";

export function getDateObject(timeStr) {
    let dateObj;
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
        dateObj = new Date(timeStr.replace(/\s/, 'T'));
    } else {
        dateObj = new Date(timeStr);
    }
    return dateObj;
}

// let teamID = localStorage.getItem('team_id')
export function formDatasetsList(datasets) {
    let childDatasets = []
    let parentDatasets = []
    let child = {}
    let childDatasetMap = []
    parentDatasets.push({ "integration_id": "-1", "label": "All", "value": "*" })
    datasets.map((data) => {
        if (data.is_child_dataset === true) {
            data['label'] = normalizeChildDatasetName(data.label)
            childDatasets.push(data)
            if (child[data.parent_data_set_id] === undefined) {
                child[data.parent_data_set_id] = [{
                    "label": "All", "value": "*",
                    is_child_dataset: true,
                    parent_data_set_id: data.parent_data_set_id
                }]
            }
            child[data.parent_data_set_id].push(data)
            if (childDatasetMap[data.parent_data_set_id] === undefined) {
                childDatasetMap[data.parent_data_set_id] = []
            }
            childDatasetMap[data.parent_data_set_id] = child[data.parent_data_set_id].concat(data)


        }
        else {
            parentDatasets.push(data)
        }
        return data;
    })
    let finalList = []
    parentDatasets.map((data) => {
        if (child[String(data.value)] !== undefined) {
            let partitionsLength = child[String(data.value)].length - 1
            data['label'] = data.label + '( ' + String(partitionsLength) + ' Partitions)'
            data['children'] = child[String(data.value)]
            finalList.push(data)
        }
        else {
            finalList.push(data)
        }
        return data;
    }
    )
    finalList = { "childDatasetMap": child, "parentDatasets": finalList }
    return finalList
}

export function formAttributesDict(attributes) {
    let finalDict = {}
    attributes.map((data) => {
        let id = data.dataset_id === undefined ? data.data_set_id : data.dataset_id
        if (finalDict[id] === undefined) {
            finalDict[id] = []
        }
        data['dataset_id'] = id
        finalDict[id].push(data)
        return data;
    })
    return finalDict
}

export function getMappedMetadatas() {

    call_get_api(ui_service_ip, "metadata/mapping/latest", { "tenant_id": localStorage.getItem('team_id') }).then(
        (result) => {
            let mappedDatasetInfo = extractDatasetInfoForMonitorListView(result.meta_data);
            this.setState({ metaData: mappedDatasetInfo, mappedDatasetInfo: mappedDatasetInfo, showLoader: false })

            let endPoint = "follow_attributes";
            call_get_api(ui_service_ip, endPoint, { "tenant_id": localStorage.getItem('team_id') }).then(
                (result) => {
                    if (result !== undefined && result !== null) {
                        this.setState({ follow_attributes: result.follow_attributes })
                    }
                }
            );

        }
    )
}

export function getFollowAttributes() {
    let endPoint = "follow_attributes";
    call_get_api(ui_service_ip, endPoint, { "tenant_id": localStorage.getItem('team_id') }).then(
        (result) => {
            if (result !== undefined && result !== null) {
                this.setState({ follow_attributes: result.follow_attributes })
            }
        }
    );
}

// export function getMetricsInfo() {
//     const endPoint = "alert_applicable_metrics";
//     call_get_api(ui_service_ip, endPoint,{"tenant_id":localStorage.getItem('team_id')}).then(
//         (result) => {
//             if (result === undefined || result === null) {
//                 // const error = `Unable to fetch metadata details for tenant: ${tenantID}`;
//                 // postUIlogs(error);
//                 return;
//             }
//             let metadataDetails = result.metadata_details
//             this.setState({metrics_info:metadataDetails})
//         }
//     );
// }


export function getMetaDatas() {
    let endPoint = "metadata_mapping";
    let data = { "tenant_id": localStorage.getItem('team_id') }
    let api2 = false

    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result !== undefined && result !== null && result.data !== undefined) {
                let filterData = {}
                filterData["environmentOptions"] = result.data['environments']
                filterData["datasourceOptions"] = result.data['integrations']
                filterData["datasetsOptions"] = result.data['datasets']
                filterData["attributesOptions"] = result.data['attributes']
                let childDatasets = []


                // TODO Need to append values in existingList without this iteration
                let existingList = []
                if(result.data['environments'] !== undefined && result.data['integrations'] !== undefined && result.data['datasets'] !== undefined && result.data['environments'].length > 0 && result.data['integrations'].length > 0 && result.data['datasets'].length > 0 && result.data['attributes'].length > 0 )
                {
                    result.data['environments'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    result.data['integrations'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    result.data['datasets'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    result.data['attributes'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    this.setState({ existingList: existingList })
                }
                // TODO Need to append values in existingList without this iteration

                if(result.data['integrations'].length === 1){
                    // Checking the In-progress status for the First Configured Datasource
                    const teamId = localStorage.getItem('team_id');
                    let data = {"tenant_id":teamId, "integration_id": result.data['integrations'][0].value}
                    let endPoint = "dataset_progress_info";
                    call_get_api(ui_service_ip, endPoint,data).then((response) => {
                        if (response === undefined) {
                            this.setState({errorOccurred: true});
                        } else if (response !== null && response.code === 200) {
                            api2 = true
                            this.setState({progressStatus: response["progress_percentage"]});
                        }
                        if(response["progress_percentage"] !== 100){
                            this.setState({inProgress:true, showLoader:false, noDataFound:false})
                        }
                        else{
                            this.setState({inProgress:false, showLoader:false})
                        }
                    });
                }
                else if (result.data['integrations'].length === 0){
                    api2=true
                    this.setState({noDataFound:true, noIntegration: true})
                }
                else{
                    api2=true
                    this.setState({selectedDataSourceID :result.data['integrations'][0]['value'] ,inProgress:false, noDataFound:false})
                }

                for (let level in result.data) {
                    // eslint-disable-next-line
                    result.data[level].filter((data) => {
                        if (data.is_child_dataset !== undefined && data.is_child_dataset === true) {
                            childDatasets.push(data.value)
                        }
                        return data;
                    })
                }
                let datasets = result.data['datasets']

                let formDatasetOption = formDatasetsList(datasets)
                let attributesList = filterData["attributesOptions"]
                let formAttributeOption = formAttributesDict(attributesList)

                let integrationMapping = {};
                result.data['integrations'].map((data) => {
                    integrationMapping[data.value] = ""
                    integrationMapping[data.value] = data.label
                    return null
                }
                )

                filterData["childDatasets"] = childDatasets
                filterData["childDatasetMap"] = formDatasetOption['childDatasetMap']
                filterData["parentDatasets"] = formDatasetOption['parentDatasets']
                filterData["attributesOptions"] = formAttributeOption

                call_get_api(ui_service_ip, "metadata/mapping/latest", { "tenant_id": localStorage.getItem('team_id') }).then(
                    (result) => {
                        let metaData = result.meta_data
                        let mappedDatasetInfo = extractDatasetInfoForMonitorListView(metaData);

                        let endPoint = "follow_attributes";
                        call_get_api(ui_service_ip, endPoint, { "tenant_id": localStorage.getItem('team_id') }).then(
                            (result) => {
                                if (result !== undefined && result !== null) {

                                    let showLoader = true
                                    if(api2 === true){
                                        showLoader = false
                                    }

                                    this.setState({
                                        metaData: metaData,
                                        mappedDatasetInfo: mappedDatasetInfo,
                                        showLoader: showLoader,
                                        follow_attributes: result.follow_attributes,
                                        initialData: { "value": filterData, "label": "tempvalue" },
                                        filterData: filterData,
                                        integrationMapping: integrationMapping,
                                        pre_load:false
                                    })
                                }
                            }
                        );

                    }
                )

            }
        }
    );
}

// export function getLastProcessing(pmtype, integrationid = "") {
//     const tenantId = localStorage.getItem('team_id');
//     let endPoint = "metrics_store_payload_created_time";
//     let data = { "tenant_id": tenantId }

//     call_get_api(ui_service_ip, endPoint, data).then((response) => {
//         if (response !== undefined && response.code === 200) {
//             let results = response["results"];
//             let maxLastProfilingTime = results.reduce((a, b) => Number(getDateObject(results[a])) > Number(getDateObject(results[b])) ? a : b);
//         }
//     }
//     )
// }

/*function numAvg(num) {
    var total = 0;
    for (var i = 0; i < num.length; i++) {
        total += num[i];
    }
    return total / num.length;
}*/




export function getMonitorInfo(
    tenantData, addTimelinessDataSetInfo, addRecencyPreview, integrationId = null, store) {
    let apiReqData = tenantData;
    let selectedDataSourceID = integrationId
    let  selectedDataSourceName = "Choose Datasource"
    if (integrationId !== null) {
      apiReqData["integration_id"] = integrationId;
      tenantData["integration_id"] = integrationId;
      selectedDataSourceName = this.props.integrationMapping[integrationId]
    }
    else{
        selectedDataSourceID = -1
    }

    let monitorModuleProps = {}
    getRecencyInfo(tenantData, store, addTimelinessDataSetInfo, addRecencyPreview);
    call_post_api(ui_service_ip, `monitor/info`, apiReqData).then(
      (result) => {
        if (result !== undefined && result !== null) {
          if (result.results !== undefined) {
            let integrationId = result.results[0].integration_id
            monitorModuleProps = formMonitorChartData(result.results);
            const tenantId = localStorage.getItem('team_id');
            let endPoint = "metrics_store_payload_created_time";
            let data = { "tenant_id": tenantId, "integration_id": integrationId}
            let last_processed_time=null;
            call_get_api(ui_service_ip, endPoint, data).then((response) => {
                if (response !== undefined && response.code === 200) {
                    let results = response["results"];
                    last_processed_time = results[0].last_processed_time
                    // let maxLastProfilingTime = results.reduce((a, b) => Number(getDateObject(results[a])) > Number(getDateObject(results[b])) ? a : b);
                    selectedDataSourceName = this.props.integrationMapping[integrationId]
                    this.setState({
                      monitorModuleProps: monitorModuleProps,
                      selectedDataSrc:{'value':String(integrationId), 'label':selectedDataSourceName},
                      last_processed_time:last_processed_time,
                      selectedDataSourceID:selectedDataSourceID,
                      showLoader:false,
                      noIntegration: false,
                      noDataFound : false,
                      inProgress:false
                    })
                  }
                }
            )
          }
          else if (result.message === 'Result not found') {
            const teamId = localStorage.getItem('team_id');
            let data = {"tenant_id":teamId, "integration_id": integrationId}
            let endPoint = "dataset_progress_info";
            call_get_api(ui_service_ip, endPoint,data).then((response) => {
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response !== null && response.code === 200) {
                    this.setState({progressStatus: response["progress_percentage"]});
                }
                if(response["progress_percentage"] !== 100){
                    this.setState({
                                    showLoader: false,
                                    inProgress: true,
                                    selectedDataSourceID:selectedDataSourceID,
                                    selectedDataSrc:{'value':String(selectedDataSourceID), 'label':selectedDataSourceName},
                                })
                }
                else{
                    this.setState({
                                    noIntegration: false,
                                    showLoader: false,
                                    inProgress: false,
                                    selectedDataSourceID:selectedDataSourceID,
                                    selectedDataSrc:{'value':String(selectedDataSourceID), 'label':selectedDataSourceName},
                                })

                }
            });
        }
        else{
                this.setState({ showLoader: false, noIntegration: true, selectedDataSourceID:selectedDataSourceID })
            }
        }
        else{
            this.setState({ showLoader: false, noIntegration: true, selectedDataSourceID:selectedDataSourceID })
        }
      }
    );
  }

function getRecencyInfo(tenantData, store, addTimelinessDataSetInfo, addRecencyPreview) {
    let endPoint = "recency/all";
    let reData = { "tenant_id": tenantData.tenant_id,integration_id:tenantData["integration_id"], "type": "monitor_chart" };
    call_get_api(ui_service_ip, endPoint, reData).then(
      (recencyResult) => {
        if (recencyResult !== undefined && recencyResult !== null) {
          if (recencyResult.code === 200) {
            let recencyData = recencyResult.monitor_others;
            let recencyPreviewData = recencyResult.preview_res;
            if (recencyData !== undefined && recencyData !== null) {
              let chartData = recencyData.data;
            //   if (store !== undefined) {
            //     store.dispatch(addTimelinessDataSetInfo(chartData))
            //     store.dispatch(addRecencyPreview(recencyPreviewData))
            //   } else {
                addTimelinessDataSetInfo(chartData)
                addRecencyPreview(recencyPreviewData)
            //   }
            }
          }
        }
      }
    );
  }

function formMonitorChartData(data) {
    // let chartData = {}


    let metricList = [
        'drift_dq_attribute',
        'drift_dq_dataset',

        'completeness_dq_attribute',
        'completeness_dq_dataset',

        'uniqueness_dq_attribute',
        'uniqueness_dq_dataset',

        'accuracy_dq_attribute',
        'accuracy_dq_dataset',

        'consistency_dq_dataset',
        'consistency_dq_attribute',

        'conformity_dq_dataset',
        'conformity_dq_attribute',

        'ml_attribute',
        'ml_dataset',
        'preview',

    ]

    let completenessDataSetInfo = {}
    let completenessAttributeInfo = {}

    let conformityDataSetInfo = {}
    let conformityAttributeInfo = {}

    let accuracyDataSetInfo = {}
    let accuracyAttributeInfo = {}

    let uniquenessDataSetInfo = {}
    let uniquenessAttributeInfo = {}

    let driftDataSetInfo = {}
    let driftAttributeInfo = {}

    let consistencyDataSetInfo = {}
    let consistencyAttributeInfo = {}

    let monitorPreview = {}
    let mlDatasetInfo = {}
    let mlAttributeInfo = {}

    let filterPreviewData = data.filter((data) => data.type === 'preview' && data.payload !== undefined && Object.keys(data.payload).length !== 0)
    monitorPreview['dq_metrics'] = filterPreviewData[0]['payload']['dq_metrics']
    monitorPreview['dqpreview'] = filterPreviewData[0]['payload']['dq_metrics']
    monitorPreview['ml_model'] = filterPreviewData[0]['payload']['ml_model']
    monitorPreview['mlPreview'] = filterPreviewData[0]['payload']['ml_model']

    // Filter out Null data
    let filterData = data.filter((data) => metricList.includes(data.type) && data.payload.data !== undefined && Object.keys(data.payload.data).length !== 0)

    for (let metric of metricList) {

        filterData.filter((data) => data.type === metric && data.payload.data !== undefined && Object.keys(data.payload.data).length !== 0 &&
            // eslint-disable-next-line
            Object.keys(data.payload.data).map((key) => {
                // eslint-disable-next-line
                Object.keys(data.payload.data[key]).map((value) => {
                    if (metric === 'drift_dq_dataset') {
                        if (driftDataSetInfo[key] === undefined) {
                            driftDataSetInfo[key] = {}
                            driftDataSetInfo[key][value] = data.payload.data[key][value]

                        }
                        else {
                            driftDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'drift_dq_attribute') {
                        if (driftAttributeInfo[key] === undefined) {
                            driftAttributeInfo[key] = {}
                            driftAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            driftAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    if (metric === 'uniqueness_dq_dataset') {
                        if (uniquenessDataSetInfo[key] === undefined) {
                            uniquenessDataSetInfo[key] = {}
                            uniquenessDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            uniquenessDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'uniqueness_dq_attribute') {
                        if (uniquenessAttributeInfo[key] === undefined) {
                            uniquenessAttributeInfo[key] = {}
                            uniquenessAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            uniquenessAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'completeness_dq_attribute') {
                        if (completenessAttributeInfo[key] === undefined) {
                            completenessAttributeInfo[key] = {}
                            completenessAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            completenessAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'completeness_dq_dataset') {
                        if (completenessDataSetInfo[key] === undefined) {
                            completenessDataSetInfo[key] = {}
                            completenessDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            completenessDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'accuracy_dq_dataset') {
                        if (accuracyDataSetInfo[key] === undefined) {
                            accuracyDataSetInfo[key] = {}
                            accuracyDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            accuracyDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    if (metric === 'accuracy_dq_attribute') {
                        if (accuracyAttributeInfo[key] === undefined) {
                            accuracyAttributeInfo[key] = {}
                            accuracyAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            accuracyAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'conformity_dq_dataset') {
                        if (conformityDataSetInfo[key] === undefined) {
                            conformityDataSetInfo[key] = {}
                            conformityDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            conformityDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'conformity_dq_attribute') {
                        if (conformityAttributeInfo[key] === undefined) {
                            conformityAttributeInfo[key] = {}
                            conformityAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            conformityAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'consistency_dq_dataset') {
                        if (consistencyDataSetInfo[key] === undefined) {
                            consistencyDataSetInfo[key] = {}
                            consistencyDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            consistencyDataSetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'consistency_dq_attribute') {
                        if (consistencyAttributeInfo[key] === undefined) {
                            consistencyAttributeInfo[key] = {}
                            consistencyAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            consistencyAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'ml_dataset') {
                        if (mlDatasetInfo[key] === undefined) {
                            mlDatasetInfo[key] = {}
                            mlDatasetInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            mlDatasetInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                    else if (metric === 'ml_attribute') {
                        if (mlAttributeInfo[key] === undefined) {
                            mlAttributeInfo[key] = {}
                            mlAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                        else {
                            mlAttributeInfo[key][value] = data.payload.data[key][value]
                        }
                    }
                })
            }
            ))
    }

    // if (integrationId !== 'none') {
    //     this.propsprefetchMonitorState(integrationId)
    // }

    let monitorModuleProps = {
        "accuracyAttributeInfo": accuracyAttributeInfo,
        "accuracyDataSetInfo": accuracyDataSetInfo,
        "consistencyDataSetInfo": consistencyDataSetInfo,
        "consistencyAttributeInfo": consistencyAttributeInfo,
        "conformityDataSetInfo": conformityDataSetInfo,
        "conformityAttributeInfo": conformityAttributeInfo,
        "completenessAttributeInfo": completenessAttributeInfo,
        "completenessDataSetInfo": completenessDataSetInfo,
        "driftAttributeInfo": driftAttributeInfo,
        "driftDataSetInfo": driftDataSetInfo,
        "mlAttributeInfo": mlAttributeInfo,
        "mlDataSetInfo": mlDatasetInfo,
        "monitorPreview": monitorPreview,
        "uniquenessAttributeInfo": uniquenessAttributeInfo,
        "uniquenessDataSetInfo": uniquenessDataSetInfo,
        "dqPreview": monitorPreview,
        "MonitorPreview": monitorPreview
    }
    return monitorModuleProps
}

export function getMonitorListViewData(tenantData, addMonitorDQListViewData, integrationId = null, store, retries = 3) {
    let endPoint = "monitor_list_view";
    let data = { "tenant_id": tenantData, "res_type": "dq" }
    let selectedDataSourceID = integrationId
    let selectedDataSourceName = "Choose Datasource"
    if (integrationId !== null) {
        data["integration_id"] = integrationId;
        selectedDataSourceName = this.props.integrationMapping[integrationId]
    }
    else{
        selectedDataSourceID =- 1
    }
    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result !== undefined && result !== null && result.list_view_data !== null) {
                let endPoint = "recency/all";
                let reData = { "tenant_id": tenantData, "type": "monitor_list" };
                let integrationId = result.integration_id
                selectedDataSourceName = this.props.integrationMapping[integrationId]

                call_get_api(ui_service_ip, endPoint, reData).then(
                    (recencyResult) => {
                        if (recencyResult !== undefined && recencyResult.message === "Successfully fetched") {
                            let recencyProfileResult = recencyResult.monitor_list_view;
                            recencyProfileResult["isRecency"] = true;
                            if (store !== undefined) {
                                store.dispatch(addMonitorDQListViewData(recencyProfileResult));
                            } else {
                                addMonitorDQListViewData(recencyProfileResult);
                            }
                        }
                    }
                );

                let list_view_data = result.list_view_data;
                if(result.integration_id !== undefined && result.integration_id !== null ){
                    selectedDataSourceID = result.integration_id
                }
                list_view_data["apiFetchOver"] = true;

                if (integrationId === undefined || integrationId === null) {
                    this.setState({ showLoader: false, noIntegration: true, selectedDataSourceID : selectedDataSourceID})
                }
                else {
                    this.setState({ noIntegration: false, selectedDataSourceID : selectedDataSourceID })
                    let datasets = []
                    integrationId = String(integrationId)
                    if(this.props.mappedDatasetInfo !== undefined && this.props.mappedDatasetInfo["idDatasetDetail"] !== undefined){
                        for (const [key, value] of Object.entries(this.props.mappedDatasetInfo["idDatasetDetail"])) {
                            if (value["dataSourceId"] === integrationId) {
                                datasets.push({ "label": normalizeChildDatasetName(value["datasetName"]), "value": key });
                            }
                        }
                    }

                    let last_processed_time = null;
                    if(result.last_processed_time !== undefined && result.last_processed_time !== null){
                        last_processed_time = result.last_processed_time
                    }
                    let defaultDataSourceOption = { "value": integrationId, "label": this.props.dataModule.integrations[integrationId] }
                    const reqTableData = getMonitorTabDQPListViewTableData(this.state.mappedDatasetInfo, list_view_data, this.props.monitorModule.follow_attributes, this.props.timelinessData);
                    const listViewTableData = reqTableData.tableData;
                    this.setState({
                        keyData: `dqListView_${listViewTableData.length}`,
                        monitorDQListView: list_view_data,
                        currListViewTableData: listViewTableData, completeTableData: listViewTableData, listViewTableData: listViewTableData,
                        defaultDatasourceInside: defaultDataSourceOption,
                        selectedDataSource: defaultDataSourceOption,
                        selectedDataSourceID: selectedDataSourceID,
                        selectedDataSourceName: selectedDataSourceName,
                        datasets: datasets,
                        last_processed_time:last_processed_time,
                        noDataFound : false,
                        inProgress: false,
                        noIntegration:false

                    })
                    this.setState({ showLoader: false })
                }
            }
            else if (result.list_view_data === undefined || result.list_view_data === null) {
                let integrationId = null
                let last_processed_time = null
                let noIntegration = true

                if(result.integration_name !== null && result.integration_name !== undefined ){
                    this.setState({ selectedDataSourceName:result.integration_name})
                }
                else if(result.integration_id !== undefined && result.integration_id !== null){
                    selectedDataSourceName = this.props.integrationMapping[integrationId]
                    this.setState({ selectedDataSourceName:selectedDataSourceName})
                }
                else{
                    this.setState({ selectedDataSourceName:selectedDataSourceName})
                }

                if(result.last_processed_time !== undefined && result.last_processed_time !== null){
                    last_processed_time = result.last_processed_time
                    noIntegration =  false
                }
                if( result.list_view_data === null) {
                    const teamId = localStorage.getItem('team_id');
                    let data = {"tenant_id":teamId, "integration_id": integrationId}
                    let endPoint = "dataset_progress_info";
                    call_get_api(ui_service_ip, endPoint,data).then((response) => {
                        if (response === undefined) {
                            this.setState({errorOccurred: true});
                        } else if (response !== null && response.code === 200) {
                            this.setState({progressStatus: response["progress_percentage"]});
                        }
                        if(response["progress_percentage"] !== 100){
                            this.setState({ noIntegration:noIntegration, last_processed_time:last_processed_time,  inProgress: true, currListViewTableData:[], selectedDataSourceID: selectedDataSourceID, showLoader: false })
                        }
                        else{
                            this.setState({ noIntegration:noIntegration, last_processed_time:last_processed_time,  inProgress: false, currListViewTableData:[],selectedDataSourceID: selectedDataSourceID, showLoader: false })
                        }
                    });
                }
            }
            else {
                if (retries > 0) {
                    getMonitorListViewData(tenantData, addMonitorDQListViewData, store, retries - 1)
                }
            }
        }
    );
}
