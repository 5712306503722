import React from 'react';
import { faArrowRight, faChartArea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChartGrid from './chartGrid';
import Image from '../../components/image';
import AdvancedFilterModel from "./advanced_filter_model";
import ChartFilterHeader from './chartFilterHeader';
import {typeMappings} from './filter_common_utils';
import _ from 'lodash';
import {
    getChartType,
    replaceNullValues,
    convertDecimalDigits,
    sortModelErrorsBasedOnMetrics,
    filterBoxPlotData,
    getRowCountContributionFromData,
    getChartFilterData,
    getCustomMetrics
} from "../../utils/common_utils";
import Load from '../../components/loadAction';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import NoErrorComponent from './monitorNoResult';
import NoErrorInPreview from './noErrorPreview';
import {
    MIN_MAX_SCALE,
    NO_ERROR_CHART_COLORS,
    LIGHT_COLOR_MAP,
    ML_BOX_PLOT_LIST,
    SINGLE_ARROW_CHART_TYPE, GENERIC_CONSTANTS, FEATURE_DRIFT_ALIASES,
    MAX_DATA_POINTS_FOR_LIST_VIEW,
    DRIFT_PATTERN_TYPES, FEATURE_DRIFT_ALIAS_SINGLE_ARROW_GREEN_TEXT, FEATURE_DRIFT_ALIAS_SINGLE_ARROW_RED_TEXT,
    SECTION_TYPE_MAPPING, CUSTOM_ML_METRIC
} from "../../utils/constant";

class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.closeFullScreen = this.closeFullScreen.bind(this);
        this.renderGroup = this.renderGroup.bind(this);
        this.renderGroupItems = this.renderGroupItems.bind(this);
        this.getFilteredChartData = this.getFilteredChartData.bind(this);
        this.updateCharts = this.updateCharts.bind(this);

        let selectedModel = null;
        let preSelectedModelID = this.props.selectedMLModelID;
        let gridTitle = "Model Errors Dashboard - " + this.props.title

        if(this.props.title === undefined) {
          gridTitle = "Model Errors - Dashboard"
        }else if (this.props.variant === "mlModel" && preSelectedModelID !== undefined && preSelectedModelID !== null) {
          selectedModel = {"label": this.props.title, "value": preSelectedModelID.toString()};
        }
        let showOnlyErrorData = false;


        this.state={
            data: this.props.data,
            title: gridTitle,
            idValue: this.props.title,
            chartType: "areaChart",
            fullScreen: "fade",
            showOnlyErrorData: showOnlyErrorData,
            showErrorsOnly: true,
            showFullScreen: false,
            initialSelection: selectedModel,
            selectedDataSet: null,
            selectedModel: selectedModel,
            variant: this.props.variant,
            modalData: this.props.modalData,
            customMetricsOptions: getCustomMetrics(this.props.modalData["dataSet"]),
            aggregateOptions: this.getAggregateOptions(this.props.modalData["attribute"]),
            compareAttributeOptions: this.getAttributeOptions(this.props.modalData["attribute"]),
            compareDatasetOptions: this.getDataSrcOptions(this.props.modalData["dataSet"]),
            srcOptionList: null,
            selectedDataSrc:null,
            completeModalData: _.cloneDeep(this.props.modalData),
            underPerformModelIDs: this.props.underPerformModelIDs,
            fullScreenData: null,
            advancedModels: [],
            startDate: null,
            endDate: null,
            selectedMetrics: [],
            selectedAttributes: [],
            isAdvancedFilterApplied: false

        }
    }

    static getDerivedStateFromProps(props, state){
        if (props.data !== state.data) {
            return {data : props.data};
        } else{
            return null;
        }
    }

    updateCharts(model, metrics, attributeOptions, startDate, endDate, showErrorsOnly) {
           this.setState({showErrorsOnly: showErrorsOnly, advancedModels: model, selectedMetrics: metrics, startDate: startDate, endDate: endDate,
                          selectedAttributes: attributeOptions, isAdvancedFilterApplied: !this.state.isAdvancedFilterApplied});
    }

    componentDidUpdate(prevProps, prevState) {
       if (prevState.data !== this.state.data) {
            let _modalData= _.cloneDeep(this.props.data);
            this.setState({modalData:_modalData});
       }
    }

    closeFullScreen(){
     document.body.classList.remove("overflow-hidden");
        this.setState({
            fullScreen: '',
            showFullScreen: false,
            selectedModel: this.state.initialSelection,
            advancedModels: [],
            selectedMetrics: [],
            selectedDataSet: null
        });

        if (this.props.variant === "mlModel") {
            let restoreTitle = "Model Errors - Dashboard";
            if (this.state.initialSelection !== null){
                restoreTitle = this.state.initialSelection["label"];
            }

            this.setState({title: restoreTitle});
        }
    }

    setFullScreen() {
      document.body.classList.add("overflow-hidden");
      this.setState({showFullScreen : true, fullScreen: "show"});
    }


    renderGridItem() {
        if (this.props.data) {
            let chartValues = this.props.data;
            let gridType = "Model";

            if (this.props.isInProgress !== undefined && this.props.isInProgress === true) {
                return (
                    <NoErrorInPreview
                        message={'Model Profiling is in-progress.'} footer={''}/>
                );
            }

            if (this.props.noModelConfigured !== undefined && this.props.noModelConfigured === true) {
                return (
                    <NoErrorInPreview
                        message={'Model not configured for the selected period.'}
                        footer={'Try with a different time period.'}/>
                );
            }

            if (chartValues.length === 0) {
                return (
                    <NoErrorInPreview
                        message={`No Errors found in the configured ${gridType}`}/>
                );
            }

            return chartValues.map((chartData, index) => {
                const attribute_name = chartData.attribute_name;
                const dataSetId = chartData.data_set_id;
                let key = `${chartData.name}_${dataSetId}`;
                if (attribute_name !== undefined && attribute_name !== null) {
                    key = key + "_" + attribute_name;
                }

                const isModelPerformance = chartData.isModelPerformance;
                let scale = undefined;
                if (isModelPerformance !== undefined && isModelPerformance) {
                    key = key + "_preview_" + chartData.key;
                    scale = MIN_MAX_SCALE;
                }

                let chartTitle = chartData.title;
                chartData["chartTitle"] = chartTitle;
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                replaceNullValues(chartData);
                let chartType = getChartType(chartData, index);
                let idValue = chartData["key"] +"_"+ attribute_name
                if (chartData["chartType"] === "boxPlotZoomable") {
                    chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                    chartType = "boxPlotZoomable";
                }
                else if (chartData["chartType"] === SINGLE_ARROW_CHART_TYPE){
                    chartType = SINGLE_ARROW_CHART_TYPE;
                }

                const rowValue = getRowCountContributionFromData(chartData)
                if (FEATURE_DRIFT_ALIASES.includes(chartData["name"]) && rowValue !== GENERIC_CONSTANTS.NA) {
                    chartType = SINGLE_ARROW_CHART_TYPE;
                    chartData["chartType"]=chartType
                    if (chartData["type"] === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT) {
                        chartData["name"] = FEATURE_DRIFT_ALIAS_SINGLE_ARROW_RED_TEXT;
                    } else {
                        chartData["name"] = FEATURE_DRIFT_ALIAS_SINGLE_ARROW_GREEN_TEXT;
                    }
                }

                return (
                    <ChartGrid
                        yValue={chartData["name"]}
                        title={chartTitle}
                        key={key}
                        idValue={idValue}
                        scale={scale}
                        data={chartData}
                        chartType={chartType}
                        variant={this.state.variant}
                        index={index}
                        //selectedIntegration={selectedSource}
                    />
                );
            });
        }
    }

    getDataSrcOptions(dataSetData) {
        let compareDatasetOptions =[];
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(dataSetData)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                if (attribute === "data") {
                    for(let j = 0; j < chartData.length; j++) {
                        let dataSetChart = chartData[j];
                        let dataSetName = (dataSetChart.title.split(">")[2]).split("-")[0]
                        if (dataSetName !== undefined && dataSetName !== null) {
                            compareDatasetOptions.push({"label": dataSetName.trim(), "value":dataSetChart.data_set_id, "type": dataSetChart.name, "data": dataSetChart});
                        }
                    }
                }
            }
        }
        return compareDatasetOptions;
    }

     getAttributeOptions(attributeData) {
        let compareAttributeOptions =[];
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(attributeData)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                if (attribute === "data") {
                    for(let j = 0; j < chartData.length; j++) {
                        let attr = chartData[j];
                        let attribute_name = attr.attribute_name;
                        if (attribute_name !== undefined && attribute_name !== null) {
                            compareAttributeOptions.push({"label": attribute_name, "value": attribute_name+"$"+attr.name,"data_set_id":attr.data_set_id,
                            "data": attr});
                        }
                    }
                }
            }
        }
     return compareAttributeOptions;
    }

    getAggregateOptions(attributeData) {
        let aggregateOptions =[];
        let profileKeys = ["avg", "duplicate", "empty","max","min","mode","std","unique"];
        if (this.props.variant !== "accuracy") {
            return [];
        }
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(attributeData)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                if (attribute === "data") {
                    for(let j = 0; j < chartData.length; j++) {
                        let attr = chartData[j];
                        let attribute_name = attr.attribute_name;
                        let drift_pattern = attr.type;
                        if (profileKeys.includes(attr.name)) {
                            if (attribute_name !== undefined && attribute_name !== null) {
                                if (drift_pattern !== undefined && drift_pattern !== null) {
                                    if (drift_pattern === "NO_DRIFT" || drift_pattern === "not_computed") {
                                        aggregateOptions.push({"label":<a className="qd-cw_dp-item" href="/#">{attr.name}</a> , "value":attr.name,"attribute_name":attribute_name,
                                "data": attr, "data_set_id": attr.data_set_id, "is_error": false});
                                    }
                                } else {
                                    aggregateOptions.push({"label":<a className="qd-cw_dp-item" href="/#">{attr.name} <span className="label label-danger">Error</span></a> , "value":attr.name,"attribute_name":attribute_name,
                                "data": attr, "data_set_id": attr.data_set_id, "is_error": true});
                                }
                            }
                        }
                    }
                }
            }
        }
        return aggregateOptions;
    }

    renderGroup() {
        let attributeLevelGroupedData = this.props.modalData.attribute;
        let datasetLevelGroupedData = this.props.modalData.dataSet;
        if (this.props.variant === "mlModel" && this.props.selectedMLModelID === undefined) {
            attributeLevelGroupedData = this.getFilteredChartData(attributeLevelGroupedData,
                this.props.underPerformModelIDs);
            datasetLevelGroupedData = this.getFilteredChartData(datasetLevelGroupedData,
                this.props.underPerformModelIDs);
        }
        else if (this.props.variant === "mlModel") {
            let preSelectedModelID = this.props.selectedMLModelID;
            // In 'Show More' page show only the charts corresponding to the grid's model
            attributeLevelGroupedData = this.getFilteredChartData(attributeLevelGroupedData,
                [String(preSelectedModelID)]);

            datasetLevelGroupedData = this.getFilteredChartData(datasetLevelGroupedData,
                [String(preSelectedModelID)]);
        }


        let combinedData = [...datasetLevelGroupedData, ...attributeLevelGroupedData];

        // Sort the groups based on our requirement
        combinedData.sort(sortModelErrorsBasedOnMetrics);

        return this.renderGroupItems(combinedData)

    }

    renderGroupItems(combinedData) {
        let dataSetData;
        let rowCount = 0;
        let level
        return combinedData.map((group, groupId) =>{
            level = group.level
            dataSetData = [group]
            if(dataSetData === undefined){
              return ""
            }
            return dataSetData.map((groupData, index) => {
                rowCount = rowCount + 1
                let sectionName = groupData.header
                if(this.state.selectedMetrics.length > 0) {
                  let filteredMetrics = this.state.selectedMetrics.filter(x=>x.label === sectionName || sectionName === SECTION_TYPE_MAPPING[x.type]);
                  if(filteredMetrics.length === 0){
                    rowCount = rowCount - 1
                    return ""
                  }
                } else if(groupData.metric === CUSTOM_ML_METRIC && groupData.data.length === 0) {
                    rowCount = rowCount-1;
                    return ""
                }


                return (
                    <>
                    <div className="qd-chart-group m-0 mb-4"
                         key={groupData.key === undefined? index: groupData.key}>
                        <div className="qd-chart-group-heading">
                            <h4 className="qd-chart-group-title">
                                <span className="circle-number">
                                {rowCount}
                            </span>
                                {groupData.header}
                            </h4>
                            <p className="m-0">
                                {groupData.description}
                            </p>

                        </div>
                        <div className="qd-chart-group-body">
                            <div className="row row-sm">
                                { this.renderModalChart(level, groupData.data) }
                            </div>
                        </div>
                    </div>
                    </>
                );
            });

        });
    }


    renderModalChart(chartLevel, chartDataList) {
        let profileKeys = ["avg", "duplicate", "empty","max","min","mode","std","unique"];
        let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
        chartDataList = _.reject(chartDataList, function(d) { return (profileKeys.includes(d.name)); });
        if (chartDataList === undefined || chartDataList === null || chartDataList.length === 0){
            return (<NoErrorComponent/>);
        }

        let isMLModel = this.props.showModelFilter;


        let chartCount = 0;

        // To append color for attribute level charts
        let noErrorCount = 0;
        let is_same_col = false;
        let j =1;
        let attributeList = []
        // To append color for dataset level charts
        let noErrorDatasetCount = 0;
        let is_same_data_col = false;
        let k = 1;
        let datasetList = []

        if (chartDataList) {
            return chartDataList.map((chartData, index) => {
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                const attribute_name = chartData.attribute_name;
                let lastDiv = (chartDataList.length === index+1 && chartCount === 0)
                if (this.state.showErrorsOnly) {
                    if (chartData["hasDrift"] === false && lastDiv === true) {
                        return (<NoErrorComponent key={chartData["key"]}/>);
                    }
                    if (chartData["hasDrift"] === false) {
                        return "";
                    }
                }else if(profileKeys.includes(chartData.name)) {
                    return "";
                }
                let isUnderPerformingGrid = (this.state.underPerformModelIDs !== undefined)
                if (isMLModel && this.state.showOnlyErrorData && this.state.selectedModel === null && isUnderPerformingGrid) {
                    const chartHasNoError = chartData["hasDrift"] === false;
                    let modelID = String(chartData["ml_model_id"]);
                    if (!this.state.underPerformModelIDs.includes(modelID)){
                        return "";
                    }

                    if (chartHasNoError && lastDiv === true){
                        return  (<NoErrorComponent key={chartData["key"]}/>);
                    } else if(chartHasNoError) {
                        return "";
                    }
                } else if(isMLModel && this.state.showOnlyErrorData && String(this.state.selectedModel["value"]) !== String(chartData["ml_model_id"]) && lastDiv === true)
                {
                    return  (<NoErrorComponent key={chartData["key"]}/>);
                } else if(isMLModel && this.state.showOnlyErrorData && String(this.state.selectedModel["value"]) !== String(chartData["ml_model_id"])) {
                    return ""
                }

                if ( chartLevel === "Dataset"  ||
                    (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute") ) {

                    let id = null;
                    let compareModelOptions = null;
                    let compareMLAttributeOptions =  null;
                    let aggregateOptions = null;
                    if(this.props.showModelFilter) {
                        id = chartData["ml_model_id"];
                        compareModelOptions = _.reject(this.state.compareModelOptions, function(d) { return (d.label === chartData.data_set_name || d.value !== chartData.name ); });
                    } else {
                        id = chartData["data_set_id"];
                    }

                    let key = `modal_${chartData.name}_${id}`;
                    let title = this.props.title !== undefined ? this.props.title: '';
                    let compareDatasetOptions = null
                    let compareType =null;
                    let compareAttributeOptions = null;
                    if (attribute_name !== undefined && attribute_name !== null && this.props.showModelFilter ) {
                        key = key + "_" + attribute_name;
                        compareMLAttributeOptions = _.reject(this.state.compareAttributeOptions, function(d) { return (d.label === attribute_name || d.value !== chartData.name || d.dataSetName !== chartData.data_set_name); });
                    }

                    let idValue = ''
                    let showAggregateError = false;
                    if (chartLevel === "Attribute") {
                        key = key + "_" + attribute_name;
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g,"_");
                        compareType = chartLevel;
                        compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function(d) { return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id)  || d.value.split("$")[1] !== chartData.name ||
                        d.data_set_id !== chartData.data_set_id); });
                        aggregateOptions = this.state.aggregateOptions.filter(function (d) { return d.attribute_name === attribute_name && d.data_set_id === chartData.data_set_id});
                        let isAggregateError = aggregateOptions.filter(function (d) {return d.is_error === true});
                        if (isAggregateError.length > 0) {
                            showAggregateError = true;
                        }
                    }
                    if (chartLevel === "Dataset") {
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + chartData["name"].replace(/ /g,"_");
                        compareType = chartLevel;
                        compareDatasetOptions = _.reject(this.state.compareDatasetOptions, function(d) { return (d.value === chartData["data_set_id"] || d.type !== chartData.name)  });
                    }

                    title = title.replace(" ", "_");
                    /*let selectedSource = this.props.selectedIntegration;
                    chartData["selectedDataSource"] = selectedSource;
                    if (selectedSource === undefined) {
                        chartData["selectedMlModel"] = defaultModel;
                    }*/
                    chartCount = chartCount + 1;

                    let chartType = getChartType(chartData, chartCount);
                    const customGridKey = this.props.customKey;
                    if (customGridKey !== undefined) {
                        key = `${key}_${customGridKey}`;
                    }

                    if (chartData["key"] !== undefined) {
                        // Since we are using single complete model data for all Grids
                        // related to model error, we will append the existing chart key with
                        // current Grid's custom key.
                        chartData = _.cloneDeep(chartData);

                        key = `${key}_${chartData["key"]}`;
                        chartData["key"] = key;
                    }

                    if (chartLevel === "Dataset") {
                        if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                            if (datasetList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_data_col = true;
                                }
                            } else {
                                if (is_same_data_col === true && datasetList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_data_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[k]];
                                    if (is_same_data_col === false) {
                                        is_same_data_col = true;
                                    } else {
                                        is_same_data_col = false;
                                        k = k + 1;
                                    }
                                    if (k === 4) {
                                        k = 0;
                                    }
                                }
                            }
                            datasetList.push(noErrorDatasetCount);
                            noErrorDatasetCount = noErrorDatasetCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }
                    if (chartLevel === "Attribute") {
                        if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                            if (attributeList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_col = true;
                                }
                            } else {
                                if (is_same_col === true && attributeList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                                    if (is_same_col === false) {
                                        is_same_col = true;
                                    } else {
                                        is_same_col = false;
                                        j = j + 1;
                                    }
                                    if (j === 4) {
                                        j = 0;
                                    }
                                }
                            }
                            attributeList.push(noErrorCount);
                            noErrorCount = noErrorCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }
                    if (ML_BOX_PLOT_LIST.includes(chartData["name"]) && chartData["chartType"] === "boxPlotZoomable") {
                        chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                        chartType = "boxPlotZoomable";
                    }

                    const rowValue = getRowCountContributionFromData(chartData)
                    if (FEATURE_DRIFT_ALIASES.includes(chartData["name"]) && rowValue !== GENERIC_CONSTANTS.NA) {
                        chartType = SINGLE_ARROW_CHART_TYPE;
                        if (chartData["type"] === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT) {
                            chartData["name"] = FEATURE_DRIFT_ALIAS_SINGLE_ARROW_RED_TEXT;
                         } else {
                            chartData["name"] = FEATURE_DRIFT_ALIAS_SINGLE_ARROW_GREEN_TEXT;
                         }
                    }

                    let filteredData = null;
                    let propsStartDate = this.state.startDate === null ? this.props.startDate : this.state.startDate;
                    let propsEndDate = this.state.endDate === null ? this.props.endDate : this.state.endDate;
                    if (propsStartDate !== null && propsEndDate !== null) {
                        filteredData = getChartFilterData(chartData, propsStartDate,
                            propsEndDate, chartType, false, false, MAX_DATA_POINTS_FOR_LIST_VIEW);
                    }

                    return (
                        <div key={key} className="col-md-6" id={idValue}>
                            <LazyLoadComponent placeholder={<Load isBootStrapColumn={true}/>}>
                                    <ChartFilterHeader
                                        hideTopMenuOptions={chartType === SINGLE_ARROW_CHART_TYPE}
                                        yValue={chartData["name"]}
                                        showHeatMap={true}
                                        showSettingsMenu={true}
                                        filteredData={filteredData}
                                        scale={chartData.scale}
                                        colorCode= {chartData["color"]}
                                        lineColorCode = {LIGHT_COLOR_MAP[chartData["color"]]}
                                        label={chartData["label"]}
                                        data={chartData}
                                        startDate={propsStartDate}
                                        endDate={propsEndDate}
                                        timeFilterApplied={true}
                                        dataSetId={id}
                                        mlModelId={id}
                                        chartLevel ={chartLevel}
                                        showContribution = {chartLevel === "Dataset"} 
                                        selectedIntegration={this.props.selectedIntegration}
                                        compareAttributeOptions={compareAttributeOptions}
                                        compareMLAttributeOptions={compareMLAttributeOptions}
                                        compareDataSrc={compareDatasetOptions}
                                        compareModelOptions = {compareModelOptions}
                                        aggregateOptions = {aggregateOptions}
                                        title={title}
                                        compareType ={compareType}
                                        chartType={chartType}
                                        variant={this.state.variant}
                                        chartTimeFilter={this.chartTimeFilter}
                                        hideDistanceFilter = {true}
                                        dataSetName = {chartData["datasetName"]}
                                        attributeName = {chartData["attribute_name"]}
                                        referenceDataSetName = {chartData["referenceDataSetName"]}
                                        referenceDataSetLabel = {chartData["referenceDataSetLabel"]}
                                        showAggregateError = {showAggregateError}
                                    />
                            </LazyLoadComponent>
                        </div>

                    );
                } else if(lastDiv === true) {
                    return  (<NoErrorComponent/>);
                }
                return '';
            });
        }
    }

    getFilteredChartData(data, selectedMLModelIDs){
        let attributeGrouping = _.cloneDeep(data);
        for (let group of attributeGrouping){
            const updatedData = [];
            for (let chartData of group.data){
                if(this.state !== undefined && this.state.advancedModels.length > 0) {
                    let filteredModels = this.state.advancedModels.filter(x=>Number(x.value) === Number(chartData.ml_model_id))
                    if(filteredModels.length === 0) {
                       continue
                    }
                    let metricName = typeMappings[chartData.name];
                    let filteredMetrics = this.state.selectedMetrics.filter(x=>x.label === metricName || x.label === chartData.name);
                    if(filteredMetrics.length === 0) {
                      continue
                    }
                    let typeFiltered = filteredMetrics.filter(x=>["type4", "type5"].includes(x.type))
                    if(typeFiltered.length > 0) {
                      let filteredAttributes = this.state.selectedAttributes.filter(x=>x.value === chartData.attribute_id)
                      if(filteredAttributes.length === 0) {
                        continue
                      }
                    }
                }else if(selectedMLModelIDs !== undefined && !selectedMLModelIDs.includes(String(chartData.ml_model_id))){
                      continue;
                }

                updatedData.push(chartData);
            }
            group["data"] = updatedData;
        }

        return attributeGrouping;
    }

    render() {
        let page = this.props.showModelFilter ? "model": "datasource";
        return (
            (this.props.modalData.length === 0) ?
                 <>
                    <div className="text-center text-muted py-5 w-100">
                        <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                        <h4>{`Drift values are currently not available for the selected ${page}.`}</h4>
                        <p>Please check after sometime.</p>
                    </div>
                 </>
                :
                <>
                <div className="qd-grid qd-grid-monitor">
                    <div className="qd-grid-item qd-section">
                        <div className="row row-sm">
                            {this.renderGridItem()}
                        </div>
                    </div>
                    <div className="qd-grid-item qd-aside">
                        <div className="d-flex align-items-center w-100 h-100 justify-content-center"
                             style={{height:"200px"}}>
                            {
                                this.props.disableShowMore === true ? '' :
                                <div className="show-more monitor-row_show-more"
                                     onClick={this.setFullScreen}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </span>
                                    <p>Show More</p>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {this.state.fullScreen === "show" ?
                <div className={"qd-fullscreen fade " + this.state.fullScreen}>
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                    <h4 className="header_title">
                                        {this.state.title}
                                    </h4>
                                </div>
                                <div className="actions">
                                    <AdvancedFilterModel
                                         customMetricsOptions={this.state.customMetricsOptions}
                                         mlModelOptions={this.props.mlModelOptions}
                                         selectedMlModel={this.state.selectedModel !== null ? [this.state.selectedModel] : this.state.selectedModel}
                                         underPerformModelIDs={this.props.selectedMLModelID === undefined ? this.state.underPerformModelIDs : []}
                                         startDate={this.props.startDate}
                                         type="modelError"
                                         updateCharts={this.updateCharts}
                                         endDate={this.props.endDate}
                                         attributeOptions={this.props.attributeOptions}
                                    />
                                </div>
                                <button type="button" onClick={this.closeFullScreen} className="close" aria-label="Close">
                                    <span aria-hidden="true"/>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="qd-fs_body overflow-auto">
                                <div className="qd-grid qd-grid-monitor">
                                    <div className="qd-grid-item qd-section" key={this.state.isAdvancedFilterApplied}>
                                        { this.renderGroup() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>: ""}
            </>
        );
    }
}
export default Grid;

