import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import {postPipelinesource}  from "../../../../utils/event_handling";
import {userValidation} from "../../../../utils/common_utils"
import {PIPELINE_TEST_CONNECTION_MESSAGES_MWA_AIRFLOW} from "../../../../utils/constant";
import { faEye, faLock, faEyeSlash,faPlusCircle,faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import {faTimesCircle} from '@fortawesome/fontawesome-free-regular';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from '../../../../components/image.js';
import Alert from 'react-bootstrap/Alert';
//#SpecificPipeline Code
const SpecificPipeline = (props) => {
      if (props.clickedValue === undefined){
          return '';
      }
      let tablelist;
      tablelist = props.clickedValue.map(function(elem){
          return elem.pipeline_name; }).join(",");
      tablelist = tablelist.split(",");

      return props.clickedValue.map((value,idx) => {
          let name = props.name;

          let checked = props.clickedValue[idx]["is_exact_match"] === true ? "checked" : "";
          return (
      <div className="dataset-partition-card">
                                {(props.pipelineToBeProcessed === "specifiedPipeline" && props.clickedValue.length > 1) ?( <i onClick={props.removeSpecificPipeline.bind(null,idx,props.name,props.clickedValue[idx][name])} className="icon-close text-danger"><FontAwesomeIcon icon={faTimesCircle} /></i>):""}
                                            <div className="dp-card__content">
                                            { props.pipelineToBeProcessed === "allPipeline" ?
                                                  <span className="dp-card__label">Add Incremental Data Identification (Optional)</span>
                                                  :
                                                  <span className="dp-card__label">Pipeline {idx+1} to be processed</span>}
                                                  <div className="form-row">
                                                        <Form.Group className="col-md-6 col-lg-4">
                                                              <Form.Label>Pipeline Name
                                                                  {props.pipelineToBeProcessed === "specifiedPipeline" ?
                                                                  <span class="text-danger">*</span> : "" }
                                                                  <OverlayTrigger
                                                                      placement="top"
                                                                      overlay={
                                                                          <Tooltip>This Dataset will get partition.</Tooltip>
                                                                      }
                                                                      >
                                                                      <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                  </OverlayTrigger>
                                                              </Form.Label>
                                                              <Form.Control
                                                                    type="text"
                                                                    className={(props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined  || (props.clickedValue[idx]["pipeline_name"]!=="" && props.clickedValue[idx]["pipeline_name"]!==undefined && tablelist.filter(x => x===props.clickedValue[idx]["pipeline_name"]).length > 1))   ? "form-control is-invalid" : "form-control"}
                                                                    placeholder="Pipeline Name"
                                                                    name={"specific_pipeline_name_"+(idx+1)}
                                                                    id={"specific_pipeline_name_"+(idx+1)}
                                                                    data-index={(idx)}
                                                                    data-key={"pipeline_name"}
                                                                    value={((props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined)) ? "" : props.clickedValue[idx]["pipeline_name"]}
                                                                    onChange={(e) => {props.changePipelineText(e)}}
                                                              />
                          {
                          (props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined)  ? <div class="error-text">Pipeline Name is required field</div> : ""
                        }
                         {
                          (props.clickedValue[idx]["pipeline_name"]!=="" && props.clickedValue[idx]["pipeline_name"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["pipeline_name"]).length > 1) ? <div class="error-text">Pipeline Name  already added</div> : ""
                        }
                                                                  {checked !=="" ?

                                                              <Form.Text className="text-muted">
                                                                  <Form.Check
                                                                      custom
                                                                      type="checkbox"
                                                                      label="Exact match only"
                                                                      id={"exact-match_"+(idx+1)}
                                                                      data-index={(idx)}
                                                                      data-key={"is_exact_match"}
                                                                      name={"specic_exact_match_"+(idx+1)}
                                                                      defaultChecked={(true)}
                                                                      onChange={(e) => {props.handleExactMatch(e)}}
                                                                  />
                                                              </Form.Text>
                                                              :
                                                              <Form.Text className="text-muted">
                                                              <Form.Check
                                                                  custom
                                                                  type="checkbox"
                                                                  label="Exact match only"
                                                                  id={"exact-match_"+(idx+1)}
                                                                  data-index={(idx)}
                                                                  data-key={"is_exact_match"}
                                                                  name={"specic_exact_match_"+(idx+1)}
                                                                  onChange={(e) => {props.handleExactMatch(e)}}
                                                              />
                                                          </Form.Text>
                                                          }
                                                        </Form.Group>
                                                  </div>
                                            </div>
      </div>)
    //}
      });
  }
//#SpecificPipeline Code


class MwaAirflow extends React.Component {
        constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postPipelinesource = postPipelinesource.bind(this);
        this.postMwaAirflowData = this.postMwaAirflowData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangesecretKeyIcon = this.ChangesecretKeyIcon.bind(this);
        //#SpecificPipeline Code
        this.handlePipelineChange = this.handlePipelineChange.bind(this);
        this.addSpecificPipeline = this.addSpecificPipeline.bind(this);
        this.removeSpecificPipeline = this.removeSpecificPipeline.bind(this);
        this.handleExactMatch = this.handleExactMatch.bind(this)
        this.changePipelineText  = this.changePipelineText.bind(this);
        this.state = {
            formType_option:null,
            isChecked: true,
            hideSubmitButton: false,
            integration_data: this.props.integration_data,
            integration_data_edit: "",
            airflow_url:'',
            secretKeyType:'password',
            secretKeyIcon:faEye,
            //#SpecificPipeline Code
            pipelineToBeProcessed:"allPipeline",
            partition:[],
            specificPipeline:[{"pipeline_name":"","is_exact_match":"False"}],
            dataset_processed_edit:true,
            updateData:false
        };
       }

       componentDidMount() {
        this.fill_details();

        //   console.log(this.props.ex_integration_details);
            // return;

        //#SpecificPipeline Code
        if(this.props.ex_integration_details!==null){


        if(this.props.ex_integration_details[0]['pipeline_config']['pipeline_name']!==undefined){
            for(let loop=0;loop<this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'].length;loop++){
                let selectobj = {};
                selectobj.label= this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'][loop]["pipeline_name"];
                selectobj.value= this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'][loop]["pipeline_name"];
           }
        }
    this.setState({
            pipelineToBeProcessed:this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined ? "allPipeline" : this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'],
            dataset_processed_edit : this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined ? true : false,
            specificPipeline:this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'],
    });
    if(this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined){
        this.setState((prevState) => ({
            specificPipeline:[]
        }))
    }
    }
    //#SpecificPipeline Code
    }

//#SpecificPipeline Code
  changePipelineText(e){
      let specificPipeline = [...this.state.specificPipeline];

      if(e.currentTarget.getAttribute("data-key")==="pipeline_name"){
          let selectobj = {};
          selectobj.label= e.currentTarget.value;
          selectobj.value= e.currentTarget.value;
      }
      if (this.state.pipelineToBeProcessed === "specifiedPipeline"){
      specificPipeline[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.currentTarget.value;
      }
      this.setState({specificPipeline:specificPipeline, updateData:true});
  }

  handleExactMatch(e){
      let specificPipeline = [...this.state.specificPipeline];
      specificPipeline[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.target.checked === true ? "True" : "False";
      this.setState({specificPipeline:specificPipeline});
  }
  handlePipelineChange(e){
      if(e.target.value==="specifiedPipeline"){
              this.setState((prevState) => ({
                  specificPipeline: [{"pipeline_name":"","is_exact_match":"False"}]
              }))
          }
          this.setState({
            pipelineToBeProcessed: e.target.value
          })
  }
  addSpecificPipeline(e){
      e.preventDefault();
      let checkpartitionvalidation = true;
      let specificPipeline = this.state.specificPipeline;
      if (this.state.pipelineToBeProcessed === "specifiedPipeline"){
          for(let loop=0;loop<specificPipeline.length;loop++){
              if(specificPipeline[loop]['pipeline_name']==="" ||
              specificPipeline[loop]['pipeline_name']=== undefined
              ){
                  checkpartitionvalidation = false;
                  break;
              }
          }
      }
      if(checkpartitionvalidation===true){
      let tempData = null
      // tempData = Object.assign({});
      tempData ={"pipeline_name":"","is_exact_match":"False"}
      // idx = this.state.specificPipeline.length-1;
          this.setState((prevState) => ({
              specificPipeline: [...prevState.specificPipeline, tempData]
          }));
      }
  }
  removeSpecificPipeline  = (idx,name) => {
      this.state.specificPipeline.splice(idx,1)
      this.setState({specificPipeline:this.state.specificPipeline, updateData:true});
  }
//#SpecificPipeline Code

       toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
       }

       fill_details() {
            if (this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) {

               let integration_data =  this.state.integration_data;

            //   alert(JSON.stringify(this.props.ex_integration_details));

               integration_data.integration_name = this.props.ex_integration_details[0]["pipeline_integration_name"];
               integration_data.integration_id = this.props.ex_integration_details[0]["pipeline_integration_id"];

                integration_data.access_key = this.props.ex_integration_details[0]["auth_config"]["access_key"];
                integration_data.secret_key = this.props.ex_integration_details[0]["auth_config"]["secret_access_key"];
            //    integration_data.tenant_id = this.props.ex_integration_details[0]["auth_config"]["tenant_id"];


               integration_data.region = this.props.ex_integration_details[0]["pipeline_config"]["region"];
               integration_data.env_name = this.props.ex_integration_details[0]["pipeline_config"]["environment"];
               integration_data.pipeline_name = this.props.ex_integration_details[0]["pipeline_config"]["given_pipeline_name"];

            //    alert(JSON.stringify(integration_data));

               this.setState({
                            integration_type: this.props.formType,
                            integration_data_edit: integration_data
                            });
            }

       }

       postMwaAirflowData(event) {
            let pipelineToBeProcessed= this.state.pipelineToBeProcessed;
            let specificPipeline = this.state.specificPipeline;
            let checkpartitionvalidation = true;

             let pipeline_name_filter = specificPipeline.map(function(elem){
             return elem.pipeline_name; }).join(",");
             if(pipeline_name_filter!==""){
                 pipeline_name_filter = pipeline_name_filter.split(",");
             }

             if(pipelineToBeProcessed === "specifiedPipeline" && specificPipeline.length > 0){
                specificPipeline.forEach( input => {
                    if(input['pipeline_name']==="" ||
                    input['pipeline_name']=== undefined
                        ){
                            checkpartitionvalidation = false;
                            return;
                        }
                        else {
                        if(pipeline_name_filter.filter(x => x===input["pipeline_name"]).length > 1) {
                            checkpartitionvalidation = false;
                            return;
                        }
                    }
                })
             }else{
             specificPipeline=[];
            }
            if(checkpartitionvalidation===false){
             return;
            }
            this.disableButton();
            const {integration_name,access_key,secret_key,
            region, env_name} = event;
            let mwa_airflowData = {"pipeline_integration_name":integration_name,
                                 "integration_id":this.state.integration_data.integration_id,
                                 "env_id":this.props.env_id,
                                 "auth_config": {
                                    "access_key": access_key,
                                    "secret_access_key": secret_key,

                                  },
                                  "pipeline_config": {
                                    "region":region,
                                    "environment":env_name,
                                    "pipelineToBeProcessed":this.state.pipelineToBeProcessed,
                              "pipeline_name": specificPipeline,
                                  },
                                  "tenant_id": localStorage.getItem('team_id'),
                                  "pipeline_type": "mwa_airflow",
                            }
            let default_message = PIPELINE_TEST_CONNECTION_MESSAGES_MWA_AIRFLOW[this.props.formType];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(mwa_airflowData, default_message);
            this.postPipelinesource(mwa_airflowData);
        }

        getSchema() {
           let mwa_airflowSchema = '';
           if(this.props.formType === "mwa_airflow" && this.state.isChecked === true) {
                  mwa_airflowSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Pipelinesource Name is a required field").test(
                              'Pipelinesource name test',
                              'invalid Pipelinesource name',
                              function test(value) {
                                 let val = userValidation(value,"input");
                                 return val;
                          }
                          ),
                        region: yup.string().required('AWS region is a required field'),
                        access_key: yup.string().required('AWS access key is a required field'),
                        secret_key: yup.string().required('AWS secret key is a required field'),
                        env_name: yup.string().required('Enviroment Name is a required field'),

                  });
           }
           return mwa_airflowSchema;
        }

        disableButton() {
            this.setState({hideSubmitButton: true});
        }

        ChangesecretKeyIcon() {
            let state = this.state.secretKeyIcon;
            if (state.iconName === "eye") {
                this.setState({secretKeyIcon: faEyeSlash});
                this.setState({secretKeyType: "text"})
              } else {
                this.setState({secretKeyIcon: faEye});
                this.setState({secretKeyType: "password"})
              }
        }


        render() {
            let airflowSchema = this.getSchema();
            let initial_values = {
                integration_name: '',
                error_storage: '',
                access_key:'',
                secret_access_key:'',
                region:'',
                env_name:''
               };

               let edit_integration = null;
               if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
                   edit_integration = true;
               }
               else {
                   edit_integration = false;
               }
            return (
                  <>
                        <Formik
                              validationSchema={airflowSchema}
                              onSubmit={this.postMwaAirflowData}
                              validateOnBlur={false}
                              initialValues={edit_integration === true ? this.state.integration_data_edit : initial_values}
                              enableReinitialize={true}                        >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              isValid,
                        }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group controlId="integration_name" className="form-group col-md-6">
                                <Form.Label>Pipeline Source Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="integration_name"
                                      value={values.integration_name === undefined ? '' : values.integration_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.integration_name && touched.integration_name}
                                      placeholder="Pipeline Source Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                           { touched.integration_name && errors.integration_name }
                                </Form.Control.Feedback>
                            </Form.Group>
                                  <>

                                    <Form.Group controlId="access_key" className="col-md-6">
                                         <Form.Label>AWS Access Key <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="access_key"
                                               value={values.access_key === undefined ? "" : values.access_key}
                                               onChange={handleChange}
                                               placeholder="AWS Access Key"
                                               onBlur={handleBlur}
                                               isInvalid={errors.access_key && touched.access_key}
                                         />

                                         <Form.Control.Feedback type="invalid">
                                           { touched.access_key && errors.access_key }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="secret_key" className="col-md-6">
                                         <Form.Label>AWS Secret Key<span className="text-danger">*</span></Form.Label>
                                         <InputGroup bsPrefix="icon-input-group">
                                          <i>
                                                <FontAwesomeIcon icon={faLock}/>
                                          </i>
                                                <Form.Control
                                                      type={this.state.secretKeyType}
                                                      className="form-control"
                                                      name="secret_key"
                                                      value={values.secret_key === undefined ? "" : values.secret_key}
                                                      onChange={handleChange}
                                                      placeholder="AWS Secret Key"
                                                      onBlur={handleBlur}
                                                      isInvalid={errors.secret_key && touched.secret_key}
                                                      autoComplete={this.props.autocomplete}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                { touched.secret_key && errors.secret_key }
                                                </Form.Control.Feedback>

                                                <i className="security-eye">
                                                    <FontAwesomeIcon onClick={this.ChangesecretKeyIcon} icon={this.state.secretKeyIcon} className="security-eye"/>
                                                </i>
                                          </InputGroup>

                                    </Form.Group>
                                    <Form.Group controlId="region" className="col-md-6">
                                         <Form.Label>AWS Region <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="region"
                                               value={values.region === undefined ? "" : values.region}
                                               onChange={handleChange}
                                               placeholder="AWS Region"
                                               onBlur={handleBlur}
                                               isInvalid={errors.region && touched.region}
                                         />

                                         <Form.Control.Feedback type="invalid">
                                           { touched.region && errors.region}
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="env_name" className="col-md-6">
                                         <Form.Label>Environment Name<span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="env_name"
                                               value={values.env_name === undefined ? "" : values.env_name}
                                               onChange={handleChange}
                                               placeholder="Environment Name"
                                               onBlur={handleBlur}
                                               isInvalid={errors.env_name && touched.env_name}
                                         />

                                         <Form.Control.Feedback type="invalid">
                                           { touched.env_name && errors.env_name}
                                         </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="col-md-6">
<Form.Label>Pipeline to be processed</Form.Label>
<div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id="allPipeline"
                                                          name="allPipeline"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          checked={this.state.pipelineToBeProcessed === "allPipeline"}
                                                          value="allPipeline"
                                                          onChange={this.handlePipelineChange}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="allPipeline">All Pipelines</Form.Label>
</div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id="specifiedPipeline"
                                                          name="specifiedPipeline"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          value="specifiedPipeline"
                                                          onChange={this.handlePipelineChange}
                                                          checked={this.state.pipelineToBeProcessed === "specifiedPipeline"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="specifiedPipeline">Specified Pipelines</Form.Label>
</div>
</div>
</Form.Group>
{edit_integration === true?
<div className="col-12  partition_alert_info">
    <Alert variant="warning">
        <p className="mb-0 alert-warning-text"><i className="mr-2"><FontAwesomeIcon icon={faInfoCircle} /></i>Current Metrics and all related information will be lost on modifying existing specified pipelines</p>
    </Alert>
</div>
    :""}
{this.state.pipelineToBeProcessed === "specifiedPipeline" ?
       <>
       <SpecificPipeline
           clickedValue={this.state.specificPipeline}
           pipelineToBeProcessed={this.state.pipelineToBeProcessed}
           partitiondatasetlist={this.state.partitiondatasetlist}
           changePipelineText={this.changePipelineText}
           removeSpecificPipeline={this.removeSpecificPipeline}
           handleExactMatch={this.handleExactMatch}
       />

       <a href="!#" onClick={this.addSpecificPipeline}>
       {
        this.state.pipelineToBeProcessed === "specifiedPipeline" ?
        <><i className="mr-1"><FontAwesomeIcon icon={faPlusCircle} /></i>Add Another Pipeline</>
        :
        ""
       }
        </a>
       <hr className="clearfix w-100" />
       </>
      : ""
    }

{/*
                                    <Form.Group controlId="airflow_pipelinename" className="col-md-6">
                                         <Form.Label>Airflow Pipelinename</Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="airflow_pipelinename"
                                               value={values.airflow_pipelinename === undefined ? "" : values.airflow_pipelinename}
                                               onChange={handleChange}
                                               placeholder="Airflow Pipelinename"
                                               onBlur={handleBlur}
                                               isInvalid={errors.airflow_pipelinename && touched.airflow_pipelinename}
                                         />

                                         <Form.Control.Feedback type="invalid">
                                           { touched.airflow_pipelinename && errors.airflow_pipelinename }
                                         </Form.Control.Feedback>
                                    </Form.Group> */}


                                  </>
                            <div className="checkbox col-md-12" style={{"padding": "10px 5px"}}>
                                 <Form.Label>
                                     <Form.Control
                                           type="checkbox"
                                           name="errorColumn"
                                           onChange={this.toggleChange}
                                           checked={this.state.isChecked}/>
                                     <span className="checkbox-decorator">
                                            <span className="check">
                                            </span>
                                     </span>Store Pipeline Issues and Errors in Qualdo™
                                 </Form.Label>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error Storage Sub Path <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="error_storage"
                                      placeholder="Error Storage Sub Path"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.error_storage && touched.error_storage}
                                      value={values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                           { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                             </Form.Group> : ''}
                        </div>
                        {edit_integration === true  && this.state.updateData=== true?
                        <Alert variant="danger" className='partition_alert_check'>
                        <div className="custom-control custom-checkbox custom-checkbox-lg checkbox-valign-middle">
                            <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="threshold"
                                id="partion-checkbox"
                            />
                            <Form.Label className="custom-control-label pl-2" htmlFor="partion-checkbox">
                                <div className="d-sm-flex align-items-center">
                                    <Image src="icon_layer_partition" className="mr-2" />
                                    <p className="mb-0 alert-danger-text">Current Metrics and all related information will be lost on modifying existing specified pipelines. <strong>Are you sure you want to go ahead ?</strong></p>
                                </div>
                            </Form.Label>
                        </div>
                    </Alert>:""}
                    <hr className="clearfix"/>
                        <div className="text-right">
                            {this.props.showCancel ?
                                <button type="button"
                                        onClick={() => this.props.ex_integration_details ?
                                            this.props.closeModal() :
                                            this.props.setPage(false)}
                                        className="btn btn-outline btn-grey btn-circle mr-2">
                                    Cancel
                                </button> : ''
                            }
                             <button type="submit"
                                     disabled={this.state.hideSubmitButton}
                                     className="btn btn-primary btn-circle mr-2">
                                 Test Connection
                             </button>
                        </div>
                        </Form>)}
                        </Formik>
                </>
            );
        }
}
export default MwaAirflow;