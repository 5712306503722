import React from "react";
import BasicPortlet from "../../components/basicPortlet";
import Load from "../../components/loadAction";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import ChartFilterHeader from "./chartFilterHeader";
import NoErrorComponent from './monitorNoResult';
import moment from 'moment';

import {
    getChartType, convertDecimalDigits,
    getChartFilterData
} from "../../utils/common_utils";
import {
    chartTypes, NO_ERROR_CHART_COLORS,
    LIGHT_COLOR_MAP, MAX_DATA_POINTS_FOR_LIST_VIEW
} from "../../utils/constant";
import {getDateObject} from "../../charts/browser_utils";

class MonitorChartGroup extends React.Component {

    renderModalChart() {
        if (this.props.groupData) {
            if (this.props.loadValue === true) {
                return (
                    <div className="col-md-12">
                        <BasicPortlet
                            className="mb-0 pb-0" title="" bodyClassName="pb-0"
                            content={<Load/>}
                        />
                    </div>
                );
            }

            let charts = this.props.groupData["data"];
            if (charts.length === 0){
                return (
                    <NoErrorComponent
                        message={"Model performance not computed for the selected model."}/>
                );
            }

            let chartCount = 0;
            let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
            let is_same_col = false;
            let j = 0;

            return charts.map((chartData, index) => {
                let key = chartData["key"];
                let id = chartData["ml_model_id"];
                let title = chartData["chartTitle"];
                let chartType = chartData["chartType"];
                let idValue = ("modelPerformanceShowMoreChart" + chartData["data_source_name"] + "_" + chartData["name"]).replace(/ /g,"_");
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                chartCount = chartCount + 1;
                let isTimeSeries = (chartData.isNonTimeSeries === undefined || !chartData.isNonTimeSeries);
                if (chartType === 'areaChart' && isTimeSeries){
                    chartType = getChartType(chartData, chartCount)
                }
                if (is_same_col === false) {
                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                    is_same_col = true;
                } else {
                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                    is_same_col = false;
                    j = j + 1;
                }
                if (j === 4) {
                    j = 0;
                }

                let filteredData = null;
                let propsStartDate = this.props.startDateInside === null ? this.props.startDate: this.props.startDateInside;
                let propsEndDate = this.props.endDateInside === null ? this.props.endDate : this.props.endDateInside;
                if (propsStartDate !== null && propsEndDate !== null) {
                    filteredData = getChartFilterData(chartData, propsStartDate,
                        propsEndDate, chartType, true, this.props.considerEndDate, MAX_DATA_POINTS_FOR_LIST_VIEW);

                    let timeVals = null;
                    if (filteredData !== null) {
                        timeVals = filteredData.time;
                    }

                    if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                        const dateObj = getDateObject(timeVals[0]);
                        propsStartDate = moment(dateObj);

                        // propsEndDate = moment(new Date(timeVals[timeVals.length - 1]));
                    }
                }
                let col_size ="col-md-6";
//                if(chartType === "multiLineFeatureChart"){
//                    col_size = "col-md-12"
//                }

                return (
                    <LazyLoadComponent key={`lazy_comp_${key}`} placeholder={<Load isBootStrapColumn={true}/>}>
                        <div key={key} id={idValue} className={col_size}>
                            <ChartFilterHeader
                                tab={this.props.tab}
                                y2Value={chartData["y2Value"]}
                                y1Value={chartData["y1Value"]}
                                yValue={chartData["yValue"]==="mean shap value" ? "Feature Contribution Value" : chartData["yValue"]}
                                timeFilterApplied={this.props.timeFilterApplied}
                                xValue={chartData["xValue"]}
                                isError={chartData["isError"]}
                                colorCode= {chartData["color"]}
                                lineColorCode = {LIGHT_COLOR_MAP[chartData["color"]]}
                                toolTipTitle={chartData["toolTipTitle"]}
                                label={chartData["label"]}
                                showHeatMap={false}
                                showSettingsMenu={chartType !== chartTypes.CONFUSION_MATRIX}
                                key={`model_performance_${key}_${id}`}
                                customKey={`model_performance_${id}`}
                                scale={chartData["scale"]}
                                mapModel={this.props.mapModel}
                                selectedMLModel={this.props.selectedMlModel}
                                data={chartData}
                                filteredData={filteredData}
                                dataSetId={id}
                                dataSetName={chartData["data_set_name"]}
                                multipleLines={chartData.multipleLines}
                                mlModelId={id}
                                title={title}
                                chartType={chartType}
                                startDate={propsStartDate}
                                endDate={propsEndDate}
                                usePropsTitle={true}
                                variant={this.props.variant}
                                hideDistanceFilter={true}
                                chartTimeFilter={this.props.chartTimeFilter}
                            />
                        </div>
                    </LazyLoadComponent>
                );
            });
        }
    }

    render() {
        if (this.props.groupData === undefined || this.props.groupData === null){
            return ""
        }

        return (
            <div className="qd-chart-group m-0 mb-4">
                <div className="qd-chart-group-heading">
                    <h4 className="qd-chart-group-title">
                        <span className="circle-number">
                            {this.props.index}
                        </span>
                        {this.props.groupData.header}
                    </h4>
                    <p className="m-0">
                        {this.props.groupData.sub_header}
                    </p>
                </div>
                <div className="qd-chart-group-body" key={this.props.isAdvancedFilterApplied}>
                    <div className="row row-sm">
                        {this.renderModalChart()}
                    </div>
                </div>
            </div>
        );
    }
}

export default MonitorChartGroup;
