import mixpanel from 'mixpanel-browser';
import {MIXPANEL_KEY} from './utils/constant'

mixpanel.init(MIXPANEL_KEY, {debug: true});

export function postMixpanel(event_name, data) {
    if (MIXPANEL_KEY === undefined) {
        return;
    }

    if (data === undefined) {
        data = {}
    }

    mixpanel.track(event_name, data);
}

export function postMixPanelIdentifyData(data) {
    if (MIXPANEL_KEY === undefined) {
        return
    }

    mixpanel.identify(data);
}

export function postMixpanelPeopleData(data) {
    if (MIXPANEL_KEY === undefined) {
        return;
    }
    mixpanel.people.set(data);
}