import React from 'react';
import DqListViewContent from "./dqListView";
import DqGridViewContent from "./dqGridView";
import {faChartLine, faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";

// import {
//     extractDatasetInfoForMonitorListView,
//     getMonitorTabDQListViewTableData
// } from "../utils/common_utils";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

class MonitorDQTabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);
        let metricsList = this.props.monitorModule.metrics;

        this.state = {
            activeTabKey: "dq_list_view",
            metricsList: metricsList,
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     let propsMonitorModule = props.monitorModule;
    //     const monitorDQListViewData = propsMonitorModule.monitorDQListView;
    //     if (monitorDQListViewData === undefined || monitorDQListViewData === null) {
    //         return null;
    //     }

    //     // If API fetch is over, we can hide the loader - showLoader => False
    //     let showLoader = !monitorDQListViewData.apiFetchOver;
    //     if (state.showLoader !== showLoader || state.timelinessData !== props.timelinessData) {
    //         // API has fetched some new data. So recompute and update the table data
    //         // const metaDataMapping = props.dataModule.metaData;
    //         // const mappedDatasetInfo = extractDatasetInfoForMonitorListView(metaDataMapping);
    //         // const reqTableData = getMonitorTabDQListViewTableData(mappedDatasetInfo, monitorDQListViewData, props.monitorModule, props.timelinessData);

    //         // const listViewTableData = reqTableData.tableData;
    //         const metricsList = props.monitorModule.metrics

    //         return {
    //             showLoader: showLoader,
    //             // listViewTableData: listViewTableData,
    //             // tableRowCount: listViewTableData.length,
    //             // mappedDatasetInfo: mappedDatasetInfo,
    //             metricsList: metricsList
    //         }
    //     }


    //     return null;
    // }


    changeTab(tab) {
        this.setState({activeTabKey: tab});
    }
    
    componentDidUpdate(prevProps){
        if(this.state.activeTabKey !== prevProps.activeTabKey){
            
        }
    }

    render() {
        return (
            <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                <div className="tabbable-line mb-3">
                    <Nav variant="tabs" id="page-nav-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="dq_grid_view">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </i>  Chart View</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="dq_list_view">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faList}/>
                                        </i>  List View</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                            <div className="tab-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="dq_grid_view">
                                        <div className="portlet">
                                            <div className="portlet-body">
                                                {this.state.activeTabKey === 'dq_grid_view'?
                                                        <DqGridViewContent 
                                                            integrationsMap={this.props.integrationsMap}
                                                            mappedDatasetInfo={this.props.mappedDatasetInfo}
                                                            filterData={this.props.filterData}
                                                            metricsList={this.state.metricsList}
                                                            integrationMapping={this.props.integrationMapping}
                                                            metaData = {this.props.metaData}
                                                            default_filter_data={this.props.default_filter_data}
                                                            setCustomFilterValues={this.props.setCustomFilterValues}
                                                            noIntegration = {this.props.noDataFound}
                                                            noDataFound = {this.props.noDataFound}
                                                        />
                                                    :
                                                        ''
                                                }
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dq_list_view">
                                    {this.state.activeTabKey === 'dq_list_view'?
                                            <DqListViewContent 
                                                showLoader={this.state.showLoader}
                                                integrationsMap={this.props.integrationsMap}
                                                monitorModule={this.props.monitorModule}
                                                timelinessData={this.props.timelinessData}
                                                firstDatasetUp={this.props.firstDatasetUp}
                                                mappedDatasetInfo={this.props.mappedDatasetInfo}
                                                metricsList={this.state.metricsList}
                                                noIntegration = {this.props.noDataFound}
                                                noDataFound = {this.props.noDataFound}
                                                filterData={this.props.filterData}
                                                dataModule={this.props.dataModule}
                                                follow_attributes={this.props.follow_attributes}
                                                integrationMapping={this.props.integrationMapping}
                                                metaData = {this.props.metaData}
                                                default_filter_data={this.props.default_filter_data}
                                                setCustomFilterValues={this.props.setCustomFilterValues}

                                            />
                                        :
                                            ''
                                    }
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
            </Tab.Container>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MonitorDQTabs);
