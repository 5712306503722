import React from 'react';
import DqPerformance from "./dqPerformance";
import {
    getMonitorInfo,
} from "../utils/monitorEventHandling";
import {
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    setDataSource,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyPreview,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMonitorPreview,
    prefetchMonitorState,
} from "../redux/actions";
import { connect } from "react-redux";
// import { post_api_call_cnt, post_api_call_complete_cnt } from "../utils/event_handling"
import Load from '../components/loadAction';

class DqpGridViewContent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.getMonitorInfo = getMonitorInfo.bind(this)
        this.fetchMonitorInfo = this.fetchMonitorInfo.bind(this)
        this.fetchDataSourceData = this.fetchDataSourceData.bind(this)
        this.pre_load = true
        this.state = {
            activeTabKey: "dqp_grid_view",
            render: true,
            monitorModuleProps: {},
            showLoader:true,
            selectedDataSrc:null,
            last_processed_time:null,
            inProgress:this.props.inProgress,
            noIntegration:this.props.noIntegration,
            noDataFound:this.props.noDataFound,

        };
    }

    fetchMonitorInfo(id=null, tab=null) {
        let teamID = localStorage.getItem('team_id')
        let tenantData = { "tenant_id": teamID };
        let integrationId = id

        if(integrationId !== null){
            this.setState({showLoader:true})
            this.getMonitorInfo(
                tenantData,this.props.addTimelinessDataSetInfo,
                 this.props.addRecencyPreview,integrationId
            );
        }
        else{
            this.setState({showLoader:true})
            this.getMonitorInfo(
                    tenantData, this.props.addTimelinessDataSetInfo,
                     this.props.addRecencyPreview,                    
                );
        }
        
    }

    componentDidMount() {
        if(this.props.default_filter_data.datasource!==undefined && this.props.default_filter_data.datasource > 0 ){
            this.setState({
                startDate: this.props.default_filter_data.start_date !==undefined ? this.props.default_filter_data.start_date : this.state.startDate,
                endDate: this.props.default_filter_data.end_date !==undefined ? this.props.default_filter_data.end_date : this.state.endDate ,
               timeFilterApplied: true
            }, function() {
               this.fetchMonitorInfo(this.props.default_filter_data.datasource, 'dqp_grid_view')
            });
        }else {
            this.fetchMonitorInfo();   
        }
    }
    
    // shouldComponentUpdate() {
    //     if (this.state.showLoader === false) {
    //         return true
    //     }   
    //     return true

    //     // if (post_api_call_cnt === post_api_call_complete_cnt && this.state.pre_load === true) {
    //     //     this.pre_load = false;
    //     //     // this.setState({ pre_load: false, showLoader:false })
    //     //     return true
    //     // }
    //     // else {
    //     //     return false;
    //     // }
    // }

    fetchDataSourceData(id, tab) {

        this.fetchMonitorInfo(id, tab);
    }

    render() {
        return (
            this.state.showLoader === true ? <Load /> :
                <DqPerformance
                    // integrationsMap={this.props.integrationsMap}
                    metricsList={this.props.metricsList}
                    metaData = {this.props.metaData}
                    mappedDatasetInfo = {this.props.mappedDatasetInfo}                                                                            showLoader = {this.state.showLoader}
                    monitorModuleProps={this.state.monitorModuleProps}
                    fetchDataSourceData={this.fetchDataSourceData}
                    noIntegration={this.state.noIntegration}
                    selectedDataSrc={this.state.selectedDataSrc}
                    filterData={this.props.filterData}
                    integrationMapping={this.props.integrationMapping}
                    last_processed_time={this.state.last_processed_time}
                    default_filter_data={this.props.default_filter_data}
                    setCustomFilterValues={this.props.setCustomFilterValues}
                    inProgress={this.state.inProgress}
                    noDataFound={this.state.noDataFound}
                >
                </DqPerformance>
        );
    }
}
const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps,
    {
        setDataSource,
        addCompletenessDataSetInfo,
        addCompletenessAttributeInfo,
        addTimelinessDataSetInfo,
        addTimelinessAttributeInfo,
        addAccuracyDataSetInfo,
        addAccuracyAttributeInfo,
        addConformityDataSetInfo,
        addConformityAttributeInfo,
        addConsistencyDataSetInfo,
        addConsistencyAttributeInfo,
        addDriftDataSetInfo,
        addDriftAttributeInfo,
        addMLDataSetInfo,
        addMLAttributeInfo,
        addUniquenessDataSetInfo,
        addUniquenessAttributeInfo,
        addRecencyPreview,
        addMonitorPreview,
        prefetchMonitorState
    }
)(DqpGridViewContent);