import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import RcTooltip from 'rc-tooltip';
import VideoContent from './videoContent';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import EnableConsistency from "./enableConsistency";
import {DISPLAY_DATE_FORMAT} from "../utils/constant";


const renderTooltip = (props) => (
    <span {...props}>
      Need Help ?
    </span>
  );

class MonitorPortlet extends React.Component {
    constructor(props) {
        super(props);
        this.changeSource = this.changeSource.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.state = {
            startDate: moment().subtract(29, 'days'),
            endDate: moment().utc().endOf('day'),
            showModal: false,
            selectedDataSrc: null,
            selectedModel: null
        }
      }

    handleClick() {
        this.setState({showModal:true});
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };


    changeSource(event, obj)
    {
        this.props.changeSource(event, this.props.id, this.props.title, this.state.startDate.format('YYYY-MM-DD 00:00:00'), this.state.endDate.format('YYYY-MM-DD 23:59:59'));
    }

    changeModel(event, obj)
    {
        this.props.changeModel(event, this.props.id, this.props.title, this.state.startDate.format('YYYY-MM-DD 00:00:00'), this.state.endDate.format('YYYY-MM-DD 23:59:59'));
    }

    handleTimeFilter(event, picker) {
         let startDate = picker.startDate;
         let endDate = picker.endDate;
         let selectedValue = {"label":"", value:null};
         if (this.props.selectedDataSrc !== undefined && this.props.selectedDataSrc !== null ) {
            selectedValue = this.props.selectedDataSrc;
         } else if(this.props.selectedMlModel !== undefined && this.props.selectedMlModel !== null) {
            selectedValue = this.props.selectedMlModel;
         }
         this.setState({startDate: startDate, endDate: endDate});
         this.props.changeTime(selectedValue, this.props.id, this.props.title, startDate.format('YYYY-MM-DD 00:00:00'),endDate.format('YYYY-MM-DD 23:59:59'));
    }

    render() {
        let ranges = {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };


        let srcOption = [];
        if (this.props.srcOption !== null && this.props.srcOption !== undefined) {
            srcOption = this.props.srcOption;
        }

        let defaultDataSrc = {"label":"", "value":""};
        if (this.props.selectedDataSrc != null) {
            defaultDataSrc = this.props.selectedDataSrc;
        }

        let defaultModel = {"label":"", "value":""};
        if(this.props.selectedMlModel != null ) {
            defaultModel = this.props.selectedMlModel;
        }
        return(
            <div className={`portlet has-numbered-title ${this.props.className}`} id={this.props.id}>
                <div className="portlet-title">
                    <div className="caption">
                    {this.props.index ? <span className="circle-number circle-number-gray">{this.props.index}</span> : ""}
                        <span className="text-uppercase font-dark">{this.props.title}</span>
                    </div>
                    <div className='actions d-flex'>
                        {
                            this.props.showFilter ?
                                    <Select
                                        name="data_sources"
                                        filterOption={({label}, query) => label.includes(query)}
                                        className="mr-2"
                                        classNamePrefix='select-control'
                                        options={srcOption}
                                        value = {defaultDataSrc}
                                        onChange={this.changeSource}
                                        placeholder="Choose Data source"
                                    />
                            : ''
                        }
                        {
                            this.props.showModelFilter ?
                                    <Select
                                        name="ml_model"
                                        filterOption={({label}, query) => label.includes(query)}
                                        className="mr-2"
                                        classNamePrefix='select-control'
                                        options={srcOption}
                                        value = {defaultModel}
                                        onChange = {this.changeModel}
                                        placeholder="Choose Model"
                                    />
                            : ''
                        }
                        {
                            this.props.showtimeFilter ?
                               <>
                                     {/* <div className="qd-cw-col"> */}
                                        <DateRangePicker containerClass="btn btn-datapicker reportrange mr-2" startDate={this.state.startDate}  onApply={this.handleTimeFilter} endDate={this.state.endDate} ranges={ranges}>
                                            <i>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </i>
                                            <span className="ml-2 d-none d-md-inline-block">
                                                {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                            </span>
                                        </DateRangePicker>
                                     {/* </div> */}
                                 </>
                                  : ''
                        }
                        {this.props.showFilter || this.props.showModelFilter || this.props.showHelpIcon ?
                            <div onClick={this.handleClick}>
                                <RcTooltip 
                                    placement="top" 
                                    overlay={renderTooltip}
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                >
                                 {/* <a className="help-icon"
                                    href={this.handleClick} data-toggle="modal"
                                    data-target="#model__help">
                                     <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                 </a> */}
                                 <span className="help-icon" data-toggle="modal" data-target="#model__help">
                                     <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                 </span>
                                </RcTooltip>
                            </div> : ''
                        }
                        {
                            this.state.showModal ?
                            <VideoContent
                                show={this.state.showModal}
                                onHide={this.hideModal}
                                video_url ={this.props.video_url}
                                name={this.props.helpIconTitle !== undefined ?  this.props.helpIconTitle : this.props.title}
                                modelName={this.props.name}
                            /> : ""
                        }
                    </div>
                </div>
                <div className={`portlet-body ${this.props.bodyClassName}`}>
                    {this.props.isConsistencyEnabled !== null && this.props.isConsistencyEnabled === false?
                        <EnableConsistency
                            message={"Please enable Consistency to view the chart."}/>
                            :
                        this.props.content

                    }
                </div>
            </div>
        );
    }
}

export default MonitorPortlet;