import React from 'react';
import Card from './card';
import { METRIC_CARD_COLORS } from '../utils/constant';
import { NON_EDITABLE_METRIC } from '../utils/constant';
class CardGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }


    renderCard() {
        let showToast = this.props.showToast;
        let handleCardFollow = this.props.handleCardFollow;
        let additionalDetails = this.props.additionalDetails;
        let colors = {0: [4,3,1,2,0], 1: [2,4,3,0,1], 2:[1,0,4,2,3], 3:[0,1,2,4,3], 4:[3,2,1,0,4]}
        if (this.props.data) {
            const showFollow = this.props.showFollow;
            const showConfigureConsistency=this.props.showConfigureConsistency;
            let rowNo = this.props.rowNo;
            let colorIndex = colors[rowNo % 4];
            let consistencyCount = this.props.data["consistency_count"];
            return this.props.data.map(function (row, index) {
                if (index % 5 === 0 && index > 4) {
                    colorIndex = colors[(rowNo+1) % 4];
                }
                let i = colorIndex[index % 5]
                let color = METRIC_CARD_COLORS[i];
                let cardBackground = color["cardBackground"];
                let textColor = color["textColor"];
                let chartLine = color["chartLine"];
                if (NON_EDITABLE_METRIC.includes(row.metric_name)){
                    row.hide_edit = true;
                }
                let configuredCount = false;
                if (consistencyCount !== undefined){
                   let metrics_key = row.metric_name.toLowerCase().replace(/ /g,'_');
                   configuredCount = consistencyCount[metrics_key];
                }

                return (
                    <Card
                        key={index}
                        name={row.metric_name}
                        showFollow={showFollow}
                        showConfigureConsistency={showConfigureConsistency}
                        value={row.metric_value}
                        configuredCount={configuredCount}
                        cardType={row.cardType}
                        id={row.metrics_id}
                        metricType={row.metric_type}
                        hide_edit={Boolean(row.hide_edit)}
                        chartData={row.chartData}
                        idValue={row.idValue}
                        ex_metric_details={row}
                        showToast={showToast}
                        handleCardFollow={handleCardFollow}
                        component_name="metrics"
                        additionalDetails={additionalDetails}
                        background={cardBackground}
                        textColor={textColor}
                        chartLine = {chartLine}
                    />
                );
                
            });
        }
    }

    render() {
        return (
            <div className="form-row">
                { this.renderCard() }
            </div>
        );
    }
}

export default CardGroup;