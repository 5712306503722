import React from 'react';
import Image from '../../components/image';
import Banner from './banner';
import {removeLocalStorageKeys} from "../../utils/common_utils";

class showUserStatus extends React.Component {

    render() {
            removeLocalStorageKeys();

            return (
                <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                             <div className="prelogin-content">
                                <div className="prelogin-thankyou">
                                    <i className="d-inline-flex p-2 mr-3">
                                       <Image src="email"/>
                                    </i>
                                    <h2>Contact ADMIN!</h2>
                                    <p className="text-muted">Your account is disabled by your admin. Contact you admin for further information</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>

            );
    }
}

export default showUserStatus;
