import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faTimesCircle, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {handleRequest} from "../utils/User_handling";


class ButtonActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disableApprove: false
        }

    }
    loadViewDetails() {
        if (this === undefined || this.props.view_details === undefined) {
            return;
        }

        const details = this.props.view_details;
        const divItems = [];
        Object.keys(details).forEach(function (key) {
            let value;
            if (typeof (details[key]) == 'object') {
                value = JSON.stringify(details[key]);
            }
            else {
                value = details[key];
            }
            divItems.push(
                <div key={key} className="form-group  col-md-6">
                    <label> {key} : </label>
                    <label> {value} </label>
                </div>
            );
        });
        return divItems;
    }

    render() {
        return (
            <>
           
                    <button onClick={this.props.approveShow} type="button"
                            className="btn btn-sm btn-outline-info mr-1 show-danger-toast">
                        <i><FontAwesomeIcon icon={faCheck}/></i>
                        Approve
                    </button>
                    <button onClick={this.props.rejectShow} type="button"
                            className="btn btn-sm btn-outline-danger mr-1 show-danger-toast">
                        <i><FontAwesomeIcon icon={faTimes}/></i>
                        Reject
                    </button>
               
                <Modal show={this.props.approveUser} centered onHide={this.props.approveClose}>
                    {/* <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {`Approve ${this.props.userDetails['username']}`}</h5>
                    </Modal.Header> */}
                    <Modal.Body>
                    <div className="modal__alert">
                        <div className="modal_alert-icon"><i className="text-success"><FontAwesomeIcon icon={faCheckCircle}/></i></div>
                            <div className="modal_alert-text">
                                <h4>Confirm Approve</h4>
                                <p><strong>{`${this.props.userDetails['requested_username']}`}</strong> ({`${this.props.userDetails["requested_user_email"]}`})</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.props.approveClose}
                                        className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                                </button>
                                <button type="button"
                                        onClick={handleRequest.bind(this, this.props.userDetails,
                                            this.props.component,
                                            this.props.approveClose,"approved")
                                            }
                                        disabled={this.state.disableApprove}
                                        className="btn btn-success btn-circle mr-2">Approve
                                </button>
                            </div>
                       </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.props.rejectUser} centered onHide={this.props.rejectClose}>
                    {/* <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {`Reject ${this.props.userDetails['username']}`}</h5>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon"><i className="text-danger"><FontAwesomeIcon icon={faTimesCircle}/></i></div>
                            <div className="modal_alert-text">
                                <h4>Are you sure you want to Reject?</h4>
                                <p><strong>{`${this.props.userDetails['requested_username']}`}</strong> ({`${this.props.userDetails["requested_user_email"]}`})</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.props.rejectClose}
                                        className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                                </button>
                                <button type="button"
                                        onClick={handleRequest.bind(this, this.props.userDetails,
                                            this.props.component,
                                            this.props.rejectClose, "rejected")}
                                        className="btn btn-primary btn-circle mr-2">Reject
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );

    }
}

export default ButtonActions;