import React from 'react';


class SimpleBasicPortlet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
      }

    render() {

        return( <>
                <div className={`portlet-body ${this.props.bodyClassName}`}>
                    {this.props.content}
                </div>
                </>
        );
    }
}

export default SimpleBasicPortlet;