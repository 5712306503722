import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "../image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import Load from "../loadAction";
import PaymentSectionHeader from "./paymentSectionHeader";


class PaymentDetails extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.getPaymentCardsAsRadioButtons = this.getPaymentCardsAsRadioButtons.bind(this);
    }

    getPaymentCardsAsRadioButtons() {
        return (
            this.props.availableCards.map((card, index) =>
                <div className={`custom-control custom-radio`}
                     key={card.id}>

                    <input type="radio"
                           id={`pay_option-type${index}`}
                           name="radio_sendDataAs"
                           defaultChecked={this.props.selectedPayment === null
                               ?
                               card.isPrimary :
                               this.props.selectedPayment === card.id
                           }
                           onClick={(e) => this.props.radioHandler(card.id)}
                           className="custom-control-input"/>

                    <label className="custom-control-label d-block"
                           htmlFor={`pay_option-type${index}`}>
                        <div className={`payment-savedCard ${(
                            (this.props.selectedPayment === null && card.isPrimary) || 
                            this.props.selectedPayment === card.id) 
                            ? 
                            'primary-payment' 
                            : 
                            ''
                        }`}>
                            <div className="row">
                                <div className="col payCard-details">
                                    <h4 className="card-title">
                                        Credit card:
                                    </h4>
                                    <div className="payCard-content">
                                        <i><FontAwesomeIcon icon={faCreditCard}/></i>
                                        <div className="payCard-info">
                                            <h5>{card.brand}</h5>
                                            <p>
                                                <span>
                                                    <small>....</small>{" "}
                                                    <small>....</small>{" "}
                                                    <small>....</small>{" "}
                                                    {card.lastFourDigits}
                                                    {" "}
                                                </span>
                                                <span>(Expires {" "} {card.expires})</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    card.isPrimary
                                        ?
                                        <div className="col payCard-action">
                                            <label className="text-success mr-3">Primary</label>
                                        </div>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </label>
                </div>)
        )
    }

    render() {
        if (this.props.availableCards === null) {
            return (<Load/>);
        }

        let hasNoCardDetails = this.props.availableCards.length === 0;
        if (hasNoCardDetails) {
            return (
                <>
                    <PaymentSectionHeader sectionIndex={3}
                                          sectionTitle={"Enter Payment Details"}
                    />

                    <div className="qd-payment-body">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Form>
                                            <Form.Row>
                                                <Col md={10}>
                                                    <Form.Group>
                                                        <div key={"pay_option1"}
                                                            className="custom-control custom-radio custom-control-inline mb-1 mb-sm-4">
                                                            <input type="radio"
                                                                   defaultChecked={true}
                                                                   id="pay_option1"
                                                                   name="radio_sendDataAs"
                                                                   className="custom-control-input"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="pay_option1">
                                                                <ul className="payment-cards">
                                                                    <li><Image src="creditCard_visa"/></li>
                                                                    <li><Image src="creditCard_master"/></li>
                                                                    <li><Image src="creditCard_amex"/></li>
                                                                    <li><Image src="creditCard_discover"/></li>
                                                                    <li><Image src="creditCard_jcb"/></li>
                                                                </ul>
                                                            </label>
                                                        </div>
                                                        <div
                                                            key={"pay_option2"}
                                                            className="custom-control custom-radio custom-control-inline mb-1 mb-sm-4">
                                                            <input type="radio"
                                                                   disabled={true}
                                                                   id="pay_option2"
                                                                   name="radio_sendDataAs"
                                                                   className="custom-control-input"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="pay_option2">
                                                                <Image className="payment-card-icon"
                                                                       src="creditCard_paypal"/>
                                                            </label>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={7}>
                                                    <Form.Group controlId="formCardName">
                                                        <Form.Label>Name on Card</Form.Label>
                                                        <Form.Control type="text" placeholder="Name"/>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={7}>
                                                    <Form.Group controlId="Card Number">
                                                        <Form.Label>Card Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="1111 2222 3333 4444"/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group controlId="Expiration Date">
                                                        <Form.Label>Expiration Date</Form.Label>
                                                        <Form.Control type="text" placeholder="MM/YYYY"/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group controlId="CVV Code">
                                                        <Form.Label>CVV Code</Form.Label>
                                                        <Form.Control type="text" placeholder="XXX"/>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Group controlId="formBasicCheckbox"
                                                        className="mt-2">
                                                <Form.Label>Would you like to make this card your primary payment
                                                    method?</Form.Label>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    id={"custom-checkbox2"}
                                                    className="text-muted"
                                                    label={"Yes, please make the this my primary payment method"}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </div>
                </>
            );
        }


        return (
            <>
                <PaymentSectionHeader sectionIndex={3}
                                      sectionTitle={"Enter Payment Details"}
                />

                <div className="qd-payment-body">
                    <Card>
                        <Card.Body>
                            <Form className="card-payment__control">
                                {this.getPaymentCardsAsRadioButtons()}
                                <button type="button"
                                        className="btn btn-outline-primary btn-circle"
                                        data-toggle="modal"
                                        onClick={this.props.toggleNewCardSection}
                                        data-target="#modal__payment-method"
                                >
                                    <i><FontAwesomeIcon icon={faCreditCard}/></i>
                                    Add New Card
                                </button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </>
        )
    }
}

export default PaymentDetails;
