import React from 'react';
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import { call_post_api }  from "../../utils/event_handling";
import {removeLocalStorageKeys} from  "../../utils/common_utils";
import { MANDATE_RESET_PASSWORD } from '../../utils/constant';



class ResetForm extends React.Component {
    constructor(props) {
        super(props);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.changeConfirmPasswordIcon = this.changeConfirmPasswordIcon.bind(this);
        this.state = {
            formValid: false,
            hideSubmitButton: false,
            errorCount: null,
            errors: '',
            passwordIcon: faEye,
            passwordType: "password",
            confirmPasswordIcon: faEye,
            confirmPasswordType: "password"
        };

    }


    resetPassword(event) {
            this.disableButton();
            const {password} = event;
            let token = localStorage.getItem('resetPwdToken');
            let reset_password = localStorage.getItem(MANDATE_RESET_PASSWORD.Reset_Password);
            let auth_token = localStorage.getItem('sessionToken')
            if (auth_token !== "" && auth_token !== null && auth_token !== undefined) {
                const item = JSON.parse(auth_token);
                auth_token = item.value;
            }
            let passwordData = {password:password};
            if (reset_password === MANDATE_RESET_PASSWORD.One){
                passwordData['authToken'] = auth_token;
            }
            call_post_api(iam_ip, 'reset-confirm?token='+token, passwordData).then((response) => {
                localStorage.removeItem("resetPwdToken");
                if (response.code === 200) {
                    if (reset_password === MANDATE_RESET_PASSWORD.One) {
                        removeLocalStorageKeys()
                    }
                    window.location = '/';
                } else {
                    this.setState({errors:response.message,hideSubmitButton: false});
                }
            });
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }

    }

    changeConfirmPasswordIcon() {
        let state = this.state.confirmPasswordIcon;
        if (state.iconName === "eye") {
          this.setState({confirmPasswordIcon: faEyeSlash});
          this.setState({confirmPasswordType: "text"});
        } else {
          this.setState({confirmPasswordIcon: faEye});
          this.setState({confirmPasswordType: "password"});
        }

    }

    hideAlert() {
        this.setState({
          errors: '',
        });
    }

    render() {
        const resetSchema = yup.object({
            password: yup.string().required("Password is a required field").matches(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&._-])[A-Za-z\d@$!%*#?&._-]{8,}$/,
                      "Use at least 8 characters containing at least 1 number, 1 lowercase letter, 1 special character in @$!%*#?&._-"),
            confirmPassword: yup.string().required("Confirm Password is a required field").oneOf([yup.ref('password'), null],'Passwords must match',)
        });
        return (
            <div className="prelogin-content">
                <h4 className="login-welcome-text">Set new password</h4>
                <p>Create a new password for your account. If you have a problem with resetting your password,
                    please send your queries to <a href = "mailto:helpdesk@qualdo.ai" >helpdesk@qualdo.ai</a></p>
                <div className="prelogin-form-container mt-5">
                    {this.state.errors !== '' ?
                           <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                              {this.state.errors}
                              <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"></button>
                           </div> : ''
                    }
                    <Formik
                        validationSchema={resetSchema}
                        onSubmit={this.resetPassword}
                        initialValues={{ password: '', confirmPassword: '' }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                    <Form onSubmit={handleSubmit} className="login-form">
                        <Form.Group controlId="password">
                            <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faLock}/>
                                </i>
                                <Form.Control
                                      className="form-control-solid placeholder-no-fix form-group"
                                      type={this.state.passwordType}
                                      autoComplete="off"
                                      placeholder="Password"
                                      name="password"
                                      value={values.password}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      isInvalid={errors.password && touched.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                </Form.Control.Feedback>
                                <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changePasswordIcon}  icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password<span className="text-danger">*</span></Form.Label>
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faLock}/>
                                </i>
                                <Form.Control
                                      className="form-control-solid placeholder-no-fix form-group"
                                      type={this.state.confirmPasswordType}
                                      autoComplete="off"
                                      placeholder="Confirm Password"
                                      name="confirmPassword"
                                      value={values.confirmPassword}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      isInvalid={errors.confirmPassword && touched.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                        { touched.confirmPassword && errors.confirmPassword }
                                </Form.Control.Feedback>
                                <i className="security-eye">
                                        <FontAwesomeIcon icon={this.state.confirmPasswordIcon} onClick={this.changeConfirmPasswordIcon} className="security-eye"/>
                                </i>
                            </InputGroup>
                        </Form.Group>
                        <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Reset Password</button>
                    </Form>
                     )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default ResetForm;