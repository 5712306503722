import React from 'react';
import Image from '../components/image';
import Banner from '../components/banner';
import ResetForm from './components/set_password';
import ErrorHandler from '../components/error_500';
import { postUIlogs } from "../utils/event_handling";


class setPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.postUIlogs = postUIlogs.bind(this);
         this.state = {
             errorOccurred: false
         }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    render() {
        return (
            // this.state.errorOccurred ?
            //     <ErrorHandler></ErrorHandler>
            // :
            <>
            {this.state.errorOccurred ? <ErrorHandler/> : ""}
            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                           <ResetForm />
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>
            </>
        );
    }
}

export default setPasswordForm;