import React from 'react';
import Select from 'react-select';
import {postConsistencyMetrics} from "../utils/event_handling";
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import {getDatasets} from "../utils/common_utils";
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';



class ConsistencyMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.postConsistencyMetrics = postConsistencyMetrics.bind(this);
        this.disableButton = this.disableButton.bind(this);
        let consistencyMetricOptions = this.getMetricOptions(this.props.consistencyMetricsList);
        let consistencyTypes = [];
        let selected_metric_id=null;
        if (this.props.selected_metric_name !== null && this.props.selected_metric_name !== undefined){
            let selectedType = consistencyMetricOptions.find(o => o.label === this.props.selected_metric_name);
            consistencyTypes = [selectedType]
            selected_metric_id = selectedType['value'];
        }
        this.state = {
            hideSubmitButton: false,
            customMetrics: {
                sourceDataset: null,
                targetDataSetId: null,
                consistencyTypes: consistencyTypes,
                consistencyMetric: selected_metric_id
            },
            details: {
                data_set: [],
                attributes: []
            },
            consistencyMetricOptions: consistencyMetricOptions,
            selectedDataSetOption: null,
            targetDataSetOption: null,
            consistencyTypes: consistencyTypes

        }
    }


    componentDidMount() {
        if (this.props.details === undefined || this.props.details === null) {
            return;
        }
        this.setState({details: this.props.details});
        if (this.props.exMetricDetails === undefined || this.props.exMetricDetails === null) {
           return;
        }
        let dataSetOptions = getDatasets(this.props.details.data_set);
        let customMetrics = this.state.customMetrics;
        let selectedTarget = this.props.exMetricDetails.target_data_set_id;
        let targetDataSetOption = null;
        let matchedTargets = dataSetOptions.filter(x => x["value"] === selectedTarget);
        if (matchedTargets.length > 0) {
            targetDataSetOption = matchedTargets[0];
            customMetrics.targetDataSetId = targetDataSetOption.value;
        }
        let selectedSource = this.props.exMetricDetails.data_set_id;
        let selectedDataSetOption = null;
        let matchedSource = dataSetOptions.filter(x => x["value"] === selectedSource);
        if (matchedSource.length > 0) {
            selectedDataSetOption = matchedSource[0];
            customMetrics.sourceDataset = selectedDataSetOption.value;
        }

//        let selectedInterval = this.props.exMetricDetails.schedule_interval;
//        let selectedIntervalOption = null;
//        let matchedInterval = this.state.scheduleOptions.filter(x => x["value"] === selectedInterval);
//        if (matchedInterval.length > 0) {
//            selectedIntervalOption = matchedInterval[0];
//            customMetrics.scheduleInterval = selectedIntervalOption.value;
//        }

        this.setState({selectedDataSetOption:selectedDataSetOption,
                       targetDataSetOption:targetDataSetOption,
                       consistencyTypes:[]});
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    requires_metrics_update(metricsData) {
             const exMetricDetails = this.props.exMetricDetails;
             if(exMetricDetails.target_data_set_id !== metricsData.target_data_set_id) {
                return true
             }
             if(exMetricDetails.data_set_id !== metricsData.data_set_id) {
                return true
             }
             if(exMetricDetails.schedule_interval !== metricsData.schedule_interval) {
                return true
             }
             return false
    }

    requiresTargetSelection() {
        let showTarget = false;

        if (this.props.exMetricDetails !== undefined) {
            showTarget = this.props.exMetricDetails.metrics_definition.requires_target;
        }
        return showTarget;
    }

    getMetricOptions(consistencyMetricsList){
        let option_arr = [];
        if (consistencyMetricsList !== undefined) {
            option_arr.push({"label":"All", "value":"all"});
            this.props.consistencyMetricsList.map(function (row) {
                option_arr.push({"label":row["metric_name"], "value":row["metrics_id"]});
                return 1;
            });
        }
        return option_arr;
    }
    render() {
        let metricsSchema = yup.object({
                consistencyMetric: yup.mixed().nullable(false).required("Dataset Consistency type is a required field"),
                sourceDataset: yup.mixed().nullable(false).required("Dataset to be monitored is a required field"),
                targetDataSetId: yup.mixed().nullable(false).required("Reference dataset is a required field"),
           });

        let dataSetOptions = getDatasets(this.state.details.data_set);


        let sourceKey = "emptySourceKey";
        if (this.state.selectedDataSetOption !== null) {
            sourceKey = "updatedSourceKey";
        }

        let targetSelectKey = "emptyTargetSelectKey";
        if (this.state.targetDataSetOption !== null) {
            targetSelectKey = "updatedTargetSelectKey";
        }

//        let consistencyMetricKey = "emptyConsistencyMetricKey";
//        if (this.state.consistencyTypes !== null) {
//            consistencyMetricKey = "updateConsistencyMetricKey";
//        }

        let disableSourceDatasetSelection = this.props.src_page === "edit_metrics";

        return (
                     <Formik
                          validationSchema={metricsSchema}
                          onSubmit={this.postConsistencyMetrics}
                          initialValues={this.state.customMetrics}
                     >
                     {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                     }) => (
                    <Form  name="consistencyMetrics"
                           className="needs-validation" noValidate method="POST"
                           onSubmit={handleSubmit}>
                                <Form.Group controlId="consistencyMetric" className="row">
                                    <Form.Label className="col-md-4 col-form-label">
                                        Data Consistency Type
                                        <span className="text-danger">*</span> :</Form.Label>
                                        <div className="col-md-5">
                                        <Select
                                                isMulti={true}
                                                filterOption={({ label }, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                isInvalid={errors.consistencyMetric && touched.consistencyMetric}
                                                classNamePrefix='select-control'
                                                isDisabled={disableSourceDatasetSelection}
                                                name="consistencyMetric"
                                                defaultValue={this.state.consistencyTypes}
                                                key={sourceKey}
                                                onChange={selectedOption => {
                                                if (selectedOption !==null && selectedOption.length > 0) {
                                                    handleChange("consistencyMetric")(selectedOption[0].value.toString());
                                                     this.setState({consistencyTypes:selectedOption});
                                                } else {
                                                    handleChange("consistencyMetric")("");
                                                }
                                              }}
                                                options={this.state.consistencyMetricOptions}
                                                placeholder="Select data consistency type"

                                        />
                                        <ErrorMessage component="div"
                                                      className="error-text"
                                                      name="consistencyMetric" />
                                        </div>
                                </Form.Group>
                                <Form.Group controlId="sourceDataset" className="row">
                                    <Form.Label className="col-md-4 col-form-label">Dataset to be monitored
                                        <span className="text-danger">*</span> :</Form.Label>
                                        <div className="col-md-5">
                                        <Select
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                isInvalid={errors.sourceDataset && touched.sourceDataset}
                                                classNamePrefix='select-control'
                                                isDisabled={disableSourceDatasetSelection}
                                                name="sourceDataset"
                                                defaultValue={this.state.selectedDataSetOption}
                                                key={sourceKey}
                                                onChange={selectedOption => {
                                                  handleChange("sourceDataset")(selectedOption.value.toString());
                                                  this.setState({selectedDataSetOption:selectedOption});
                                                }}
                                                options={dataSetOptions}
                                                placeholder="Select dataset"

                                        />
                                        <ErrorMessage component="div"
                                                      className="error-text"
                                                      name="sourceDataset" />
                                        </div>
                                </Form.Group>
                                <Form.Group controlId="targetDataSetId"
                                            className="row">
                                     <Form.Label className="col-md-4 col-form-label">
                                        Reference Dataset <span
                                        className="text-danger">*</span> :
                                    </Form.Label>
                                        <div className="col-md-5">
                                        <Select
                                                classNamePrefix='select-control'
                                                name="targetDataSetId"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                isInvalid={errors.targetDataSetId && touched.targetDataSetId}
                                                key={targetSelectKey}
                                                onChange={selectedOption => {
                                                  handleChange("targetDataSetId")(selectedOption.value.toString());
                                                  this.setState({targetDataSetOption:selectedOption});
                                                }}
                                                defaultValue={this.state.targetDataSetOption}
                                                options={dataSetOptions}
                                                placeholder="Select dataset"

                                        />
                                        <ErrorMessage component="div" className="error-text" name="targetDataSetId" />
                                        </div>
                                </Form.Group>
                          <Modal.Footer>
                            <button type="button"
                                    onClick={() => this.props.closeModal()}
                                    className="btn btn-outline btn-grey btn-circle mr-2">
                                Cancel
                            </button>
                            <button type="submit"
                                    disabled={this.state.hideSubmitButton}
                                    className="btn btn-primary btn-circle show-success-toast"
                                    data-dismiss="modal"
                                    >
                                <FontAwesomeIcon icon={faCheck}/> Apply
                            </button>
                        </Modal.Footer>
                    </Form>)}
                    </Formik>
        );

    }
}

export default ConsistencyMetrics;
