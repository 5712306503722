import Modal from "react-bootstrap/Modal";
import Image from "../image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";

class UpgradeComponent extends React.Component {

    render() {
        return (
              <Modal show={this.props.showModal}
                      className="modal__upgrade text-center"
                      onHide={this.props.closeModal}
                      centered={"true"}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                        <h5 className="modal-title" id={`upgrade_plan_${this.props.id}`}>
                        {this.props.title}</h5>
                        <p className="text-muted">{this.props.description}</p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                    <Image src="exceedModelLimit"/>
                </Modal.Body>
                  <Modal.Footer className=" border-0 justify-content-center"
                                centered={"true"}>
                      <button type="button"
                              onClick={this.props.setConfirm}
                              className="btn btn-primary btn-circle">
                          {this.props.confirmText}
                          <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight}/></button>
                  </Modal.Footer>
               </Modal>
        )
    }
}

export default UpgradeComponent;
