import React from 'react';
import Button from 'react-bootstrap/Button';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RcTooltip from 'rc-tooltip';


class MonitorButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleMore = this.handleMore.bind(this);
        this.state = {
            showMore: false,
        }
    }

    handleMore() {
       this.setState({showMore: true})
    }

    getFinalComponent(prevRefreshDate,latestRefreshDate,firstRefreshDate ) {
      let finalComponent = []
      if(prevRefreshDate !== undefined) {
         finalComponent.push(<>{`Previous Refresh Date: ${prevRefreshDate}`}<br/></>)
      }
      if(latestRefreshDate !== undefined) {
         finalComponent.push(<>{`Latest Refresh Date: ${latestRefreshDate}`}<br/></>)
      }
      if(firstRefreshDate !== undefined) {
         finalComponent.push(<>{`First Refresh Date: ${firstRefreshDate}`}<br/></>)
      }
      return finalComponent

    }


    render() {
          const children = []
          let cellValue = this.props.cell;
          let length = (cellValue.length > 5 && this.state.showMore === false ? 5 : cellValue.length)
          let datasetName;
          let prevRefreshDate;
          let latestRefreshDate;
          let firstRefreshDate;
          let finalComponent;
          for (let i = 0; i < length; i++) {
                 const cellValueElement = cellValue[i];
                 if(this.props.columnName === "dqIssues") {
                     datasetName = cellValueElement["data_set_name"];
                     prevRefreshDate = cellValueElement["prevRefreshDate"]
                     latestRefreshDate = cellValueElement["latestRefreshDate"]
                     firstRefreshDate = cellValueElement["firstRefreshDate"]
                     finalComponent = this.getFinalComponent(prevRefreshDate,latestRefreshDate,firstRefreshDate)

                 }
                 const textContent = (this.props.columnName === "servingFeatureDrifts") ?
                     cellValueElement
                     :
                     (cellValueElement["latestError"] === "not refreshed" ? datasetName : (cellValueElement["latestError"] === undefined ? "" : datasetName + " - " + cellValueElement["latestError"]));

                 children.push(
                     <div key={"div_key" + i}>
                         <Button key={i}
                                 variant="link"
                                 onClick={this.props.columnName === "servingFeatureDrifts" ?
                                     this.props.ShowAttributeModal.bind(this, this.props) :
                                     this.props.ShowDatasetModal.bind(this, cellValueElement, this.props.datasourceName)}
                                 className="px-0 table-monitor-label-mw">
                             {textContent}

                             {this.props.columnName === "servingFeatureDrifts" || cellValueElement["latestError"] !== "not refreshed" ? "" : (finalComponent.length===0 ? "" : <><RcTooltip placement="right"
                                         tooltipInfo={"Refresh Date"}
                                         overlay={
                                            <span id={`tooltipId_${i}`}>
                                                 {finalComponent}
                                             </span>
                                         }
                                         arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                        >
                             <i className={"d-inline-block px-1"}>
                                 <FontAwesomeIcon icon={faInfoCircle}/>
                             </i>
                         </RcTooltip>
                         <small className="d-block text-muted">{cellValueElement["recencyInDays"] !== undefined && cellValueElement["recencyInDays"] !== 0 ? (cellValueElement["recencyInDays"] === 1 ? `${cellValueElement["recencyInDays"]} Day since last refresh` : `${cellValueElement["recencyInDays"]} Days since last refresh`): ""}</small></>
                         )}
                         
                         </Button>
                         
                     </div>
             );

        }
        if (cellValue.length > 5 && this.state.showMore === false) {
                children.push(
                <Button key="more" 
                        onClick={this.handleMore.bind(this)}
                        className="px-0"
                        variant="link">+{cellValue.length - 5} More
                </Button>)
        }
        return (
            <>
                {children}
            </>
        );

    }
}

export default MonitorButton;
