import React from 'react';
import { faChartArea} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class EmptyAPIResponse extends React.Component {

    getAttributeResponse(content){
        return (
            <div className="text-center text-muted py-5 w-100">
            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
            <h4>{content}</h4>
          </div>
        );
    }

    render(){
       let page = this.props.page;
       let content = this.props.content;
       if (page === "attribute"){
            return this.getAttributeResponse(content);
       }
    }
}

export default EmptyAPIResponse;



