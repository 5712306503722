import React from 'react';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ChartGrid from './chartGrid';
import Image from '../../components/image';
import _ from 'lodash';
import { connect } from "react-redux";
import {setModelPerformanceData} from "../../utils/event_handling";
import {LIGHT_COLOR_MAP, ML_MODEL_WITH_MODEL_EVALUATION_METRICS, MODEL_EVALUATION} from "../../utils/constant";
import AdvancedFilterModel from "./advanced_filter_model";

import {
    getGroupedDataListView,
    formChartTitleForModelPerformanceChart,
    getChartType,
    convertDecimalDigits,
    capitalize
} from "../../utils/common_utils";

import MonitorChartGroup from "./monitorChartGroup";
import NoErrorInPreview from "./noErrorPreview";
import {addModelPerformance} from "../../redux/actions";

class ModelPerformanceGrid extends React.Component {
    constructor(props) {
        super(props);
        this.changeModel = this.changeModel.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.closeFullScreen = this.closeFullScreen.bind(this);
        this.setMlModel = setModelPerformanceData.bind(this);
        this.getGroupedDataListView = getGroupedDataListView.bind(this);
        let completeData = this.props.completeFullscreenData;

        let initialSelectedMlModel;
        if(this.props.mlModelOptions !== undefined){
            initialSelectedMlModel = this.props.mlModelOptions.filter(x=>x.value===this.props.currentModelID)
        } else {
            initialSelectedMlModel = [{"label": this.props.currentModel, "value": this.props.currentModelID}];
        }
        let full_screen_data = this.getGroupedDataListView([], completeData, initialSelectedMlModel)
        this.state = {
            loadValue: true,
            idValue: this.props.currentModel,
            data: this.props.data,
            selectedMetrics: [],
            title: this.props.title,
            chartType: "areaChart",
            fullScreen: "fade",
            showFullScreen: false,
            updatedKey: false,
            dataLoaded: false,
            variant: this.props.variant,
            modalData: _.cloneDeep(this.props.data),
            selectedMlModel: initialSelectedMlModel,
            fullScreenData: full_screen_data,
            completeData: completeData,
            startDateInside: null,
            endDateInside: null,
            isAdvancedFilterApplied: false,
            initialSelection: full_screen_data
        }
    }

    static getDerivedStateFromProps(props, state) {
        let propsChanged = props.data !== state.data;
        let gotUpdatedData = props.monitorModule.modelPerformanceFetchCompleted && !state.dataLoaded;

        if (propsChanged && gotUpdatedData) {
            let completeData = this.props.completeFullscreenData;

            let initialSelectedMlModel;
            if(props.mlModelOptions !== undefined){
                initialSelectedMlModel = props.mlModelOptions.filter(x=>x.value===props.currentModelID)
            } else {
                initialSelectedMlModel = [{"label": props.currentModel, "value": props.currentModelID}];
            }
            let groupedData = getGroupedDataListView([],completeData, initialSelectedMlModel);
            return {
                data: props.data,
                fullScreenData: _.cloneDeep(groupedData),
                completeData: completeData,
                updatedKey: state.updatedKey,
                dataLoaded: true
            };
        } else if (propsChanged) {
            return {data: props.data};
        } else if (gotUpdatedData) {
            let completeData = props.completeFullscreenData;

            const initialSelectedMlModel = [{"label": props.currentModel, "value": props.currentModelID}];

            let groupedData1 = getGroupedDataListView([], completeData, initialSelectedMlModel);
            return {
                fullScreenData: _.cloneDeep(groupedData1),
                completeData: completeData,
                updatedKey: state.updatedKey,
                dataLoaded: true
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            let _modalData = _.cloneDeep(this.props.data);
            this.setState({modalData: _modalData});
        }
    }

    closeFullScreen() {
        document.body.classList.remove("overflow-hidden");
        this.setState({fullScreen: '', showFullScreen: false,  selectedMetrics: [], startDateInside: null, endDateInside: null, isAdvancedFilterApplied: false, fullScreenData: this.state.initialSelection});
    }

    setFullScreen() {
        document.body.classList.add("overflow-hidden");
        this.setState({showFullScreen: true, fullScreen: "show"})
        if (this.props.showModelFilter) {
            if (this.props.mlModelOptions !== undefined && this.props.mlModelOptions !== null) {
                if (this.props.mlModelOptions.length > 0) {

                    if (this.state.fullScreenData === null){
                        this.setState({loadValue: true});
                    }

                }
            }
        }
    }

    renderGridItem() {
        if (this.props.data) {
            let chartValues = this.state.modalData["data"];
            if (chartValues.length > 3) {
                chartValues = this.state.modalData["data"].slice(0, 3);
            }

            return chartValues.map((chartData, index) => {
                let key = chartData["key"];
                let chartType = chartData["chartType"];
                if (chartType === undefined) {
                    chartType = this.state.chartType;
                }
                chartData["name"] = capitalize(chartData["name"]);
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                if(chartData["drift1"] !== undefined){
                   chartData["drift1"] = convertDecimalDigits(chartData["drift1"])
                }

                let isTimeSeries = (chartData.isNonTimeSeries === undefined || !chartData.isNonTimeSeries);
                if (chartType === 'areaChart' && isTimeSeries){
                    chartType = getChartType(chartData, index)
                }

                let title = chartData["chartTitle"];
                if (this.props.selectedMlModel !== undefined) {
                    title = formChartTitleForModelPerformanceChart(title, this.props.selectedMlModel);
                }
                let idValue = ("modelPerformancePreviewChart" + chartData["model_name"] + "_" + chartData["metric"]).replace(/ /g,"_");

                return (
                    <ChartGrid
                        y2Value={chartData["y2Value"]}
                        y1Value={chartData["y1Value"]}
                        yValue={chartData["yValue"]}
                        xValue={chartData["xValue"]}
                        isError={chartData["isError"]}
                        toolTipTitle={chartData["toolTipTitle"]}
                        label={chartData["label"]}
                        colorCode= {chartData["color"]}
                        lineColorCode = {LIGHT_COLOR_MAP[chartData["color"]]}
                        scale={chartData["scale"]}
                        compareChart={chartData["multipleLines"]}
                        title={title}
                        idValue={idValue}
                        selectedIntegration={this.props.selectedMlModel}
                        key={`${key}_${this.props.updatedKey}`}
                        data={chartData}
                        chartType={chartType}
                        variant={this.state.variant}
                        hideChartHeaderLabel={true}
                    />
                );
            });
        }
    }

    formGroupedChart() {
        let selectedMlModel = this.state.selectedMlModel;
        let ml_model_id = 0;
        if (selectedMlModel !== undefined && selectedMlModel !== null ) {
            ml_model_id = selectedMlModel.value;
        }

        const groupedData = this.state.fullScreenData;
        let rowCount = 0
        let ModelEvaluationMetrics=selectedMlModel.filter(x=>(x.mlType !== undefined && ML_MODEL_WITH_MODEL_EVALUATION_METRICS.includes(x.mlType)))
        let hasEvaluationMetrics = false;
        if (ModelEvaluationMetrics.length > 0){
            hasEvaluationMetrics=true;
        }
        return Object.keys(groupedData).map((key, index) => {
            let loadValue = !this.props.monitorModule.modelPerformanceFetchCompleted;
            let sectionType = groupedData[key]["type"]
            if(key === MODEL_EVALUATION && hasEvaluationMetrics === false){
                return ""
            }
            rowCount = rowCount + 1
            if(this.state.selectedMetrics.length > 0){
                let sectionFiltered = this.state.selectedMetrics.filter(x=>x.type === sectionType)
                if(sectionFiltered.length === 0){
                   rowCount = rowCount - 1
                   return ""
                }
            }
            return (
                (<MonitorChartGroup key={`${this.state.updatedKey}_${ml_model_id}_${key}`}
                                    groupData={groupedData[key]}
                                    mapModel={this.props.monitorModule.mlModelMapping}
                                    index={rowCount}
                                    startDate={this.props.startDate}
                                    startDateInside={this.state.startDateInside}
                                    endDate={this.props.endDate}
                                    isAdvancedFilterApplied={this.state.isAdvancedFilterApplied}
                                    endDateInside={this.state.endDateInside}
                                    loadValue={loadValue}
                                    considerEndDate={false}
                                    timeFilterApplied={true}
                                    chartType={this.state.chartType}
                                    selectedMlModel={selectedMlModel}
                                    variant={this.state.variant}
                                    chartTimeFilter={this.chartTimeFilter}
                >

                </MonitorChartGroup>));
        });
    }

    changeModel(selectedModel, selectedMetrics=[], startDate, endDate) {
        this.setState({loadValue: true});
        this.setState({selectedMlModel: selectedModel, idValue: selectedModel.label});
        const updatedData = this.getGroupedDataListView(selectedMetrics, this.state.completeData, selectedModel);
        this.setState({fullScreenData: updatedData});
        this.setState({updatedKey: !this.state.updatedKey});
        this.setState({selectedMetrics: selectedMetrics, loadValue: false, startDateInside: startDate, endDateInside: endDate, isAdvancedFilterApplied: !this.state.isAdvancedFilterApplied});
        // this.setMlModel(event, this.props.id, this.props.title);
    }

    render() {
        let message = "Model performance values are currently not available for the selected model.";
        let footer = "Try with a different time period.";
        if (this.props.isInProgress !== undefined && this.props.isInProgress === true) {
            message = 'Model Profiling is in-progress';
            footer = '';
        }
        if(this.state.data["data"].length === 0){
            message = "Model performance values are currently not available for the selected model.";
            footer = "Try with a different time period.";
        }

        let modelMonitoringData = this.getGroupedDataListView([], this.props.completeFullscreenData, this.state.selectedMlModel)
        modelMonitoringData = modelMonitoringData["Model Monitoring"]["data"];

        if (this.props.noModelConfigured !== undefined && this.props.noModelConfigured === true) {
            message = 'Model not configured for the selected period.';
            footer = 'Try with a different time period.';
        }
        return (
                (this.state.data["data"].length === 0) ?
                    <div className="qd-chart-group-body">
                        <NoErrorInPreview
                            message={message} footer={footer}/>
                    </div>
                :
                <>
                    <div className="qd-grid qd-grid-monitor">
                        <div className="qd-grid-item qd-section" key={`div_${this.props.updatedKey}`}>
                            <div className="row row-sm">
                                {this.renderGridItem()}
                            </div>
                        </div>
                        <div className="qd-grid-item qd-aside">
                            <div className="d-flex align-items-center w-100 justify-content-center"
                                style={{height: "200px"}}>
                                <div className="show-more monitor-row_show-more"
                                    onClick={this.setFullScreen}>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faArrowRight}/>
                                    </span>
                                    <p>Show More</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    { this.state.fullScreen === 'show' ? <div className={"qd-fullscreen fade " + this.state.fullScreen}>
                        <div className="qd-fs_dialog">
                            <div className="qd-fs_content">
                                <div className="qd-fs_header">
                                    <div className="header_content">
                                        <Image
                                            className="qd-header__brand-logo-default"
                                            src="logo"
                                        />
                                        <h4 className="header_title">{this.props.title}</h4>
                                    </div>
                                    <div className="actions">
                                        <AdvancedFilterModel
                                             mlModelOptions={this.props.mlModelOptions}
                                             selectedMlModel={this.state.selectedMlModel}
                                             startDate={this.props.startDate}
                                             endDate={this.props.endDate}
                                             modelMonitoringData={modelMonitoringData}
                                             type="modelPerformance"
                                             changeModelInFullScreen={this.changeModel}/>
                                    </div>
                                    <button type="button" onClick={this.closeFullScreen}
                                            className="close" aria-label="Close">
                                        <span aria-hidden="true"/>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="qd-fs_body overflow-auto pt-0">
                                    <div className="qd-grid qd-grid-monitor">
                                        <div className="qd-grid-item qd-section"
                                             key={this.state.updatedKey}>
                                            {this.formGroupedChart()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""}
                 </>
            );
       }
    }

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, addModelPerformance)  (ModelPerformanceGrid);