import React from 'react';
import {PRIVACY_POLICY_LINK, COOKIE_POLICY_LINK, TERMS_CONDITIONS_LINK} from "../utils/constant";

const d = new Date();
let year = d.getFullYear();

class CopyRights extends React.Component {
    render() {
        return (
            <div className={this.props.classDetails}>
                {
                    (this.props.text !== null && this.props.text !== undefined) ?
                        this.props.text : ''
                }
                <a href={TERMS_CONDITIONS_LINK} rel="noopener noreferrer" target="_blank">Terms & Conditions</a> -
                <a href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank"> Privacy Policy</a> -
                <a href={COOKIE_POLICY_LINK} rel="noopener noreferrer" target="_blank"> Cookie Policy</a>
                <br/>
                {
                    this.props.showCopyRight ?
                        'Copyright Saturam Inc.'+year: ''
                }
            </div>
        );
    }
}

export default CopyRights;


export function CopyRightsFooter() {
        return (
            <footer className="page-footer text-center">
                <div className="container-fluid">
                    <div className="text-copyright">
                        <a href={TERMS_CONDITIONS_LINK} rel="noopener noreferrer" target="_blank">Terms & Conditions</a> -
                        <a href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank"> Privacy Policy</a> -
                        <a href={COOKIE_POLICY_LINK} rel="noopener noreferrer" target="_blank"> Cookie Policy</a>
                        <br /> Copyright Saturam Inc.{year}
                    </div>
                </div>
            </footer>
        );
}

