import React from "react";


class ABModelName extends React.Component {

    render() {
        const firstComponent = this.props.nameComponents[0];
        const secondComponent = this.props.nameComponents[1];
        const thirdComponent = this.props.nameComponents[2];

        return (
            <>

                <div className="abtested_div abtested_start">
                <span className="abtest_parent">
                    {firstComponent.text}
                </span> {firstComponent.name}
                </div>

                <div className="abtested_div abtested_end">
                    <span className="abtest_child">
                        {secondComponent.text}
                    </span> {secondComponent.name}
                </div>

                {thirdComponent === undefined ?
                    '' :
                    <div className="abtested_div abtested_end">
                        <span className="abtest_nextchild">
                            {thirdComponent.text}
                        </span> {thirdComponent.name}
                    </div>
                }

            </>)
    }
}


export default ABModelName;
