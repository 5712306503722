import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { handleEditTeamMate, getApi} from "../utils/User_handling";
import { handleDeleteTeamMate } from "../utils/event_handling"
import Select from "react-select";


class TeamEditAction extends React.Component {

    constructor(props) {
        super(props);
        this.disableUser = this.disableUser.bind(this);
        this.getApi = getApi.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.fill_details= this.fill_details.bind(this);
        this.state = {
            roles: [],
            groups: [],
            disableUser: "",
            department: "",
            organisation: "",
            role: {"label": "", "value":""}
        }
         this.fill_details();
    }


    fill_details() {
         if (this.props.userDetails !== undefined && this.state.disableUser === '') {
            let userDetails = this.props.userDetails;
            //eslint-disable-next-line
            this.state.role = {"label": userDetails["role_name"] === "DataScientist" ? "Data Scientist" : userDetails["role_name"], "value": userDetails["role_id"]}
            //eslint-disable-next-line
            this.state.organisation =userDetails["organisation_name"];
            //eslint-disable-next-line
            this.state.department =userDetails["department_name"];
        }
    }

    disableUser(e){
        let isChecked = e.target.checked;
        this.setState({disableUser: isChecked})
    }

    handleRoleChange(event, obj){
        this.setState({role: event});
    }

    getRoles() {
        let option_arr = [];
        if (this.props.ugmDetails != null) {
            this.props.ugmDetails["roles"].map(function (row) {
                option_arr.push({"label":row["role_name"] === "DataScientist" ? "Data Scientist" : row["role_name"], "value":row["role_id"]});
                return 1;
            });
        }
        return option_arr;
    }

    handleChange(event){
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    render() {
        let ModelTitle = "Manage Teammate";
        let buttonStatus = false;
        if (this.props.userDetails !== undefined  && this.props.userDetails !== null) {
            ModelTitle = "Edit Role - " + this.props.userDetails["username"]+" ("+this.props.userDetails['email']+")";
        }
        let roles = this.getRoles()

        if (this.props.userDetails !== undefined && this.state.disableUser === '') {
            buttonStatus = this.props.userDetails["current_status"] === 'disabled' ? true: false;
        } else {
            buttonStatus = this.state.disableUser;
        }

        let DisableEditDelete = false;
        let user_signup_type = localStorage.getItem("user_signup_type");
        if (user_signup_type === "join_team") {
            DisableEditDelete = true;
        }
        return (
            <>
                    <button onClick={this.props.handleShow} disabled={DisableEditDelete} type="button"
                            className="btn btn-sm btn-outline-warning mr-1">
                        <i><FontAwesomeIcon icon={faEdit}/> </i>
                        <span>Edit</span>
                    </button>
                    <button onClick={this.props.deleteShow} disabled={DisableEditDelete} type="button"
                            className="btn btn-sm btn-outline-danger show-danger-toast mr-1">
                        <i><FontAwesomeIcon icon={faTrash}/></i>
                        <span>Delete</span>
                    </button>
                <Modal show={this.props.delete_show} centered onHide={this.props.deleteClose}>
                    <Modal.Header closeButton className="d-none">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {`Delete ${this.props.component}`}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal__alert">
                        <div className="modal_alert-icon"><i className="text-danger"><FontAwesomeIcon icon={faTrashAlt}/></i></div>
                            <div className="modal_alert-text">
                                <h4>You are about to delete a Teammate</h4>
                                <p>{`This will delete your Teammate`}</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.props.deleteClose}
                                        className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                                </button>
                                <button type="button"
                                        onClick={handleDeleteTeamMate.bind(this, this.props.userDetails,
                                            this.state,
                                            this.props.deleteClose)}
                                        className="btn btn-primary btn-circle mr-2">Delete
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.props.show}  centered onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            { ModelTitle }
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                       <div className="form-group">
                            <label>Status</label>
                            <div>
                              <button className="btn tbl-status_btn-sm btn-success" disabled="">Active</button>
                              <span className="switch switch-sm switch-dark-75">
                                <label className="m-0" style={{ display: "none" }}>
                                  Disable user
                                  <input type="checkbox" name="disable_user" checked={buttonStatus} onChange={e => this.disableUser(e)}/>
                                  <span className="m-0 mx-2"></span>
                                </label>
                              </span>
                            </div>
                       </div>
                       <div className="form-group">
                            <label>Roles </label>
                            <Select
                                name="role"
                                filterOption={({label}, query) => label.includes(query)}
                                options={roles}
                                value={this.state.role}
                                onChange={this.handleRoleChange}
                                placeholder="Select Role"
                                classNamePrefix='select-control'
                            />
                       </div>
                       <div className="row row-sm">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Organization</label>
                                <input type="text" name="organisation" value={this.state.organisation} onChange={e => this.handleChange(e)} className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Department Name</label>
                                <input type="text" name="department" value={this.state.department} onChange={e => this.handleChange(e)}  className="form-control" />
                              </div>
                            </div>
                       </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline btn-grey btn-circle" onClick={this.props.handleClose}>Cancel</button>
                        <button type="button" className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal"
                                            onClick={handleEditTeamMate.bind(this, this.props.userDetails, this.state,
                                                       this.props.handleClose, this.getApi)}>
                                            <i className="fas fa-check"></i> Save Changes</button>
                    </Modal.Footer>
                </Modal>
            </>
        );

    }
}

export default TeamEditAction;