import React, { Component } from 'react';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import './ganttchart.css';

const d3 = {
  ...d3Module,
  tip: d3Tip
};



class GanttChart extends Component {
  
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
        this.drawChart();
    }

  componentDidUpdate(prevProps) {

    if (this.props.data !== prevProps.data) {
      this.drawChart();
    }

  }

  drawChart() {

    d3.select("#gantt_chart svg").remove();

    let data = this.props.data;
    
    const margin = { top: 50, right: 20, bottom: 20, left: 20 };
    const width = 960 - margin.left - margin.right; // Reduced width to fit better
    const height = 500 - margin.top - margin.bottom;

    const svg = d3.select(this.chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Find min and max dates
    let minDate = d3.min(data, d => d.start);
    let maxDate = d3.max(data, d => d.end);
    
    // Format dates as needed, for example, as "YYYY-MM-DD"
    const dateFormat = d3.timeFormat("%A, %d %B %Y");
    const formattedMaxDate = dateFormat(maxDate);
        
    // Add 10 minutes before and after
    minDate = new Date(minDate.getTime() - 50 * 60 * 1000);
    maxDate = new Date(maxDate.getTime() + 50 * 60 * 1000);
    
    // Add the heading "Task Tracker"
    svg.append("text")
    .attr("x", width / 2)
    .attr("y", -30)
    .attr("text-anchor", "middle")
    .attr("font-size", "16px")
    .attr("font-weight", "bold")
    .text(`${formattedMaxDate}`);
    //   .text(minDate+" - "+maxDate);

    let tip = d3.tip()
    .attr('class', 'd3-tip')
    .direction('e')
    .offset([0, 5])
    .html((d) => this.getToolTipContent(d));

    svg.call(tip);

    const x = d3.scaleTime()
      .domain([minDate, maxDate])
      .range([0, width]);

    const y = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([0, height])
      .padding(0.1);


    // X-axis at the top
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, 0)`)
      .attr("stroke", `lightgrey`)
      .call(d3.axisTop(x).tickFormat(d3.timeFormat("%H:%M")));

    // Add grid lines
    svg.append("g")
      .attr("class", "x grid")
      .attr("transform", `translate(0, 0)`)
      .call(d3.axisTop(x)
        .ticks(d3.timeHour.every(1))
        .tickSize(-height)
        .tickFormat(""))
      .selectAll(".tick line")
      .attr("stroke", "lightgrey")
      .attr("stroke-opacity", 0.7);

    svg.append("g")
      .attr("class", "y grid")
      .attr("transform", `translate(0, 0)`)
      .attr("stroke", `lightgrey`)
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat(""))
      .selectAll(".tick line")
      .attr("stroke", "lightgrey")
      .attr("stroke-opacity", 0.7);
    svg.selectAll("path")
        .attr("stroke", "lightgrey");

    // Add bars
    const barHeight = y.bandwidth() * 0.3;
    svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", d => x(d.start))
      .attr("y", d => y(d.name) + (y.bandwidth() - barHeight) / 2)
      .attr("width", d => x(d.end) - x(d.start) < 10 ? 10 : x(d.end) - x(d.start))
      .attr("height", barHeight)
      .attr("fill", d => {
        switch (d.status) {
          case 'Failed':
            return '#F64E60';
          case 'Succeeded':
            return '#4ED2CC';
          default:
            return '#795548';
        }
      })
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);


    // Add task names
    svg.selectAll(".task-name")
      .data(data)
      .enter().append("text")
      .attr("class", "task-name")
      .attr("x", d => x(d.end) + 10)
      .attr("y", d => y(d.name) + y.bandwidth() / 2 + 4)
      .text(d => d.name);

    // Add arrow marker definition
    svg.append("defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z");

    // Draw links for task dependencies
    const linkGroup = svg.append("g").attr("class", "links");
    data.forEach((d) => {
      d.dependencies.forEach((dep) => {
        const dependentTask = data.find(task => task.name === dep);
        if (dependentTask) {
          linkGroup.append("path")
            .attr("d", () => {
              const startX = x(d.start);
              const startY = y(d.name) + y.bandwidth() / 2;
              const endX = x(dependentTask.start);
              const endY = y(dependentTask.name) + y.bandwidth() / 2;

              const horizontalOffset = 20;
              let path = `M${startX},${startY}`;

              if (startY !== endY) {
                const midX = startX - horizontalOffset;
                path += ` H${midX} V${endY} H${endX}`;
              } else {
                path += ` H${endX}`;
              }

              return path;
            })
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("marker-end", "url(#arrow)");
        }
      });
    });
  }
getToolTipContent(d) {
    return `
      <div class="popover pipeline-popover">
        <h3 class="popover-header">${d.name}</h3>
        <div class="popover-body">
          <div class="job-details">
            <p><span><label>Started:</label> ${d.start.toString().replace("GMT+0530 (India Standard Time)", "")}</span></p>
            <p><span><label>End:</label> ${d.end.toString().replace("GMT+0530 (India Standard Time)", "")}</span></p>
            <p>
              <span class="d-flex align-items-center">
                <label>Status</label>
                ${d.status === 'Failed' ? 
                  '<span class="badge badge-light-danger mr-2"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>Failed</span>' :
                  d.status === 'Succeeded' ? 
                  '<span class="badge badge-light-success mr-2"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>Success</span>' :
                  `<span class="badge badge-light-brown bdr-light-brown"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="currentColor"><path d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm160 320h160q17 0 28.5-11.5T680-320q0-17-11.5-28.5T640-360H480q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160h160q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H480q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160h160q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H480q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"/></svg>${d.status}</span>`
                }
              </span>
            </p>
          </div>
        </div>
      </div>`;
  }

  render() {
    return <div id="gantt_chart" className="chart-container" ref={this.chartRef}></div>;
  }
}
export default GanttChart;