import {
    ADD_METADATA,
    ADD_RELATIONSHIP,
    ADD_INFO,
    SET_DATA_SOURCE,
    SET_ML_DATA_SOURCE,
    ADD_DATA_SOURCE,
    ADD_LAST_PROFILING_TIME,
    ADD_DISCOVERY_STATUS,
    ADD_PROCESSING_STATUS,
    ADD_ENVIRONMENT,
    SET_ENVIRONMENT,
    SET_DATA_SET,
    SET_CHIP_DATA,
    SET_ML_CHIP_DATA,
    SET_ATTRIBUTE,
    SET_FILTERED_DATA,
    ADD_ENVIRONMENT_DATA_SOURCE_MAPPING,
    ADD_DATA_SOURCE_DATA_SET_MAPPING,
    ADD_DATA_SET_ATTRIBUTE_MAPPING,
    SET_TENANT_ID,
    SET_INITIAL_VALUES,
    ADD_MONITOR_PREVIEW,
    ADD_DQ_MONITOR_PREVIEW,
    ADD_PERFORMANCE_PREVIEW,
    ADD_COMPLETENESS_INFO,
    SET_MONITOR_INITIAL_VALUES,
    ADD_COMPLETENESS_ATTRIBUTE_INFO,
    ADD_TIMELINESS_INFO,
    SET_END_DATE,
    SET_START_DATE,
    SET_TIME_FILTER,
    ADD_TIMELINESS_ATTRIBUTE_INFO,
    ADD_ACCURACY_INFO,
    ADD_ACCURACY_ATTRIBUTE_INFO,
    ADD_DRIFT_INFO,
    ADD_DRIFT_ATTRIBUTE_INFO,
    ADD_CONFORMITY_INFO,
    ADD_CONFORMITY_ATTRIBUTE_INFO,
    ADD_CONSISTENCY_INFO,
    ADD_CONSISTENCY_ATTRIBUTE_INFO,
    ADD_ML_MODEL_MAPPING,
    ADD_ML_MODEL_INFO,
    ADD_ML_MODEL_ATTRIBUTE_INFO,
    ADD_ML_RELATIONSHIP,
    SET_ML_MODEL,
    ADD_ML_MODEL_PERFORMANCE,
    ADD_ML_MODEL_DETAILS,
    ADD_MONITOR_DQ_LIST_VIEW,
    ADD_METRICS,
    USER_NOTIFICATIONS, MARK_NOTIFICATION_AS_READ,
    REMOVE_NOTIFICATION_FROM_LIST,
    ADD_DATASET_VERSIONS,
    ADD_UNIQUENESS_INFO,
    ADD_UNIQUENESS_ATTRIBUTE_INFO,
    ADD_RECENCY_DETAILS,
    ADD_RECENT_HISTORY,
    ADD_FOLLOW_ATTRIBUTES,
    ADD_DATA_SIZE, SET_MODEL_AB_LIST,
    ADD_RECENCY_PREVIEW,
    PREFETCH_MONITOR_STATE,
    STORE_IP_DATA,
    ADD_METADATA_DETAILS,
    NO_INTEGRATION,
    FORM_DATASET_INFO,
    FORM_LISTVIEW_TABLE_DATA,
    ADD_DATASET_LAST_REFRESH,
    ADD_DATASOURCE_DISCOVER_PAGE
} from "./actionTypes";



export const addMetaData = content => ({
    type: ADD_METADATA,
    payload: {
        content
    }
});

export const addRelationship = (dataSource, data) => ({
    type: ADD_RELATIONSHIP,
    payload: {
        dataSource: dataSource,
        data: data
    }
});

export const setDataSource = dataSource => ({
    type: SET_DATA_SOURCE,
    payload: { dataSource }
});

export const setMlDataSource = mlDataSource => ({
    type: SET_ML_DATA_SOURCE,
    payload: { mlDataSource }
});

export const addMLModel = (mlModel, data) => ({
    type: ADD_ML_RELATIONSHIP,
    payload: {
        mlModel: mlModel,
        data: data
    }
});

export const setMLModel = mlModel => ({
    type: SET_ML_MODEL,
    payload: { mlModel }
});

export const setDataSet = dataSet => ({
    type: SET_DATA_SET,
    payload: { dataSet }
});

export const setChipData = chipData => ({
    type: SET_CHIP_DATA,
    payload: { chipData }
});

export const setMlChipData = mlChipData => ({
    type: SET_ML_CHIP_DATA,
    payload: { mlChipData }
});

export const setFilteredValues = filteredValues => ({
    type: SET_FILTERED_DATA,
    payload: { filteredValues }
});


export const setAttribute = (attributeId, attributeName) => ({
    type: SET_ATTRIBUTE,
    payload: {
        attributeId: attributeId,
        attributeName: attributeName
    }
});

export const setInitialValues = () => ({
    type: SET_INITIAL_VALUES
});

export const setMonitorInitialValues = () => ({
    type: SET_MONITOR_INITIAL_VALUES
});

export const setTenantId = tenantId => ({
    type: SET_TENANT_ID,
    payload: { tenantId }
});

export const setStartDate = startDate => ({
    type: SET_START_DATE,
    payload: { startDate }
});

export const setEndDate = endDate => ({
    type: SET_END_DATE,
    payload: { endDate }
});

export const setTimeFilter = timeFilterApplied => ({
    type: SET_TIME_FILTER,
    payload: { timeFilterApplied }
});

export const addDataSource = (dataSourceId, dataSourceName) => ({
    type: ADD_DATA_SOURCE,
    payload: {
        dataSourceId: dataSourceId,
        dataSourceName: dataSourceName
    }
});
export const addDiscoveryStatus = (dataSourceId, discoveryStatus) => ({
    type: ADD_DISCOVERY_STATUS,
    payload: {
        dataSourceId: dataSourceId,
        discoveryStatus: discoveryStatus
    }
});

export const addProcessingStatus = (dataSourceId, processingStatus) => ({
    type: ADD_PROCESSING_STATUS,
    payload: {
        dataSourceId: dataSourceId,
        processingStatus: processingStatus
    }
});

export const addLastProfilingTime = (dataSourceId, lastProfilingTime) => ({
    type: ADD_LAST_PROFILING_TIME,
    payload: {
        dataSourceId: dataSourceId,
        lastProfilingTime: lastProfilingTime
    }
});

//export const addMonitorPreview = (dqPreview, mlPreview) => ({
export const addMonitorPreview = (mlPreview) => ({
    type: ADD_MONITOR_PREVIEW,
    payload: {
//        dqPreview: dqPreview,
        mlPreview: mlPreview
    }
});

export const addMonitorDQPreview = (dqPreview) => ({
    type: ADD_DQ_MONITOR_PREVIEW,
    payload: {
        dqPreview: dqPreview,
    }
});


export const addPerformancePreview = (performancePreview) => ({
    type: ADD_PERFORMANCE_PREVIEW,
    payload: {
        performancePreview
    }
});

export const addInfo = (data) => ({
    type: ADD_INFO,
    payload: { data }
});

export const addEnvironment = (data) => ({
    type: ADD_ENVIRONMENT,
    payload: { data }
});

export const setEnvironment = environment => ({
    type: SET_ENVIRONMENT,
    payload: { environment }
});

export const addEnvironmentDataSourceMapping = mapping => ({
    type: ADD_ENVIRONMENT_DATA_SOURCE_MAPPING,
    payload: { mapping }
});

export const addDataSourceDataSetMapping = mapping => ({
    type: ADD_DATA_SOURCE_DATA_SET_MAPPING,
    payload: { mapping }
});

export const addDataSetAttributeMapping = mapping => ({
    type: ADD_DATA_SET_ATTRIBUTE_MAPPING,
    payload: { mapping }
});

export const addCompletenessDataSetInfo = completenessDataSetInfo => ({
    type: ADD_COMPLETENESS_INFO,
    payload: { completenessDataSetInfo }
});

export const addCompletenessAttributeInfo = completenessAttributeInfo => ({
    type: ADD_COMPLETENESS_ATTRIBUTE_INFO,
    payload: { completenessAttributeInfo }
});

export const addTimelinessDataSetInfo = timelinessDataSetInfo => ({
    type: ADD_TIMELINESS_INFO,
    payload: { timelinessDataSetInfo }
});

export const addTimelinessAttributeInfo = timelinessAttributeInfo => ({
    type: ADD_TIMELINESS_ATTRIBUTE_INFO,
    payload: { timelinessAttributeInfo }
});

export const addConformityDataSetInfo = conformityDataSetInfo => ({
    type: ADD_CONFORMITY_INFO,
    payload: { conformityDataSetInfo }
});

export const addConformityAttributeInfo = conformityAttributeInfo => ({
    type: ADD_CONFORMITY_ATTRIBUTE_INFO,
    payload: { conformityAttributeInfo }
});

export const addAccuracyDataSetInfo = accuracyDataSetInfo => ({
    type: ADD_ACCURACY_INFO,
    payload: { accuracyDataSetInfo }
});

export const addAccuracyAttributeInfo = accuracyAttributeInfo => ({
    type: ADD_ACCURACY_ATTRIBUTE_INFO,
    payload: { accuracyAttributeInfo }
});

export const addUniquenessDataSetInfo = uniquenessDataSetInfo => ({
    type: ADD_UNIQUENESS_INFO,
    payload: { uniquenessDataSetInfo }
});

export const addUniquenessAttributeInfo = uniquenessAttributeInfo => ({
    type: ADD_UNIQUENESS_ATTRIBUTE_INFO,
    payload: { uniquenessAttributeInfo }
});


export const addDriftDataSetInfo = driftDataSetInfo => ({
    type: ADD_DRIFT_INFO,
    payload: { driftDataSetInfo }
});

export const addDriftAttributeInfo = driftAttributeInfo => ({
    type: ADD_DRIFT_ATTRIBUTE_INFO,
    payload: { driftAttributeInfo }
});


export const addConsistencyDataSetInfo = consistencyDataSetInfo => ({
    type: ADD_CONSISTENCY_INFO,
    payload: { consistencyDataSetInfo }
});

export const addConsistencyAttributeInfo = consistencyAttributeInfo => ({
    type: ADD_CONSISTENCY_ATTRIBUTE_INFO,
    payload: { consistencyAttributeInfo }
});

export const addMLModelMapping = mlModelMapping => ({
    type: ADD_ML_MODEL_MAPPING,
    payload: { mlModelMapping }
});

export const addMonitorDQListViewData = monitorDQListView => ({
    type: ADD_MONITOR_DQ_LIST_VIEW,
    payload: { monitorDQListView }
});

export const addMetrics = metrics => ({
        type: ADD_METRICS,
        payload: { metrics }
});

export const addFollowAttributes = follow_attributes => ({
        type: ADD_FOLLOW_ATTRIBUTES,
        payload: { follow_attributes }
})

export const addMLDataSetInfo = mlModelDataSetInfo => ({
    type: ADD_ML_MODEL_INFO,
    payload: { mlModelDataSetInfo }
});

export const addMLAttributeInfo = mlModelAttributeInfo => ({
    type: ADD_ML_MODEL_ATTRIBUTE_INFO,
    payload: { mlModelAttributeInfo }
});

export const addModelPerformance = mlModelPerformanceInfo => ({
    type: ADD_ML_MODEL_PERFORMANCE,
    payload: { mlModelPerformanceInfo }
});


export const addModelDetails = mlModelDetails => ({
    type: ADD_ML_MODEL_DETAILS,
    payload: { mlModelDetails }
});


export const addUserNotificationToState = userNotificationData => ({
    type: USER_NOTIFICATIONS,
    payload: { userNotificationData }
});


export const markNotificationAsRead = notificationID => ({
    type: MARK_NOTIFICATION_AS_READ,
    payload: { notificationID }
});

export const removeNotificationFromList = notificationID => ({
    type: REMOVE_NOTIFICATION_FROM_LIST,
    payload: { notificationID }
});


export const addDatasetVersions = datasetVersion => ({
    type: ADD_DATASET_VERSIONS,
    payload: {
        datasetVersion
    }
});


export const addRecencyDetails = recencyDetails => ({
    type: ADD_RECENCY_DETAILS,
    payload: {
        recencyDetails
    }
});

export const addRecentHistory = recentHistory => ({
    type: ADD_RECENT_HISTORY,
    payload: {
        recentHistory
    }
});

export const addDataSize = dataSize => ({
    type: ADD_DATA_SIZE,
    payload: {
        dataSize
    }
});

export const setModelABDataToList = (modelABList, model_info_map) => ({
    type: SET_MODEL_AB_LIST,
    payload: {
        modelABList, model_info_map
    }
});

export const addRecencyPreview = recencyPreview => ({
    type: ADD_RECENCY_PREVIEW,
    payload: {
        recencyPreview
    }
});


export const prefetchMonitorState = fetchState => ({
    type: PREFETCH_MONITOR_STATE,
    payload: {
        fetchState
    }
});

export const storeIpData = ipData => ({
    type: STORE_IP_DATA,
    payload: { ipData }
});

export const storeMetadataDetails = metadataDetails => ({
    type: ADD_METADATA_DETAILS,
    payload: {
        metadataDetails
    }
});
export const noIntegration = data => ({
    type: NO_INTEGRATION,
    payload: { data }
});

export const mappedDatasetInfo = data => ({
    type: FORM_DATASET_INFO,
    payload: { data }
});

export const listViewTableData = data => ({
    type: FORM_LISTVIEW_TABLE_DATA,
    payload: { data }
});


export const fetchLastRefreshOfDatasets = (lastRefreshOfDatasets) => ({
    type: ADD_DATASET_LAST_REFRESH,
    payload: {
        lastRefreshOfDatasets
    }
});


export const storeDiscoverPageDataForDataSrc = (discoverPageData) => ({
    type: ADD_DATASOURCE_DISCOVER_PAGE,
    payload: {
        discoverPageData
    }
});
