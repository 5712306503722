import React from 'react';
import { toastObj, getFeatureAccess, decryptForLocalUsage } from "../../utils/common_utils";
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faUserFriends, faMailBulk, faUserPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import QualdoDataTable from '../../components/bootstrapTable';
import { getApi, sendInvite } from "../../utils/User_handling";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import { setShow } from "../../utils/event_handling";
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Load from '../../components/loadAction';
import Select from 'react-select';
import { PERMISSIONS_LOCAL_STORE_KEY } from '../../utils/constant';





class TeamMate extends React.Component {
    constructor(props) {
        super(props);
        this.sendInvite = sendInvite.bind(this);
        this.setShow = setShow.bind(this);
        this.handleClose = () => this.setShow("show", false);
        this.handleShow = () => this.setShow("show", true);
        this.closeToast = this.closeToast.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.getApi = getApi.bind(this);
        this.showToast = this.showToast.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.isValidEmail = this.isValidEmail.bind(this);

        let emailId = localStorage.getItem("emailId");
        let username = localStorage.getItem("user_name");
        let qualdo_txt = null;
        if (process.env.REACT_APP_CUSTOM_BRAND_LOGO === "logoDrxLight") {
            qualdo_txt = "Qualdo™-DRX"
        } else {
            qualdo_txt = "Qualdo™"
        }


        let tab = "teamMate"

        if (window.location.subpath !== undefined && window.location.subpath !== null) {
            tab = window.location.subpath
            window.location.subpath = null
        }

        this.state = {
            toastStatus: false,
            hideSubmitButton: false,
            teamMateData: null,
            inviteData: null,
            requestData: null,
            description: username.charAt(0).toUpperCase() + username.slice(1) + "(" +
                emailId + ") is inviting you to join the team in " + qualdo_txt,
            emails: [],
            show: false,
            errorMsg: '',
            descriptionErrorMsg: '',
            featureAccess: getFeatureAccess(),
            qualdo_txt: qualdo_txt,
            activeTabKey: tab,
            role: {"label": "", "value":""},
            roleErrorMsg:'',
            roleOptions: [
                {"label":"Data Analyst", "value":"Data Analyst"},
                {"label":"Data Engineer", "value":"Data Engineer"},
                {"label":"Co-Admin", "value": "Admin"} 
        ]
        };
        this.getData();
    }

    getData() {
        this.getApi("teamMate");
    }

    handleRoleChange(event, obj){
        this.setState({role: event});
    }

    handleDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    closeToast() {
        this.setState({ toastStatus: false });
    }

    disableButton() {
        this.setState({ hideSubmitButton: true });
    }

    showToast(status, msg, link) {
        this.getData();
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });
    }

    renderTable(key) {
        if (key === "team" && this.state.teamMateData !== null && this.state.ugmDetails != null) {
            return (
                <QualdoDataTable
                    data={this.state.teamMateData}
                    ugmDetails={this.state.ugmDetails}
                    component_name="teamMate"
                    showToast={this.showToast} />);
        } else if (key === "invitation" && this.state.inviteData !== null) {
            return (<QualdoDataTable
                data={this.state.inviteData}
                component_name="invitation"
                showToast={this.showToast} />);
        } else if (key === "team" && this.state.teamMateData === null) {
            return (<Load />);
        } else if (key === "invitation" && this.state.inviteData === null) {
            return (<Load />);
        } else if (key === "request" && this.state.requestData !== null) {
            return (<QualdoDataTable
                data={this.state.requestData}
                component_name="requests"
                showToast={this.showToast} />)
        } else if (key === "request" && this.state.requestData === null) {
            return (<Load />);
        }


    }
    changeTab(tab) {
        this.setState({ activeTabKey: tab });
    }

    isValidEmail(email) {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    }

    render() {
        let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
        let permissionsList = [];
        if (permissions !== undefined && permissions !== null) {
            permissionsList = permissions;
        }
        // let _emails = [];
        return (
            <>
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                        onClose={() => this.closeToast()}
                        show={this.state.toastStatus}
                        delay={3000} autohide>
                        <div className="d-flex align-items-center">

                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon} />
                                </i>
                                :
                                ''
                            }

                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastHeading}</strong>
                                <p>{this.state.toastMsg}</p>
                            </Toast.Body>
                            <button type="button"
                                onClick={this.closeToast}
                                className="close"
                                data-dismiss="toast"
                                aria-label="Close" />
                        </div>
                    </Toast>
                </div>
                <div className="qd-section_content settings-manage-teammates">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title">
                            <i>
                                <FontAwesomeIcon icon={faUsersCog} />
                            </i>  Manage teammates
                        </h4>
                    </div>
                    <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                        <div className="tabbable-line border-0">
                            <Nav variant="tabs" id="page-nav-tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="teamMate">
                                        <span className="qd-menu__link-text">
                                            <i>
                                                <FontAwesomeIcon icon={faUserFriends} />
                                            </i>  Teammates</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="invitation">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faMailBulk} />
                                        </i>  Invitations</span>
                                    </Nav.Link>
                                </Nav.Item>
                                {(this.state.featureAccess["usage_payment"] === false &&
                                    permissionsList.includes('BillingAccess')) ?
                                    ''
                                    :
                                    <Nav.Item>
                                        <Nav.Link eventKey="request">
                                            <span className="qd-menu__link-text"><i>
                                                <FontAwesomeIcon icon={faUserPlus} />
                                            </i>  Requests</span>
                                        </Nav.Link>
                                    </Nav.Item>}

                            </Nav>
                        </div>
                        <div className="qd-section-body">
                            <div className="row row-sm">
                                <div className="col-md-12">
                                    <div className="tab-content">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="teamMate">
                                                <div className="portlet">
                                                    <div className="portlet-title">
                                                        <div className="caption">
                                                            <span className="text-uppercase font-dark">TEAM MATES</span>
                                                        </div>
                                                    </div>
                                                    <div className="portlet-body">
                                                        {this.renderTable("team")}
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="invitation">
                                                <div className="portlet">
                                                    <div className="portlet-title">
                                                        <div className="caption">
                                                            <span className="text-uppercase font-dark">INVITATIONS</span>
                                                        </div>
                                                        <div className="actions">
                                                            <button type="button" onClick={this.handleShow} className="btn btn-outline-primary" data-toggle="modal" data-target="#modal__member-requests">
                                                                <FontAwesomeIcon icon={faMailBulk} /> Configure Invitations
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="portlet-body">
                                                        <Modal show={this.state.show} centered onHide={this.handleClose}>
                                                            <Modal.Header closeButton>
                                                                <h5 className="modal-title" id="exampleModalLongTitle">Invitations</h5>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <><p className="text-muted">
                                                                    Please invite others to join your team in {this.state.qualdo_txt}. Please note, when you send an invitation, they will receive an email notification.
                                                                </p>
                                                                    <div className="form-group member-requests-form">
                                                                        <label className="d-block">Email Addresses <span className="text-danger">*</span></label>
                                                                        <div className="d-block">
                                                                            <ReactMultiEmail
                                                                                name="email"
                                                                                placeholder="eg. john@domain.com"
                                                                                emails={this.state.emails}
                                                                                onChange={(_emails) => {
                                                                                    this.setState({ emails: _emails });
                                                                                }}
                                                                                validateEmail={email => {
                                                                                    return this.isValidEmail(email);
                                                                                }}
                                                                                getLabel={(
                                                                                    email,
                                                                                    index,
                                                                                    removeEmail: (index: number) => void,
                                                                                ) => {
                                                                                    return (
                                                                                        <div data-tag key={index}>
                                                                                            <div data-tag-item>
                                                                                                {email} <span data-tag-handle onClick={() => removeEmail(index)}>×
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {this.state.errorMsg !== '' && this.state.emails.length === 0 ? <div className='error-text'>{this.state.errorMsg}</div> : <small className="d-block text-muted">To type multiple email addresses, press enter.</small>}
                                                                    </div>
                                                                <div className="form-group">
                                                                    <label>Roles </label>
                                                                    <Select
                                                                        name="role"
                                                                        filterOption={({label}, query) => label.includes(query)}
                                                                        options={this.state.roleOptions}
                                                                        value={this.state.role}
                                                                        onChange={this.handleRoleChange}
                                                                        placeholder="Select Role"
                                                                        classNamePrefix='select-control'
                                                                    />
                                                                        {this.state.role['value'] === '' && this.state.roleErrorMsg !== '' ? <div className='error-text'>{this.state.roleErrorMsg}</div> : ''}
                                                                </div>
                                                                    <div className="form-group member-requests-form">
                                                                        <label className="d-block">Invitation Email Content</label>
                                                                        <div className="d-block">
                                                                            <textarea
                                                                                rows="3"
                                                                                type="textarea"
                                                                                name="description"
                                                                                onChange={this.handleDescription}
                                                                                defaultValue={this.state.description}
                                                                                className="form-control">
                                                                            </textarea>
                                                                        </div>
                                                                        {this.state.description === '' && this.state.descriptionErrorMsg !== '' ? <div className='error-text'>{this.state.descriptionErrorMsg}</div> : ''}
                                                                    </div>
                                                                </>
                                                                <div className="modal-footer">
                                                                    <button type="button" onClick={this.handleClose} className="btn btn-outline btn-grey btn-circle">Cancel</button>
                                                                    <button type="submit" onClick={this.sendInvite} disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal"><i><FontAwesomeIcon icon={faPaperPlane} /></i> Send</button>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>
                                                        {this.renderTable("invitation")}
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="request">
                                                <div className="portlet">
                                                    <div className="portlet-title">
                                                        <div className="caption">
                                                            <span className="text-uppercase font-dark">REQUESTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="portlet-body">
                                                        {this.renderTable("request")}
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </>
        );
    }

}

export default TeamMate;
