import React from "react";


class OrderRow extends React.Component {

    render() {
        return (
            <div className="billing-summary-card__item">
                <span className="billing-summary-card__item-name">{this.props.labelContent}</span>
                <div>
                    <span className="billing-summary-card__item-cost">
                            {this.props.valueContent}
                    </span>
                </div>
            </div>
        )
    }
}

export default OrderRow;
