import React, {useState, forwardRef, useImperativeHandle}  from 'react';
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import { DATA_SET, ATTRIBUTE } from "../../utils/constant";
import _ from 'lodash';
import {
    datasetNameFromMappedValue,
    attributeNameFromMappedValue,
    METADATA_MAP_KEY_SEPARATOR
} from "../../utils/attribute_name_utils";

export const MlFilterOptions = forwardRef((props, ref) => {
    let [ mlModelOption, setMlModelOption ] = useState(props.mlModelOption);
    let [ dataSetOption, setDataSetOption ] = useState(props.dataSetOptions);
    let [ attributeOption, setAttributeOption ] = useState(props.attributeOptions);
    let [ selectedEnvironmentOption, setSelectedEnvironmentOption ] = useState(props.preSelectedEnvironment);
    let [ selectedDataSourceOption, setSelectedDataSourceOption ] = useState(props.preSelectedDataSource);
    let [ selectedMlModelOption, setSelectedMlModelOption ] = useState(props.preSelectedMlModel);
    let [ selectedDataSetOption, setSelectedDataSetOption ] = useState(props.preSelectedDatasets);
    let [ selectedAttributeOption, setSelectedAttributeOption ] = useState(props.preSelectedAttributes);
    let [ isFilterSubmitted, setFilterSubmitted ] = useState(false);
    let [ isformResetFlag, setFormResetFlag ] = useState(props.formResetFlag);

    useImperativeHandle(ref, () => ({
        setDefaultValuesFromParent(environment, mlModel, dataSet, attribute) {
            if (environment !== null) {
                setSelectedEnvironmentOption(environment);
            }
            if (mlModel !== null) {
                setSelectedMlModelOption(mlModel);
            }
            if (dataSet !== null) {
                setSelectedDataSetOption(dataSet);
            }
            if (attribute !== null) {
                setSelectedAttributeOption(attribute);
            }

        }
    }));

    React.useEffect(() => {
        setSelectedEnvironmentOption(props.preSelectedEnvironment);
    }, [props.preSelectedEnvironment])

    React.useEffect(() => {
        setSelectedMlModelOption(props.preSelectedMlModel);
    }, [props.preSelectedMlModel])

    React.useEffect(() => {
        setSelectedDataSetOption(props.preSelectedDatasets);
    }, [props.preSelectedDatasets])

    React.useEffect(() => {
        setSelectedAttributeOption(props.preSelectedAttributes);
    }, [props.preSelectedAttributes])

    React.useEffect(() => {
        setSelectedDataSourceOption(props.preSelectedDataSource);
    }, [props.preSelectedDataSource])

    React.useEffect(() => {
        setMlModelOption(props.mlModelOption);
    }, [props.mlModelOption])

    React.useEffect(() => {
        setDataSetOption(props.dataSetOptions);
    }, [props.dataSetOptions])

    React.useEffect(() => {
        setFormResetFlag(props.formResetFlag);
    }, [props.formResetFlag])


    if(isformResetFlag===1){
        setFormResetFlag(0);
        setMlModelOption([]);
        setDataSetOption([]);
        setAttributeOption([]);
        setSelectedDataSetOption(-1);
        setSelectedMlModelOption(-1);
        setSelectedAttributeOption(-1);
        setSelectedEnvironmentOption(-1)
    }


    function parseMetaMapping(type, parentId, id) {
        let metaMapping = _.cloneDeep(props.dataModule.metaData);
        let prefix;
        switch (type) {
            case DATA_SET: {
                prefix = `${selectedEnvironmentOption.value}.${parentId}.${id}`;
                // const filtered = Object.keys(metaMapping)
                //     .filter( key => key.startsWith(prefix))
                //     .reduce((obj, key) => {
                //         obj[key] = metaMapping[key];
                //         return obj;
                //     }, {});
                let dataSetName = metaMapping[prefix];
                return datasetNameFromMappedValue(dataSetName)
            }
            case ATTRIBUTE: {
                prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}.${parentId}.${id}`;
                // const filtered = Object.keys(metaMapping)
                //     .filter(key => key === prefix)
                //     .reduce((obj, key) => {
                //         obj[key] = metaMapping[key];
                //         return obj;
                //     }, {});
                let attributeMappedVal = metaMapping[prefix];
                let attributeName = attributeNameFromMappedValue(attributeMappedVal);
                return attributeName;
            }
            default:
                return "NA";
        }
    }


    function setSelectedEnvironment(event) {
        let option = [];
//        let selectedEnvironment = event.value;
//        let dataSources = props.dataModule.environmentDataSourceMapping[selectedEnvironment];
//        dataSources.forEach((dataSourceId) => {
//            option.push({"value": dataSourceId, "label":props.dataModule.integrations[dataSourceId]});
//        });
        let mlModels = props.monitorModule.mlModelDetails;
        mlModels.forEach((mlModel) => {
            if (mlModel.env_id.toString() === event.value ){
                option.push({"value": mlModel.ml_model_id, "label": mlModel.model_name,
                             "dataSrcName": mlModel.integration_name,
                              "dataSourceId": mlModel.integration_id});
            }
        });
        setMlModelOption(option);
        setSelectedEnvironmentOption(event);
    }


    function setSelectedMlModel(event) {
        let option = [];
        let datasetIds = [];
//        let selectedMlModel = event.value;
//        let mlDatasetDetails = props.monitorModule.mlModelDataSetInfo[selectedMlModel];
//        if (mlDatasetDetails !== undefined && mlDatasetDetails !== null) {
////            dataSets.forEach((dataSetId) => {
////                option.push({"value": dataSetId, "label": parseMetaMapping(DATA_SET, selectedDataSource, dataSetId)});
////            })
//
//            for (let metricValues of Object.values(mlDatasetDetails)){
//                    Object.keys(metricValues).forEach((modelPrefix) => {
//                        let dataSetId=modelPrefix.split(".")[2];
//                        if (!datasetIds.includes(dataSetId)){
//                            datasetIds.push(dataSetId);
//                            option.push({"value": dataSetId, "label": parseMetaMapping(DATA_SET,
//                                event.dataSourceId, dataSetId)});
//                        }
//                    })
//                }
//        }
        let chartDataV1 = props.dataModule.relationship[event.dataSourceId];
        if (props.dataModule !== undefined && chartDataV1.length !== 0) {
            for (let i in chartDataV1){
                let attributeName= chartDataV1[i].name;
                let dataSetId=attributeName.split(METADATA_MAP_KEY_SEPARATOR)[2];
                if (!datasetIds.includes(dataSetId)){
                    datasetIds.push(dataSetId);
                    option.push({"value": dataSetId, "label": parseMetaMapping(DATA_SET,event.dataSourceId, dataSetId)});
                }
            }
        }
        setDataSetOption(option);
        let dataSrc = {"value": event.dataSourceId ,"label": event.dataSrcName}
        setSelectedDataSourceOption(dataSrc);
        setSelectedMlModelOption(event);
        setSelectedDataSetOption(-1);
        setSelectedAttributeOption(-1);
    }


    function setSelectedDataSet(event) {
        let option = [];
        let selectedDataSet = [];
        if (event !== null ) {
            event.forEach((item) => {
                selectedDataSet.push(item.value);
                let attributes = props.dataModule.dataSetAttributeMapping[item.value];
                attributes.forEach((attributeId) => {
                    option.push({"value": attributeId, "label": parseMetaMapping(ATTRIBUTE, item.value, attributeId)});
                });
            });
            setSelectedDataSetOption(event);
        } else {
            setSelectedDataSetOption(-1);
            setSelectedAttributeOption(-1);
        }
        setAttributeOption(option);
        setSelectedAttributeOption(-1);

    }

    function setSelectedAttribute(event) {
        let selectedAttribute = [];
        if (event !== null ) {
            event.forEach((item) => {
                selectedAttribute.push(item.value);
            });
            setSelectedAttributeOption(event);
        } else {
            setSelectedAttributeOption(-1);
        }
    }


    function handleFilterClose(){
        setSelectedAttributeOption(props.preSelectedAttributes);
        setSelectedDataSourceOption(props.preSelectedDataSource);
        setSelectedDataSetOption(props.preSelectedDatasets);
        setSelectedEnvironmentOption(props.preSelectedEnvironment);
        props.closeFilterModal();
    }

    function handleReset(){
        setFormResetFlag(1);
    }
    
    function setFilteredValues() {
        setFilterSubmitted(true);
        let filteredValues = [];
        let prefix;
        if (selectedEnvironmentOption !== -1 && selectedMlModelOption !== -1) {
            prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}-${selectedMlModelOption.value}`;
            if (selectedDataSetOption!==undefined && selectedDataSetOption !== null && selectedDataSetOption !== -1) {
                selectedDataSetOption.forEach((dataSet) => {
                    if(selectedAttributeOption !== null && selectedAttributeOption !== -1) {
                        selectedAttributeOption.forEach((attribute) => {
                            filteredValues.push(`${prefix}.${dataSet.value}.${attribute.value}`);
                        });
                    } else {
                        filteredValues.push(`${prefix}.${dataSet.value}`);
                    }
                });
            } else {
                filteredValues.push(prefix);
            }
            props.setFilteredValues(filteredValues)
            props.closeFilterModal();
            setFilterSubmitted(false);
            props.setChipData(selectedEnvironmentOption, selectedMlModelOption, selectedDataSetOption, selectedAttributeOption);
        }

    }

   return (
         <Modal show={props.show}  style={{display: "block", paddingRight: "15px"}} centered
                onHide={handleFilterClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {props.filterTitle}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="">Environment <span className="text-danger">*</span>:</label>
                            <Select
                                name="environments"
                                filterOption={({label}, query) => label.includes(query)}
                                id={props.id + "Environment"}
                                onChange={setSelectedEnvironment}
                                options={props.environments}
                                placeholder="Choose Environment"
                                classNamePrefix='select-control'
                                value={selectedEnvironmentOption === -1 ? '' : selectedEnvironmentOption}
                                defaultValue={selectedEnvironmentOption === -1 ? '' : selectedEnvironmentOption}
                            />
                            { selectedEnvironmentOption === -1 && isFilterSubmitted ?
                                <span className='error-text'> {"Please Select an Environment"} </span> : ""}
                        </div>
                        <div className="form-group">
                            <label htmlFor="">ML-Model <span className="text-danger">*</span>:</label>
                            <Select name="ml_model"
                                filterOption={({label}, query) => label.includes(query)}
                                options={mlModelOption}
                                id={props.id + "Datasource"}
                                onChange={setSelectedMlModel}
                                placeholder="Choose ML Model"
                                classNamePrefix='select-control'
                                value={selectedMlModelOption === -1 ? '' : selectedMlModelOption}
                                defaultValue={selectedMlModelOption === -1 ? '' : selectedMlModelOption}
                            />
                            { selectedMlModelOption === -1 && isFilterSubmitted ?
                                <span className='error-text'>{"Please Select a Model"}</span> : ""}
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Dataset :</label>
                            <Select  isMulti={true}  name="schemas"
                                options={dataSetOption}
                                filterOption={({label}, query) => label.includes(query)}
                                id={props.id + "Schemas"}
                                onChange={setSelectedDataSet}
                                placeholder="Choose Dataset"
                                classNamePrefix='select-control'
                                value = {selectedDataSetOption === -1 ? '' : selectedDataSetOption}
                                defaultValue={selectedDataSetOption === -1 ? '' : selectedDataSetOption}
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="">Attribute :</label>
                            <Select isMulti={true} name="columns"
                                filterOption={({label}, query) => label.includes(query)}
                                options={attributeOption}
                                id={props.id + "Columns"}
                                onChange={setSelectedAttribute}
                                placeholder="Choose Attributes"
                                classNamePrefix='select-control'
                                value = {selectedAttributeOption === -1 ? '' : selectedAttributeOption}
                                defaultValue={selectedAttributeOption === -1 ? '' : selectedAttributeOption}
                            />
                        </div>
                        <div className="form-group mb-0 mt-2">
                            <span className="switch switch-sm switch-info">
                                <label className="m-0">
                                    Include PII
                                    <input className="form-check-input" disabled checked={true} type="checkbox"/>
                                    <span className="m-0 mx-2"></span>
                                </label>
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                       <button type="button" onClick={handleReset} class="btn btn-link">Reset</button>
                        <div>
                        <button type="button" onClick={handleFilterClose}
                                className="btn btn-outline btn-grey btn-circle">Close</button>
                        <button type="button" onClick={setFilteredValues}
                                className="btn btn-primary btn-circle show-success-toast">Apply</button>
                        </div>        
                    </Modal.Footer>
         </Modal>
    );
});