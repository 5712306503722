import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function DeleteWarningModal(props) {

    const componentName = props.modalTitle !== undefined ? `${props.modalTitle}` : `${props.component}`;
    let deleteMsgTitle = `You are about to delete a ${componentName}`;
    let deleteMsgContent = `This will delete your ${componentName}`;

    return (
        <Modal show={props.delete_show} centered onHide={props.deleteClose}>
            <Modal.Header closeButton className="d-none">
                <h5 className="modal-title" id="deleteWarningModal">
                    {`Delete ${componentName}`}</h5>
            </Modal.Header>
            <Modal.Body>
                <div className="modal__alert">
                    <div className="modal_alert-icon">
                        <i className="text-danger">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </i>
                    </div>
                    <div className="modal_alert-text">
                        <h4>{deleteMsgTitle}</h4>
                        <p>{deleteMsgContent}</p>
                    </div>
                    <div className="modal_alert-btn-group">
                        <button type="button" onClick={props.deleteClose}
                                className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                        </button>
                        <button type="button"
                                onClick={props.handleDeleteFunction}
                                disabled={props.disableDelete}
                                className="btn btn-primary btn-circle mr-2">Delete
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}