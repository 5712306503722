import React from 'react';
import CopyRights from '../../components/copyrights';
import { faEnvelope, faArrowLeft, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iam_ip } from "../../utils/ip_details";
import { call_post_api }  from "../../utils/event_handling"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Formik } from 'formik';
import * as yup from 'yup';


class TeamSignup extends React.Component {
    constructor(props) {
        super(props);
        this.hideAlert = this.hideAlert.bind(this);
        this.state = {
            formValid: false,
            errorCount: null,
            hideSubmitButton: false,
            error: null,
            errors: {
                adminEmail: '',
                msg: ''
            }
         }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    hideAlert() {
        this.setState({
          error: null,
        });
    }

    handleSubmit = (event) => {
        this.disableButton();
        let { adminEmail } = event;
        this.props.setEmail(adminEmail,"joinTeam");
        if ( this.props.userDetails["email"] === adminEmail ) {
                this.setState({error: "Provide Admin's email", hideSubmitButton: false});
            } else {
                const requestData = { email: this.props.userDetails["email"], admin_email: adminEmail,
                                user_id: this.props.userDetails["user_id"], team_id: this.props.userDetails["team_id"] };
                call_post_api(iam_ip, "/request/invite", requestData).then((response) => {
                    if ( response === undefined ) {
                        this.setState({error: "Could not process your request.", hideSubmitButton: false});
                    } else if( response.code === 200 ) {
                        this.props.setEmailPage(response.message);
                    } else {
                        this.setState({error: response.message, hideSubmitButton: false});
                    }
                });
            }
     }

    render() {
        const adminSchema = yup.object({
            adminEmail: yup.string().email("Admin's Email must be a valid email").required("Admin's Email is a required field")
        });
        return (
            <div className="prelogin-content">
                <h4 className="login-welcome-text mt-0">Do you wish to join an existing team?</h4>
                <h5>Team Admin’s Email</h5>
                    {
                    this.state.error ?
                        <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                             {this.state.error}
                            <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"></button>
                        </div> : ""
                    }
                    <Formik
                        validationSchema={adminSchema}
                        onSubmit={this.handleSubmit}
                        initialValues={{ adminEmail: ''}}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    }) => (
                        <Form noValidate name="requestInvite" className="login-form needs-validation" onSubmit={handleSubmit}>
                            <div className="prelogin-form-container">
                            <Form.Group controlId="adminEmail">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Admin’s Email"
                                        className="form-group form-control-solid placeholder-no-fix"
                                        value={values.adminEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.adminEmail && touched.adminEmail}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.adminEmail && errors.adminEmail }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <CopyRights classDetails="text-copyright text-left" text="By signing up, you agree to the"/>
                    </div>
                    <div className="form-action-btn prelogin-btn-group">
                        <a href="/signup" className="btn btn-circle btn-outline-secondary">
                            <i><FontAwesomeIcon icon={faArrowLeft}/></i> Back
                        </a>
                        <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary mx-1">
                            <FontAwesomeIcon icon={faUserFriends}/>
                             Request Invite
                        </button>
                    </div>
                </Form>
               )}
               </Formik>
           </div>
        );
    }
}

export default TeamSignup;
