import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {CONFIGURE_DATA_SOURCE_URL, MONITOR_MODEL_URL, MONITOR_DATA_URL,
        PROFILE_DATA_URL, PROFILE_MODEL_URL, MONITOR_MODEL_PERFORMANCE_URL} from "../utils/constant";
import {Mediakind} from "./mediaKind"


class VideoContent extends Component {

  constructor(props) {
        super(props);
        this.getVideo = this.getVideo.bind(this);
        this.getVideoUrl = this.getVideoUrl.bind(this);
        this.state = {
        }
  }

  renderContent(name) {
      if(this.props.modelName !== undefined) {
        switch(this.props.modelName) {
            case "Model Errors":
                  return (<><h4 className="text-info">{this.props.modelName}</h4>
                            <p>This page is the primary source to look for any issues around your model. All the supported metrics on the model side are configured with a threshold by default but it can be changed at any point in time from the Metrics page. Any threshold breach on drift detected is highlighted as the error in red.</p>
                            <h4 className="text-info">Drifts in the configured model</h4>
                            <p>This section is a view to understand different errors/drifts contributing to the model decay. At this point of time, any threshold violation of the performance metrics will appear under this section.</p>
                            <h4 className="text-info">Drifts in the serving data of the model</h4>
                            <p>This section is a view to represent the overall drift in the serving data. Many features might be contributing to it and the same can be understood by clicking on contribution in the options.</p>
                            <h4 className="text-info">Consistency Errors in the Serving data in comparison with the Training data</h4>
                            <p>This section highlights any consistency error between serving and training data. Consistency problems can easily lead to model failure if unnoticed.</p>
                            <h4 className="text-info">Feature Drifts on Serving data over time</h4>
                            <p>This section captures the drifts in features of the serving data over time which can cause a potential decay of the model. </p>
                            <h4 className="text-info">Feature Drifts on Serving data in comparison with training data</h4>
                            <p>This section captures the drifts in features of the serving data in comparison with the training data. Variations in serving data can cause the poor performance of a model compared to the time it was trained.</p>
                            <h4 className="text-info">Drifts in Predictions of model</h4>
                            <p>This section captures how the predictions of the model themselves are drifting over time. It needn’t always point to a performance issue in the model but will be a good starting point to understand any imbalance of serving data.</p>
                            <h4 className="text-info">Drifts in response of model</h4>
                            <p>This section captures how the business response to the predictions made are drifting over time.</p>
                          </>);
            case "Model Performance Dashboard":
                  return (<><h4 className="text-info">{this.props.modelName}</h4>
                            <p>The page provides different model performance metrics for the configured model.</p>
                            <h4 className="text-info">Model Accuracy</h4>
                            <p>Model Accuracy is the first level of metrics to understand the model performance. For a classification type of model, this section provides the time-series view of metrics like F1 score, Precision, Recall, etc. For a regression model, this section provides basic metrics like RMSE, MAE, etc.</p>
                            <h4 className="text-info">Model Evaluation</h4>
                            <p>Model Evaluation is the second level metrics to debug further to understand the model performance. For a classification type of model, this section is loaded with additional views like confusion matrix, AUC, etc.</p>
                            <h4 className="text-info">Model Monitoring</h4>
                            <p>Model Monitoring is a view to monitor the resource utilization of the model like CPU, Memory Usages, etc.</p>
                          </>);
            default:
                  return ""
        }
      }
      switch(name) {
         case "Data Completeness Dashboard":
               return(<><h4 className="text-info">{name}</h4>
                          <p>Fill rate is a metric to understand whether desired data are present or not. Qualdo has a default definition to detect the presence or absence of desired data but this definition can be customized.</p>
                          <h4 className="text-info">Cards</h4>
                          <p>1. Cards on this page give an overview of the percentage of data contributing to different ranges of fill rate and the total number of rows present.</p>
                          <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                          <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                          <h4 className="text-info">Visualization</h4>
                          <p>1. Data completeness visualization gives an overview of the fill rate of the attribute(s) in the data source by default or based on the applied filters.</p>
                          <p>2. Attributes are on the circumference and each of the concentric circles represents a fill rate in percentage.</p>
                          <p>3. Each bar represents the fill rate for the attribute they correspond to and hover over the bar to find out the fill rate value.</p>
                          <p>4. Fill rate values shown are always the lowest fill rate for attributes in the selected period.</p>
                          <p>5. Visualization will change based on the applied filters.</p>
                          <p>6. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                          <h4 className="text-info">Grid</h4>
                          <p>1. A table view is to get quick access to the fill rates of attributes.</p>
                          <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                     );
         case "Data Accuracy Dashboard":
               return(<><h4 className="text-info">{name}</h4>
                        <p>Data Outliers are metrics to capture any anomaly in the data.</p>
                        <h4 className="text-info">Cards</h4>
                        <p>1. Cards on this page give an overview of outliers present in the data.</p>
                        <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                        <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                         <h4 className="text-info">Visualization</h4>
                         <p>1. The visualization captures the different types of accuracy errors present in the configured data at a glimpse. Currently, data outlier is the supported metric.</p>
                         <p>2. Every unique type of accuracy error is shown as separate circles. Data Outlier is represented as a single circle surrounded by attribute names found to contain outliers.</p>
                         <p>3. Higher the error values, the bigger will be the circle containing the metric name (Data outliers).</p>
                         <p>4. Hover over the circle containing the attribute name to find out the outlier value.</p>
                         <p>5. For the selected period, visualization always shows the maximum of the outlier errors that occurred per attribute.</p>
                         <p>6. Visualization will change based on the applied filters.</p>
                         <p>7. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                         <h4 className="text-info">Grid</h4>
                         <p>1. A table view is to get quick access to the outlier values in the data.</p>
                         <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                      </>
                     );
         case "Data Conformity Dashboard":
               return(<><h4 className="text-info">{name}</h4>
                        <p>Data conformity errors are metrics to identify data that don’t comply with certain standards. For example, a date attribute has to be of the format “dd/mm/yyyy”</p>
                        <h4 className="text-info">Cards</h4>
                        <p>1. Cards on this page give an overview of different types of conformity errors that occurred.</p>
                        <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                        <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                        <h4 className="text-info">Visualization</h4>
                        <p>1. Data conformity visualization captures the different types of conformity errors present in the configured data at a glimpse.</p>
                        <p>2. Every unique type of conformity error is shown as separate circles. Within the circle are attribute names found to contain errors and a single circle containing the conformity error name.</p>
                        <p>3. Higher the error values, the bigger will be the circle containing the conformity error name.</p>
                        <p>4. Hover over the circle containing the attribute name to find out the conformity error value.</p>
                        <p>5. For the selected period, visualization always shows the maximum of the conformity errors that occurred per attribute.</p>
                        <p>6. Visualization will change based on the applied filters.</p>
                        <p>7. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                        <h4 className="text-info">Grid</h4>
                        <p>A table view is to get quick access to the different conformity errors in the data.</p>
                        <p>This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                      </>
               );
         case "Data Timeliness Dashboard":
               return(<><h4 className="text-info">{name}</h4>
                        <p>Recency is a metric to understand how latest the data is. Recency is measured in refreshes once the dataset is configured and is reset every time the dataset is refreshed.</p>
                        <h4 className="text-info">Cards</h4>
                        <p>1. Cards on this page give an overview of how recent the datasets are.</p>
                        <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                        <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                        <h4 className="text-info">Visualization</h4>
                        <p>1. Visualization gives an overview of recency for the configured datasets.</p>
                        <p>2. Datasets are on the circumference and each of the concentric circles represents different recency in refreshes.</p>
                        <p>3. Each bar represents the recency of the dataset they correspond to and hover over the bar to find the recency value.</p>
                        <p>4. For the selected time, the visualization shows the maximum recency value of a dataset. (i.e) the longest duration in refreshes for which the dataset hasn’t been refreshed.</p>
                        <p>5. Visualization will change based on the applied filters.</p>
                        <p>6. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                        <h4 className="text-info">Grid</h4>
                        <p>1. A table view is to get quick access to the recency of the datasets.</p>
                        <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                      );
         case "Relationships & Errors within a Datasource":
               return(<><h4 className="text-info">{name}</h4>
                        <p> Data relationship is a way to understand how the data are interconnected. Currently, attributes that are similar in nature will be auto-detected and shown on the visualization.</p>
                        <h4 className="text-info">Cards</h4>
                        <p>1. Cards on this page give an overview of the different data quality metrics on the configured data source.</p>
                        <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                        <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                        <h4 className="text-info">Visualization</h4>
                         <p>1. Visualization gives a quick peek of how attributes in different data sources are related to each other. A relation is represented as a line connecting the related attributes.</p>
                         <p>2. Attributes are found on the circumference and each group of attributes belongs to a dataset within the data source.</p>
                         <p>3. Hover over the attribute to understand about the other attributes they are related to and the confidence score of the relationships. It also shows you details about any errors detected in that attribute.</p>
                         <p>4. Different types of errors are represented in a different color next to the attribute and a legend is placed to guide you.</p>
                         <p>5. Error-values shown are always the latest errors found on the attributes for the selected time.</p>
                         <p>6. Visualization will change based on the applied filters.</p>
                         <p>7. Clicking on any attribute will take you to a detailed overview of the selected attribute.</p>
                         <h4 className="text-info">Grid</h4>
                         <p>1. A table view is to get quick access to the different data quality metrics and their associated values for each attribute.</p>
                         <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                      );
         case "Data Consistency Dashboard":
               return(<><h4 className="text-info">{name}</h4>
                          <p>Data consistency errors are metrics to identify any consistency errors happening between two datasets. Data consistency is not enabled automatically and the user can enable it from the “Metrics” page.</p>
                          <h4 className="text-info">Cards</h4>
                          <p>1. Cards on this page give an overview of different types of consistency errors that occurred.</p>
                          <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                          <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                          <h4 className="text-info">Visualization</h4>
                          <p>1. Visualization captures the data consistency issues in the dataset being monitored in comparison with the reference dataset provided.</p>
                          <p>2. Attributes are found on the circumference and each group of attributes belongs to a dataset within the data source.</p>
                          <p>3. Different types of consistency errors are represented in a different color next to the attribute and a legend is placed to guide you.</p>
                          <p>4. Hover over the attribute to find out the error values.</p>
                          <p>5. For the selected period, visualization always shows the maximum of the consistency errors that occurred per attribute.</p>
                          <p>6. Visualization will change based on the applied filters.</p>
                          <p>7. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                          <h4 className="text-info">Grid</h4>
                          <p>1. A table view is to get quick access to the different consistency errors in the data.</p>
                          <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                       );
         case "Drift Overview":
               return(<><h4 className="text-info">{name}</h4>
                          <p>This page provides a quick view of how the model is performing and the different types of drifts detected in the model which in turn affects the performance.</p>
                          <h4 className="text-info">Cards</h4>
                          <p>1. Cards on this page give an overview of how the model is performing and highlight any issues on a glance.</p>
                          <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                          <h4 className="text-info">Visualization</h4>
                          <p>1. Visualization gives an overview of different types of drifts and also on when they are happening at a glimpse.</p>
	                      <p>2. Features are on the circumference and each of the concentric circles represents a timeline.</p>
	                      <p>3. Drifts on features are represented by a bar and the bar starts and ends based on the timeline of when it started and ended.</p>
	                      <p>4. Hover over the bar to know more details of the drifts.</p>
	                      <p>5. For the selected period, visualization represents the maximum drift noticed during that time.</p>
                          <h4 className="text-info">Grid</h4>
                          <p>1. A table view is to get quick access to the different model drifts and consistency errors.</p>
                          <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                       );
         case "Model Performance":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. This section covers the model performance metrics for the configured models.</p>
                          <p>2. Model accuracy metrics like f1 score, precision, recall and Model Evaluation metrics like ROC, AUC are presented for investigation.</p>
                      </>
                      );
         case "Data Completeness Errors - Across Datasources":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. Fill rate is a metric to understand whether desired data are present or not. Qualdo has a default definition to detect the presence or absence of desired data but this definition can be customized.</p>
                          <p>2. Fill rate values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Fill rate) are indicated as error in red color.</p>
                          <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                          <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                          <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                      </>
                      );
         case "Data Timeliness Errors - Across Datasources":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. Recency is a metric to understand how latest the data is. Recency is measured in refreshes once the dataset is configured and is reset every time the dataset is refreshed.</p>
                          <p>2. Recency values represented in refreshes are presented for only for the datasets. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Recency) are indicated as error in red color.</p>
                          <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                          <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                          <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                      </>
                      );
         case "Data Accuracy Errors - Across Datasources":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. Data Outliers are metrics to capture any anomaly in the data.</p>
                          <p>2. Data Outliers values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Outliers) are indicated as error in red color.</p>
                          <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                          <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                          <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                      </>
                      );
         case "Data Consistency Errors - Across Datasources":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. Data consistency errors are metrics to identify any consistency errors happening between two datasets. Data consistency is not enabled automatically and the user can enable it from the “Metrics” page.</p>
                          <p>2. Data Consistency errors represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (consistency checks) are indicated as error in red color.</p>
                          <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                          <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                          <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                      </>
                      );
         case "Data Conformity Errors - Across Datasources":
               return(<><h4 className="text-info">{name}</h4>
                          <p>1. Data conformity errors are metrics to identify data that don’t comply with certain standards. For example, a date attribute has to be of the format “dd/mm/yyyy”.</p>
                          <p>2. All the supported conformity checks appear as separate groups.</p>
                          <p>3. Data Conformity errors represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (conformity checks) are indicated as error in red color.</p>
                          <p>4. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                          <p>5. Inside show more, data can be filtered by a dataset for further analysis.</p>
                          <p>6. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                      </>
                      );
         case "Data Drift Errors - Across Datasources":
              return( <><h4 className="text-info">{name}</h4>
                      <p>1. Data Drift is a metric to understand variations in the statistical properties of the data. It’s an indicator to a possible problem in the data.</p>
                      <p>2. Drift values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Drift) are indicated as error in red color.</p>
                      <p>3. Drift values are also computed and presented on aggregates like mean, min, max, mode etc.</p>
                      <p>4. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                      <p>5. Inside show more, data can be filtered by a dataset for further analysis.</p>
              </>
              );

         case "Data Uniqueness Errors - Across Datasources":
            return(<>
                        <h4 className="text-info">{name}</h4>
                        <p>Uniqueness is a metric to understand whether duplicates present in the data or not.</p>
                        <p>Uniqueness values represented in numbers are presented for both dataset and attributes.</p>
                        <p>Three charts are shown on this page and the rest of the views are available on clicking show more.</p>
                        <p>Inside show more, data can be filtered by datasource, dataset, attribute and metrics for further analysis.</p>
                        <p>Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                  </>
            );

         case "Data Uniqueness Dashboard":
               return(<><h4 className="text-info">Data Uniqueness Overview</h4>
                          <p>Uniqueness is a metric to understand whether duplicates present in your data or not.</p>
                          <h4 className="text-info">Cards</h4>
                          <p>1. Cards on this page give an overview of the number of datasets having the duplicate data.</p>
                          <p>2. Refer to the individual help section on the cards to understand what they represent.</p>
                          <p>3. Card values will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                          <h4 className="text-info">Visualization</h4>
                          <p>1. Data uniqueness visualization gives an overview of the uniqueness of the attribute(s) in the datasource by default or based on the applied filters.</p>
                          <p>2. Attributes are on the circumference and each of the concentric circles represents a uniqueness in percentage.</p>
                          <p>3. Each bar represents the uniqueness for the attribute they correspond to and hover over the bar to find out the uniqueness percentage value.</p>
                          <p>4. Uniqueness values shown are always the lowest uniqueness for attributes in the selected period.</p>
                          <p>5. Visualization will change based on the applied filters.</p>
                          <p>6. Clicking on any attribute will take you to a detailed overview of the attribute.</p>
                          <h4 className="text-info">Grid</h4>
                          <p>1. A table view is to get quick access to the uniqueness of attributes.</p>
                          <p>2. This view also will change based on the applied filters (Top level filter and the filters found near the visualization)</p>
                       </>
                     );
         case "Attribute Drifts":
               return (<><h4 className="text-info">{name}</h4>
                         <p>1. This page gives a detailed summary of the selected attribute.</p>
                         <p>2. Attribute Drifts is a visualization on different types of drifts happening on the selected attribute.</p>
                         <p>   a. Metrics drop down - Gives a view of drift on the values of attribute over time, drift on the applicable metrics on the attribute</p>
                         <p>   b. Aggregate drop down - Gives a view of drifts happening on the statistical measures of the attribute. Statistical measures are applied only on the numeric types.</p>
                       </>);

         case "Discover":
              return (
                        <>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Discover page helps you to discover the information about a particular Environment, Datasource, Dataset and Attributes in a single place. It also gives you the view on the complete metadata of the configured Datasource(s).</p>
                              <p>2. One can start typing the name of the entity that they are looking for in the Search box present at the top of all the pages or present in the Discover page.</p>
                              <p>3. One can also click on the Filters button and apply the filters of their interest to discover the information.</p>
                              <p>4. One can also search from the Search box available in the left panel on top of the metdadata tree, where only the matched entities are shown in the tree view.</p>
                        </>);

            case "Data Drift - Across Datasources":
                  return( <>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Data Drift is a metric to understand variations in the statistical properties of the data. It’s an indicator to a possible problem in the data.</p>
                              <p>2. Drift values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Drift) are indicated as error in red color.</p>
                              <p>3. Drift values are also computed and presented on aggregates like mean, min, max, mode etc.</p>
                              <p>4. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>5. Inside show more, data can be filtered by a dataset for further analysis.</p>
                  </>
                  );

            case "Data Completeness - Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Fill rate is a metric to understand whether desired data are present or not. Qualdo has a default definition to detect the presence or absence of desired data but this definition can be customized.</p>
                              <p>2. Fill rate values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Fill rate) are indicated as error in red color.</p>
                              <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                              <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );
            case "Data Uniqueness - Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>Uniqueness is a metric to understand whether duplicates present in the data or not.</p>
                              <p>Uniqueness values represented in numbers are presented for both dataset and attributes.</p>
                              <p>Three charts are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>Inside show more, data can be filtered by datasource, dataset, attribute and metrics for further analysis.</p>
                              <p>Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );
            case "Data Timelines- Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Recency is a metric to understand how latest the data is. Recency is measured in refreshes once the dataset is configured and is reset every time the dataset is refreshed.</p>
                              <p>2. Recency values represented in refreshes are presented for only for the datasets. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Recency) are indicated as error in red color.</p>
                              <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                              <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );
            case "Data Accuracy - Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Data Outliers are metrics to capture any anomaly in the data.</p>
                              <p>2. Data Outliers values represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (Outliers) are indicated as error in red color.</p>
                              <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                              <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );
            case "Data Conformity - Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Data conformity errors are metrics to identify data that don’t comply with certain standards. For example, a date attribute has to be of the format “dd/mm/yyyy”.</p>
                              <p>2. All the supported conformity checks appear as separate groups.</p>
                              <p>3. Data Conformity errors represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (conformity checks) are indicated as error in red color.</p>
                              <p>4. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>5. Inside show more, data can be filtered by a dataset for further analysis.</p>
                              <p>6. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );
            case "Data Consistency - Across Datasources":
                  return(<>
                              <h4 className="text-info">{name}</h4>
                              <p>1. Data consistency errors are metrics to identify any consistency errors happening between two datasets. Data consistency is not enabled automatically and the user can enable it from the “Metrics” page.</p>
                              <p>2. Data Consistency errors represented in percentage are presented for both dataset and attributes. A default threshold value is set and it can be changed from the Metrics page. Any threshold breach and drift on the metric (consistency checks) are indicated as error in red color.</p>
                              <p>3. Top three critical errors are shown on this page and the rest of the views are available on clicking show more.</p>
                              <p>4. Inside show more, data can be filtered by a dataset for further analysis.</p>
                              <p>5. Apart from default metrics, any custom metrics created and associated with this metric will appear under this section.</p>
                        </>
                  );


         default:
                  return ""

      }

  }

  getVideo() {
        switch(this.props.name) {
            case "accuracy":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "completeness":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "timeliness":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "standardisation":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "relationship":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "integrity":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            case "ml_model":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            default:
                  return ""
        }
  }

  getVideoUrl(){
     let video_url = CONFIGURE_DATA_SOURCE_URL;
     switch(this.props.video_url) {
        case "profile_model":
              return PROFILE_MODEL_URL;
        case "profile_data":
              return PROFILE_DATA_URL;
        case "monitor_model":
              return MONITOR_MODEL_URL;
        case "monitor_model_performance":
              return MONITOR_MODEL_PERFORMANCE_URL;
        case "monitor_data":
              return MONITOR_DATA_URL;
        default:
              return video_url
     }
  }

  render() {
    let video_url = this.getVideoUrl();
     return (
              <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="modal-vertical shadow" className="fixed-right model__help" size="md">
                  <Modal.Header className="modal-header py-3 align-items-center bg-dark text-white" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                              <h4 className="mb-0"><i><FontAwesomeIcon icon={faQuestionCircle}/></i>Help</h4>
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="qd-search-bar d-none">
                        <div className="input-group border">
                          <div className="input-group-prepend">
                            <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch}/></button>
                          </div>
                          <input type="search" placeholder="Search" className="form-control"/>
                        </div>
                    </div>
                    <div className="model__help-content">
                        {this.renderContent(this.props.name)}
                     </div>
                    <div className="card my-3">
                        <div className="card-body p-0">
                          <Mediakind
                            url= {video_url}
                          />
                        </div>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                   </Modal.Footer> */}
              </Modal>
    );
  }
}

export default VideoContent;
