import React from 'react';
import { getShortTitleForAttribute } from "../../utils/common_utils";
import {
    FQ_TOOLTIP_PLACEHOLDER
} from "../../utils/attribute_name_utils";
import RcTooltip from 'rc-tooltip';

const renderAttributeNameToolTip = (props) => (
    <span {...props}>
        {props}
    </span>
);

class AttributeNameComp extends React.Component {

    render() {
        let attributeName = this.props.attributeName;
        let shortenedData = getShortTitleForAttribute(attributeName);
        let shortenedName = shortenedData["requiredName"]
        let attributeTooltip = shortenedData["fqTooltipContent"];
        if (attributeTooltip === null) {
            // Tooltip is not needed. So we can simply 
            // return attribute name to render
            return shortenedName;
        }

        let tooltipPlacement = this.props.tooltipPlacement;
        if (tooltipPlacement === undefined) {
            tooltipPlacement = "top";
        }
        // Render attribute name with short name 
        // along with full name in tooltip
        return (
            <>
                <RcTooltip 
                    placement={tooltipPlacement}
                    overlay={renderAttributeNameToolTip(attributeTooltip)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <span> {FQ_TOOLTIP_PLACEHOLDER} </span>
                </RcTooltip>
                
                {shortenedName}
            </>


        );
    }
}

export default AttributeNameComp;
