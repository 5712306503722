import React from 'react';
import Image from '../../components/image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faDatabase, faTag, faClock, faCheck, faServer, faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faClock as regularClock } from "@fortawesome/fontawesome-free-regular";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';
// import $ from 'jquery';
import { timeRange, WEEKDAYS } from '../metric_utils/constants';
import DropdownTreeSelect from "react-dropdown-tree-select";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { postBulkScheduleData, postUIlogs } from '../../utils/metricEventHandling';
// import ConfirmationModal from './components/ConfirmationModal';
// import { toastObj } from '../utils/common_utils';
// import Toast from 'react-bootstrap/Toast';
// import RcTooltip from 'rc-tooltip';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#!"
        className="btn btn-blue btn-circle dropdown-toggle"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <i className="mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="d-inline-block" width="20" height="20" viewBox="0 0 20 20">
                <g id="Group_4431" data-name="Group 4431" transform="translate(-956 -921)">
                    <g id="icon_insert_chart" transform="translate(957.667 922.667)">
                        <path id="Path_2023" data-name="Path 2023" d="M2.966,16.319a1.528,1.528,0,0,0,1.121.466H9.873a5.59,5.59,0,0,1,.01-3.207.853.853,0,0,1-.24.033.779.779,0,0,1-.794-.794V6.468a.794.794,0,1,1,1.587,0v5.867a5.585,5.585,0,0,1,1.587-1.7V8.055a.794.794,0,1,1,1.587,0V9.873a5.59,5.59,0,0,1,3.175,0V4.087A1.592,1.592,0,0,0,15.2,2.5H4.087A1.592,1.592,0,0,0,2.5,4.087V15.2A1.528,1.528,0,0,0,2.966,16.319ZM5.9,8.284a.794.794,0,0,1,1.359.565v3.968a.794.794,0,1,1-1.587,0V8.849A.768.768,0,0,1,5.9,8.284Z" transform="translate(-2.5 -2.5)" fill="#fff" fillRule="evenodd" />
                        <path id="Path_2024" data-name="Path 2024" d="M16.032,15.475V14.047a.4.4,0,1,0-.794,0v1.577a.411.411,0,0,0,.03.154.4.4,0,0,0,.089.134l1.31,1.31a.393.393,0,0,0,.556-.556Zm-.4,4.127a3.863,3.863,0,0,1-1.548-.312,3.962,3.962,0,0,1-2.108-2.108,3.989,3.989,0,0,1,0-3.1,3.962,3.962,0,0,1,2.108-2.108,3.989,3.989,0,0,1,3.1,0,3.962,3.962,0,0,1,2.108,2.108,3.989,3.989,0,0,1,0,3.1,3.962,3.962,0,0,1-2.108,2.108A3.864,3.864,0,0,1,15.635,19.6Z" transform="translate(-2.937 -2.937)" fill="#fff" />
                    </g>
                    <rect id="Rectangle_631" data-name="Rectangle 631" width="20" height="20" transform="translate(956 921)" fill="none" />
                </g>
            </svg>
        </i>
        Bulk Schedule Metrics
    </a>
));

let nowDate;
let nowDay;
export default class BulkSchedule extends React.Component {
    constructor(props) {
        super(props);


        this.postUIlogs = postUIlogs.bind(this);
        this.showToast = this.props.showToast.bind(this);

        this.handleToggle = this.handleToggle.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.removeChipData = this.removeChipData.bind(this);

        this.confirmationBulkSchedule = this.confirmationBulkSchedule.bind(this);
        this.closeBulkSchedule = this.closeBulkSchedule.bind(this);
        this.returnBulkSchedule = this.returnBulkSchedule.bind(this);
        this.saveBulkSchedule = this.saveBulkSchedule.bind(this);
        this.postBulkScheduleData = postBulkScheduleData.bind(this);
        this.getMetricsMetadata = this.props.getMetricsMetadata.bind(this);


        let startDate = moment().set({ "hour": 0, "minute": 0, "seconds": 0 });
        let now = new Date();
        let day = now.getDay();
        let currentDay = WEEKDAYS[day]
        nowDate = startDate
        nowDay = currentDay

        this.state = {
            isOpen: false,
            environments: this.props.filterData['environments'],
            selectedEnv: [],
            integrations: this.props.filterData['integrations'].filter((data) => this.props.existingIntegrations.includes(data.value)),
            applicableIntegrations: [],
            selectedIntegration: [],
            datasets: this.props.filterData['datasets'],
            applicableDatasets: [],
            selectedDatasets: [],
            currentDay: currentDay,
            startDate: startDate,
            timeRange: timeRange,
            defaultSchedule: true,
            selectedScheduleTime: [],
            recurrence: { "label": "Daily", "value": "Daily" },
            recurrenceList: [
                // { "label": "Does not repeat", "value": "Does not repeat" },
                { "label": "Daily", "value": "Daily" },
                { "label": "Weekly on " + currentDay, "value": "Weekly on " + currentDay },
                { "label": "Every Weekday(Monday to Friday)", "value": "Every Weekday(Monday to Friday)" },
                { "label": "Custom", "value": "Custom" }
            ],
            weekdaysClasses: "",
            selectedScheduleDay: WEEKDAYS,
            color: "Blue",
            bulkScheduleConfirmation: false,
            selectedAttributeCount: 0,
            chipData: [],
            sleepInterval: this.props.sleepInterval,
            customTimeSchedule: false
        }
    }

    handleToggle() {
        this.setState({ isOpen: !this.state.isOpen })
    };

    handleTimeFilter(event, picker) {
        let startDate = picker.startDate;
        const now = new Date(startDate)
        const day = now.getDay();
        let selectedDay = WEEKDAYS[day]

        let replaceLable = { "label": "Weekly on " + selectedDay, "value": "Weekly on " + selectedDay }

        let recurrenceList = this.state.recurrenceList
        recurrenceList[1] = replaceLable

        this.setState({
            startDate: startDate,
            currentDay: selectedDay,
            recurrenceList: recurrenceList,
            recurrence: replaceLable
        });
    }

    removeChipData(data) {
        // Removing the corresponding label from chipData
        let chipData = this.state.chipData.filter((value) => value.label !== data.label)

        // Removing the corresponding time from selectedScheduleTime
        let selectedScheduleTime = this.state.selectedScheduleTime.filter((value) => value !== data.value)

        // Uncheck the corresponding time in timerange data
        let updateTimeRange = []
        this.state.timeRange.map((value) => {
            if (value.value === data.value) {
                value['checked'] = false
            }
            updateTimeRange.push(value)
            return null
        })

        this.setState({ chipData: chipData, selectedScheduleTime: selectedScheduleTime, timeRange: updateTimeRange })
    }


    confirmationBulkSchedule() {
        // $(".bulk-schedule-dropdown dropdown-menu show dropdown-menu-right").addClass("d-none")
        // $(".btn btn-outline").trigger("click");
        this.setState({
            isOpen: !this.state.isOpen,
            bulkScheduleConfirmation: !this.state.bulkScheduleConfirmation
        })
    }

    closeBulkSchedule() {
        let startDate = nowDate

        let timeRange = this.state.timeRange.map(data => ({
            ...data,
            checked: false
        })
        )

        this.setState({
            isOpen: false,
            selectedEnv: [],
            selectedIntegration: [],
            selectedDatasets: [],
            selectedScheduleTime: [],
            startDate: startDate,
            currentDay: nowDay,
            recurrence: { "label": "Daily", "value": "Daily" },
            selectedScheduleDay: [],
            weekdaysClasses: {},
            chipData: [],
            timeRange: timeRange,
            applicableDatasets: [],
            applicableIntegrations: [],
            defaultSchedule: true,
            customTimeSchedule: false
        });
    };

    returnBulkSchedule() {
        // $(".btn btn-outline").trigger("click");
        this.setState({
            isOpen: !this.state.isOpen,
            bulkScheduleConfirmation: !this.state.bulkScheduleConfirmation
        })
    }


    saveBulkSchedule(resetForm) {
        let tenant_id = Number(localStorage.getItem('team_id'))
        let selectedScheduleDay = {}
        let scheduledDays = this.state.selectedScheduleDay

        if (scheduledDays.length === 0) {
            scheduledDays = WEEKDAYS
        }

        scheduledDays.map((day) => {
            return selectedScheduleDay[day] = this.state.selectedScheduleTime
        })


        let repeat_on = this.state.recurrence

        if (this.state.defaultSchedule === true) {
            selectedScheduleDay = []
        }

        let data = {
            tenant_id: tenant_id,
            env_id: this.state.selectedEnv.value,
            integration_id: this.state.selectedIntegration.value,
            data_set_id: this.state.selectedDatasets,
            scheduling_time: selectedScheduleDay,
            repeat_on: repeat_on,
            defaultSchedule: this.state.defaultSchedule,
            color: this.state.color,
            // individualDataset : true
        }
        this.postBulkScheduleData(data, resetForm)
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidMount() {

    }

    render() {
        let validateschema = null;
        let chipData = this.state.chipData
        let selectedScheduleDay = this.state.selectedScheduleDay
        validateschema = yup.object({
            selectedEnv: yup.string().required('Please select an Environment').test(
                'Please Choose an Environment',
                'Please Choose an Environment',
                function test(value) {
                    console.log("Environment", value)
                    if (value === "Error") {
                        return false
                    }
                    return value
                }
            ),
            selectedIntegration: yup.string().required('Please select an Datasource').test(
                'Please Choose an Datasource',
                'Please Choose an Datasource',
                function test(value) {
                    console.log("Datasource", value)
                    if (value === "Error") {
                        return false
                    }
                    return value
                }
            ),
            selectedDatasets: yup.string().required('Please Choose an Datsets').test(
                'Please Choose an Datsets',
                'Please Choose an Datsets',
                function test(value) {
                    console.log("Datsets", value)
                    if (value === "Error") {
                        return false
                    }
                    return value
                }
            ),
            selectedScheduleTime: yup.string().required('Please Choose an Time').test(
                'Please Choose an Time',
                'Please Choose an Time',

                function test(value) {
                    console.log("Time", value)
                    if (value === "Error" || chipData.length === 0) {
                        return false
                    }
                    return value
                }
            ),
        });

        if (this.state.defaultSchedule === true) {
            validateschema = yup.object({
                selectedEnv: yup.string().required('Please select an Environment').test(
                    'Please Choose an Environment',
                    'Please Choose an Environment',
                    function test(value) {
                        console.log("Environment", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
                selectedIntegration: yup.string().required('Please select an Datasource').test(
                    'Please Choose an Datasource',
                    'Please Choose an Datasource',
                    function test(value) {
                        console.log("Datasource", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
                selectedDatasets: yup.string().required('Please Choose an Datsets').test(
                    'Please Choose an Datsets',
                    'Please Choose an Datsets',
                    function test(value) {
                        console.log("Datsets", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
            });
        }

        if (this.state.recurrence['label'] === "Custom") {
            validateschema = yup.object({
                selectedEnv: yup.string().required('Please select an Environment').test(
                    'Please Choose an Environment',
                    'Please Choose an Environment',
                    function test(value) {
                        console.log("Environment", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
                selectedIntegration: yup.string().required('Please select an Datasource').test(
                    'Please Choose an Datasource',
                    'Please Choose an Datasource',
                    function test(value) {
                        console.log("Datasource", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
                selectedDatasets: yup.string().required('Please Choose an Datsets').test(
                    'Please Choose an Datsets',
                    'Please Choose an Datsets',
                    function test(value) {
                        console.log("Datsets", value)
                        if (value === "Error") {
                            return false
                        }
                        return value
                    }
                ),
                selectedScheduleTime: yup.string().required('Please Choose an Time').test(
                    'Please Choose an Time',
                    'Please Choose an Time',
                    function test(value) {
                        console.log("Time", value)
                        if (value === "Error" || chipData.length === 0) {
                            return false
                        }
                        return value
                    }
                ),
                recurrence: yup.string().required('Please Choose a Day').test(
                    'Please Choose an day',
                    'Please Choose a day',
                    function test(value) {
                        if (value === "Error" || selectedScheduleDay.length === 0) {
                            return false
                        }
                        return value
                    }
                ),
            });

        }

        let initial_values = this.state;

        return (
            <>
                <Formik
                    validationSchema={validateschema}
                    initialValues={initial_values}
                    onSubmit={this.confirmationBulkSchedule}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        resetForm,
                        dirty
                    }) => (
                        <Form>
                            <Dropdown
                                show={this.state.isOpen}
                                onToggle={this.handleToggle}
                                alignRight
                                drop='down'
                                className="bulk-schedule-metrics-dropdown hide-dropdown-arrow">
                                <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="dropdown-custom-components">
                                    Custom toggle
                                </Dropdown.Toggle>

                                {this.state.isOpen &&
                                    <Dropdown.Menu className="bulk-schedule-dropdown" menuAlign="right" >
                                        <div className="dropdown-menu__head">
                                            <div className="title">
                                                <h3 className="caption">Bulk Schedule Metrics</h3>
                                            </div>
                                        </div>
                                        <div className="dropdown-menu__main">
                                            <div className="form-line-input">
                                                <div className="form-line-input__prepend">
                                                    <i><FontAwesomeIcon icon={faServer} /></i>
                                                </div>
                                                <Select
                                                    id="selectedEnv"
                                                    name="selectedEnv"
                                                    className='form-control'
                                                    classNamePrefix='select-control'
                                                    options={this.state.environments}
                                                    placeholder="Choose Environment"
                                                    value={this.state.selectedEnv}
                                                    onChange={(e) => {
                                                        handleChange("selectedEnv")(String(e.value));
                                                        this.setState({
                                                            selectedEnv: { "label": e.label, "value": e.value },
                                                            selectedIntegration: [],
                                                            applicableDatasets: [],
                                                            selectedDatasets: [],
                                                            applicableIntegrations: this.state.integrations.filter((data) => data.env_id === e.value)
                                                        })
                                                    }}

                                                    onBlur={handleBlur}
                                                // isInvalid={errors.region && touched.region}
                                                />
                                            </div>
                                            <ErrorMessage component="div" className="error-text" name="selectedEnv" />


                                            <div className="form-line-input">
                                                <div className="form-line-input__prepend">
                                                    <i><FontAwesomeIcon icon={faDatabase} /></i>
                                                </div>
                                                <Select
                                                    options={this.state.applicableIntegrations}
                                                    id="selectedIntegration"
                                                    name="selectedIntegration"
                                                    className='form-control'
                                                    classNamePrefix='select-control'
                                                    placeholder="Choose datasource"
                                                    value={this.state.selectedIntegration}
                                                    onChange=
                                                    {(e) => {
                                                        handleChange("selectedIntegration")(String(e.value))
                                                        let applicableDatasets = [{ "label": "All", "value": "checked_all" }]
                                                        this.state.datasets.filter((data) => data.is_child_dataset !== true && data.integration_id === e.value && applicableDatasets.push(data))

                                                        applicableDatasets = applicableDatasets.map((data) => ({
                                                            ...data,
                                                            checked: false
                                                        }))

                                                        this.setState({
                                                            selectedIntegration: { "label": e.label, "value": e.value },
                                                            selectedDatasourceId: { "label": e.label, "value": e.value },
                                                            selectedDatasourceName: { "label": e.label, "value": e.value },
                                                            selectedDatasets: [],
                                                            applicableDatasets: applicableDatasets,
                                                        })
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            <ErrorMessage component="div" className="error-text" name="selectedIntegration" />

                                            <div className="form-line-input">
                                                <div className="form-line-input__prepend">
                                                    <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                                                </div>
                                                <DropdownTreeSelect

                                                    id="selectedDatasets"
                                                    name="selectedDatasets"
                                                    // className="w-100"
                                                    className="form-control w-100"
                                                    classNamePrefix='select-control'
                                                    data={this.state.applicableDatasets}
                                                    onChange={(currentNode) => {

                                                        let selectedDatasets = this.state.selectedDatasets
                                                        let applicableDatasets = this.state.applicableDatasets
                                                        if (currentNode['checked'] === true) {
                                                            if (currentNode.value === "checked_all") {
                                                                applicableDatasets = applicableDatasets.map((data) => ({
                                                                    ...data,
                                                                    checked: true
                                                                }))
                                                                selectedDatasets = applicableDatasets.map((data) => {
                                                                    if (data["value"] !== "checked_all") {
                                                                        return data["value"]
                                                                    }
                                                                    return null
                                                                })
                                                            }
                                                            else {
                                                                applicableDatasets = applicableDatasets.map((data) => {
                                                                    if (data['value'] === currentNode.value) {
                                                                        data['checked'] = true
                                                                    }
                                                                    return data
                                                                })
                                                                selectedDatasets.push(currentNode.value)
                                                            }
                                                        }
                                                        else {
                                                            if (currentNode.value === "checked_all") {
                                                                applicableDatasets = applicableDatasets.map(data => ({
                                                                    ...data,
                                                                    checked: false
                                                                }))
                                                                selectedDatasets = []
                                                            }
                                                            else {
                                                                selectedDatasets = this.state.selectedDatasets.filter((data) => data !== currentNode.value)
                                                                applicableDatasets[0]["checked"] = false
                                                                applicableDatasets = applicableDatasets.map((data) => {
                                                                    if (data['value'] === currentNode.value) {
                                                                        data['checked'] = false
                                                                    }
                                                                    return data
                                                                    // ...data,
                                                                    // checked : false &&
                                                                })
                                                            }
                                                        }
                                                        selectedDatasets = selectedDatasets.filter(data => (data !== undefined && data !== null))

                                                        if (currentNode.checked === false && selectedDatasets.length === 0) {
                                                            handleChange("selectedDatasets")("Error")
                                                        }
                                                        else {
                                                            handleChange("selectedDatasets")(String(currentNode.value))
                                                        }
                                                        let selectedAttributeCount = this.state.applicableDatasets.map((data) => {
                                                            if (selectedDatasets.includes(data.value)) {
                                                                return data['attribute_ids'].length
                                                            }
                                                            return 0
                                                        })

                                                        let checkAll = []
                                                        checkAll = applicableDatasets.filter((data) => data.checked !== true)
                                                        if (checkAll.length === 1) {
                                                            applicableDatasets[0]["checked"] = true
                                                        }
                                                        else if (checkAll.length > 1) {
                                                            applicableDatasets[0]["checked"] = false
                                                        }
                                                        selectedAttributeCount = selectedAttributeCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                                        this.setState({ applicableDatasets: applicableDatasets, selectedDatasets: selectedDatasets, selectedAttributeCount: selectedAttributeCount })
                                                    }}
                                                    texts={{ placeholder: this.state.selectedDatasets.length === 0 ? 'Choose Dataset' : this.state.selectedDatasets.length + ' dataset selected' }}

                                                />
                                            </div>
                                            <ErrorMessage component="div" className="error-text" name="selectedDatasets" />

                                            <div className="form-line-input align-items-start mt-3">
                                                <div className="form-line-input__prepend">
                                                    <i><FontAwesomeIcon icon={regularClock} /></i>
                                                </div>
                                                <div>
                                                    <div class="custom-control custom-control-inline custom-radio flex-shrink-0 mb-2">
                                                        <input
                                                            type="radio"
                                                            class="custom-control-input"
                                                            name="scheduleTimeInputs"
                                                            checked={this.state.customTimeSchedule}
                                                            onChange={() => {
                                                                this.setState({
                                                                    customTimeSchedule: true,
                                                                    defaultSchedule: false
                                                                })
                                                            }
                                                            }
                                                            id="chooseTime" />
                                                        <label class="custom-control-label d-flex align-items-center" for="chooseTime">
                                                            Choose Time
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-control-inline custom-radio flex-shrink-0 mb-2">
                                                        <input
                                                            type="radio"
                                                            defaultChecked={this.state.defaultSchedule}
                                                            checked={this.state.defaultSchedule}
                                                            name="scheduleTimeInputs"
                                                            onChange=
                                                            {(e) => {
                                                                this.setState({
                                                                    // chipData : [],
                                                                    recurrence: { label: "Daily", value: "Daily" },
                                                                    customTimeSchedule: false,
                                                                    defaultSchedule: true
                                                                })
                                                            }}
                                                            class="custom-control-input" id="allDay" />
                                                        <label class="custom-control-label d-flex align-items-center" for="allDay">
                                                            Default Scanning
                                                        </label>
                                                    </div>
                                                    {this.state.customTimeSchedule === true ?
                                                        <>
                                                            <div className="weekday-time-input">
                                                                <>
                                                                    <DropdownTreeSelect
                                                                        id="selectedScheduleTime"
                                                                        name="selectedScheduleTime"
                                                                        type="timeSchedule"
                                                                        data={this.state.timeRange}
                                                                        onChange={(currentNode) => {
                                                                            let selectedScheduleTime = this.state.selectedScheduleTime
                                                                            let chipData = this.state.chipData
                                                                            let updateTimeRange = []
                                                                            if (currentNode['checked'] === true) {
                                                                                selectedScheduleTime.push(currentNode.value)

                                                                                let chipDict = { "value": currentNode.value, "label": currentNode.label }
                                                                                chipData.push(chipDict)
                                                                                this.state.timeRange.map((data) => {
                                                                                    if (currentNode.value === data.value) {
                                                                                        data['checked'] = true
                                                                                    }
                                                                                    updateTimeRange.push(data)
                                                                                    return null
                                                                                })
                                                                            }
                                                                            else {
                                                                                selectedScheduleTime = this.state.selectedScheduleTime.filter((data) => data !== currentNode.value)
                                                                                chipData = this.state.chipData.filter((data) => data.label !== currentNode.label)

                                                                                this.state.timeRange.map((data) => {
                                                                                    if (currentNode.value === data.value) {
                                                                                        data['checked'] = false
                                                                                    }
                                                                                    updateTimeRange.push(data)
                                                                                    return null
                                                                                })

                                                                            }
                                                                            if ((currentNode.checked === false && selectedScheduleTime.length === 0) || (this.state.selectedScheduleTime.length === 0)) {
                                                                                handleChange("selectedScheduleTime")("Error")
                                                                            }
                                                                            else {
                                                                                handleChange("selectedScheduleTime")(String(currentNode.value))
                                                                            }

                                                                            this.setState({ selectedScheduleTime: selectedScheduleTime, timeRange: updateTimeRange, chipData: chipData })
                                                                        }}
                                                                        texts={{ placeholder: this.state.selectedScheduleTime.length === 0 ? "Choose Time" : this.state.selectedScheduleTime.length + " Time selected" }}
                                                                    />
                                                                    <span className="text-muted ml-1 timeZone">(GMT)</span>
                                                                </>
                                                                {/* } */}
                                                            </div>

                                                            {this.state.defaultSchedule === false &&
                                                                <ErrorMessage component="div" className="error-text" name="selectedScheduleTime" />
                                                            }
                                                        </>
                                                        : " "}
                                                    <div className="metric-schedule-time-chips">
                                                        {this.state.defaultSchedule === true
                                                            // && this.state.chipData.length === 0
                                                            ?
                                                            <>
                                                                <div className="metric-schedule-time-chip">
                                                                    <span className="metric-schedule-time-text">{this.state.sleepInterval + " Minutes once"}</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={
                                                                        <Popover className="flush-popover">
                                                                            <Popover.Title as="h3">Scan Interval Time</Popover.Title>
                                                                            <Popover.Content>
                                                                                The <strong>Scan Interval Time</strong> has already been set in the configure screen. To modify the <strong>Scan Interval Time</strong>, please navigate to the configure screen.
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <svg width="16" height="16" className="mb-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.4 11H8.6V7.4H7.4V11ZM8 6.2C8.17 6.2 8.3125 6.1425 8.4275 6.0275C8.5425 5.9125 8.6 5.77 8.6 5.6C8.6 5.43 8.5425 5.2875 8.4275 5.1725C8.3125 5.0575 8.17 5 8 5C7.83 5 7.6875 5.0575 7.5725 5.1725C7.4575 5.2875 7.4 5.43 7.4 5.6C7.4 5.77 7.4575 5.9125 7.5725 6.0275C7.6875 6.1425 7.83 6.2 8 6.2ZM8 14C7.17 14 6.39 13.8425 5.66 13.5275C4.93 13.2125 4.295 12.785 3.755 12.245C3.215 11.705 2.7875 11.07 2.4725 10.34C2.1575 9.61 2 8.83 2 8C2 7.17 2.1575 6.39 2.4725 5.66C2.7875 4.93 3.215 4.295 3.755 3.755C4.295 3.215 4.93 2.7875 5.66 2.4725C6.39 2.1575 7.17 2 8 2C8.83 2 9.61 2.1575 10.34 2.4725C11.07 2.7875 11.705 3.215 12.245 3.755C12.785 4.295 13.2125 4.93 13.5275 5.66C13.8425 6.39 14 7.17 14 8C14 8.83 13.8425 9.61 13.5275 10.34C13.2125 11.07 12.785 11.705 12.245 12.245C11.705 12.785 11.07 13.2125 10.34 13.5275C9.61 13.8425 8.83 14 8 14ZM8 12.8C9.34 12.8 10.475 12.335 11.405 11.405C12.335 10.475 12.8 9.34 12.8 8C12.8 6.66 12.335 5.525 11.405 4.595C10.475 3.665 9.34 3.2 8 3.2C6.66 3.2 5.525 3.665 4.595 4.595C3.665 5.525 3.2 6.66 3.2 8C3.2 9.34 3.665 10.475 4.595 11.405C5.525 12.335 6.66 12.8 8 12.8Z" fill="#B3B2BD" />
                                                                    </svg>
                                                                </OverlayTrigger>
                                                            </>
                                                            :
                                                            this.state.chipData.map((data) => {
                                                                return (
                                                                    <>
                                                                        <div className="metric-schedule-time-chip">

                                                                            <span className="metric-schedule-time-text">{data.label}</span>
                                                                            <i
                                                                                className="metric-schedule-time-action"
                                                                                value={data}
                                                                                onClick={(e) => {
                                                                                    this.removeChipData(data)
                                                                                }}
                                                                            >
                                                                                <svg width="16" height="16" class="d-block" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M4.26634 12.9173L3.33301 11.984L7.06634 8.25065L3.33301 4.51732L4.26634 3.58398L7.99967 7.31732L11.733 3.58398L12.6663 4.51732L8.93301 8.25065L12.6663 11.984L11.733 12.9173L7.99967 9.18398L4.26634 12.9173Z" fill="currentColor" />
                                                                                </svg>
                                                                            </i>
                                                                        </div>

                                                                    </>

                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </div>

                                            {this.state.defaultSchedule !== true &&
                                                <div className="form-line-input">
                                                    <div className="form-line-input__prepend">
                                                        <i>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                                <g id="icon_repeat" transform="translate(-881 -905)">
                                                                    <path id="icon_repeat-2" data-name="icon_repeat" d="M6.556,18.088l.8.8a.862.862,0,0,1,.271.659.992.992,0,0,1-.271.659.959.959,0,0,1-.671.294.876.876,0,0,1-.671-.271L3.592,17.806a.826.826,0,0,1-.2-.306,1.088,1.088,0,0,1,0-.706.826.826,0,0,1,.2-.306l2.423-2.423a.876.876,0,0,1,.671-.27.959.959,0,0,1,.671.294.992.992,0,0,1,.271.659.862.862,0,0,1-.271.659l-.8.8h9.552V13.382a.941.941,0,1,1,1.882,0v2.823a1.888,1.888,0,0,1-1.882,1.882ZM16.25,6.794H6.7V9.618a.948.948,0,0,1-1.612.671.911.911,0,0,1-.271-.671V6.794A1.888,1.888,0,0,1,6.7,4.912H16.25l-.8-.8a.863.863,0,0,1-.271-.659.993.993,0,0,1,.271-.659.96.96,0,0,1,.671-.294.876.876,0,0,1,.671.271l2.423,2.423a.827.827,0,0,1,.2.306,1.089,1.089,0,0,1,0,.706.826.826,0,0,1-.2.306L16.791,8.935a.876.876,0,0,1-.671.271.96.96,0,0,1-.671-.294.993.993,0,0,1-.271-.659.863.863,0,0,1,.271-.659Z" transform="translate(881.667 905.5)" fill="#99a1b7" />
                                                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="24" height="24" transform="translate(881 905)" fill="none" />
                                                                </g>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <Select
                                                        options={this.state.recurrenceList}
                                                        id="recurrence"
                                                        name="recurrence"
                                                        className='form-control custom-select-control'
                                                        classNamePrefix='select-control'
                                                        placeholder="Does not repeat"
                                                        value={this.state.recurrence}
                                                        isDisabled={this.state.defaultSchedule}
                                                        onChange=
                                                        {(e) => {
                                                            let selectedScheduleDay = []

                                                            if (e.value === "Daily") {
                                                                selectedScheduleDay = WEEKDAYS
                                                            }
                                                            else if (e.value === "Every Weekday(Monday to Friday)") {
                                                                selectedScheduleDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
                                                            }
                                                            else if (e.value === "Weekly on " + this.state.currentDay) {
                                                                selectedScheduleDay = [this.state.currentDay]
                                                            }

                                                            if (selectedScheduleDay.length === 0) {
                                                                handleChange("recurrence")("Error")
                                                            }
                                                            else {
                                                                handleChange("recurrence")(String(e.value))
                                                            }

                                                            this.setState({ recurrence: e, selectedScheduleDay: selectedScheduleDay })
                                                        }}


                                                    />

                                                </div>

                                            }
                                            {this.state.recurrence['label'] === "Custom"
                                                &&
                                                <div className="form-line-input align-items-start">
                                                    <div className="form-line-input__prepend">
                                                        <i><FontAwesomeIcon icon={faCalendarDay} /></i>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <span className="mb-2">Repeat on</span>
                                                        <div className="weekday-picker">
                                                            <div className="weekday-picker__cols">
                                                                {WEEKDAYS.map((day) => (
                                                                    <div
                                                                        className={this.state.weekdaysClasses[day] !== undefined ? "weekday-picker__col" + this.state.weekdaysClasses[day] : "weekday-picker__col"}
                                                                        onClick={(e) => {
                                                                            let selectedScheduleDay = this.state.selectedScheduleDay

                                                                            let weekdaysClasses = {};
                                                                            if (selectedScheduleDay.includes(day)) {
                                                                                selectedScheduleDay = selectedScheduleDay.filter(data => data !== day)
                                                                            }
                                                                            else {
                                                                                selectedScheduleDay.push(day)
                                                                            }

                                                                            selectedScheduleDay.map((day) => {
                                                                                weekdaysClasses[day] = ''
                                                                                weekdaysClasses[day] = " --is-selected"
                                                                                return null
                                                                            }
                                                                            )

                                                                            if (selectedScheduleDay.length === 0) {
                                                                                handleChange("recurrence")("Error")
                                                                            }
                                                                            else {
                                                                                handleChange("recurrence")(String(day))
                                                                            }
                                                                            this.setState({ selectedScheduleDay: selectedScheduleDay, weekdaysClasses: weekdaysClasses })
                                                                        }
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={"weekday-picker__label"}
                                                                        >
                                                                            {day.split("")[0]}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {this.state.defaultSchedule === false &&
                                                                <ErrorMessage component="div" className="error-text" name="recurrence" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="dropdown-menu__footer justify-content-between">
                                            <button
                                                type="button"
                                                class="btn btn-link"
                                                onClick={
                                                    (e) => {
                                                        let startDate = nowDate
                                                        let timeRange = this.state.timeRange.map(data => ({
                                                            ...data,
                                                            checked: false
                                                        })
                                                        )

                                                        this.setState({
                                                            selectedEnv: [],
                                                            selectedIntegration: [],
                                                            selectedDatasets: [],
                                                            selectedScheduleTime: [],
                                                            startDate: startDate,
                                                            currentDay: nowDay,
                                                            recurrence: { "label": "Daily", "value": "Daily" },
                                                            selectedScheduleDay: [],
                                                            weekdaysClasses: {},
                                                            timeRange: timeRange,
                                                            chipData: [],
                                                            applicableDatasets: [],
                                                            applicableIntegrations: [],
                                                            defaultSchedule: true,
                                                            customTimeSchedule: false
                                                        })
                                                        resetForm();
                                                    }
                                                }
                                            >Reset
                                            </button>
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={
                                                        (e) => {
                                                            setTimeout(() => {
                                                                resetForm();
                                                            }, 100);
                                                            this.closeBulkSchedule();
                                                        }
                                                    }
                                                    class="btn btn-outline btn-grey btn-circle mr-2">
                                                    Cancel
                                                </button>
                                                <button disabled={!dirty} type="button" onClick={handleSubmit} class="btn btn-primary btn-circle"><i className="mr-1"><FontAwesomeIcon icon={faCheck} /></i>Save</button>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                }
                            </Dropdown>

                            {/* Bulk Schedule confirmation*/}
                            <Modal className="schedule-metric-alert" show={this.state.bulkScheduleConfirmation} centered>
                                <button
                                    type="button"
                                    class="btn btn-link"
                                    onClick={
                                        (e) => {
                                            this.returnBulkSchedule();
                                        }
                                    }
                                >
                                    <i className="mr-2"

                                    >
                                        <svg width="24" height="24" className="d-inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.80078 13.0008L9.70078 15.9008C9.88411 16.0841 9.97578 16.3174 9.97578 16.6008C9.97578 16.8841 9.88411 17.1174 9.70078 17.3008C9.51745 17.4841 9.28411 17.5758 9.00078 17.5758C8.71745 17.5758 8.48411 17.4841 8.30078 17.3008L3.70078 12.7008C3.60078 12.6008 3.52995 12.4924 3.48828 12.3758C3.44661 12.2591 3.42578 12.1341 3.42578 12.0008C3.42578 11.8674 3.44661 11.7424 3.48828 11.6258C3.52995 11.5091 3.60078 11.4008 3.70078 11.3008L8.30078 6.70078C8.48411 6.51745 8.71745 6.42578 9.00078 6.42578C9.28411 6.42578 9.51745 6.51745 9.70078 6.70078C9.88411 6.88411 9.97578 7.11745 9.97578 7.40078C9.97578 7.68411 9.88411 7.91745 9.70078 8.10078L6.80078 11.0008H20.0008C20.2841 11.0008 20.5216 11.0966 20.7133 11.2883C20.9049 11.4799 21.0008 11.7174 21.0008 12.0008C21.0008 12.2841 20.9049 12.5216 20.7133 12.7133C20.5216 12.9049 20.2841 13.0008 20.0008 13.0008H6.80078Z" fill="#969696" />
                                        </svg>
                                    </i>
                                    Back
                                </button>
                                <Modal.Body>
                                    <div className="schedule-metric-alert__icon">
                                        <Image src="icon_add_event_danger" alt="" />
                                    </div>
                                    <h4>Are you sure you want to Bulk schedule for?</h4>
                                    <div className="schedule-metric-alert__box">
                                        <div className="schedule-metric-list">
                                            <div className="schedule-metric-list__item">
                                                <i className="schedule-metric-list__icon text-danger">
                                                    <FontAwesomeIcon icon={faClock} />
                                                </i>
                                                <div className="schedule-metric-list__content">
                                                    <h5>Schedule</h5>
                                                    <p>This schedule will be applied to the selected datasets and all its attributes for the configured metrics.</p>
                                                </div>
                                            </div>
                                            <div className="schedule-metric-list__item">
                                                <i className="schedule-metric-list__icon text-danger">
                                                    <FontAwesomeIcon icon={faLayerGroup} />
                                                </i>
                                                <div className="schedule-metric-list__content">
                                                    <h5>Datasets</h5>
                                                    <p>The schedule will be applied to {this.state.selectedDatasets.length} datasets</p>
                                                </div>
                                            </div>
                                            <div className="schedule-metric-list__item">
                                                <i className="schedule-metric-list__icon text-danger">
                                                    <FontAwesomeIcon icon={faTag} />
                                                </i>
                                                <div className="schedule-metric-list__content">
                                                    <h5>Attributes</h5>
                                                    <p>The schedule will also applied to {this.state.selectedAttributeCount} attributes across the selected datasets</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <p>Clicking <strong>"Confirm"</strong> will immediately apply the schedule to the selected dataset and attributes.
                                        {/*     You'll also receive alerts through your configured alert system. */}
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block btn-circle mb-2"
                                        onClick={(e) => { this.saveBulkSchedule(resetForm) }}>
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-block btn-circle btn-light-gray"
                                        onClick={
                                            (e) => {
                                                // this.returnBulkSchedule();
                                                this.setState({
                                                    bulkScheduleConfirmation: !this.state.bulkScheduleConfirmation
                                                })
                                                this.closeBulkSchedule(resetForm);
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            {/* End Bulk Schedule confirmation*/}

                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}
