import React from 'react';
import Image from '../../components/image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faDatabase, faClock, faEllipsisV, faCheck, faServer, faCalendarDay, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { faClock as regularClock } from "@fortawesome/fontawesome-free-regular";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import $ from 'jquery';
import { metricClassMapping, timeRange, WEEKDAYS, REGEX_METRIC, METRIC_ORDER, DEFAULT_CHECKS } from '../metric_utils/constants';
import DropdownTreeSelect from "react-dropdown-tree-select";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import { postBulkScheduleData, putThresholdData, postToggleAction, postUIlogs } from '../../utils/metricEventHandling';
// import ConfirmationModal from './components/ConfirmationModal';
// import { toastObj } from '../utils/common_utils';
// import Toast from 'react-bootstrap/Toast';
import RcTooltip from 'rc-tooltip';
import { DATA_CONSISTENCY_METRICS } from '../../utils/constant';
// import { sortData } from './metric_utils/common_utils'
// import { Formik, ErrorMessage } from 'formik';
// import * as yup from 'yup';
import _ from 'lodash';

// let nowDate;
// let nowDay;
export default class DatasetToggle extends React.Component {
    constructor(props) {
        super(props);

        this.postUIlogs = postUIlogs.bind(this);

        this.postToggleAction = postToggleAction.bind(this);
        this.popoverAction = this.popoverAction.bind(this);
        this.handleActiveIcon = this.handleActiveIcon.bind(this);
        this.datasetPopover = this.datasetPopover.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
        this.putThresholdData = putThresholdData.bind(this);
        this.postBulkScheduleData = postBulkScheduleData.bind(this);
        this.updateThresholdData = this.updateThresholdData.bind(this);
        this.closeAlertmodel = this.closeAlertmodel.bind(this);
        this.goToMetricPage = this.goToMetricPage.bind(this);
        this.returnDatasetSchedule = this.returnDatasetSchedule.bind(this);
        this.closeUpdateThreshold = this.closeUpdateThreshold.bind(this);
        this.handleRemoveActiveIcon = this.handleRemoveActiveIcon.bind(this);

        this.handleSort = this.handleSort.bind(this);
        // this.sortAttributes = this.props.sortAttributes.bind(this);

        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.confirmationDatasetSchedule = this.confirmationDatasetSchedule.bind(this)

        this.getMetricsMetadata = this.props.getMetricsMetadata.bind(this);
        this.showToast = this.props.showToast.bind(this);


        // let startDate = moment().set({ "hour": 0, "minute": 0, "seconds": 0 });
        let now = new Date(this.props.nextScheduledTime);
        let day = now.getDay();
        let currentDay = WEEKDAYS[day]
        // nowDate = startDate
        // nowDay = currentDay


        this.state = {

            metaDataMapping: this.props.metaDataMapping,
            data: this.props.data,
            dataset: this.props.dataset,
            // attribute: this.props.attribute,
            enabledCount: this.props.enabledCount,
            datasetMetric: this.props.datasetMetric,
            startDate: this.props.nextScheduledTime,

            indexLimit: 5,
            currentDay: currentDay,
            timeRange: timeRange,
            selectedScheduleTime: [],
            chipData: [],
            recurrenceList: [
                // { "label": "Does not repeat", "value": "Does not repeat" },
                { "label": "Daily", "value": "Daily" },
                { "label": "Weekly on " + currentDay, "value": "Weekly on " + currentDay },
                { "label": "Every Weekday(Monday to Friday)", "value": "Every Weekday(Monday to Friday)" },
                { "label": "Custom", "value": "Custom" }
            ],

            defaultSchedule: false,
            weekdaysClasses: {},
            selectedScheduleDay: WEEKDAYS,
            check_value: null,
            threshold_value: null,
            allAttributes: null,
            recurrence: { "label": "Daily", "value": "Daily" },
            isIconActive: false,
            datasetScheduleConfirmation: false,
            datasetConfirmationData: null,
            color: "Blue",
            showConfirmation: false,
            confirmationData: {},
            toggleId: '',
            warning: false,
            applicableDatasets: [],
            sleepInterval: this.props.sleepInterval,
            currentDataset: null,
            thresholdError: false,
            windowError: false,
            windowCount: null,
            allAttributesChecked: true,
            customTimeSchedule: false,
            formated_check: null,
            errorMessage: "This field is a required field",
            windowCountError: "This field is a required field",
            showAlertModel: false,
            disableSave: false,
            disableEditConfirmation: false,
            disableToggleConfirmation: false,
            datasets: this.props.filterData['datasets'],
            integrations: this.props.filterData['integrations'].filter((data) => this.props.existingIntegrations.includes(data.value)),
            selectedDataset: {},
            selectedDatasource : null,
            showEdit: false,
            scheduleTimeError: false,
            dayError: false,
            metaDataCount: this.props.metaDataCount,
            initialEnable: false
        }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                data: this.props.data,
                dataset: this.props.dataset,
                datasetMetric: this.props.datasetMetric,
                attribute: this.props.attribute,
                enabledCount: this.props.enabledCount,
                metaDataMapping: this.props.metaDataMapping,
                datasets: this.props.filterData,
                // addOnClass:this.props.addOnClass
            })
        }

        // let className = ".datasetHighlight"
        // $(className).removeClass("tbl-row--highlighted")
        // $('#topCover').removeClass("overflow-hidden");
        // className = ".highlight" + this.state.currentDataset
        // if ($('.datasetPopOver').hasClass('show')) {
        //     $(className).addClass("tbl-row--highlighted")
        //     $('#topCover').addClass("overflow-hidden");
        // }
        // else {
        //     $(className).removeClass("tbl-row--highlighted")
        //     $('#topCover').removeClass("overflow-hidden");
        // }
    }


    popoverAction(dataset) {
        let className = ".datasetHighlight"
        $(className).removeClass("tbl-row--highlighted")
        $('#topCover').removeClass("overflow-hidden");
        className = ".highlight" + dataset.dataset_id
        let popOverClass = ".datasetPopOver"+dataset.dataset_id
        setTimeout(() => {
            if ($(popOverClass).hasClass('show')) {
                $(className).addClass("tbl-row--highlighted")
                $('#topCover').addClass("overflow-hidden");
            }
            else {
                $(className).removeClass("tbl-row--highlighted")
                $('#topCover').removeClass("overflow-hidden");
            }
        }, 1000);
    };

    handleActiveIcon = (dataset) => {

        // dataset = _.cloneDeep(dataset)
         dataset = { ...dataset }; // for objects

        let updatedTimeRange = timeRange;

        let scheduledDays = dataset['scheduledDays']
        let selectedScheduleTime = dataset['scheduledTimes'];

        let chipData = [];
        let recurrence = this.state.recurrence

        let defaultSchedule = false
        let selectedScheduleDay = [];
        let weekdaysClasses = {};

        //
        // if (dataset['payload'] === null || scheduledDays.length === 0) {
        if (dataset['payload'] === null || scheduledDays.length === 0) {
            defaultSchedule = true
            // let chipDict = { value: this.state.sleepInterval + 'M Once Daily', label: this.state.sleepInterval + 'M Once Daily' }
            // chipData = [chipDict]
            selectedScheduleTime = []
        }
        else {
            recurrence = dataset['payload']['repeat_on']
            selectedScheduleDay = dataset['scheduledDays']
            selectedScheduleTime = dataset['scheduledTimes']
            if (selectedScheduleDay.length > 0) {

                chipData = updatedTimeRange.filter((data) => selectedScheduleTime.includes(data.label))

                updatedTimeRange = updatedTimeRange.map(data => ({
                    ...data,
                    checked: selectedScheduleTime.includes(data.label)
                }))

                if (recurrence['label'] === "Custom" && selectedScheduleDay.length > 1) {
                    selectedScheduleDay.map((data) => {
                        if (weekdaysClasses[data] === undefined) {
                            weekdaysClasses[data] = ''
                        }
                        weekdaysClasses[data] = " --is-selected"
                        return null
                    })
                }
            }
            else {
                chipData = updatedTimeRange.filter((data) => selectedScheduleTime.includes(data.label))
                updatedTimeRange = updatedTimeRange.map(data => ({
                    ...data,
                    checked: selectedScheduleTime.includes(data.label)
                }))
            }
        }

        // let now = new Date(this.props.nextScheduledTime);
        // let day = now.getDay();
        // let currentDay = WEEKDAYS[day]
        // nowDate = startDate
        // nowDay = currentDay


        // let windowCount = null;
        // let windowError = true;
        // if (dataset['metrics_name'] === "Row Count" && dataset['window_count'] !== undefined && dataset['window_count'] !== null) {
        //     // windowCount = dataset['window_count']
        //     windowError = false
        // }

        // let startDate = this.props.nextScheduledTime

        let selectedDataset = {}
        let selectedDatasource = {}

        if (dataset['target_data_set_id'] !== undefined) {
            selectedDataset = { label: this.state.metaDataMapping[dataset['target_data_set_id']], value: dataset['target_data_set_id'] }
            selectedDatasource = { label: this.state.metaDataMapping[dataset['integration_id']], value: dataset['integration_id'] }
        }

        this.setState({
            timeRange: updatedTimeRange,
            selectedScheduleTime: selectedScheduleTime,
            chipData: chipData,
            defaultSchedule: defaultSchedule,
            recurrence: recurrence,
            selectedScheduleDay: selectedScheduleDay,
            weekdaysClasses: weekdaysClasses,
            currentDataset: dataset['dataset_id'],
            // currentDay: currentDay,
            startDate: this.props.nextScheduledTime,
            thresholdError: false,
            datasetScheduleConfirmation: false,
            disableSave: false,
            selectedDataset: selectedDataset,
            selectedDatasource : selectedDatasource,
            scheduleTimeError: false,
            dayError: false,
        });
    }


    datasetPopover(data, dataset, datasetMetric, datasetThresholdData, title) {

        if (datasetMetric['payload'] !== undefined && datasetMetric['payload']['check'] !== undefined) {
            datasetMetric['payload']['checks'] = datasetMetric['payload']['check']
        }

        return (
            <Popover
                className={"popover-theme-blue scheduleMetric-popover datasetPopOver datasetPopOver"+ dataset.dataset_id +" highlight" + dataset.dataset_id + " " + (datasetMetric['metrics_name'] !== "Unique Values" && "scheduleMetric-popover--horizontal")}>
                <Popover.Title>
                    <div className="title">
                        <h3 className="caption">{title +" "+ datasetMetric.metrics_name + " Data Quality Metric"}</h3>
                        {/* <h3 className="caption">{"Edit Schedule and " + datasetMetric.metrics_name + " Data Quality Metric"}</h3> */}
                    </div>
                </Popover.Title>
                <Popover.Content>
                    <div className="popover-content d-flex p-0">
                        <div className={"popover-content__left " + (datasetMetric['metrics_name'] === "Unique Values" && "w-100 border-0")}>
                            <div className="title-area">
                                <h6 className="headline">Edit Schedule</h6>
                                <p className="paratext">The changes will be applied to all the attributes for this dataset.</p>
                            </div>
                            <div className="form-line-input">
                                <div className="form-line-input__prepend">
                                    <i><FontAwesomeIcon icon={faServer} /></i>
                                </div>
                                <input type="text" disabled={true} defaultValue={this.state.metaDataMapping[data.env_id]} className="form-control" placeholder="Enter Environment" />
                            </div>
                            <div className="form-line-input">
                                <div className="form-line-input__prepend">
                                    <i><FontAwesomeIcon icon={faDatabase} /></i>
                                </div>
                                <input

                                    type="text"
                                    disabled={true}
                                    defaultValue={this.state.metaDataMapping[data.integration_id]}
                                    className="form-control"
                                    placeholder="Enter Datasource"
                                />
                            </div>

                            <div className="form-line-input">
                                <div className="form-line-input__prepend">
                                    <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                                </div>
                                <input type="text" disabled={true} defaultValue={this.state.metaDataMapping[dataset.dataset_id]} className="form-control" placeholder="Enter Environment" />
                            </div>
                            <div className="form-line-input align-items-start mb-3">
                                <div className="form-line-input__prepend">
                                    <i><FontAwesomeIcon icon={regularClock} /></i>
                                </div>
                                <div>
                                    <div class="custom-control custom-control-inline custom-radio flex-shrink-0 mb-2">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            name="scheduleTimeInputs"
                                            checked={!this.state.defaultSchedule}
                                            onChange={() => {
                                                this.setState({
                                                    customTimeSchedule: true,
                                                    defaultSchedule: false,
                                                    recurrence: { label: "Daily", value: "Daily" },
                                                })
                                            }
                                            }
                                            id="chooseTime" />
                                        <label class="custom-control-label d-flex align-items-center" for="chooseTime">
                                            Choose Time
                                        </label>
                                    </div>
                                    <div class="custom-control custom-control-inline custom-radio flex-shrink-0 mb-2">
                                        <input
                                            type="radio"
                                            checked={this.state.defaultSchedule}
                                            name="scheduleTimeInputs"
                                            onChange=
                                            {(e) => {
                                                this.setState({
                                                    // chipData : [],
                                                    recurrence: { label: "Daily", value: "Daily" },
                                                    customTimeSchedule: false,
                                                    defaultSchedule: true,
                                                    scheduleTimeError: false
                                                })
                                            }}
                                            class="custom-control-input" id="allDay" />
                                        <label class="custom-control-label d-flex align-items-center" for="allDay">
                                            Default Scanning
                                        </label>
                                    </div>
                                    {this.state.defaultSchedule === false ?
                                        <>
                                            <div className="weekday-time-input">
                                                <DropdownTreeSelect
                                                    id="selectedScheduleTime"
                                                    name="selectedScheduleTime"
                                                    // className="mr-4"
                                                    type="timeSchedule"
                                                    data={this.state.timeRange}
                                                    onChange={(currentNode) => {
                                                        let selectedScheduleTime = this.state.selectedScheduleTime
                                                        let chipData = this.state.chipData
                                                        let updateTimeRange = []
                                                        if (currentNode['checked'] === true) {
                                                            selectedScheduleTime.push(currentNode.label)

                                                            let chipDict = { "value": currentNode.value, "label": currentNode.label }
                                                            chipData.push(chipDict)
                                                            this.state.timeRange.map((data) => {
                                                                if (currentNode.value === data.value) {
                                                                    data['checked'] = true
                                                                }
                                                                updateTimeRange.push(data)
                                                                return null
                                                            })
                                                        }
                                                        else {
                                                            selectedScheduleTime = this.state.selectedScheduleTime.filter((data) => data !== currentNode.label)

                                                            chipData = this.state.chipData.filter((data) => data.label !== currentNode.label)

                                                            this.state.timeRange.map((data) => {
                                                                if (currentNode.value === data.value) {
                                                                    data['checked'] = false
                                                                }
                                                                updateTimeRange.push(data)
                                                                return null
                                                            })

                                                        }

                                                        if (selectedScheduleTime.length === 0) {
                                                            this.setState({ scheduleTimeError: true })
                                                        }
                                                        else {
                                                            this.setState({ scheduleTimeError: false })
                                                        }
                                                        this.setState({ selectedScheduleTime: selectedScheduleTime, timeRange: updateTimeRange, chipData: chipData, scheduleTimeError: false })
                                                    }}

                                                    texts={{ placeholder: this.state.selectedScheduleTime.length === 0 ? "Choose Time" : this.state.selectedScheduleTime.length + " Time selected" }}
                                                />
                                                <span className="text-muted ml-2 timeZone">(GMT)</span>
                                                {/* } */}
                                            </div>
                                            {/* {this.state.defaultSchedule === false &&
                                                <ErrorMessage component="div" className="error-text" name="selectedScheduleTime" />
                                            } */}
                                        </>
                                        : " "}
                                    <div className="metric-schedule-time-chips">
                                        {this.state.defaultSchedule === true
                                            // && this.state.chipData.length === 0
                                            ?
                                            <>
                                                <div className="metric-schedule-time-chip">
                                                    <span className="metric-schedule-time-text">{this.state.sleepInterval + " Minutes once"}</span>
                                                    {/* <i
                                                        className="metric-schedule-time-action"
                                                    >
                                                        <svg width="16" height="16" class="d-block" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.26634 12.9173L3.33301 11.984L7.06634 8.25065L3.33301 4.51732L4.26634 3.58398L7.99967 7.31732L11.733 3.58398L12.6663 4.51732L8.93301 8.25065L12.6663 11.984L11.733 12.9173L7.99967 9.18398L4.26634 12.9173Z" fill="currentColor" />
                                                        </svg>
                                                    </i> */}
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    trigger="hover"
                                                    overlay={
                                                        <Popover className="flush-popover">
                                                            <Popover.Title as="h3">Scan Interval Time</Popover.Title>
                                                            <Popover.Content>
                                                                The <strong>Scan Interval Time</strong> has already been set in the configure screen. To modify the <strong>Scan Interval Time</strong>, please navigate to the configure screen.
                                                            </Popover.Content>
                                                        </Popover>
                                                    }
                                                >
                                                    <svg width="16" height="16" className="mb-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.4 11H8.6V7.4H7.4V11ZM8 6.2C8.17 6.2 8.3125 6.1425 8.4275 6.0275C8.5425 5.9125 8.6 5.77 8.6 5.6C8.6 5.43 8.5425 5.2875 8.4275 5.1725C8.3125 5.0575 8.17 5 8 5C7.83 5 7.6875 5.0575 7.5725 5.1725C7.4575 5.2875 7.4 5.43 7.4 5.6C7.4 5.77 7.4575 5.9125 7.5725 6.0275C7.6875 6.1425 7.83 6.2 8 6.2ZM8 14C7.17 14 6.39 13.8425 5.66 13.5275C4.93 13.2125 4.295 12.785 3.755 12.245C3.215 11.705 2.7875 11.07 2.4725 10.34C2.1575 9.61 2 8.83 2 8C2 7.17 2.1575 6.39 2.4725 5.66C2.7875 4.93 3.215 4.295 3.755 3.755C4.295 3.215 4.93 2.7875 5.66 2.4725C6.39 2.1575 7.17 2 8 2C8.83 2 9.61 2.1575 10.34 2.4725C11.07 2.7875 11.705 3.215 12.245 3.755C12.785 4.295 13.2125 4.93 13.5275 5.66C13.8425 6.39 14 7.17 14 8C14 8.83 13.8425 9.61 13.5275 10.34C13.2125 11.07 12.785 11.705 12.245 12.245C11.705 12.785 11.07 13.2125 10.34 13.5275C9.61 13.8425 8.83 14 8 14ZM8 12.8C9.34 12.8 10.475 12.335 11.405 11.405C12.335 10.475 12.8 9.34 12.8 8C12.8 6.66 12.335 5.525 11.405 4.595C10.475 3.665 9.34 3.2 8 3.2C6.66 3.2 5.525 3.665 4.595 4.595C3.665 5.525 3.2 6.66 3.2 8C3.2 9.34 3.665 10.475 4.595 11.405C5.525 12.335 6.66 12.8 8 12.8Z" fill="#B3B2BD" />
                                                    </svg>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            this.state.chipData.map((data) => {
                                                return (
                                                    <>
                                                        <div className="metric-schedule-time-chip">

                                                            <span className="metric-schedule-time-text">{data.label}</span>
                                                            <i
                                                                className="metric-schedule-time-action"
                                                                value={data}
                                                                onClick={(e) => {
                                                                    this.removeChipData(data)
                                                                }}
                                                            >
                                                                <svg width="16" height="16" class="d-block" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.26634 12.9173L3.33301 11.984L7.06634 8.25065L3.33301 4.51732L4.26634 3.58398L7.99967 7.31732L11.733 3.58398L12.6663 4.51732L8.93301 8.25065L12.6663 11.984L11.733 12.9173L7.99967 9.18398L4.26634 12.9173Z" fill="currentColor" />
                                                                </svg>
                                                            </i>
                                                        </div>

                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        this.state.scheduleTimeError === true &&
                                        <h6 className="headline text-orange"><strong> Please Choose an Time</strong></h6>
                                    }
                                </div>
                            </div>
                            <div className="form-line-input">
                                <div className="form-line-input__prepend">
                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                            <g id="icon_repeat" transform="translate(-881 -905)">
                                                <path id="icon_repeat-2" data-name="icon_repeat" d="M6.556,18.088l.8.8a.862.862,0,0,1,.271.659.992.992,0,0,1-.271.659.959.959,0,0,1-.671.294.876.876,0,0,1-.671-.271L3.592,17.806a.826.826,0,0,1-.2-.306,1.088,1.088,0,0,1,0-.706.826.826,0,0,1,.2-.306l2.423-2.423a.876.876,0,0,1,.671-.27.959.959,0,0,1,.671.294.992.992,0,0,1,.271.659.862.862,0,0,1-.271.659l-.8.8h9.552V13.382a.941.941,0,1,1,1.882,0v2.823a1.888,1.888,0,0,1-1.882,1.882ZM16.25,6.794H6.7V9.618a.948.948,0,0,1-1.612.671.911.911,0,0,1-.271-.671V6.794A1.888,1.888,0,0,1,6.7,4.912H16.25l-.8-.8a.863.863,0,0,1-.271-.659.993.993,0,0,1,.271-.659.96.96,0,0,1,.671-.294.876.876,0,0,1,.671.271l2.423,2.423a.827.827,0,0,1,.2.306,1.089,1.089,0,0,1,0,.706.826.826,0,0,1-.2.306L16.791,8.935a.876.876,0,0,1-.671.271.96.96,0,0,1-.671-.294.993.993,0,0,1-.271-.659.863.863,0,0,1,.271-.659Z" transform="translate(881.667 905.5)" fill="#99a1b7" />
                                                <rect id="Rectangle_632" data-name="Rectangle 632" width="24" height="24" transform="translate(881 905)" fill="none" />
                                            </g>
                                        </svg>
                                    </i>
                                </div>
                                <Select
                                    options={this.state.recurrenceList}
                                    name="recurrence"
                                    className='form-control custom-select-control'
                                    classNamePrefix='select-control'
                                    placeholder="Does Not Repeat"
                                    value={this.state.recurrence}
                                    isDisabled={this.state.defaultSchedule}
                                    onChange=
                                    {(e) => {
                                        let selectedScheduleDay = []

                                        if (e.value === "Daily") {
                                            selectedScheduleDay = WEEKDAYS
                                        }
                                        else if (e.value === "Every Weekday(Monday to Friday)") {
                                            selectedScheduleDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
                                        }
                                        else if (e.value === "Weekly on " + this.state.currentDay) {
                                            selectedScheduleDay = [this.state.currentDay]
                                        }
                                        if (e.value !== "Custom") {
                                            this.setState({ dayError: false })
                                        }
                                        this.setState({ recurrence: e, selectedScheduleDay: selectedScheduleDay })
                                    }}
                                />

                            </div>

                            {this.state.recurrence['label'] === "Custom"
                                &&
                                <div className="form-line-input align-items-start">
                                    <div className="form-line-input__prepend">
                                        <i><FontAwesomeIcon icon={faCalendarDay} /></i>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="mb-2">Repeat on</span>
                                        <div className="weekday-picker">
                                            <div className="weekday-picker__cols mb-3">
                                                {WEEKDAYS.map((day) => (
                                                    <div
                                                        className={this.state.weekdaysClasses[day] !== undefined ? "weekday-picker__col" + this.state.weekdaysClasses[day] : "weekday-picker__col"}
                                                        onClick={(e) => {

                                                            let selectedScheduleDay = this.state.selectedScheduleDay
                                                            let weekdaysClasses = {};
                                                            if (selectedScheduleDay.includes(day)) {
                                                                selectedScheduleDay = selectedScheduleDay.filter(data => data !== day)
                                                            }
                                                            else {
                                                                selectedScheduleDay.push(day)
                                                            }
                                                            selectedScheduleDay.map((day) => {
                                                                weekdaysClasses[day] = ''
                                                                weekdaysClasses[day] = " --is-selected"
                                                                return null
                                                            })

                                                            if (selectedScheduleDay.length === 0) {
                                                                this.setState({ dayError: true })
                                                            }
                                                            else {
                                                                this.setState({ dayError: false })
                                                            }
                                                            this.setState({ selectedScheduleDay: selectedScheduleDay, weekdaysClasses: weekdaysClasses })
                                                        }
                                                        }
                                                    >
                                                        <span
                                                            className={"weekday-picker__label"}                                                                                                                                                                                                                       >
                                                            {day.split("")[0]}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                            {
                                                this.state.dayError === true &&
                                                <h6 className="headline text-orange"><strong> Please Choose a Day.</strong></h6>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {datasetMetric['metrics_name'] !== "Unique Values" ?
                            <div className="popover-content__right">
                                <div className={"qd-metrics-section " + metricClassMapping[datasetMetric['metrics_name']]['tableClass'] + " mt-0"}>
                                    <div className={"qd-metrics-section__head text-" + metricClassMapping[datasetMetric['metrics_name']]['tableClass'].split('-')[2]}>
                                        <i>
                                            <svg width="20" height="20" class="d-block opacity-75" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM6 7.5C5.7875 7.5 5.60938 7.57188 5.46562 7.71562C5.32187 7.85938 5.25 8.0375 5.25 8.25V12C5.25 12.2125 5.32187 12.3906 5.46562 12.5344C5.60938 12.6781 5.7875 12.75 6 12.75C6.2125 12.75 6.39062 12.6781 6.53438 12.5344C6.67812 12.3906 6.75 12.2125 6.75 12V8.25C6.75 8.0375 6.67812 7.85938 6.53438 7.71562C6.39062 7.57188 6.2125 7.5 6 7.5ZM9 5.25C8.7875 5.25 8.60938 5.32187 8.46563 5.46562C8.32188 5.60938 8.25 5.7875 8.25 6V12C8.25 12.2125 8.32188 12.3906 8.46563 12.5344C8.60938 12.6781 8.7875 12.75 9 12.75C9.2125 12.75 9.39063 12.6781 9.53438 12.5344C9.67813 12.3906 9.75 12.2125 9.75 12V6C9.75 5.7875 9.67813 5.60938 9.53438 5.46562C9.39063 5.32187 9.2125 5.25 9 5.25ZM12 9.75C11.7875 9.75 11.6094 9.82188 11.4656 9.96563C11.3219 10.1094 11.25 10.2875 11.25 10.5V12C11.25 12.2125 11.3219 12.3906 11.4656 12.5344C11.6094 12.6781 11.7875 12.75 12 12.75C12.2125 12.75 12.3906 12.6781 12.5344 12.5344C12.6781 12.3906 12.75 12.2125 12.75 12V10.5C12.75 10.2875 12.6781 10.1094 12.5344 9.96563C12.3906 9.82188 12.2125 9.75 12 9.75Z" fill="currentColor" />
                                            </svg>
                                        </i>
                                        <div className="title-area">
                                            <h6 className={"headline text-" + metricClassMapping[datasetMetric['metrics_name']]['tableClass'].split('-')[2]}>Edit <strong>{datasetMetric.metrics_name === "Unique Values" ? "Uniqueness" : datasetMetric.metrics_name}</strong> Metric</h6>
                                            <p className="paratext">This specific settings that only apply to this particular metric</p>
                                        </div>
                                    </div>
                                    <div className="qd-metrics-section__content">
                                        {datasetMetric['metrics_name'] === "Fill Rate" &&
                                            <Form.Group >
                                                <InputGroup className="mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="metric">List of invalid values</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type=""
                                                        placeholder='"NA","", "Not Available"'
                                                        value={this.state.check_value === null ? datasetMetric['payload'] !== undefined && datasetMetric['payload']['checks'].length > 0 && datasetMetric['payload']['checks'][0]['value'] : this.state.check_value}
                                                        onChange={e => {

                                                            let default_format = datasetMetric['payload']['checks']
                                                            if (e.target.value.length > 0) {
                                                                default_format[0]['value'] = e.target.value
                                                            }
                                                            else {
                                                                default_format = DEFAULT_CHECKS[datasetMetric['metrics_name']]
                                                            }
                                                            this.setState({ check_value: e.target.value, formated_check: default_format });
                                                        }}

                                                    />
                                                </InputGroup>
                                                <Form.Text>
                                                    Example: ["NA","", "Not Available"]
                                                </Form.Text>
                                            </Form.Group>
                                        }
                                        {REGEX_METRIC.includes(datasetMetric['metrics_name']) &&
                                            <Form.Group >
                                                <InputGroup className="mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="metric" className="flex-column align-items-start">Pattern to identify<span>possible values:</span></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Regex'
                                                        className="h-auto"
                                                        value={this.state.check_value === null ? datasetMetric['payload'] !== undefined && datasetMetric['payload']['checks'].length > 0 && datasetMetric['payload']['checks'][0]['value'] : this.state.check_value}
                                                        onChange={e => {

                                                            let default_format = datasetMetric['payload']['checks']

                                                            if (e.target.value.length > 0) {
                                                                default_format[0]['value'] = e.target.value
                                                            }
                                                            else {
                                                                default_format = DEFAULT_CHECKS[datasetMetric['metrics_name']]
                                                            }

                                                            this.setState({ check_value: e.target.value, formated_check: default_format });
                                                        }}
                                                    />
                                                </InputGroup>
                                                <Form.Text>
                                                    {datasetMetric['metrics_name'] === "Gender Conformity"
                                                        &&
                                                        "Example: ^M(ale)?$|^F(emale)?$"
                                                    }
                                                </Form.Text>
                                            </Form.Group>
                                        }
                                        {DATA_CONSISTENCY_METRICS.includes(datasetMetric['metrics_name']) &&
                                             <div className="metric-reference-card">
                                                <h5 className="card-title text-purple">Reference Datasource/Dataset</h5>
                                            <>
                                                <Form.Group >
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>Reference Datasource</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Select
                                                            id="referenceDatasource"
                                                            name="referenceDatasource"
                                                            className='form-control p-0'
                                                            classNamePrefix='select-control'
                                                            placeholder={this.state.selectedDatasource === null || Object.keys(this.state.selectedDatasource).length === 0 ? "Datasource" : this.state.selectedDatasource.label}
                                                            value={this.state.selectedDatasource === null || Object.keys(this.state.selectedDatasource).length === 0 ? [] : this.state.selectedDatasource}
                                                            options={this.state.integrations}
                                                            onChange={(e) => {
                                                                let applicableDatasets = []
                                                                this.state.datasets.filter((data) => data.is_child_dataset !== true && data.integration_id === e.value && applicableDatasets.push(data))
                                                                this.setState({
                                                                    selectedDatasource: { "label": e.label, "value": e.value },
                                                                    selectedDataset : {},
                                                                    applicableDatasets: applicableDatasets,
                                                                })

                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {/* <Form.Text className="text-muted">Select a reference dataset to apply it to the current metric</Form.Text> */}
                                                </Form.Group>
                                                <Form.Group >
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>Reference Dataset</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Select
                                                            id="referenceDataset"
                                                            name="referenceDataset"
                                                            className='form-control p-0'
                                                            classNamePrefix='select-control'
                                                            placeholder={Object.keys(this.state.selectedDataset).length === 0 ? "Dataset" : this.state.selectedDataset.label}
                                                            value={Object.keys(this.state.selectedDataset).length === 0 ? [] : this.state.selectedDataset}
                                                            options={this.state.applicableDatasets}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    selectedDataset: { "label": e.label, "value": e.value },
                                                                })
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    <Form.Text className="text-muted">Select a reference datasource and dataset to apply it to the current metric</Form.Text>
                                                </Form.Group></>

                                                </div>
                                        }

                                        <Form.Group>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="metric-threshold">Metric Threshold (in {datasetMetric['metrics_name'] === "Recency" ? "Days" : "%"})</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                {/* <input type="text" defaultValue={datasetMetric.threshold_value} className="form-control" placeholder="Metrics Threshold (in %)" /> */}

                                                <Form.Control
                                                    type="text"
                                                    pattern="[a-zA-Z0-9 ]+"
                                                    min={1}
                                                    max={100}
                                                    placeholder="Metric Threshold"
                                                    value={this.state.threshold_value === null ? datasetMetric.threshold_value : this.state.threshold_value}
                                                    onChange={e => {

                                                        let threshold_value = e.target.value // .split('.')[0]
                                                        if (threshold_value.length === 0) {
                                                            this.setState({
                                                                thresholdError: true,
                                                                threshold_value: threshold_value
                                                            })
                                                        }
                                                        else if (Number(threshold_value) <= 100) {
                                                            this.setState({
                                                                thresholdError: false,
                                                                threshold_value: threshold_value
                                                            })
                                                        }
                                                        if (threshold_value.length > 0 && Number(threshold_value) < 0) {
                                                            this.setState({
                                                                thresholdError: true,
                                                                errorMessage: "Threshold cannot be less than 0"
                                                            })
                                                        }

                                                    }}
                                                />
                                            </InputGroup>
                                            {
                                                this.state.thresholdError === true &&
                                                <h6 className="headline text-orange"><strong>{this.state.errorMessage}</strong></h6>
                                            }
                                        </Form.Group>

                                        {(datasetMetric['metrics_name'] === "Row Count" || datasetMetric['metrics_name'] === "Data Drift") &&
                                            <>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="window-size">Window Count (in Refreshes)</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            type="text"
                                                            pattern="[a-zA-Z0-9 ]+"
                                                            min={1}
                                                            max={100}
                                                            placeholder="Window Size"
                                                            value={this.state.windowCount === null ? datasetMetric['payload'] !== undefined && datasetMetric['payload']['window_count'] !== undefined && datasetMetric['payload']['window_count'] : this.state.windowCount}
                                                            onChange={e => {

                                                                let windowCount = e.target.value // .split('.')[0]
                                                                if (windowCount.length === 0) {
                                                                    this.setState({
                                                                        windowError: true,
                                                                        windowCount: windowCount
                                                                    })
                                                                }
                                                                else if (Number(windowCount) <= 100) {
                                                                    this.setState({
                                                                        windowError: false,
                                                                        windowCount: windowCount
                                                                    })
                                                                }
                                                                if (windowCount.length > 0 && Number(windowCount) < 0) {
                                                                    this.setState({
                                                                        windowError: true,
                                                                        windowCountError: "Window Count cannot be less than 0"
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {
                                                        this.state.windowError === true &&
                                                        <h6 className="headline text-orange"><strong> {this.state.windowCountError}</strong></h6>
                                                    }
                                                    <small className="form-text text-muted">The window size denotes the number of refreshes which is used to measure the drift in the Row Count.</small>
                                                </Form.Group>
                                            </>
                                        }

                                        {datasetMetric['metrics_name'] !== "Row Count" && datasetMetric['metrics_name'] !== "Recency"
                                            &&
                                            <Form.Check
                                                custom
                                                type="checkbox"
                                                className="mb-1 text-muted"
                                                // defaultChecked={datasetMetric.all_attributes === undefined ? this.state.allAttributes : datasetMetric.all_attributes}
                                                checked={this.state.allAttributes === null ? datasetMetric.all_attribute_enable : this.state.allAttributes}
                                                onChange={(e) => {
                                                    this.setState({ allAttributes: e.target.checked })
                                                }}
                                                id={"all_attribute" + datasetMetric.dataset_id}
                                                label={<small>Make this default and apply to all attributes</small>}
                                            />
                                        }
                                    </div>
                                </div>
                            </div> : ''
                        }
                    </div>
                    <div className="popover-footer justify-content-end">
                        <button
                            type="button"
                            id="dataset"
                            onClick={(e) => {
                                this.closeUpdateThreshold(datasetThresholdData, dataset)
                            }
                            }
                            class="btn btn-outline btn-grey btn-circle mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-circle"
                            data={datasetThresholdData}
                            disabled={(this.state.disableSave === true) || (this.state.thresholdError) || (datasetMetric['metrics_name'] === "Row Count" && this.state.windowError) === true ? true : false}
                            onClick={(e) => {
                                this.setState({ disableSave: true })
                                this.confirmationDatasetSchedule(datasetThresholdData);
                            }
                            }
                        >
                            <i className="mr-1">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                />
                            </i>
                            Save
                        </button>
                    </div>
                </Popover.Content>
            </Popover >
        )
    }

    handleTimeFilter(event, picker) {
        let startDate = picker.startDate;
        const now = new Date(startDate)
        const day = now.getDay();
        let selectedDay = WEEKDAYS[day]

        let replaceLable = { "label": "Weekly on " + selectedDay, "value": "Weekly on " + selectedDay }

        let recurrenceList = this.state.recurrenceList

        recurrenceList[1] = replaceLable

        let selectedScheduleDay = this.state.selectedScheduleDay
        let recurrence = this.state.recurrence

        if (this.state.selectedScheduleDay.length === 1) {
            selectedScheduleDay = [selectedDay]
            selectedDay = [selectedDay]
            recurrence = replaceLable
        }

        this.setState({
            startDate: startDate,
            currentDay: selectedDay,
            scheduledDays: [selectedDay],
            selectedScheduleDay: selectedScheduleDay,
            recurrenceList: recurrenceList,
            recurrence: recurrence
        });
    }

    removeChipData(data) {
        // Removing the corresponding label from chipData
        let chipData = this.state.chipData.filter((value) => value.label !== data.label)

        // Removing the corresponding time from selectedScheduleTime
        let selectedScheduleTime = this.state.selectedScheduleTime.filter((value) => value !== data.label)

        // Uncheck the corresponding time in timerange data
        let updateTimeRange = []
        this.state.timeRange.map((value) => {
            if (value.value === data.value) {
                value['checked'] = false
            }
            updateTimeRange.push(value)
            return null
        })

        if (selectedScheduleTime.length === 0) {
            this.setState({ scheduleTimeError: true })
        }
        else {
            this.setState({ scheduleTimeError: false })
        }

        this.setState({ chipData: chipData, selectedScheduleTime: selectedScheduleTime, timeRange: updateTimeRange })
    }

    confirmationDatasetSchedule(data) {
        let selectedScheduleDay = {}
        let scheduledDays = this.state.selectedScheduleDay

        if (scheduledDays.length === 0) {
            scheduledDays = WEEKDAYS
        }

        if ((this.state.defaultSchedule === false && this.state.selectedScheduleTime.length === 0) || (this.state.recurrence['label'] === "Custom" && this.state.selectedScheduleDay.length === 0)) {
            if (this.state.recurrence['label'] === "Custom" && this.state.selectedScheduleDay.length === 0) {
                this.setState({ dayError: true, disableSave: false })
            }
            if (this.state.defaultSchedule === false && this.state.selectedScheduleTime.length === 0) {
                this.setState({ scheduleTimeError: true, disableSave: false })
            }
            return null
        }


        let selectedScheduleTime = this.state.selectedScheduleTime
        selectedScheduleTime = timeRange.map((data) => {
            if (selectedScheduleTime.includes(data.label)) {
                return data.value
            }
            return null
        })

        selectedScheduleTime = selectedScheduleTime.filter((data) => data !== null)

        scheduledDays.map((day) => {
            return selectedScheduleDay[day] = selectedScheduleTime
        })

        let repeat_on = this.state.recurrence
        if (this.state.defaultSchedule === true) {
            selectedScheduleDay = []
            this.setState({
                recurrence: { label: "Daily", value: "Daily" },
                chipData: [],
            })
        }
        data['scheduling_time'] = selectedScheduleDay
        data['defaultSchedule'] = this.state.defaultSchedule
        data['repeat_on'] = repeat_on
        data['color'] = this.state.color

        // if (this.state.allAttributes === true) {
        let className = ".datasetPopOver"
        $(className).addClass("d-none");
        this.setState({ datasetConfirmationData: data, datasetScheduleConfirmation: !this.state.datasetScheduleConfirmation })
        // }
        // else {
        //     this.updateThresholdData(data, "update");
        // }
    }

    returnDatasetSchedule(data) {
        // let className = ".clickpopover" + data.data_set_id + "_" + data.metrics_id + "_ i"
        // $(className).eq(0).trigger("click");
        let className = ".datasetPopOver"
        $(className).removeClass("d-none");

        this.setState({ datasetScheduleConfirmation: !this.state.datasetScheduleConfirmation })
    }

    closeUpdateThreshold(data) {
        let className = ".clickpopover" + data.data_set_id + "_" + data.metrics_id + "_ i"
        let clockIcon = ".clock_icon" + data.data_set_id + "_" + data.metrics_id + "_"
        $(className).eq(0).trigger("click");

        let highLightClass = ".highlight" + data['data_set_id']
        $(highLightClass).removeClass("tbl-row--highlighted")

        // let timeRange = this.state.timeRange.map(data => ({
        //     ...data,
        //     checked: false
        // })
        // )

        this.setState({
            check_value: null,
            threshold_value: null,
            windowCount: null,
            color: "Blue",
            allAttributes: null,
        })
        this.handleRemoveActiveIcon(clockIcon);
    }

    handleRemoveActiveIcon = (data) => {
        let Icon = data;
        $(Icon).removeClass('icon--is-active')
    }

    closeConfirmation(e) {

        if (this.state.initialEnable === true || e.currentTarget.id === 'save-btn') {
            let thresholdData = this.state.confirmationData
            let allAttributesChecked = this.state.allAttributesChecked
            let checked = thresholdData["checked"]

            if (this.state.initialEnable === true) {
                allAttributesChecked = false
                checked = true
            }

            let data = {
                "tenant_id": thresholdData['tenant_id'],
                "env_id": thresholdData['env_id'],
                "integration_id": thresholdData['integration_id'],
                "data_set_id": thresholdData["data_set_id"],
                "attribute_id": null,
                "metrics_id": thresholdData['metrics_id'],
                // "metrics_name" : thresholdData['metrics_name'],
                "checked": checked,
                "is_enable": thresholdData["checked"] === true ? 1 : 0,
                "update_type": "dataset_status",
                "all_attributes": allAttributesChecked,
            }
            this.setState({ disableToggleConfirmation: false })
            this.postToggleAction(data, thresholdData['target_data_set_id'])
        }
        else {
            // let id = "#"+this.state.toggleId
            // $(id).prop("checked", true);
            this.setState({ showConfirmation: false, allAttributesChecked: true, disableToggleConfirmation: false })
        }
    }

    updateThresholdData(data, e = null) {

        if (this.state.initialEnable === true) {
            data["is_enable"] = 1
        }
        let datasetPostData =
        {
            tenant_id: data["tenant_id"],
            env_id: data["env_id"],
            integration_id: data["integration_id"],
            data_set_id: data["data_set_id"],
            attribute_id: data["attribute_id"],
            is_enable: data["is_enable"],
            metrics_id: data["metrics_id"],
            metrics_name: data["metrics_name"],
            update_type: data["update_type"],
        }

        if (data['update_type'] === "dataset_threshold") {
            if (data['defaultSchedule'] === true) {
                data['repeat_on'] = this.state.recurrence
            }

            let scheduleData = {
                tenant_id: Number(localStorage.getItem('team_id')),
                env_id: data.env_id,
                integration_id: data.integration_id,
                data_set_id: [data.data_set_id],
                scheduling_time: data['scheduling_time'],
                defaultSchedule: data['defaultSchedule'],
                repeat_on: this.state.recurrence
            }
            scheduleData['color'] = "Blue"
            if (data['defaultSchedule'] === true) {
                scheduleData['repeat_on'] = {}
            }
            this.postBulkScheduleData(scheduleData)
        }

        let className = ""

        datasetPostData["check_value"] = this.state.check_value === null ? data['check_value'] : this.state.formated_check
        datasetPostData["updated_threshold_value"] = this.state.threshold_value === null ? data['threshold_value'] : Number(this.state.threshold_value)
        if (data['metrics_name'] === 'Row Count' || data['metrics_name'] === 'Data Drift') {
            datasetPostData["window_count"] = this.state.windowCount === null ? data['window_count'] : Number(this.state.windowCount)
        }
        datasetPostData["all_attributes"] = this.state.allAttributes === null ? data['all_attribute_enable'] : this.state.allAttributes

        className = ".clickpopover" + data.data_set_id + "_" + data.metrics_id + "_ i"


        let highLightClass = ".highlight" + data['data_set_id']
        $(highLightClass).removeClass("tbl-row--highlighted")

        let action = null

        if ((e !== null && e.currentTarget !== undefined && e.currentTarget.id === "save-btn") || e === "update") {
            if (DATA_CONSISTENCY_METRICS.includes(data['metrics_name'])) {
                if ((data['target_data_set_id'] === null || data['target_data_set_id'] === undefined) && Object.keys(this.state.selectedDataset).length > 0) {
                    // Add first time
                    action = "post"
                }
                else if (data['target_data_set_id'] !== null) {
                    // Update flow
                    action = "update"
                }
                datasetPostData['target_data_set_id'] = this.state.selectedDataset['value']
            }
            this.putThresholdData(datasetPostData, className, action)
        }
        else {
            // if (datasetPostData['all_attributes'] !== true) {
            $(className).eq(0).trigger("click");
            // }
            this.setState({
                check_value: null,
                threshold_value: null,
                defaultSchecule: false,
                color: "",
                allAttributes: null,
                selectedScheduleTime: [],
                selectedScheduleDay: {},
                defaultSchedule: false,
                startDate: this.state.startDate,
                currentDay: this.state.currentDay,
                recurrence: { "label": "Daily", "value": "Daily" },
                weekdaysClasses: {},
                datasetScheduleConfirmation: false,
                allAttributesChecked: true,
                disableEditConfirmation: false,
                disableSave: false
            })
        }
    }


    handleSort(data) {
        let columnIndex = METRIC_ORDER.indexOf(data['metrics_name']) + 1;
        let targetClassOne = "#" + String(data['data_set_id']) + "_nestedAttributes"
        let targetClassTwo = targetClassOne + " tr"

        $(targetClassTwo).sort((a, b) => {
            const valueA = $(a).hasClass(targetClassOne) ? 0 : 1; // Prioritize rows with the target class
            const valueB = $(b).hasClass(targetClassOne) ? 0 : 1;

            // Sort rows with the target class first
            if (valueA === 0 && valueB === 1) {
                return -1;
            } else if (valueA === 1 && valueB === 0) {
                return 1;
            }

            // Sort other rows based on the checked status
            // const isCheckedA = $(a).find('td:eq(+ +) input.checked-input').prop('checked');
            // const isCheckedB = $(b).find('td:eq(3) input.checked-input').prop('checked');
            const isCheckedA = $(a).find('td:eq(' + columnIndex + ') input.custom-control-input').prop('checked');
            const isCheckedB = $(b).find('td:eq(' + columnIndex + ') input.custom-control-input').prop('checked');

            if (isCheckedA && !isCheckedB) {
                return -1;
            } else if (!isCheckedA && isCheckedB) {
                return 1;
            }
            return 1
            // Sort unchecked rows alphabetically by name
            // const nameA = $(a).find('td:eq(0)').text().toUpperCase();
            // const nameB = $(b).find('td:eq(0)').text().toUpperCase();
            // return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        }).appendTo(targetClassOne);
    }

    closeAlertmodel() {
        this.setState({ showAlertModel: !this.state.showAlertModel })
    }


    goToMetricPage() {
        this.props.closeFullScreen();
    }

    render() {
        // let data = _.cloneDeep(this.state.data)
        let dataset = _.cloneDeep(this.state.dataset)
        // let datasetMetric = _.cloneDeep(this.state.datasetMetric)

        let data = { ...this.state.data }; // for objects
        // let dataset = { ...this.state.dataset }; // for objects
        // let datasetMetric = { ...this.state.datasetMetric }; // for objects
        let enabledCount = this.state.enabledCount
        let metaDataCount = this.state.metaDataCount
        let datasetMetricCopy;
        return (
            <>
            {
            dataset.metric_info.map((datasetMetric, index) => {
                datasetMetricCopy = { ...datasetMetric }; // for objects
                let datasetThresholdData = {
                    tenant_id: Number(localStorage.getItem('team_id')),
                    env_id: data.env_id,
                    integration_id: dataset.integration_id,
                    data_set_id: dataset.dataset_id,
                    attribute_id: null,
                    is_enable: datasetMetricCopy.is_enable,
                    metrics_id: datasetMetricCopy.metrics_id,
                    metrics_name: datasetMetricCopy.metrics_name,
                    update_type: "dataset_threshold",
                    threshold_value: datasetMetricCopy.threshold_value,
                    all_attribute_enable: datasetMetricCopy.all_attribute_enable,
                    target_data_set_id: null
                }

                let checked = false
                let disabled = false

                if (datasetMetricCopy['payload'] !== undefined) {
                    if (datasetMetricCopy['payload']['checks'] !== undefined) {
                        datasetThresholdData["check_value"] = datasetMetricCopy['payload']['checks']
                    }
                    if (datasetThresholdData['metrics_name'] === "Row Count" || datasetThresholdData['metrics_name'] === "Data Drift") {
                        datasetThresholdData['window_count'] = datasetMetricCopy['payload']['window_count']
                    }


                    if (datasetMetricCopy['payload']['target_dataset_id'] !== undefined && datasetMetricCopy['payload']['target_dataset_id'] !== null) {
                        dataset['target_data_set_id'] = datasetMetricCopy['payload']['target_dataset_id']

                        datasetThresholdData['target_data_set_id'] = datasetMetricCopy['payload']['target_dataset_id']
                    }

                }

                dataset['threshold_value'] = datasetThresholdData['threshold_value']
                if (datasetMetricCopy['target_data_set_id'] !== undefined && datasetMetricCopy['target_data_set_id'] !== null) {
                    dataset['target_data_set_id'] = datasetMetricCopy['target_data_set_id']
                    datasetThresholdData['target_data_set_id'] = datasetMetricCopy['target_data_set_id']
                }

                if (datasetThresholdData['is_enable'] === 1 || datasetThresholdData['is_enable'] === true) {
                    checked = true
                }

                if (datasetThresholdData['is_enable'] === "NA") {
                    disabled = true
                }

                datasetThresholdData['checked'] = checked
                let popover_title = DATA_CONSISTENCY_METRICS.includes(datasetThresholdData['metrics_name']) ?    "Enable/Schedule and Edit " : "Edit Schedule and ";

                // <td className={metricClassMapping[datasetMetricCopy['metrics_name']]['tableClass']}>
                return (
                <>
                 <td className={metricClassMapping[datasetMetricCopy['metrics_name']]['tableClass']} >
                    <div className="metric-toggle-switch top-level-node">
                        <div className="metric-toggle-switch__left">
                            <RcTooltip
                                overlay={
                                    <div>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Environment : </strong></label>{this.state.metaDataMapping[data.env_id]}</p>
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Datasource : </strong></label>{this.state.metaDataMapping[data.integration_id]}</p>
                                        {/* <p className="mb-0"><label className="mb-0 mr-1"><strong>Enabled Attributes :</strong></label><span className="font-weight-semibold">{(enabledCount['attributes'][datasetMetricCopy['metrics_name']] === undefined ? 0 : enabledCount['attributes'][datasetMetricCopy['metrics_name']]['enabled'])}/{metaDataCount['attributes']}</span></p> */}
                                        <p className="mb-0"><label className="mb-0 mr-1"><strong>Enabled Attributes :</strong></label><span className="font-weight-semibold">{enabledCount !== "" ? (enabledCount['attributes'] === undefined || enabledCount['attributes'][dataset.dataset_id] === undefined || enabledCount['attributes'][dataset.dataset_id][datasetMetricCopy['metrics_name']] === undefined || enabledCount['attributes'][dataset.dataset_id][datasetMetricCopy['metrics_name']]['enabled'] === undefined ? 0 : enabledCount['attributes'][datasetMetricCopy['metrics_name']]['enabled']) + "/" + metaDataCount['attributes'] : ""}</span></p>
                                    </div>
                                }
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <i className="icon fs-10"><FontAwesomeIcon icon={faLayerGroup} /></i>
                            </RcTooltip>
                        </div>

                        <div class="custom-control custom-switch custom-switch-sm">
                            <input type="checkbox"
                                id={"datasetToggle" + dataset.dataset_id + (datasetMetricCopy.metrics_id === undefined ? dataset.dataset_id + datasetMetricCopy.dummyId : datasetMetricCopy.metrics_id)}
                                data-popup={"dataset_" + dataset.dataset_id + '_' + datasetMetricCopy.metrics_id}
                                checked={checked}
                                className="custom-control-input "
                                disabled={disabled || datasetThresholdData['metrics_name'] === "Row Count"}
                                onChange={(e) => {
                                    checked = e.target.checked
                                    let id = "#" + e.target.id
                                    datasetThresholdData["update_type"] = "dataset_status"
                                    datasetThresholdData['checked'] = checked
                                    $(id).prop("checked", !checked);

                                    if (this.props.activeMetrics[datasetThresholdData['metrics_name']] === -1) {
                                        this.setState({ showAlertModel: !this.state.showAlertModel, confirmationData: datasetThresholdData, toggleId: id })
                                        return null
                                    }

                                    if (DATA_CONSISTENCY_METRICS.includes(datasetThresholdData['metrics_name']) && checked === true && datasetThresholdData['target_data_set_id'] === null) {
                                        if (e.target.getAttribute('data-popup') !== undefined && e.target.getAttribute('data-popup') !== null) {
                                            document.getElementById(e.target.getAttribute('data-popup')).click()
                                            this.setState({ confirmationData: datasetThresholdData, toggleId: id, initialEnable: true })
                                            return;
                                        }
                                    }
                                    this.setState({ showConfirmation: true, confirmationData: datasetThresholdData, toggleId: id })
                                }}

                            /><label class="custom-control-label" for={"datasetToggle" + dataset.dataset_id + (datasetMetricCopy.metrics_id === undefined ? dataset.dataset_id + datasetMetricCopy.dummyId : datasetMetricCopy.metrics_id)}>&nbsp;</label>

                        </div>
                        <div className={disabled === true ? "clickpopover" + datasetThresholdData.data_set_id + "_" + datasetThresholdData.metrics_id + "_ metric-toggle-switch__right d-none" : "clickpopover" + datasetThresholdData.data_set_id + "_" + datasetThresholdData.metrics_id + "_ metric-toggle-switch__right"}>
                            <OverlayTrigger
                                trigger="click"
                                placement='auto'
                                value={dataset}
                                disable={this.props.activeMetrics[datasetThresholdData['metrics_name']] === -1}
                                rootClose={!this.state.datasetScheduleConfirmation}
                                onToggle={(e) => {
                                    this.popoverAction(dataset);
                                }}

                                overlay={
                                    this.datasetPopover(data, dataset, datasetMetricCopy, datasetThresholdData, popover_title)
                                }
                            >
                                <RcTooltip
                                    disable={this.props.activeMetrics[datasetThresholdData['metrics_name']] === -1}
                                    overlay={
                                        <span>Edit Metric</span>
                                    }
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                >
                                    {this.props.activeMetrics[datasetThresholdData['metrics_name']] === -1 ?
                                        <i>
                                            {/* <FontAwesomeIcon icon={faClock} /> */}
                                        </i>
                                        :
                                        <i
                                            id={"dataset_" + dataset.dataset_id + '_' + datasetMetricCopy.metrics_id}
                                            className={"clock_icon" + datasetThresholdData.data_set_id + "_" + datasetThresholdData.metrics_id + "_ icon"}
                                            onClick={e =>
                                                //this.handleActiveIcon(_.cloneDeep(dataset))
                                                this.handleActiveIcon({ ...dataset }
)
                                            }
                                        >
                                            <FontAwesomeIcon icon={faClock} />
                                        </i>
                                    }
                                </RcTooltip>
                            </OverlayTrigger>
                            <Dropdown>
                                <Dropdown.Toggle variant="" className="p-0">
                                    <RcTooltip
                                        overlay={
                                            <span>Sort</span>
                                        }
                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                    >
                                        {this.props.activeMetrics[datasetThresholdData['metrics_name']] === -1
                                            ?
                                            <i>

                                            </i>
                                            :
                                            <i className={this.state.isIconActive ? "icon fs-10 icon--is-active" : "icon fs-10"}>
                                                <FontAwesomeIcon className="m-0" icon={faEllipsisV} />
                                            </i>
                                        }
                                    </RcTooltip>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        disabled={datasetThresholdData['metrics_name'] === "Recency" || datasetThresholdData['metrics_name'] === "Row Count"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleSort(datasetThresholdData)
                                        }}
                                        href="#!">Sort by enabled attributes</  Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </td>
                </>)
            })}

                <Modal className="schedule-metric-alert" show={this.state.datasetScheduleConfirmation} centered>
                    <button
                        type="button"
                        class="btn btn-link"
                        onClick={(e) => {
                            this.setState({ disableSave: false })
                            this.returnDatasetSchedule(this.state.datasetConfirmationData);
                        }}
                    >
                        <i className="mr-2"

                        >
                            <svg width="24" height="24" className="d-inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.80078 13.0008L9.70078 15.9008C9.88411 16.0841 9.97578 16.3174 9.97578 16.6008C9.97578 16.8841 9.88411 17.1174 9.70078 17.3008C9.51745 17.4841 9.28411 17.5758 9.00078 17.5758C8.71745 17.5758 8.48411 17.4841 8.30078 17.3008L3.70078 12.7008C3.60078 12.6008 3.52995 12.4924 3.48828 12.3758C3.44661 12.2591 3.42578 12.1341 3.42578 12.0008C3.42578 11.8674 3.44661 11.7424 3.48828 11.6258C3.52995 11.5091 3.60078 11.4008 3.70078 11.3008L8.30078 6.70078C8.48411 6.51745 8.71745 6.42578 9.00078 6.42578C9.28411 6.42578 9.51745 6.51745 9.70078 6.70078C9.88411 6.88411 9.97578 7.11745 9.97578 7.40078C9.97578 7.68411 9.88411 7.91745 9.70078 8.10078L6.80078 11.0008H20.0008C20.2841 11.0008 20.5216 11.0966 20.7133 11.2883C20.9049 11.4799 21.0008 11.7174 21.0008 12.0008C21.0008 12.2841 20.9049 12.5216 20.7133 12.7133C20.5216 12.9049 20.2841 13.0008 20.0008 13.0008H6.80078Z" fill="#969696" />
                            </svg>
                        </i>Back
                    </button>
                    <Modal.Body>
                        <div className="schedule-metric-alert__icon">
                            <Image src="icon_add_event_danger" alt="" />
                        </div>
                        <h4>Are you sure you want to change the schedule for profiling?</h4>
                        <div className="schedule-metric-alert__box">
                            <div className="schedule-metric-list">
                                <div className="schedule-metric-list__item">
                                    <i className="schedule-metric-list__icon text-danger">
                                        <FontAwesomeIcon icon={faClock} />
                                    </i>
                                    <div className="schedule-metric-list__content">
                                        <h5>Schedule</h5>

                                        {this.state.allAttributes === true ?
                                            <p>All the metric will be computed for “{this.state.dataset['dataset_name']}” and all its {this.state.dataset['attribute_list'].length} attributes at the chosen times.</p>
                                            :
                                            <p>All the metric will be computed for “{this.state.dataset['dataset_name']}” at the chosen times.</p>
                                        }
                                    </div>
                                </div>
                                <hr className="w-100 my-2" />
                                <div className="schedule-metric-list__item">
                                    <i className="schedule-metric-list__icon text-danger">
                                        <svg width="18" height="18" class="d-block" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM6 7.5C5.7875 7.5 5.60938 7.57188 5.46562 7.71562C5.32187 7.85938 5.25 8.0375 5.25 8.25V12C5.25 12.2125 5.32187 12.3906 5.46562 12.5344C5.60938 12.6781 5.7875 12.75 6 12.75C6.2125 12.75 6.39062 12.6781 6.53438 12.5344C6.67812 12.3906 6.75 12.2125 6.75 12V8.25C6.75 8.0375 6.67812 7.85938 6.53438 7.71562C6.39062 7.57188 6.2125 7.5 6 7.5ZM9 5.25C8.7875 5.25 8.60938 5.32187 8.46563 5.46562C8.32188 5.60938 8.25 5.7875 8.25 6V12C8.25 12.2125 8.32188 12.3906 8.46563 12.5344C8.60938 12.6781 8.7875 12.75 9 12.75C9.2125 12.75 9.39063 12.6781 9.53438 12.5344C9.67813 12.3906 9.75 12.2125 9.75 12V6C9.75 5.7875 9.67813 5.60938 9.53438 5.46562C9.39063 5.32187 9.2125 5.25 9 5.25ZM12 9.75C11.7875 9.75 11.6094 9.82188 11.4656 9.96563C11.3219 10.1094 11.25 10.2875 11.25 10.5V12C11.25 12.2125 11.3219 12.3906 11.4656 12.5344C11.6094 12.6781 11.7875 12.75 12 12.75C12.2125 12.75 12.3906 12.6781 12.5344 12.5344C12.6781 12.3906 12.75 12.2125 12.75 12V10.5C12.75 10.2875 12.6781 10.1094 12.5344 9.96563C12.3906 9.82188 12.2125 9.75 12 9.75Z" fill="currentColor" />
                                        </svg>
                                    </i>
                                    <div className="schedule-metric-list__content">
                                        <h5>{(this.state.datasetConfirmationData !== null) && (this.state.datasetConfirmationData['metrics_name'] === "Unique Values" ? "Uniqueness" : this.state.datasetConfirmationData['metrics_name'])} metric</h5>
                                        {this.state.allAttributes === true ?
                                            <p>The configuration will be applied to “{this.state.dataset['dataset_name']}” (and all of its {this.state.dataset['attribute_list'].length} attributes)</p>
                                            :
                                            <p>The configuration will be applied to “{this.state.dataset['dataset_name']}”</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <p>Clicking <strong>"Confirm"</strong> will start your scheduled time immediately. You'll also receive alerts through your configured alert system.</p>
                        <button
                            type="button"
                            id="save-btn"
                            className="btn btn-primary btn-block btn-circle"
                            disabled={this.state.disableEditConfirmation}
                            onClick={(e) => {
                                this.setState({ disableEditConfirmation: true })
                                if (this.state.initialEnable === true) {
                                    this.closeConfirmation();
                                }
                                this.updateThresholdData(this.state.datasetConfirmationData, e);
                            }}>
                            Confirm
                        </button>
                        <button
                            type="button"
                            id="close-btn"
                            className="btn btn-light-gray btn-block btn-circle"
                            onClick={(e) => {
                                this.setState({ disableSave: false })
                                this.updateThresholdData(this.state.datasetConfirmationData, e);
                            }}>
                            cancel
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmation} centered>
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger"><FontAwesomeIcon icon={this.state.confirmationData['checked'] === true ? faToggleOn : faToggleOff} /></i>
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Are you sure you want to {this.state.confirmationData['checked'] === true ? "enable" : "disable"} {this.state.confirmationData['metrics_name'] === "Unique Values" ? "Uniqueness" : this.state.confirmationData['metrics_name']} metric ?</h4>  {/* for <strong>" {this.state.metaDataMapping[this.state.confirmationData['data_set_id']]}"</strong> */}
                                <p className="mb-3"><strong className="font-weight-semibold text-blue">{this.state.confirmationData['metrics_name'] === "Unique Values" ? "Uniqueness" : this.state.confirmationData['metrics_name']}</strong> will be {this.state.confirmationData['checked'] === true ? "enabled" : "disabled"} for dataset <strong>{this.state.dataset['dataset_name']}</strong></p>
                                {datasetMetricCopy['metrics_name'] !== "Row Count" &&
                                    datasetMetricCopy['metrics_name'] !== "Recency" &&
                                    <>
                                        <hr className="w-75" />
                                        <div class="custom-control custom-checkbox flex-shrink-0 d-inline-flex">
                                            <input
                                                type="checkbox"
                                                checked={this.state.allAttributesChecked}
                                                onChange={(e) => {
                                                    this.setState({ allAttributesChecked: !this.state.allAttributesChecked })
                                                }}
                                                class="custom-control-input" id="allDay" />
                                            <label class="custom-control-label" for="allDay">
                                                {this.state.confirmationData['checked'] === true ? "Enable " : "Disable "}{this.state.confirmationData['metrics_name'] === "Unique Values" ? "Uniqueness" : this.state.confirmationData['metrics_name']} for all <strong className="font-weight-semibold">{this.state.dataset['attribute_list'].length}</strong> attributes
                                            </label>
                                        </div>
                                        <hr className="w-75" />
                                    </>
                                }
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-2 pb-4">
                                <button
                                    type="button"
                                    id="close-btn"
                                    className="btn btn-outline btn-grey btn-circle mr-2"
                                    onClick={(e) => { this.closeConfirmation(e); }}
                                >Cancel</button>

                                <button
                                    type="button"
                                    id="save-btn"
                                    className="btn btn-primary btn-circle mr-2"
                                    disabled={this.state.disableToggleConfirmation}
                                    onClick={(e) => {
                                        this.setState({ disableToggleConfirmation: true })
                                        this.closeConfirmation(e)
                                    }}
                                >Confirm</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* datasource/dataset/attribute level */}
                <Modal show={this.state.showAlertModel} centered>
                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger"><FontAwesomeIcon icon={faToggleOn} /></i>
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Global {this.state.confirmationData['metrics_name']} metric is disabled</h4>
                                <p>You need to enable the Global  {this.state.confirmationData['metrics_name']} metric before activating it at the dataset level.</p>
                                {/* <p>Please enable the Global  {this.state.confirmationData['metrics_name']} metric in the default metrics page to proceed.</p> */}
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-0 pb-4">
                                <button
                                    type="button"
                                    id="close-btn"
                                    className="btn btn-outline btn-grey btn-circle mr-2"
                                    onClick={this.closeAlertmodel}
                                >Cancel</button>

                                <button
                                    type="button"
                                    id="save-btn"
                                    className="btn btn-primary btn-circle mr-2"
                                    onClick={this.goToMetricPage}
                                >Go Back</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );

    }
}
