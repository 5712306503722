import React from 'react';
import DqListViewContent from "../pipelineListView";
// import DqGridViewContent from "../dqGridView";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {
    extractDatasetInfoForMonitorListView,
    getMonitorTabDQListViewTableData
} from "../../utils/common_utils";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";


class PipelinePerformance extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);

        const metaDataMapping = this.props.dataModule.metaData;
        const monitorDQListViewData = this.props.monitorModule.monitorDQListView;
        let showLoader = true;
        if (monitorDQListViewData === undefined || monitorDQListViewData === null) {
            showLoader = true;
        } else {
            showLoader = !monitorDQListViewData.apiFetchOver;
        }

        let metricsList = this.props.monitorModule.metrics;
        const mappedDatasetInfo = extractDatasetInfoForMonitorListView(metaDataMapping);
        const reqTableData = getMonitorTabDQListViewTableData(mappedDatasetInfo, monitorDQListViewData, this.props.monitorModule, this.props.timelinessData);


        const listViewTableData = reqTableData.tableData;
        this.state = {
            activeTabKey: "dq_list_view",
            listViewTableData: listViewTableData,
            metricsList: metricsList,
            showLoader: showLoader,
            timelinessData: this.props.timelinessData,
            tableRowCount: listViewTableData.length,
            mappedDatasetInfo: mappedDatasetInfo,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let propsMonitorModule = props.monitorModule;
        const monitorDQListViewData = propsMonitorModule.monitorDQListView;
        if (monitorDQListViewData === undefined || monitorDQListViewData === null) {
            return null;
        }

        // If API fetch is over, we can hide the loader - showLoader => False
        let showLoader = !monitorDQListViewData.apiFetchOver;
        if (state.showLoader !== showLoader || state.timelinessData !== props.timelinessData) {
            // API has fetched some new data. So recompute and update the table data
            const metaDataMapping = props.dataModule.metaData;
            const mappedDatasetInfo = extractDatasetInfoForMonitorListView(metaDataMapping);
            const reqTableData = getMonitorTabDQListViewTableData(mappedDatasetInfo, monitorDQListViewData, props.monitorModule, props.timelinessData);

            const listViewTableData = reqTableData.tableData;
            const metricsList = props.monitorModule.metrics

            return {
                showLoader: showLoader,
                listViewTableData: listViewTableData,
                tableRowCount: listViewTableData.length,
                mappedDatasetInfo: mappedDatasetInfo,
                metricsList: metricsList
            }
        }


        return null;
    }


    changeTab(tab) {
        this.setState({activeTabKey: tab});
    }


    render() {

        return (
            <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                <div className="tabbable-line mb-3">
                    <Nav variant="tabs" id="page-nav-tabs">
                        {/* <Nav.Item>
                            <Nav.Link eventKey="dq_grid_view">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </i>
                                         Chart View</span>
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link eventKey="dq_list_view">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faList}/>
                                        </i>List View</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                            <div className="tab-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="dq_grid_view">
                                        <div className="portlet">
                                            <div className="portlet-body">
                                                {/* <DqGridViewContent integrationsMap={this.props.integrationsMap}
                                                                   mappedDatasetInfo={this.state.mappedDatasetInfo}
                                                                   metricsList={this.state.metricsList}/> */}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dq_list_view">
                                                <DqListViewContent showLoader={this.state.showLoader}
                                                                   monitorModule={this.props.monitorModule}
                                                                   timelinessData={this.state.timelinessData}
                                                                   firstDatasetUp={this.props.firstDatasetUp}
                                                                   key={`mlv_${this.state.tableRowCount}`}
                                                                   mappedDatasetInfo={this.state.mappedDatasetInfo}
                                                                   metricsList={this.state.metricsList}
                                                                   listViewTableData={this.state.listViewTableData}/>

                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
            </Tab.Container>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(PipelinePerformance);
