import React from 'react';
import Image from '../../components/image';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import DateRangePicker from "react-bootstrap-daterangepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar,faCheck} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {DISPLAY_DATE_FORMAT} from "../../utils/constant";
import {getDefaultDateRangeOptions} from "../../utils/common_utils";
import {getPlaceholder,Option, handleTime, getOptions} from "./filter_common_utils";
import CustomSelectNestedCheckbox from "./customSelectNestedCheckbox";
import CustomSelect from "./customSelect";
import _ from 'lodash';

class AdvancedFilterModel extends React.Component{
      constructor(props, context) {
        super(props, context);
        this.changeModel = this.changeModel.bind(this);
        this.getOptions = getOptions.bind(this);
        this.handleTime = handleTime.bind(this);
        this.changeMetric = this.changeMetric.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fillDetails = this.fillDetails.bind(this);
        this.getPlaceholder = getPlaceholder.bind(this);
        this.changeAttributes = this.changeAttributes.bind(this);
        this.handleCheckClickErrors = this.handleCheckClickErrors.bind(this);
        let startDate = moment().subtract(9, 'days').set({"hour": 0, "minute": 0, "seconds": 0});
        let endDate = moment().endOf('day').set({"hour": 23, "minute": 59, "seconds": 59});
        this.state = {
              startDate: startDate,
              endDate: endDate,
              selectedModel: [],
              selectedAttributes: [],
              attributeOptions: [],
              showErrorsOnly: true,
              selectedMetrics: [],
              showAttributes: true,
              showDropDown: false,
              initial: {
                 model: "",
                 metric: "",
                 attributesModel: ""

              }
        }

      }

      componentDidMount() {
          this.fillDetails();

      }

      changeAttributes(selected){
         this.setState({selectedAttributes: selected});
      }




      fillDetails(){
          let metricOptions = []
          let initial = this.state.initial
          let selectedMetrics = []
          let selectedModel = []
          if(this.props.underPerformModelIDs !== undefined && this.props.underPerformModelIDs.length > 0) {
              let underPerformModelIDs = this.props.underPerformModelIDs
              initial.model = underPerformModelIDs[0]
              selectedModel = this.props.mlModelOptions.filter(x=>underPerformModelIDs.includes(x.value))
              metricOptions = this.getOptions(selectedModel, this.props.customMetricsOptions);
              selectedMetrics = _.cloneDeep(metricOptions)
              initial.metric = metricOptions[0].value
              selectedMetrics = [{"label": "All", "value": "*", "type": "*"}].concat(selectedMetrics)
              if(selectedModel.length === this.props.mlModelOptions.length) {
                selectedModel = [{"label": "All", "value": "*", "type": "*"}].concat(selectedModel)
              }
              this.setState({initial:initial,
                             selectedModel: selectedModel,
                             selectedMetrics: selectedMetrics,
                             });
          }else if(this.props.selectedMlModel !== null){
              let propsSelectedMlModel = this.props.mlModelOptions.filter(x=>Number(x.value) === Number(this.props.selectedMlModel[0].value))

              if (propsSelectedMlModel.length > 0) {
                  selectedModel = propsSelectedMlModel
                  metricOptions = this.getOptions(selectedModel, this.props.customMetricsOptions);
                  selectedMetrics = _.cloneDeep(metricOptions)
                  selectedMetrics = [{ "label": "All", "value": "*", "type": "*" }].concat(selectedMetrics)
                  initial.model = propsSelectedMlModel[0].value
                  initial.metric = metricOptions[0].value
                  if (selectedModel.length === this.props.mlModelOptions.length) {
                      selectedModel = [{ "label": "All", "value": "*", "type": "*" }].concat(selectedModel)
                  }
                  this.setState({
                      initial: initial,
                      selectedModel: selectedModel,
                      selectedMetrics: selectedMetrics,
                  })
              }

         }
         if(this.props.startDate !== undefined && this.props.endDate !== undefined) {
           this.setState({startDate: this.props.startDate, endDate: this.props.endDate})
         }
         if(this.props.type === "modelError") {
           let attributeOptions = []
           let propsAttributeOptions = this.props.attributeOptions
           for(let i=0;i<selectedModel.length;i++) {
                    if(selectedModel[i].label === "All") {
                       continue
                    }
                    let modelId = Number(selectedModel[i]["value"])
                    let selected = propsAttributeOptions[modelId]
                    attributeOptions = attributeOptions.concat(selected)
           }

           let selectedAttributes = [{"label": "All", "value": "*", "type": "*"}].concat(_.cloneDeep(attributeOptions))
           this.setState({attributeOptions: attributeOptions, selectedAttributes: selectedAttributes});
         }
      }

      handleSubmit(event) {
          this.setState({showDropDown: false});

           if(this.props.type === "modelError") {
             this.props.updateCharts(this.state.selectedModel, this.state.selectedMetrics,
                                             this.state.selectedAttributes, this.state.startDate, this.state.endDate, this.state.showErrorsOnly);

           }else {
              this.props.changeModelInFullScreen(this.state.selectedModel, this.state.selectedMetrics,
                                             this.state.startDate, this.state.endDate);

           }

      }

      changeModel(event){
          this.setState({selectedModel: event, selectedMetrics: []});
          if(this.props.type === "modelError") {
               let attributeOptions = []
               let propsAttributeOptions = this.props.attributeOptions
               for(let i=0;i<event.length;i++) {
                    if(event[i].label === "All") {
                       continue
                    }
                    let modelId = Number(event[i]["value"])
                    let selected = propsAttributeOptions[modelId]
                    attributeOptions = attributeOptions.concat(selected)
               }

               this.setState({attributeOptions: attributeOptions, selectedAttributes: []});
          }

      }


      changeMetric(event) {
         this.setState({selectedMetrics: event});
         if(this.props.type === "modelError"){
           let filtered = event.filter(x=>["type4", "type5"].includes(x.value))
           let showAttributes = filtered.length > 0 ? true : false;

           this.setState({showAttributes: showAttributes});
           if(!showAttributes) {
             this.setState({selectedAttributes: []});
           }

         }
      }

      reset() {

        this.setState({
            initial: {
                model: "",
                metric: "",
                attributesModel: ""

             },
            selectedMetrics:[],
            selectedAttributes:[],
            selectedDataSet:[],
            selectedModel:[],
            showAttributes: false

        });
    }
      handleCheckClickErrors(){
         this.setState({showErrorsOnly: !this.state.showErrorsOnly});
      }

      render() {
            let ranges = getDefaultDateRangeOptions();
            let metricLabel = this.props.type === "modelError" ? "Metric" : "Performance Metric";
            let modelSchema = yup.object({
                model: yup.string().required("Model is a required field"),
                metric: yup.string().required(`${metricLabel} is a required field`),
            });
            let metricOptions = this.getOptions(this.state.selectedModel, this.props.customMetricsOptions);

            return(<Formik
                        enableReinitialize
                        validationSchema={modelSchema}
                        onSubmit={this.handleSubmit}
                        initialValues={this.state.initial}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setValues,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    }) => (<><Dropdown className="qd_filter-btn d-inline" alignRight show={this.state.showDropDown}>
                                    <Dropdown.Toggle onClick={()=>{this.state.showDropDown === true ? this.setState({showDropDown: false}) : this.setState({showDropDown: true})}} id="dropdown-autoclose-false" className="btn-light">
                                    <Image
                                        src="icon_filter"
                                    /><span className="ml-0 ml-lg-2">Filters</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right">
                                    <Form noValidate name="signup"
                                              className="login-form needs-validation"
                                              onSubmit={handleSubmit}>
                                        <div className="qd_filter__body">
                                            <h4 className="filter_title">Select Date</h4>
                                                <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                                                startDate={this.state.startDate}
                                                                onApply={this.handleTime}
                                                                endDate={this.state.endDate}
                                                                key={`date_${this.state.keyData}`}
                                                                ranges={ranges}>
                                                    <i>
                                                        <FontAwesomeIcon icon={faCalendar}/>
                                                    </i>
                                                    <span
                                                        className="ml-2 d-none d-md-inline-block">
                                                        {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                                    </span>
                                                </DateRangePicker>
                                            <Form.Group>
                                                <Form.Label>Model</Form.Label>
                                                    <CustomSelect
                                                      isMulti
                                                      isClearable={false}
                                                      name="model"
                                                      id="model"
                                                      closeMenuOnSelect={false}
                                                      hideSelectedOptions={false}
                                                      isInvalid={errors.model && touched.model}
                                                      components={{ Option }}
                                                      options={this.props.mlModelOptions}
                                                      onChange={selectedOption => {
                                                                    if(selectedOption !== null && selectedOption.length !== 0) {
                                                                       handleChange("model")(selectedOption[0].value.toString());
                                                                    }else {
                                                                        handleChange("model")("");
                                                                    }
                                                                    this.changeModel(selectedOption);
                                                                    }}
                                                        allowSelectAll={true}
                                                        placeholder={this.getPlaceholder("Choose Model", this.state.selectedModel, this.props.mlModelOptions)}
                                                        value={this.state.selectedModel}
                                                        controlShouldRenderValue={false}
                                                        classNamePrefix='select-control'
                                                    />
                                                <ErrorMessage component="div" className="error-text" name="model" />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{metricLabel}</Form.Label>
                                                    <CustomSelectNestedCheckbox
                                                      isMulti
                                                      isClearable={false}
                                                      name="metric"
                                                      id="metric"
                                                      type={this.props.type}
                                                      closeMenuOnSelect={false}
                                                      hideSelectedOptions={false}
                                                      isInvalid={errors.metric && touched.metric}
                                                      components={{ Option }}
                                                      options={metricOptions}
                                                      onChange={selectedOption => {
                                                                    if(selectedOption !== null && selectedOption.length !== 0) {
                                                                       handleChange("metric")(selectedOption[0].value.toString());
                                                                    }else {
                                                                        handleChange("metric")("");
                                                                    }
                                                                    this.changeMetric(selectedOption);
                                                                    }}
                                                        allowSelectAll={true}
                                                        placeholder={this.getPlaceholder(`Choose ${metricLabel}`, this.state.selectedMetrics, metricOptions)}
                                                        value={this.state.selectedMetrics}
                                                        controlShouldRenderValue={false}
                                                        classNamePrefix='select-control'
                                                    />
                                                <ErrorMessage component="div" className="error-text" name="metric" />
                                            </Form.Group>
                                            {this.props.type === "modelError" && this.state.showAttributes ? <Form.Group>
                                                <Form.Label>Attribute</Form.Label>
                                                    <CustomSelect
                                                      isMulti
                                                      isClearable={false}
                                                      name="attributesModel"
                                                      id="attributesModel"
                                                      closeMenuOnSelect={false}
                                                      hideSelectedOptions={false}
                                                      isInvalid={errors.attributesModel && touched.attributesModel}
                                                      components={{ Option }}
                                                      options={this.state.attributeOptions}
                                                      onChange={selectedOption => {
                                                                    if(selectedOption !== null && selectedOption.length !== 0) {
                                                                       handleChange("attributesModel")(selectedOption[0].value.toString());
                                                                    }else {
                                                                        handleChange("attributesModel")("");
                                                                    }
                                                                    this.changeAttributes(selectedOption);
                                                                    }}
                                                        allowSelectAll={true}
                                                        placeholder={this.getPlaceholder("Choose Attributes", this.state.selectedAttributes, this.state.attributeOptions)}
                                                        value={this.state.selectedAttributes}
                                                        controlShouldRenderValue={false}
                                                        classNamePrefix='select-control'
                                                    />
                                                <ErrorMessage component="div" className="error-text" name="attributesModel" />
                                            </Form.Group> : ""}
                                            {this.props.type === "modelError" ?
                                            <Form.Group>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                            <Form.Control
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                checked={this.state.showErrorsOnly}
                                                                onChange={this.handleCheckClickErrors}
                                                                name="showErrorsOnly"
                                                                id="modelError" />
                                                            <Form.Label className="custom-control-label" htmlFor="modelError">Show Errors Only</Form.Label>
                                            </div>
                                            </Form.Group>: ""}
                                        </div>
                                        <div className="d-flex justify-content-between border-top p-3 my-1">
                                                <button className="btn btn-link" onClick={()=>{this.reset(); setValues(this.state.initial)}}>Reset</button>
                                                 <div>
                                            <Button className="btn-outline btn-grey btn-circle mr-2" onClick={()=>{this.setState({showDropDown: false})}}>Cancel</Button>
                                            <Button type="submit" className="btn-primary btn-circle"><FontAwesomeIcon icon={faCheck}/> Apply</Button>
                                            </div>
                                        </div>
                                        </Form>
                                    </Dropdown.Menu>
                                </Dropdown>

                                </>
                                )}
                    </Formik>

            );
      }

}

export default AdvancedFilterModel;