import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import BreadCrumb from "../components/breadCrumb";
import NavigationHeader from "../components/navigationHeader";
import Image from "./image";
import {CopyRightsFooter} from "./copyrights";
import QualdoPlanComponent from "./planComponents/qualdoPlanComponent";
import {IS_NONE_CHECK, QualdoEditions, QualdoPlanCycle, LOCAL_STORE_KEY} from "../utils/constant";
import {getApi, getPaymentDetailsTableData} from "../utils/event_handling";
import Load from "./loadAction";
import Toast from "react-bootstrap/Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getQualdoPlanCycle, isUserOnFreeTrial, toastObj, getFeatureAccess, decryptForLocalUsage, encryptForLocalUsage} from "../utils/common_utils";
import PaymentSuccess from "./paymentComponents/payment_success";
import $ from "jquery";
import PlanDowngradeModal from "./paymentComponents/planDowngradeModal";
import PlanChangeNotAllowed from "./paymentComponents/planChangeNotAllowed";


let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO

class ChoosePlan extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handlePlanTabChange = this.handlePlanTabChange.bind(this);
        this.setQualdoPlans = this.setQualdoPlans.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.setPaymentStatus = this.setPaymentStatus.bind(this);
        this.getApi = getApi.bind(this);
        this.getQualdoPlanCycle = getQualdoPlanCycle.bind(this);
        this.navigateToPaymentPage = this.navigateToPaymentPage.bind(this);
        let selectedPlanCycle = this.getQualdoPlanCycle();
        this.getQualdoPlanCycle = getQualdoPlanCycle.bind(this);
        this.changePaymentPageDetails = this.changePaymentPageDetails.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.showDowngradePopup = this.showDowngradePopup.bind(this);
        this.closeDowngradePopup = this.closeDowngradePopup.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.hideErrorMsg = this.hideErrorMsg.bind(this);
        let hideModelInfo = true
        let featureAccess = getFeatureAccess()
        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const modelInfo = featureAccess["models"];
            hideModelInfo = modelInfo === false;
        }


        this.state = {
            srcIsChoosePlan: true,
            hideModelInfo: hideModelInfo,
            error_message: null,
            showDowngradePopup: false,
            downgradePopupData: null,
            toastStatus: false,
            planDetails: null,
            reRenderNavComponent: false,
            paymentSuccess: false,
            qualdoPlanCycle: selectedPlanCycle
        }

    }

    changePaymentPageDetails(data){
        data["hideModelInfo"] = this.state.hideModelInfo
        this.props.route.history.push({pathname:'/payment', state: data});
    }

    setPaymentStatus(paymentSuccess) {
        let reRenderNavComponent = this.state.reRenderNavComponent;

        if(paymentSuccess) {
            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
            let warning_message = decrypt_data['warning_message']
            //  let warning_message = localStorage.getItem("warning_message");
            
             if (!IS_NONE_CHECK.includes(warning_message) && !['', 'undefined'].includes(warning_message)) {
                /**
                 * Setting warning_message as null and encrypting the data
                 * Storing in localStorage with updated value
                 */
                decrypt_data['warning_message'] = null
                let encrypt_data = encryptForLocalUsage(decrypt_data)
                localStorage.setItem(LOCAL_STORE_KEY,encrypt_data)
                // localStorage.removeItem("warning_message");
                reRenderNavComponent = !reRenderNavComponent;
             }
         }

        this.setState({
            paymentSuccess: paymentSuccess,
            reRenderNavComponent: reRenderNavComponent
        });
    }

    navigateToPaymentPage(planType) {
        localStorage.setItem("src_choose_plan", "true");
        localStorage.setItem("qualdoPlan", planType);
        this.getApi("get_payment_page_details");
        //window.location.href = "/payment";
    }

    showDowngradePopup(data) {
        this.setState({"showDowngradePopup": true, downgradePopupData: data});
    }

    closeDowngradePopup(){
        this.setState({"showDowngradePopup": false, downgradePopupData: null});
    }

    showErrorMessage(message){
        this.setState({"error_message": message});
    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }
    }

    closeToast() {
        this.setState({toastStatus: false}, this.getData);
    }

    componentDidMount() {
        let edition = localStorage.getItem("qualdoEdition");
        if (edition === undefined || edition === null) {
            edition = QualdoEditions.ML_EDITION;
        }

        this.getApi("fetchQualdoPlans", {"edition": edition});

        
        
            
       

    }
    hideErrorMsg() {
        this.setState({error_message: null})
    }

    setQualdoPlans(planInfo) {
        this.setState({planDetails: planInfo});
        localStorage.setItem("qualdoPlanDetails", JSON.stringify(planInfo));
    }

    handlePlanTabChange(key) {
        this.setState({qualdoPlanCycle: key});
        localStorage.setItem('qualdoPlanCycle', key);
    }

    render() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }

        $( document ).ready(function() {
        /*  Pricing sections responsive tab active */
            $( ".pricing-table ul" ).on( "click", "li", function() {
                var pos = $(this).index()+2;
                $("tr").find('td:not(:eq(0))').hide();
                $('td:nth-child('+pos+')').css('display','table-cell');
                $("tr").find('th:not(:eq(0))').hide();
                $('li').removeClass('active');
                $(this).addClass('active');
            });
        });

        let planDetails = this.state.planDetails;
        let freeTrialUser = isUserOnFreeTrial();



        return (
            <>
                {sessionToken === null ? (
                    
                    <header className="qd-header">
                        <div className="qd-header-container navbar navbar-expand-md">
                            <div className="qd-header__brand">
                                <a href="#!">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src={BRAND_LOGO}
                                    />
                                </a>
                            </div>
                        </div>
                    </header>
                ) : (
                    <NavigationHeader {...this.props} key={this.state.reRenderNavComponent}/>
                )}
                <main>
                    <div id="toastMsg">
                        <Toast className={this.state.toastClass}
                               onClose={() => this.closeToast()}
                               show={this.state.toastStatus} delay={5000} autohide>
                            <div className="d-flex align-items-center">

                                {
                                    this.state.toastStatus ?
                                        <i className="toast-icon">
                                            <FontAwesomeIcon icon={this.state.toastIcon}/>
                                        </i>
                                        :
                                        ''
                                }

                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button"
                                        onClick={this.closeToast}
                                        className="close"
                                        data-dismiss="toast"
                                        aria-label="Close"/>
                            </div>
                        </Toast>
                    </div>
                    <div>
                    {
                        this.state.showDowngradePopup === true && this.state.downgradePopupData !== null
                        &&
                        <PlanDowngradeModal data={this.state.downgradePopupData} 
                                            closeDowngradePopup={this.closeDowngradePopup} 
                                            showToast={this.showToast}/>
                    }

                    {
                        this.state.error_message !== null
                        &&
                        <PlanChangeNotAllowed error_message= {this.state.error_message} hideErrorMsg={this.hideErrorMsg}/>
                    }
                    </div>
                    <BreadCrumb icon="privacy" title="Choose Plan"/>
                    <div className="qd-container">
                        <div className="qd-body no-tabs p-3">
                            {this.state.paymentSuccess ?
                                <PaymentSuccess/>
                                :
                                <>
                                    <div className="heading-section">
                                        <h3 className="heading-section-title">
                                            Choose your plan
                                        </h3>

                                        {
                                            freeTrialUser ?
                                                <p>
                                                    You free trail has expired, please subscribe
                                                    <br/>
                                                    to one of the plans below to continue.
                                                </p>
                                                :
                                                ''
                                        }


                                    </div>

                                    <Tab.Container defaultActiveKey={this.state.qualdoPlanCycle}
                                                   onSelect={this.handlePlanTabChange}>
                                        <div className="text-center">
                                            <Nav variant="tabs" className="price_tab mt_70 wow fadeInUp">
                                                <Nav.Item>
                                                    <Nav.Link eventKey={QualdoPlanCycle.MONTHLY}>
                                                <span className="qd-menu__link-text">
                                                    Billed Monthly
                                                </span>
                                                    </Nav.Link>
                                                    <Nav.Link eventKey={QualdoPlanCycle.YEARLY}>
                                                <span className="qd-menu__link-text">
                                                    Billed Annually
                                                </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="tab-content price_content">
                                            <Tab.Content>
                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                          eventKey={QualdoPlanCycle.MONTHLY}>

                                                    {(planDetails === undefined ||
                                                        planDetails === null) ?
                                                        <Load/> :
                                                        <QualdoPlanComponent key={QualdoPlanCycle.MONTHLY}
                                                                             navigateToPaymentPage={this.navigateToPaymentPage}
                                                                             plan={QualdoPlanCycle.MONTHLY}
                                                                             hideModelInfo={this.state.hideModelInfo}
                                                                             details={planDetails[QualdoPlanCycle.MONTHLY]}
                                                                             showToast={this.showToast}
                                                                             setPaymentStatus={this.setPaymentStatus}
                                                                             closeToast={this.closeToast}
                                                        />
                                                    }

                                                </Tab.Pane>
                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                          eventKey={QualdoPlanCycle.YEARLY}>

                                                    {(planDetails === undefined ||
                                                        planDetails === null) ?
                                                        <Load/> :
                                                        <QualdoPlanComponent key={QualdoPlanCycle.YEARLY}
                                                                             plan={QualdoPlanCycle.YEARLY}
                                                                             hideModelInfo={this.state.hideModelInfo}
                                                                             details={planDetails[QualdoPlanCycle.YEARLY]}
                                                                             navigateToPaymentPage={this.navigateToPaymentPage}
                                                                             showToast={this.showToast}
                                                                             setPaymentStatus={this.setPaymentStatus}
                                                                             closeToast={this.closeToast}
                                                        />
                                                    }

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </>
                            }

                        </div>
                    </div>
                </main>
                <CopyRightsFooter/>
            </>
        );
    }
}

export default ChoosePlan;
