import React from "react";
import TableCellButton from "../../components/tableCellButton";
import {browserHistory} from 'react-router';

import {
    faCheckDouble,
    faTrash, faEdit
} from "@fortawesome/free-solid-svg-icons";
import {
    TABLE_COMP_MODELS_CLASS, TABLE_DELETE_CLASS,
    TABLE_EDIT_CLASS
} from "../../utils/constant";
// import ViewContentModelAB from "./viewContentModelAB";
import {handleDeleteForModelABConfig} from "../../utils/event_handling";
import ConfigureABTest from "./configureABTest";
import {DeleteWarningModal} from "../../components/DeleteWarningModal";

class ModelABEditActions extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.go_to_monitor_page = this.go_to_monitor_page.bind(this);
        this.state = {deleteButtonDisabled: false}
    }

    go_to_monitor_page() {
        const modelAbId = this.props.view_details.model_ab_id;
        browserHistory.push(`/monitor/modelPerf/mp_compare_view/${modelAbId}`);
    }

    render() {
        let matchingData = null;
        if (this.props.edit) {
            // Prepare data required for edit operation
            const modelAbId = this.props.view_details.model_ab_id;

            // This will get existing model AB data list, we will be using this to identify
            // pre-selected options
            const existingABData = this.props.modelABTableProps.getExistingABData();
            matchingData = existingABData.filter(x => x.model_ab_id === modelAbId)[0];
        }

        return (
            <div>
                {/*<TableCellButton toolTipContent={"View"}*/}
                {/*                 onClickFunction={this.props.viewShow}*/}
                {/*                 classNameValue={TABLE_VIEW_CLASS}*/}
                {/*                 buttonIconName={faEye}*/}
                {/*                 componentName={"View"}*/}
                {/*>*/}
                {/*</TableCellButton>*/}

                <TableCellButton toolTipContent={"Edit"}
                                 onClickFunction={this.props.showEdit}
                                 classNameValue={TABLE_EDIT_CLASS}
                                 buttonIconName={faEdit}
                                 componentName={"Edit"}
                >
                </TableCellButton>

                <TableCellButton toolTipContent={"Compare Models"}
                                 onClickFunction={this.go_to_monitor_page}
                                 classNameValue={TABLE_COMP_MODELS_CLASS}
                                 buttonIconName={faCheckDouble}
                                 componentName={"Compare Models"}
                >
                </TableCellButton>

                <TableCellButton toolTipContent={"Delete"}
                                 onClickFunction={this.props.enableDeleteWarning}
                                 isDisabled={this.state.deleteButtonDisabled}
                                 classNameValue={TABLE_DELETE_CLASS}
                                 buttonIconName={faTrash}
                                 componentName={"Delete"}
                >
                </TableCellButton>

                {/*{this.props.view ?*/}
                {/*    <ViewContentModelAB show={this.props.view}*/}
                {/*                        view_details={this.props.view_details}*/}
                {/*                        handleClose={this.props.viewClose}>*/}

                {/*    </ViewContentModelAB>*/}
                {/*    : ''*/}
                {/*}*/}

                <DeleteWarningModal delete_show={this.props.deleteWarningStatus}
                                    deleteClose={this.props.closeDeleteWarning}
                                    modalTitle={"Model AB configuration"}
                                    disableDelete={this.state.deleteButtonDisabled}
                                    handleDeleteFunction={handleDeleteForModelABConfig.bind(this, this.props)}
                >
                </DeleteWarningModal>

                {this.props.edit && matchingData !== null ?
                    <ConfigureABTest canShowABModal={this.props.edit}
                                     operation={"ab_edit"}
                                     existingConfig={matchingData}
                                     handleModelABAddition={this.props.modelABTableProps.handleModelABAddition}
                                     tableRowProps={this.props.modelABTableProps}
                                     closeModalFunction={this.props.closeEdit}>

                    </ConfigureABTest>
                    : ''
                }

            </div>
        );
    }
}

export default ModelABEditActions;
