import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';

class DefaultHome extends React.Component {
    render() {
        return (
            <div>
                <NavigationHeader/>
                <BreadCrumb/>
            </div>
        );
    }
}

export default DefaultHome;