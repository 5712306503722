import React from 'react';
import Image from './image';
// import {removeLocalStorageKeys, checkExpiryPowerBI, getProtocol} from "../utils/common_utils";
// import {browserHistory} from 'react-router';
const alertContent = {
   'issue'   : { 
       icon : 'icon_wifi_warning', 
       text : 'Try to refresh the page or feel free to report us if the problem persists.', 
       title : 'Oops, Something went wrong.',
       addonClass : 'internet-status--issue'
   },
}
class ErrorHandler extends React.Component {
     constructor(props) {
        super(props);
        this.refreshPage = this.refreshPage.bind(this);
   }
   // Roll back code
   //  componentWillUnmount() { 
   //       var element = document.getElementById("root");
   //       element.classList.add("main-wrap");
   //  }
   refreshPage() {
      window.location.reload(false);
    }
    render() {
      let status = 'issue'
      if(status === 'issue'){  // New Design Implementation 
      const internetStatus = alertContent[status];
      const { icon, text, title, addonClass } = internetStatus || {};
      return (
         navigator.onLine ? 
          <>
              <div className={`internet-status-alert ${addonClass}`}>
                  <div className="internet-status-alert__dialog">
                      <div className="internet-status-alert__icon">
                          <Image src={icon} alt="" />
                      </div>
                      <div className="internet-status-alert__content">
                          {status !== undefined && status === 'issue' ? <h4 className="internet-status-alert__headline">{title}</h4> :''}
                          <p className={status === 'issue' ? 'internet-status-alert__text opacity-75' : 'internet-status-alert__text'}>{text}</p>
                      </div>
                          <div className="internet-status-alert__action">
                              <button onClick={this.refreshPage} className="btn btn-link px-0">Refresh</button>
                          </div>
                  </div>
              </div>
          </> : ""
      )
      }else{  // Roll back code 
      var element = document.getElementById("root");
      element.classList.remove("main-wrap");
      return (
         <>
            <div className="opps-wrap">
               <div className="opps-content">
                  <div className="opps-infographic">
                      <Image className="opps-infographic_human" src="oopsHuman" />
                      <Image className="opps-infographic_globe" src="oopsGlobe" />
                  </div>
                  <div className="opps-text">
                    <h3>Oops! Something went wrong…</h3>
                    <p>Go back to the previous page and try again. If you think something is broken, report a problem.</p>
                  </div>
                  <div className="opps-button">
                    <button onClick={this.goToLogin} className="btn btn-primary btn-lg btn-circle shadow">Back to home</button>
                  </div>
               </div>
            </div>
         </>
      );
   }
  }
}
export default ErrorHandler;