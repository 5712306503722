import React from 'react';
import ChartBody from '../../components/chartBody';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import Load from '../../components/loadAction';
import { ENVIRONMENT, DATA_SOURCE, DATA_SET, ATTRIBUTE, ML_METRIC_2 } from '../../utils/constant';
import { MlFilterOptions } from '../components/mlFilterOptions';
import _ from 'lodash';
import Filter from '../components/filter';
import { connect } from "react-redux";
import { setMlDataSource, setMLModel, setChipData, setFilteredValues} from "../../redux/actions";
import moment from 'moment';
import CardHelpContent from '../../components/cardHelpContent';
import RcTooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faBezierCurve } from '@fortawesome/free-solid-svg-icons';
import ProgressStatus from '../../components/progressStatus';
import {
    filterInfoData_mlModel, parseMetaMapping,
    getTimeFilteredData_mlModel, formCardData_mlModel
} from '../../utils/common_utils';
import {
    METADATA_MAP_KEY_SEPARATOR, METADATA_MAP_VALUE_SEPARATOR
} from '../../utils/attribute_name_utils';
// import TreeFilter from "../data/treeFilter"


const renderTooltip = (props) => (
    <span {...props}>
      Need Help ?
    </span>
  );



class MlModel extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.setDataSource = this.setDataSource.bind(this);
        this.formCardData = this.formCardData.bind(this);
        this.setDefaultValuesInFilter = this.setDefaultValuesInFilter.bind(this);
        this.changeFilterValues = this.changeFilterValues.bind(this);
        this.formTableData = this.formTableData.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.setFilteredValues = this.setFilteredValues.bind(this);
        this.setChipData = this.setChipData.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
        this.filterInfoData_mlModel = filterInfoData_mlModel.bind(this);
        this.filterOptions = React.createRef();
        this.renderCards = this.renderCards.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.getTimeFilteredData_mlModel = getTimeFilteredData_mlModel.bind(this);
        this.formCardData_mlModel = formCardData_mlModel.bind(this);
        this.mapModelToDataSource = this.mapModelToDataSource.bind(this);
        this.state = {
            hideLastProfiledTime: false,
            isFilterMode: false,
            filteredValues:[],
            showModal: false,
            cardName: "",
//            chipData:[{
//                "key": this.props.dataModule.selectedMLModel,
//                "value": this.props.monitorModule.mlModelMapping[this.props.dataModule.selectedMLModel],
//                "type": DATA_SOURCE
//            }],
            cardData:[],
            tableData: {
                "headers":["DataSource","ML Model", "DataSet", "Attribute Name", "Metric", "Metric Value"],
                "data":[]
            },
            startDate: moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00'),
            endDate: moment().format('YYYY-MM-DD 23:59:59'),
            selectedDate: [],
            timeFilterApplied: false,
            modelDataSourceDetails: this.mapModelToDataSource(),
            selectedMLModel:null
        }
    }

    componentDidMount() {
        this.formCardData([],-1, this.props.dataModule.selectedMLModel);
        this.formTableData([],-1, this.props.dataModule.selectedMLModel);
        this.setDefaultValuesInFilter();
        this.getSelectedTime();
//        this.props.setMlChipData([]);
    }

    componentDidUpdate(prevProps) {
       if (prevProps.dataModule.info !== this.props.dataModule.info) {
            this.formCardData([],-1, this.props.dataModule.selectedMLModel);
            this.formTableData([],-1, this.props.dataModule.selectedMLModel);
            this.setDefaultValuesInFilter();
        //    this.props.setMlChipData([]);
        }
    }

    setDefaultValuesInFilter(){
        let environment = -1;
        let mlModel = -1;
        let dataSourceId;
        if(this.props.monitorModule.mlModelMapping !== undefined && this.props.dataModule.selectedMLModel !== undefined ){
            for (const [key, value] of Object.entries(this.props.monitorModule.mlModelMapping)) {
                if (key === this.props.dataModule.selectedMLModel.toString()) {
                    mlModel = {"label": value, "value": key};
                    dataSourceId = this.state.modelDataSourceDetails[key];
                }
            }
        }
        if (this.props.dataModule.environmentDataSourceMapping !== undefined) {
            for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
                if (dataSourceId !== undefined && value.includes(dataSourceId.toString())) {
                    environment = {"label": this.props.dataModule.environments[key], "value": key};
                }
            }
        }
        this.setChipData(environment, mlModel)

        this.setState({prefillEnvironment:environment, prefillMlModel:mlModel});
    }


    mapModelToDataSource() {
        let modelDetails = this.props.monitorModule.mlModelDetails;
        let modelToDataSource = {};
        modelDetails.forEach((modelDetail) => {
            modelToDataSource[modelDetail.ml_model_id] = modelDetail.integration_id;
        })
        if (modelToDataSource[this.props.dataModule.selectedMLModel] !== undefined){
            this.props.setMlDataSource(modelToDataSource[this.props.dataModule.selectedMLModel]);
        }
        return modelToDataSource;
    }

    changeTime(selectedValue, startDate, endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
        this.formCardData(this.state.filteredValues, -1, this.props.dataModule.selectedMLModel, true, startDate, endDate);
        this.formTableData(this.state.filteredValues, -1, this.props.dataModule.selectedMLModel, true, startDate, endDate);
        this.setState({startDate:startDate, endDate:endDate, timeFilterApplied: true});
    }

    getSelectedTime() {
        let timeList = [];
        let tempStartDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00');
        let tempEndDate = moment().format('YYYY-MM-DD 00:00:00');
        while(moment(tempStartDate).isSameOrBefore(moment(tempEndDate))) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }
        this.setState({selectedDate: timeList});

    }


    setChipData(environment, dataSource, dataSets, attributes) {
        let chipData = [];
        if (environment !== -1) {
            chipData.push({"key": environment.value, "value": environment.label, "type": ENVIRONMENT});
        }
        if (dataSource !== -1) {
            chipData.push({"key": dataSource.value, "value": dataSource.label, "type": DATA_SOURCE});
        }
        if (dataSets !== undefined && dataSets !== -1) {
            dataSets.forEach((dataSet) => {
                chipData.push({"key": dataSet.value, "value": dataSet.label, "type": DATA_SET});
            });
        }
        if (attributes !== undefined && attributes !== -1) {
            attributes.forEach((attribute) => {
                chipData.push({"key": attribute.value, "value": attribute.label, "type": ATTRIBUTE});
            });
        }
        this.props.setChipData(chipData);
    }

    removeChipData(key) {
        let chipData = this.props.dataModule.chipData;
        let chipDataAttribute;
        let checkdataSetAttributeMapping = this.props.dataModule.dataSetAttributeMapping;

        chipData = chipData.filter((item) => item.key !== key && (item.type === ENVIRONMENT || item.type === DATA_SOURCE || item.type === DATA_SET || (item.type === ATTRIBUTE && item.key !== "*")));

        if (checkdataSetAttributeMapping[key] !== undefined) {
            // While removing the dataset level key we have to remove the corresponding attributes key
            // Filtering the attribute keys for the particular dataset  
            chipDataAttribute = chipData.filter((item) => checkdataSetAttributeMapping[key].indexOf(item.key) > -1 && item.type === ATTRIBUTE);
            for (let i of chipDataAttribute) {
                // Here will exclude the attribute from chipData 
                chipData = chipData.filter((item) => item.key !== i.key);
            }
        }
        let filteredValues = [];
        let environment = chipData.filter((item) => item.type === ENVIRONMENT);
        let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
        if (environment.length === 0 || dataSource.length === 0) {
            chipData = [{
                "key": this.props.dataModule.selectedDataSource,
                "name": this.props.dataModule.integrations[this.props.dataModule.selectedDataSource],
                "type": DATA_SOURCE
            }];
            this.filterOptions.current.setDefaultValuesFromParent(this.state.prefillEnvironment,this.state.prefillDataSource,-1,-1);
        } else {
            let prefix = `${environment[0].key}.${this.props.dataModule.selectedDataSource}`;
            let dataSets = chipData.filter((item) => item.type === DATA_SET);
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            if (dataSets.length !== 0) {
                dataSets.forEach((dataSet) => {
                    if (attributes.length !== 0) {
                        if (attributes.length !== 0 && attributes.key !== "*") {
                            attributes.forEach((attribute) => {
                                if (checkdataSetAttributeMapping[dataSet.key].filter((item) => item.key !== attribute.key)) {
                                    filteredValues.push(`${prefix}.${dataSet.key}.${attribute.key}`);
                                } else {

                                    filteredValues.push(`${prefix}.${dataSet.key}`);
                                }
                            });
                        } 
                    }
                    else{
                        filteredValues.push(`${prefix}.${dataSet.key}`);
                    }
                });
            } else {
                filteredValues.push(prefix);
            }
        }
        let selectedMLModelId = dataSource[0].key
        this.setFilteredValues(filteredValues,selectedMLModelId);
        this.props.setChipData(chipData);
    }

    changeFilterValues(mlModelId, dataSourceId){
        let mlModel = -1;
        let environment = -1;
        if (this.props.monitorModule.mlModelMapping !== undefined){
            mlModel = {"label": this.props.monitorModule.mlModelMapping[mlModelId], "value": mlModelId};
        }
        for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
            if (dataSourceId !== undefined && value.includes(dataSourceId.toString())) {
                environment = {"label": this.props.dataModule.environments[key], "value": key};
            }
        }
        this.setState({prefillEnvironment:environment, prefillMlModel:mlModel});
    }

    setDataSource(mlModelId, changeSource) {
        if(mlModelId===undefined){
            return
        }

        let dataSourceId = this.state.modelDataSourceDetails[mlModelId];
        this.props.setMlDataSource(dataSourceId);
        let envId = null;
        let filteredValues =[];
        for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
            if (value.includes(dataSourceId.toString())) {
                envId = key;
            }
        }
        if (envId !== null){
            let arr = [envId, dataSourceId]
            filteredValues = [arr.join(".").toString()];
        }
        let environment= this.props.dataModule.chipData.filter((item) => item.type === ENVIRONMENT);
        environment = {"label": environment[0].value, "value": environment[0].key};

        if (filteredValues.length > 0){
            let chipData = [{
                "key": mlModelId,
                "value": this.props.monitorModule.mlModelMapping[mlModelId],
                "type": DATA_SOURCE
            }];
            this.setState({
                chipData: chipData,
                filteredValues: filteredValues,
            });

            if(changeSource){
                let selectedModel = {
                                        "value": mlModelId,
                                        "label": this.props.monitorModule.mlModelMapping[mlModelId],
                                        "type": DATA_SOURCE
                                    }
                this.setState({selectedMLModel:selectedModel})
                this.setChipData(environment, selectedModel,-1,-1)
                this.formCardData(filteredValues, dataSourceId, mlModelId);
                this.formTableData(filteredValues, dataSourceId, mlModelId);
            }
            
        }
//        let mlDataSetInfo = this.props.monitorModule.mlModelDataSetInfo;
//        if (mlDataSetInfo !== undefined) {
//            let mlDetails = mlDataSetInfo[mlModelId];
//            let filteredValues =[];
//            if (mlDetails !== undefined) {
//                    for (let metricValues of Object.values(mlDetails)){
//                        Object.keys(metricValues).forEach((modelPrefix) => {
//                        if (!filteredValues.includes(modelPrefix)){
//                            filteredValues.push(modelPrefix);
//                        }
//                    });
//                }
//                let chipData = [{
//                    "key": mlModelId,
//                    "value": this.props.monitorModule.mlModelMapping[mlModelId],
//                    "type": DATA_SOURCE
//                }];
//                this.setState({
//                    chipData: chipData,
//                    filteredValues: filteredValues
//                });
//                this.formCardData(filteredValues, dataSourceId, mlModelId);
//                this.formTableData(filteredValues, dataSourceId, mlModelId);
//            }
//        }
        if (mlModelId !== -1) {
            this.changeFilterValues(mlModelId, dataSourceId);
        }
    }

    handleClick(data) {
        this.setState({showModal:true,cardName:data.metric_name});
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    renderCards(cardData) {
        return (
            <div className="form-row">
                {
                    this.state.showModal ?
                       <CardHelpContent
                                show={this.state.showModal}
                                onHide={this.hideModal}
                                name={this.state.cardName}
                                page={"model"}
                      /> : ""
                }
                {
                    cardData.map((data) => {
                        let cardBackground = `dashboard-stat ${data.background}`;
                        let textColor = `title ${data.textColor}`;
                        return(
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={data.idValue + "_card"}>
                                <div className={cardBackground} >
                                    <div className="details">
                                        <div className="desc-header">
                                            <div className={textColor}>
                                                {data.metric_name}
                                            </div>
                                            <span>
                                                <RcTooltip 
                                                    placement="top" 
                                                    overlay={renderTooltip}
                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                >
                                                    <div className="help-icon" onClick={this.handleClick.bind(this, data)} data-toggle="modal" data-target="#model__help"><i><FontAwesomeIcon icon={faQuestionCircle}/></i></div>
                                                </RcTooltip>
                                            </span>
                                        </div>
                                        <div className="desc">
                                            <span className="desc-value">{data.metric_value}</span>

                                        </div>
                                        <ChartBody
                                            classValue="chart"
                                            id={data.idValue}
                                            chartType="cardArea"
                                            style={{height: "250px"}}
                                            chart_data={data.chartData}
                                            colorType={data.textColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    closeLastProfiledTime() {
        this.setState({
          hideLastProfiledTime: true,
        });
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }


    resetFilter() {
        this.setState({formResetFlag:1});
    }


    toggleMainFilterApplied() {
        let isMainFilterApplied = this.state.isMainFilterApplied ? false : true;
        this.setState({
            isMainFilterApplied: isMainFilterApplied
        })
    }

    setFilteredValues(filteredValues,selectedMLModelId=null) {
        if(filteredValues.length === 0){
            return
        }
        let filterValuesUpdated = [];
        let dataSourceId;
        let mlModelId;
        filteredValues.forEach((filterVal) => {
                let filteredArr = filterVal.split(".")
                let dataSrcMlIds = filteredArr[1].split("-");
                dataSourceId = dataSrcMlIds[0];
                mlModelId = dataSrcMlIds[1];
                filteredArr[1]= dataSourceId;
                filteredArr = filteredArr.join(".").toString();
                filterValuesUpdated.push(filteredArr);
        });
        let filterResult = _.cloneDeep(filterValuesUpdated);
        this.formCardData(filterResult, dataSourceId, mlModelId);
        this.formTableData(filterResult, dataSourceId, mlModelId);
        this.props.setMlDataSource(dataSourceId);
        this.setDataSource(mlModelId);
       
        let selectedModel = {
            "value": mlModelId,
            "label": this.props.monitorModule.mlModelMapping[mlModelId],
            "type": DATA_SOURCE
        }
        if(selectedMLModelId!==null){
            selectedModel = {
                "value": selectedMLModelId,
                "label": this.props.monitorModule.mlModelMapping[selectedMLModelId],
                "type": DATA_SOURCE
            }   
        }
        if ((filterValuesUpdated.length > 1) || (filterValuesUpdated.length === 1 && filterValuesUpdated[0].split(".").length > 1)) {
            this.setState({
                filteredValues: filterValuesUpdated,
                selectedMLModel:selectedModel
            })
        }
        //this.props.setDataSource(dataSourceId);
    }

    renderFilter(environmentOption, mlModelOption, dataSetOptions, preSelectedDataSource) {
        let preSelectedEnvironment = this.state.prefillEnvironment;
        let preSelectedMlModel = this.state.prefillMlModel;
        let preSelectedDatasets = this.state.preSelectedDatasets;
        let preSelectedAttributes = this.state.preSelectedAttributes;
        let attributeOptions = [];
        if (this.props.dataModule.chipData !== undefined && this.props.dataModule.chipData.length > 1) {
            let chipData = this.props.dataModule.chipData;
            let environment= chipData.filter((item) => item.type === ENVIRONMENT);
            preSelectedEnvironment = {"label": environment[0].value, "value": environment[0].key};

//            let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
//            preSelectedDataSource = {"label": dataSource[0].value, "value": dataSource[0].key};

            let mlModel = chipData.filter((item) => item.type === DATA_SOURCE);
            preSelectedMlModel = {"label": mlModel[0].value, "value": mlModel[0].key};

            let datasets = chipData.filter((item) => item.type === DATA_SET);
            preSelectedDatasets = [];
            datasets.forEach((dataSet) => {
                preSelectedDatasets.push({"label": dataSet.value, "value": dataSet.key})
            });
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            preSelectedAttributes = [];
            attributes.forEach((attribute) => {
                preSelectedAttributes.push({"label": attribute.value, "value": attribute.key})
            });

            preSelectedDatasets.forEach((item) => {
                let attributesList = this.props.dataModule.dataSetAttributeMapping[item.value];
                if(attributesList!==undefined){
                attributesList.forEach((attributeId) => {
                    attributeOptions.push({"value": attributeId, "label": parseMetaMapping(this.props, ATTRIBUTE, item.value,
                     attributeId, preSelectedEnvironment, preSelectedDataSource)});
                });
                }
            });

//            let mlDatasetDetails = this.props.monitorModule.mlModelDataSetInfo[preSelectedMlModel.value];
//            for (let metricValues of Object.values(mlDatasetDetails)){
//                Object.keys(metricValues).forEach((modelPrefix) => {
//                    let dataSetId=modelPrefix.split(".")[2];
//                    if (!datasetIds.includes(dataSetId)){
//                        datasetIds.push(dataSetId);
//                        dataSetOptions.push({"value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
//                            preSelectedDataSource.value, dataSetId, preSelectedEnvironment, preSelectedDataSource)});
//                    }
//                })
//            }
            let datasetIds =[];
            let chartDataV1 = this.props.dataModule.relationship[this.state.modelDataSourceDetails[mlModel[0].key]];
            for (let i in chartDataV1){
                let attributeName= chartDataV1[i].name;
                let dataSetId=attributeName.split(METADATA_MAP_KEY_SEPARATOR)[2];
                if (!datasetIds.includes(dataSetId)){
                    datasetIds.push(dataSetId);
                    dataSetOptions.push({"value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
                        preSelectedDataSource.value, dataSetId, preSelectedEnvironment, preSelectedDataSource)});
                }
            }
        }
        return (
            <MlFilterOptions
                ref={this.filterOptions}
                show={this.state.isFilterMode}
                closeFilterModal={this.toggleFilter}
                resetFilter={this.resetFilter}
                dataModule={this.props.dataModule}
                monitorModule={this.props.monitorModule}
                setFilteredValues={this.setFilteredValues}
                environments={environmentOption}
                mlModelOption={mlModelOption}
                dataSetOptions={dataSetOptions}
                attributeOptions ={attributeOptions}
                preSelectedEnvironment={preSelectedEnvironment}
                preSelectedDataSource={preSelectedDataSource}
                preSelectedMlModel={preSelectedMlModel}
                preSelectedDatasets={preSelectedDatasets}
                preSelectedAttributes={preSelectedAttributes}
                filterTitle="ML-Model Filter"
                setChipData={this.setChipData}
            />
        );
    }


    formTableData(filteredValues, changedDataSource=-1, mlModelId, timeFilterApplied=false, startDate=null, endDate=null) {
        let tableInfo = [];
        let allMetricInfo;
        let dataSourceId = this.state.modelDataSourceDetails[mlModelId];

        if (changedDataSource === -1) {
            allMetricInfo = _.cloneDeep(this.props.dataModule.info[dataSourceId]);
        } else {
            allMetricInfo = _.cloneDeep(this.props.dataModule.info[changedDataSource]);
        }

        if (filteredValues.length !== 0) {
            allMetricInfo = this.filterInfoData_mlModel(filteredValues, allMetricInfo);
        }

        if (allMetricInfo === undefined || allMetricInfo == null) {
            allMetricInfo = {};
        }

        for (const [key, value] of Object.entries(allMetricInfo)) {
            let metaData = this.props.dataModule.metaData[key];
            if (metaData !== undefined) {
                let reqMappedVal = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                let dataSource = reqMappedVal[1];
                let dataSet = reqMappedVal[2];
                let attribute = reqMappedVal[reqMappedVal.length - 1];
                for (const [metricId, metricValue] of Object.entries(value)) {
                    let tableData = [];
                    if (!metricId.endsWith("drift") && metricId in ML_METRIC_2 ) {
                        tableData.push({"value": dataSource, "type": "td"});
                        tableData.push({"value": this.props.monitorModule.mlModelMapping[mlModelId], "type": "td"});
                        tableData.push({"value": dataSet, "type": "td"});
                        tableData.push({"value": attribute, "type": "td"});
                        tableData.push({"value": ML_METRIC_2[metricId], "type": "td"});
                        let tempMetricValue = metricValue;
                        if (timeFilterApplied) {
                            let timeFilteredData = this.getTimeFilteredData_mlModel(allMetricInfo, startDate,
                                endDate, key, metricId);
                            if (timeFilteredData !== null && timeFilteredData.length > 0) {
                                tempMetricValue = `${Math.max.apply(null, timeFilteredData)}`;
                            } else {
                                continue;
                            }
                        }
                        tableData.push({"value": tempMetricValue, "type": "td"});
                        tableInfo.push(tableData);
                    }
                }
            }

        }
          let tableHeaders = this.state.tableData.headers;
          let tableData = {"headers": tableHeaders, data: tableInfo};
          this.setState({tableData: tableData});


    }

    formCardData(filteredValues, changedDataSource = -1, mlModelId,
                 timeFilterApplied = false, startDate = null, endDate = null) {
        const cardInfo = this.formCardData_mlModel(filteredValues, changedDataSource, mlModelId,
            timeFilterApplied, startDate, endDate);
        this.setState({
            cardData: cardInfo
        });
    }


    render() {
        let isCalculationInProgress = this.props.dataModule.relationship[this.state.modelDataSourceDetails[this.props.dataModule.selectedMLModel]];

        let mlModelOption = [];
        let environmentOption = [];
        let selectedMLModel = {};
        let selectedDataSource = {};
        let lastProfilingTime = this.props.dataModule.lastProfilingTime[this.props.dataModule.selectedMlDataSource] ?
                `Your data last profiled at ${this.props.dataModule.lastProfilingTime[this.props.dataModule.selectedMlDataSource]}` : "--";


        if (this.props.dataModule.selectedMLModel !== undefined && this.props.dataModule.selectedMLModel !== "undefined") {
            let dataSourceId = this.state.modelDataSourceDetails[this.props.dataModule.selectedMLModel.toString()];
            for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
                if (dataSourceId !== undefined && key === dataSourceId.toString()) {
                    selectedDataSource = {"label": value, "value": key};
                }
            }
        }
        for (const [key, value] of Object.entries(this.props.monitorModule.mlModelMapping)) {
            mlModelOption.push({"label": value, "value": key, "dataSourceId": this.state.modelDataSourceDetails[key],
                        "dataSrcName": this.props.dataModule.integrations[this.state.modelDataSourceDetails[key]]});
            if (key === this.props.dataModule.selectedMLModel.toString()) {
                selectedMLModel = {"label": value, "value": key};
            }
        }

        for (const [key, value] of Object.entries(this.props.dataModule.environments)) {
            environmentOption.push({"label": value, "value": key});
        }
        let dataSetOptions = [];
        let mlDatasetDetails = this.props.monitorModule.mlModelDataSetInfo[selectedMLModel.value];
        let datasetIds =[];
//        if (this.props.dataModule !== undefined && Object.keys(selectedDataSource).length !== 0  && this.state.prefillEnvironment !== undefined && this.state.prefillEnvironment !== -1 && mlDatasetDetails !== undefined ) {
//                for (let metricValues of Object.values(mlDatasetDetails)){
//                    Object.keys(metricValues).forEach((modelPrefix) => {
//                        let dataSetId=modelPrefix.split(".")[2];
//                        if (!datasetIds.includes(dataSetId)){
//                            datasetIds.push(dataSetId);
//                            dataSetOptions.push({"value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
//                                selectedDataSource.value, dataSetId, this.state.prefillEnvironment, selectedDataSource)});
//                        }
//                    })
//                }
//        }

        let isAfterDataSetUp = (this.props.dataModule.tenantId === -1)
        let chartDataV1 = this.props.dataModule.relationship[this.state.modelDataSourceDetails[this.props.dataModule.selectedMLModel]];
        if (this.props.dataModule !== undefined && Object.keys(selectedDataSource).length !== 0  && this.state.prefillEnvironment !== undefined && this.state.prefillEnvironment !== -1 && mlDatasetDetails !== undefined ) {
            for (let i in chartDataV1){
                let attributeName= chartDataV1[i].name;
                let dataSetId=attributeName.split(METADATA_MAP_KEY_SEPARATOR)[2];
                if (!datasetIds.includes(dataSetId)){
                    datasetIds.push(dataSetId);
                    dataSetOptions.push({"value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
                        selectedDataSource.value, dataSetId, this.state.prefillEnvironment, selectedDataSource)});
                }
            }
        }
        let isInProgress = false;
        if (this.props.monitorModule.mlModelMapping !== undefined && Object.keys(this.props.monitorModule.mlModelMapping).length > 0 &&
            (this.props.monitorModule.mlModelDetails.length === 0 || isCalculationInProgress === undefined)){
            isInProgress = true;
        }
        let initialChipData = [{
                "key": this.props.dataModule.selectedMLModel,
                "value": this.props.monitorModule.mlModelMapping[this.props.dataModule.selectedMLModel],
                "type": DATA_SOURCE
        }];

        if(this.state.selectedMLModel!=null){
            selectedMLModel = this.state.selectedMLModel
        }
        return (
            isCalculationInProgress !== undefined  ? (
            <>
                { this.renderFilter(environmentOption, mlModelOption, dataSetOptions, selectedDataSource) }
                {
                    this.state.hideLastProfiledTime === false ?
                        <div className="alert moniker-alert" role="alert">
                            <button type="button" className="close close-sm" onClick={() => this.closeLastProfiledTime()} data-dismiss="alert" aria-label="Close"/>
                            <p className="mb-0">
                                <strong> {lastProfilingTime}</strong>
                            </p>
                        </div> : ""
                }
                <Filter
                    dataModule={this.props.dataModule}
                    showFilterModal={this.toggleFilter}
                    chipData={this.props.dataModule.chipData === undefined || this.props.dataModule.chipData.length === 0 ? initialChipData : this.props.dataModule.chipData}
                    removeChipData={this.removeChipData}
                    lastProfilingTime={lastProfilingTime}
                />
                 {/* {this.state.isFilterMode === true ? */}
                                    {/* <TreeFilter
                                        tab={"relationship"}
                                        startDate={this.props.startDate!==null?this.props.startDate:this.state.startDate}
                                        endDate={this.props.endDate!==null?this.props.endDate:this.state.endDate}
                                        // seletedDatasourceId={this.state.seletedDatasourceId}
                                        // seletedDatasourceName={this.state.seletedDatasourceName}
                                        data={this.props.filterData}
                                        clearAllFilterData={this.clearAllFilterData}
                                        clearAllFilter={this.state.clearAllFilter}
                                        loadDataSourceRender={this.loadDataSourceRender}
                                        handleFilteredData={this.handleFilteredData}
                                        handleDateFiltered={this.props.handleDateFiltered}

                                    />  */}
                                    {/* : ""} */}

                {
                    this.props.dataModule.selectedMlDataSource !== -1 ?
                        <>
                            {this.renderCards(this.state.cardData)}
                            <BasicPortlet
                                className="pb-0"
                                video_url="profile_model"
                                title="Drift Overview"
                                srcOption={mlModelOption}
                                changeSource={this.setDataSource}
                                bodyClassName="pb-0"
                                showFilter={true}
                                changeModel={this.props.changeModel}
                                showtimeFilter={false}
                                id="modelProfileChart"
                                selectedDataSrc={selectedMLModel}
                                changeTime={this.changeTime}
                                content={
                                     chartDataV1.length === 0 ?
                                     <>
                                     <ProgressStatus integration_id={this.props.dataModule.selectedMlDataSource}/>
                                      </> : <ChartBody
                                        id="chartMl_model"
                                        title="Drift Overview"
                                        chartType="radialBarChartV3"
                                        chartName="ML Model"
//                                        chart_data={this.props.dataModule.mlRelationship[this.props.dataModule.selectedMLModel]}
                                        metaData={this.props.dataModule.metaData}
                                        metricInfo={this.props.dataModule.info[this.state.modelDataSourceDetails[this.props.dataModule.selectedMLModel]]}
                                        filteredValues={this.state.filteredValues}
                                        datasetVersion={this.props.dataModule.datasetVersion}
                                        mlModelName={this.props.monitorModule.mlModelMapping[this.props.dataModule.selectedMLModel]}
                                        chart_data_v1={chartDataV1}
                                        page="profile"
                                        video_url="profile_model"
                                        timeFilterApplied={this.state.timeFilterApplied}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                    />
                                }
                            />
                            <BasicPortlet
                                video_url="profile_model"
                                className="mb-0 pb-0" title="Following are the list of metrics and it's latest values" bodyClassName="pb-0"
                                id="modelProfileTable"
                                content = {
                                    <QualdoDataTable
                                        data={this.state.tableData}
                                        component_name="profile-models"
                                    />
                                }
                           />
                        </>
                    :   <>
                            <BasicPortlet
                                className="pb-0"
                                video_url="profile_model"
                                title="Drift Overview"
                                srcOption={[]}
                                bodyClassName="pb-0"
                                showFilter={true}
                                showtimeFilter={false}
                                id="modelProfileChartLoading"
                                content={<Load/>}
                            />
                            <BasicPortlet
                                video_url="profile_model"
                                className="mb-0 pb-0" title="Following are the list of metrics and it's latest values" bodyClassName="pb-0"
                                id="modelProfileTableLoading"
                                content={<Load/>}
                           />
                        </>
                }
            </>) :(
                isInProgress === true ?
                <BasicPortlet
                    bodyClassName="pb-0"
                    video_url="profile_model"
                    id="noModelProfileConfigured"
                    className="pb-0"
                    title="Drift Overview"
                    srcOption={mlModelOption}
                    changeSource={this.setDataSource}
                    showFilter={true}
                    changeModel={this.props.changeModel}
                    showtimeFilter={false}
                    selectedDataSrc={selectedMLModel}
                    changeTime={this.changeTime}
                    content={
                        <div className="py-4 text-center">
                          <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faBezierCurve}/></i></h1>
                            <h4> Model profiling is in progress</h4>
                          </div>
                        </div>
                    }
                />
                :
                this.props.dataModule.selectedMLModel === undefined || this.props.dataModule.selectedMLModel === -1 || isAfterDataSetUp ?
                <BasicPortlet
                    video_url="profile_model"
                    className="mb-0 pb-0" bodyClassName="pb-0"
                    id="noModelProfileConfigured"
                    content={
                        <div className="py-4 text-center">
                          <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faBezierCurve}/></i></h1>
                            <h4>No models configured yet.</h4>
                            <p>Please go <a href="/model">here</a> and setup your first model.</p>
                          </div>
                        </div>
                    }
                />
                :
                <>
                    <BasicPortlet
                        video_url="profile_model"
                        id="modelProfileLoading"
                        className="pb-0" bodyClassName="pb-0"
                        content={<Load/>}
                    />
                </>
                )
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { setMlDataSource, setMLModel,setChipData, setFilteredValues }) (MlModel);