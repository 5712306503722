/**
 * Represents the Monitor page -> Model performance -> List View tab contents
 * */

import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import Load from '../components/loadAction';
import {
    filterInfoData_mlModel,
    formModelErrorDataForListView,
    getGroupedDataListView,
    getTimeFilteredData_mlModel,
    setModelPerformanceTimeFilterPreviewPage,
    getDefaultDateRangeOptions
} from "../utils/common_utils";
import _ from 'lodash';
import { DISPLAY_DATE_FORMAT, MONITOR_MODEL_PERF_LIST_VIEW_HEADERS } from "../utils/constant";
import BasicPortlet from "../components/basicPortlet";
import QualdoDataTable from "../components/bootstrapTable";
import CustomListInCell from "./components/customListInCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar } from "@fortawesome/free-solid-svg-icons";
import ThumbnailChartList from "./components/thumbnailChartList";
import MPListViewFullScreen from "./MPListViewFullScreen";
import Select from "react-select";

import DateRangePicker from "react-bootstrap-daterangepicker";
import { getDateObject } from "../charts/browser_utils";

class MpListView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.changeModelInFullScreen = this.changeModelInFullScreen.bind(this);
        this.getMPListTableComponents = this.getMPListTableComponents.bind(this);
        this.getCustomClassForActions = this.getCustomClassForActions.bind(this);
        this.closeFullScreenMode = this.closeFullScreenMode.bind(this);
        this.changeModelMPListViewTable = this.changeModelMPListViewTable.bind(this);
        this.filterInfoData_mlModel = filterInfoData_mlModel.bind(this);
        this.getTimeFilteredData_mlModel = getTimeFilteredData_mlModel.bind(this);
        this.getGroupedDataListView = getGroupedDataListView.bind(this);

        let tableDataListView = this.props.tableDataListView;
        let completeFullscreenData = this.props.completeFullscreenData;
        let modelDataSourceDetails = this.props.modelDataSourceDetails;
        let mlModelOptions = this.props.mlModelOptions;

        let fullModelInfo = this.props.monitorModule.mlModelDetails
        const propsDataModuleInfo = this.props.dataModule.info;
        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        if (props.monitorModule.performancePreview !== null && props.monitorModule.performancePreview !== undefined && props.monitorModule.performancePreview["filterStartDate"] !== null &&
            props.monitorModule.performancePreview["filterStartDate"] !== undefined) {
            const filterStartDateStr = props.monitorModule.performancePreview["filterStartDate"];
            const filterEndDateStr = props.monitorModule.performancePreview["filterEndDate"];
            const startDateObj = getDateObject(filterStartDateStr);
            const endDateObj = getDateObject(filterEndDateStr);
            startDate = moment(startDateObj).utc().startOf('day');
            endDate = moment(endDateObj).utc().endOf('day');
        }
        this.state = {
            fullScreenClassKey: '',
            isInFullScreenMode: false,
            selectedMetrics: [],
            propsDataModuleInfo: propsDataModuleInfo,
            completeFullscreenData: completeFullscreenData,
            completeTableData: tableDataListView,
            currentTableData: _.cloneDeep(tableDataListView),
            modelFilteredData: [],
            fullScreenData: {},
            startDateInside: null,
            endDateInside: null,
            selectedModelID: null,
            lastProfilingTimeModel: null,
            lastProfilingTimeModelInside: null,
            selectedModelInFullScreen: null,
            fullModelInfo: fullModelInfo,
            startDate: startDate,
            endDate:endDate,
            tableUpdated: this.props.tableUpdated,
            filterApplied: false,
            timeFilterApplied: false,
            isAdvancedFilterApplied: false,
            mlModelOptions: mlModelOptions,
            modelDataSourceDetails: modelDataSourceDetails,
            selectedDataSource: this.props.selectedDataSource
        };
    }

    static getDerivedStateFromProps(props, state) {
        let tableDataListView = props.tableDataListView;
        let completeFullscreenData = props.completeFullscreenData;
        let mlModelOptions = props.mlModelOptions;
        let modelDataSourceDetails = props.modelDataSourceDetails;
        const dataChanged = (state.completeTableData !== tableDataListView ||
            state.completeFullscreenData !== completeFullscreenData ||
            state.mlModelOptions !== mlModelOptions ||
            modelDataSourceDetails !== state.modelDataSourceDetails);

        /* Assign the latest updated data from props to the state variable*/
        if (dataChanged) {
            return {
                completeTableData: tableDataListView,
                currentTableData: _.cloneDeep(tableDataListView),
                completeFullscreenData: completeFullscreenData,
                mlModelOptions: mlModelOptions,
                tableUpdated: !state.tableUpdated,
                modelDataSourceDetails: modelDataSourceDetails
            };
        }

        return null;
    }

    changeModelMPListViewTable(selectedModel) {
        let selectedModelID = selectedModel.value;

        let filteredRows = [];
        let integrationId;
        for (let rowData of this.state.currentTableData) {
            // Index 4 has the model id as its value
            if (rowData[4].value === selectedModelID) {
                filteredRows.push(rowData);
                integrationId = rowData[4].integrationId
            }
        }
        let lastProfilingTimeModel = this.props.dataModule.lastProfilingTime[integrationId]

        this.setState({
            modelFilteredData: filteredRows,
            lastProfilingTimeModel: lastProfilingTimeModel,
            selectedModelID: selectedModelID,
            selectedDataSource: integrationId
        });
    }

    getCustomClassForActions() {
        /**
         * This function returns a "function" which will provide the class for <td> entries
         * i.e the cells of the tables
         * */

        return (cell, row, rowIndex, colIndex) => {
            // 5 Represents the Column index of the 'Action' column
            // Refer 'MONITOR_MODEL_PERF_LIST_VIEW_HEADERS' variable from constant.js
            if (colIndex === 5) {
                return "align-middle";
            }

            return "";
        };
    }

    setFullScreen(selectedModelID, integrationId) {
        const mlModelMapping = this.props.monitorModule.mlModelMapping;
        let selectedModelInFullScreen = [{
            "label": mlModelMapping[selectedModelID],
            "value": selectedModelID
        }];

        // Select the full screen data to be shown inside the Detailed full screen chart
        let fullScreenData = this.getGroupedDataListView([], this.state.completeFullscreenData, selectedModelInFullScreen)
        let lastProfilingTimeModelInside = this.props.dataModule.lastProfilingTime[integrationId]


        this.setState({
            fullScreenClassKey: "show",
            isInFullScreenMode: true,
            fullScreenData: fullScreenData,
            lastProfilingTimeModelInside: lastProfilingTimeModelInside,
            selectedModelInFullScreen: selectedModelInFullScreen
        });
    }

    closeFullScreenMode() {
        this.setState({
            fullScreenClassKey: '',
            isInFullScreenMode: false,
            isAdvancedFilterApplied: false,
            startDateInside: null,
            endDateInside: null,
            selectedMetrics: []
        });
    }

    changeModelInFullScreen(selectedModel, selectedMetrics = [], startDate, endDate) {
        let fullScreenData = this.getGroupedDataListView(selectedMetrics, this.state.completeFullscreenData, selectedModel)
        this.setState({
            selectedModelInFullScreen: selectedModel, selectedMetrics: selectedMetrics, fullScreenData: fullScreenData,
            startDateInside: startDate, endDateInside: endDate, timeFilterApplied: true, isAdvancedFilterApplied: !this.state.isAdvancedFilterApplied
        });
    }

    getMPListTableComponents(actionType, headerValue, cell) {

        if (headerValue === "Performance Metrics") {
            const listData = cell.value;
            return (
                <ThumbnailChartList listData={listData.data}
                    key={`${listData.key}`}
                    noModelConfigured={cell.noModelConfigured}
                    isModel={true}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    component_name ={"modelPerformanceListView"}
                >
                </ThumbnailChartList>
            );
        } else if (["Model Error Summary", "Model Summary"].includes(headerValue)) {
            return <CustomListInCell key={`summary_${headerValue}`}
                listData={cell.value}
                noModelConfigured={cell.noModelConfigured}
                isModel={true}
                inProgress={cell.inProgress}
            />
        } else if (headerValue === "Action") {
            return (
                <div className="show-more monitor-row_show-more"
                    onClick={this.setFullScreen.bind(this, cell.value, cell.integrationId)}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <p>Detailed Charts</p>
                </div>
            );
        }

        return cell;
    }

    filterModelErrorSummaryData(rowData, startDate, endDate, noModelConfigured, mlModelId) {
        let modelErrorSummary = rowData.find(x => x.type === "modelErrorSummary");
        if (modelErrorSummary === undefined || modelErrorSummary === null) {
            return;
        }

        const propsDataModuleInfo = this.props.dataModule.info;

        const errorSummaryData = formModelErrorDataForListView([], this.state.modelDataSourceDetails,
            propsDataModuleInfo, mlModelId, true, startDate, endDate);
        modelErrorSummary.value = errorSummaryData;
        modelErrorSummary.noModelConfigured = noModelConfigured
    }

    handleTimeFilter(event, picker) {
        const startDate = picker.startDate;
        const endDate = picker.endDate;
        let clonedData = _.cloneDeep(this.state.completeTableData);
        let filteredRows = [];
        let modelFilteredRows = [];
        for (let rowData of clonedData) {
            let detailedChart = rowData.find(x => x.type === "detailedChart");
            let createdTime = moment(detailedChart.createdTime).startOf("day").toDate();
            let mlModelId = parseInt(detailedChart.value);
            let selectedModel = this.state.selectedModelID;
            let modelSummary = rowData.find(x => x.type === "modelSummary");
            let modelCreatedDate = modelSummary["value"][3]
            let noModelConfigured = moment(endDate).isBefore(createdTime)
            if (modelCreatedDate !== undefined && noModelConfigured === true) {
                modelCreatedDate["highlight"] = true

            } else if (modelCreatedDate !== undefined) {
                modelCreatedDate["highlight"] = false
            }
            this.filterModelErrorSummaryData(rowData, startDate, endDate, noModelConfigured, mlModelId);
            let thumbnailData = rowData.find(x => x.type === "thumbnailCharts");
            const thumbnailVal = thumbnailData.value;
            let lastProfilingTime = thumbnailData.lastProfilingTime;
            let chartDate = startDate.startOf("day").toDate()
            let chartDate1 = endDate.endOf("day").toDate()
            for (let _cData of thumbnailVal.data) {
                let updatedThumbnailCharts = [];
                for (let _singleThumbnailData of _cData.data) {
                    let actChartData = _singleThumbnailData.chartData;
                    if (_singleThumbnailData.name === "Confusion matrix") {
                        // let startDate = picker.startDate;
                        // let endDate = picker.endDate;
                        let filteredData = setModelPerformanceTimeFilterPreviewPage(_singleThumbnailData, startDate, endDate);
                        if (filteredData === null) {
                            continue;
                        }
                        updatedThumbnailCharts.push(filteredData);
                        actChartData.time = lastProfilingTime
                    } else if (_singleThumbnailData.name === "mean_shap_value") {
                        continue
                    } else {
                        let timeArr = actChartData.time;
                        if (timeArr === undefined) {
                            continue;
                        }

                        let filteredArr =timeArr.filter(x => moment(x).isBetween(chartDate, chartDate1, null, '[]'));
                        let filteredLength = filteredArr.length;
                        // If model is not configured on selected time period we don't need to filter the data as there is no data.
                        if (filteredLength === 0 || noModelConfigured) {
                            continue;
                        }

                        actChartData.time = filteredArr;
                        if (actChartData.values !== undefined) {
                            actChartData.values = actChartData.values.slice(0, filteredLength);
                        }
                        if (actChartData.Y2_Values !== undefined) {
                            actChartData.Y2_Values = actChartData.Y2_Values.slice(0, filteredLength);
                        }
                        updatedThumbnailCharts.push(_singleThumbnailData);
                    }
                }

                _cData.data = updatedThumbnailCharts;
            }
            thumbnailData.noModelConfigured = noModelConfigured
            if (selectedModel !== null && Number(mlModelId) === Number(selectedModel)) {
                modelFilteredRows.push(rowData);
            }

            filteredRows.push(rowData);
        }

        this.setState({
            currentTableData: filteredRows,
            modelFilteredData: modelFilteredRows,
            filterApplied: !this.state.filterApplied,
            startDate: startDate,
            endDate: endDate,
            timeFilterApplied: true
        });
    }

    render() {
        let tableData = {
            "data": (this.state.modelFilteredData.length > 0 ? this.state.modelFilteredData : this.state.currentTableData),
            "headers": MONITOR_MODEL_PERF_LIST_VIEW_HEADERS
        };
        let ranges = getDefaultDateRangeOptions();
        const currStartDate = this.state.startDate;
        const currEndDate = this.state.endDate;
        const currentStartDateAsTxt = currStartDate === null ?
            ' ' :
            currStartDate.format(DISPLAY_DATE_FORMAT);

        const currentEndDateAsTxt = currEndDate === null ?
            ' ' :
            currEndDate.format(DISPLAY_DATE_FORMAT);

        const customKey = `${tableData.data.length}_${this.state.tableUpdated}_${this.state.filterApplied}`;

        const selectedDataSource = this.state.selectedDataSource !== undefined ? this.state.selectedDataSource : this.props.dataModule.selectedDataSource ;
        let lastProfilingTime = this.props.dataModule.lastProfilingTime[selectedDataSource];

        return (

            <>
                <div className="qd-tab__content-action">
                    <div className="caption">
                        <div className="alert moniker-alert" role="alert">
                            <p className="mb-0">
                                <strong> {lastProfilingTime === null || lastProfilingTime === undefined ? "--" : `Your data last profiled at ${lastProfilingTime}`}</strong>
                            </p>
                        </div>
                    </div>

                    <div className="actions">
                        <div className="action-left">
                            <Select
                                name="models"
                                filterOption={({ label }, query) => label.includes(query)}
                                id="chooseModelMPLV"
                                options={this.state.mlModelOptions}
                                onChange={this.changeModelMPListViewTable}
                                classNamePrefix='form-control'
                                placeholder="Choose Model"
                            />
                        </div>
                        <div className="action-right">
                            <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                 startDate={this.state.startDate}
                                 endDate={this.state.endDate}
                                 onApply={this.handleTimeFilter}
                                 ranges={ranges}>
                                 <i>
                                     <FontAwesomeIcon icon={faCalendar} />
                                 </i>
                                 <span className="d-inline-block">
                                     {currentStartDateAsTxt} - {currentEndDateAsTxt}
                                 </span>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>

                {this.state.isInFullScreenMode ?
                    <MPListViewFullScreen 
                        fullScreenClassVal={this.state.fullScreenClassKey}
                        key={currentStartDateAsTxt}
                        title={"Model Performance Metrics - Dashboard"}
                        showModelFilter={true}
                        selectedMetrics={this.state.selectedMetrics}
                        timeFilterApplied={this.state.timeFilterApplied}
                        fullScreenData={this.state.fullScreenData}
                        mlModelOptions={this.state.mlModelOptions}
                        variant={"mlModelPerformanceListView"}
                        lastProfilingTimeModelInside={this.state.lastProfilingTimeModelInside}
                        changeModelInFullScreen={this.changeModelInFullScreen}
                        closeFullScreen={this.closeFullScreenMode}
                        isAdvancedFilterApplied={this.state.isAdvancedFilterApplied}
                        startDate={moment(this.state.startDate.startOf("day"))}
                        endDate={moment(this.state.endDate.endOf("day"))}
                        startDateInside={this.state.startDateInside}
                        endDateInside={this.state.endDateInside}
                        selectedMlModel={this.state.selectedModelInFullScreen}
                    >

                    </MPListViewFullScreen>
                    :
                    <BasicPortlet
                        key={`monitorMPListView_${customKey}`}
                        className="pb-0"
                        id="monitorMPListView"
                        title="Model Performance List View"
                        content={
                            this.props.showLoader === true && this.props.firstDatasetUp === false ?
                                <Load />
                                :
                                <QualdoDataTable
                                    id="monitorMPListView"
                                    key={`M_MPLV_table_${customKey}`}
                                    customGetActionComponent={this.getMPListTableComponents}
                                    data={tableData}
                                    component_name="mpErrorListViewTable"
                                    paginationSize={2}
                                    getCustomClassForColumns={this.getCustomClassForActions()}
                                />
                        }
                    />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MpListView);