import React from 'react';
import Image from '../../components/image';

class URLWidget extends React.Component {

    render() {
        return(
            <div className="col-md-8 offset-md-2 text-center p-3 p-md-5">
                <i className="d-inline-block mb-3">
                    <Image src="urlLink"/>
                </i>
                <h4 className="mb-3">Create your cute {this.props.title} URL</h4>
                <p className="mb-4">Integrations are apps and databases that you can use to send data through your
                    pipeline. Select your first integration below to get started.
                </p>
                <div className="d-flex justify-content-center">
                    <form className="form-inline needs-validation justify-content-center single-row-form" noValidate method="post">
                        <div className="form-group">
                            <label className="sr-only">Create URL</label>
                            <input type="text" className="form-control text-sm-right" placeholder="qualdo.ai"/>
                        </div>
                        <button type="submit" className="btn btn-primary">Create URL</button>
                    </form>
                </div>
            </div>
        );
    }

}

export default URLWidget;