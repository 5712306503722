import React from "react";

class PaymentSectionHeader extends React.Component {

    render() {
        return (
            <div className="qd-payment-header">
                <h4 className="qd-payment-title">
                    <span className="circle-number circle-number-gradient">
                        {this.props.sectionIndex}
                    </span>
                    {this.props.sectionTitle}
                </h4>
            </div>
        )
    }
}

export default PaymentSectionHeader;
