import React from 'react';
import Image from "../image";
import {getRowCountContributionFromData} from "../../utils/common_utils";
import {DRIFT_PATTERN_TYPES, IMAGE_NAMES, GENERIC_CONSTANTS} from "../../utils/constant";
import {faChartArea} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class SingleArrowChart extends React.Component {

    constructor(props, context) {
        super(props, context);
        const chartData = this.props.data;

        const upCount = getRowCountContributionFromData(chartData);

        const imageName = (chartData["type"] === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT) ?
            IMAGE_NAMES.ICON_DOWN_RED : IMAGE_NAMES.ICON_UP_GREEN;

        this.state = {
            upCount: upCount,
            imageName: imageName,
            name: "Training vs Serving Drift"
        }
    }

    render() {
        return (
            this.state.upCount !== GENERIC_CONSTANTS.NA ?
            <div className="qd-serving-data_block">
                <div className="qd-serving-data_card">
                    <div className="qd-serving-data_card-body">
                        <p>
                            <Image src={this.state.imageName}/>
                            <span>{this.state.upCount <= 1 ? this.state.upCount + " Row": this.state.upCount + " Rows"}</span>
                        </p>
                        <h4>{this.state.name}</h4>
                    </div>
                </div>
            </div>
            :
            <div className="text-center text-muted py-5">
                 <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                 <h4>No Data found in the selected Time period</h4>
            </div>
        )
    }

}


export default SingleArrowChart;