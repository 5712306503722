import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "../image";

class PaymentOptions extends React.Component {
    render() {
        return (
            <Form.Row>
                <Col md={10}
                >
                    <Form.Group>
                        <div
                            key={"pay_option1"}
                            className="custom-control custom-radio custom-control-inline mb-1 mb-sm-4">
                            <input
                                type="radio"
                                defaultChecked={true}
                                id="pay_option1"
                                name="radio_sendDataAs"
                                className="custom-control-input"/>
                            <label
                                className="custom-control-label"
                                htmlFor="pay_option1">
                                <ul className="payment-cards">
                                    <li>
                                        <Image
                                            src="creditCard_visa"/>
                                    </li>
                                    <li>
                                        <Image
                                            src="creditCard_master"/>
                                    </li>
                                    <li>
                                        <Image
                                            src="creditCard_amex"/>
                                    </li>
                                    <li>
                                        <Image
                                            src="creditCard_discover"/>
                                    </li>
                                    <li>
                                        <Image
                                            src="creditCard_jcb"/>
                                    </li>
                                </ul>
                            </label>
                        </div>

                    </Form.Group>
                </Col>
            </Form.Row>
        );
    }
}

export default PaymentOptions;
