import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faCheck, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import RcTooltip from 'rc-tooltip';
import {Formik, ErrorMessage} from 'formik';
import * as yup from "yup";
import {postModelABConfiguration} from "../../utils/event_handling";
import {checkUniqueModelABCombination} from "../../utils/common_utils";
import {IS_NONE_CHECK} from "../../utils/constant";

const TOOLTIP_CONTENT = "You can compare only 2 extra models in AB testing.";


export function AddModelButton(props) {
    if (props.showWithTooltip) {
        return (
            // Showing button with tooltip
            <RcTooltip placement="right-start"
                            overlay={
                                <span id={'tooltip-top'}>
                                    {TOOLTIP_CONTENT}
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>

                <div onClick={props.onClickFunc}
                     className={props.addButtonClassName}>
                    <i>
                        <FontAwesomeIcon icon={faPlusCircle}/>
                    </i>
                    <div className="vs-heading__title">
                        Add Model
                    </div>
                </div>


            </RcTooltip>
        )
    }

    return (
        <div onClick={props.onClickFunc}
             className={props.addButtonClassName}>
            <i>
                <FontAwesomeIcon icon={faPlusCircle}/>
            </i>
            <div className="vs-heading__title">
                Add Model
            </div>
        </div>
    )
}

class ConfigureABTest extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postModelABConfiguration = postModelABConfiguration.bind(this);
        this.updateVersionOptions = this.updateVersionOptions.bind(this);
        this.remove3rdSection = this.remove3rdSection.bind(this);

        const incomingProps = this.props.tableRowProps;
        const currentModelDetail = incomingProps.view_details;

        // This is in list form. Each entry will be in form {"label": "Model name", "value": model_id}
        const models = incomingProps.availableModels;

        // Dictionary contains root model id key and corresponding values will list of association versions
        // Each entry in the list will in form {"label": "Version_22", "value": model_id}
        const modelVersionMap = incomingProps.modelVersionMap;

        // Dictionary with model types as keys and associated model options list as their values
        const modelTypeMap = incomingProps.modelTypeMap;

        // Dictionary containing model ids as keys along with the complete detail about the model.
        const modelsDataMap = incomingProps.modelsDataMap;

        let availableModelAVersions;
        let availableModelBVersions = [];
        let availableModelCVersions = [];

        let selectedModelA;
        let selectedModelAVersion;
        let selectedModelB = null;
        let selectedModelBVersion = null
        let selectedModelC = null;
        let selectedModelCVersion = null;

        if (this.props.operation === 'ab_add') {
            const selectedModelDetailID = String(currentModelDetail.ml_model_id);
            availableModelAVersions = modelVersionMap[selectedModelDetailID];

            selectedModelA = models.filter(x => x.value === selectedModelDetailID)[0];
            selectedModelAVersion = availableModelAVersions.filter(x => x.value === selectedModelDetailID)[0];
        } else {
            // Edit operation. We will use existing configuration.
            const model_1 = String(this.props.existingConfig.model_1);
            selectedModelA = models.filter(x => x.value === model_1)[0];
            availableModelAVersions = modelVersionMap[model_1];
            selectedModelAVersion = availableModelAVersions.filter(x => x.value === model_1)[0];

            const model_2 = String(this.props.existingConfig.model_2);
            selectedModelB = models.filter(x => x.value === model_2)[0];
            availableModelBVersions = modelVersionMap[model_2];
            selectedModelBVersion = availableModelBVersions.filter(x => x.value === model_2)[0];

            let model_3 = this.props.existingConfig.model_3;
            if (model_3 !== null) {
                model_3 = String(model_3);
                selectedModelC = models.filter(x => x.value === model_3)[0];
                availableModelCVersions = modelVersionMap[model_3];
                selectedModelCVersion = availableModelCVersions.filter(x => x.value === model_3)[0];
            }
        }

        const modelA_ID = selectedModelAVersion.value;
        const modelAData = modelsDataMap[String(modelA_ID)];
        const modelAType = modelAData.ml_model_type;
        let modelBOptions = modelTypeMap[modelAType];
        let modelCOptions = modelTypeMap[modelAType];

        this.state = {
            hideSubmitButton: false,
            show3rdSection: !IS_NONE_CHECK.includes(selectedModelC),
            currentModelDetail: currentModelDetail,
            modelVersionMap: modelVersionMap,
            availableModelOptions: models,
            modelsDataMap: modelsDataMap,
            modelTypeMap: modelTypeMap,
            prevModelAType: modelAType,
            modelBOptions: modelBOptions,
            modelCOptions: modelCOptions,
            availableModelAVersions: availableModelAVersions,
            availableModelBVersions: availableModelBVersions,
            availableModelCVersions: availableModelCVersions,
            modelA: selectedModelA,
            modelA_version: selectedModelAVersion,
            modelB: selectedModelB,
            modelB_version: selectedModelBVersion,
            modelC: selectedModelC,
            modelC_version: selectedModelCVersion,
        }
    }

    closeCurrentModal() {
        this.setState({show3rdSection: false});
        this.props.closeModalFunction();
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleSubmit = (event) => {

        const {
            modelA_version,
            modelB_version,
            modelC_version
        } = event;

        const ab_data_to_post = {
            'model_1': modelA_version,
            'model_2': modelB_version
        };
        if (modelC_version !== '') {
            ab_data_to_post["model_3"] = modelC_version
        }

        let isAB_Edit = this.props.operation === 'ab_edit';
        if (isAB_Edit) {
            ab_data_to_post["model_ab_id"] = this.props.existingConfig.model_ab_id;
        } else {
            ab_data_to_post["created_by"] = localStorage.getItem('user_name');
        }

        const showToastFunction = this.props.tableRowProps.showToast;
        this.postModelABConfiguration(ab_data_to_post,
            showToastFunction, isAB_Edit);
    }

    remove3rdSection() {
        this.setState({show3rdSection: false});
    }

    update3rdSectionStatus() {
        if (this.state.show3rdSection) {
            return;
        }

        this.setState({show3rdSection: true});
    }

    updateVersionOptions(reqKey, selectedModel, setFieldValueFunc) {
        if (reqKey === "availableModelAVersions") {
            // When user is changing model A, update model B and C drop down options
            const modelAData = this.state.modelsDataMap[String(selectedModel)];
            const modelAType = modelAData.ml_model_type;

            // User has chosen a different model type. So update the model B and model C options
            if (this.state.prevModelAType !== modelAType) {
                let modelBOptions = this.state.modelTypeMap[modelAType];
                let modelCOptions = this.state.modelTypeMap[modelAType];
                setFieldValueFunc('modelB', '');
                setFieldValueFunc('modelB_version', '');
                setFieldValueFunc('modelC', '');
                setFieldValueFunc('modelC_version', '');
                this.setState({
                    modelB: '',
                    modelB_version: '',
                    modelC: '',
                    modelC_version: '',
                    prevModelAType: modelAType,
                    modelBOptions: modelBOptions,
                    modelCOptions: modelCOptions
                });
            }

        }

        const modelVersionMap = this.state.modelVersionMap;
        let currVersions = modelVersionMap[selectedModel];
        this.setState({[reqKey]: currVersions})
    }

    getModelABConfigurationSchema() {
        const incomingProps = this.props.tableRowProps;

        // We will get existing data by calling getExistingABData function in root component
        // which is Configure -> getExistingABData().
        const existingABData = incomingProps.getExistingABData();
        const show3rdSection = this.state.show3rdSection;

        const modelSchema = yup.object().shape({
            modelA: yup.string().required("Model A is a required field"),
            modelA_version: yup.string().required("Model A version is a required field").test(
                'Model A version unique config',
                'This configuration exists already',
                function test(current_A_Version) {
                    const bVersion = this.parent.modelB_version;
                    const cVersion = this.parent.modelC_version;
                    return checkUniqueModelABCombination(current_A_Version, bVersion,
                        cVersion, existingABData);
                }
            ),
            modelB: yup.string().required("Model B is a required field"),
            modelB_version: yup.string().required("Model B version is a required field").test(
                'Model B version unique check',
                'Selected model - version should be unique across configuration',
                function test(current_B_Version) {
                    const aVersion = this.parent.modelA_version;
                    const aModel = this.parent.modelA;
                    const bModel = this.parent.modelB;

                    if (bModel === aModel && current_B_Version === aVersion) {
                        // Model A - Model A Version is same as Model B and it's version
                        return false;
                    }

                    return true;
                }
            ),
            modelC: yup.string().test('Model C is required', 'Model C is required',
                function test(modelC) {
                    if (show3rdSection && modelC === undefined) {
                        // User has enabled 3rd section, but we have not provided value for that
                        return false;
                    }
                    return true;
                }),
            modelC_version: yup.string().when("modelC", {
                is: (modelCVal) => {
                    return modelCVal !== undefined;
                },

                then: yup.string().required("Model C version is a required field")
            }).test(
                'Model C version unique check',
                'Selected model - version should be unique across configuration',
                function test(current_C_Version) {
                    if (current_C_Version === undefined) {
                        return true;
                    }

                    const aVersion = this.parent.modelA_version;
                    const aModel = this.parent.modelA;
                    const bVersion = this.parent.modelB_version;
                    const bModel = this.parent.modelB;
                    const cModel = this.parent.modelC;

                    if (cModel === aModel && current_C_Version === aVersion) {
                        // Model A - Model A Version is same as Model C and it's version
                        return false;
                    }

                    if (cModel === bModel && current_C_Version === bVersion) {
                        // Model B - Model B Version is same as Model C and it's version
                        return false;
                    }

                    return true;
                }
            ),
        });
        return modelSchema;
    }

    getInitialValuesForFormik() {
        let selectedModelA = '';
        let selectedModelAVersion = '';
        let selectedModelB = '';
        let selectedModelBVersion = '';
        let selectedModelC = '';
        let selectedModelCVersion = '';

        if (!IS_NONE_CHECK.includes(this.state.modelA)) {
            selectedModelA = this.state.modelA["value"];
        }

        if (!IS_NONE_CHECK.includes(this.state.modelA_version)) {
            selectedModelAVersion = this.state.modelA_version["value"];
        }

        if (!IS_NONE_CHECK.includes(this.state.modelB)) {
            selectedModelB = this.state.modelB["value"];
        }

        if (!IS_NONE_CHECK.includes(this.state.modelB_version)) {
            selectedModelBVersion = this.state.modelB_version["value"];
        }

        if (!IS_NONE_CHECK.includes(this.state.modelC)) {
            selectedModelC = this.state.modelC["value"];
        }

        if (!IS_NONE_CHECK.includes(this.state.modelC_version)) {
            selectedModelCVersion = this.state.modelC_version["value"];
        }

        let initial_values = {
            modelA: String(selectedModelA), modelA_version: String(selectedModelAVersion),
            modelB: selectedModelB, modelB_version: selectedModelBVersion,
            modelC: selectedModelC, modelC_version: selectedModelCVersion,
        };

        return initial_values;
    }

    render() {
        let modelSchema = this.getModelABConfigurationSchema();
        const initial_values = this.getInitialValuesForFormik();

        let addButtonClassName = "vertical-stepper__heading has-hyperlink ";
        if (this.state.show3rdSection) {
            addButtonClassName = addButtonClassName + "disabled";
        }

        return (
            <Modal show={this.props.canShowABModal}
                   size="md"
                   className="modal__sm-scrollable-fullscreen modal__ab-test"
                   scrollable centered
                   dialogClassName="modal-light"
                   onHide={this.closeCurrentModal.bind(this)}>
                <Modal.Header closeButton className="has-border-bottom">
                    <h3 className="modal-header__title" id="modelABCreation">
                        Model AB Creation
                    </h3>
                </Modal.Header>

                <Modal.Body className="modal-body">
                    <small className="text__user-guide mt-0">{"Please do the Model AB Testing by clicking "}
                        <span>
                            <i>
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            </i>
                            {" Plus symbol"}
                        </span>
                        {" you wish to continue monitoring in the new plan"}
                    </small>
                    <Formik validationSchema={modelSchema}
                            validateOnBlur={true}
                            onSubmit={this.handleSubmit}
                            initialValues={initial_values}
                    >
                        {({
                                values,
                                setFieldValue,
                                handleReset,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (
                            <Form name='configureABTest' onSubmit={handleSubmit}
                                    className="col-12 mt-2"
                                    onChange={handleChange}>

                                <div
                                    className="vertical-stepper vertical-stepper-sm vertical-stepper__abtest">
                                    <div className="vertical-stepper__heading">
                                        <span className="vs-heading__circle">1</span>
                                        <div className="vs-heading__title">
                                            Model A
                                        </div>

                                    </div>
                                    <div className="vertical-stepper__content">
                                        <div className="row row-sm">
                                            <Form.Group controlId="modelA" className="col">
                                                <Form.Label>Choose Model
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Select
                                                    classNamePrefix='select-control'
                                                    name="modelA"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    options={this.state.availableModelOptions}
                                                    isInvalid={errors.modelA && touched.modelA}
                                                    onChange={selectedOption => {
                                                        handleChange("modelA")(selectedOption.value);
                                                        this.updateVersionOptions("availableModelAVersions", selectedOption.value, setFieldValue);
                                                        setFieldValue("modelA_version", '');
                                                        this.setState({
                                                            modelA: selectedOption,
                                                            modelA_version: '',
                                                        });
                                                    }}
                                                    value={this.state.modelA}
                                                    id={"modelA"}
                                                    isOptionDisabled={(option) => option.disabled === 'yes'}
                                                    placeholder="Choose Model"
                                                />
                                                <ErrorMessage component="div" className="error-text"
                                                                name="modelA"/>
                                            </Form.Group>

                                            <Form.Group controlId="modelA_version"
                                                        className="col">
                                                <Form.Label>Choose Version <span
                                                    className="text-danger">*</span>
                                                </Form.Label>
                                                <Select
                                                    classNamePrefix='select-control'
                                                    name="modelA_version"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    options={this.state.availableModelAVersions}
                                                    isInvalid={errors.modelA_version && touched.modelA_version}
                                                    onChange={selectedOption => {
                                                        handleChange("modelA_version")(selectedOption.value);
                                                        this.setState({
                                                            modelA_version: selectedOption,
                                                        });
                                                    }}
                                                    value={this.state.modelA_version}
                                                    id={"modelA_version"}
                                                    isOptionDisabled={(option) => option.disabled === 'yes'}
                                                    placeholder="Version"
                                                />
                                                <ErrorMessage component="div" className="error-text"
                                                                name="modelA_version"/>
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div data-repeater-list="">
                                        <div data-repeater-item="">
                                            <div className="vertical-stepper__heading">
                                                <span className="vs-heading__circle">2</span>
                                                <div className="vs-heading__title">
                                                    Model B
                                                </div>
                                            </div>
                                            <div className="vertical-stepper__content">
                                                <div className="row row-sm">
                                                    <Form.Group controlId="modelB" className="col">
                                                        <Form.Label>Choose Model <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Select
                                                            classNamePrefix='select-control'
                                                            name="modelB"
                                                            filterOption={({label}, query) => label.includes(query)}
                                                            options={this.state.modelBOptions}
                                                            isInvalid={errors.modelB && touched.modelB}
                                                            onChange={selectedOption => {
                                                                handleChange("modelB")(selectedOption.value);
                                                                this.updateVersionOptions("availableModelBVersions", selectedOption.value);
                                                                setFieldValue("modelB_version", '');
                                                                this.setState({
                                                                    modelB: selectedOption,
                                                                    modelB_version: '',
                                                                });
                                                            }}
                                                            value={this.state.modelB}
                                                            id={"modelB"}
                                                            isOptionDisabled={(option) => option.disabled === 'yes'}
                                                            placeholder="Choose Model"
                                                        />
                                                        <ErrorMessage component="div"
                                                                        className="error-text"
                                                                        name="modelB"/>
                                                    </Form.Group>

                                                    <Form.Group controlId="modelB_version"
                                                                className="col">
                                                        <Form.Label>Choose Version <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Select
                                                            classNamePrefix='select-control'
                                                            name="modelB_version"
                                                            filterOption={({label}, query) => label.includes(query)}
                                                            options={this.state.availableModelBVersions}
                                                            isInvalid={errors.modelB_version && touched.modelB_version}
                                                            onChange={selectedOption => {
                                                                handleChange("modelB_version")(selectedOption.value);
                                                                this.setState({
                                                                    modelB_version: selectedOption,
                                                                });
                                                            }}
                                                            value={this.state.modelB_version}
                                                            id={"modelB_version"}
                                                            isOptionDisabled={(option) => option.disabled === 'yes'}
                                                            placeholder="Version"
                                                        />
                                                        <ErrorMessage component="div" className="error-text"
                                                                        name="modelB_version"/>
                                                    </Form.Group>

                                                </div>

                                            </div>
                                        </div>

                                        {this.state.show3rdSection ?
                                            <div data-repeater-item="">
                                                <div className="vertical-stepper__heading">
                                                    <span className="vs-heading__circle">3</span>
                                                    <div className="vs-heading__title">
                                                        Model C
                                                    </div>
                                                    <button onClick={this.remove3rdSection}
                                                            className="btn btn-link text-danger ml-auto">
                                                        <i className={"far"}>
                                                            <FontAwesomeIcon icon={faTimesCircle}/>
                                                        </i>
                                                        Remove
                                                    </button>
                                                </div>
                                                <div className="vertical-stepper__content">
                                                    <div className="row row-sm">
                                                        <Form.Group controlId="modelC"
                                                                    className="col">
                                                            <Form.Label>Choose Model <span
                                                                className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Select
                                                                classNamePrefix='select-control'
                                                                name="modelC"
                                                                filterOption={({label}, query) => label.includes(query)}
                                                                options={this.state.modelCOptions}
                                                                isInvalid={errors.modelC && touched.modelC}
                                                                onChange={selectedOption => {
                                                                    handleChange("modelC")(selectedOption.value);
                                                                    this.updateVersionOptions("availableModelCVersions", selectedOption.value);
                                                                    setFieldValue("modelC_version", '');
                                                                    this.setState({
                                                                        modelC: selectedOption,
                                                                        modelC_version: '',
                                                                    });
                                                                }}
                                                                value={this.state.modelC}
                                                                id={"modelC"}
                                                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                                                placeholder="Choose Model"
                                                            />
                                                            <ErrorMessage component="div"
                                                                            className="error-text"
                                                                            name="modelC"/>
                                                        </Form.Group>

                                                        <Form.Group controlId="modelC_version"
                                                                    className="col">
                                                            <Form.Label>Choose Version <span
                                                                className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Select
                                                                classNamePrefix='select-control'
                                                                name="modelC_version"
                                                                filterOption={({label}, query) => label.includes(query)}
                                                                options={this.state.availableModelCVersions}
                                                                isInvalid={errors.modelC_version && touched.modelC_version}
                                                                onChange={selectedOption => {
                                                                    handleChange("modelC_version")(selectedOption.value);
                                                                    this.setState({
                                                                        modelC_version: selectedOption,
                                                                    });
                                                                }}
                                                                value={this.state.modelC_version}
                                                                id={"modelC_version"}
                                                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                                                placeholder="Version"
                                                            />
                                                            <ErrorMessage component="div"
                                                                            className="error-text"
                                                                            name="modelC_version"/>
                                                        </Form.Group>

                                                    </div>

                                                </div>
                                            </div>

                                            :
                                            ''
                                        }
                                    </div>

                                    <AddModelButton
                                        showWithTooltip={this.state.show3rdSection}
                                        onClickFunc={this.update3rdSectionStatus.bind(this)}
                                        addButtonClassName={addButtonClassName}/>

                                </div>

                                <Modal.Footer className={"text-right"}>
                                    <button type="button"
                                            onClick={this.closeCurrentModal.bind(this)}
                                            className="btn btn-outline btn-grey btn-circle mr-2">
                                        Cancel
                                    </button>

                                    <button type="submit"
                                            disabled={this.state.hideSubmitButton}
                                            className="btn btn-primary btn-circle show-success-toast"
                                    >
                                        <i>
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </i>
                                        AB Test
                                    </button>
                                </Modal.Footer>


                            </Form>)}


                    </Formik>
                </Modal.Body>

            </Modal>
        );

    }
}


export default ConfigureABTest;
