import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faBezierCurve } from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import ModelAlertTab from './components/modelAlertTab';
import DqAlertTab from './components/dqAlertTab';
import { getApi } from "../utils/event_handling";



class Alert extends React.Component {
    constructor(props) {
        super(props);
        let tab = "dq"
        if(this.props.tab !== '') {
          tab = this.props.tab;
        }
        this.handleTab = this.handleTab.bind(this);
        this.getApi = getApi.bind(this);
        this.state={
            current_tab:tab,
            activeKey: tab,
        }
    }

    handleTab(key) {
        this.setState ({current_tab: key,activeKey: key});
    }

    render() {
        return (
            <div className="triggers-alert-tab-content" >
                  <div className="triggers-alert-tab-dq-content">
                     <Tab.Container defaultActiveKey="dq" activeKey={this.state.activeKey} onSelect={this.handleTab}>
                     <div className="tabbable-line mb-3">
                         <Nav variant="tabs" id="page-nav-tabs">
                              <Nav.Item>
                                   <Nav.Link eventKey="dq">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faDatabase}/>
                                        </i>  Data Quality Alerts</span>
                                   </Nav.Link>
                              </Nav.Item>
                              {this.props.hideModelAlertsTab ?  "" :<Nav.Item>
                                   <Nav.Link eventKey="ml">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faBezierCurve}/>
                                        </i>  Model Alerts</span>
                                   </Nav.Link>
                              </Nav.Item>
                              }
                         </Nav>
                     </div>
                     <div className="tab-content" id={this.state.activeKey + "Tab"}>
                        <Tab.Content>
                             <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="dq">
                                <DqAlertTab showToast={this.props.showToast}
                                            details={this.props.details}
                                            integrationOption={this.props.integrationOption}
                                            alertsDataDq={this.props.alertsDataDq}/>
                             </Tab.Pane>
                             {this.props.hideModelAlertsTab ? "" : <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="ml">
                                <ModelAlertTab showToast={this.props.showToast}
                                               modelOptions={this.props.modelOptions}
                                               details={this.props.details}
                                               alertsDataMl={this.props.alertsDataMl}/>
                             </Tab.Pane>
                             }
                         </Tab.Content>
                     </div>
                     </Tab.Container>
                  </div>
            </div>
        );
    }
}

export default Alert;
