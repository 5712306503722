import React from "react";
import Image from "./image"; // Custom Image component

// Define messages for different network statuses
const networkMessage = {
  'offline': { 
    icon: 'icon_wifi_offline', 
    message: 'You are currently offline.', 
    addonClass: 'internet-status--offline'
  },
  'restore': { 
    icon: 'icon_wifi_success', 
    message: 'Your internet connection was restored.', 
    addonClass: 'internet-status--restore'
  },
  'online': { 
    icon: 'icon_wifi_success', 
    message: 'Your internet connection was restored.', 
    addonClass: 'internet-status--restore'
  },
  'slow': { 
    icon: 'icon_wifi_drop', 
    message: 'Your internet connection is slow. All actions are currently disabled until the connection improves.', 
    addonClass: 'internet-status--restore'
  }
};

class NetworkStatusAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: navigator.onLine ? 'online' : 'offline', // Initial status based on navigator.onLine
      slowConnection: false, // Track if the connection is slow
    };
  }

  componentDidMount() {
    // Listen for online/offline status changes
    window.addEventListener('online', this.updateNetworkStatus);
    window.addEventListener('offline', this.updateNetworkStatus);

    // Check connection speed on component mount
    this.checkConnectionSpeed();
  }

  componentWillUnmount() {
    // Remove event listeners on component unmount
    window.removeEventListener('online', this.updateNetworkStatus);
    window.removeEventListener('offline', this.updateNetworkStatus);
  }

  // Method to update the network status when online/offline event fires
  updateNetworkStatus = () => {
    const onlineStatus = navigator.onLine ? 'restore' : 'offline'; // Set status to 'restore' when back online
    this.setState({ 
      status: onlineStatus,
      slowConnection: false // Reset slowConnection when coming online
    }, () => {
      // Re-check connection speed if online
      if (navigator.onLine) {
        setTimeout(() => {
          this.setState({ status: 'online' }); // Clear 'restore' message after 5 seconds
        }, 5000);
        this.checkConnectionSpeed();
      }
    });
  };

  // Method to check the connection speed
  checkConnectionSpeed = () => {
    if ('connection' in navigator) {
      // Use the Network Information API if available
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      const isSlow = connection.downlink < 1.0 || connection.effectiveType.includes('2g');
      this.setState({ slowConnection: isSlow });
    } else {
      // Fallback: Use a fetch request to measure speed if the API isn't supported
      const startTime = new Date().getTime();
      fetch('https://www.google.com/', { method: 'HEAD', mode: 'no-cors' })
        .then(() => {
          const endTime = new Date().getTime();
          const duration = endTime - startTime;
          // Consider a connection slow if it takes more than 5000ms
          const isSlow = duration > 5000;
          this.setState({ slowConnection: isSlow });
        })
        .catch(() => {
          // Assume slow or offline if the fetch fails
          this.setState({ slowConnection: true });
        });
    }
  };

  render() {
    const { status, slowConnection } = this.state;
    // Determine the alert type based on status and slowConnection
    const alertType = status === 'offline' ? 'offline' : slowConnection ? 'slow' : status === 'restore' ? 'restore' : 'online';
    const networkInfo = networkMessage[alertType];

    // Disable pointer events when the connection is slow
    document.body.style.pointerEvents = slowConnection ? 'none' : 'auto';

    // Don't render anything if the status is 'online' with no issues
    if (alertType === 'online') {
      return null;
    }

    return (
      <div className={`internet-status-alert ${networkInfo.addonClass}`}>
        <div className="internet-status-alert__dialog">
          {/* Display network icon */}
          <div className="internet-status-alert__icon">
            <Image src={networkInfo.icon} alt="Network Icon" />
          </div>

          {/* Display network message */}
          <div className="internet-status-alert__content">
            <p className="internet-status-alert__text">
              {networkInfo.message}
            </p>
          </div>

          {/* Optional Close Button for 'restore' status */}
          {status === 'restore' && slowConnection === false && (
            <div className="internet-status-alert__action">
              <i className="icon-remove" onClick={() => this.setState({ status: 'online' })}></i>
            </div>
          )}

          {/* Refresh Button for slow connections */}
          {slowConnection && (
            <div className="internet-status-alert__action">
              <button style={{ pointerEvents: 'auto' }} className="btn btn-link px-0" onClick={() => window.location.reload()}>
                Refresh
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NetworkStatusAlert;