import React, { Component } from "react";
import Image from '../components/image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faChevronRight, faDatabase, faLayerGroup, faTag, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Slider from 'react-rangeslider';
import PipelineLineage from "./pipelineLineageDataset";
import PipelineLineageErr from "./pipelineLineageErr";
import Form from 'react-bootstrap/Form';
import { Gantt, Task } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import 'react-rangeslider/lib/index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import LgChart from '../assets/img/lg-chart.png';
import gantt_chart from '../assets/img/gantt-chart.png';
import moment from "moment";
import Popover from 'react-bootstrap/Popover';
import $ from "jquery";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

let tasks: Task[] = [{
    "start": new Date(2023, 4, 5),
    "end": new Date(2023, 4, 15),
    "name": "Some Project",
    "id": "ProjectSample",
    "type": "project",
    "isDisabled": true,
    "hideChildren": false
}, {
    "start": new Date(2023, 4, 15),
    "end": new Date(2023, 4, 20),
    "name": "Idea",
    "isDisabled": true,

    "id": "Task 0",
    "type": "task",
    "project": "ProjectSample"
}, {
    "start": new Date(2023, 4, 20),
    "end": new Date(2023, 4, 25),
    "name": "Research",
    "id": "Task 1",
    "isDisabled": true,

    "type": "task",
    "project": "ProjectSample"
}, {
    "start": new Date(2023, 4, 25),
    "end": new Date(2023, 4, 30),
    "name": "Discussion with team",
    "id": "Task 2",
    "type": "task",
    "isDisabled": true,

    "project": "ProjectSample"
}, {
    "start": new Date(2023, 5, 20),
    "end": new Date(2023, 5, 25),
    "name": "Developing",
    "id": "Task 3",
    "type": "task",
    "isDisabled": true,

    "project": "ProjectSample"
}, {
    "start": new Date(2023, 4, 20),
    "end": new Date(2023, 4, 25),
    "name": "Review",
    "id": "Task 4",
    "type": "task",
    "isDisabled": true,

    "project": "ProjectSample"
}, {
    "start": new Date(2023, 4, 25),
    "end": new Date(2023, 4, 30),
    "name": "Release",
    "id": "Task 6",
    "type": "task",
    "isDisabled": true,

    "project": "ProjectSample"
}, {
    "start": new Date(2023, 4, 20),
    "end": new Date(2023, 4, 20),
    "name": "All Stage Completed",
    "id": "Task 9",
    "type": "task",
    "isDisabled": true,
    "project": "ProjectSample"
}]

export default class DQAttributeFullScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rangeValue: 0,
            detailviewstatus: "false",
            parentView:true,
            childView:false,
            showJobsFullscreen : true,
            showSingleJob : false
        }
        this.loadGanntChart = this.loadGanntChart.bind(this);
        this.setRangeValue = this.setRangeValue.bind(this);
        this.gobackfullscreen = this.gobackfullscreen.bind(this);
        this.upstreamDataset = this.upstreamDataset.bind(this);
    }
    setRangeValue = (event) => {
        this.setState({ rangeValue: event });
    }
    hiderightpanel() {
        $(".lineage-graph-sidebar").removeClass("show")
    }
    gobackfullscreen = () => {
        // $("#grant_chart_view").addClass("d-none")
        // $("#remove_chart_view").removeClass("d-none")

        this.setState({
            showJobsFullscreen : true,
            showSingleJob : false,
            parentView:true,
            childView:false
            });
    }
    formatDate(Your_Date){
        return moment.utc(Your_Date).format("ddd , DD MMM Y")
    }
    formatTime(Your_Date){
        return moment.utc(Your_Date).format("HH:MM:SS")
    }
    upstreamDataset() {
        //this.props.monitorcallback();
        //console.log(this.props.tableData)
        $("#dataset_"+this.props.DQAttrdatasetId).click()
    }
    loadGanntChart() {
       // alert(this.props.DQAttrdatasetId);
        // $("#remove_chart_view").addClass("d-none")
        // $("#grant_chart_view").removeClass("d-none")
        this.setState({
        showJobsFullscreen : false,
        showSingleJob : true,
        parentView:false,
        childView:true
        });
        document.body.click();
    }
    componentDidMount() {
        document.body.classList.add('overflow-hidden');
    }
    componentWillUnmount(){
        document.body.classList.remove('overflow-hidden');
    }
    render() {
        const {showJobsFullscreen,showSingleJob} = this.state;
        return (
            <div className="qd-fullscreen qd-pipeline-fullscreen show">
                <div className="qd-fs_dialog">
                    <div className="qd-fs_content bg-white">
                        {/* FullScreen Header */}
                        <div className="qd-fs_header">
                            <div className="header_content">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src="logo"
                                />
                            </div>
                            <div className="actions">
                                {showJobsFullscreen === true ?
                                    <button type="button" onClick={this.props.closeFullScreenMode}
                                        className="close" aria-label="Close">
                                        <span aria-hidden="true" />
                                        <span className="sr-only">Close</span>
                                    </button>
                                :''}
                            </div>
                        </div>

                        {/* FullScreen Body */}
                        <div className="qd-fs_body p-0">
                            <div className="qd-grid">
                                <div className="qd-grid-item qd-section">
                                    {/* QD Jobs Section */}
                                    {showJobsFullscreen ?
                                        <div className="qd-jobs-wrap" id="remove_chart_view">
                                            <Tab.Container defaultActiveKey="dqp_attribute">
                                                <div className="qd-jobs__header">
                                                    <div className="qd-jobs__header-title">
                                                        <Breadcrumb>
                                                            <Breadcrumb.Item href="#/">
                                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>{this.props.DQAttrdatasourceName}
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item href="#/">
                                                                <i className="mr-1"><FontAwesomeIcon icon={faLayerGroup} /></i>{this.props.DQAttrdatasetName}
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item active>
                                                                <i className="mr-1"><FontAwesomeIcon icon={faTag} /></i>{this.props.DQAttrattributeName}
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                        <div className="divider mx-20"></div>
                                                        <div className="qd-jobs__stats">
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><FontAwesomeIcon icon={faClock} /></i>
                                                                <span>Refresh Time: <strong>{this.formatDate(this.props.DQAttrlastProfilingTime)}</strong><span className="divider"></span><strong>{this.formatTime(this.props.DQAttrlastProfilingTime)} GMT</strong></span>
                                                            </div>
                                                            <div className="badge bdr-danger-50 badge-light-danger">
                                                                <ul className="error-list">
                                                                    <li className="has-error"><span>{this.props.DQAttrissuename}
                                                                        <strong className="ml-1">{this.props.DQAttrfirstNumber}
                                                                        <span className={this.props.DQAttrfirstNumberStrongClass}/>
                                                                        <i>
                                                                            {
                                                                            this.props.DQAttrfirstNumberStrongClass === "perform nve-up" ? 
                                                                            <FontAwesomeIcon icon={faArrowUp} />  : ""
                                                                            }
                                                                            {/* <FontAwesomeIcon icon={this.props.DQAttrfirstNumberStrongClass} /> */}
                                                                            </i>
                                                                            </strong></span></li>
                                                                    <li><span>Threshold<strong className="ml-1">5</strong></span></li>
                                                                </ul>    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3">
                                                        <div className="qd-jobs__stats mb-3">
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                        <g id="icon_troubleshoot" transform="translate(-677 -429)">
                                                                            <path id="icon_troubleshoot-2" data-name="icon_troubleshoot" d="M10.054,16.838A7.531,7.531,0,0,1,7.208,16.3,7.807,7.807,0,0,1,4.87,14.851a.768.768,0,0,1-.266-.642.894.894,0,0,1,.339-.642,1.042,1.042,0,0,1,.727-.254,1.147,1.147,0,0,1,.727.279,5.965,5.965,0,0,0,1.684.969,5.6,5.6,0,0,0,1.974.339,5.607,5.607,0,0,0,4.118-1.7,5.607,5.607,0,0,0,1.7-4.118,5.607,5.607,0,0,0-1.7-4.118,5.607,5.607,0,0,0-4.118-1.7A5.576,5.576,0,0,0,6.505,4.482,5.752,5.752,0,0,0,4.458,7.559a1.689,1.689,0,0,1-.436.727A1,1,0,0,1,3.3,8.6a.8.8,0,0,1-.666-.327.835.835,0,0,1-.157-.739A7.719,7.719,0,0,1,5.124,3.1a7.4,7.4,0,0,1,4.93-1.769,7.482,7.482,0,0,1,5.5,2.253,7.482,7.482,0,0,1,2.253,5.5,7.421,7.421,0,0,1-.436,2.556,7.961,7.961,0,0,1-1.211,2.192l3.876,3.876a.96.96,0,0,1-1.357,1.357L14.8,15.19A7.962,7.962,0,0,1,12.61,16.4,7.421,7.421,0,0,1,10.054,16.838Zm-.315-6.444.9-2.786a.781.781,0,0,1,.23-.327.562.562,0,0,1,.376-.133h.581a.6.6,0,0,1,.339.1.776.776,0,0,1,.242.266l1.284,2.544a.727.727,0,1,1,0,1.454h-.436A.746.746,0,0,1,12.6,11.1l-.945-1.865L10.587,12.5a.659.659,0,0,1-.242.339.65.65,0,0,1-.388.121H9.521a.661.661,0,0,1-.4-.133.7.7,0,0,1-.254-.351L7.486,7.923l-1.09,3.1a.7.7,0,0,1-.678.485H2.06a.727.727,0,1,1,0-1.454H5.209L6.639,5.912a.636.636,0,0,1,.254-.339.706.706,0,0,1,.4-.121h.46a.611.611,0,0,1,.412.145.8.8,0,0,1,.242.363Z" transform="translate(678.667 430.667)" fill="rgba(0,0,0,0.5)" />
                                                                            <rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" transform="translate(677 429)" fill="none" />
                                                                        </g>
                                                                    </svg>
                                                                </i>
                                                                <span>No. of upsteam jobs: <strong>4</strong></span>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger="click"
                                                                    rootClose={true}
                                                                    overlay={
                                                                        <Popover className="popover-dark">
                                                                            <Popover.Content>
                                                                                <ul className="pipeline-affected-list text-left">
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <i className="ml-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                            <g id="icon_info" transform="translate(-656 -429)">
                                                                                <path id="icon_info-2" data-name="icon_info" d="M11,15.5a.884.884,0,0,0,.9-.9V11a.9.9,0,1,0-1.8,0v3.6a.884.884,0,0,0,.9.9Zm0-7.2a.884.884,0,1,0-.641-.259A.871.871,0,0,0,11,8.3ZM11,20a8.764,8.764,0,0,1-3.51-.709A8.986,8.986,0,0,1,2.709,14.51,8.764,8.764,0,0,1,2,11a8.764,8.764,0,0,1,.709-3.51A8.986,8.986,0,0,1,7.49,2.709,8.764,8.764,0,0,1,11,2a8.764,8.764,0,0,1,3.51.709A8.986,8.986,0,0,1,19.291,7.49,8.764,8.764,0,0,1,20,11a8.764,8.764,0,0,1-.709,3.51,8.986,8.986,0,0,1-4.781,4.781A8.764,8.764,0,0,1,11,20Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,18.2,11a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,11,3.8,6.949,6.949,0,0,0,5.892,5.892,6.949,6.949,0,0,0,3.8,11a6.949,6.949,0,0,0,2.092,5.108A6.949,6.949,0,0,0,11,18.2Z" transform="translate(657 430)" fill="#6c757d" />
                                                                                <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="24" transform="translate(656 429)" fill="none" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                        <g id="icon_feature-search" transform="translate(-635 -429)">
                                                                            <path id="icon_feature_search" d="M16.9,7.543l2.88,2.88-1.26,1.26L15.643,8.8a5.607,5.607,0,0,1-1.013.45,3.605,3.605,0,0,1-1.148.18,3.906,3.906,0,0,1-2.869-1.181A3.906,3.906,0,0,1,9.433,5.383a3.906,3.906,0,0,1,1.181-2.869,3.906,3.906,0,0,1,2.869-1.181,3.906,3.906,0,0,1,2.869,1.181,3.906,3.906,0,0,1,1.181,2.869,3.6,3.6,0,0,1-.18,1.148A5.606,5.606,0,0,1,16.9,7.543Zm-3.42.09a2.228,2.228,0,0,0,2.25-2.25,2.259,2.259,0,0,0-3.848-1.6,2.173,2.173,0,0,0-.652,1.6,2.228,2.228,0,0,0,2.25,2.25Zm1.755,3.33,2.3,2.295v4.275a1.805,1.805,0,0,1-1.8,1.8H3.133A1.733,1.733,0,0,1,1.862,18.8a1.733,1.733,0,0,1-.529-1.271V4.933a1.733,1.733,0,0,1,.529-1.271,1.733,1.733,0,0,1,1.271-.529h4.95a4.553,4.553,0,0,0-.338,1.159,7.9,7.9,0,0,0-.09,1.181,5.411,5.411,0,0,0,1.733,4.1,5.781,5.781,0,0,0,4.118,1.642,6.546,6.546,0,0,0,.855-.056A5.6,5.6,0,0,0,15.238,10.963Z" transform="translate(636.667 430.667)" fill="rgba(0,0,0,0.5)" />
                                                                            <rect id="Rectangle_2" data-name="Rectangle 2" width="24" height="24" transform="translate(635 429)" fill="none" />
                                                                        </g>
                                                                    </svg>
                                                                </i>
                                                                <span>No. of Downsteam jobs: <strong>4</strong></span>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger="click"
                                                                    rootClose={true}
                                                                    overlay={
                                                                        <Popover className="popover-dark">
                                                                            <Popover.Content>
                                                                                <ul className="pipeline-affected-list text-left">
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <i className="ml-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                            <g id="icon_info" transform="translate(-656 -429)">
                                                                                <path id="icon_info-2" data-name="icon_info" d="M11,15.5a.884.884,0,0,0,.9-.9V11a.9.9,0,1,0-1.8,0v3.6a.884.884,0,0,0,.9.9Zm0-7.2a.884.884,0,1,0-.641-.259A.871.871,0,0,0,11,8.3ZM11,20a8.764,8.764,0,0,1-3.51-.709A8.986,8.986,0,0,1,2.709,14.51,8.764,8.764,0,0,1,2,11a8.764,8.764,0,0,1,.709-3.51A8.986,8.986,0,0,1,7.49,2.709,8.764,8.764,0,0,1,11,2a8.764,8.764,0,0,1,3.51.709A8.986,8.986,0,0,1,19.291,7.49,8.764,8.764,0,0,1,20,11a8.764,8.764,0,0,1-.709,3.51,8.986,8.986,0,0,1-4.781,4.781A8.764,8.764,0,0,1,11,20Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,18.2,11a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,11,3.8,6.949,6.949,0,0,0,5.892,5.892,6.949,6.949,0,0,0,3.8,11a6.949,6.949,0,0,0,2.092,5.108A6.949,6.949,0,0,0,11,18.2Z" transform="translate(657 430)" fill="#6c757d" />
                                                                                <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="24" transform="translate(656 429)" fill="none" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="divider ml-0"></div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                        <g id="icon_troubleshoot" transform="translate(-677 -429)">
                                                                            <path id="icon_troubleshoot-2" data-name="icon_troubleshoot" d="M10.054,16.838A7.531,7.531,0,0,1,7.208,16.3,7.807,7.807,0,0,1,4.87,14.851a.768.768,0,0,1-.266-.642.894.894,0,0,1,.339-.642,1.042,1.042,0,0,1,.727-.254,1.147,1.147,0,0,1,.727.279,5.965,5.965,0,0,0,1.684.969,5.6,5.6,0,0,0,1.974.339,5.607,5.607,0,0,0,4.118-1.7,5.607,5.607,0,0,0,1.7-4.118,5.607,5.607,0,0,0-1.7-4.118,5.607,5.607,0,0,0-4.118-1.7A5.576,5.576,0,0,0,6.505,4.482,5.752,5.752,0,0,0,4.458,7.559a1.689,1.689,0,0,1-.436.727A1,1,0,0,1,3.3,8.6a.8.8,0,0,1-.666-.327.835.835,0,0,1-.157-.739A7.719,7.719,0,0,1,5.124,3.1a7.4,7.4,0,0,1,4.93-1.769,7.482,7.482,0,0,1,5.5,2.253,7.482,7.482,0,0,1,2.253,5.5,7.421,7.421,0,0,1-.436,2.556,7.961,7.961,0,0,1-1.211,2.192l3.876,3.876a.96.96,0,0,1-1.357,1.357L14.8,15.19A7.962,7.962,0,0,1,12.61,16.4,7.421,7.421,0,0,1,10.054,16.838Zm-.315-6.444.9-2.786a.781.781,0,0,1,.23-.327.562.562,0,0,1,.376-.133h.581a.6.6,0,0,1,.339.1.776.776,0,0,1,.242.266l1.284,2.544a.727.727,0,1,1,0,1.454h-.436A.746.746,0,0,1,12.6,11.1l-.945-1.865L10.587,12.5a.659.659,0,0,1-.242.339.65.65,0,0,1-.388.121H9.521a.661.661,0,0,1-.4-.133.7.7,0,0,1-.254-.351L7.486,7.923l-1.09,3.1a.7.7,0,0,1-.678.485H2.06a.727.727,0,1,1,0-1.454H5.209L6.639,5.912a.636.636,0,0,1,.254-.339.706.706,0,0,1,.4-.121h.46a.611.611,0,0,1,.412.145.8.8,0,0,1,.242.363Z" transform="translate(678.667 430.667)" fill="rgba(0,0,0,0.5)" />
                                                                            <rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" transform="translate(677 429)" fill="none" />
                                                                        </g>
                                                                    </svg>
                                                                </i>
                                                                <span>No. of upsteam Dataset: <strong>1</strong></span>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger="click"
                                                                    rootClose={true}
                                                                    overlay={
                                                                        <Popover className="popover-dark">
                                                                            <Popover.Content>
                                                                                <ul className="pipeline-affected-list text-left">
                                                                                    <li>
                                                                                        <a href="#/"  onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>PSTG_ACCOUNT</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <a href="#/" onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                    {/*<li>
                                                                                        <a href="#/" >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <i className="ml-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                            <g id="icon_info" transform="translate(-656 -429)">
                                                                                <path id="icon_info-2" data-name="icon_info" d="M11,15.5a.884.884,0,0,0,.9-.9V11a.9.9,0,1,0-1.8,0v3.6a.884.884,0,0,0,.9.9Zm0-7.2a.884.884,0,1,0-.641-.259A.871.871,0,0,0,11,8.3ZM11,20a8.764,8.764,0,0,1-3.51-.709A8.986,8.986,0,0,1,2.709,14.51,8.764,8.764,0,0,1,2,11a8.764,8.764,0,0,1,.709-3.51A8.986,8.986,0,0,1,7.49,2.709,8.764,8.764,0,0,1,11,2a8.764,8.764,0,0,1,3.51.709A8.986,8.986,0,0,1,19.291,7.49,8.764,8.764,0,0,1,20,11a8.764,8.764,0,0,1-.709,3.51,8.986,8.986,0,0,1-4.781,4.781A8.764,8.764,0,0,1,11,20Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,18.2,11a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,11,3.8,6.949,6.949,0,0,0,5.892,5.892,6.949,6.949,0,0,0,3.8,11a6.949,6.949,0,0,0,2.092,5.108A6.949,6.949,0,0,0,11,18.2Z" transform="translate(657 430)" fill="#6c757d" />
                                                                                <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="24" transform="translate(656 429)" fill="none" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                        <g id="icon_doc" transform="translate(-614 -429)">
                                                                            <path id="icon_description" d="M7.833,16.4h5.4a.9.9,0,1,0,0-1.8h-5.4a.9.9,0,1,0,0,1.8Zm0-3.6h5.4a.9.9,0,1,0,0-1.8h-5.4a.9.9,0,1,0,0,1.8ZM5.133,20a1.805,1.805,0,0,1-1.8-1.8V3.8a1.733,1.733,0,0,1,.529-1.271A1.733,1.733,0,0,1,5.133,2h6.458a1.777,1.777,0,0,1,.686.135,1.749,1.749,0,0,1,.574.383l4.365,4.365a1.749,1.749,0,0,1,.382.574,1.777,1.777,0,0,1,.135.686V18.2a1.805,1.805,0,0,1-1.8,1.8Zm6.3-12.6V3.8h-6.3V18.2h10.8V8.3h-3.6a.884.884,0,0,1-.9-.9Z" transform="translate(615.167 430)" fill="rgba(0,0,0,0.5)" />
                                                                            <rect id="Rectangle_1" data-name="Rectangle 1" width="24" height="24" transform="translate(614 429)" fill="none" />
                                                                        </g>
                                                                    </svg>
                                                                </i>
                                                                <span>No. of Downsteam Dataset: <strong>2</strong></span>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger="click"
                                                                    rootClose={true}
                                                                    overlay={
                                                                        <Popover className="popover-dark">
                                                                            <Popover.Content>
                                                                                <ul className="pipeline-affected-list text-left">
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>PSTG_LOCATION</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>PSTG_EVENT</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <a href="#/" onClick={this.upstreamDataset} >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                    {/* <li>
                                                                                        <a href="#/" >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" >
                                                                                            <i>
                                                                                                <FontAwesomeIcon icon={faLayerGroup} />
                                                                                            </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <i className="ml-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                            <g id="icon_info" transform="translate(-656 -429)">
                                                                                <path id="icon_info-2" data-name="icon_info" d="M11,15.5a.884.884,0,0,0,.9-.9V11a.9.9,0,1,0-1.8,0v3.6a.884.884,0,0,0,.9.9Zm0-7.2a.884.884,0,1,0-.641-.259A.871.871,0,0,0,11,8.3ZM11,20a8.764,8.764,0,0,1-3.51-.709A8.986,8.986,0,0,1,2.709,14.51,8.764,8.764,0,0,1,2,11a8.764,8.764,0,0,1,.709-3.51A8.986,8.986,0,0,1,7.49,2.709,8.764,8.764,0,0,1,11,2a8.764,8.764,0,0,1,3.51.709A8.986,8.986,0,0,1,19.291,7.49,8.764,8.764,0,0,1,20,11a8.764,8.764,0,0,1-.709,3.51,8.986,8.986,0,0,1-4.781,4.781A8.764,8.764,0,0,1,11,20Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,18.2,11a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,11,3.8,6.949,6.949,0,0,0,5.892,5.892,6.949,6.949,0,0,0,3.8,11a6.949,6.949,0,0,0,2.092,5.108A6.949,6.949,0,0,0,11,18.2Z" transform="translate(657 430)" fill="#6c757d" />
                                                                                <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="24" transform="translate(656 429)" fill="none" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                            {/* <div className="divider ml-0"></div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1">
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.986 13.667c-1.443 0-2.701-.475-3.774-1.426-1.073-.95-1.69-2.142-1.853-3.574h1.02a4.56 4.56 0 0 0 1.555 2.863c.868.758 1.886 1.137 3.052 1.137 1.3 0 2.403-.453 3.309-1.358C12.2 10.402 12.653 9.3 12.653 8c0-1.3-.453-2.403-1.358-3.308-.906-.906-2.009-1.359-3.309-1.359-.728 0-1.41.162-2.047.486a5.039 5.039 0 0 0-1.645 1.335h1.744v1H2.653V2.769h1v1.58a5.659 5.659 0 0 1 1.944-1.487 5.579 5.579 0 0 1 2.39-.529c.786 0 1.522.15 2.21.447a5.737 5.737 0 0 1 1.797 1.212c.511.512.915 1.11 1.213 1.798.297.687.446 1.424.446 2.21s-.149 1.523-.446 2.21a5.737 5.737 0 0 1-1.213 1.798 5.737 5.737 0 0 1-1.797 1.213 5.503 5.503 0 0 1-2.21.446zm2.002-2.982L7.506 8.203V4.667h1v3.13l2.184 2.185-.702.703z" fill="#000" fill-opacity=".5" /></svg>
                                                                </i>
                                                                <span>No. of Reports: <strong>3</strong></span>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger="click"
                                                                    rootClose={true}
                                                                    overlay={
                                                                        <Popover className="popover-dark">
                                                                            <Popover.Content>
                                                                                <ul className="pipeline-affected-list text-left">
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#/" onClick={this.loadGanntChart}>
                                                                                            <i>
                                                                                                <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                    <g id="icon_job" transform="translate(-8449 726)">
                                                                                                        <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                        <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </Popover.Content>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <i className="ml-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="d-block">
                                                                            <g id="icon_info" transform="translate(-656 -429)">
                                                                                <path id="icon_info-2" data-name="icon_info" d="M11,15.5a.884.884,0,0,0,.9-.9V11a.9.9,0,1,0-1.8,0v3.6a.884.884,0,0,0,.9.9Zm0-7.2a.884.884,0,1,0-.641-.259A.871.871,0,0,0,11,8.3ZM11,20a8.764,8.764,0,0,1-3.51-.709A8.986,8.986,0,0,1,2.709,14.51,8.764,8.764,0,0,1,2,11a8.764,8.764,0,0,1,.709-3.51A8.986,8.986,0,0,1,7.49,2.709,8.764,8.764,0,0,1,11,2a8.764,8.764,0,0,1,3.51.709A8.986,8.986,0,0,1,19.291,7.49,8.764,8.764,0,0,1,20,11a8.764,8.764,0,0,1-.709,3.51,8.986,8.986,0,0,1-4.781,4.781A8.764,8.764,0,0,1,11,20Zm0-1.8a6.949,6.949,0,0,0,5.108-2.093A6.949,6.949,0,0,0,18.2,11a6.949,6.949,0,0,0-2.093-5.108A6.949,6.949,0,0,0,11,3.8,6.949,6.949,0,0,0,5.892,5.892,6.949,6.949,0,0,0,3.8,11a6.949,6.949,0,0,0,2.092,5.108A6.949,6.949,0,0,0,11,18.2Z" transform="translate(657 430)" fill="#6c757d" />
                                                                                <rect id="Rectangle_3" data-name="Rectangle 3" width="24" height="24" transform="translate(656 429)" fill="none" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="tabbable-line">
                                                        <Nav variant="tabs" id="page-nav-tabs" className="pl-3">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="dqp_attribute">
                                                                    <span className="qd-menu__link-text">Lineage</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>

                                                </div>
                                                <div className="qd-jobs__content">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="dqp_attribute">
                                                            <div className="portlet lineage-graph-portlet border-0">
                                                                <div className="portlet-title">
                                                                    <div className="caption">
                                                                        <span className="font-dark">Attribute: <strong>{this.props.DQAttrattributeName}</strong></span>
                                                                        <p>Click on attribute or job in order to see their downstream & upstream</p>
                                                                    </div>
                                                                    <div className="actions">
                                                                        <div className="lg-toolbar">
                                                                            <div className="lg-toolbar-item">
                                                                                <Form className="mr-3">
                                                                                    <Form.Group controlId="formBasicRangeCustom" className="form-inline flex-nowrap mb-0">
                                                                                        <Form.Label className="d-none d-xl-inline-block">Zoom</Form.Label>
                                                                                        <Slider
                                                                                            min={0}
                                                                                            max={100}
                                                                                            tooltip={false}
                                                                                            value={this.state.rangeValue}
                                                                                            onChange={this.setRangeValue}
                                                                                        />
                                                                                        <span className="rangeslider__label">{this.state.rangeValue + "%"}</span>
                                                                                    </Form.Group>
                                                                                </Form>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>Zoom Reset</Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i>
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clip-path="url(#clip0_2066_27139)">
                                                                                                <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15ZM5 6C5 5.45 5.45 5 6 5H8C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3H5C3.9 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6ZM19 3H16C15.45 3 15 3.45 15 4C15 4.55 15.45 5 16 5H18C18.55 5 19 5.45 19 6V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V5C21 3.9 20.1 3 19 3ZM19 18C19 18.55 18.55 19 18 19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19C20.1 21 21 20.1 21 19V16C21 15.45 20.55 15 20 15C19.45 15 19 15.45 19 16V18Z" fill="#A1A5B7" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_2066_27139">
                                                                                                    <rect width="24" height="24" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                    </i>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                            <div className="lg-toolbar-item">
                                                                                <button class="btn btn-light-secondary">Reset</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="portlet-body p-0">
                                                                {
                                                                this.state.parentView === true ? 
                                                                    <PipelineLineage
                                                                        type = "1"
                                                                        height="calc(100vh - 78px)"
                                                                        dataset={this.props.DQAttrdatasetName}
                                                                        attribute={this.props.DQAttrattributeName}
                                                                    /> : "" }
                                                                    <div className="lineage-graph-sidebar">
                                                                        <div className="lg-sidebar__content">
                                                                            <div className="lg-sidebar__header">
                                                                                <div className="caption">
                                                                                    <span>Attribute</span>
                                                                                    <p>{this.props.DQAttrattributeName}</p>
                                                                                </div>
                                                                                <div className="action">
                                                                                    <button type="button" onClick={this.hiderightpanel} className="close"><span className="sr-only">Close</span></button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="lg-sidebar__body">
                                                                                <div className="lg-section">
                                                                                    <h5 className="lg-section__title">Details:</h5>
                                                                                    <div className="lg-section__body">
                                                                                        <div className="pipeline-details">
                                                                                            {/* <p><label><i><FontAwesomeIcon icon={faServer} /></i>Environment:</label><span>aws_qualdo</span></p> */}
                                                                                            <p><label><i><FontAwesomeIcon icon={faDatabase} /></i>Datasource:</label><span>{this.props.DQAttrdatasourceName}</span></p>
                                                                                            <p><label><i><FontAwesomeIcon icon={faLayerGroup} /></i>Dataset:</label><span>{this.props.DQAttrdatasetName}</span></p>
                                                                                            <p><label>
                                                                                                <i>
                                                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <g clip-path="url(#clip0_6578_64659)">
                                                                                                            <path d="M8 5.81818C6.79455 5.81818 5.81818 6.79455 5.81818 8C5.81818 9.20545 6.79455 10.1818 8 10.1818C9.20545 10.1818 10.1818 9.20545 10.1818 8C10.1818 6.79455 9.20545 5.81818 8 5.81818ZM12.8764 7.45455C12.6255 5.18 10.82 3.37455 8.54545 3.12364V2.54545C8.54545 2.24545 8.3 2 8 2C7.7 2 7.45455 2.24545 7.45455 2.54545V3.12364C5.18 3.37455 3.37455 5.18 3.12364 7.45455H2.54545C2.24545 7.45455 2 7.7 2 8C2 8.3 2.24545 8.54545 2.54545 8.54545H3.12364C3.37455 10.82 5.18 12.6255 7.45455 12.8764V13.4545C7.45455 13.7545 7.7 14 8 14C8.3 14 8.54545 13.7545 8.54545 13.4545V12.8764C10.82 12.6255 12.6255 10.82 12.8764 8.54545H13.4545C13.7545 8.54545 14 8.3 14 8C14 7.7 13.7545 7.45455 13.4545 7.45455H12.8764ZM8 11.8182C5.88909 11.8182 4.18182 10.1109 4.18182 8C4.18182 5.88909 5.88909 4.18182 8 4.18182C10.1109 4.18182 11.8182 5.88909 11.8182 8C11.8182 10.1109 10.1109 11.8182 8 11.8182Z" fill="#B5B5C3" />
                                                                                                        </g>
                                                                                                        <defs>
                                                                                                            <clipPath id="clip0_6578_64659">
                                                                                                                <rect width="16" height="16" fill="white" />
                                                                                                            </clipPath>
                                                                                                        </defs>
                                                                                                    </svg>
                                                                                                </i>DQ Issue :</label><span>{this.props.DQAttrissuename}</span></p>
                                                                                            <p><label><i><FontAwesomeIcon icon={faClock} /></i>Occurred at :</label><span>At <strong>{this.formatTime(this.props.DQAttrlastProfilingTime)} GMT</strong> </span></p>
                                                                                            <p><label>
                                                                                                <i>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                                                        <g id="icon_circle-star" transform="translate(-807 -734)">
                                                                                                            <path id="icon_circle-stars" d="M8,9.77l1.665,1.26a.308.308,0,0,0,.36.008.245.245,0,0,0,.105-.323L9.5,8.63l1.635-1.17a.27.27,0,0,0,.1-.338.282.282,0,0,0-.285-.2H8.96L8.285,4.73a.3.3,0,0,0-.57,0L7.04,6.92H5.045a.282.282,0,0,0-.285.2.27.27,0,0,0,.1.338L6.5,8.63l-.63,2.085a.245.245,0,0,0,.105.323.308.308,0,0,0,.36-.008ZM8,14a5.843,5.843,0,0,1-2.34-.472A5.991,5.991,0,0,1,2.473,10.34a6.031,6.031,0,0,1,0-4.68A5.991,5.991,0,0,1,5.66,2.473a6.031,6.031,0,0,1,4.68,0A5.991,5.991,0,0,1,13.528,5.66a6.031,6.031,0,0,1,0,4.68,5.991,5.991,0,0,1-3.187,3.188A5.843,5.843,0,0,1,8,14Z" transform="translate(807 734)" fill="#b5b5c3" />
                                                                                                            <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="16" transform="translate(807 734)" fill="none" />
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </i>Run Id :</label><span>qp23422</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-section">
                                                                                    <h5 className="lg-section__title">{this.props.DQAttrissuename}</h5>
                                                                                    <div className="lg-section__body">
                                                                                        <div className="lg-chart-area">
                                                                                            <div className="lg-ca__body">
                                                                                                <img src={LgChart} alt="lg-chart" />
                                                                                            </div>
                                                                                            <div className="lg-ca__footer">
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        <p>
                                                                                                            <label>{this.props.DQAttrissuename}</label>
                                                                                                            <span>
                                                                                                                <span className="badge bdr-danger-50 badge-light-danger"><label className="text-danger">Previous: </label><strong>13</strong></span>
                                                                                                                <span className="badge bdr-danger-50 badge-light-danger"><label className="text-danger">Latest: </label><strong>0</strong></span>
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <p>
                                                                                                            <label>Threshold</label>
                                                                                                            <span>
                                                                                                                <span className="badge bdr-light badge-light">5</span>
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-section">
                                                                                    <h5 className="lg-section__title">Troubleshooting</h5>
                                                                                    <div className="lg-section__body">
                                                                                        <Tab.Container defaultActiveKey="ts_jobs">
                                                                                            <div className="tabbable-line">
                                                                                                <Nav variant="tabs" id="page-nav-tabs">
                                                                                                    <Nav.Item>
                                                                                                        <Nav.Link eventKey="ts_jobs" >
                                                                                                            <span className="qd-menu__link-text">Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                        </Nav.Link>
                                                                                                    </Nav.Item>
                                                                                                    <Nav.Item>
                                                                                                        <Nav.Link eventKey="ts_dataset">
                                                                                                            <span className="qd-menu__link-text">Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                        </Nav.Link>
                                                                                                    </Nav.Item>
                                                                                                </Nav>
                                                                                            </div>
                                                                                            <Tab.Content className="pt-2">
                                                                                                <Tab.Pane eventKey="ts_jobs">
                                                                                                    <ul className="pipeline-affected-list">
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </Tab.Pane>
                                                                                                <Tab.Pane eventKey="ts_dataset">
                                                                                                    <ul className="pipeline-affected-list">
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" >
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/">
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </Tab.Pane>
                                                                                            </Tab.Content>
                                                                                        </Tab.Container>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-section">
                                                                                    <h5 className="lg-section__title">Impact Analysis</h5>
                                                                                    <div className="lg-section__body">
                                                                                        <Tab.Container defaultActiveKey="ia_jobs">
                                                                                            <div className="tabbable-line">
                                                                                                <Nav variant="tabs" id="page-nav-tabs">
                                                                                                    <Nav.Item>
                                                                                                        <Nav.Link eventKey="ia_jobs" >
                                                                                                            <span className="qd-menu__link-text">Affected Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                        </Nav.Link>
                                                                                                    </Nav.Item>
                                                                                                    <Nav.Item>
                                                                                                        <Nav.Link eventKey="ia_dataset">
                                                                                                            <span className="qd-menu__link-text">Affected Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                        </Nav.Link>
                                                                                                    </Nav.Item>
                                                                                                </Nav>
                                                                                            </div>
                                                                                            <Tab.Content className="pt-2">
                                                                                                <Tab.Pane eventKey="ia_jobs">
                                                                                                    <ul className="pipeline-affected-list">
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i>
                                                                                                                    <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                        <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                            <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                            <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </Tab.Pane>
                                                                                                <Tab.Pane eventKey="ia_dataset">
                                                                                                    <ul className="pipeline-affected-list">
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </Tab.Pane>
                                                                                            </Tab.Content>
                                                                                        </Tab.Container>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    :''}
                                    {/* Pipeline Run Content */}
                                    {showSingleJob ?
                                        <div className="qd-jobs-run-wrapper" id="grant_chart_view">
                                            <div className="qd-jr__header">
                                                <div className="qd-jr__header-left">
                                                    <a href="#/" onClick={this.gobackfullscreen}><i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#767676"><rect fill="none" height="24" width="24" /><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19" /></svg>
                                                    </i></a>
                                                </div>
                                                <div className="qd-jr__header-right">
                                                    <div className="qd-jr__header-title">
                                                        <h4 id="detailsid">6efee51f</h4>
                                                        <div className="divider"></div>
                                                        <div className="badge badge-light-danger mr-2">
                                                            <i className="mr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            Failed
                                                        </div>
                                                        <div className="badge bg-white mr-2">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0V0" fill="none" /><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8" /></svg></i>Run of <strong>timers_pending_count</strong>
                                                        </div>
                                                        <div className="badge bg-white mr-2">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" /><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67" /></svg></i>12 Dec 2022, 05:30:47
                                                        </div>
                                                        <div className="badge bg-white mr-2">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i>0:00:45
                                                        </div>
                                                    </div>
                                                    <ul className="qd-jobs__status">
                                                        <li>
                                                            <div className="qd-jobs__status-card bg-white">
                                                                <div className="card-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#86899A"><g><path d="M0,0h24v24H0V0" fill="none" /></g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M10.94,18L7.4,14.46l1.41-1.41 l2.12,2.12l4.24-4.24l1.41,1.41L10.94,18z M13,9V3.5L18.5,9H13" /></g></svg>                                                                </div>
                                                                <div className="card-content">
                                                                    <label className="card-label">Total Tasks</label>
                                                                    <p className="card-text"><strong>2343</strong></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="qd-jobs__status-card bg-white">
                                                                <div className="card-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                </div>
                                                                <div className="card-content">
                                                                    <label className="card-label">Success Tasks</label>
                                                                    <p className="card-text"><strong>344</strong></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="qd-jobs__status-card bg-white">
                                                                <div className="card-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                </div>
                                                                <div className="card-content">
                                                                    <label className="card-label">Failed Tasks</label>
                                                                    <p className="card-text"><strong>234</strong></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="qd-jr__body">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <Tab.Container defaultActiveKey="dqp_attribute">
                                                            <div className="tabbable-line">
                                                                <Nav variant="tabs" id="page-nav-tabs" className="pl-3">
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="dqp_timedView">
                                                                            <span className="qd-menu__link-text">Timed View</span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="dqp_attribute">
                                                                            <span className="qd-menu__link-text">Troubleshooting & Diagnostics</span>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                            </div>
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="dqp_timedView">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div>
                                                                            <img src={gantt_chart} style={{width: '100%'}} alt="" /> 
                                                                            </div>
                                                                            <div style={{"display":"none"}}>
                                                                                <Gantt
                                                                                    tasks={tasks}
                                                                                    listCellWidth={false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row no-gutters">
                                                                        <div className="col-12 border-right">
                                                                            <div className="p-3 d-flex align-items-center">
                                                                                <div className="qd-search-box">
                                                                                    <input className="form-control" placeholder="search" />
                                                                                </div>
                                                                                <div className="badge badge-light px-2 ml-2">
                                                                                    <div class="form-check form-check-inline mr-0">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                                                        <label class="form-check-label" for="defaultCheck1">
                                                                                            Info
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="badge badge-light is-checked px-2 ml-2">
                                                                                    <div class="form-check form-check-inline mr-0">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                        <label class="form-check-label" for="defaultCheck1">
                                                                                            Debug
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="badge badge-light is-checked px-2 ml-2">
                                                                                    <div class="form-check form-check-inline mr-0">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                        <label class="form-check-label" for="defaultCheck1">
                                                                                            Warning
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="badge badge-light is-checked px-2 ml-2">
                                                                                    <div class="form-check form-check-inline mr-0">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                        <label class="form-check-label" for="defaultCheck1">
                                                                                            Error
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="badge badge-light is-checked px-2 ml-2">
                                                                                    <div class="form-check form-check-inline mr-0">
                                                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                        <label class="form-check-label" for="defaultCheck1">
                                                                                            Event
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: "15%" }}>OP</th>
                                                                                        <th style={{ width: "15%" }}>Event Type</th>
                                                                                        <th style={{ width: "45%" }}>Info</th>
                                                                                        <th style={{ width: "25%" }}>Timestamp</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {
                                                                            this.state.detailviewstatus === "Success" ?
                                                                                <div className="col-3">
                                                                                    <div className="job-run-summary">
                                                                                        <div className="job-run-summary__title">
                                                                                            <h6>Succeeded (4)</h6>
                                                                                        </div>
                                                                                        <div className="job-run-summary__body">
                                                                                            <ul className="job-run-summary__list">
                                                                                                <li>
                                                                                                    <label>Orders</label>
                                                                                                    <span>0.0805s</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <label>Users</label>
                                                                                                    <span>0.269s</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <label>run_dbt_da47d</label>
                                                                                                    <span>5.941s</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <label>order_forecast_model</label>
                                                                                                    <span>0.323s</span>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ""
                                                                        }


                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dqp_attribute">
                                                                    <div className="portlet lineage-graph-portlet border-0">
                                                                        <div className="portlet-title">
                                                                            <div className="caption">
                                                                                <span className="text-danger">Affected assets are colored in red</span>
                                                                                <p>Click on task or dataset in order to see their downstream</p>
                                                                            </div>
                                                                            <div className="actions">
                                                                                <div className="lg-toolbar">
                                                                                    <div className="lg-toolbar-item">
                                                                                        <p class="mb-0"><label class="text-muted mb-0 d-none d-xl-inline-block">Last Sync:</label> <strong>Today at 04:00:00 PM</strong></p>
                                                                                    </div>
                                                                                    <div className="lg-toolbar-item">
                                                                                        <Form className="mr-3">
                                                                                            <Form.Group controlId="formBasicRangeCustom" className="form-inline flex-nowrap mb-0">
                                                                                                <Form.Label className="d-none d-xl-inline-block">Zoom</Form.Label>
                                                                                                <Slider
                                                                                                    min={0}
                                                                                                    max={100}
                                                                                                    tooltip={false}
                                                                                                    value={this.state.rangeValue}
                                                                                                    onChange={this.setRangeValue}
                                                                                                />
                                                                                                <span className="rangeslider__label">{this.state.rangeValue + "%"}</span>
                                                                                            </Form.Group>
                                                                                        </Form>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={
                                                                                                <Tooltip>Zoom Reset</Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <i>
                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clip-path="url(#clip0_2066_27139)">
                                                                                                        <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15ZM5 6C5 5.45 5.45 5 6 5H8C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3H5C3.9 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6ZM19 3H16C15.45 3 15 3.45 15 4C15 4.55 15.45 5 16 5H18C18.55 5 19 5.45 19 6V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V5C21 3.9 20.1 3 19 3ZM19 18C19 18.55 18.55 19 18 19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19C20.1 21 21 20.1 21 19V16C21 15.45 20.55 15 20 15C19.45 15 19 15.45 19 16V18Z" fill="#A1A5B7" />
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_2066_27139">
                                                                                                            <rect width="24" height="24" fill="white" />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg>
                                                                                            </i>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                    <div className="lg-toolbar-item">
                                                                                        <button class="btn btn-light-secondary">Reset</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="portlet-body p-0">
                                                                            {
                                                                                this.state.childView === true ? 
                                                                                <PipelineLineageErr
                                                                                    height="calc(100vh - 78px)"
                                                                                    dataset={this.props.DQAttrdatasetName}
                                                                                    attribute={this.props.DQAttrattributeName}
                                                                                />
                                                                                : ""
                                                                            }
                                                                            {/* <div className="lineage-graph-sidebar">
                                                                                <div className="lg-sidebar__content">
                                                                                    <div className="lg-sidebar__header">
                                                                                        <div className="caption">
                                                                                            <span>Attribute</span>
                                                                                            <p>severity</p>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button type="button" className="close"><span className="sr-only">Close</span></button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-sidebar__body">
                                                                                        <div className="lg-section">
                                                                                            <h5 className="lg-section__title">Details:</h5>
                                                                                            <div className="lg-section__body">
                                                                                                <div className="pipeline-details">
                                                                                                    <p><label><i><FontAwesomeIcon icon={faServer} /></i>Environment:</label><span>aws_qualdo</span></p>
                                                                                                    <p><label><i><FontAwesomeIcon icon={faDatabase} /></i>Datasource:</label><span>Centralops docker</span></p>
                                                                                                    <p><label><i><FontAwesomeIcon icon={faLayerGroup} /></i>Dataset:</label><span>qualdo_central_ops</span></p>
                                                                                                    <p><label><i></i>DQ Issue :</label><span>Recency</span></p>
                                                                                                    <p><label><i><FontAwesomeIcon icon={faClock} /></i>Occurred at :</label><span>At <strong>12:07:12 GMT</strong>, only Sunday</span></p>
                                                                                                    <p><label><i></i>Run Id :</label><span>qp23422</span></p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="lg-section">
                                                                                            <h5 className="lg-section__title">Recency</h5>
                                                                                            <div className="lg-section__body">

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="lg-section">
                                                                                            <h5 className="lg-section__title">Troubleshooting</h5>
                                                                                            <div className="lg-section__body">
                                                                                                <Tab.Container defaultActiveKey="ts_jobs">
                                                                                                    <div className="tabbable-line">
                                                                                                        <Nav variant="tabs" id="page-nav-tabs">
                                                                                                            <Nav.Item>
                                                                                                                <Nav.Link eventKey="ts_jobs" >
                                                                                                                    <span className="qd-menu__link-text">Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                                </Nav.Link>
                                                                                                            </Nav.Item>
                                                                                                            <Nav.Item>
                                                                                                                <Nav.Link eventKey="ts_dataset">
                                                                                                                    <span className="qd-menu__link-text">Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                                </Nav.Link>
                                                                                                            </Nav.Item>
                                                                                                        </Nav>
                                                                                                    </div>
                                                                                                    <Tab.Content className="pt-2">
                                                                                                        <Tab.Pane eventKey="ts_jobs">
                                                                                                            <ul className="pipeline-affected-list">
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </Tab.Pane>
                                                                                                        <Tab.Pane eventKey="ts_dataset">
                                                                                                            <ul className="pipeline-affected-list">
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </Tab.Pane>
                                                                                                    </Tab.Content>
                                                                                                </Tab.Container>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="lg-section">
                                                                                            <h5 className="lg-section__title">Impact Analysis</h5>
                                                                                            <div className="lg-section__body">
                                                                                                <Tab.Container defaultActiveKey="ia_jobs">
                                                                                                    <div className="tabbable-line">
                                                                                                        <Nav variant="tabs" id="page-nav-tabs">
                                                                                                            <Nav.Item>
                                                                                                                <Nav.Link eventKey="ia_jobs" >
                                                                                                                    <span className="qd-menu__link-text">Affected Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                                </Nav.Link>
                                                                                                            </Nav.Item>
                                                                                                            <Nav.Item>
                                                                                                                <Nav.Link eventKey="ia_dataset">
                                                                                                                    <span className="qd-menu__link-text">Affected Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                                </Nav.Link>
                                                                                                            </Nav.Item>
                                                                                                        </Nav>
                                                                                                    </div>
                                                                                                    <Tab.Content className="pt-2">
                                                                                                        <Tab.Pane eventKey="ia_jobs">
                                                                                                            <ul className="pipeline-affected-list">
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </Tab.Pane>
                                                                                                        <Tab.Pane eventKey="ia_dataset">
                                                                                                            <ul className="pipeline-affected-list">
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                        <i>
                                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                                <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </Tab.Pane>
                                                                                                    </Tab.Content>
                                                                                                </Tab.Container>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}