import React from 'react';
import ChartBody from "../../components/chartBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";
import {AB_THUMBNAIL_LIMIT} from "../../utils/constant";


class ModelABThumbnailChartList extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.getHorizontalSection = this.getHorizontalSection.bind(this);
        this.renderSingleThumbnailChart = this.renderSingleThumbnailChart.bind(this);
    }

    renderSingleThumbnailChart(singleChartData) {
        return (
            <div className="tbl_chart tbl_chart-lg" key={singleChartData.idValue + "_div"}>
                <div className="chart-card">
                    <ChartBody
                        classValue="chart"
                        id={singleChartData.idValue}
                        key={singleChartData.idValue}
                        chartType={singleChartData.chartType}
                        style={{height: "250px"}}
                        scale={singleChartData.scale}
                        chart_data={singleChartData}
                        colorType={singleChartData.lineColors}
                        isErrorChart={this.props.isErrorChart}
                    />
                </div>
                <div className="tbl_chart-caption">
                    <label>{singleChartData.name}</label>
                    <div>


                    </div>
                </div>
            </div>
        );
    }

    getHorizontalSection(sectionData) {
        let sectionName = sectionData.name;
        let sectionKey = sectionData.key;

        if (sectionData.data.length === 0) {
            return (<dl className="tbl_horiz-charts" key={sectionKey}>
                <dt>{sectionName}</dt>
                <dd>
                    <div className="tbl-placeholder">
                        <h2>
                            <FontAwesomeIcon icon={faChartArea}/>
                        </h2>
                        {this.props.noModelConfigured === true ?
                            "Model not configured" :
                            "No Charts Found"
                        }
                    </div>
                </dd>
            </dl>);

        }

        // Get only 'n' number of charts so that UI will not be crowded
        const currSectionCharts = sectionData.data.slice(Math.max(sectionData.data.length - AB_THUMBNAIL_LIMIT, 0));

        return (
            <dl className="tbl_horiz-charts" key={sectionKey}>
                <dt>{sectionName}</dt>
                <dd>
                    <div
                        className="tbl_horiz-chart-content">
                        {
                            currSectionCharts.map(_d => this.renderSingleThumbnailChart(_d))
                        }

                    </div>
                </dd>
            </dl>
        );
    }

    render() {
        let allHorizontalSections = this.props.listData;
        return (
            allHorizontalSections.length > 0 ? allHorizontalSections.map(x => this.getHorizontalSection(x)) :
                <div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea}/>
                    </h2>
                    <h4>
                        {this.props.noModelConfigured === true ? "Model not configured"
                            : (this.props.noDataAvailable === true ?
                                "Dataset not available" :
                                "No chart found")}
                    </h4>
                </div>
        )
    }
}

export default ModelABThumbnailChartList;