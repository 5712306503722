/**
 * Represents the Monitor page -> Model performance tab contents
 * */

import React from 'react';
import {connect} from "react-redux";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faList, faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import ModelPerformance from "./modelPerformance";
import MpListView from "./mpListView";
import {IS_NONE_CHECK} from "../utils/constant";
import MpCompareView from "./mpCompareView";


class MonitorMPTabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.changeTab = this.changeTab.bind(this);

        let activeTabKey = "mp_list_view";
        if (!IS_NONE_CHECK.includes(this.props.innerTab)){
            activeTabKey = this.props.innerTab;
        }

        let addOnInfoFromParam = null;
        if (!IS_NONE_CHECK.includes(this.props.additionalInfoFromParam)) {
            addOnInfoFromParam = this.props.additionalInfoFromParam;
        }

        this.state = {
            activeTabKey: activeTabKey,
            addOnInfoFromParam: addOnInfoFromParam,
        }
    }

    changeTab(tab) {
        this.setState({activeTabKey: tab});
    }

    render() {
        return (
            <Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
                <div className="tabbable-line mb-3">
                    <Nav variant="tabs" id="page-nav-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="mp_chart_view">
                                        <span className="qd-menu__link-text">
                                        <i>
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </i>  Chart View</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="mp_list_view">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faList}/>
                                        </i>  List View</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="mp_compare_view">
                                        <span className="qd-menu__link-text"><i>
                                            <FontAwesomeIcon icon={faCheckDouble}/>
                                        </i>  Compare View</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
 
                <div className="tab-content">
                    <Tab.Content>
                       
                        {this.state.activeTabKey === "mp_chart_view" ?

                            <Tab.Pane eventKey="mp_chart_view">
                                <ModelPerformance mlModelOptions={this.props.mlModelOptions} integrationsMap={this.props.integrationsMap} completeFullscreenData={this.props.completeFullscreenData} />
                            </Tab.Pane>
                            :
                            (this.state.activeTabKey === "mp_list_view") ?
                                <Tab.Pane eventKey="mp_list_view">
                                    <MpListView
                                        tableDataListView={this.props.tableDataListView}
                                        completeFullscreenData={this.props.completeFullscreenData}
                                        tableUpdated={this.props.tableUpdated}
                                        modelDataSourceDetails={this.props.modelDataSourceDetails}
                                        mlModelOptions={this.props.mlModelOptions}
                                        firstDatasetUp={this.props.firstDatasetUp}
                                        showLoader={this.props.showLoader}
                                    />
                                </Tab.Pane> 
                            :
                                (this.state.activeTabKey === "mp_compare_view" ?

                                    <Tab.Pane eventKey="mp_compare_view">
                                        <MpCompareView
                                            mpCompareViewTableData={this.props.mpCompareViewTableData}
                                            completeFullscreenData={this.props.completeFullscreenData}
                                            tableUpdated={this.props.tableUpdated}
                                            addOnInfoFromParam={this.state.addOnInfoFromParam}
                                            modelDataSourceDetails={this.props.modelDataSourceDetails}
                                            mpCompareViewDetailedChartData={this.props.mpCompareViewDetailedChartData}
                                            mlModelOptions={this.props.mlModelOptions}
                                            firstDatasetUp={this.props.firstDatasetUp}
                                            availableModelABData={this.props.availableModelABData}
                                            showLoader={this.props.showLoader}
                                            modelErrShowMoreData={this.props.modelErrShowMoreData}
                                            attributeOptions={this.props.attributeOptions}
                                        />
                                    </Tab.Pane> 
                                :
                                     ""
                                )
                        
                        }

                       
                    </Tab.Content>
                </div>
                  
            </Tab.Container>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MonitorMPTabs);