import React from "react";
import Image from "../components/image";
import {connect} from "react-redux";
import ABCompareViewAdvFilterModel from "./components/adv_filter_mp_compare_view";
import MpCompareViewChartGroup from "./components/mpCompareViewChartGroup";
import {IS_NONE_CHECK} from "../utils/constant";

class MPCompareViewFullScreen extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.formGroupedChart = this.formGroupedChart.bind(this);

        this.state = {
            updatedKey: "k1",
            fullScreenData: {},
            variant: this.props.variant,
            selectedMlModel: this.props.selectedMlModel
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedMlModel !== state.selectedMlModel) {
            return {
                selectedMlModel: props.selectedMlModel
            }
        }

        return null;
    }

    formGroupedChart() {

        const groupedData = this.props.fullScreenData;

        return Object.keys(groupedData).map((key, index) => {
            let loadValue = !this.props.monitorModule.modelPerformanceFetchCompleted;

            return (
                (<MpCompareViewChartGroup key={`mpcompareViewGroup_${key}`}
                                          groupData={groupedData[key]}
                                          mapModel={this.props.monitorModule.mlModelMapping}
                                          index={index + 1}
                                          tab="mpCompareView"
                                          considerEndDate={false}
                                          timeFilterApplied={true}
                                          loadValue={loadValue}
                                          chartType={this.state.chartType}
                                          variant={this.state.variant}
                                          startDate={this.props.startDate}
                                          endDate={this.props.endDate}
                                          chartTimeFilter={this.chartTimeFilter}
                >

                </MpCompareViewChartGroup>));
        });
    }

    render() {

        return (
            <div className={"qd-fullscreen fade " + this.props.fullScreenClassVal}>
                <div className="qd-fs_dialog">
                    <div className="qd-fs_content">
                        <div className="qd-fs_header">
                            <div className="header_content">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src="logo"
                                />
                                <h4 className="header_title">{this.props.title}</h4>
                            </div>

                            <div className="actions">
                                <ABCompareViewAdvFilterModel
                                    mlModelOptions={this.props.mlModelOptions}
                                    selectedMlModel={this.props.selectedMlModel}
                                    startDate={this.props.startDate}
                                    modelMonitoringData={[]}
                                    type="modelPerformanceCompView"
                                    endDate={this.props.endDate}
                                    changeModelInFullScreen={this.props.changeModelInFullScreen}/>
                            </div>

                            <button type="button" onClick={this.props.closeFullScreen}
                                    className="close" aria-label="Close">
                                <span aria-hidden="true"/>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="qd-fs_body overflow-auto pt-0">
                            <div className="qd-grid qd-grid-monitor">
                                <div className="qd-grid-item qd-section"
                                     key={this.props.isAdvancedFilterApplied}>
                                    {!IS_NONE_CHECK.includes(this.props.lastProfilingTimeModelInside) ?
                                        <div className="alert moniker-alert d-inline-block mt-3"
                                             style={{minWidth: '700px'}}
                                             role="alert">
                                            <p className="mb-0">
                                                <strong> {`Your data last profiled at ${this.props.lastProfilingTimeModelInside}`}
                                                </strong>
                                            </p>
                                        </div> : ""
                                    }
                                    {this.formGroupedChart()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MPCompareViewFullScreen);