import {ui_service_ip, iam_ip} from "./ip_details";
import _ from 'lodash';
import {
    LIMIT_ABOUT_TO_REACH,
    PAID_PLANS,
    PENDING_PAYMENTS,
    PLAN_PAGE_WARNINGS,
    QUALDO_PAYMENT_STATUS,
    QualdoPlanVersions,
    LOCAL_STORE_KEY
} from "./constant";
import {formatBytes, getProtocol, removeLocalStorageKeys, convertBytesToGB, checkExpiryPowerBI, decryptForLocalUsage} from "./common_utils";
import {storeIpData} from "../redux/actions";
import store from "../redux/store";

function call_Update_api(ip, end_point,data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'authToken':token, 'userId':user_id, 'teamId':team_id}
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}

function call_post_api(ip, end_point,data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'authToken':token, "api-type": "qualdo_db_auth", 'userId':user_id, 'teamId':team_id }
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}

function call_iam_get_api(ip, end_point,data, token="") {
        let session =  localStorage.getItem('sessionToken');
        let user_id = localStorage.getItem('user_id');
        let team_id = localStorage.getItem('team_id');
        if (session !== "" && session !== null) {
            const item = JSON.parse(session);
            const now = new Date();
            if (now.getTime() > item.expiry) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            }
            session = item.value;
        }
        let protocol = getProtocol(ip);
        return fetch(protocol+'//'+ip+'/'+end_point, {
                method: 'GET',
                headers:{ 'data':JSON.stringify(data), 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':session, "userId": user_id, "teamId": team_id}
            }).then((response) => response.json()).then((responseData) => {
                return responseData;
            }).catch( error => console.error(end_point, "- failed"));
}

function teamMateTable(inputData) {
    let headers = ["Teammate", "Role", "Status","Last Login", "Actions"]
    let resultData = [];
    for (let i = 0; i < inputData.length; i++) {
        let row = [];
        let d = inputData[i];
        row.push({ "userDetails": d, "type": "image"});
        row.push({"value":d["role_name"], "type":"td"});
        row.push({"value":d["current_status"], "type":"td"});
        row.push({"value":d["last_login"], "type":"td"});
        row.push({"value": "", "type": "component", "userDetails":d});
        resultData.push(row);
    }
    let tableData = {"headers": headers, "data": resultData};
    return tableData;
}

function invitationTable(inputData) {
    let headers = ["Invited Email", "Role", "Status"];
    let resultData = [];
    let tableData = null;
    let firstDatum = inputData[0];
    if (firstDatum === undefined || firstDatum === null) {
        return {"headers": headers, "data": resultData};
    }

    if (firstDatum.email_role_map !== undefined){
        for (const [email, role] of Object.entries(firstDatum.email_role_map)) {
            let row = [];
            row.push({"value": email, "type": "td"});
            row.push({"value": role, "type": "td"});
            row.push({"value": "Invitation Sent", "type": "td"});
            resultData.push(row);
        }
    }
    else{
        for (let i = 0; i < firstDatum.email_id.length; i++) {
            let row = [];
            let d = firstDatum["email_id"][i];
            row.push({"value": d, "type": "td"});
            row.push({"value": "Invitation Sent", "type": "td"});
            resultData.push(row);
        }
    }

    tableData = {"headers": headers, "data": resultData};
    return tableData;

}

function requestTable(inputData){
   let headers = ["User Name","Action"];
   let resultData = [];
   for (let i = 0; i < inputData.length; i++) {
        let row = [];
        let d = inputData[i];
        row.push({ "value": d["requested_user_email"], "type": "td" });
        row.push({ "userDetails": d, "type": "buttons"});
        resultData.push(row);
   }
   let tableData = {"headers": headers, "data": resultData};
   return tableData;
}
function call_update_api(ip, end_point,data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
                removeLocalStorageKeys();
                window.location = '/';
            }
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token,
            "userId": user_id, "teamId": team_id}
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.error(end_point, "- failed"));
}

export function handleEditTeamMate(userDetails,state_details, handleClose, getApi){
    handleClose();
    let org_id = localStorage.getItem("org_id");
    let role_id = null;
    let role_name = null;
    if (state_details.role["value"] !== "") {
        role_id = state_details.role["value"];
        role_name = state_details.role["label"];
    }
    let inputData= {"teammate_user_id": userDetails["user_id"],
                    "org_id": org_id,
                    "organisation": state_details.organisation,
                    "department": state_details.department,
                    "role_id": role_id,
                    "role_name": role_name,
                    "team_id": localStorage.getItem('team_id'),
                    "user_id": localStorage.getItem('user_id')
                    };

    let msg = "could not update user status";
    if (state_details.disableUser === true) {
        inputData["user_current_status"] = "disabled";
        msg = "User status changed to disabled successfully";
    } else if(state_details.disableUser === false) {
        inputData["user_current_status"] = "active";
        msg = "User status changed to active successfully"
    }
    let session = localStorage.getItem("sessionToken");
    call_update_api(iam_ip, "team/user", inputData, session).then((response) => {
            if (response === undefined) {
                this.setState({errorOccurred: true});
            } else if (response.code === 200) {
                this.props.showToast("alert-success", response.message,"");
                getApi("teamMate");
            } else {
                this.props.showToast("alert-danger",msg,'');
                getApi("teamMate");
            }
    });
}

export function findQuantileForProgress(currentUsage, maxAllowedUsage) {
    let progressPercentage = 0;
    if (parseInt(currentUsage) === 0) {
        return progressPercentage
    } else {
        let usedPercentage = (currentUsage / maxAllowedUsage) * 100;
        if (usedPercentage >= 100) {
            progressPercentage = 100;
        } else if (usedPercentage >= 0 && usedPercentage <= 1) {
            progressPercentage = 0;
        } else if (usedPercentage >= 1 && usedPercentage <= 25) {
            progressPercentage = 25;
        } else if (usedPercentage >= 25 && usedPercentage < 50) {
            progressPercentage = 25;
        } else if (usedPercentage >= 50 && usedPercentage <= 75) {
            progressPercentage = 50;
        } else if (usedPercentage >= 75 && usedPercentage <= 100) {
            progressPercentage = 75;
        }
        return progressPercentage;
    }
}

export function handleRequest(props, name, closeModal, status){
    switch (name) {
        case "requests":
            this.setState({disableApprove: true});
            let userDetails = _.cloneDeep(this.props.userDetails);
            userDetails.request_status = status;
            userDetails.team_id = localStorage.getItem("team_id");
            call_Update_api(iam_ip, "/notification/requests/"+localStorage.getItem("user_id"), userDetails, localStorage.getItem("sessionToken")).then((response) =>{
                this.setState({disableApprove: false});
                closeModal()
                if(response.code === 200) {
                    status === "approved" ? this.props.showToast("alert-success","Approved successfully",''): this.props.showToast("alert-success","Rejected successfully",'');
                } else {
                    this.props.showToast("alert-danger","could not update",'');
                }
            });
            break;
        default:
            break;
    }
}

export function increaseDataLimit() {
    const tenant_id = localStorage.getItem("team_id");
    const inputData = {"tenant_id": tenant_id};
    let session = localStorage.getItem("sessionToken");

    call_update_api(ui_service_ip, "plan/status", inputData, session).then((response) => {
        if (response === undefined) {
            this.setState({errorOccurred: true});
        } else if (response.code === 200) {
            let planDetails = this.state.planDetails;
            planDetails.requiresDataLimitUpgrade = false;
            planDetails.showInformationBanner = false;
            planDetails.maxDataLimit = response.new_data_limit;
            this.closeWarningMessage();
            this.setState({planDetails: planDetails});
        } else {
            console.error("Unable to update data limit")
        }
    });
}

export function getApi(api_name) {
    let data = {tenant_id:localStorage.getItem('team_id')}
    switch(api_name) {
        case "edition_details":
            call_iam_get_api(ui_service_ip, "plan/edition",data).then( (response) =>{
                if(response.code === 200) {
                    if (Object.keys(response['edition_details']).length > 0)
                    {
                        this.setState({edition_details:response['edition_details'],
                                       next_billing_date:response["next_billing_date"]});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "planChangeRequest":
             call_iam_get_api(ui_service_ip, "plan_change_requests", data).then( (response) =>{
                if(response.code === 200) {
                    if (Object.keys(response['change_request']).length > 0)
                    {
                        this.setState({change_request:response['change_request']});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "teamMate":
            call_iam_get_api(iam_ip, "notification/requests",{"user_id":localStorage.getItem("user_id")}).then( (response) =>{
                if(response.code === 200) {
                    if ("requests_result" in response && response['requests_result'] !==null)
                    {
                    let table = requestTable(response["requests_result"]);
                    this.setState({requestData: table});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            let teamUserdata = {"team_id":localStorage.getItem("team_id"),"user_id":localStorage.getItem("user_id")}
            call_iam_get_api(iam_ip, "tenant/users", teamUserdata).then( (response) =>{
                if(response.code === 200) {
                    if ("users" in response && response['users'] !== null)
                    {
                    let table = teamMateTable(response["users"]);
                    this.setState({teamMateData: table});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                   this.setState({errorOccurred: true});
                }
            });
            call_iam_get_api(iam_ip, "groups/roles",{"team_id":localStorage.getItem("team_id")}).then( (response) =>{
                if(response.code === 200) {
                    this.setState({ugmDetails: response});
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                   this.setState({errorOccurred: true});
                }
            });
            let invitationData = {"user_id":localStorage.getItem("user_id"),"team_id":localStorage.getItem("team_id")}
            call_iam_get_api(iam_ip, "invitations", invitationData).then( (response) =>{
                if(response.code === 200) {
                    if ("invitations_result" in response && response['invitations_result'] !== null)
                    {
                    let table = invitationTable(response["invitations_result"]);
                    this.setState({inviteData: table});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "user_details":
           call_iam_get_api(iam_ip, "user",{"user_id":localStorage.getItem("user_id")}).then( (response) =>{
                if(response.code === 200) {
                    if ("user_details" in response && response['user_details'] !==null)
                    {
                        let user_details=response['user_details'];
                        this.setState({first_name: user_details["first_name"],
                        last_name:user_details["last_name"],
                        org_name: user_details["org_name"],
                        email: user_details["email"],
                        phoneNo: user_details["phone_number"],
                        user_profile_image: user_details["user_profile_image"]})
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "usage":
            call_iam_get_api(ui_service_ip, "usage",data).then( (response) =>{
                if(response.code === 200) {
                    let dataUsage = -1;
                    let dataLimit = 0;
                    
                    let mydate = new Date(response['data_usage']['cycle_start']);
                    mydate.setDate(mydate.getDate() - 1);
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                                        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
                                        ];
                    let cycle_end_date = String(mydate.getDate() )+" "+ monthNames[mydate.getMonth()] +" "+ String( mydate.getFullYear())
                    this.setState({cycle_end_data:cycle_end_date})

                    if ("data_usage" in response && response['data_usage'] !== null) {
                        let dataUsageDetails = response["data_usage"];
                        // Converting the bytes to the GB
                        const dataUsageSize = dataUsageDetails["size"];
                        let dataSize= (dataUsageSize !== undefined ? formatBytes(dataUsageSize) : '0 GB' );
                        let planDetails = this.state.planDetails;
                        dataUsage = (dataUsageSize !== undefined ? convertBytesToGB(dataUsageSize) : 0 );
                        planDetails.size = dataSize;
                        planDetails.progressbar = dataUsage;
                        planDetails.mlModelCount = response["ml_model_count"];
                        planDetails.cycleEnd = dataUsageDetails["cycle_end"];
                        planDetails.cycleEndDate = cycle_end_date;
                        planDetails.paymentStatus = response["plan_details"]["payment_status"]
                        this.setState({planDetails:planDetails});
                    }

                    if ("plan_details" in response && response['plan_details'] !== null) {
                        const planInformation = response["plan_details"];
                        let planDetails = planInformation["payload"];
                        let planNameKey = planInformation["plan_name"];
                        let metaDetails = planInformation["details"];
                        let addDataCost = metaDetails.additional_data_cost;
                        let addModelCost = metaDetails.additional_model_cost;
                        const currencySymbol = metaDetails.currency;
                        const paymentStatus = planInformation["payment_status"];
                        dataLimit = planInformation["allowed_datasize"];
                        let planInfo = this.state.planDetails;
                        planInfo.addModelCost = addModelCost;
                        planInfo.addDataCost = addDataCost;
                        planInfo.planName = planDetails["name"]
                        planInfo.planBenefits = planDetails["plan_benefits"];
                        planInfo.cost = `${currencySymbol}${planInformation.price.toFixed(1)}/Month`;
                        planInfo.monthlyLimit = planDetails["monthly_limit"]
                        planInfo.maxDataLimit = dataLimit;
                        planInfo.maxMlModelLimit = planDetails["max_ml_model_limit"];
                        planInfo.comments = planDetails["comments"];
                        planInfo.dataLimit = dataLimit;
                        planInfo.planNameKey = planNameKey;
                        let usageExceeded = dataUsage >= dataLimit;

                        if (!usageExceeded) {
                            // User has received a warning message that their limit is about to reach.
                            // So we will show Increase Data limit button to these users

                            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
                            const warning_message = decrypt_data['warning_message']
                            // const warning_message = localStorage.getItem("warning_message");

                            usageExceeded = (warning_message !== undefined &&
                                warning_message !== null &&
                                warning_message.includes(LIMIT_ABOUT_TO_REACH));
                        }

                        if (planNameKey === QualdoPlanVersions.FREE && usageExceeded) {
                            planInfo.showInformationBanner = true;
                            planInfo.comments = PLAN_PAGE_WARNINGS["free_trial_exceeded"];
                        }
                        // paid plans and completed payment, exceeded data limit warning messages
                        const validPaymentStatus = (paymentStatus === QUALDO_PAYMENT_STATUS.PAYMENT_COMPLETED);
                        if (PAID_PLANS.includes(planNameKey) && validPaymentStatus && usageExceeded) {
                            planInfo.requiresDataLimitUpgrade = usageExceeded;
                            planInfo.showInformationBanner = true;
                            planInfo.comments = PLAN_PAGE_WARNINGS['data_limit_exceeded_paid_plans'];
                        }

                        // Get Appropriate message for failure to show page
                        // and display those messages in the banner section
                        // free trial expired or pending payment is there
                        if (PENDING_PAYMENTS.includes(paymentStatus)) {
                            planInfo.showInformationBanner = true;
                            let messages = PLAN_PAGE_WARNINGS[paymentStatus];
                            if (messages !== undefined) {
                                planInfo.comments = messages;
                            }
                        }

                        this.setState({planDetails: planInfo});
                    }

                } else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
             });
            break;
             case "get_email_subscription":
             call_iam_get_api(ui_service_ip, "subscription_info", data).then( (response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    let subscriptionInfo = response['subscription_info'];
                    this.checkAndUpdateTopButton(subscriptionInfo);
                    
                    this.setState({
                        emailsubscription: subscriptionInfo,
                        emailsubscriptioninitalloader: false
                    });
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken")
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;

            case "get_whitelisted_ip":
                call_iam_get_api(ui_service_ip, "managed_service/whitelisted_ip", data).then( (response) =>{
                    let data = response['whitelisted_ip']
                    if (response.code === 200){
                        if (store !== undefined){
                            store.dispatch(storeIpData(response));
                        } else {
                            storeIpData(response);
                        }
                        this.setState({ whitelisted_ip: data['ip_list'],
                                        old_whitelisted_ip: data['ip_list'],
                                        updated :response['updated_status'],
                                        client_id:data['client_id'],
                                        client_secret:data['client_secret'],
                                        cloud:response['cloud']

                    });

                    }

                    setTimeout(() => {
                        this.setState({loader:false})
                    }, 200);

                    let updated_status = response['updated_status'];
                    if (updated_status === undefined) {
                        updated_status = {};
                    }

                    /**Handle updation failure case */
                    if(updated_status['status'] === false){
                        this.setState({ old_whitelisted_ip:updated_status['old_ip'],
                                        updated:false,
                                        disableSave:false,
                                        disableCancel:false,
                                        editButton:false,
                                        updateloader:false,
                                        placeholder:null,
                                        close_tag:'tag-close-icon',
                                    })
                    }
                    else if(response.cloud === 'GCP' && updated_status['status'] === true){
                        this.setState({
                                        updated:true,
                                        editButton:true,
                                        updateloader:null,
                                        disableSave:false,
                                        disableCancel:false,
                                        close_tag:'tag-close-icon d-none',
                                    })
                    }
                    else if(updated_status['status'] === 'initiated'){
                        this.setState({
                            updated:null,
                            updateloader:true,
                            editButton:false,
                            disableSave:true,
                            disableCancel:true,
                            close_tag:'tag-close-icon d-none',
                        })
                    }
               });
               break;

            case "save_email_subscription":
                const preferences_data= this.state.emailsubscription;
                let session = localStorage.getItem("sessionToken");
                let team_id = localStorage.getItem("team_id");
                preferences_data["tenant_id"] = team_id;
                    call_Update_api(ui_service_ip, "subscription_info", preferences_data, session).then(
                                (response) => {
                                    if(response.code === 200) {
                                       this.showToast("alert-success", "Email Subscriptions Sucessfully Updated", "");
                                    }else{
                                     this.showToast("alert-danger", "Could Not Update Email Subscriptions", "");
                                    }
                                }
                            );
            break;
            case "get_email_subscriptionviaemail":
             let eData = {"u":this.state.uid,"k":this.state.eid};   
             call_iam_get_api(ui_service_ip, "email_subscription_info", eData).then( (response) =>{
                if (response === undefined) {
                    this.setState({errorOccurred: true});
                } else if (response.code === 200) {
                    this.setState({emailsubscription:response['subscription_info'],
                    emailsubscriptioninitalloader:false,
                    emailsubscriptionid:response['eid'],
                });
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
            case "save_email_subscriptionviaemail":
                let preferences_data_email= this.state.emailsubscription;
                let sessionemail = localStorage.getItem("sessionToken");
                preferences_data_email["u"] = this.state.uid;
                preferences_data_email["k"] = this.state.eid;
                    call_Update_api(ui_service_ip, "email_subscription_info", preferences_data_email, sessionemail).then(
                                (response) => {
                                    if(response.code === 200) {
                                        this.setState({errorOccurred: false});
                                    }else{
                                        this.setState({errorOccurred: true});                                    }
                                }
                            );
            break;  
        default:
            break;
        }
}


export function handleIAMSubmit(event){
    event.preventDefault();
    let session = localStorage.getItem("sessionToken");
    let user_id = localStorage.getItem("user_id");
    const { name } = event.target;
    switch(name) {
        case "preferences":
            const preferences_data={user_id:localStorage.getItem('user_id'),
                              daily_digest:this.state.daily_digest,weekly_digest:this.state.weekly_digest,
                              monitor_report_weekly:this.state.monitor_report,pager_duty_report_weekly:this.state.pager_duty_report,
                              nagios_report_weekly:this.state.nagios_report,reply_to_post_you_follow:this.state.reply_post_notify,
                              mention_email_notification_from_event_stream:this.state.mention_email_notify,
                              notification_from_monitor_alerts:this.state.monitor_alert_notify,reply_to_your_comments:this.state.reply_comments_notify};
            call_post_api(iam_ip, "mysettings/preferences", preferences_data, session).then(
                (response) => {
                    if(response.code === 200) {
                       this.showToast("alert-success", "preferences added successfully", "");
                    }else{
                     this.showToast("alert-danger", "could not add preferences", "");
                    }
                }
            );
            break;
        case "changePassword":
              const passwordData = new FormData(event.target);
              const passwordDataValue = {"user_id":localStorage.getItem('user_id'),
                                 "current_password":passwordData.get("currentPassword"),
                                 "reset_password":passwordData.get("password")}
              call_post_api(iam_ip, "profile/reset-password", passwordDataValue, session).then(
                (response) => {
                    if(response.code === 200) {
                       this.showToast("alert-success", "password changed successfully", "");
                    }else{
                     this.showToast("alert-danger", "could not change password", "");
                    }
                }
            );
            break;
        case "update_user_details":
            this.setState({disableButton: true});
            const profile_data = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                org_name: this.state.org_name,
                org_id:localStorage.getItem('org_id'),
                user_profile_image: this.state.user_profile_image
            };
            const userDetailUpdateURL = "user";
            profile_data["team_id"] = localStorage.getItem("team_id");
            profile_data["user_id"] = user_id;
            profile_data["detail_type"] = "account";
            call_Update_api(iam_ip, userDetailUpdateURL, profile_data, session).then((response) =>{
                if(response.code === 200) {
                    localStorage.setItem("user_name",response.data["username"]);
                    this.showToast("alert-success","User details updated successfully",'');
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.showToast("alert-danger","could not update user details",'');
                }
            });
            break;

        default:
            break;
            }
    }

export function sendInvite() {
      if(this.state.emails.length === 0) {
         this.setState({errorMsg:"Please Enter email"})
      }else if(this.state.description === '') {
         this.setState({descriptionErrorMsg: "Please Enter email content"})
      }
      else if(this.state.role['value'] === '') {
        this.setState({roleErrorMsg: "Please Select role"})
      }else{
         this.disableButton();
         let data = {
                 user_id: localStorage.getItem('user_id'),
                 description: this.state.description,
                 email_id: this.state.emails,
                 team_id: localStorage.getItem('team_id'),
                 role: this.state.role}
         let session = localStorage.getItem("sessionToken");
         call_post_api(iam_ip, "send_invitation", data, session).then(
                (response) => {
                    if(response.code === 200) {
                       this.handleClose();
                       this.showToast("alert-success", "Invitations sent successfully", "");
                    }else{
                     this.handleClose();
                     this.showToast("alert-danger", "Could not send Invitations", "");
                    }
                    this.setState({emails: [],hideSubmitButton: false});
                }
            );
      }

}


export function updateWhitelistedIp(data){
    let end_point = "managed_service/whitelisted_ip"
    let session = localStorage.getItem("sessionToken");
    call_Update_api(ui_service_ip,end_point, data, session).then((response) =>{
        if(response !== null && response !== undefined){

            /**
             * While making cancle after update failure
             * Need to update the DB with old ip
             * So Will make update API call
             */

            if(data['internal_update'] === true){
                this.setState({updated:null, updateloader: false})
            }
            else{
                if(response['cloud'] === 'GCP' && response.code === 200){
                    this.setState({updated:null, updateloader: true, disableSave:true,disableCancel:true})
                }
                else if(response.code === 200) {
                    this.setState({ editButton:true,
                                    whitelisted_ip:response['whitelisted_ip'],
                                    old_whiteleted_ip:response['whitelisted_ip'],
                                    disableSave:false,
                                    disableCancel:false,
                                    invalid:false,
                                    updated:true,
                                    updateloader:false,
                                })
                }
                else if(response.code !== 200) {
                    this.setState({disableSave:false, updated:false})
                }
            }
        }
    });
}