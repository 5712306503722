import React from "react";
import { browserHistory} from 'react-router';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from "./image";

class EnableConsistency extends React.Component {

    constructor(props, context) {
        super(props, context);
        let message = this.props.message;
        if (message === undefined || message === null) {
            message = "No Error found in the latest computation.";
        }

        let footer = this.props.footer;
        if (footer === undefined || footer === null) {
            footer = "Try with a different time period."
        }
        this.state = {
            message: message,
            footer: footer
        }
    }

    go_to_metrics_page() {
        browserHistory.push("/metrics");
    }

    render() {
        return (
            <div className="col-md-8 offset-md-2 text-center p-5">
                <div className="p-5">
                    <i className="d-inline-block mb-3">
                        <Image src="icon_monitor"/>
                    </i>
                    <h4 className="mb-3">Enable Consistency</h4>
                    <p>{this.state.message}</p>
                    <div className="d-flex justify-content-center">
                        <button type="button"
                                onClick={this.go_to_metrics_page}
                                className="btn btn-primary mb-2">
                            <i>
                                <FontAwesomeIcon icon={faChartArea}/>
                            </i>  Enable Consistency
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EnableConsistency;
