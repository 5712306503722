import React from 'react';
import { getProgressPercentage }  from  "../utils/event_handling";
import ProgressBar from 'react-bootstrap/ProgressBar'


class ProgressStatus extends React.Component {
   constructor(props){
        super(props);
        this.getProgressPercentage = getProgressPercentage.bind(this);
        this.state={
          progressStatus: this.props.progressStatus
        }

    }

    componentDidMount() {
        // this.getProgressPercentage();
    }


    render() {

        return (
            <div className="py-4 text-center">
                <div className="data-progress-status">
                    <p className="progress-percentage">
                        <strong>
                            <small>{this.state.progressStatus} %</small>
                        </strong> Completed</p>
                    <ProgressBar now={this.state.progressStatus}/>
                    <p>We are processing your data. Please check back in sometime.</p>
                </div>
            </div>
        );


    }

}

export default ProgressStatus;