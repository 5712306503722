import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faBell} from "@fortawesome/free-solid-svg-icons";


class PlanHeaderInfo extends React.Component {

    render() {
        return (
            <div className="card__data-limit-exceed">
                <div className="row no-gutters">
                    <div className="col">
                        <div className="data-limit-exceed-detail">
                            <i className="bell">
                                <FontAwesomeIcon icon={faBell}/>
                            </i>
                            <div className="data-limit-exceed-info">
                                <h6>{this.props.title}</h6>
                                <p>{this.props.description}</p>
                            </div>
                        </div>
                    </div>

                    {(this.props.buttonLabel !== undefined &&
                        this.props.buttonLabel !== null &&
                        this.props.buttonAction !== undefined) ?

                        <div className="col data-limit-exceed-action">
                            <button className="btn btn-primary btn-circle"
                                    onClick={this.props.buttonAction}
                                    type="button">
                                {this.props.buttonLabel}
                                <i><FontAwesomeIcon icon={faArrowRight}/></i>
                            </button>
                        </div>
                        :
                        ''
                    }

                </div>
            </div>
        );
    }
}

export default PlanHeaderInfo;
