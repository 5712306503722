import React from 'react';
import Select from 'react-select';
import {formSubmit} from "../utils/event_handling";
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import {getDatasets} from "../utils/common_utils";
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postCustomMetrics }  from "../utils/event_handling";



class CustomDefaultMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.postCustomMetrics = postCustomMetrics.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            hideSubmitButton: false,
            scheduleOptions: [{"label": "once", "value": "@once"},
            {"label": "daily", "value": "@daily"},
            {"label": "hourly", "value": "@hourly"},
            {"label": "monthly", "value": "@monthly"}],
            customMetrics: {
                sourceDataset: null,
                targetDataSetId: null,
//                scheduleInterval: null,
            },
            details: {
                data_set: [],
                attributes: []
            },
//            selectedIntervalOption: null,
            selectedDataSetOption: null,
            targetDataSetOption: null

        }
    }


    componentDidMount() {
        if (this.props.details === undefined || this.props.details === null) {
            return;
        }
        this.setState({details: this.props.details});
        if (this.props.exMetricDetails === undefined || this.props.exMetricDetails === null) {
           return;
        }
        let dataSetOptions = getDatasets(this.props.details.data_set);
        let customMetrics = this.state.customMetrics;
        let selectedTarget = this.props.exMetricDetails.target_data_set_id;
        let targetDataSetOption = null;
        let matchedTargets = dataSetOptions.filter(x => x["value"] === selectedTarget);
        if (matchedTargets.length > 0) {
            targetDataSetOption = matchedTargets[0];
            customMetrics.targetDataSetId = targetDataSetOption.value;
        }
        let selectedSource = this.props.exMetricDetails.data_set_id;
        let selectedDataSetOption = null;
        let matchedSource = dataSetOptions.filter(x => x["value"] === selectedSource);
        if (matchedSource.length > 0) {
            selectedDataSetOption = matchedSource[0];
            customMetrics.sourceDataset = selectedDataSetOption.value;
        }

//        let selectedInterval = this.props.exMetricDetails.schedule_interval;
//        let selectedIntervalOption = null;
//        let matchedInterval = this.state.scheduleOptions.filter(x => x["value"] === selectedInterval);
//        if (matchedInterval.length > 0) {
//            selectedIntervalOption = matchedInterval[0];
//            customMetrics.scheduleInterval = selectedIntervalOption.value;
//        }

        this.setState({selectedDataSetOption:selectedDataSetOption,
                       targetDataSetOption:targetDataSetOption,
                       customMetrics:customMetrics});
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    requires_metrics_update(metricsData) {
             const exMetricDetails = this.props.exMetricDetails;
             if(exMetricDetails.target_data_set_id !== metricsData.target_data_set_id) {
                return true
             }
             if(exMetricDetails.data_set_id !== metricsData.data_set_id) {
                return true
             }
             if(exMetricDetails.schedule_interval !== metricsData.schedule_interval) {
                return true
             }
             return false
    }

    requiresTargetSelection() {
        let showTarget = false;

        if (this.props.exMetricDetails !== undefined) {
            showTarget = this.props.exMetricDetails.metrics_definition.requires_target;
        }
        return showTarget;
    }

    render() {
        let metricsSchema = yup.object({
            sourceDataset: yup.mixed().nullable(false).required("Dataset to be monitored is a required field"),
        });
        if(this.requiresTargetSelection()) {
           metricsSchema = yup.object({
                sourceDataset: yup.mixed().nullable(false).required("Dataset to be monitored is a required field"),
                targetDataSetId: yup.mixed().nullable(false).required("Reference dataset is a required field"),
           });
        }
        let dataSetOptions = getDatasets(this.state.details.data_set);

        let sourceKey = "emptySourceKey";
        if (this.state.selectedDataSetOption !== null) {
            sourceKey = "updatedSourceKey";
        }

        let targetSelectKey = "emptyTargetSelectKey";
        if (this.state.targetDataSetOption !== null) {
            targetSelectKey = "updatedTargetSelectKey";
        }

        let disableSourceDatasetSelection = this.props.src_page === "edit_metrics";

//        let intervalKey = "emptyIntervalSelectKey";
//        if (this.state.selectedIntervalOption !== null) {
//            intervalKey = "updatedIntervalSelectKey";
//        }

        return (
                     <Formik
                          validationSchema={metricsSchema}
                          onSubmit={this.postCustomMetrics}
                          initialValues={this.state.customMetrics}
                     >
                     {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                     }) => (
                    <Form  name="customDefaultMetrics"
                           className="needs-validation" noValidate method="POST"
                           onSubmit={handleSubmit}>
                        <div className="form-row">
                                <Form.Group controlId="sourceDataset" className="col-md-8">
                                    <Form.Label>Dataset to be monitored
                                        <span className="text-danger">*</span> :</Form.Label>
                                        <Select
                                                filterOption={({label}, query) => label.includes(query)}
                                                isInvalid={errors.sourceDataset && touched.sourceDataset}
                                                classNamePrefix='select-control'
                                                isDisabled={disableSourceDatasetSelection}
                                                name="sourceDataset"
                                                defaultValue={this.state.selectedDataSetOption}
                                                key={sourceKey}
                                                onChange={selectedOption => {
                                                  handleChange("sourceDataset")(selectedOption.value.toString());
                                                  this.setState({selectedDataSetOption:selectedOption});
                                                }}
                                                options={dataSetOptions}
                                                placeholder="Select dataset"

                                        />
                                        <ErrorMessage component="div"
                                                      className="error-text"
                                                      name="sourceDataset" />
                                </Form.Group>
                            {
                                this.requiresTargetSelection() ?
                                    <Form.Group controlId="targetDataSetId"
                                                className="col-md-8">
                                        <Form.Label>
                                            Reference Dataset <span
                                            className="text-danger">*</span> :
                                        </Form.Label>
                                            <Select
                                                    classNamePrefix='select-control'
                                                    name="targetDataSetId"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    isInvalid={errors.targetDataSetId && touched.targetDataSetId}
                                                    key={targetSelectKey}
                                                    onChange={selectedOption => {
                                                      handleChange("targetDataSetId")(selectedOption.value.toString());
                                                      this.setState({targetDataSetOption:selectedOption});
                                                    }}
                                                    defaultValue={this.state.targetDataSetOption}
                                                    options={dataSetOptions}
                                                    placeholder="Select dataset"

                                            />
                                            <ErrorMessage component="div" className="error-text" name="targetDataSetId" />
                                    </Form.Group>
                                    :
                                    ''
                            }
                            {/*<Form.Group controlId="scheduleInterval" className="col-md-8">
                                    <Form.Label>Schedule Interval :</Form.Label>
                                        <Select
                                                classNamePrefix='select-control'
                                                isInvalid={errors.scheduleInterval && touched.scheduleInterval}
                                                name="scheduleInterval"
                                                defaultValue={this.state.selectedIntervalOption}
                                                key={intervalKey}
                                                onChange={selectedOption => {
                                                      handleChange("scheduleInterval")(selectedOption.value.toString());
                                                    }}
                                                options={this.state.scheduleOptions}
                                                placeholder="Select Interval"

                                        />
                                        <ErrorMessage component="div" className="error-text" name="scheduleInterval" />
                            </Form.Group> */
                            }
                        </div>
                        <Modal.Footer>
                            <button type="button"
                                    onClick={() => this.props.closeModal()}
                                    className="btn btn-outline btn-grey btn-circle mr-2">
                                Cancel
                            </button>
                            <button type="submit"
                                    disabled={this.state.hideSubmitButton}
                                    className="btn btn-primary btn-circle show-success-toast"
                                    data-dismiss="modal"
                                    onSubmit={formSubmit.bind(this)}>
                                <FontAwesomeIcon icon={faCheck}/> Apply
                            </button>
                        </Modal.Footer>
                    </Form>)}
                    </Formik>
        );

    }
}

export default CustomDefaultMetrics;
