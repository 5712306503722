import React from 'react';
import { PRIVACY_POLICY_LINK, COOKIE_POLICY_LINK } from "../utils/constant";
import { getApi } from "../utils/User_handling"
import Load from "../components/loadAction"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import Image from './image';

let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO

class Unsubscribe extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);

        let query = String(window.location.href);
        let qs = query.split("/");
        let uid, eid = "";
        let querylen = qs.length;
        if (querylen) {
            uid = qs[querylen - 1]
            eid = qs[querylen - 2]
        }
        this.state = {
            emailsubscription: [],
            emailsubscriptioninitalloader: false,
            emailsavemessage: false,
            uid: uid,
            eid: eid
        };
        this.getData()
    }
    saveData() {
        this.setState({ emailsavemessage: true })
        this.getApi("save_email_subscriptionviaemail");
    }
    getData() {
        this.getApi("get_email_subscriptionviaemail");
    }
    toggleEmailGroup = (i) => (event) => {
        let checkedlist = this.state.emailsubscription;
        checkedlist[i].email_user_status = (event.target.checked === true ? 1 : 0);
        this.setState({ emailsubscription: checkedlist })
    }

    render() {

        return (
            <>
                <header className="qd-header">
                    <div className="qd-header-container navbar navbar-expand-md">
                        <div className="qd-header__brand">
                            <a href="../../../">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src={BRAND_LOGO}
                                />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="qd-container pt-3 pt-md-4">
                    <div className="qd-body no-tabs qd-grid shadow-none bg-transparent">
                        <div className="qd-aside-overlay"></div>
                        <div id="qd-section__section" className="qd-section qd-grid-item">
                            <div className="row m-0 m-sm-0">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className={this.state.emailsavemessage ? "portlet mb-3 d-none" : "portlet mb-3"}>
                                        <div className="portlet-body p-sm-4 p-md-5">
                                            <div style={{
                                                display: this.state.emailsubscriptioninitalloader ? "block" : "none",
                                            }}>
                                                {<Load />}
                                            </div>
                                            <div style={{
                                                visibility: this.state.emailsubscriptioninitalloader ? "hidden" : "visible",
                                            }}>
                                                <div className="d-flex">
                                                    <h3 className="mr-3 mb-0 align-self-center d-none d-sm-block text-blue"><FontAwesomeIcon icon={faChalkboardTeacher} /></h3>
                                                    <div className="d-flex align-items-start flex-column">
                                                        <h3>Update Your Email Preferences</h3>
                                                        <p className="mb-2 text-muted">Your changes will impact preference for email sent to</p>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-8">
                                                        {/* <input className="form-control" type="text" placeholder="Email" aria-label=".form-control-lg example" value="sasi@saturam.com"  /> */}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div>
                                                    <h4 className="mb-3">Email me the following Notifications</h4>
                                                    {this.state.emailsubscription.map(function (emailsubscriptiondata, i) {
                                                        return (
                                                            <div>
                                                                <div className="custom-control custom-switch custom-switch-sm blue-switch  mb-2">
                                                                    <input type="checkbox" checked={emailsubscriptiondata.email_user_status === 1 ? "checked" : ""} className="custom-control-input" onChange={this.toggleEmailGroup(i)} id={'emailsubcheck' + i} />
                                                                    <label className="custom-control-label" for={'emailsubcheck' + i}>&nbsp;{emailsubscriptiondata.email_group_name}</label>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        )
                                                    }, this)}
                                                </div>

                                                <p className="text-muted mt-4 small">
                                                    by submitting this form you agree &nbsp;
                                                    <a href={PRIVACY_POLICY_LINK}>Privacy Policy</a> -
                                                    <a href={COOKIE_POLICY_LINK}>Cookie Policy</a>
                                                </p>

                                                <div className="form-group row mt-3">
                                                    <div className="col-sm-12">
                                                        <button onClick={this.saveData.bind(this)} className="btn btn-primary btn-circle px-5" disabled="" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.state.emailsavemessage ? "portlet mt-5 mb-3 " : "portlet mt-5 mb-3 d-none"}>
                                        <div className="portlet-body p-sm-4 p-md-5">
                                            <div className="text-center py-5">
                                                <i className="d-inline-block my-4">
                                                    <Image src="success"
                                                    />
                                                </i>
                                                <h3 className="mb-3">You have been successfully unsubscribed!</h3>
                                                <p>Thank you. Your Subscription preferance have been updated.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default Unsubscribe;